import moment from 'moment-timezone';

export const snoozeOptions = [
  {
    key: '1-hour',
    label: '1 hour from now',
    value: 60 * 60 * 1000,
  },
  {
    key: '2-hour',
    label: '2 hour from now',
    value: 2 * 60 * 60 * 1000,
  },
  {
    key: '4-hour',
    label: '4 hour from now',
    value: 4 * 60 * 60 * 1000,
  },
  {
    key: '6-hour',
    label: '6 hour from now',
    value: 6 * 60 * 60 * 1000,
  },
  {
    key: '1-day',
    label: '1 day from now',
    value: 24 * 60 * 60 * 1000,
  },
  {
    key: '2-days',
    label: '2 days from now',
    value: 2 * 24 * 60 * 60 * 1000,
  },
  {
    key: '4-days',
    label: '4 days from now',
    value: 4 * 24 * 60 * 60 * 1000,
  },
  {
    key: '1-week',
    label: '1 week from now',
    value: 7 * 24 * 60 * 60 * 1000,
  },
  {
    key: 'custom',
    label: 'Custom date & time',
    value: null,
  },
];

export const generateTimeArray = () => {
  const timeArray = [];

  // eslint-disable-next-line no-plusplus
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += 30) {
      const formattedHour12 = (((hour + 11) % 12) + 1)
        .toString()
        .padStart(2, '0');
      const formattedMinute = minute.toString().padStart(2, '0');
      const ampm = hour < 12 ? 'AM' : 'PM';

      const timeObject = {
        key: `${formattedHour12}:${formattedMinute} ${ampm}`,
        value: `${hour.toString().padStart(2, '0')}:${formattedMinute}`,
      };

      timeArray.push(timeObject);
    }
  }

  return timeArray;
};

export const mergedDateTime = (date, time): Date =>
  moment(date)
    .hours(moment(time.value, 'HH:mm').hours())
    .minutes(moment(time.value, 'HH:mm').minutes())
    .toDate();
