import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { DotsVertical } from '@saleshandy/icons';
import LastLoginCell from './last-login-cell';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { getActionPermissions } from '../utils/get-action-permissions';
import { Action } from '../../../../../../../shared/design-system/components/organisms/table/types';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { User } from '../../../../../types/users-and-teams';

type IProps = {
  userId: number;
  row: User;
  actions: Action[];
  onAction: (key: string, row: User) => void;
  userTimezone: string;
  isLastRow: boolean;
  currentUserATMRole?: string;
};

const ActionsCell: React.FC<IProps> = ({
  userId,
  row,
  actions,
  onAction,
  userTimezone,
  isLastRow,
  currentUserATMRole,
}) => {
  let actionsCounter = 0;
  const outerAction = [];
  const dropdownActions = [];

  const filteredActions = actions.filter((action) =>
    getActionPermissions(
      userId,
      action,
      row.status,
      row.id,
      row.role,
      currentUserATMRole,
    ),
  );

  filteredActions.forEach((action) => {
    if (actionsCounter < 3 || filteredActions.length === 4) {
      outerAction.push(action);
      actionsCounter += 1;
    } else {
      dropdownActions.push(action);
    }
  });

  return outerAction.length > 0 ? (
    <div className="hoverable-cell">
      <div className="hoverable-cell--content">
        <LastLoginCell lastLogin={row.lastSeen} userTimezone={userTimezone} />
      </div>
      <div className="hoverable-cell--actions actionables">
        {outerAction.map((action) => (
          <OverlayTooltip
            text={action.displayName}
            key={action.key}
            placement={isLastRow ? Placement.Top : Placement.Bottom}
          >
            <div
              {...accessibleOnClick(() => onAction(action.key, row))}
              className="action-btn"
            >
              {action.iconElement}
            </div>
          </OverlayTooltip>
        ))}

        {dropdownActions.length > 0 && (
          <div>
            <Dropdown bsPrefix="dropdown-custom" drop="down">
              <Dropdown.Toggle
                bsPrefix="dropdown-toggle-custom"
                variant="dropdown-custom"
                id="dropdown-basic"
              >
                <DotsVertical />
              </Dropdown.Toggle>
              <Dropdown.Menu bsPrefix="dropdown-menu-custom">
                {dropdownActions.map((action) => (
                  <Dropdown.Item
                    key={action.key}
                    {...accessibleOnClick(() => onAction(action.key, row))}
                  >
                    {action.iconElement} {action.displayName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  ) : (
    <LastLoginCell lastLogin={row.lastSeen} userTimezone={userTimezone} />
  );
};

export default ActionsCell;
