export const cobaltSessionTokenStorageKey = 'cobalt-session-token';

export const setCobaltSessionToken = (token: string) =>
  localStorage.setItem(cobaltSessionTokenStorageKey, token);
export const getCobaltSessionToken = () =>
  localStorage.getItem(cobaltSessionTokenStorageKey);
export const removeCobaltSessionToken = () =>
  localStorage.removeItem(cobaltSessionTokenStorageKey);

export enum CobaltEvent {
  'When a Prospect is Unsubscribed' = 'prospect-unsubscribed',
  'Prospect is Unsubscribed in Saleshandy' = 'prospect-unsubscribed',
  'When a Prospect is Finished' = 'prospect-finished',
  'Prospect is Finished in Saleshandy' = 'prospect-finished',
  'Reply is Received in Saleshandy' = 'reply-received',
  'Link is Clicked in Saleshandy' = 'email-link-clicked',
  'Sequence Email is Opened in Saleshandy' = 'email-opened',
  'Email Sent from Saleshandy' = 'sequence-email-sent',
  'When an Email is Bounced' = 'email-bounced',
  'Email is Bounced in Saleshandy' = 'email-bounced',
  'Conversation Category is Updated in Saleshandy' = 'reply-category-updated',
  'Conversation Category Updated in Saleshandy' = 'reply-category-updated',
  'Prospect Outcome is updated in Saleshandy' = 'reply-category-updated',
  'Prospect Added to Sequence in Saleshandy' = 'prospect-added-to-sequence',
  'Email is Opened in Saleshandy' = 'email-opened',
  'Import Prospects from Salesforce' = 'sync-prospects',
  'Import Prospects from Hubspot' = 'sync-prospects',
}
export const staticOptionsForCategory = [
  {
    key: 'Interested',
    value: 'Interested',
  },
  { key: 'Not Interested', value: 'Not Interested' },
  { key: 'Closed', value: 'Closed' },
  { key: ':Meeting Booked', value: 'Meeting Booked' },
  { key: 'Do Not Contact', value: 'Do Not Contact' },
  { key: 'Out of Office', value: 'Out of Office' },
  { key: 'Not Now', value: 'Not Now' },
];

export enum IntegrationTabs {
  FieldMapping = 'Field Mapping',
  TriggersActivity = 'Triggers & Activity',
  ProspectImport = 'Prospects Import',
}
export const hasConversationCategorySelected = (obj) =>
  Object.values(obj).some((objValue) =>
    staticOptionsForCategory.some((arrItem) => arrItem.value === objValue),
  );

export const cleanPlanName = (planName: string): string =>
  planName
    .replace(/\([^)]*\)/g, '')
    .toLowerCase()
    .trim();
