import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import DeleteAccountModal from './delete-account-modal';

import { deleteMyProfileRequest } from '../../extra-actions';
import { resetDeleteMyProfileRequest } from '../../my-profile-slice';

import { resetPasswordRequest } from '../../../../../auth/components/reset-password/extra-actions';

const mapStateToProps = (state: RootState) => ({
  email: state.myProfile.myProfile.email,

  deleteMyProfileRequestStatus: state.myProfile.deleteMyProfileRequest.status,
  deleteMyProfileRequestMessage: state.myProfile.deleteMyProfileRequest.message,
  deleteMyProfileRequestError: state.myProfile.deleteMyProfileRequest.error,

  resetPasswordRequestStatus: state.resetPassword.status,
});

const mapDispatchToProps = {
  sendDeleteMyProfileRequest: (password: string) =>
    deleteMyProfileRequest(password),
  resetDeleteMyProfileRequest: () => resetDeleteMyProfileRequest(),

  sendResetPasswordRequest: (email: string) => resetPasswordRequest({ email }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: () => void;
};

export default connector(DeleteAccountModal);
