import React from 'react';
import { Forbid } from '@saleshandy/icons';
import ConfirmationModalV3 from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';

const DisableUserModal = ({ show, onSubmit, onClose, isLoading }) => (
  <ConfirmationModalV3
    show={show}
    hideTitleIcon
    icon={<Forbid className="blue-txt-11" />}
    className="action-modal"
    title="Disable User"
    showCloseIcon={false}
    cancelButtonText="Cancel"
    contents={[
      'Are you sure you want to disable the selected user?',
      'Once disabled, the user will not be able to access the Saleshandy account.',
    ]}
    submitButtonText="Disable"
    onSubmit={onSubmit}
    onClose={onClose}
    cancelButtonClassName="cancel-btn"
    isSubmitDisabled={isLoading}
    isSubmitLoading={isLoading}
  />
);

export default DisableUserModal;
