import React from 'react';
import type { IProps } from './tasks-header-container';
import { TaskStatusKey } from '../../utils/enums';
import { getTaskStatusArray } from '../../utils/helper';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { getIsRequestPending } from '../../../../shared/utils';

const TasksHeader: React.FC<IProps> = ({
  taskStatus,
  onChange,
  taskStatusCount,
  getTasksRequestStatus,
  getTasksStatusCountRequest,
}) => {
  const onTaskStatusChange = (status: TaskStatusKey) => {
    if (getIsRequestPending(getTasksRequestStatus) || taskStatus === status) {
      return;
    }

    onChange(status);
  };

  if (!taskStatusCount) {
    return null;
  }

  return (
    <div className="tasks__header">
      {getTaskStatusArray().map(({ label, key }) => (
        <div
          key={key}
          className={`tasks__header--item ${
            key === taskStatus && 'tasks__header--active'
          }`}
          {...accessibleOnClick(() => onTaskStatusChange(key))}
        >
          <div className="d-flex align-items-center">
            <span
              className={`regular-2 font-medium line-height-20 label ${
                key === taskStatus ? 'font-semibold blue-txt-11' : 'gray-txt-30'
              }`}
            >
              {label}
            </span>

            <span
              className={`ml-1 regular-1 font-medium count ${
                key === TaskStatusKey.Overdue
                  ? 'red-txt-18 red-19'
                  : 'gray-txt-15 gray-16'
              }`}
            >
              {Number(taskStatusCount[key])}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TasksHeader;
