import React from 'react';
import { Link } from 'react-router-dom';

import Auth from '../..';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';

import { IProps } from './types';
import { SHLogoDark, BrokenLink } from '../../../../shared/svg';
import {
  getIsRequestFailed,
  getIsRequestIdeal,
  getIsRequestPending,
  executeOnRequestStatusWithPrevStatusCheck,
} from '../../../../shared/utils';

class ResetPasswordCallback extends React.Component<IProps, null> {
  componentDidMount() {
    const { sendVerifyResetPasswordCodeRequest, showLoading } = this.props;

    sendVerifyResetPasswordCodeRequest();

    showLoading();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { resetPasswordCallbackStatus, hideLoading } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: resetPasswordCallbackStatus,
      prevStatus: prevProps.resetPasswordCallbackStatus,
      onSuccess: hideLoading,
      onFailed: hideLoading,
    });
  }

  render() {
    const { resetPasswordCallbackStatus } = this.props;

    if (
      getIsRequestIdeal(resetPasswordCallbackStatus) ||
      getIsRequestPending(resetPasswordCallbackStatus)
    ) {
      return null;
    }

    if (getIsRequestFailed(resetPasswordCallbackStatus)) {
      return (
        <div className="auth">
          <div className="testimonials-container">
            <SHLogoDark width={160} />
            <TestimonialsCarousel />
            <p className="copyright">
              &copy;{`${new Date().getFullYear()} Saleshandy`}
            </p>
          </div>

          <div className="auth-wrapper">
            <div className="auth-container">
              <div className="auth-container--icon">
                <BrokenLink />
              </div>
              <h1 className="auth-container--title">
                The reset password link is not valid
              </h1>
              <p className="auth-container--sub-title">
                The reset password link is incorrect or might have expired. You
                can reset the password from{' '}
                <Link to="/reset-password">here</Link> also or{' '}
                <Link to="/login">Login</Link>.
              </p>
            </div>
          </div>
        </div>
      );
    }

    return <Auth />;
  }
}

export default ResetPasswordCallback;
