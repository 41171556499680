import React from 'react';
import { supportUrls } from '../../../../../shared/utils/urls';
import { GlobalSettings } from '../../../../../shared/components/global-settings-wrapper';
import LinkBadge from '../../../../../shared/design-system/components/atoms/link-badge';

const OutOfOfficeHeader = () => (
  <>
    <div className="ooo-header">
      <GlobalSettings.Header className="globalSettings__Header--margin">
        <GlobalSettings.HeaderLeft>
          <GlobalSettings.HeaderTitle
            title="Out of Office"
            isToolTip={true}
            tooltipText="Manage out-of-office settings for the prospects who are replying using the
            Vacation Responder."
          />

          <LinkBadge url={supportUrls.outOfOfficeBlog} />
        </GlobalSettings.HeaderLeft>
      </GlobalSettings.Header>
    </div>
  </>
);

export default OutOfOfficeHeader;
