import React from 'react';
import type { IProps } from './logo-secondary-container';

const LogoSecondary: React.FC<IProps> = ({ agencyConfig }) => {
  if (agencyConfig?.icon) {
    return (
      <div className="logo--secondary">
        {!!agencyConfig?.icon && <img src={agencyConfig?.icon} alt="Logo" />}
      </div>
    );
  }

  if (agencyConfig?.logo?.secondary !== '') {
    return (
      <div className="logo--secondary">
        <img src={agencyConfig?.logo?.secondary} alt="Logo" />
      </div>
    );
  }

  return null;
};

export default LogoSecondary;
