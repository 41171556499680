import React from 'react';

export const ArrowBackForward = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.78251 3.96754C9.07501 4.26004 9.07501 4.73254 8.78251 5.02504L7.06501 6.74254H13.5075C14.505 6.74254 15.4575 7.14004 16.1625 7.83754C16.8675 8.54254 17.2575 9.49504 17.2575 10.4925C17.2575 11.49 16.86 12.4425 16.1625 13.1475C15.4575 13.8525 14.505 14.2425 13.5075 14.2425H12.7575C12.345 14.2425 12.0075 13.905 12.0075 13.4925C12.0075 13.08 12.345 12.7425 12.7575 12.7425H13.5075C14.1075 12.7425 14.6775 12.5025 15.0975 12.0825C15.5175 11.6625 15.7575 11.085 15.7575 10.4925C15.7575 9.90004 15.5175 9.32254 15.0975 8.90254C14.6775 8.48254 14.1 8.24254 13.5075 8.24254H7.06501L8.78251 9.96004C9.07501 10.2525 9.07501 10.725 8.78251 11.0175C8.49001 11.31 8.01751 11.31 7.72501 11.0175L4.72501 8.01754C4.43251 7.72504 4.43251 7.25254 4.72501 6.96004L7.71751 3.96754C8.01001 3.67504 8.48251 3.67504 8.77501 3.96754H8.78251Z"
      fill="currentColor"
    />
    <path
      d="M5.03251 5.03254C5.32501 4.74004 5.32501 4.26754 5.03251 3.97504C4.74001 3.68254 4.26751 3.68254 3.97501 3.97504L0.967513 6.96754C0.675013 7.26004 0.675013 7.73254 0.967513 8.02504L3.96751 11.025C4.26001 11.3175 4.73251 11.3175 5.02501 11.025C5.31751 10.7325 5.31751 10.26 5.02501 9.96754L3.30751 8.25004H3.32251C2.95501 7.87504 2.71501 7.29004 3.34501 6.72004L5.03251 5.03254Z"
      fill="currentColor"
    />
  </svg>
);
