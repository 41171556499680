import React from 'react';

export const NoEmailContent = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="38.0873" cy="39.9195" r="30.4762" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9448 34.3878C17.3534 34.2244 17.5523 33.7607 17.3889 33.352C17.2255 32.9434 16.7617 32.7446 16.3531 32.908L14.3799 33.6969C13.9713 33.8603 13.7725 34.3241 13.9359 34.7327C14.0993 35.1414 14.563 35.3402 14.9716 35.1768L16.9448 34.3878ZM23.1241 23.6845C23.1869 24.1201 22.8847 24.5242 22.4491 24.587C22.0135 24.6498 21.6095 24.3476 21.5466 23.912L21.2433 21.8087C21.1805 21.3731 21.4827 20.9691 21.9183 20.9063C22.3539 20.8434 22.758 21.1456 22.8208 21.5812L23.1241 23.6845ZM17.251 29.5162C17.2141 29.9548 16.8287 30.2804 16.3901 30.2435L14.2726 30.0653C13.834 30.0284 13.5084 29.643 13.5453 29.2044C13.5822 28.7659 13.9677 28.4403 14.4062 28.4772L16.5238 28.6553C16.9623 28.6922 17.2879 29.0776 17.251 29.5162ZM19.2553 25.2773C19.5066 25.6386 19.4173 26.1352 19.0559 26.3864C18.6946 26.6377 18.198 26.5484 17.9467 26.187L16.7337 24.4423C16.4825 24.0809 16.5717 23.5843 16.9331 23.3331C17.2944 23.0819 17.791 23.1711 18.0423 23.5325L19.2553 25.2773Z"
      fill="#CCD0DB"
    />
    <g clipPath="url(#clip0_35756_4209)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4258 43.2028L22.9034 34.4607C22.5055 34.2043 22.1793 33.8563 21.9538 33.4479C21.7283 33.0395 21.6106 32.5833 21.6111 32.1201C21.6111 30.4948 22.9734 29.1738 24.6479 29.1737H51.5742C53.2487 29.1737 54.6111 30.4947 54.6111 32.1185C54.6111 33.0629 54.128 33.9379 53.3188 34.4607L39.7963 43.2028C39.2845 43.5337 38.6977 43.6992 38.111 43.6992C37.5244 43.6992 36.9377 43.5338 36.4258 43.2028ZM38.1111 45.5744C39.0943 45.5744 40.048 45.2938 40.869 44.7631L54.3909 36.0213C54.4657 35.9728 54.5391 35.9223 54.611 35.8699V50.1948C54.611 51.8078 53.2669 53.1406 51.5732 53.1406H24.649C22.9855 53.1406 21.6111 51.8371 21.6111 50.1948V35.8699C21.6829 35.9222 21.7562 35.9726 21.8308 36.021L35.3533 44.7631C36.1743 45.2938 37.1279 45.5744 38.1111 45.5744Z"
        fill="url(#paint0_linear_35756_4209)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.1467 48.9383C72.1467 48.1494 71.5071 47.5098 70.7182 47.5098H64.7487C63.9597 47.5098 63.3201 48.1494 63.3201 48.9383C63.3201 49.7273 63.9597 50.3669 64.7487 50.3669H70.7182C71.5071 50.3669 72.1467 49.7273 72.1467 48.9383ZM72.1467 55.6053C72.1467 54.8164 71.5071 54.1768 70.7182 54.1768H60.3353C59.5464 54.1768 58.9068 54.8164 58.9068 55.6053C58.9068 56.3943 59.5464 57.0339 60.3353 57.0339H70.7182C71.5071 57.0339 72.1467 56.3943 72.1467 55.6053ZM60.7879 47.5098C61.5769 47.5098 62.2165 48.1494 62.2165 48.9383C62.2165 49.7273 61.5769 50.3669 60.7879 50.3669H60.3351C59.5461 50.3669 58.9065 49.7273 58.9065 48.9383C58.9065 48.1494 59.5461 47.5098 60.3351 47.5098H60.7879Z"
      fill="#DBE0E7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_35756_4209"
        x1="30.3459"
        y1="50.7745"
        x2="31.5475"
        y2="28.6098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <clipPath id="clip0_35756_4209">
        <rect
          width="33"
          height="32"
          fill="white"
          transform="translate(21.6111 25.1572)"
        />
      </clipPath>
    </defs>
  </svg>
);
