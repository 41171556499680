import React from 'react';

export const NoTagsFound = () => (
  <svg
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="32.0001" cy="30.7682" r="24.381" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0449 12.4973C39.0885 12.863 39.4203 13.1241 39.7859 13.0805C40.1516 13.037 40.4127 12.7052 40.3691 12.3396L40.1588 10.574C40.1152 10.2083 39.7835 9.94719 39.4178 9.99076C39.0522 10.0343 38.791 10.3661 38.8346 10.7317L39.0449 12.4973ZM46.355 19.8087C45.9893 19.7651 45.7282 19.4334 45.7718 19.0677C45.8153 18.7021 46.1471 18.441 46.5127 18.4845L48.2783 18.6949C48.644 18.7384 48.9051 19.0702 48.8615 19.4358C48.818 19.8015 48.4862 20.0626 48.1206 20.019L46.355 19.8087ZM42.917 13.8001C42.5705 13.6753 42.3908 13.2932 42.5156 12.9468L43.1182 11.2739C43.243 10.9275 43.625 10.7478 43.9714 10.8726C44.3179 10.9974 44.4976 11.3794 44.3728 11.7258L43.7702 13.3987C43.6455 13.7452 43.2634 13.9249 42.917 13.8001ZM45.9074 16.3377C45.5609 16.4625 45.1789 16.2828 45.0541 15.9364C44.9293 15.5899 45.109 15.2079 45.4555 15.0831L47.1283 14.4805C47.4748 14.3557 47.8568 14.5354 47.9816 14.8819C48.1064 15.2284 47.9267 15.6104 47.5802 15.7352L45.9074 16.3377Z"
      fill="#CCD0DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.9665 44.7004C29.5977 44.7004 30.1094 45.2121 30.1094 45.8433C30.1094 46.4745 29.5977 46.9862 28.9665 46.9862H19.0618C18.4306 46.9862 17.9189 46.4745 17.9189 45.8433C17.9189 45.2121 18.4306 44.7004 19.0618 44.7004H28.9665ZM28.9665 50.034C29.5977 50.034 30.1094 50.5457 30.1094 51.1769C30.1094 51.8081 29.5977 52.3198 28.9665 52.3198H12.9665C12.3353 52.3198 11.8237 51.8081 11.8237 51.1769C11.8237 50.5457 12.3353 50.034 12.9665 50.034H28.9665ZM30.1094 56.5843C30.1094 55.9531 29.5977 55.4415 28.9665 55.4415H12.9665C12.3353 55.4415 11.8237 55.9531 11.8237 56.5843C11.8237 57.2155 12.3353 57.7272 12.9665 57.7272H28.9665C29.5977 57.7272 30.1094 57.2155 30.1094 56.5843ZM15.2522 44.7005C15.8834 44.7005 16.3951 45.2122 16.3951 45.8434C16.3951 46.4745 15.8834 46.9862 15.2522 46.9862H12.9665C12.3353 46.9862 11.8237 46.4745 11.8237 45.8434C11.8237 45.2122 12.3353 44.7005 12.9665 44.7005H15.2522Z"
      fill="#DBE0E7"
    />
    <path
      d="M51.6122 46.2262L40.2129 34.9622L37.9557 37.2465L49.355 48.5106L51.6122 46.2262Z"
      fill="url(#paint0_linear_34361_52592)"
    />
    <path
      d="M55.9679 49.2527L47.0992 40.4892C47.0498 40.4404 46.9702 40.4409 46.9214 40.4903L43.5647 43.8873C43.5159 43.9367 43.5164 44.0163 43.5657 44.0651L52.4345 52.8286C52.4838 52.8774 52.5634 52.8769 52.6122 52.8275L55.969 49.4305C56.0178 49.3811 56.0173 49.3015 55.9679 49.2527Z"
      fill="url(#paint1_linear_34361_52592)"
    />
    <path
      d="M30.1101 40.6269C37.9525 40.6269 44.31 34.2694 44.31 26.427C44.31 18.5846 37.9525 12.2271 30.1101 12.2271C22.2677 12.2271 15.9102 18.5846 15.9102 26.427C15.9102 34.2694 22.2677 40.6269 30.1101 40.6269Z"
      fill="url(#paint2_linear_34361_52592)"
    />
    <path
      d="M30.1115 38.3098C36.6742 38.3098 41.9944 32.9897 41.9944 26.4269C41.9944 19.8641 36.6742 14.5439 30.1115 14.5439C23.5487 14.5439 18.2285 19.8641 18.2285 26.4269C18.2285 32.9897 23.5487 38.3098 30.1115 38.3098Z"
      fill="#DBE0E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.1782 20.8688C31.8572 20.9031 33.2406 22.2579 33.3275 23.9533C33.3738 24.8558 33.0611 25.7131 32.4471 26.3674C32.0689 26.7703 31.5949 27.066 31.0781 27.2311V28.3378C31.0781 28.8774 30.6457 29.3149 30.1122 29.3149C29.5788 29.3149 29.1463 28.8774 29.1463 28.3378V26.4052C29.1463 25.8656 29.5788 25.4281 30.1122 25.4281C30.4686 25.4281 30.8002 25.2838 31.0462 25.0218C31.2918 24.7601 31.4169 24.4166 31.3983 24.0546C31.3816 23.7295 31.2423 23.4231 31.006 23.1918C30.7695 22.9604 30.4617 22.8293 30.1393 22.8227C29.4856 22.8096 28.9268 23.2907 28.8369 23.9423C28.8286 24.0023 28.8244 24.0639 28.8244 24.1253C28.8244 24.6649 28.3919 25.1024 27.8585 25.1024C27.325 25.1024 26.8926 24.6649 26.8926 24.1253C26.8926 23.9737 26.9029 23.8223 26.9236 23.6722C27.1456 22.0628 28.5104 20.8682 30.1125 20.8682C30.1343 20.8682 30.1562 20.8684 30.1782 20.8688ZM31.0777 31.008C31.0777 31.5476 30.6453 31.9851 30.1118 31.9851C29.5784 31.9851 29.1459 31.5476 29.1459 31.008C29.1459 30.4683 29.5784 30.0308 30.1118 30.0308C30.6453 30.0308 31.0777 30.4683 31.0777 31.008Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_34361_52592"
        x1="40.8718"
        y1="40.128"
        x2="43.8487"
        y2="37.7178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_34361_52592"
        x1="45.7908"
        y1="46.2638"
        x2="50.7346"
        y2="43.057"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_34361_52592"
        x1="23.1752"
        y1="40.6269"
        x2="37.6366"
        y2="14.5716"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
    </defs>
  </svg>
);
