import api from '../../../../../api';
import { ResourceType } from './enums';

export const saveCompanyName = async (companyName: string) =>
  api.post('/whitelabel/company-name', { companyName });

export const editCompanyName = async (companyName: string) =>
  api.patch('/whitelabel/company-name', { companyName });

export const getResource = async () => api.get('/whitelabel/resource');

const fileUploadHeaders = {
  headers: {
    ContentType: 'multipart/form-data',
    'Transfer-Encoding': 'gzip',
    'Content-Encoding': 'gzip',
  },
};

export const uploadResource = async (
  resource: File,
  resourceType: ResourceType,
) => {
  const formData = new FormData();
  formData.append('file', resource);

  return api.post(
    `/whitelabel/upload-resource/${resourceType}`,
    formData,
    fileUploadHeaders,
  );
};

export const editResource = async (
  resource: File,
  resourceType: ResourceType,
) => {
  const formData = new FormData();
  formData.append('file', resource);
  return api.patch(
    `/whitelabel/upload-resource/${resourceType}`,
    formData,
    fileUploadHeaders,
  );
};

export const deleteResource = async (resourceType: ResourceType) =>
  api.delete(`/whitelabel/resource/${resourceType}`);

//* DNS */
export const getDNS = async () => api.get('/whitelabel/dns');

export const saveDNS = async (domain: string) =>
  api.post('/whitelabel/dns', { domain });

export const deleteDNS = async () => api.delete('/whitelabel/dns');
