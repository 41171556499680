import React, { useEffect, useState } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { useHistory, useParams } from 'react-router';

import type { IProps } from './webhook-details-and-logs-container';

import AddWebhookHeader from '../add-webhook/components/add-webhook-header';
import AddWebhook from '../add-webhook';
import WebhookLogs from './components/webhook-logs';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import AddWebhookLoader from '../add-webhook/components/add-webhook-loader';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { WebhookStatus } from '../../../../enums/webhook';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import RestrictionModal from '../webhook-list/components/restriction-modal';

// Enum defining possible tab values
const WebhookTabsEnum = {
  Details: 'details',
  Logs: 'logs',
};

const WebhookDetailAndLogs: React.FC<IProps> = ({
  webhook,
  webhookTestResult,
  getWebhookRequestStatus,
  getWebhookRequestError,
  sendGetWebhookRequest,
  resetGetWebhookRequest,
  updateWebhookData,
  resetWebhookData,

  sendUpdateWebhookStatusRequest,
  resetUpdateWebhookStatusRequest,
  updateWebhookStatusRequestStatus,
  updateWebhookStatusRequestError,

  updateWebhookRequestStatus,

  showLoading,
  hideLoading,
}) => {
  const history = useHistory();

  const { webhookId, tab: tabFromParams } = useParams<{
    webhookId: string;
    tab: string;
  }>();

  const [activeTab, setActiveTab] = useState(WebhookTabsEnum.Details);
  const [showRestrictionErrorModal, setShowRestrictionErrorModal] = useState(
    false,
  );

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === WebhookTabsEnum.Details) {
      history.push(`/settings/webhook/${webhookId}/details`);
    } else if (tab === WebhookTabsEnum.Logs) {
      history.push(`/settings/webhook/${webhookId}/logs`);
    }
  };

  const onWebhookStatusToggle = () => {
    if (hasPermission(Permissions.WEBHOOKS_WRITE)) {
      const showErrorMsg = () =>
        toaster.error(
          'Please ensure that the webhook test response is successful before activating.',
          { theme: Theme.New },
        );

      if (webhookTestResult) {
        if (webhookTestResult?.failed) {
          showErrorMsg();
        }
      } else if (webhook?.failed) {
        showErrorMsg();
      } else {
        sendUpdateWebhookStatusRequest({
          webhookId,
          webhookStatus:
            webhook?.status === WebhookStatus.Active
              ? WebhookStatus.Paused
              : WebhookStatus.Active,
        });
      }
    } else {
      setShowRestrictionErrorModal(true);
    }
  };

  useEffect(() => {
    if (tabFromParams) {
      if (
        tabFromParams !== activeTab &&
        tabFromParams === WebhookTabsEnum.Details
      ) {
        setActiveTab(tabFromParams);
        history.push(`/settings/webhook/${webhookId}/details`);
      } else if (
        tabFromParams !== activeTab &&
        tabFromParams === WebhookTabsEnum.Logs
      ) {
        setActiveTab(tabFromParams);
        history.push(`/settings/webhook/${webhookId}/logs`);
      }
    } else {
      history.push(`/settings/webhook/${webhookId}/details`);
    }
  }, [tabFromParams]);

  useEffect(() => {
    sendGetWebhookRequest(webhookId);

    return () => {
      resetWebhookData();
    };
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateWebhookRequestStatus,
      onSuccess: () => {
        sendGetWebhookRequest(webhookId);
      },
    });
  }, [updateWebhookRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateWebhookStatusRequestStatus,
      onSuccess: () => {
        resetUpdateWebhookStatusRequest();
        toaster.success(
          `Webhook has been ${
            webhook?.status === WebhookStatus.Paused ? 'activated' : 'paused'
          } successfully`,
          {
            theme: Theme.New,
          },
        );

        updateWebhookData({
          status:
            webhook?.status === WebhookStatus.Active
              ? WebhookStatus.Paused
              : WebhookStatus.Active,
        });
      },
      onFailed: () => {
        resetUpdateWebhookStatusRequest();
        executeOnErrorWithErrorCheck({
          error: updateWebhookStatusRequestError,
          onError: () => {
            toaster.error(updateWebhookStatusRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [updateWebhookStatusRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getWebhookRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        hideLoading();
        resetGetWebhookRequest();
      },
      onFailed: () => {
        resetGetWebhookRequest();
        executeOnErrorWithErrorCheck({
          error: getWebhookRequestError,
          onError: () => {
            toaster.error(getWebhookRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [getWebhookRequestStatus]);

  const isGetWebhookRequestStatusPending = getIsRequestPending(
    getWebhookRequestStatus,
  );

  return (
    <>
      <AddWebhookHeader
        label="Webhook"
        className="webhook-details-and-logs-header"
        showActivePausedToggle
        webhookStatus={webhook?.status}
        onWebhookStatusToggle={onWebhookStatusToggle}
        isLoading={getIsRequestPending(updateWebhookStatusRequestStatus)}
      />

      <Tabs
        activeKey={activeTab}
        prefixCls="bs-tabs"
        className="bs-tabs-small webhook-details-and-logs-tabs"
        onChange={handleTabChange}
      >
        <TabPane tab="Details" key={WebhookTabsEnum.Details}>
          {isGetWebhookRequestStatusPending ? (
            <AddWebhookLoader />
          ) : (
            <AddWebhook hideHeader defaultWebhook={webhook} action="update" />
          )}
        </TabPane>
        <TabPane tab="Logs" key={WebhookTabsEnum.Logs}>
          <WebhookLogs />
        </TabPane>
      </Tabs>

      <RestrictionModal
        show={showRestrictionErrorModal}
        onClose={() => {
          setShowRestrictionErrorModal(false);
        }}
      />
    </>
  );
};

export default WebhookDetailAndLogs;
