import React from 'react';

export const Connectivity = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_34800_126375)">
      <path
        d="M20.835 18.0489C20.835 18.0489 30.4303 25.3304 45.0176 11.7152C57.9725 -0.37602 68.5505 18.7013 68.6245 27.8371C68.7204 39.6715 55.6695 49.1408 62.0031 56.9139C68.3367 64.6869 49.4427 77.5228 39.2599 66.4142C26.5928 52.5955 23.1611 63.8232 15.9409 63.8232C10.7589 63.8232 0.119689 50.9472 7.30422 41.3678C13.3499 33.3069 10.0527 30.6317 8.45598 27.8371C6.15268 23.8066 11.6226 12.8669 20.835 18.0489Z"
        fill="#EFF6FF"
      />
      <path
        d="M62.1532 33.24C62.5538 33.3888 62.9916 33.3987 63.4082 33.3031C65.1533 32.9026 71.154 31.8419 70.1112 36.4919C69.8842 37.4145 66.3697 42.5811 71.3747 44.4672C71.7414 44.6071 72.1293 44.6835 72.5217 44.6931C74.0627 44.7248 77.1164 44.361 78.3944 43.06C79.9383 41.4884 78.2516 42.2337 78.2516 42.2337C78.2516 42.2337 72.3822 44.795 70.309 42.0776C70.0783 41.7753 69.9314 41.3361 69.8893 40.9581C69.8163 40.3026 70.1074 39.4364 70.4897 38.6824C71.0072 37.6619 73.4914 33.0713 68.0977 31.734C66.8552 31.4381 65.0506 31.1723 62.7236 32.152C61.3663 32.7234 61.6669 33.0593 62.1532 33.24Z"
        fill="#BDD6F9"
      />
      <path
        d="M62.9454 31.174L60.696 31.8112C59.9195 32.0312 59.4683 32.8389 59.6883 33.6154L59.7727 33.9134C59.9926 34.6899 60.8004 35.141 61.5769 34.9211L63.8263 34.2839C64.6028 34.064 65.054 33.2562 64.8341 32.4797L64.7497 32.1818C64.5297 31.4053 63.7219 30.9541 62.9454 31.174Z"
        fill="#DBEAFE"
      />
      <path
        d="M46.4413 32.5157L37.3784 35.0829C36.904 35.2173 36.6284 35.7107 36.7628 36.185L36.7898 36.2804C36.9242 36.7547 37.4176 37.0303 37.8919 36.8959L46.9549 34.3288C47.4292 34.1944 47.7048 33.701 47.5704 33.2267L47.5434 33.1313C47.409 32.657 46.9156 32.3814 46.4413 32.5157Z"
        fill="#DBEAFE"
      />
      <path
        d="M48.356 39.274L39.293 41.8412C38.8187 41.9756 38.5431 42.469 38.6775 42.9433L38.7045 43.0387C38.8388 43.513 39.3323 43.7886 39.8066 43.6542L48.8696 41.0871C49.3439 40.9527 49.6195 40.4593 49.4851 39.985L49.4581 39.8896C49.3238 39.4153 48.8303 39.1397 48.356 39.274Z"
        fill="#DBEAFE"
      />
      <path
        d="M57.381 28.9113L50.0356 30.992C49.087 31.2607 48.5358 32.2475 48.8045 33.1962L50.7026 39.8969C50.9713 40.8455 51.9581 41.3967 52.9068 41.128L60.2521 39.0473C61.2007 38.7786 61.7519 37.7918 61.4832 36.8432L59.5852 30.1425C59.3165 29.1938 58.3296 28.6426 57.381 28.9113Z"
        fill="#BFDBFE"
      />
      <path
        d="M50.8509 30.0905L43.5056 32.1712C42.557 32.4399 42.0058 33.4267 42.2745 34.3754L44.5389 42.3696C44.8076 43.3182 45.7945 43.8694 46.7431 43.6007L54.0885 41.52C55.0371 41.2513 55.5883 40.2645 55.3196 39.3159L53.0551 31.3216C52.7864 30.373 51.7996 29.8218 50.8509 30.0905Z"
        fill="#BFDBFE"
      />
      <path
        d="M52.0958 28.6085L52.0382 28.6249C51.4057 28.804 51.0383 29.4619 51.2174 30.0943L54.3897 41.2933C54.5688 41.9258 55.2267 42.2932 55.8591 42.1141L55.9167 42.0978C56.5491 41.9186 56.9166 41.2607 56.7374 40.6283L53.5652 29.4293C53.3861 28.7969 52.7282 28.4294 52.0958 28.6085Z"
        fill="#DBEAFE"
      />
      <path
        d="M2.0927 38.4526C2.0927 38.4526 10.6878 36.0372 9.43997 41.6022C9.22327 42.4827 6.10379 47.0166 9.81956 49.1935C10.6174 49.6609 11.5382 49.868 12.4628 49.8631C14.1206 49.8545 16.7488 49.5354 17.9294 48.3337C19.5118 46.7229 17.7831 47.4868 17.7831 47.4868C17.7831 47.4868 12.8468 49.5173 10.3072 47.8987C9.44008 47.346 9.04702 46.276 9.2825 45.275C9.40286 44.7777 9.58614 44.2979 9.8279 43.8471C10.3802 42.8126 12.9044 38.0962 7.37632 36.7256C6.10287 36.4223 4.25328 36.1499 1.86841 37.154C-0.51646 38.1581 2.0927 38.4526 2.0927 38.4526Z"
        fill="#BDD6F9"
      />
      <path
        d="M17.232 46.4814L15.0996 47.2795C14.3635 47.555 13.9901 48.3751 14.2656 49.1112L14.3713 49.3936C14.6468 50.1297 15.4668 50.5031 16.203 50.2276L18.3354 49.4295C19.0715 49.154 19.4449 48.334 19.1694 47.5978L19.0637 47.3154C18.7882 46.5793 17.9681 46.2059 17.232 46.4814Z"
        fill="#DBEAFE"
      />
      <path
        d="M21.3933 41.6409L17.5049 43.0962C16.5992 43.4352 16.1398 44.4442 16.4787 45.3499L18.3537 50.3596C18.6927 51.2653 19.7017 51.7247 20.6073 51.3858L24.4957 49.9305C25.4014 49.5915 25.8608 48.5825 25.5219 47.6768L23.6469 42.6671C23.3079 41.7614 22.299 41.302 21.3933 41.6409Z"
        fill="#BFDBFE"
      />
      <path
        d="M27.8666 37.7839L22.0679 39.9541C21.1622 40.2931 20.7028 41.3021 21.0417 42.2078L23.8982 49.8401C24.2372 50.7458 25.2462 51.2052 26.1519 50.8662L31.9505 48.696C32.8562 48.357 33.3157 47.348 32.9767 46.4424L30.1202 38.81C29.7812 37.9043 28.7722 37.4449 27.8666 37.7839Z"
        fill="#BFDBFE"
      />
      <path
        d="M29.095 36.2031L29.04 36.2236C28.4362 36.4496 28.13 37.1223 28.3559 37.7261L32.3576 48.4181C32.5836 49.0219 33.2562 49.3282 33.86 49.1022L33.915 49.0816C34.5188 48.8557 34.8251 48.183 34.5991 47.5792L30.5975 36.8872C30.3715 36.2834 29.6988 35.9771 29.095 36.2031Z"
        fill="#DBEAFE"
      />
      <path
        d="M37.3471 27.4657C37.1638 27.4453 36.9955 27.3546 36.8777 27.2128C36.7599 27.0709 36.7017 26.8888 36.7155 26.7049L37.0953 19.7682C37.1179 19.3538 37.4678 19.039 37.8649 19.0757L38.4182 19.1269C38.8153 19.1635 39.0996 19.5367 39.044 19.9482L38.1108 26.8336C38.0893 27.017 37.998 27.1851 37.8557 27.3028C37.7134 27.4206 37.5312 27.4789 37.3471 27.4657Z"
        fill="#BFDBFE"
      />
      <path
        d="M34.0679 28.0637C34.0109 28.0805 33.9511 28.0858 33.892 28.0795C33.833 28.0732 33.7757 28.0552 33.7236 28.0268C33.6714 27.9983 33.6254 27.9598 33.5881 27.9136C33.5508 27.8673 33.523 27.8141 33.5062 27.7571L32.1501 23.4421C32.1106 23.3194 32.1215 23.1861 32.1802 23.0713C32.239 22.9566 32.3409 22.8699 32.4635 22.8302L32.8146 22.7242C32.8749 22.7067 32.9381 22.7018 33.0003 22.7098C33.0626 22.7179 33.1225 22.7388 33.1762 22.7712C33.23 22.8036 33.2764 22.8468 33.3126 22.8981C33.3488 22.9493 33.374 23.0075 33.3866 23.069L34.3918 27.49C34.4198 27.6088 34.402 27.7339 34.342 27.8402C34.282 27.9465 34.1841 28.0263 34.0679 28.0637Z"
        fill="#BFDBFE"
      />
      <path
        d="M32.2381 30.5521C31.9951 30.8531 31.5064 30.8496 31.1302 30.5439L24.4512 25.1174C24.0523 24.7933 23.9346 24.2568 24.1924 23.9376L24.5516 23.4925C24.8094 23.1732 25.3389 23.1994 25.7164 23.5501L32.0361 29.4217C32.392 29.7524 32.4811 30.251 32.2381 30.5521Z"
        fill="#BFDBFE"
      />
      <path
        d="M40.3573 52.5103C40.5285 52.5765 40.6673 52.7064 40.7445 52.8728C40.8218 53.0392 40.8315 53.2291 40.7715 53.4025L38.6438 59.9778C38.5167 60.3706 38.1002 60.5843 37.7276 60.4484L37.2082 60.2587C36.8356 60.1225 36.6567 59.6912 36.8149 59.3097L39.4619 52.9242C39.5291 52.7531 39.6597 52.6146 39.8265 52.5375C39.9934 52.4604 40.1835 52.4506 40.3573 52.5103Z"
        fill="#BFDBFE"
      />
      <path
        d="M43.6633 52.7677C43.7224 52.7661 43.7812 52.7761 43.8364 52.7973C43.8917 52.8184 43.9422 52.8502 43.9851 52.8908C44.0281 52.9314 44.0626 52.9801 44.0867 53.0341C44.1108 53.0881 44.1241 53.1463 44.1257 53.2053L44.3338 57.7003C44.3405 57.8283 44.2962 57.9538 44.2106 58.0493C44.1249 58.1447 44.0049 58.2022 43.8768 58.2093L43.5121 58.222C43.4497 58.2236 43.3876 58.2122 43.3298 58.1886C43.272 58.165 43.2197 58.1297 43.1763 58.0849C43.1328 58.0401 43.0991 57.9867 43.0773 57.9282C43.0555 57.8697 43.046 57.8073 43.0495 57.745L43.2062 53.2374C43.2094 53.116 43.2582 53.0002 43.343 52.9132C43.4277 52.8262 43.542 52.7743 43.6633 52.7677Z"
        fill="#BFDBFE"
      />
      <path
        d="M46.0559 50.8394C46.3662 50.6115 46.8353 50.7392 47.1195 51.1287L52.1649 58.0449C52.4663 58.458 52.4432 59.0038 52.1139 59.2455L51.6552 59.5823C51.3262 59.8239 50.8236 59.6642 50.5496 59.231L45.9626 51.9777C45.7047 51.5695 45.7457 51.0672 46.0559 50.8394Z"
        fill="#BFDBFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_34800_126375">
        <rect
          width="80"
          height="80"
          fill="white"
          transform="translate(0 0.233887)"
        />
      </clipPath>
    </defs>
  </svg>
);
