import React from 'react';
import { SortOrder as ReactTableSortOrder } from 'react-bootstrap-table-next';

import type { IProps } from './types';

import Table from '../../../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../../../shared/design-system/components/organisms/table/types';
import { getDoNotContactListColumns } from './utils/get-do-not-contact-list-columns';

import { DoNotContactListsSortBy } from '../../../../../../enums/do-not-contact-list';
import { DoNotContactList } from '../../../../../../types/do-not-contact-list';
import history from '../../../../../../../../shared/history';
import { SortOrder } from '../../../../../../../../shared/enums/order';
import hasPermission from '../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../shared/utils/access-control/enums/permissions';

const DoNotContactListTable: React.FC<IProps> = ({
  doNotContactLists,
  doNotContactListsPaginationOptions,
  filters,
  onFiltersChange,
  onAction,
  isLoading,
}) => {
  // handle table pagination change
  const onPaginationOptionsChange = ({ page, limit }) => {
    if (filters.page === page && filters.limit === limit) {
      return;
    }
    onFiltersChange({ page, limit });
  };

  // handle table sorting
  const onSort = (key: DoNotContactListsSortBy, order: ReactTableSortOrder) => {
    const newFilters = {
      sortByKey: key,
      order: order === 'asc' ? SortOrder.ASC : SortOrder.DESC,
    };

    if (
      newFilters.sortByKey !== filters.sortByKey ||
      newFilters.order !== filters.order
    ) {
      onFiltersChange(newFilters);
    }
  };

  return (
    <div className="do-not-contact-list-table-wrapper">
      <Table
        columns={getDoNotContactListColumns({
          onSort,
          onAction,
          isCellClickable: hasPermission(Permissions.DNC_LIST_READ),
        })}
        data={doNotContactLists}
        sort={{
          dataField: filters.sortByKey,
          order: filters.order === SortOrder.ASC ? 'asc' : 'desc',
        }}
        paginationOptions={{
          options: {
            limit: doNotContactListsPaginationOptions?.itemsPerPage,
            page: doNotContactListsPaginationOptions?.currentPage,
            totalElements: doNotContactListsPaginationOptions?.totalItems,
          },
        }}
        tableWrapperClasses="do-not-contact-list-table"
        {...(hasPermission(Permissions.DNC_LIST_READ) && {
          onRowClickHandler: (row: DoNotContactList) => {
            history.push({
              pathname: `/settings/dnc-list/${row.id}`,
              state: {
                row,
              },
            });
          },
        })}
        isLoading={isLoading}
        pagination={PaginationShowHide.SHOW}
        isNewPagination={true}
        onPaginationOptionsChange={onPaginationOptionsChange}
      />
    </div>
  );
};

export default DoNotContactListTable;
