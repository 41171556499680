import validator from 'validator';

export const validate = (name, value) => {
  switch (name) {
    case 'fieldName':
      if (validator.isEmpty(value)) {
        return 'provide_field_name';
      }
      if (value.includes(',')) {
        return 'invalid_outcome_name';
      }
      break;
    default:
      return '';
  }

  return '';
};
