import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import Suspense from '../../../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../../../shared/components/error-boundary-wrapper';

import DoNotContactList from './components/do-not-contact-list';
import DoNotContactListDetails from './components/do-not-contact-list-details';

const DoNotContact: React.FC = () => (
  <Container fluid className="p-0 d-flex flex-column overflow-hidden h-100">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/settings/dnc-list" component={DoNotContactList} />
          <Route
            exact
            path="/settings/dnc-list/:listId"
            component={DoNotContactListDetails}
          />
          <Redirect to="/settings/dnc-list" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default DoNotContact;
