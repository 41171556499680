import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { captureException } from '@sentry/minimal';
import Button from '../design-system/components/atoms/button';
import { Images } from '../app-constants';
import ImageIcon from '../components/images/image-icon';
import { accessibleOnClick } from '../utils/accessible-on-click';
import { openChatSupport } from '../utils/open-chat-support';
import { version } from '../../../package.json';

const ErrorFallback: React.FC = () => {
  const { t } = useTranslation();

  const openBeacon = () => {
    openChatSupport();
  };

  const onRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    captureException(new Error(`Broken Screen version:${version}`));
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center vh-100">
      <span className="semibold-3 gray-txt-17">
        {t('messages.error_boundary_title')}
      </span>
      <div className="mt-5">
        <ImageIcon src={Images.Broken} />
      </div>
      <span className="mt-5 regular-3 font-weight-500 gray-txt-15">
        {t('messages.error_boundary_sub_text_one')}
      </span>
      <div className="mt-2">
        <span className="regular-3 font-weight-500 gray-txt-15">
          {t('messages.error_boundary_sub_text_two')}
        </span>
        <span
          {...accessibleOnClick(openBeacon)}
          className="ml-1 regular-3 font-weight-500 blue-txt-11 pointer"
        >
          {t('labels.Contact_Support')}.
        </span>
      </div>
      <Button
        variant={Button.Variant.Primary}
        onClick={onRefresh}
        className="mt-4"
      >
        {t('messages.return_to_application')}
      </Button>
    </div>
  );
};

export default ErrorFallback;
