import React from 'react';
import { useHistory } from 'react-router';
import { Button, SkeletonLoading } from '@saleshandy/design-system';
import { ChevronLeft, Pencil } from '@saleshandy/icons';

import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import {
  OverlayPopover,
  Placement,
  TriggerType,
} from '../../../../../../../../shared/design-system/components/overlay';
import TruncatedTextWithTooltip from '../../../../../../../../shared/components/truncated-text-with-tooltip';
import { GlobalSettings } from '../../../../../../../../shared/components/global-settings-wrapper';
import hasPermission from '../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../shared/utils/access-control/enums/permissions';
import { isAgency } from '../../../../../../../../shared/utils/user-details';

type IProps = {
  listName: string;
  emailsCount: number;
  domainsCount: number;
  associatedClient: string;
  onEdit: VoidFunction;
  isLoading: boolean;
  onShowImportDoNotContactListModal: VoidFunction;
};

const DoNotContactListDetailsHeader: React.FC<IProps> = ({
  listName,
  emailsCount,
  domainsCount,
  associatedClient,
  onEdit,
  isLoading,
  onShowImportDoNotContactListModal,
}) => {
  const history = useHistory();

  const isClientColVisible =
    hasPermission(Permissions.CLIENT_READ) && isAgency();

  const renderListCount = (label: string, count: number) => (
    <div className="do-not-contact-list-details-count">
      <p>{label}</p>
      <p>{count}</p>
    </div>
  );

  const renderClientName = () => {
    if (isClientColVisible) {
      if (isLoading) {
        return (
          <div className="skeleton-loader">
            <SkeletonLoading width={100} height={16} />
          </div>
        );
      }

      return <p className="list-associated-client">{associatedClient}</p>;
    }

    return <></>;
  };

  return (
    <GlobalSettings.Header className="do-not-contact-list-details--header">
      <GlobalSettings.HeaderLeft>
        <div
          className="go-back-btn"
          {...accessibleOnClick(() => {
            history.push(`/settings/dnc-list`);
          })}
        >
          <ChevronLeft />
        </div>

        <div className="list-details">
          {isLoading ? (
            <div className="skeleton-loader">
              <SkeletonLoading width={200} height={20} />
            </div>
          ) : (
            <div className="list-details-inner">
              <TruncatedTextWithTooltip
                length={50}
                string={listName}
                textClassName="list-name"
                tooltipClassName="tooltip-xxl"
              />

              <OverlayPopover
                className="p-0 do-not-contact-list-details-popover"
                content={
                  <div className="do-not-contact-list-details-popover-content">
                    {renderListCount('Domains', domainsCount)}
                    {renderListCount('Emails', emailsCount)}
                  </div>
                }
                trigger={TriggerType.Hover}
                placement={Placement.BottomStart}
              >
                <div className="do-not-contact-list-details-popover-badge">
                  <span>{domainsCount + emailsCount}</span>
                </div>
              </OverlayPopover>

              {hasPermission(Permissions.DNC_UPDATE) && (
                <div className="edit-list-btn" {...accessibleOnClick(onEdit)}>
                  <Pencil />
                </div>
              )}
            </div>
          )}
          {renderClientName()}
        </div>
      </GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderRight>
        <Button onClick={onShowImportDoNotContactListModal}>Add via CSV</Button>
      </GlobalSettings.HeaderRight>
    </GlobalSettings.Header>
  );
};

export default DoNotContactListDetailsHeader;
