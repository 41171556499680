import React from 'react';
import { Book, ChevronLeft } from '@saleshandy/icons';
import { useHistory } from 'react-router';
import { Spinner } from 'reactstrap';

import Switch from '../../../../../../../shared/design-system/components/atoms/switch';
import { Placement } from '../../../../../../../shared/design-system/components/overlay';

import { supportUrls } from '../../../../../../../shared/utils/urls';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { WebhookStatus } from '../../../../../enums/webhook';
import { GlobalSettings } from '../../../../../../../shared/components/global-settings-wrapper';
import LinkBadge from '../../../../../../../shared/design-system/components/atoms/link-badge';

type IProps = {
  className?: string;
  label?: string;
  showActivePausedToggle?: boolean;
  webhookStatus?: WebhookStatus;
  onWebhookStatusToggle?: VoidFunction;
  isLoading?: boolean;
};

const AddWebhookHeader: React.FC<IProps> = ({
  className = '',
  label = 'Add Webhook',
  showActivePausedToggle = false,
  webhookStatus,
  onWebhookStatusToggle,
  isLoading = false,
}) => {
  const history = useHistory();

  const onGoBack = () => {
    history.push('/settings/webhook');
  };

  return (
    <div className={`webhook--header ${className}`}>
      <GlobalSettings.Header>
        <GlobalSettings.HeaderLeft>
          <div className="webhook-details-header">
            <div className="webhook-back-btn" {...accessibleOnClick(onGoBack)}>
              <ChevronLeft />
            </div>
          </div>
          <GlobalSettings.HeaderTitle title={label} />
          <LinkBadge
            label="View setup guide"
            url={supportUrls.webhookSetupGuideURL}
            Icon={<Book />}
          />
        </GlobalSettings.HeaderLeft>

        {showActivePausedToggle && webhookStatus !== undefined && (
          <div className="webhook-details-status">
            <Switch
              checked={webhookStatus === WebhookStatus.Active}
              onChange={onWebhookStatusToggle}
              placement={Placement.Bottom}
              disabled={isLoading}
            />
            {isLoading ? (
              <Spinner animation="border" className="webhook-spinner" />
            ) : (
              <p>
                {webhookStatus === WebhookStatus.Active ? 'Active' : 'Paused'}
              </p>
            )}
          </div>
        )}
      </GlobalSettings.Header>
    </div>
  );
};

export default AddWebhookHeader;
