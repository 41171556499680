/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-cycle */
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Spinner } from 'reactstrap';
import { ExtendedOption, TransferProps } from './transfer';
import Checkbox from '../atoms/checkbox';
import TransferEmpty from './transfer-empty';
import { accessibleOnClick } from '../../../utils/accessible-on-click';

type OptionListProps<T> = Pick<
  TransferProps<T>,
  | 'multiSelect'
  | 'options'
  | 'optionRenderer'
  | 'filterOption'
  | 'optionFilterProp'
  | 'selectedOptionKey'
  | 'onSelect'
  | 'onDeselect'
  | 'onChange'
  | 'showOptionsSeparator'
  | 'showEmpty'
  | 'emptyText'
  | 'customFilterOption'
  | 'customEmptyElement'
  | 'showListSeparator'
  | 'listSeparatorOptions'
> & {
  searchText: string;
  isLoading: boolean;
  clickListenerOn: 'row' | 'checkbox';
};

const OptionList = <T extends ExtendedOption>({
  multiSelect,
  options: propOptions,
  optionRenderer,
  filterOption: propFilterOption,
  customFilterOption,
  optionFilterProp,
  selectedOptionKey,
  onSelect,
  onDeselect,
  onChange,
  showOptionsSeparator,
  searchText,
  showEmpty,
  emptyText,
  customEmptyElement,
  isLoading,
  clickListenerOn = 'row',
  showListSeparator = false,
  listSeparatorOptions: propListSeparatorOptions,
}: OptionListProps<T>) => {
  const defaultFilterOption = (value, option, options, searchProp) =>
    !searchProp ||
    option[searchProp].toLowerCase().includes(value.toLowerCase().trim());

  const options = useMemo(() => {
    const filterOptions = propFilterOption || defaultFilterOption;
    const searchProp = optionFilterProp;

    if (customFilterOption) {
      return customFilterOption(propOptions, searchText);
    }

    return propOptions.filter((option) =>
      filterOptions(searchText, option, propOptions, searchProp),
    );
  }, [
    customFilterOption,
    propFilterOption,
    optionFilterProp,
    propOptions,
    searchText,
  ]);

  const listSeparatorOptions = useMemo(() => {
    const filterOptions = propFilterOption || defaultFilterOption;
    const searchProp = optionFilterProp;

    const newPropOptions = [];
    if (propListSeparatorOptions && propListSeparatorOptions.length) {
      newPropOptions.push(...propListSeparatorOptions);
    }
    if (customFilterOption) {
      return customFilterOption(newPropOptions, searchText);
    }

    return newPropOptions.filter((option) =>
      filterOptions(searchText, option, newPropOptions, searchProp),
    );
  }, [
    customFilterOption,
    propFilterOption,
    optionFilterProp,
    propListSeparatorOptions,
    searchText,
  ]);

  // eslint-disable-next-line no-nested-ternary
  const selectedOptionKeyArr = selectedOptionKey
    ? Array.isArray(selectedOptionKey)
      ? selectedOptionKey
      : [selectedOptionKey]
    : [];

  const isOptionSelected = (key: T['key']) =>
    selectedOptionKeyArr.includes(key);

  const optionClickHandler = (option: T) => {
    const isSelected = isOptionSelected(option.key);
    const finalPropOptions = propOptions;
    if (propListSeparatorOptions && propListSeparatorOptions?.length) {
      finalPropOptions.push(...propListSeparatorOptions);
    }
    let selectedOptionKeyToEmit: T['key'][];
    if (multiSelect) {
      if (isSelected) {
        onDeselect?.(option);
        selectedOptionKeyToEmit = selectedOptionKeyArr.filter(
          (key) => key !== option.key,
        );
      } else {
        onSelect?.(option);
        selectedOptionKeyToEmit = selectedOptionKeyArr.concat([option.key]);
      }
    } else {
      const prevSelectedOption = finalPropOptions.find(
        (o) => o.key === selectedOptionKeyArr[0],
      );
      onSelect?.(option);
      if (prevSelectedOption) {
        // only if prev selected option was there.
        onDeselect?.(prevSelectedOption);
      }
      // below two lined can be simplified into one. i.e. just create a
      // new array containing the selected option's key.

      selectedOptionKeyToEmit = selectedOptionKeyArr.filter(
        (key) =>
          (typeof key === 'number' &&
            key >= 0 &&
            key !== prevSelectedOption?.key) ||
          (typeof key === 'string' && key !== prevSelectedOption?.key),
      );
      selectedOptionKeyToEmit = selectedOptionKeyToEmit.concat([option.key]);
    }
    const selectedOptionToEmit = selectedOptionKeyToEmit
      .map((key) => finalPropOptions.find((o) => o.key === key))
      .filter(Boolean);
    onChange?.(selectedOptionToEmit);
  };

  if (isLoading) {
    return (
      <div className="loader-wrapper d-flex justify-content-center align-items-center">
        <Spinner className="spinner--blue" animation="border" />
      </div>
    );
  }

  if (options.length === 0 && listSeparatorOptions.length === 0 && showEmpty) {
    return (
      <TransferEmpty customEmptyElement={customEmptyElement} text={emptyText} />
    );
  }

  const optionList = options.map((option) => {
    const isSelected = isOptionSelected(option.key);
    const optionClass = classNames([
      {
        active: isSelected && !multiSelect,
        separator: showOptionsSeparator,
        disabled: option?.isDisabled,
      },
    ]);
    let optionJSX = optionRenderer(option);
    if (multiSelect) {
      optionJSX = (
        <>
          <div
            className="checkbox-wrapper"
            {...(clickListenerOn === 'checkbox' &&
              accessibleOnClick(() => optionClickHandler(option)))}
          >
            <Checkbox
              key={
                isSelected ? `checked-${option.key}` : `unchecked-${option.key}`
              }
              checked={isSelected || option.isDisabled}
              disabled={option?.isDisabled}
            />
          </div>
          <div className="option-content">{optionJSX}</div>
        </>
      );
    }
    return (
      <li
        key={option.key}
        className={optionClass}
        {...(clickListenerOn === 'row' &&
          accessibleOnClick(() => optionClickHandler(option)))}
      >
        {optionJSX}
      </li>
    );
  });

  const getExtraOptionList = (list: JSX.Element[]) => {
    const extraOptionList: JSX.Element[] = [<div>{list}</div>];
    if (showListSeparator && listSeparatorOptions?.length > 0) {
      const newOptionList = listSeparatorOptions.map((option) => {
        const isSelected = isOptionSelected(option.key);
        const optionClass = classNames([
          {
            active: isSelected && !multiSelect,
            separator: showOptionsSeparator,
            disabled: option?.isDisabled,
          },
        ]);
        let optionJSX = optionRenderer(option);
        if (multiSelect) {
          optionJSX = (
            <>
              <div
                className="checkbox-wrapper"
                {...(clickListenerOn === 'checkbox' &&
                  accessibleOnClick(() => optionClickHandler(option)))}
              >
                <Checkbox
                  key={
                    isSelected
                      ? `checked-${option.key}`
                      : `unchecked-${option.key}`
                  }
                  checked={isSelected || option.isDisabled}
                  disabled={option?.isDisabled}
                />
              </div>
              <div className="option-content">{optionJSX}</div>
            </>
          );
        }
        return (
          <li
            key={option.key}
            className={optionClass}
            {...(clickListenerOn === 'row' &&
              accessibleOnClick(() => optionClickHandler(option)))}
          >
            {optionJSX}
          </li>
        );
      });
      const separatorList = <div className="transfer-separator" />;
      if (options.length) {
        extraOptionList.push(separatorList);
      }
      extraOptionList.push(
        <div className="transfer-extra-content">{newOptionList}</div>,
      );
    }
    return extraOptionList;
  };

  return <ul className="transfer-list">{getExtraOptionList(optionList)}</ul>;
};

export default OptionList;
