import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';
import { OverlayTooltip } from '../../../../../../../../shared/design-system/components/overlay';

type IProps = {
  isButtonDisabled: boolean;
  isButtonLoading: boolean;
  handleResendOtp: VoidFunction;
  showError: boolean;
};

const ResendOTPButton: React.FC<IProps> = ({
  isButtonDisabled,
  isButtonLoading,
  handleResendOtp,
  showError,
}) => {
  const [counter, setCounter] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    let timer = null;

    if (counter > 0) {
      // Start the countdown when the component mounts
      timer = setInterval(() => {
        setCounter((prevCounter) => prevCounter - 1);
      }, 1000);
    } else {
      // Disable the button and clear the timer when the countdown reaches 0
      setIsDisabled(false);
      if (timer) {
        clearInterval(timer);
      }
    }

    return () => {
      // Clear the timer when the component unmounts
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [counter]);

  const handleResendClick = () => {
    // Reset the counter and enable the button
    setCounter(60);
    setIsDisabled(true);
    handleResendOtp();
  };

  const btn = (
    <Button
      className="p-0 link-cta-btn link-inline ml-1"
      variant="link"
      disabled={isDisabled || isButtonDisabled}
      isLoading={isButtonLoading}
      loadingText="Resending..."
      onClick={handleResendClick}
    >
      Resend {counter > 0 && !showError ? `(${counter})` : ''}
    </Button>
  );

  return showError ? (
    <OverlayTooltip
      className="tooltip-xl"
      text="Maximum verification code requests reached. Please try again later."
    >
      {btn}
    </OverlayTooltip>
  ) : (
    btn
  );
};

export default ResendOTPButton;
