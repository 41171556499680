import React from 'react';
import Button from '../../../../../../shared/design-system/ui/button';
import { SuccessChecks } from '../../../../../../shared/svg';
import { redirectWithoutRefresh } from '../../../../../../shared/utils';

const ChangePasswordSuccess: React.FC = () => (
  <div className="auth-wrapper">
    <div className="auth-container gap-0">
      <div className="auth-container--icon mb-3">
        <SuccessChecks />
      </div>
      <h1 className="auth-container--title mt-1 mb-2">
        Password reset successful
      </h1>
      <p className="auth-container--sub-title">
        Awesome! You’ve successfully reset your password.
      </p>
      <div className="bottom-navigation">
        <Button isFullWidth onClick={() => redirectWithoutRefresh('/login')}>
          Return to Login
        </Button>
      </div>
    </div>
  </div>
);

export default ChangePasswordSuccess;
