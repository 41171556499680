import useSWR from 'swr';
import { getTasksListFetcher } from '../utils/swr-helper';
import { GetTasksParams } from '../../../utils/types';

export default function useTasks(params: GetTasksParams) {
  const { data, isLoading, isValidating, mutate } = useSWR(
    [`/tasks/${params.status}`, params.pageNumber],
    () => getTasksListFetcher(params),
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
      initialData: { count: 0, data: [] },
    },
  );

  return {
    tasks: data || { count: 0, data: [] },
    isLoading,
    isValidating,
    mutateTasks: mutate,
  };
}
