import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../store/root-reducer';
import ResetPasswordCallback from './reset-password-callback';
import { verifyResetPasswordCodeRequest } from './reset-password-callback-slice';

const mapStateToProps = (state: RootState) => ({
  resetPasswordCallbackStatus: state.resetPasswordCallback.status,
});

export type ResetPasswordCallbackRouteProps = RouteComponentProps<{
  code: string;
}>;

const mapDispatchToProps = (
  dispatch,
  ownProps: ResetPasswordCallbackRouteProps,
) => ({
  sendVerifyResetPasswordCodeRequest: () =>
    dispatch(verifyResetPasswordCodeRequest(ownProps.match.params.code)),

  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  ResetPasswordCallbackRouteProps;

export default connector(ResetPasswordCallback);
