import React from 'react';
import { useTranslation } from 'react-i18next';
import { Trash } from '@saleshandy/icons';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { IProps } from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const DeleteCustomFieldModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  title,
  className,
  content,
  isRequestPending,
  showCloseIcon,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      className={`delete-modal ${className}`}
      titleContent={
        <div className="d-flex align-items-center">
          <Trash width={23} height={23} className="red-txt-12" />
          <div className="delete-title">{title}</div>
        </div>
      }
      showCloseIcon={showCloseIcon}
      onClose={onClose}
      cancelButtonText={t('labels.cancel')}
      submitButtonText={t('labels.delete')}
      isSubmitLoading={isRequestPending}
      isSubmitDisabled={isRequestPending}
      onSubmit={onSubmit}
      cancelButtonVarient={Button.Variant.Outlined}
      onHide={onClose}
    >
      <span className="regular-2 popover-arrow-color-txt">{content}</span>
    </Modal>
  );
};

export default DeleteCustomFieldModal;
