import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import Modal from '../../../shared/design-system/components/atoms/modal';
import { accessibleOnClick } from '../../../shared/utils/accessible-on-click';
import { isLeadFinderURL } from '../../leads/helpers/helper';
import {
  getWhitelabelCompanyName,
  isAgency,
} from '../../../shared/utils/user-details';
import { supportUrls } from '../../../shared/utils/urls';
import { getCurrentRoute } from '../../../shared/utils/get-current-route';

type IProps = {
  show: boolean;
  firstName: string;
  onViewSampleSequence?: VoidFunction;
  onCreateNewSequence?: VoidFunction;
  onClose: VoidFunction;
  updateWatchVideoProfileProgressStep: VoidFunction;
  isGettingStartedVideModalShowThroughIcon: boolean;
  isCreateSequenceRequestPending?: boolean;
  isViewSampleSequenceRequestPending?: boolean;
  isLeadFinderOnboarding?: boolean;
};

const WatchVideoModal: React.FC<IProps> = ({
  show,
  firstName,
  onViewSampleSequence,
  onCreateNewSequence,
  onClose,
  updateWatchVideoProfileProgressStep,
  isGettingStartedVideModalShowThroughIcon,
  isViewSampleSequenceRequestPending,
  isCreateSequenceRequestPending,
  isLeadFinderOnboarding = isLeadFinderURL(),
}) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const viewSampleSequenceBtnRef = useRef<HTMLButtonElement>();
  const isWhiteLabelVideo =
    getCurrentRoute(location) === '/settings/whitelabel';

  const getVideoLink = () => {
    if (isWhiteLabelVideo) {
      return supportUrls.whitelabelYoutubeURL;
    }

    if (isAgency()) {
      return supportUrls.agencyOnboardingVideoURL;
    }
    if (isLeadFinderOnboarding) {
      return supportUrls.leadOnboardingVideoURL;
    }

    return supportUrls.outreachOnboardingVideoURL;
  };

  const generateWelcomeText = () => {
    if (isGettingStartedVideModalShowThroughIcon) {
      if (firstName && firstName.length > 15) {
        return `Hi ${firstName.substring(0, 15)}...!👋, Welcome to Saleshandy`;
      }
      return `Hi ${firstName}!👋, Welcome to Saleshandy`;
    }

    return t('messages.learn_create_sequence');
  };

  const onSubmit = () => {
    history.push({
      search: 'videoskipped',
    });
    setTimeout(() => {
      history.push({
        search: '',
      });
    }, 2000);
    onViewSampleSequence();
  };

  const onCancel = () => {
    history.push({
      search: 'videoskipped',
    });
    setTimeout(() => {
      history.push({
        search: '',
      });
    }, 2000);
    onCreateNewSequence();
  };

  useEffect(() => {
    if (show && viewSampleSequenceBtnRef.current) {
      viewSampleSequenceBtnRef.current.focus();
    }
  }, [viewSampleSequenceBtnRef, show]);

  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://fast.wistia.net/assets/external/E-v1.js',
    );
    script.setAttribute('async', '');

    document.body.appendChild(script);
  }, []);

  return (
    <Modal
      show={show}
      submitButtonRef={viewSampleSequenceBtnRef}
      titleContent={generateWelcomeText()}
      submitButtonText={t('labels.view_sample_sequence')}
      submitButtonClassName="watch-video-modal-btn"
      onSubmit={onSubmit}
      showCloseIcon
      cancelButtonText={t('labels.create_new_sequence')}
      onCancel={onCancel}
      onClose={onClose}
      onHide={onClose}
      className="watch-video-modal"
      backdrop="static"
      // hideFooter={!isGettingStartedVideModalShowThroughIcon}
      isSubmitLoading={isViewSampleSequenceRequestPending}
      isSubmitDisabled={
        isViewSampleSequenceRequestPending || isCreateSequenceRequestPending
      }
      isCancelLoading={isCreateSequenceRequestPending}
      isCancelDisabled={
        isViewSampleSequenceRequestPending || isCreateSequenceRequestPending
      }
      hideHeader
      hideFooter
    >
      <div className="watch-video-modal-body">
        <div
          className="d-flex justify-content-center align-items-center"
          {...accessibleOnClick(updateWatchVideoProfileProgressStep)}
        >
          <iframe
            src={getVideoLink()}
            title="Final welcome video (1)"
            allow="autoplay; fullscreen"
            allowTransparency={true}
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            style={{
              border: 'none',
              width: '100%',
              height: '24rem',
              maxHeight: '700px',
            }}
          />
        </div>

        <div className="d-flex flex-column align-items-center watch-video-modal-text-container">
          <div className="d-flex flex-column text-center">
            <span className="semibold-5 popover-arrow-color-txt">
              {isWhiteLabelVideo
                ? `Setup your brand identity with ${
                    getWhitelabelCompanyName() || 'Saleshandy'
                  }`
                : `Welcome to ${getWhitelabelCompanyName() || 'Saleshandy'}!`}
            </span>
            <p className="regular-2 font-medium gray-txt-15">
              {isWhiteLabelVideo
                ? 'Unlock a Tailored Experience: Your Brand, Your Domain, Your Identity - All Seamlessly Integrated.'
                : 'Take 3 minutes now to save hours down the line by watching this video or else directly jump into the product.'}
            </p>
          </div>

          {isLeadFinderOnboarding || isWhiteLabelVideo ? (
            <div className="mt-2">
              <Button onClick={onClose}>
                {isWhiteLabelVideo ? 'Add your domain' : 'Start finding leads'}
              </Button>
            </div>
          ) : (
            <div className="mt-2 d-flex">
              <Button variant="secondary" onClick={onSubmit}>
                View sample sequence
              </Button>
              <Button onClick={onClose} className="ml-2">
                Let's Start
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default WatchVideoModal;
