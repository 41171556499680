import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import {
  createApiToken,
  getApiTokenList,
  revokeApiToken,
  getCobaltSessionToken,
  cobaltEventsSubscribe,
  getIntegrationAdminSetting,
  changeIntegrationSetting,
  generateIntegrationApiToken,
} from '../../helpers/api-token.api';
import {
  CreateApiTokenPayload,
  RevokeApiTokenPayload,
  SubscribedEventPayload,
} from './types';

export const getApiTokenListRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/getApiTokenList', async (args, thunkAPI) => {
  try {
    return await getApiTokenList();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createApiTokenRequest = createAsyncThunk<
  ResponseSuccess,
  CreateApiTokenPayload,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/createApiTokenRequest', async ({ label }, thunkAPI) => {
  try {
    return await createApiToken(label);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const revokeApiTokenRequest = createAsyncThunk<
  ResponseSuccess,
  RevokeApiTokenPayload,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/revokeApiToken', async ({ tokenId }, thunkAPI) => {
  try {
    return await revokeApiToken(tokenId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getCobaltSessionTokenRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/getCobaltSessionToken', async (args, thunkAPI) => {
  try {
    return await getCobaltSessionToken();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const cobaltEventsSubscribeRequest = createAsyncThunk<
  ResponseSuccess,
  SubscribedEventPayload[],
  { rejectValue: ResponseErrorWithHandled }
>('api-token/cobaltEventsSubscribeRequest', async (args, thunkAPI) => {
  try {
    return await cobaltEventsSubscribe(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getIntegrationAdminSettingRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/getIntegrationAdminSettingRequest', async (args, thunkAPI) => {
  try {
    return await getIntegrationAdminSetting();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const changeIntegrationSettingRequest = createAsyncThunk<
  ResponseSuccess,
  { isAllUser: boolean },
  { rejectValue: ResponseErrorWithHandled }
>(
  'api-token/changeIntegrationSettingRequest',
  async ({ isAllUser }, thunkAPI) => {
    try {
      return await changeIntegrationSetting(isAllUser);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const generateIntegrationApiTokenRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/generateIntegrationApiTokenRequest', async (_args, thunkAPI) => {
  try {
    return await generateIntegrationApiToken();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
