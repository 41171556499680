import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  User,
  Mail,
  Calendar,
  Users,
  TerminalCode,
  Link,
  Code,
  World,
  Bullet,
  FileX,
  Wallet,
  OutOfOffice,
  Webhook,
  UserStar,
  Brush,
} from '@saleshandy/icons';
import SettingsNavigationItem from './components/settings-navigation-item';
import SettingsNavigationTitle from './components/settings-navigation-title';

import store from '../../../../store';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import { isValueBasedPlan } from '../billing-subscription/components/outreach-plans/utils/helper';

type SettingsNavItem = {
  id: string;
  to: string;
  permission: boolean;
  icon: React.FC;
  label: string;
};

type Settings = {
  title: string;
  navItems: SettingsNavItem[];
};

const SettingNavigation = () => {
  const { t } = useTranslation();
  const state = store.getState();
  const planType = state.home?.subscription?.planType;

  const userSettings: Settings = {
    title: 'User Settings',
    navItems: [
      {
        id: 'profile',
        to: '/settings/profile',
        permission: hasPermission(Permissions.MY_PROFILE_READ),
        icon: User,
        label: t('labels.my_profile'),
      },
      {
        id: 'email-accounts',
        to: '/settings/email-accounts',
        permission: hasPermission(Permissions.EMAIL_ACCOUNT_READ),
        icon: Mail,
        label: t('labels.email_accounts'),
      },
      {
        id: 'schedules',
        to: '/settings/schedules',
        permission:
          hasPermission(Permissions.SCHEDULE_SHOW) &&
          hasPermission(Permissions.SCHEDULE_READ),
        icon: Calendar,
        label: t('labels.schedules'),
      },
      {
        id: 'out-of-office',
        to: '/settings/out-of-office',
        permission: hasPermission(Permissions.OOO_SETTING_READ),
        icon: OutOfOffice,
        label: t('labels.outOfOffice'),
      },
    ],
  };

  const companySettings: Settings = {
    title: 'Company Settings',
    navItems: [
      {
        id: 'users',
        to: '/settings/users',
        permission:
          hasPermission(Permissions.USER_TEAM_SHOW) &&
          (hasPermission(Permissions.ACCOUNT_USER_READ) ||
            hasPermission(Permissions.TEAM_SHOW)),
        icon: Users,
        label: t('labels.users_and_teams'),
      },
      {
        id: 'custom-fields',
        to: '/settings/custom-fields',
        permission:
          hasPermission(Permissions.FIELD_SHOW) &&
          hasPermission(Permissions.FIELD_READ),
        icon: TerminalCode,
        label: t('labels.prospect_fields'),
      },
      {
        id: 'custom-outcomes',
        to: '/settings/custom-outcomes',
        permission:
          hasPermission(Permissions.OUTCOME_SHOW) &&
          hasPermission(Permissions.OUTCOME_READ),
        icon: UserStar,
        label: t('labels.prospect_outcomes'),
      },
      {
        id: 'integrations',
        to: '/settings/integrations',
        permission: hasPermission(Permissions.INTEGRATION_READ),
        icon: Link,
        label: t('labels.integration'),
      },
      {
        id: 'webhook',
        to: '/settings/webhook',
        permission: hasPermission(Permissions.WEBHOOKS_SHOW),
        icon: Webhook,
        label: t('labels.webhook'),
      },
      {
        id: 'api-keys',
        to: '/settings/api-keys',
        permission:
          hasPermission(Permissions.API_TOKEN_SHOW) &&
          hasPermission(Permissions.API_TOKEN_READ),
        icon: Code,
        label: t('labels.api_key'),
      },
      {
        id: 'custom-domain',
        to: '/settings/custom-domain',
        permission:
          hasPermission(Permissions.CUSTOM_DOMAIN_SHOW) &&
          hasPermission(Permissions.CUSTOM_DOMAIN_READ),
        icon: World,
        label: t('labels.custom_tracking_domain'),
      },
      {
        id: 'admin-settings',
        to: '/settings/admin-settings',
        permission: hasPermission(Permissions.ACCOUNT_USER_READ),
        icon: Bullet,
        label: t('labels.admin_settings'),
      },
      {
        id: 'do-not-contact-list',
        to: '/settings/dnc-list',
        permission: hasPermission(Permissions.DNC_READ),
        icon: FileX,
        label: t('labels.do_not_contact_list'),
      },
      {
        id: 'whitelabel',
        to: '/settings/whitelabel',
        permission: hasPermission(Permissions.WHITELABEL_SHOW),
        icon: Brush,
        label: 'Whitelabel',
      },
      {
        id: 'billing',
        to: '/settings/billing',
        permission:
          hasPermission(Permissions.BILLING_SHOW) &&
          (isValueBasedPlan(planType) ||
            hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)),
        icon: Wallet,
        label: hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
          ? t('labels.billing_and_subscription')
          : 'Plan details & billing',
      },
    ],
  };

  const renderNavItems = (navItems: SettingsNavItem[]) =>
    navItems
      .filter((item) => item.permission)
      .map((item) => (
        <SettingsNavigationItem
          key={item.id}
          to={item.to}
          icon={item.icon}
          label={item.label}
        />
      ));

  const renderSection = (settings: Settings, renderDivider = false) => {
    const navItems = renderNavItems(settings.navItems);
    if (navItems.length > 0) {
      return (
        <>
          {renderDivider && <div className="settings-navigation-divider" />}
          <SettingsNavigationTitle title={settings.title} />
          {navItems}
        </>
      );
    }
    return null;
  };

  return (
    <>
      {renderSection(userSettings)}
      {renderSection(companySettings, true)}
    </>
  );
};

export default SettingNavigation;
