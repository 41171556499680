import React from 'react';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import { Images } from '../../app-constants';
import EmptyList from '../../design-system/components/molecules/empty-list/empty-list';

import history from '../../history';
import { contactAdmin } from '../../utils/contact-admin';
import {
  isPlanDowngraded,
  isPlanDowngradedFromPaid,
} from '../../../components/home/utils/plan-transition';
import type { IProps } from './block-page-container';
import hasPermission from '../../utils/access-control/has-permission';
import { Permissions } from '../../utils/access-control/enums/permissions';

import { TrialExtendSource } from '../../enums/trial-extend-source';
import { getIsRequestPending } from '../../utils/get-request-status';

import { getPlanExpiredDescriptionMessage } from './helper';
import { Routes } from '../../routes';

const BlockPage: React.FC<IProps> = ({
  redirectURL = '/sequence',
  firstName,
  planTransitionDetails,
  isEligibleToExtendTrial,
  sendExtendTrialPeriodRequest,
  extendTrialPeriodRequestStatus,
}) => {
  const { t } = useTranslation();

  if (
    planTransitionDetails !== null &&
    !isPlanDowngraded(planTransitionDetails)
  ) {
    history.push(redirectURL);
  }

  const onButtonClick = () => {
    const emailBody = `Hey ${firstName}, %0D%0A %0D%0A ${t(
      'messages.upgrade_to_pro_email_body',
    )}`;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      history.push(Routes.Setting_Billing_Upgrade_Email_Outreach_Plan);
    } else {
      contactAdmin(t('labels.upgrade_to_saleshandy_pro_email_sub'), emailBody);
    }
  };

  const onTrialExtended = () => {
    if (isEligibleToExtendTrial) {
      sendExtendTrialPeriodRequest(TrialExtendSource.Webapp);
    }
  };

  return (
    <div className="block-page-container">
      <EmptyList
        description={getPlanExpiredDescriptionMessage({
          t,
          planTransitionDetails,
          isPlanDowngradedFromPaid,
        })}
        title={
          isPlanDowngradedFromPaid(planTransitionDetails)
            ? t('labels.your_subscription_end')
            : t('labels.your_trail_expired')
        }
        imgSrc={Images.Lock}
        className="subscription-ended-container"
        isVertical={true}
      >
        <Button variant="primary" onClick={onButtonClick}>
          {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? t('labels.upgrade')
            : t('labels.contact_admin')}
        </Button>
        {isEligibleToExtendTrial &&
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) && (
            <Button
              className="ml-3"
              variant="secondary"
              onClick={onTrialExtended}
              isLoading={getIsRequestPending(extendTrialPeriodRequestStatus)}
              loadingText="Extending..."
            >
              Extend your trial
            </Button>
          )}
      </EmptyList>
    </div>
  );
};

export default BlockPage;
