import React from 'react';
import { useTranslation } from 'react-i18next';
import { Chrome } from '@saleshandy/icons';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { AnalyticsEvents } from '../../../../../../shared/enums/analytics';

type IProps = {
  trackingId: string;
  planName: string;
};

const ChromeWebstore: React.FC<IProps> = ({ trackingId, planName }) => {
  const redirectToChromeWebStore = () => {
    // Segment tracking
    window.analytics?.track({
      userId: trackingId,
      event: AnalyticsEvents.ExtensionIconClicked,
      properties: {
        current_tab_url: document.location.href,
        current_plan: planName,
      },
    });
    window.open(process.env.REACT_APP_SALESHANDY_CONNECT_URL, '_blank');
  };

  const { t } = useTranslation();

  return (
    <OverlayTooltip
      text={t('messages.download_chrome_plugin')}
      placement={Placement.Bottom}
    >
      <div
        className="d-flex hover-on-header"
        {...accessibleOnClick(redirectToChromeWebStore)}
      >
        <Chrome height={20} width={20} className="d-flex chrome-webstore" />
      </div>
    </OverlayTooltip>
  );
};

export default ChromeWebstore;
