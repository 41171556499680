import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../store/root-reducer';
import TasksContent from './tasks-content';
import {
  GetTasksParams,
  UpdateTask,
  UpdateTaskNoteRequest,
} from '../../utils/types';
import {
  getTasksRequest,
  getTaskStatusCountRequest,
  updateTaskNoteRequest,
  updateTasksRequest,
} from '../../extra-actions';
import { TaskStatusKey } from '../../utils/enums';
import { ProspectFilters } from '../../../prospect/types';
import {
  checkSingle,
  removeTaskFromTaskerData,
  resetSelectedTasks,
  resetTasksFilters,
  setTasksFilters,
} from '../../tasks-slice';

const mapStateToProps = (state: RootState) => ({
  tasks: state.tasks.tasks,
  tasksCount: state.tasks.tasksCount,
  getTasksRequestStatus: state.tasks.getTasksRequest.status,
  taskActionRequestStatus: state.tasks.updateTasksRequest.status,
  updateTaskRequestMessage: state.tasks.updateTasksRequest.message,
  tasksFilters: state.tasks.tasksFilters,
  selectedTasks: state.tasks.selectedTasks,
  updateTaskNoteRequestStatus: state.tasks.updateTaskNoteRequest.status,
});

const mapDispatchToProps = {
  getTasksRequest: (params: GetTasksParams) => getTasksRequest(params),
  updateTasksRequest: (payload: UpdateTask) => updateTasksRequest(payload),
  getTasksStatusCountRequest: (params: GetTasksParams) =>
    getTaskStatusCountRequest(params),
  updateTaskNoteRequest: (payload: UpdateTaskNoteRequest) =>
    updateTaskNoteRequest(payload),
  setTasksFilters: (filters: ProspectFilters) => setTasksFilters(filters),
  resetTasksFilters: (filter: string) => resetTasksFilters(filter),
  checkSingle: ({ row, status }) => checkSingle({ row, status }),
  resetSelectedTasks: () => resetSelectedTasks(),
  removeTaskFromTaskerData: (taskId: number) =>
    removeTaskFromTaskerData({ taskId }),
  showLoadingBar: () => showLoading(),
  hideLoadingBar: () => hideLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    taskStatus: TaskStatusKey;
  };

export default withRouter(connector(TasksContent));
