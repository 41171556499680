import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import Whitelabel from './whitelabel';
import { resetResourceState } from './whitelabel-slice';

const mapStateToProps = (state: RootState) => ({
  isLoading: state.safetySettings.isLoading,
});

const mapDispatchToProps = {
  resetResourceState: () => resetResourceState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(Whitelabel);
