import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';
import validator from 'validator';

import type { ResetPasswordProps } from '../types';

import Input from '../../../../../../../shared/design-system/components/input';

import Title from './sub-components/title';
import Description from './sub-components/description';
import Action from './sub-components/action';
import Footer from './sub-components/footer';
import Divider from './sub-components/divider';
import { getIsRequestPending } from '../../../../../../../shared/utils';

const ResetPassword: React.FC<ResetPasswordProps> = ({
  onCancel,
  onSubmit,
  resetPasswordRequestStatus,
}) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const onEmailChange = (value: string) => {
    if (!validator.isEmail(value)) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }

    setEmail(value);
  };

  const handleOnSubmit = () => {
    onSubmit(email);
  };

  useEffect(
    () => () => {
      setEmail('');
      setEmailError('');
    },
    [],
  );

  const isResetPasswordRequestLoading = getIsRequestPending(
    resetPasswordRequestStatus,
  );

  return (
    <>
      <Title>
        <h1>Reset your Password</h1>
      </Title>

      <Description content="Enter the email address associated with your account, and we’ll send you instructions to reset your password." />

      <Action>
        <Input
          name="email"
          label="Current Email"
          type="email"
          placeholder="johndoe@example.com"
          value={email}
          onChange={onEmailChange}
          autoComplete="current-email"
          className="my-profile-input modal-input"
          variant={!!emailError && Input.Variant.Error}
          caption={emailError}
        />
      </Action>

      <Divider />

      <Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
          className="secondary-cta-btn"
          disabled={isResetPasswordRequestLoading}
        >
          Cancel
        </Button>
        <Button
          loadingText="Sending link..."
          isLoading={isResetPasswordRequestLoading}
          disabled={!email || !!emailError || isResetPasswordRequestLoading}
          onClick={handleOnSubmit}
        >
          Reset Password
        </Button>
      </Footer>
    </>
  );
};

export default ResetPassword;
