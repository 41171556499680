import React from 'react';
import { Webhook } from '../../../../../../../types/webhook';
import { getWebhookTriggerEventName } from '../../../../../utils/get-webhook-trigger-event-name';
import {
  OverlayPopover,
  Placement,
} from '../../../../../../../../../shared/design-system/components/overlay';

type IProps = {
  webhook: Webhook;
};

const WebhookTriggerEventField: React.FC<IProps> = ({ webhook }) => {
  const events = webhook?.events
    ?.map((event) => getWebhookTriggerEventName(event))
    ?.filter((name) => name !== null);

  const renderCell = (label: string, className = '') => (
    <p className={`trigger-event-label ${className}`}>{label}</p>
  );

  return (
    <div className="d-flex cursor-default trigger-event-field">
      {events?.length === 0 ? (
        renderCell('-', 'blue-txt-15')
      ) : (
        <OverlayPopover
          className="trigger-event-popover"
          content={
            <div className="trigger-event-popover-content">
              <h2>Events Triggered</h2>
              <div className="trigger-event-wrapper">
                {events.map((event) => (
                  <div className="trigger-event">
                    <p>{event}</p>
                  </div>
                ))}
              </div>
            </div>
          }
          placement={Placement.BottomStart}
          rootClose
        >
          {renderCell(`${events.length}`, 'cursor-pointer')}
        </OverlayPopover>
      )}
    </div>
  );
};

export default WebhookTriggerEventField;
