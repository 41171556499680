/* eslint-disable @typescript-eslint/no-shadow */
import React, {
  useState,
  useRef,
  useCallback,
  createRef,
  useEffect,
} from 'react';
import { DragAndDrop, Button as ShButton } from '@saleshandy/design-system';
import { Pencil, Trash } from '@saleshandy/icons';
import Cropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import type { IProps } from './types';
import UploadCloud from '../../../../../../../../shared/components/images/upload-cloud';
import { OverlayTooltip } from '../../../../../../../../shared/design-system/components/overlay';
import DeleteAlertModal from '../../../../../../../../shared/components/delete-alert-modal';
import { getIsRequestPending } from '../../../../../../../../shared/utils';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import { getCroppedImg } from '../../utils/crop-image';
import Button from '../../../../../../../../shared/design-system/components/atoms/button';

const customStyles = `
  .cropper-modal {
    background-color: transparent !important;
  }
  .cropper-view-box,
  .cropper-face {
    border-color: #3B82F6 !important;
  }
  .cropper-point {
    background-color: #3B82F6 !important;
  }
  .cropper-line {
    background-color: #3B82F6 !important;
  }
`;

const MAX_WIDTH = 4096;
const MAX_HEIGHT = 2160;

const WhitelabelImagePicker: React.FC<IProps> = ({
  label,
  description,
  dndDescription,
  selectedImage,
  addedImage,
  isLoading,
  showImageCropper = true,
  deleteResourceRequestStatus,
  cropperWidth = 360,
  cropperHeight = 126,
  onSelecting,
  onCancel,
  onSave,
  onEdit,
  onImageDelete,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const cropperRef = createRef<ReactCropperElement>();
  const containerRef = useRef(null);

  //* States */

  const [dragging, setDragging] = useState(false);
  const [isEditView, setIsEditView] = useState(false);
  const [isImageReplaced, setIsImageReplaced] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  //* ImageCropper State */
  const [imageSrc, setImageSrc] = useState(null);
  const [cropper, setCropper] = useState(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  //* Helper Functions */

  const readV3Image = async () => {
    try {
      const response = await fetch(addedImage, {
        method: 'GET',
        credentials: 'omit',
        mode: 'no-cors',
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const file = new File([blob], 'image.png', { type: blob.type });

      return file;
    } catch (error) {
      console.error('Error fetching and converting image:', error);
      return null;
    }
  };

  const readFile = (file) =>
    new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });

  const toggleEditView = async () => {
    const img = await readV3Image();

    setImageSrc(addedImage);
    setIsEditView(!isEditView);
  };
  const onDelete = () => {
    setIsDeleteModalVisible(true);
  };
  const handleCancel = () => {
    if (isEditView) {
      setIsEditView(false);
      return;
    }

    setIsImageReplaced(false);
    onCancel();
  };

  const clickInput = () => inputRef.current?.click();
  const onInputChangeHandler = ({ target: { files } }) => {
    if (files[0]?.type !== 'image/png') {
      toaster.error(
        'The file type you are trying to upload is not supported.',
        { theme: Theme.New },
      );
      return;
    }

    if (isEditView && files.length > 0) {
      setIsImageReplaced(true);
    }

    files.length > 0 && onSelecting(files[0]);
  };

  const getImageSrc = () => {
    if (isEditView && !isImageReplaced) {
      return addedImage;
    }

    return URL.createObjectURL(selectedImage);
  };

  //* ImageCropper */
  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
      );

      // Convert the cropped image URL (blob) to a File object
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const file = new File([blob], 'croppedImage.png', { type: 'image/png' });

      return file;
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const transformToCroppedImage = async () => {
    if (cropperRef.current && cropperRef.current.cropper) {
      const croppedImage = cropperRef.current.cropper
        .getCroppedCanvas()
        .toDataURL();

      // Convert the cropped image URL (blob) to a File object
      const response = await fetch(croppedImage);
      const blob = await response.blob();
      const file = new File([blob], 'croppedImage.png', { type: 'image/png' });

      return file;
    }

    return null;
  };

  const handleSave = async () => {
    if (isEditView) {
      onEdit(await transformToCroppedImage());
      return;
    }

    onSave(await transformToCroppedImage());
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const validateImageResolution = (file) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
          reject(
            new Error(
              `Image resolution should not exceed ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`,
            ),
          );
        } else {
          resolve(true);
        }
      };
      img.onerror = () => reject(new Error('Failed to load image.'));
      img.src = URL.createObjectURL(file);
    });

  const onFileChange = async (e) => {
    e.preventDefault();

    if (e.target.files[0]?.type !== 'image/png') {
      toaster.error(
        'The file type you are trying to upload is not supported.',
        { theme: Theme.New },
      );
      return;
    }

    if (e.target.files[0].size > 1024 * 1024) {
      toaster.error('File size exceeds 1MB limit.', { theme: Theme.New });
      return;
    }

    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      // const imageDataUrl = await readFile(file);

      validateImageResolution(file)
        .then(() => {
          const reader = new FileReader();
          reader.onload = () => {
            setImageSrc(reader.result as any);
          };
          reader.readAsDataURL(file);
          onSelecting(file);
        })
        .catch((error) => {
          toaster.error(error.message, { theme: Theme.New });
        });
    }
  };

  const onDropEnd = async (files) => {
    if (files[0]?.type !== 'image/png') {
      toaster.error(
        'The file type you are trying to upload is not supported.',
        { theme: Theme.New },
      );
      return;
    }

    if (files[0].size > 1024 * 1024) {
      toaster.error('File size exceeds 1MB limit.', { theme: Theme.New });
      return;
    }

    if (files && files.length > 0) {
      const file = files[0];
      // const imageDataUrl = await readFile(file);
      try {
        await validateImageResolution(file);
        const reader = new FileReader();
        reader.onload = () => {
          setImageSrc(reader.result as any);
        };
        reader.readAsDataURL(file);
        onSelecting(file);
      } catch (error) {
        toaster.error((error as any).message, { theme: Theme.New });
      }
    }
  };

  const getAllowedImageDescription = () => {
    if (label === 'Logo') {
      return 'Horizontal or square PNG logo';
    }

    return 'Square PNG logo';
  };

  return (
    <div>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between align-items-center">
          <span className="regualr-2 font-medium popover-arrow-color-txt">
            {label}
          </span>
          {addedImage && !isEditView && (
            <div className="action-container action-container--edit">
              <OverlayTooltip text="Modify">
                <div {...accessibleOnClick(toggleEditView)}>
                  <Pencil
                    width={20}
                    height={20}
                    className="gray-txt-11 pointer"
                  />
                </div>
              </OverlayTooltip>
            </div>
          )}
          {addedImage && isEditView && (
            <div className="action-container--delete action-container">
              <OverlayTooltip text="Delete">
                <div {...accessibleOnClick(onDelete)}>
                  <Trash
                    width={20}
                    height={20}
                    className="gray-txt-11 pointer"
                    onClick={onDelete}
                  />
                </div>
              </OverlayTooltip>
            </div>
          )}
        </div>
        <span className="regular-1 line-height-18 gray-txt-15  mt-1">
          {description}
        </span>
      </div>

      {addedImage && !isEditView ? (
        <div
          className="mt-4 selected-image-section"
          style={{ width: cropperWidth, height: cropperHeight }}
        >
          <img alt="not found" src={addedImage} />
        </div>
      ) : (
        <>
          <DragAndDrop
            dragging={dragging}
            setDragging={setDragging}
            handleDroppedFiles={(files) => onDropEnd(files)}
            backgroundVariant="solid"
            title={dndDescription}
            className={`${
              dragging && 'whitelabel__image-picker-drop'
            } whitelabel__image-picker-dnd mt-3`}
          >
            <style>{customStyles}</style>
            {selectedImage || isEditView ? (
              <div ref={containerRef} className="ed-image-container">
                {showImageCropper ? (
                  <div
                    style={{
                      width: '450px',
                      height: '150px',
                      overflow: 'hidden',
                      margin: '20px 0',
                    }}
                  >
                    <Cropper
                      ref={cropperRef}
                      style={{ height: '100%', width: '100%' }}
                      aspectRatio={NaN}
                      preview=".img-preview"
                      src={imageSrc}
                      viewMode={1}
                      guides={true}
                      background={false}
                      responsive={true}
                      autoCropArea={1}
                      checkOrientation={false}
                      onInitialized={(instance) => {
                        setCropper(instance);
                      }}
                    />
                  </div>
                ) : (
                  <img alt="not found" width="250px" src={getImageSrc()} />
                )}

                <div className="file-uploader">
                  <input
                    type="file"
                    accept=".png, image/png"
                    ref={inputRef}
                    onChange={onFileChange}
                  />
                </div>
              </div>
            ) : (
              <div className="pre-select d-flex flex-column align-items-center">
                <div className="empty-data-icon-wrapper">
                  <UploadCloud />
                </div>
                <span className="regular-2 font-medium line-height-20 popover-arrow-color-txt mt-3">
                  {dndDescription}
                </span>
                <span className="regular-1 line-height-20 gray-txt-15 mt-2">
                  Recommended:{' '}
                  <span className="blue-txt-11">
                    {getAllowedImageDescription()}{' '}
                  </span>{' '}
                  with a transparent background (Max 1MB)
                </span>

                <div className="mt-3">
                  <div
                    className="file-uploader"
                    {...accessibleOnClick(clickInput)}
                  >
                    <div className="choose-file">Choose a file</div>
                    <input
                      type="file"
                      accept=".png, image/png"
                      ref={inputRef}
                      onChange={onFileChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </DragAndDrop>

          <div className="d-flex justify-content-between">
            {(selectedImage || isEditView) && (
              <div className="d-flex mt-3">
                <ShButton
                  onClick={handleSave}
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save
                </ShButton>
                <Button
                  onClick={handleCancel}
                  variant={Button.Variant.Outlined}
                  className="bs-ml-12"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
              </div>
            )}

            {isEditView && (
              <ShButton
                onClick={() => clickInput()}
                variant="secondary"
                className="bs-ml-12 mt-3"
                disabled={isLoading}
              >
                Replace Image
              </ShButton>
            )}
          </div>
        </>
      )}

      {isDeleteModalVisible && (
        <DeleteAlertModal
          show={isDeleteModalVisible}
          title={`Delete ${label}`}
          contents={[
            `Are you sure you want to delete brand ${label.toLowerCase()}?`,
            'This will remove your branding from login page.',
          ]}
          isSubmitLoading={getIsRequestPending(deleteResourceRequestStatus)}
          onClose={() => setIsDeleteModalVisible(false)}
          onSubmit={onImageDelete}
        />
      )}
    </div>
  );
};

export default WhitelabelImagePicker;
