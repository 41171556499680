import React, { ReactElement } from 'react';

export type FormField = {
  id: string | number;
  name: string;
  type?: string;
  value?: any;
  displayName: string;
  placeHolder?: string;
  required?: boolean;
  validationErrorMessage?: string;
  disabled?: boolean;
  validator?: string | (() => boolean);
  component: string | ReactElement;
  styleClassNames?: string;
  inlineStyle?: React.CSSProperties;
};

export type IProps = {
  fields: FormField[];
  formBodyClasses?: string;
  formMode?: FormMode;
  formContainerClasses?: string;
  onSubmit: (values: any) => void;
  onCancel: () => void;
  buttonClassName?: string;
  submitStatus?: boolean;
  buttonTitle?: string;
  t: (x: string) => string;
  primaryEmailChangeInProspectRequest?: (primaryEmailProspectInfoId: number, contactId: number) => void;
  deleteEmailFromProspectRequest?: (prospectInfoId:number, contactId:number) => void;
  deletePhoneFromProspectRequest?:(prospectInfoId:number, contactId:number) => void;
  selectedProspectId?: number;
};

export enum InputValidationType {
  isEmail = 'isEmail',
  MaxCharLimit = 'maxCharLimit',
  isMobilePhone = 'isMobilePhone',
}

export enum FormMode {
  Edit = 'edit',
  Add = 'add',
}
