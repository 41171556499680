import classNames from 'classnames';
import React from 'react';
import { VisibilityContext } from 'react-horizontal-scrolling-menu';
import SHBackward from '../../../../components/images/sh-backward';
import SHForward from '../../../../components/images/sh-forward';
import './arrow.scss';

const Arrow = ({ children, onClick, className }) => {
  return (
    <div className={classNames(['arrow', className])} onClick={onClick}>
      {children}
    </div>
  );
};

export const RightArrow = () => {
  const {
    getNextItem,
    isLastItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !visibleItemsWithoutSeparators.length && isLastItemVisible,
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleItemsWithoutSeparators]);

  const clickHandler = () => {
    const nextItem = getNextItem();
    scrollToItem(nextItem?.entry?.target, 'smooth', 'end');
  };

  return (
    !disabled && (
      <Arrow onClick={clickHandler} className={'right'}>
        <SHForward />
      </Arrow>
    )
  );
};

export const LeftArrow = () => {
  const {
    getPrevItem,
    isFirstItemVisible,
    scrollToItem,
    visibleItemsWithoutSeparators,
    initComplete,
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
    !initComplete || (initComplete && isFirstItemVisible),
  );
  React.useEffect(() => {
    if (visibleItemsWithoutSeparators.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleItemsWithoutSeparators]);

  const clickHandler = () => {
    const prevItem = getPrevItem();
    scrollToItem(prevItem?.entry?.target, 'smooth', 'start');
  };

  return (
    !disabled && (
      <Arrow onClick={clickHandler} className={'left'}>
        <SHBackward />
      </Arrow>
    )
  );
};
