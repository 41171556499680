export const initializeCustomScript = () => {
  const script = document.createElement('script');
  script.setAttribute('id', 'custom-script-for-agency');
  script.setAttribute('type', 'text/javascript');

  script.innerHTML = `
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '1dce0304-73c1-4f87-bf72-78fd06ab9893';
    (function () {
        d = document;
        s = d.createElement('script');
        s.src = 'https://client.crisp.chat/l.js';
        s.async = 1;
        d.getElementsByTagName('head')[0].appendChild(s);
    })();
  `;

  document.body.appendChild(script);
};

export const removeCustomScript = () => {
  const customScriptForAgency = document.getElementById(
    'custom-script-for-agency',
  );
  customScriptForAgency && document.body.removeChild(customScriptForAgency);
  window.CRISP_WEBSITE_ID = null;
  window.$crisp?.push?.(['do', 'session:reset']);
};
