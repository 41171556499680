import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { ProductTour } from '../../../../shared/enums/product-tour';
import { getNavItemIcon } from './get-nav-item-icon';

const DropDownNavItem = ({ eventKey, route, text }) => {
  const history = useHistory();

  const navItemClassName = classNames([
    'sidenav__item sidenav__dropdown__item d-flex w-100 align-items-center justify-content-start border-2',
    {
      active:
        eventKey !== 'referral'
          ? history.location.pathname.startsWith(route)
          : false,
    },
  ]);

  const getNavItemId = () => {
    if (eventKey === 'sequence') {
      return ProductTour.NavigateToSequences;
    }
    if (eventKey === 'leads') {
      return ProductTour.NavigateToLeadFinder;
    }

    return null;
  };

  const navItem = (
    <li id={getNavItemId()} key={eventKey} className={navItemClassName}>
      <div className="sidenav__icon">{getNavItemIcon(eventKey)}</div>
      <div className="sidenav__text d-block">{text}</div>
    </li>
  );

  if (eventKey === 'referral') {
    return (
      <div className="sidenav__item-wrapper nav-item-container justify-content-start celloClass">
        {navItem}
      </div>
    );
  }

  return (
    <Link
      className="sidenav__item-wrapper nav-item-container justify-content-start"
      to={route}
      key={eventKey}
    >
      {navItem}
    </Link>
  );
};

export default DropDownNavItem;
