import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

import { Column } from '../../../../../../../../../shared/design-system/components/organisms/table/types';

import EmailDomainNameField from '../fields/email-domain-name-field';
import AddedByField from '../fields/added-by-field';
import UpdatedAtField from '../fields/updated-at-field';
import hasPermission from '../../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../../shared/utils/access-control/enums/permissions';

export const getDoNotContactListDetailsColumns = (): Column[] => {
  const isActionsColVisible = hasPermission(Permissions.DNC_LIST_DELETE);

  return [
    {
      dataField: 'name',
      text: 'Email / Domain',
      headerAlign: 'start',
      cellClasses: 'email-domain-name-cell',
      component: (_cell, row) => (
        <EmailDomainNameField doNotContactListDetail={row} />
      ),
      ...(isActionsColVisible && {
        headerStyle: () => ({ minWidth: '38.46%', width: '38.46%' }),
      }),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'user',
      text: 'Added By',
      headerAlign: 'start',
      cellClasses: 'added-by-cell',
      component: (_cell, row) => <AddedByField doNotContactListDetail={row} />,
      headerStyle: () => ({ minWidth: '20.19%', width: '20.19%' }),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'createdAt',
      text: 'Added On',
      headerAlign: 'start',
      cellClasses: 'updated-at-cell',
      component: (_cell, row) => (
        <UpdatedAtField doNotContactListDetail={row} />
      ),
      headerStyle: () => ({ minWidth: '27.69%', width: '27.69%' }),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
  ];
};
