import React from 'react';
import InfiniteScroll, { Props } from 'react-infinite-scroll-component';

type WrapperDivProps = {
  wrapperId: string;
  wrapperStyle: React.CSSProperties;
};

const InfiniteScrollCustom = ({
  children,
  wrapperId,
  wrapperStyle,
  ...props
}: Props & WrapperDivProps) => {
  return (
    <div id={wrapperId} style={wrapperStyle}>
      <InfiniteScroll {...props}>{children}</InfiniteScroll>
    </div>
  );
};

export default InfiniteScrollCustom;
