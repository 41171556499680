import React from 'react';
import { ChevronDown, ChevronUp } from '@saleshandy/icons';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import Transfer from '../../../../../../shared/design-system/components/transfer';
import { Placement } from '../../../../../../shared/design-system/components/overlay';
import CreateProspectCustomOutcomeModal from '../generic-create-update-custom-outcome-modal/prospect-modal/create-prospect-custom-outcome-modal';
import { CustomOutcomeDropdownPlacement, IState } from './types';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { CreateUpdateModalData } from '../../types';
import type { IProps } from './custom-outcome-dropdown-container';
import { getOutcomeIcon } from '../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import { OutcomeSentiment } from '../../../../enums/custom-outcome';

class GenericCustomOutcomeDropdown extends React.Component<IProps, IState> {
  private elementRef: React.RefObject<HTMLDivElement>;

  constructor(props) {
    super(props);
    const { initialSelectedOption, options } = this.props;
    this.state = {
      selectedOption: initialSelectedOption || options[0],
      show: false,
      showCreateProspectCustomOutcomeModal: false,
      elementBottom: null,
      placement: null,
    };
    this.elementRef = React.createRef();
    this.updateElementHeight = this.updateElementHeight.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { initialSelectedOption, options } = this.props;
    if (
      prevProps.initialSelectedOption?.id !== initialSelectedOption?.id ||
      prevProps.initialSelectedOption?.name !== initialSelectedOption?.name ||
      prevProps.initialSelectedOption?.sentiment !==
        initialSelectedOption?.sentiment
    ) {
      this.setSelectedOption(initialSelectedOption, options);
    }
    this.updateElementHeight();
  }

  onCategoryChange = (option) => {
    if (option) {
      const {
        onChange,
        changeOutcomeRequestPayload,
        sendOnOutcomeChangeRequest,
      } = this.props;
      this.setState({ selectedOption: option, show: false });
      if (changeOutcomeRequestPayload) {
        sendOnOutcomeChangeRequest(
          changeOutcomeRequestPayload.sequenceId,
          changeOutcomeRequestPayload.prospectId,
          option.id,
        );
      }
      onChange?.(option);
    }
  };

  onCreateProspectCustomOutcomeSubmit = (payload: CreateUpdateModalData) => {
    const { sendCreateCustomOutcomeRequest } = this.props;
    sendCreateCustomOutcomeRequest({
      label: payload.label,
      sentiment: payload.sentiment,
    });
  };

  setSelectedOption = (initialSelectedOption, options) => {
    this.setState({
      selectedOption: initialSelectedOption || options[0],
    });
  };

  showCreateProspectCustomOutcome = () => {
    this.setState({
      showCreateProspectCustomOutcomeModal: true,
      show: false,
    });
  };

  hideCreateProspectCustomOutcome = () => {
    this.setState({
      showCreateProspectCustomOutcomeModal: false,
    });
  };

  updateElementHeight() {
    const element = this.elementRef.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const { elementBottom: currentBottom } = this.state;
      const topAvailableHeight = rect.top;
      if (rect.bottom !== currentBottom) {
        // if element is scrolled either way up or down
        const isSufficientHeightTop = topAvailableHeight > 400;

        const placement = isSufficientHeightTop
          ? CustomOutcomeDropdownPlacement.DynamicTop
          : CustomOutcomeDropdownPlacement.DynamicBottom;

        this.setState({
          elementBottom: rect.bottom,
          placement,
        });
      }
    }
  }

  render() {
    const {
      className,
      options,
      optionRenderer,
      selectedOptionRenderer,
      selectedOptionClassName,
      showFooter,
      createCustomOutcomeRequestStatus,
      initialSelectedOption,
      disableAutomaticOpening,
      disabled = false,
    } = this.props;

    const {
      selectedOption,
      show,
      showCreateProspectCustomOutcomeModal,
      placement,
    } = this.state;

    const isCreateRequestPending =
      createCustomOutcomeRequestStatus === RequestStatus.Pending;

    const customOutcomeDropdownClasses = classNames([
      'custom-outcome-dropdown',
      className,
      {
        'transfer-container-transform':
          !disableAutomaticOpening &&
          placement === CustomOutcomeDropdownPlacement.Top &&
          false,
        'transfer-container-dynamic-bottom':
          placement === CustomOutcomeDropdownPlacement.DynamicBottom &&
          !disableAutomaticOpening,
        'transfer-container-dynamic-top':
          placement === CustomOutcomeDropdownPlacement.DynamicTop &&
          !disableAutomaticOpening,
        disabled,
      },
    ]);

    const selectedOptionRendererClasses = classNames([
      'prospect-custom-outcome-select',
      {
        'positive-sentiment':
          selectedOption?.sentiment === OutcomeSentiment.Positive,
        'negative-sentiment':
          selectedOption?.sentiment === OutcomeSentiment.Negative,
        'neutral-sentiment':
          selectedOption?.sentiment === OutcomeSentiment.Neutral,
        'default-sentiment':
          !selectedOption ||
          !initialSelectedOption ||
          !selectedOption?.sentiment,
        'cursor-text': disabled,
        disabled,
      },
    ]);

    const renderAddOutcomeDropdown = () =>
      disabled ? (
        <span>-</span>
      ) : (
        <div className="selected-outcome-main">
          <div>
            <span className="ml-2">Add Outcome</span>
          </div>

          <div className="bs-select-box-icon-wrapper">
            {show ? <ChevronUp /> : <ChevronDown />}
          </div>
        </div>
      );

    return (
      <>
        {selectedOption && (
          <Transfer
            className={customOutcomeDropdownClasses}
            autoFocusSearch={true}
            options={options.filter((option) => option.isDefault)}
            customFilterOption={(_options, searchValue) =>
              _options.filter((option) =>
                option.name.toLowerCase().includes(searchValue.toLowerCase()),
              )
            }
            selectedOptionKey={selectedOption.key}
            onChange={([option]) => this.onCategoryChange(option)}
            optionRenderer={optionRenderer}
            placement={Placement.Bottom}
            showSearch
            searchPlaceholder="Search"
            show={show}
            onToggle={(value) => {
              if (!disabled) {
                this.setState({ show: value });
              }
            }}
            showOptionsSeparator={true}
            showListSeparator={true}
            listSeparatorOptions={options.filter((option) => !option.isDefault)}
            optionFilterProp="name"
            footer={
              showFooter && (
                <button
                  onClick={this.showCreateProspectCustomOutcome}
                  className="custom-outcome-dropdown-footer"
                  type="button"
                >
                  <p>+ Custom Outcome</p>
                </button>
              )
            }
          >
            {!selectedOptionRenderer ? (
              <div
                ref={this.elementRef}
                className={selectedOptionRendererClasses}
              >
                <>
                  {initialSelectedOption ? (
                    <div className="selected-outcome-main">
                      <div>
                        {getOutcomeIcon(selectedOption?.name, false, {
                          isDefault: selectedOption.isDefault,
                          sentiment: selectedOption.sentiment,
                        })}
                        <span className="">
                          {selectedOption?.name.length > 11 + 3
                            ? `${selectedOption?.name.substring(0, 11)}...`
                            : selectedOption?.name}
                        </span>
                      </div>
                      {!disabled && (
                        <div className="bs-select-box-icon-wrapper outcome-content-wrapper">
                          {show ? <ChevronUp /> : <ChevronDown />}
                        </div>
                      )}
                    </div>
                  ) : (
                    renderAddOutcomeDropdown()
                  )}
                </>
              </div>
            ) : (
              <div
                ref={this.elementRef}
                className={`${selectedOptionClassName} ${
                  disabled ? 'cursor-default' : ''
                }`}
              >
                {selectedOptionRenderer?.([selectedOption])}
                {!disabled && (
                  <div className="bs-select-box-icon-wrapper">
                    {show ? <ChevronUp /> : <ChevronDown />}
                  </div>
                )}
              </div>
            )}
          </Transfer>
        )}
        {showCreateProspectCustomOutcomeModal && (
          <CreateProspectCustomOutcomeModal
            show={showCreateProspectCustomOutcomeModal}
            onClose={this.hideCreateProspectCustomOutcome}
            onSave={this.onCreateProspectCustomOutcomeSubmit}
            isRequestPending={isCreateRequestPending}
          />
        )}
      </>
    );
  }
}

export default withRouter(GenericCustomOutcomeDropdown);
