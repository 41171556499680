import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';

export const getLeads = async (leadPayload) =>
  api.post<ResponseSuccess>(`/lead-finder/leads/search`, {
    ...leadPayload,
  });

export const getSavedLeads = async (leadPayload) =>
  api.get<ResponseSuccess>(
    `/lead-finder/leads?start=${leadPayload?.start}&take=${leadPayload?.take}`,
  );

export const editSavedFilterAPI = async (filterId, apiParam) =>
  api.patch(
    // eslint-disable-next-line no-underscore-dangle
    `/lead-finder/leads/filters/${filterId}`,
    apiParam,
  );

export const deleteSavedFilterAPI = async (filterId) =>
  api.delete(
    // eslint-disable-next-line no-underscore-dangle
    `/lead-finder/leads/filters/${filterId}`,
  );

export const addSavedFilterAPI = async (apiParam) =>
  api.post(`/lead-finder/leads/filters`, apiParam);

export const getSavedFilterListAPI = async () =>
  api.get(`/lead-finder/leads/filters`);

export const getFilterSuggestionAPI = async ({ type, search }) =>
  api.get(`/lead-finder/filter-suggestion/${type}?search=${search}`);

export const revealSingleLeadAPI = async (revealLeadId) =>
  api.get(`/lead-finder/leads/reveal/${revealLeadId}`);

export const leadAddToProspectAPI = async (prospectId) =>
  api.get(`/lead-finder/leads/add-to-prospect/${prospectId}`);

export const leadAddToSequenceAPI = async (leadId, payload) =>
  api.post(`/lead-finder/leads/add-to-sequence/${leadId}`, payload);

export const bulkRevealLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/bulk-actions/reveal`, payload);

export const bulkAddToProspectLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/bulk-actions/add-to-prospect`, payload);

export const bulkAddToSequenceLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/bulk-actions/add-to-sequence`, payload);

export const bulkExportLeadAPI = async (payload) =>
  api.post(`/lead-finder/leads/export`, payload);

export const exportAllLeadsAPI = async () =>
  api.post(`/lead-finder/leads/export-async`);
