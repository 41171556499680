import map from 'lodash/map';
import { FilterProperties } from '../../../../../../../shared/types/prospects-filters';
import {
  EmailFilter,
  EmailFilters,
} from '../components/email-filter-modal/types';

export const setSequenceEmailsFiltersInLocalStore = (filters: EmailFilter) => {
  localStorage.setItem('s-e-f', JSON.stringify(filters));
};

export const getSequenceEmailsFiltersFromLocalStore = (): EmailFilter =>
  JSON.parse(localStorage.getItem('s-e-f'));

export const removeSequenceEmailsFiltersFromLocalStore = (): void => {
  localStorage.removeItem('s-e-f');
};

// To separate filters when displayed on UI
export const filterSeparator = ', ';

export const emailFilterToProperties: Record<EmailFilters, FilterProperties> = {
  emailAccountIds: {
    displayNameKey: 'fromEmail',
    valueKey: 'id',
    separator: filterSeparator,
    displayLabel: 'Sender Email',
  },
  variantNumbers: {
    displayNameKey: 'value',
    valueKey: 'id',
    separator: filterSeparator,
    displayLabel: 'Email Variants',
  },
  sentAt: {
    displayNameKey: 'displayDate',
    valueKey: 'date',
    separator: ' - ',
    displayLabel: 'Sent Date',
  },
};

export const getFilterValues = (
  filterName: EmailFilters,
  filter: EmailFilter,
): string[] => map(filter, emailFilterToProperties[filterName].valueKey);

export const resolveSequenceEmailsFilters = (
  appliedFilters: Record<EmailFilters, any>,
): Record<EmailFilters, string> => {
  const payload: Record<EmailFilters, any> = {
    emailAccountIds: '',
    variantNumbers: '',
    sentAt: '',
  };

  Object.entries(appliedFilters).forEach(([filter, values]) => {
    payload[filter] = '';
    const filterValues: any = [...values];

    const filterVal = getFilterValues(
      filter as EmailFilters,
      filterValues,
    ).join(',');
    filterVal && (payload[filter] += filterVal);
  });

  return payload;
};

export const getSequenceEmailParams = (
  params: Record<string, any>,
  filters: EmailFilter,
) => {
  // Resolving the applied filters to query string
  const {
    emailAccountIds,
    variantNumbers,
    sentAt,
  } = resolveSequenceEmailsFilters(filters);

  // Adding them to params
  emailAccountIds && (params.emailAccountIds = emailAccountIds);
  variantNumbers && (params.variantNumbers = variantNumbers);
  sentAt && (params.sentAt = sentAt);

  return params;
};

export const areSequenceEmailFiltersApplied = (filters: EmailFilter): boolean =>
  Object.values(filters).some((value) => value.length);
