import React from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import ResumeSubscriptionModal from '../../../../../components/settings/components/billing-subscription/components/resume-subscription-modal.tsx';
import { IProps } from './pause-account-manager-container';
import { RequestStatus } from '../../../../enums/request-status';
import { executeOnRequestStatusWithPrevStatusCheck } from '../../../execute-on-request-status';
import toaster, { Theme } from '../../../../toaster';
import { contactAdmin } from '../../../contact-admin';
import hasPermission from '../../../access-control/has-permission';
import { Permissions } from '../../../access-control/enums/permissions';
import { handle3dSecureCardPayment } from '../../../../components/handle-3d-secure-card-payment/handle3dSecureCardPayment';

type PauseAccountManagerState = {
  showResumeSubscriptionModal: boolean;
  is3dSecureResponseStatePending: boolean;
};

class PauseAccountManager extends React.Component<
  IProps,
  PauseAccountManagerState
> {
  constructor(props) {
    super(props);

    this.state = {
      showResumeSubscriptionModal: false,
      is3dSecureResponseStatePending: false,
    };

    this.onCloseResumeSubscriptionModal = this.onCloseResumeSubscriptionModal.bind(
      this,
    );
    this.openResumeSubscriptionModal = this.openResumeSubscriptionModal.bind(
      this,
    );

    const { bindActions } = this.props;

    bindActions(this.openResumeSubscriptionModal);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      sendGetResumeSubscriptionRequestStatus,
      sendGetUserSettingsRequest,
      resetResumeSubscriptionResponse,
      sendGetResumeSubscriptionRequestError,
      sendGetResumeSubscriptionResponse,
    } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: sendGetResumeSubscriptionRequestStatus,
      prevStatus: prevProps.sendGetResumeSubscriptionRequestStatus,
      onSuccess: () => {
        this.onCloseResumeSubscriptionModal();

        if (sendGetResumeSubscriptionResponse?.requires_action) {
          this.handle3dSecureCard(sendGetResumeSubscriptionResponse);
          toaster.error(sendGetResumeSubscriptionResponse?.message, {
            theme: Theme.New,
          });
        } else {
          resetResumeSubscriptionResponse();
          sendGetUserSettingsRequest();
          toaster.success(sendGetResumeSubscriptionResponse?.message, {
            theme: Theme.New,
          });
        }
      },
      onFailed: () => {
        this.onCloseResumeSubscriptionModal();
        toaster.error(sendGetResumeSubscriptionRequestError?.message);
      },
    });
  }

  onCloseResumeSubscriptionModal = () => {
    this.setState({
      showResumeSubscriptionModal: false,
    });
  };

  openResumeSubscriptionModal = () => {
    this.setState({
      showResumeSubscriptionModal: true,
    });
  };

  handle3dSecureCard = async (response) => {
    const {
      resetResumeSubscriptionResponse,
      sendGetUserSettingsRequest,
      t,
    } = this.props;

    this.setState({ is3dSecureResponseStatePending: true });
    const { isError } = await handle3dSecureCardPayment({
      response,
    });

    if (isError) {
      toaster.error(
        'Payment failed! Please retry again after some time from billing section.',
      );
    } else {
      sendGetUserSettingsRequest();
      resetResumeSubscriptionResponse();
      toaster.success(t('messages.resume_subscription_success_message'), {
        theme: Theme.New,
      });
    }
    this.setState({ is3dSecureResponseStatePending: false });
  };

  resumeSubscription = () => {
    const {
      sendResumeSubscriptionRequest,
      customerId,
      t,
      firstName,
    } = this.props;

    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.resume_subscription_email_body',
    )}`;
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      sendResumeSubscriptionRequest({ customerId });
    } else {
      contactAdmin(t('messages.resume_subscription'), emailBody);
    }
  };

  render() {
    const {
      showResumeSubscriptionModal,
      is3dSecureResponseStatePending,
    } = this.state;
    const { sendGetResumeSubscriptionRequestStatus, pauseEndsAt } = this.props;

    return (
      <ResumeSubscriptionModal
        show={showResumeSubscriptionModal}
        onClose={this.onCloseResumeSubscriptionModal}
        onResumeSubscription={this.resumeSubscription}
        pauseEndsDate={pauseEndsAt}
        isRequestPending={
          sendGetResumeSubscriptionRequestStatus === RequestStatus.Pending ||
          is3dSecureResponseStatePending
        }
        resumeSubscriptionModalMessage1={
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? 'resume_subscription_admin_message_one'
            : 'resume_subscription_member_message_one'
        }
        resumeSubscriptionModalMessage2={
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? 'resume_subscription_admin_message_two'
            : 'resume_subscription_member_message_two'
        }
        resumeSubscriptionModalHeaderMessage="subscription_paused"
        submitButtonText={
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? 'resume_subscription'
            : 'contact_admin'
        }
      />
    );
  }
}

export default withTranslation()(withRouter(PauseAccountManager));
