import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Images } from '../../app-constants';
import Button from '../../design-system/components/atoms/button';
import Modal from '../../design-system/components/atoms/modal';
import { Permissions } from '../../utils/access-control/enums/permissions';
import hasPermission from '../../utils/access-control/has-permission';
import { contactAdmin } from '../../utils/contact-admin';
import type { IProps } from './types';
import { Routes } from '../../routes';

const RestrictionErrorModal: React.FC<IProps> = ({
  show,
  onClose,
  planName,
  restrictionOn,
  feature,
  firstName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = () => {
    const emailSubject = `${t(
      'messages.no_seat_available_email_subject_1',
    )} ${t('messages.no_seat_available_email_subject_2')}`;

    const emailBody = `Hello ${firstName}, %0D%0A %0D%0A ${t(
      'messages.no_seat_available_email_body_1',
    )} ${t('messages.no_seat_available_email_body_2')} ${t(
      'messages.no_seat_available_email_body_3',
    )} ${t('messages.no_seat_available_email_body_4')}`;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      history.push(Routes.Setting_Billing_Upgrade_Email_Outreach_Plan);
    } else {
      contactAdmin(emailSubject, emailBody);
    }
  };

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal no-seat-confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.InfoCircleYellow} alt="Alert" />
          <h2>Oops, You have reached your maximum limit!</h2>
        </div>
      }
      onSubmit={onSubmit}
      onClose={onClose}
      submitButtonText={
        hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
          ? 'Upgrade Plan'
          : t('labels.contact_admin')
      }
      cancelButtonText={t('labels.cancel')}
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
      backdrop="static"
    >
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">
          Currently, you are on Lifetime Deal with {planName}.
        </p>
        <p className="confirmation-modal-text">
          As you have reached the maximum limit of {restrictionOn} you will not
          be able to add {feature} anymore.
        </p>
      </div>
    </Modal>
  );
};

export default RestrictionErrorModal;
