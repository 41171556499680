import React from 'react';

import { IProps, IState } from './types';
import { SHLogoDark } from '../../../../shared/svg';

import toaster, { Theme } from '../../../../shared/toaster';
import ResetPasswordForm from './components/reset-password-form';
import ResetPasswordSuccess from './components/reset-password-success';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatusWithPrevStatusCheck,
} from '../../../../shared/utils';

class ResetPassword extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      showSuccessComponent: false,
      email: '',
    };

    this.setEmail = this.setEmail.bind(this);
    this.showSuccessComponent = this.showSuccessComponent.bind(this);
    this.onResendVerificationEmail = this.onResendVerificationEmail.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>): void {
    const { showSuccessComponent } = this.state;
    const { resetPasswordStatus, resetPasswordError } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: resetPasswordStatus,
      prevStatus: prevProps.resetPasswordStatus,
      onSuccess: () => {
        if (showSuccessComponent) {
          toaster.success('Email sent successfully.', {
            theme: Theme.New,
          });
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: resetPasswordError,
          onError: () => {
            toaster.error(resetPasswordError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }

  onResendVerificationEmail() {
    const { email } = this.state;
    const { sendResetPasswordRequest } = this.props;
    sendResetPasswordRequest(email);
  }

  setEmail(email: string) {
    this.setState({ email });
  }

  showSuccessComponent() {
    const { showSuccessComponent } = this.state;
    this.setState({ showSuccessComponent: !showSuccessComponent });
  }

  render() {
    const { showSuccessComponent } = this.state;

    return (
      <>
        <div className="testimonials-container">
          <SHLogoDark width={160} />
          <TestimonialsCarousel />
          <p className="copyright">
            &copy;{`${new Date().getFullYear()} Saleshandy`}
          </p>
        </div>
        {showSuccessComponent ? (
          <ResetPasswordSuccess
            showSuccessComponent={this.showSuccessComponent}
            onResendVerificationEmail={this.onResendVerificationEmail}
          />
        ) : (
          <ResetPasswordForm
            setEmail={this.setEmail}
            showSuccessComponent={this.showSuccessComponent}
          />
        )}
      </>
    );
  }
}

export default ResetPassword;
