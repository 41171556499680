import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UnifiedInboxProps } from './type';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';

const UnifiedInboxContent = React.lazy(() =>
  retryLazy(() => import('./components/unified-inbox-content')),
);

const UnifiedInbox: React.FC<UnifiedInboxProps> = () => (
  <Container fluid className="unified-inbox p-0 flex-grow-1 overflow-hidden">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/conversations" component={UnifiedInboxContent} />
          <Route
            path="/conversations/:hashId"
            component={UnifiedInboxContent}
          />
          <Redirect to="/conversations" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default UnifiedInbox;
