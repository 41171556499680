import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  getAcademyTopicsProgressRequest,
  updateAcademyTopicProgressRequest,
} from '../../extra-actions';
import type { Topic, UpdateAcademyTopicsProgressRequest } from '../../types';
import MasterClassChapterContent from './master-class-chapter-content';

const mapStateToProps = (state: RootState) => ({
  academyLessons: state.growthHub.getAcademyTopicsResponse,
  academyTopicsProgress: state.growthHub.getAcademyTopicsProgressResponse,
  updateAcademyTopicProgressRequestStatus:
    state.growthHub.updateAcademyTopicProgressRequest.status,
});

const mapDispatchToProps = {
  updateAcademyLessonProgress: (payload: UpdateAcademyTopicsProgressRequest) =>
    updateAcademyTopicProgressRequest(payload),
  getAcademyTopicsProgress: () => getAcademyTopicsProgressRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    topic: Topic;
    currentLessonId: number;
    isMaximized: boolean;
    onNextTopic: () => void;
    onPreviousTopic: () => void;
    onMaximizeMinimize: () => void;
  };

export default withRouter(connector(MasterClassChapterContent));
