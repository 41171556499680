import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  signupRequest,
  verifyUserVerificationCodeRequest,
  resendUserVerificationCodeRequest,
  onboardingDetailsRequest,
  resendVerificationEmailRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
  showError?: boolean;
};

type State = {
  signupRequest: RequestState;
  verifyUserVerificationCodeRequest: RequestState;
  resendUserVerificationCodeRequest: RequestState;
  onBoardingDetailsRequest: RequestState;
  resendVerificationEmailRequest: RequestState;
  email: string;
  token: string;
  trackingId: string;
  firstName: string;
  lastName: string;
  canResendUserVerificationCode: boolean;
};

const initialState: State = {
  signupRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
    showError: false,
  },
  verifyUserVerificationCodeRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
    showError: false,
  },
  resendUserVerificationCodeRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
  },
  onBoardingDetailsRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
  },
  resendVerificationEmailRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
  },
  email: '',
  token: '',
  trackingId: null,
  firstName: null,
  lastName: null,
  canResendUserVerificationCode: true,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    hideSignupRequestError: (state) => {
      state.signupRequest.showError = false;
    },
    hideVerifyUserVerificationCodeRequestError: (state) => {
      state.verifyUserVerificationCodeRequest.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signupRequest.pending, (state) => {
      state.signupRequest.status = RequestStatus.Pending;
      state.signupRequest.showError = false;
    });
    builder.addCase(signupRequest.fulfilled, (state, action) => {
      state.signupRequest.status = RequestStatus.Succeeded;
      state.signupRequest.message = action.payload.message;
      state.token = action.payload.payload.accessToken;
    });
    builder.addCase(signupRequest.rejected, (state, action) => {
      state.signupRequest.status = RequestStatus.Failed;
      state.signupRequest.error = action.payload;
      state.signupRequest.showError = !action.payload.isHandled;
    });

    builder.addCase(onboardingDetailsRequest.pending, (state) => {
      state.onBoardingDetailsRequest.status = RequestStatus.Pending;
      state.onBoardingDetailsRequest.showError = false;
    });
    builder.addCase(onboardingDetailsRequest.fulfilled, (state, action) => {
      state.onBoardingDetailsRequest.status = RequestStatus.Succeeded;
      state.onBoardingDetailsRequest.message = action.payload.message;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
    });
    builder.addCase(onboardingDetailsRequest.rejected, (state, action) => {
      state.onBoardingDetailsRequest.status = RequestStatus.Failed;
      state.onBoardingDetailsRequest.error = action.payload;
      state.onBoardingDetailsRequest.showError = !action.payload.isHandled;
    });

    builder.addCase(verifyUserVerificationCodeRequest.pending, (state) => {
      state.verifyUserVerificationCodeRequest.status = RequestStatus.Pending;
      state.verifyUserVerificationCodeRequest.showError = false;
    });
    builder.addCase(
      verifyUserVerificationCodeRequest.fulfilled,
      (state, action) => {
        state.verifyUserVerificationCodeRequest.status =
          RequestStatus.Succeeded;
        state.verifyUserVerificationCodeRequest.message =
          action.payload.message;
        state.token = action.payload.payload.accessToken;
        state.trackingId = action.payload.payload.trackingId;
        state.firstName = action.payload.payload.firstName;
        state.lastName = action.payload.payload.lastName;
        state.email = action.payload.payload.email;
      },
    );
    builder.addCase(
      verifyUserVerificationCodeRequest.rejected,
      (state, action) => {
        state.verifyUserVerificationCodeRequest.status = RequestStatus.Failed;
        state.verifyUserVerificationCodeRequest.error = action.payload;
        state.verifyUserVerificationCodeRequest.showError = !action.payload
          .isHandled;
      },
    );
    builder.addCase(resendUserVerificationCodeRequest.pending, (state) => {
      state.resendUserVerificationCodeRequest.status = RequestStatus.Pending;
      state.resendUserVerificationCodeRequest.error = null;
    });
    builder.addCase(
      resendUserVerificationCodeRequest.fulfilled,
      (state, action) => {
        state.resendUserVerificationCodeRequest.status =
          RequestStatus.Succeeded;
        state.resendUserVerificationCodeRequest.message =
          action.payload.message;
        state.canResendUserVerificationCode =
          action.payload.payload.canResendUserVerificationCode;
      },
    );
    builder.addCase(
      resendUserVerificationCodeRequest.rejected,
      (state, action) => {
        state.resendUserVerificationCodeRequest.status = RequestStatus.Failed;
        state.resendUserVerificationCodeRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    builder.addCase(resendVerificationEmailRequest.pending, (state) => {
      state.resendVerificationEmailRequest.status = RequestStatus.Pending;
      state.resendVerificationEmailRequest.error = null;
    });
    builder.addCase(
      resendVerificationEmailRequest.fulfilled,
      (state, action) => {
        state.resendVerificationEmailRequest.status = RequestStatus.Succeeded;
        state.resendVerificationEmailRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      resendVerificationEmailRequest.rejected,
      (state, action) => {
        state.resendVerificationEmailRequest.status = RequestStatus.Failed;
        state.resendVerificationEmailRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const {
  hideSignupRequestError,
  hideVerifyUserVerificationCodeRequestError,
} = signupSlice.actions;
export {
  signupRequest,
  verifyUserVerificationCodeRequest,
  resendUserVerificationCodeRequest,
};
export default signupSlice.reducer;
