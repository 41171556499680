import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';

import OutOfOffice from './out-of-office';
import { updateOOOSettingsRequest } from './extra-actions';
import { UpdateOOOSettingsPayload } from '../../types/out-of-office';
import { resetUpdateOOOSettingsRequest } from './out-of-office-slice';
import { getUserSettingsRequest } from '../../../home/extra-actions';

const mapStateToProps = (state: RootState) => ({
  getUserSettingsRequest: state.home.getUserSettingsRequest.status,

  updateOOOSettingsRequestStatus:
    state.outOfOffice.updateOOOSettingsRequest.status,
  updateOOOSettingsRequestMessage:
    state.outOfOffice.updateOOOSettingsRequest.message,
  updateOOOSettingsRequestError:
    state.outOfOffice.updateOOOSettingsRequest.error,

  meta: state.home.meta,
});

const mapDispatchToProps = {
  sendUpdateOOOSettingsRequest: (payload: UpdateOOOSettingsPayload) =>
    updateOOOSettingsRequest(payload),
  resetUpdateOOOSettingsRequest: () => resetUpdateOOOSettingsRequest(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(OutOfOffice);
