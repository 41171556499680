import React from 'react';

import { getUserTimeZone } from '../../../../../../../../../shared/utils/user-details';
import { getFormattedDate } from '../../../../../../users-and-teams/utils/get-formatted-date';
import { DoNotContactListEmailsAndDomains } from '../../../../../../../types/do-not-contact-list';

type IProps = {
  doNotContactListDetail: DoNotContactListEmailsAndDomains;
};

const UpdatedAtField: React.FC<IProps> = ({
  doNotContactListDetail: { createdAt },
}) => {
  const userTimezone = getUserTimeZone();

  return <p>{getFormattedDate(createdAt, userTimezone)}</p>;
};

export default UpdatedAtField;
