import { TFunction } from 'i18next';
import { isEmpty } from 'lodash';
import { CustomDomainWithEmailAccountType } from '../../types/custom-domain';

export enum ConditionTypeEnum {
  If = 'if',
  IfElse = 'if-else',
}

export const showWithCondition = (
  condition: boolean,
  ifComponent: any,
  elseComponent: any,
  conditionType: ConditionTypeEnum,
) => {
  if (conditionType === ConditionTypeEnum.If) {
    return condition && ifComponent;
  }
  return condition ? ifComponent : elseComponent;
};

export const deleteCustomDomainTitle = () =>
  'messages.delete_custom_domain_title';

export const deleteCustomDomainContent = (
  customDomain: CustomDomainWithEmailAccountType,
  t: TFunction,
) => {
  if (customDomain?.customDomain?.isDefault) {
    return [
      t('messages.delete_default_custom_domain_content_1'),
      t('messages.delete_default_custom_domain_content_2'),
    ];
  }

  return [t('messages.delete_default_custom_domain_content_2')];
};

export const sendRefetchEmailAccountSetupScoreForEmailAccounts = ({
  emailAccountToBeInserted,
  emailAccountToBeRemoved,
  sendGetEmailAccountSetupScoreRequest,
}) => {
  emailAccountToBeInserted.length > 0 &&
    emailAccountToBeInserted.forEach((emailAccountId) => {
      sendGetEmailAccountSetupScoreRequest({ emailAccountId, refresh: true });
    });
  emailAccountToBeRemoved.length > 0 &&
    emailAccountToBeRemoved.forEach((emailAccountId) => {
      sendGetEmailAccountSetupScoreRequest({ emailAccountId, refresh: true });
    });
};

export const executeVerifyAndSave = ({
  emailAccountsToBeRemoved,
  emailAccountsToBeInserted,
  modalDomain,
  newDomain,
  onSendUpdateCustomDomainRequest,
  onHideModal,
}) => {
  if (
    !(
      isEmpty(emailAccountsToBeRemoved) &&
      isEmpty(emailAccountsToBeInserted) &&
      modalDomain.domain === newDomain
    )
  ) {
    onSendUpdateCustomDomainRequest();
  } else {
    onHideModal();
  }
};

export const executeSendGetEmailAccountsListRequest = ({
  setShowCustomDomainModal,
  onRequest,
}) => {
  if (setShowCustomDomainModal) {
    onRequest();
  }
};
