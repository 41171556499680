import React, { useState, useEffect } from 'react';
import { Button, ImageIcon, SkeletonLoading } from '@saleshandy/design-system';
import { Trash } from '@saleshandy/icons';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { Images } from '../../../../../../shared/app-constants';
import type { IProps } from './domain-configuration-container';
import Input from '../../../../../../shared/design-system/components/input';
import LinkBadge from '../../../../../../shared/design-system/components/atoms/link-badge';
import DomainSetupTable from './components/domain-setup-table';
import {
  AuthHelper,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { supportUrls } from '../../../../../../shared/utils/urls';
import NotificationBanner from '../../../../../../shared/design-system/components/notification-banner';
import DeleteAlertModal from '../../../../../../shared/components/delete-alert-modal';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import planPermissionError from '../../../../../../shared/utils/errors/plan-permission-error';
import { isInternalUrl } from '../../../../../../shared/utils/is-internal-url';

const DomainConfiguration: React.FC<IProps> = ({
  isLoading,
  dnsDetails,
  getDNSDetailsRequestStatus,
  saveDNSRequestStatus,
  deleteDNSRequestStatus,
  getDNSRequest,
  saveDNSRequest,
  deleteDNSRequest,
  showGettingStartedVideoModal,
}) => {
  const [domain, setDomain] = useState<string>(dnsDetails?.domain || '');
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRefreshed, setIsRefreshed] = useState<boolean>(false);

  const removeTrailingSlash = (url) => {
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  };

  const isValidDomain = () => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
    let d = domain;

    if (d?.endsWith('/')) {
      d = removeTrailingSlash(domain);
    }

    return domainPattern.test(d);
  };

  const handleRefresh = () => {
    setIsRefreshed(true);
    getDNSRequest();
  };

  const onInputBlur = () => {
    setIsError(!isValidDomain());

    if (domain?.endsWith('/')) {
      setDomain(removeTrailingSlash(domain));
    }
  };
  const onDomainChange = (v: string) => {
    v = v.replace(/http(s?):\/\//, '').replace(/^www\./, '');
    setDomain(v);

    if (isError) {
      onInputBlur();
    }
  };

  const showUpgradePlanErrorModal = () => {
    // setIsRestrictionModalVisible(true);
    const title = 'Upgrade your plan for whitelabeling';
    const content = [
      "Your current plan doesn't allow you to rebrand yourself.",
      'Please upgrade to the outreach scale or higher plans to use Whitelabel as a feature.',
    ];

    planPermissionError.showModal(title, content, true);
  };

  const onSetupDomain = () => {
    if (!hasPermission(Permissions.WHITELABEL_ACCESS)) {
      showUpgradePlanErrorModal();
      return;
    }

    saveDNSRequest(domain);
  };

  const onDeleteDNS = () => {
    deleteDNSRequest();
  };

  const onRedirect = () =>
    window.open(`https://${dnsDetails?.domain}`, '_blank');

  //* useEffect */
  useEffect(() => {
    if (hasPermission(Permissions.WHITELABEL_ACCESS)) {
      getDNSRequest();
    }
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getDNSDetailsRequestStatus,
      onSuccess: () => {
        setDomain(dnsDetails?.domain);
        setIsRefreshed(false);
      },
    });
  }, [getDNSDetailsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteDNSRequestStatus,
      onSuccess: () => {
        toaster.success('Brand domain deleted successfully', {
          theme: Theme.New,
        });
        setDomain('');
        setIsDeleteModalVisible(false);
        getDNSRequest();

        if (!isInternalUrl() && dnsDetails.isVerified) {
          AuthHelper.logout({
            pathName: '/login',
            isBackToMainSHDomain: true,
          });
        }
      },
    });
  }, [deleteDNSRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: saveDNSRequestStatus,
      onSuccess: () => {
        getDNSRequest();
      },
    });
  }, [saveDNSRequestStatus]);

  if (getIsRequestPending(getDNSDetailsRequestStatus) && !isRefreshed) {
    return (
      <div>
        <SkeletonLoading width={1040} height={212} />
      </div>
    );
  }

  return (
    <div className="whitelabel__domain-configuration">
      {dnsDetails.isVerified ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <span className="semibold-3 line-height-20 popover-arrow-color-txt">
              Brand Domain
            </span>
            <div className="action-container--delete action-container">
              <OverlayTooltip text="Delete">
                <div
                  {...accessibleOnClick(() => setIsDeleteModalVisible(true))}
                >
                  <Trash
                    width={20}
                    height={20}
                    className="gray-txt-11 pointer"
                    onClick={() => setIsDeleteModalVisible(true)}
                  />
                </div>
              </OverlayTooltip>
            </div>
          </div>

          <div className="bs-mt-20 brand-domain">
            <Input value={dnsDetails.domain} disabled={true} />

            <div className="mt-4">
              <NotificationBanner
                variant="success"
                message="Domain setup is successfully completed!"
                submessage={
                  <span className="regular-1 popover-arrow-color-txt">
                    All set! Login with your brand domain now.{' '}
                    <span
                      className="blue-txt-11"
                      {...accessibleOnClick(onRedirect)}
                    >
                      Click here
                    </span>
                  </span>
                }
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <span className="semibold-3 line-height-24 popover-arrow-color-txt">
                Setup Your Brand Domain
              </span>
              <div>
                <OverlayTooltip text="Learn how to setup domain.">
                  <ImageIcon
                    src={Images.Youtube}
                    onClick={() => showGettingStartedVideoModal(true)}
                    alt="youtube"
                    className="ml-2 pointer"
                  />
                </OverlayTooltip>
              </div>
            </div>
            <div>
              {dnsDetails?.id && (
                <div className="action-container action-container--delete">
                  <OverlayTooltip text="Delete">
                    <div
                      {...accessibleOnClick(() =>
                        setIsDeleteModalVisible(true),
                      )}
                    >
                      <Trash
                        width={20}
                        height={20}
                        className="gray-txt-11 pointer"
                        onClick={() => setIsDeleteModalVisible(true)}
                      />
                    </div>
                  </OverlayTooltip>
                </div>
              )}
            </div>
          </div>

          <div className="whitelabel__domain-configuration-steps bs-mt-20">
            <div className="">
              <div className="d-flex align-items-center">
                <span className="whitelabel__step-number">1</span>
                <span className="regular-2 font-medium popover-arrow-color">
                  Enter your brand domain to replace all Saleshandy URLs{' '}
                </span>
              </div>

              <Input
                placeholder="E.g. app.example.com"
                value={domain}
                onChange={onDomainChange}
                onBlur={onInputBlur}
                variant={isError && Input.Variant.Error}
                caption={isError && 'Please enter a valid domain'}
                disabled={!!dnsDetails?.id}
              />
            </div>

            <div className="d-flex align-items-center mt-4">
              <span className="whitelabel__step-number">2</span>
              <span className="regular-2 font-medium popover-arrow-color mr-2">
                Log in to your domain provider (e.g. Godaddy, Namecheap) and
                navigate to the DNS settings
              </span>
              <LinkBadge
                url={supportUrls.findYourDNSProvider}
                label="Find your DNS provider"
              />
            </div>

            <div className="mt-4">
              <div className="d-flex align-items-center">
                <div className="whitelabel__step-number">
                  <span className="regular-2 line-height-20 popover-arrow-color">
                    3
                  </span>
                </div>
                <span className="regular-2 font-medium popover-arrow-color">
                  Add a CNAME record in your DNS settings by copying the below
                  details
                </span>
              </div>
              <div>
                <DomainSetupTable
                  domain={domain}
                  onRefresh={handleRefresh}
                  isVerified={dnsDetails?.isVerified}
                  isDomainSetup={!!dnsDetails?.id}
                  isRefreshed={isRefreshed}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 bs-ml-36">
            <Button
              onClick={onSetupDomain}
              disabled={!isValidDomain() || !!dnsDetails?.id}
              isLoading={isLoading}
            >
              Setup Domain
            </Button>
          </div>

          {isLoading && (
            <div className="mt-4 bs-ml-36">
              <NotificationBanner
                message="Domain setup is in progress!"
                submessage="It can take up to 48 hours. Explore the product in the meantime, and we'll notify you when your brand domain is ready."
              />
            </div>
          )}
        </>
      )}

      {isDeleteModalVisible && (
        <DeleteAlertModal
          show={isDeleteModalVisible}
          title="Delete brand domain"
          contents={[
            'Are you sure you want to delete your brand domain?',
            'This will remove your brand domain and revert back to the default urls.',
          ]}
          isSubmitLoading={getIsRequestPending(deleteDNSRequestStatus)}
          onClose={() => setIsDeleteModalVisible(false)}
          onSubmit={onDeleteDNS}
        />
      )}
    </div>
  );
};

export default DomainConfiguration;
