import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateCustomFieldRequestPayload,
  UpdateCustomFieldRequestPayload,
} from '../../types/request-payload';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';
import {
  getCustomFields,
  createCustomField,
  updateCustomField,
  deleteCustomField,
  getCustomFieldsWithPagination,
  getDefaultFieldsWithPagination,
  getAllFields,
} from '../../helpers/field.api';
import { PaginationQueryParams } from '../../../../shared/types/request';

export const getCustomFieldRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('customField/getCustomFieldRequest', async (args, thunkAPI) => {
  try {
    return await getCustomFields();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getCustomFieldsWithPaginationRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'customField/getCustomFieldsWithPaginationRequest',
  async (args, thunkAPI) => {
    try {
      return await getCustomFieldsWithPagination(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getDefaultFieldsWithPaginationRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams,
  { rejectValue: ResponseErrorWithHandled }
>(
  'customField/getDefaultFieldsWithPaginationRequest',
  async (args, thunkAPI) => {
    try {
      return await getDefaultFieldsWithPagination(args);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createCustomFieldRequest = createAsyncThunk<
  ResponseSuccess,
  CreateCustomFieldRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('customField/createCustomFieldRequest', async (payload, thunkAPI) => {
  try {
    return await createCustomField(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

type UpdateCustomFieldPayloadCreatorArgs = UpdateCustomFieldRequestPayload & {
  customFieldId: number;
};

export const updateCustomFieldRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateCustomFieldPayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'customField/updateCustomFieldRequest',
  async ({ customFieldId, ...payload }, thunkAPI) => {
    try {
      return await updateCustomField(customFieldId, payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const deleteCustomFieldRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('customField/deleteCustomFieldRequest', async (customFieldId, thunkAPI) => {
  try {
    return await deleteCustomField(customFieldId);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAllFieldsRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams,
  { rejectValue: ResponseErrorWithHandled }
>('api-token/getAllFieldsRequest', async (args, thunkAPI) => {
  try {
    return await getAllFields(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
