import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteMyProfile,
  getMyProfile,
  resendOtpForChangeEmail,
  sendOtpForChangeEmail,
  updateMyProfile,
  updatePassword,
  verifyCurrentPassword,
  verifyOtpForChangeEmail,
} from '../../helpers/my-profile.api';
import {
  UpdateUserProfilePayload,
  UpdatePasswordPayload,
  verifyCurrentPasswordPayload,
} from '../../types/request-payload';

import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

export const getMyProfileRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/getMyProfileRequest', async (args, thunkAPI) => {
  try {
    return await getMyProfile();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateMyProfileRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateUserProfilePayload,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/updateMyProfileRequest', async (payload, thunkAPI) => {
  try {
    return await updateMyProfile(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updatePasswordRequest = createAsyncThunk<
  ResponseSuccess,
  UpdatePasswordPayload,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/updatePasswordRequest', async (payload, thunkAPI) => {
  try {
    return await updatePassword(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const verifyCurrentPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  verifyCurrentPasswordPayload,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/verifyCurrentPassword', async (payload, thunkAPI) => {
  try {
    return await verifyCurrentPassword(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const sendOtpForChangeEmailRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/sendOtpForChangeEmail', async (payload, thunkAPI) => {
  try {
    return await sendOtpForChangeEmail(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resendOtpForChangeEmailRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/resendOtpForChangeEmail', async (_payload, thunkAPI) => {
  try {
    return await resendOtpForChangeEmail();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const verifyOtpForChangeEmailRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/verifyOtpForChangeEmail', async (payload, thunkAPI) => {
  try {
    return await verifyOtpForChangeEmail(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteMyProfileRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('myProfile/deleteMyProfile', async (payload, thunkAPI) => {
  try {
    return await deleteMyProfile(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
