import store from '../../store/store';

export const contactAdmin = (emailSubject, emailBody, supportEmail = null) => {
  const { home } = store.getState();

  const params = new URLSearchParams('');
  let emailToSend = home?.adminDetails?.email;

  if (supportEmail) {
    emailToSend = supportEmail;
  }
  params.set('to', emailToSend || '');

  const encodedAdminEmail = params.toString().split('=')[1];

  const mailString = `mailto:${encodedAdminEmail}?subject=${emailSubject}&body=${emailBody}`;

  window.open(mailString, '_blank');
};
