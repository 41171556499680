import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../input';
import { IconPlace } from '../../input/enums';
import { InputIconConfig } from '../../input/types';

type IProps = {
  onSearch: () => void;
  setSearchValue: (value: string) => void;
  initialValue?: string;
  searchValue: string;
  onClearSearch?: () => void;
  placeholder?: string;
  className?: string;
  hideClear?: boolean;
  searchIconPlace?: IconPlace;
  searchIconFixed?: boolean;
  inputRef?: any;
};

const SearchInput: React.FC<IProps> = ({
  onSearch,
  setSearchValue,
  searchValue,
  initialValue = '',
  onClearSearch,
  placeholder,
  className,
  hideClear,
  searchIconPlace,
  searchIconFixed,
  inputRef,
}) => {
  const { t } = useTranslation();
  const [showIconOnSearch, setShowIconOnSearch] = useState<InputIconConfig>({
    place: searchIconPlace || Input.IconPlace.Right,
    identifier: 'search',
  });

  const [fixIconOnSearch, setFixIconOnSearch] = useState<any>([
    {
      place: searchIconPlace || Input.IconPlace.Right,
      identifier: 'search',
    },
  ]);

  const clearSearch = () => {
    setSearchValue('');
    setShowIconOnSearch({
      place: searchIconPlace || Input.IconPlace.Right,
      identifier: 'search',
    });
    setFixIconOnSearch([
      {
        place: searchIconPlace || Input.IconPlace.Right,
        identifier: 'search',
      },
    ]);
    onClearSearch();
  };

  const onSearchChangeHandler = (value: string) => {
    if (searchIconFixed) {
      if (value === '') {
        setFixIconOnSearch([
          {
            place: searchIconPlace || Input.IconPlace.Right,
            identifier: 'search',
          },
        ]);
      } else {
        setFixIconOnSearch([
          {
            place: Input.IconPlace.Right,
            identifier: 'close-o',
            onClick: clearSearch,
          },
          {
            place: searchIconPlace || Input.IconPlace.Right,
            identifier: 'search',
          },
        ]);
      }
    }
    if (!hideClear && value && value.length > 0) {
      setShowIconOnSearch({
        place: Input.IconPlace.Right,
        identifier: 'close-o',
        onClick: clearSearch,
      });
    } else {
      setShowIconOnSearch({
        place: searchIconPlace || Input.IconPlace.Right,
        identifier: 'search',
      });
    }
    setSearchValue(value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  useEffect(() => {
    if (initialValue !== '') {
      onSearchChangeHandler(initialValue);
    }
    if (searchValue === '') {
      setFixIconOnSearch([
        {
          place: searchIconPlace || Input.IconPlace.Right,
          identifier: 'search',
        },
      ]);
    }
  }, [initialValue]);

  // useEffect(() => {
  //   if (!inputRef.current) {
  //     setFixIconOnSearch([
  //       {
  //         place: searchIconPlace || Input.IconPlace.Right,
  //         identifier: 'search',
  //       },
  //     ]);
  //   }
  // }, [inputRef]);

  return (
    <form onSubmit={onSubmit}>
      <Input
        value={searchValue}
        ref={inputRef}
        placeholder={placeholder || t('labels.search_hit_enter').toString()}
        onChange={onSearchChangeHandler}
        icons={searchIconFixed ? fixIconOnSearch : [showIconOnSearch]}
        className={`search-input ${className}`}
        onBlur={() => {
          onSearchChangeHandler(searchValue);
        }}
      />
    </form>
  );
};

export default SearchInput;
