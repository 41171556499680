import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../store/root-reducer';
import Tasks from './tasks';
import { getTasksRequest, getTaskStatusCountRequest } from './extra-actions';
import { GetTasksParams } from './utils/types';
import { resetTasksState } from './tasks-slice';

const mapStateToProps = (state: RootState) => ({
  getTasksRequestStatus: state.tasks.getTasksRequest.status,
  updateTasksRequestStatus: state.tasks.updateTasksRequest.status,
  isMultichannelActive: state.home.isMultichannelActive,
  tasksFilters: state.tasks.tasksFilters,
});

const mapDispatchToProps = {
  getTasksStatusCountRequest: (params: GetTasksParams) =>
    getTaskStatusCountRequest(params),
  getTasksRequest: (params: GetTasksParams) => getTasksRequest(params),
  resetTasksState: () => resetTasksState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(Tasks));
