import React from 'react';
import { Avatar } from '@saleshandy/design-system';
import { DoNotContactListEmailsAndDomains } from '../../../../../../../types/do-not-contact-list';

type IProps = {
  doNotContactListDetail: DoNotContactListEmailsAndDomains;
};

const AddedByField: React.FC<IProps> = ({
  doNotContactListDetail: { addedBy },
}) => {
  const [firstName = '', lastName = ''] = addedBy?.split(' ');
  return (
    <div className="added-by-field d-flex align-items-center">
      <Avatar firstName={firstName} lastName={lastName} />
      <p className="ml-2">{`${addedBy}`.trim()}</p>
    </div>
  );
};

export default AddedByField;
