/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import { Headset as HeadsetIcon } from '@saleshandy/icons';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const HeadsetTooltip = (popoverText) => (
  <Tooltip id="popover-basic" className="bs-tooltip-inner">
    {popoverText}
  </Tooltip>
);

const Headset = () => {
  const redirectToScheduleDemo = () => {
    window.open(process.env.REACT_APP_SCHEDULE_DEMO_URL, '_blank');
  };

  const { t } = useTranslation();

  return (
    <OverlayTrigger
      overlay={HeadsetTooltip(t('labels.scheduleADemo'))}
      placement="bottom"
    >
      <div
        style={{ cursor: 'pointer' }}
        className="d-flex hover-on-header"
        onClick={redirectToScheduleDemo}
        onKeyPress={redirectToScheduleDemo}
        role="button"
      >
        <HeadsetIcon height={20} width={20} className="d-flex headset" />
      </div>
    </OverlayTrigger>
  );
};

export default Headset;
