import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';

const AgencyClientList = React.lazy(() =>
  retryLazy(() => import('./components/agency-client-list')),
);

const AgencyClient: React.FC = () => (
  <Container fluid className="agency-client-container p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/clients" component={AgencyClientList} />
          <Redirect to="/clients" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default AgencyClient;
