/* eslint-disable consistent-return */
import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import CellCard from '../../../../../../../shared/design-system/components/atoms/cell-card';
import CustomDomainValidIcon from './custom-domain-valid-icon';

const customDomainColumnData = [
  {
    dataField: 'customDomain',
    text: 'Domain Name',
    headerAlign: 'left',
    cellClasses: 'name-field',
    component: (cell) => <CustomDomainValidIcon customDomain={cell} />,
    headerLoadingSkeleton: <SkeletonLoading width={54} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
  },
  {
    dataField: 'emailAccounts',
    text: 'Email',
    headerAlign: 'left',
    component: (cell) => {
      if (cell && cell.length > 0) {
        const mappedCell = cell.map((emailAccount) => ({
          value: emailAccount.email,
        }));
        return (
          <CellCard
            list={mappedCell}
            bulkText="Email Accounts"
            isOutcomeVisible={false}
          />
        );
      }
    },
    headerLoadingSkeleton: <SkeletonLoading width={54} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
  },
];

export default customDomainColumnData;
