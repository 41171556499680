import validator from 'validator';

export const validate = (name, value) => {
  switch (name) {
    case 'fieldName':
      if (validator.isEmpty(value)) {
        return 'provide_outcome_name';
      }
      if (value.includes(',')) {
        return 'invalid_outcome_name';
      }
      if (!validator.isLength(value, { max: 20, min: 1 })) {
        return 'outcome_field_name';
      }
      break;
    default:
      break;
  }

  return '';
};
