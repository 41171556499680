import { isEmpty } from 'lodash';
import {
  OnboardingRoutes,
  OnboardingSteps,
  UserSetting,
  UserSettingCode,
} from '../types/user-setting';
import { redirectWithoutRefresh } from './redirect';

export const handleOnboarding = (
  meta: UserSetting[],
  currentPath: string,
  search = '',
  isLeadUrl = false,
) => {
  const onboardingStep = meta.find(
    (setting) => setting.code === UserSettingCode.Onboarding,
  );

  if (onboardingStep) {
    if (currentPath !== OnboardingRoutes[onboardingStep.value]) {
      if (onboardingStep.value === OnboardingSteps.Step0) {
        redirectWithoutRefresh({
          pathname: './LTD-Checkout',
          search,
        });
      } else if (
        (onboardingStep.value === OnboardingSteps.Step1 ||
          onboardingStep.value === OnboardingSteps.Step2) &&
        !isLeadUrl
      ) {
        redirectWithoutRefresh({
          pathname: './sequence',
          search,
        });
      } else if (
        (onboardingStep.value === OnboardingSteps.Step1 ||
          onboardingStep.value === OnboardingSteps.Step2) &&
        isLeadUrl
      ) {
        redirectWithoutRefresh({
          pathname: './leads',
          search,
        });
      } else if (window.location.search) {
        // If there are query parameters in URL, instead of redirecting to "/sequence" in else condition, we want to skip the redirection for url parameters not to lost.
        //  Skip redirect
      } else {
        redirectWithoutRefresh(OnboardingRoutes[onboardingStep.value]);
      }
    }
  }
};

export const compareOnboardingStep = (
  meta,
  onboardingStepToCheck: OnboardingSteps,
) => {
  if (isEmpty(meta)) {
    return false;
  }

  const onboardingStep = meta.find(
    (setting) =>
      setting.code === UserSettingCode.Onboarding &&
      setting.value === onboardingStepToCheck,
  );
  return !!onboardingStep;
};
