import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';
import validator from 'validator';

import type { NewEmailProps } from '../types';

import Input from '../../../../../../../shared/design-system/components/input';

import Title from './sub-components/title';
import Description from './sub-components/description';
import Action from './sub-components/action';
import Footer from './sub-components/footer';
import Divider from './sub-components/divider';
import { getIsRequestPending } from '../../../../../../../shared/utils';

const NewEmail: React.FC<NewEmailProps> = ({
  email: currentEmail,
  newEmail,
  onCancel,
  onSubmit,
  sendOtpForChangeEmailRequestStatus,
  error,
  resetSendOtpForChangeEmailRequest,
}) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const onEmailChange = (value: string) => {
    if (currentEmail === value) {
      setEmailError('The email address you entered already exists.');
    } else {
      setEmailError('');
    }
    setEmail(value);

    if (error) {
      resetSendOtpForChangeEmailRequest();
    }
  };

  const validateEmail = () => {
    if (!validator.isEmail(email)) {
      setEmailError('Please enter a valid email address.');
      return false;
    }
    if (currentEmail === email) {
      setEmailError('The email address you entered already exists.');
      return false;
    }
    return true;
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter' && validateEmail()) {
      onSubmit(email);
    }
  };

  const handleOnSubmit = () => {
    if (validateEmail()) {
      onSubmit(email);
    }
  };

  useEffect(() => {
    if (newEmail !== '') setEmail(newEmail);
  }, [newEmail]);

  useEffect(() => {
    if (error && error.code === 1001) setEmailError(error.message);
  }, [error]);

  useEffect(
    () => () => {
      setEmail('');
      setEmailError('');
    },
    [],
  );

  const isSendOtpForChangeEmailRequestPending = getIsRequestPending(
    sendOtpForChangeEmailRequestStatus,
  );

  return (
    <>
      <Title>
        <p>
          Your current email is <span className="bold">{currentEmail}</span>
        </p>
      </Title>

      <Description content="Please enter a new email account and we will send you a verification code on this." />

      <Action>
        <Input
          name="email"
          label="New Email"
          type="email"
          placeholder="johndoe@example.com"
          value={email}
          onChange={onEmailChange}
          autoComplete="current-email"
          className="my-profile-input modal-input"
          variant={!!emailError && Input.Variant.Error}
          caption={emailError}
          onKeyPress={onKeyPress}
        />
      </Action>

      <Divider />

      <Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
          className="secondary-cta-btn"
          disabled={isSendOtpForChangeEmailRequestPending}
        >
          Cancel
        </Button>
        <Button
          loadingText="Sending Code..."
          isLoading={isSendOtpForChangeEmailRequestPending}
          disabled={
            !email || !!emailError || isSendOtpForChangeEmailRequestPending
          }
          onClick={handleOnSubmit}
        >
          Next
        </Button>
      </Footer>
    </>
  );
};

export default NewEmail;
