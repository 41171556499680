import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

const WebhookCodeBlockLoader: React.FC = () => (
  <div className="webhook-req-res-preview">
    <div className="webhook-req-res-preview--header mb-24px">
      <div className="header-left">
        <SkeletonLoading width={160} height={20} />
      </div>
      <div className="header-right">
        <div className="webhook-response-cta">
          <SkeletonLoading width={20} height={20} />
        </div>

        <div className="webhook-response-cta">
          <SkeletonLoading width={20} height={20} />
        </div>
      </div>
    </div>

    <div className="webhook-req-res-preview--header">
      <div className="header-left">
        <SkeletonLoading width={100} height={20} />
        <SkeletonLoading width={120} height={20} borderRadius={10} />
      </div>
    </div>

    <div className="webhook-req-res-preview--code code-loader">
      <SkeletonLoading height={150} />
    </div>

    <div className="webhook-req-res-preview--header mt-3">
      <div className="header-left">
        <SkeletonLoading width={100} height={20} />
      </div>
    </div>

    <div className="webhook-req-res-preview--code code-loader">
      <SkeletonLoading height={150} />
    </div>
  </div>
);

export default WebhookCodeBlockLoader;
