/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import Modal from '../../design-system/components/atoms/modal';
import {
  getInitialOnboardingValues,
  getOnboardingQuestions,
  getTotalQuestionCount,
  isOutreachLead,
} from './onboarding-questions';
import { OnboardingQuestionOption, OnboardingQuestionType } from './type';
import { OnBoardingRequest } from '../../../components/auth/types';
import { getIsRequestPending } from '../../utils';
import { RequestStatus } from '../../enums/request-status';
import {
  OnboardingType,
  UsagePreferenceOptions,
} from '../../enums/onboarding-question-options';
import { getPercentageValue } from '../../../components/home/components/header/utils/helper';
import ProgressBar from './components/progress-bar';
import { accessibleOnClick } from '../../utils/accessible-on-click';
import { ArrowRight, ArrowLeft } from '../../svg';

type IProps = {
  show: boolean;
  firstName: string;
  sendOnboardingRequest: (payload: OnBoardingRequest) => void;
  onBoardingRequestStatus: RequestStatus;
  trackingId: string;
  shAccountId: number;
  checkIsLeadFinderICP?: (p: boolean) => void;
};

const OnboardingModal: React.FC<IProps> = ({
  show,
  firstName,
  sendOnboardingRequest,
  onBoardingRequestStatus,
  checkIsLeadFinderICP,
}) => {
  const isLoading = getIsRequestPending(onBoardingRequestStatus);
  const [onboardingType, setOnboardingType] = useState<OnboardingType>(null);

  let onboardingQuestions = getOnboardingQuestions(onboardingType);

  const [
    currentQuestion,
    setCurrentQuestion,
  ] = useState<OnboardingQuestionType>(onboardingQuestions[0]);
  const [selectedOptions, setSelectedOptions] = useState(
    getInitialOnboardingValues(onboardingType),
  );
  const [otherOptionInput, setOtherOptionInput] = useState('');
  const [otherOptionInputError, setOtherOptionInputError] = useState(false);

  const getCurrentQuestionNumber = (value: string) => {
    if (onboardingType === OnboardingType.Individual) {
      if (value === UsagePreferenceOptions.FindLead) {
        return onboardingQuestions.find(
          (question) => question.key === 'leadsSizeCount',
        );
      }

      if (currentQuestion?.questionNumber === 5) {
        return onboardingQuestions[currentQuestion?.questionNumber + 1];
      }
      return onboardingQuestions[currentQuestion?.questionNumber];
    }

    if (
      onboardingType === OnboardingType.Business &&
      isOutreachLead(selectedOptions?.useCase) &&
      currentQuestion?.key === 'priorColdEmailToolExperience'
    ) {
      return onboardingQuestions.find(
        (question) => question.key === 'usagePreference',
      );
    }

    return onboardingQuestions[currentQuestion?.questionNumber];
  };

  const onSelectedOptionChanged = (value: string) => {
    if (isLoading) {
      return;
    }

    if (value === UsagePreferenceOptions.FindLead) {
      checkIsLeadFinderICP(true);
    }

    if (currentQuestion?.key === 'userType' && onboardingType !== value) {
      onboardingQuestions = getOnboardingQuestions(value as OnboardingType);
      const sltOptions = getInitialOnboardingValues(value as OnboardingType);

      setOnboardingType(value as OnboardingType);
      setSelectedOptions({ ...sltOptions, [currentQuestion?.key]: value });
      setCurrentQuestion(onboardingQuestions[0]);
    } else {
      setSelectedOptions({
        ...selectedOptions,
        [currentQuestion?.key]: value,
      });
    }

    if (
      currentQuestion?.questionNumber !==
      onboardingQuestions[onboardingQuestions.length - 1].questionNumber
    ) {
      setCurrentQuestion(getCurrentQuestionNumber(value));
    }
  };

  const onTextOptionSelected = (value: string) => {
    if (isLoading) {
      return;
    }

    setSelectedOptions({
      ...selectedOptions,
      [currentQuestion?.key]: value,
    });
  };

  const handleTextOptionSubmit = () => {
    if (selectedOptions[currentQuestion?.key] === 'Others') {
      if (otherOptionInput.length >= 1 && otherOptionInput.length <= 64) {
        if (isLoading) {
          return;
        }

        const newObj = Object.fromEntries(
          Object.entries(selectedOptions).filter(
            ([key, value]) => value !== '',
          ),
        );

        const payload = {
          ...newObj,
          sourceDetails: otherOptionInput,
        };

        sendOnboardingRequest(payload);
      } else {
        setOtherOptionInputError(true);
      }
    }
  };

  const previousQuestion = () => {
    if (
      onboardingType === OnboardingType.Business &&
      isOutreachLead(selectedOptions?.useCase) &&
      currentQuestion?.questionNumber === 6
    ) {
      setCurrentQuestion(onboardingQuestions[2]);
      return;
    }

    setCurrentQuestion(
      onboardingQuestions[currentQuestion?.questionNumber - 2],
    );
  };

  const nextQuestion = () => {
    if (
      onboardingType === OnboardingType.Business &&
      isOutreachLead(selectedOptions?.useCase) &&
      currentQuestion?.questionNumber === 3
    ) {
      setCurrentQuestion(onboardingQuestions[5]);
      return;
    }

    setCurrentQuestion(onboardingQuestions[currentQuestion?.questionNumber]);
  };

  const isNextButtonDisabled = () => {
    if (currentQuestion?.questionNumber === onboardingQuestions.length) {
      if (
        selectedOptions[currentQuestion?.key] === 'Others' &&
        otherOptionInput !== ''
      ) {
        return false;
      }

      return true;
    }

    if (selectedOptions[currentQuestion?.key]) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (
      show &&
      currentQuestion?.questionNumber - 1 >=
        getTotalQuestionCount(onboardingType) &&
      selectedOptions[currentQuestion?.key] &&
      selectedOptions[currentQuestion?.key] !== 'Others'
    ) {
      const newObj = Object.fromEntries(
        Object.entries(selectedOptions).filter(([key, value]) => value !== ''),
      );

      const payload = {
        ...newObj,
      };

      sendOnboardingRequest(payload);
    }
  }, [selectedOptions]);

  if (!currentQuestion) {
    return null;
  }

  return (
    <Modal
      show={show}
      hideHeader
      hideFooter
      backdrop="static"
      extraModalProps={{
        centered: true,
        'aria-labelledby': 'contained-modal-title-vcenter',
      }}
      className={`onboarding-modal--wrapper ${
        currentQuestion?.questionNumber === 1
          ? 'onboarding-modal-q-1--wrapper'
          : 'onboarding-modal-q--wrapper'
      }`}
    >
      {currentQuestion?.questionNumber === 1 && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="d-flex flex-column text-center">
            <h1 className="semibold-2 blue-txt-31">
              Hey <span>{`${firstName},`}</span>
            </h1>
            <div className="d-flex flex-column">
              <h1 className="semibold-5 popover-arrow-color-txt">
                Let’s shape your experience
              </h1>
              <span className="regular-2 font-medium gray-txt-15">
                Whom you are going to use it for?
              </span>
            </div>
          </div>

          <div className="radio-container">
            {currentQuestion?.options.map(
              (option: OnboardingQuestionOption) => (
                <label
                  key={option.value}
                  className={`cs-radio-wrapper d-flex align-items-center  ${
                    selectedOptions[currentQuestion?.key] === option.value &&
                    'selected'
                  }`}
                >
                  <div className="radio d-flex">
                    <input
                      name="radio"
                      type="radio"
                      onClick={() => onSelectedOptionChanged(option.value)}
                      checked={
                        selectedOptions[currentQuestion?.key] === option.value
                      }
                    />
                    <div className="d-flex flex-column option">
                      <span className="semibold-2 popover-arrow-color-txt">
                        {option.label}
                      </span>
                      <span className="regular-2 gray-txt-11 line-height-18">
                        {option?.description}
                      </span>
                    </div>
                  </div>
                </label>
              ),
            )}
          </div>
        </div>
      )}

      {currentQuestion?.questionNumber > 1 && (
        <div>
          <div
            key={currentQuestion?.questionNumber}
            className="onboarding-modal--question-wrapper"
          >
            <div>
              <h1 className="onboarding-modal--question">
                {currentQuestion?.question}
              </h1>
              <div className="onboarding-modal--options">
                {currentQuestion?.options.map(
                  (option: OnboardingQuestionOption) =>
                    option?.type === 'text' ? (
                      <div
                        key={option.value}
                        className={classNames([
                          'onboarding-modal--option onboarding-modal--text-option',
                          {
                            active:
                              selectedOptions[currentQuestion?.key] ===
                              option.value,
                          },
                        ])}
                        {...accessibleOnClick(() =>
                          onTextOptionSelected(option.value),
                        )}
                      >
                        <input
                          type="text"
                          placeholder="Other, write here.."
                          value={otherOptionInput}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setOtherOptionInput(event.target.value);
                            if (
                              event.target.value.length >= 1 &&
                              event.target.value.length <= 64
                            ) {
                              setOtherOptionInputError(false);
                            }
                          }}
                          onKeyDown={(
                            event: React.KeyboardEvent<HTMLInputElement>,
                          ) => {
                            if (event.key === 'Enter') {
                              handleTextOptionSubmit();
                            }
                          }}
                        />
                        {otherOptionInputError && (
                          <span className="error-message">
                            Should be between 1-64 characters.
                          </span>
                        )}
                      </div>
                    ) : (
                      <button
                        key={option.value}
                        className={classNames([
                          'onboarding-modal--option',
                          {
                            active:
                              selectedOptions[currentQuestion?.key] ===
                              option.value,
                          },
                        ])}
                        tabIndex={0}
                        type="button"
                        onClick={() => onSelectedOptionChanged(option.value)}
                      >
                        <span>{option.value}</span>
                      </button>
                    ),
                )}
              </div>
            </div>

            <div className="d-flex flex-column align-items-center">
              <ProgressBar
                percentage={getPercentageValue(
                  currentQuestion?.questionNumber - 1,
                  getTotalQuestionCount(onboardingType),
                )}
              />
              <div className="d-flex">
                <div {...accessibleOnClick(() => previousQuestion())}>
                  <ArrowLeft
                    width={28}
                    height={28}
                    className="popover-arrow-color-txt pointer"
                  />
                </div>
                <div
                  className={`${
                    isNextButtonDisabled() ? 'disable' : 'pointer'
                  }`}
                  {...accessibleOnClick(() => {
                    if (
                      (onboardingType === OnboardingType.Individual &&
                        currentQuestion?.key === 'source') ||
                      currentQuestion.questionNumber ===
                        onboardingQuestions.length
                    ) {
                      handleTextOptionSubmit();
                    } else {
                      nextQuestion();
                    }
                  })}
                >
                  <ArrowRight
                    width={28}
                    height={28}
                    className={`${
                      isNextButtonDisabled()
                        ? 'indigo-txt-2 disable'
                        : 'popover-arrow-color-txt '
                    }  ml-4`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default OnboardingModal;
