import * as React from 'react';

export const VariantsList = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={200}
    height={133}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="#fff"
        stroke="#EFF6FF"
        d="M11 3.5h135a4.5 4.5 0 0 1 4.5 4.5v25a4.5 4.5 0 0 1-4.5 4.5H11A4.5 4.5 0 0 1 6.5 33V8A4.5 4.5 0 0 1 11 3.5Z"
      />
      <rect width={18} height={18} x={26} y={11.5} fill="#EFF6FF" rx={4} />
      <path
        fill="#1D4ED8"
        d="M32.481 24.5h-1.687l3.073-8.727h1.951l3.077 8.727h-1.688l-2.33-6.938h-.069L32.481 24.5Zm.056-3.422h4.602v1.27h-4.602v-1.27Z"
      />
    </g>
    <path fill="url(#b)" d="M83 11H57a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="url(#c)" d="M101 24H57a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <g filter="url(#d)">
      <path
        fill="#fff"
        stroke="#EFF6FF"
        d="M54 46.5h135a4.5 4.5 0 0 1 4.5 4.5v25a4.5 4.5 0 0 1-4.5 4.5H54a4.5 4.5 0 0 1-4.5-4.5V51a4.5 4.5 0 0 1 4.5-4.5Z"
      />
      <rect width={18} height={18} x={69} y={54.5} fill="#EFF6FF" rx={4} />
      <path
        fill="#1D4ED8"
        d="M74.86 67.5v-8.727h3.342c.63 0 1.154.1 1.572.298.42.196.734.465.942.805.21.341.315.728.315 1.16 0 .355-.068.659-.204.911a1.72 1.72 0 0 1-.55.61c-.23.156-.487.268-.771.337v.085c.31.017.606.112.89.285.287.17.522.412.703.725.182.312.273.69.273 1.133 0 .452-.11.858-.328 1.219-.219.358-.548.64-.989.848-.44.207-.994.311-1.662.311h-3.532Zm1.582-1.321h1.7c.574 0 .987-.11 1.24-.328.256-.222.384-.506.384-.852a1.305 1.305 0 0 0-.738-1.194 1.812 1.812 0 0 0-.843-.183h-1.743v2.557Zm0-3.695h1.564c.272 0 .518-.05.737-.149.219-.102.39-.245.516-.43a1.15 1.15 0 0 0 .191-.665c0-.338-.119-.616-.358-.835-.236-.219-.586-.328-1.052-.328h-1.598v2.407Z"
      />
    </g>
    <path fill="url(#e)" d="M126 54h-26a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="url(#f)" d="M144 67h-44a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <g filter="url(#g)">
      <path
        fill="#fff"
        stroke="#EFF6FF"
        d="M11 89.5h135a4.5 4.5 0 0 1 4.5 4.5v25a4.5 4.5 0 0 1-4.5 4.5H11a4.5 4.5 0 0 1-4.5-4.5V94a4.5 4.5 0 0 1 4.5-4.5Z"
      />
      <rect width={18} height={18} x={26} y={97.5} fill="#EFF6FF" rx={4} />
      <path
        fill="#1D4ED8"
        d="M38.831 104.717h-1.594a1.976 1.976 0 0 0-.251-.694 1.863 1.863 0 0 0-1.078-.84 2.423 2.423 0 0 0-.746-.111c-.474 0-.895.12-1.261.358-.367.236-.654.583-.861 1.04-.208.455-.311 1.01-.311 1.666 0 .668.103 1.23.31 1.688.211.454.498.798.862 1.031.366.23.785.345 1.257.345.261 0 .505-.034.733-.102.23-.071.436-.175.617-.311a1.897 1.897 0 0 0 .729-1.185l1.594.009c-.06.415-.189.804-.388 1.167a3.43 3.43 0 0 1-1.888 1.611c-.426.154-.899.23-1.419.23-.767 0-1.451-.177-2.054-.532-.602-.355-1.076-.868-1.423-1.539-.346-.67-.52-1.474-.52-2.412 0-.94.175-1.744.524-2.412.35-.67.826-1.183 1.428-1.538s1.284-.533 2.045-.533c.486 0 .938.069 1.355.205.418.136.79.337 1.117.601.327.261.595.582.805.963.213.378.353.81.418 1.295Z"
      />
    </g>
    <path fill="url(#h)" d="M83 97H57a3 3 0 1 0 0 6h26a3 3 0 1 0 0-6Z" />
    <path fill="url(#i)" d="M101 110H57a3 3 0 1 0 0 6h44a3 3 0 1 0 0-6Z" />
    <defs>
      <linearGradient
        id="b"
        x1={70}
        x2={70}
        y1={11}
        y2={17}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBEAFE" />
        <stop offset={1} stopColor="#EFF6FF" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={79}
        x2={79}
        y1={24}
        y2={30}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBEAFE" />
        <stop offset={1} stopColor="#EFF6FF" />
      </linearGradient>
      <linearGradient
        id="e"
        x1={113}
        x2={113}
        y1={54}
        y2={60}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBEAFE" />
        <stop offset={1} stopColor="#EFF6FF" />
      </linearGradient>
      <linearGradient
        id="f"
        x1={122}
        x2={122}
        y1={67}
        y2={73}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBEAFE" />
        <stop offset={1} stopColor="#EFF6FF" />
      </linearGradient>
      <linearGradient
        id="h"
        x1={70}
        x2={70}
        y1={97}
        y2={103}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBEAFE" />
        <stop offset={1} stopColor="#EFF6FF" />
      </linearGradient>
      <linearGradient
        id="i"
        x1={79}
        x2={79}
        y1={110}
        y2={116}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#DBEAFE" />
        <stop offset={1} stopColor="#EFF6FF" />
      </linearGradient>
      <filter
        id="a"
        width={157}
        height={47}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_30348_231577"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_30348_231577"
          result="shape"
        />
      </filter>
      <filter
        id="d"
        width={157}
        height={47}
        x={43}
        y={43}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_30348_231577"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_30348_231577"
          result="shape"
        />
      </filter>
      <filter
        id="g"
        width={157}
        height={47}
        x={0}
        y={86}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy={3} />
        <feGaussianBlur stdDeviation={3} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_30348_231577"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_30348_231577"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
