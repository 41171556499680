import moment from 'moment-timezone';

export const getFormattedDate = (
  date: string,
  userTimezone: string,
  showTime = true,
): string => {
  if (date && userTimezone) {
    const formattedDate = moment(date);

    return `${formattedDate.format('DD MMM')}, ${formattedDate?.format(
      'YYYY',
    )} ${showTime ? formattedDate?.format('hh:mm a').toUpperCase() : ''}`;
  }
  return '';
};
