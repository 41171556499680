import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PageHeaderAlertTypes } from '../../../../utils/page-header-alert-types';
import { BannerVariant } from './types';
import { Routes } from '../../../../routes';

type State = {
  bannerAlert: string;
  icon: string;
  message: string;
  CTAText: string;
  bannerAlertType: string;
  CTAType: string;
  CTAUrl: string;
  target: string;
  bannerMessageOne: string;
  bannerMessageTwo: string;
  paymentActionType: string;
};

type Action = {
  bannerType: string;
  paymentActionUrl?: string;
  message?: string;
  ctaText?: string;
  bannerMessageOne?: string;
  bannerMessageTwo?: string;
  paymentActionType?: string;
};

const initialState: State = {
  bannerAlert: '',
  icon: '',
  message: '',
  CTAText: '',
  bannerAlertType: '',
  CTAType: '',
  CTAUrl: '',
  target: '',
  bannerMessageOne: '',
  bannerMessageTwo: '',
  paymentActionType: '',
};

const pageHeaderSlice = createSlice({
  name: 'pageHeaderBannerAlert',
  initialState,
  reducers: {
    setPageHeaderBannerAlert: (state, action: PayloadAction<Action>) => {
      switch (action.payload.bannerType) {
        case PageHeaderAlertTypes.PAYMENT_PENDING: {
          state.bannerAlert = PageHeaderAlertTypes.PAYMENT_PENDING;
          state.icon = 'svg';
          state.message =
            action.payload.message || action.payload.bannerMessageOne;
          state.bannerMessageTwo = action.payload.bannerMessageTwo;
          state.paymentActionType = action.payload.paymentActionType;
          state.CTAText = action.payload.ctaText || 'Take action now';
          state.bannerAlertType = BannerVariant.Danger;
          state.CTAType = 'redirect';
          state.CTAUrl = action.payload.paymentActionUrl;
          state.target = '_blank';
          break;
        }
        case PageHeaderAlertTypes.TRIAL_EXPIRED: {
          state.bannerAlert = PageHeaderAlertTypes.TRIAL_EXPIRED;
          state.icon = 'svg';
          state.message = action.payload.message;
          state.CTAText = action.payload.ctaText;
          state.bannerAlertType = BannerVariant.Danger;
          state.CTAType = 'redirect';
          state.CTAUrl = Routes.Setting_Billing_Upgrade_Email_Outreach_Plan;
          break;
        }
        case PageHeaderAlertTypes.NONE: {
          state.CTAText = initialState.CTAText;
          state.CTAType = initialState.CTAType;
          state.CTAUrl = initialState.CTAUrl;
          state.bannerAlert = initialState.bannerAlert;
          state.bannerAlertType = initialState.bannerAlertType;
          state.icon = initialState.icon;
          state.message = initialState.message;
          state.target = initialState.target;
          break;
        }

        default:
          break;
      }
    },
  },
});

export const { setPageHeaderBannerAlert } = pageHeaderSlice.actions;
export default pageHeaderSlice.reducer;
