import React from 'react';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import { SHLogoDarkSM, SwapArrow, TrulyInbox } from '../../../shared/svg';
import { supportUrls } from '../../../shared/utils/urls';

const EmailWarmupCollaboration = () => {
  const { t } = useTranslation();

  const onViewFAQs = () => {
    window.open(supportUrls.trulyInboxForWarmupFaq, '_blank');
  };

  const onGoToTrulyInbox = () => {
    window.open(process.env.REACT_APP_TRULYINBOX_SIGNUP_URL, '_blank');
  };

  return (
    <div className="email-warmup-collaboration">
      <h1 className="email-warmup-collaboration--heading">
        {t('messages.email_warmup_collaboration_msg_1')}
      </h1>

      <div className="email-warmup-collaboration--logos">
        <div className="logo-container">
          <SHLogoDarkSM />
        </div>
        <SwapArrow />
        <div className="logo-container">
          <TrulyInbox />
        </div>
      </div>

      <p className="email-warmup-collaboration--desc">
        When the Account Owner signs up with their Saleshandy-associated email,
        they will get the Free Email Accounts to warm up as per their current
        purchased plan of Saleshandy. Plan sync can take up to 48 hours.
      </p>

      <div className="email-warmup-collaboration--buttons">
        <Button variant="secondary" onClick={onViewFAQs}>
          Learn More
        </Button>
        <Button onClick={onGoToTrulyInbox}>
          {t('messages.go_to_trulyinbox')}
        </Button>
      </div>
    </div>
  );
};

export default EmailWarmupCollaboration;
