import { CallPurpose, SequenceStepType } from '../../../../enums';

export const getCallTitle = (purpose: CallPurpose) => {
  if (purpose === CallPurpose.Introduction) {
    return 'Introduction Call';
  }
  if (purpose === CallPurpose.Demo) {
    return 'Demo Call';
  }
  if (purpose === CallPurpose.FollowUp) {
    return 'Follow-up Call';
  }
  if (purpose === CallPurpose.Reminder) {
    return 'Reminder Call';
  }

  return 'Calling Task';
};

export const getSubjectStr = (
  stepType: SequenceStepType,
  variantTitle,
  callPurpose: CallPurpose,
) => {
  if (stepType === SequenceStepType.LinkedInConnectionRequest) {
    return 'LinkedIn Connection Request';
  }
  if (stepType === SequenceStepType.LinkedInMessage) {
    return 'LinkedIn Message';
  }
  if (stepType === SequenceStepType.LinkedInInMail) {
    return 'LinkedIn InMail';
  }
  if (stepType === SequenceStepType.LinkedInViewProfile) {
    return 'LinkedIn View Profile';
  }
  if (stepType === SequenceStepType.LinkedInPostInteraction) {
    return 'LinkedIn Post Interaction';
  }

  if (stepType === SequenceStepType.Whatsapp) {
    return 'Whatsapp Message';
  }

  if (stepType === SequenceStepType.Call) {
    return getCallTitle(callPurpose);
  }

  return variantTitle;
};
