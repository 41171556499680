import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

import { Webhook } from '../../../../../../../types/webhook';
import { Column } from '../../../../../../../../../shared/design-system/components/organisms/table/types';

import WebhookStatusField from '../fields/webhook-status-field';
import WebhookNameField from '../fields/webhook-name-field';
import WebhookTriggerEventField from '../fields/webhook-trigger-event-field';
import WebhookLastSentField from '../fields/webhook-last-sent-field';
import WebhookUrlField from '../fields/webhook-url-field';
import WebhookCreatedByField from '../fields/webhook-created-by-field';

export const getWebhookTableColumns = ({
  onWebhookStatusChanged,
  onWebhookNameClicked,
}: {
  itemCount: number;
  onWebhookStatusChanged: (webhook: Webhook) => void;
  onWebhookNameClicked: (webhook: Webhook) => void;
}): Column[] => [
  {
    dataField: 'status',
    text: '',
    headerAlign: 'start',
    cellClasses: 'webhook-status-cell',
    component: (_cell, row) => (
      <WebhookStatusField
        webhook={row}
        onChangeHandler={onWebhookStatusChanged}
      />
    ),
    cellLoadingSkeleton: <SkeletonLoading width={28} height={16} />,
    headerStyle: () => ({ minWidth: '1.75rem', width: '1.75rem' }),
  },
  {
    dataField: 'name',
    text: 'Webhook Name',
    headerAlign: 'start',
    cellClasses: 'webhook-name-cell cursor-pointer',
    component: (_cell, row) => (
      <WebhookNameField webhook={row} onClickHandler={onWebhookNameClicked} />
    ),
    headerLoadingSkeleton: <SkeletonLoading width={100} height={16} />,
    cellLoadingSkeleton: <SkeletonLoading width={230} height={16} />,
    headerStyle: () => ({ minWidth: '14.375rem', width: '14.375rem' }),
  },
  {
    dataField: 'events',
    text: 'Trigger Event',
    headerAlign: 'start',
    cellClasses: 'trigger-event-cell',
    component: (_cell, row) => <WebhookTriggerEventField webhook={row} />,
    headerLoadingSkeleton: <SkeletonLoading width={78} height={16} />,
    cellLoadingSkeleton: <SkeletonLoading width={20} height={16} />,
    headerStyle: () => ({ minWidth: '7.5rem', width: '7.5rem' }),
    isCellClickable: false,
  },
  {
    dataField: 'url',
    text: 'Webhook URL',
    headerAlign: 'start',
    cellClasses: 'webhook-url-cell cursor-pointer',
    component: (_cell, row) => <WebhookUrlField webhook={row} />,
    headerLoadingSkeleton: <SkeletonLoading width={82} height={16} />,
    cellLoadingSkeleton: <SkeletonLoading width={230} height={16} />,
  },
  {
    dataField: 'lastActivityAt',
    text: 'Last Sent',
    headerAlign: 'start',
    cellClasses: 'last-sent-cell cursor-pointer',
    component: (_cell, row) => <WebhookLastSentField webhook={row} />,
    headerLoadingSkeleton: <SkeletonLoading width={55} height={16} />,
    cellLoadingSkeleton: <SkeletonLoading width={140} height={16} />,
    headerStyle: () => ({ minWidth: '8.75rem', width: '8.75rem' }),
  },
  {
    dataField: 'createdBy',
    text: 'Created By',
    headerAlign: 'start',
    cellClasses: 'created-at-cell cursor-pointer',
    component: (_cell, row) => <WebhookCreatedByField webhook={row} />,
    headerLoadingSkeleton: <SkeletonLoading width={65} height={16} />,
    cellLoadingSkeleton: (
      <SkeletonLoading width={24} height={24} borderRadius={12} />
    ),
    headerStyle: () => ({ minWidth: '6.25rem', width: '6.25rem' }),
  },
];
