import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import type { IProps } from './webhook-list-container';
import type { Webhook as TWebhook } from '../../../../types/webhook';
import { ConfirmationModalIconType } from '../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';

import toaster, { Theme } from '../../../../../../shared/toaster';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';

import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';

import WebhookHeader from '../webhook-header';
import WebhookEmptyUI from '../webhook-empty-ui';
import WebhookTable from './components/webhook-table';
import { WebhookStatus } from '../../../../enums/webhook';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import RestrictionModal from './components/restriction-modal';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

const WebhookList: React.FC<IProps> = ({
  webhooks,
  webhookPaginationOptions,

  getWebhookListRequestStatus,
  showLoading,
  hideLoading,

  updateWebhookStatusRequestStatus,
  updateWebhookStatusRequestMessage,
  updateWebhookStatusRequestError,
  resetUpdateWebhookStatusRequest,

  deleteWebhookRequestStatus,
  deleteWebhookRequestError,
  resetDeleteWebhookRequest,

  ...props
}) => {
  const history = useHistory();

  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [webhookForActions, setWebhookForActions] = useState<TWebhook>(null);
  const [showRestrictionErrorModal, setShowRestrictionErrorModal] = useState(
    false,
  );

  const hideDeleteConfirmationPopup = () => {
    setDeleteConfirmationPopup(false);
    setWebhookForActions(null);
  };

  const getWebhookListHandler = (pageNum: number, pageSize: number) => {
    const { sendGetWebhookListRequest } = props;
    sendGetWebhookListRequest({
      pageNum,
      pageSize,
    });
  };

  const onAddWebhook = () => {
    if (hasPermission(Permissions.WEBHOOKS_WRITE)) {
      history.push('/settings/webhook/add');
    } else {
      setShowRestrictionErrorModal(true);
    }
  };

  const onDeleteWebhook = () => {
    const { sendDeleteWebhookRequest } = props;
    sendDeleteWebhookRequest(webhookForActions?.id?.toString());
  };

  const onAction = (key: string, webhook: TWebhook) => {
    if (key === 'edit') {
      history.push(`/settings/webhook/${webhook?.id}/details`);
      return;
    }
    if (key === 'viewLog') {
      history.push(`/settings/webhook/${webhook?.id}/logs`);
      return;
    }
    if (key === 'statusUpdate') {
      if (hasPermission(Permissions.WEBHOOKS_WRITE)) {
        if (webhook?.failed) {
          toaster.error(
            'Please ensure that the webhook test response is successful before activating.',
            { theme: Theme.New },
          );
        } else {
          setWebhookForActions(webhook);
          const { sendUpdateWebhookStatusRequest } = props;
          sendUpdateWebhookStatusRequest({
            webhookId: webhook?.id?.toString(),
            webhookStatus:
              webhook?.status === WebhookStatus.Active
                ? WebhookStatus.Paused
                : WebhookStatus.Active,
          });
        }
      } else {
        setShowRestrictionErrorModal(true);
      }

      return;
    }
    if (key === 'delete') {
      setDeleteConfirmationPopup(true);
      setWebhookForActions(webhook);
    }
  };

  useEffect(() => {
    getWebhookListHandler(1, 25);
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateWebhookStatusRequestStatus,
      onSuccess: () => {
        getWebhookListHandler(1, 25);
        resetUpdateWebhookStatusRequest();
        toaster.success(
          `Webhook has been ${
            webhookForActions?.status === WebhookStatus.Paused
              ? 'activated'
              : 'paused'
          } successfully`,
          {
            theme: Theme.New,
          },
        );
        setWebhookForActions(null);
      },
      onFailed: () => {
        getWebhookListHandler(1, 25);
        resetUpdateWebhookStatusRequest();
        setWebhookForActions(null);
        executeOnErrorWithErrorCheck({
          error: updateWebhookStatusRequestError,
          onError: () => {
            toaster.error(updateWebhookStatusRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [updateWebhookStatusRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteWebhookRequestStatus,
      onSuccess: () => {
        getWebhookListHandler(1, 25);
        resetDeleteWebhookRequest();
        hideDeleteConfirmationPopup();
        toaster.success('Webhook has been deleted successfully', {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        getWebhookListHandler(1, 25);
        resetDeleteWebhookRequest();
        hideDeleteConfirmationPopup();
        executeOnErrorWithErrorCheck({
          error: deleteWebhookRequestError,
          onError: () => {
            toaster.error(deleteWebhookRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [deleteWebhookRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getWebhookListRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        hideLoading();
      },
    });
  }, [getWebhookListRequestStatus]);

  const isWebhookRequestLoading = getIsRequestPending(
    getWebhookListRequestStatus,
  );
  const isDeleteWebhookRequestLoading = getIsRequestPending(
    deleteWebhookRequestStatus,
  );

  return (
    <div className="webhook">
      <WebhookHeader onAddWebhook={onAddWebhook} />

      {webhookPaginationOptions?.totalItems === 0 &&
      !isWebhookRequestLoading ? (
        <WebhookEmptyUI onAddWebhook={onAddWebhook} />
      ) : (
        <GlobalSettings.Content className="webhook">
          <WebhookTable
            webhooks={webhooks}
            webhookPaginationOptions={webhookPaginationOptions}
            onPaginationChange={getWebhookListHandler}
            onAction={onAction}
            isLoading={isWebhookRequestLoading}
          />
        </GlobalSettings.Content>
      )}

      <ConfirmationModalV3
        show={deleteConfirmationPopup}
        iconType={ConfirmationModalIconType.TRASH_RED}
        title="Are you sure you want to delete this?"
        className="webhook-delete-modal"
        content={
          <>
            Are you sure you want to do this? You will no longer receive any
            webhook events at{' '}
            <span className="font-semibold">{webhookForActions?.url}</span>
          </>
        }
        cancelButtonText="Cancel"
        submitButtonText="Delete"
        submitButtonVariant={Button.Variant.Danger}
        onSubmit={onDeleteWebhook}
        onClose={hideDeleteConfirmationPopup}
        submitButtonClassName="delete-btn"
        cancelButtonClassName="cancel-btn"
        isSubmitDisabled={isDeleteWebhookRequestLoading}
        isSubmitLoading={isDeleteWebhookRequestLoading}
        showCloseIcon={false}
      />

      <RestrictionModal
        show={showRestrictionErrorModal}
        onClose={() => {
          setShowRestrictionErrorModal(false);
        }}
      />
    </div>
  );
};

export default WebhookList;
