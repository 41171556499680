import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { getUnreadEmailThreadsCountRequest } from '../../extra-actions';

import SideNavbar from './sidenavbar';
import { updateApplicationState } from '../../home-slice';
import { UnreadEmailThreadsCountPayload } from '../../types';

const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home.agencyConfig,
  agencyConfigRequestStatus: state.home.getAgencyConfigRequest.status,

  planCode: state.home.subscription?.planCode,

  unreadEmailThreadsCount: state.home.unreadEmailThreadsCount,

  hasVisitedAgencyPortal: state.home.application.hasVisitedAgencyPortal,
  isAgency: parseInt(state.home.isAgency, 10),

  hasVisitedEmailAccounts: state.home.application.hasVisitedEmailAccounts,
  isEmailAccountAdded: !state.home.isEmailAccountAdded,
});

const mapDispatchToProps = {
  sendGetUnreadEmailThreadsCountRequest: (
    payload: UnreadEmailThreadsCountPayload,
  ) => getUnreadEmailThreadsCountRequest(payload),
  handleHasVisitedAgencyPortal: (hasVisitedAgencyPortal: boolean) =>
    updateApplicationState({ hasVisitedAgencyPortal }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(SideNavbar));
