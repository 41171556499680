/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable react/react-in-jsx-scope */
import { ImageIcon, SkeletonLoading } from '@saleshandy/design-system';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Copy, Refresh } from '@saleshandy/icons';
import { Column } from '../../../../../../../shared/design-system/components/organisms/table/types';
import { Placement } from '../../../../../../../shared/design-system/components/overlay';
import { Images } from '../../../../../../../shared/app-constants';

const getTooltip = (text: string, exterClass?: string) => (
  <Tooltip id="popover-basic" className={`bs-tooltip-inner ${exterClass}`}>
    {text}
  </Tooltip>
);

export const getDomainSetupTableColumns = (
  refreshDomain: () => void,
  onContentCopy: (text: string) => void,
  isContentCopied: boolean,
  isValidDomain: boolean,
): Column[] => [
  {
    dataField: 'recordType',
    text: 'Record type',
    headerAlign: 'left',
    cellClasses: 'prospect-field',
    headerClasses: 'name-field',
    component: (cell) => (
      <span className="semibold-2 line-height-20 popover-arrow-color-txt">
        {cell}
      </span>
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'recordHost',
    text: 'Record / Host',
    headerAlign: 'left',
    cellClasses: 'prospect-field',
    headerClasses: 'name-field',
    component: (cell, row) => (
      <div>
        <span
          className={`regular-2 line-height-18  ${
            isValidDomain ? 'popover-arrow-color-txt' : 'gray-txt-11'
          }`}
        >
          {cell}
        </span>

        {cell && isValidDomain && (
          <OverlayTrigger
            placement={Placement.Bottom}
            overlay={getTooltip(!isContentCopied ? 'Copy' : 'Copied')}
          >
            <Copy
              width={16}
              height={16}
              className="gray-txt-15 pointer"
              onClick={() => onContentCopy(row.cNameValue)}
            />
          </OverlayTrigger>
        )}
      </div>
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'pointsToValue',
    text: 'Points to / Value',
    headerAlign: 'left',
    cellClasses: 'prospect-field',
    headerClasses: 'name-field',
    component: (cell) => (
      <div>
        <span className="regular-2 line-height-18 popover-arrow-color-txt">
          {cell}
        </span>

        <OverlayTrigger
          placement={Placement.Bottom}
          overlay={getTooltip(!isContentCopied ? 'Copy' : 'Copied')}
        >
          <Copy
            width={16}
            height={16}
            className="gray-txt-15 pointer"
            onClick={() => onContentCopy(cell)}
          />
        </OverlayTrigger>
      </div>
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'isVerified',
    text: 'Status',
    headerAlign: 'left',
    cellClasses: 'prospect-field',
    headerClasses: 'name-field',
    component: (cell) => {
      if (cell === null) {
        return <ImageIcon src={Images.VerifiedFalse} alt="verified" />;
      }

      return cell ? (
        <ImageIcon src={Images.VerifiedTrue} alt="verified" />
      ) : (
        <ImageIcon src={Images.VerifiedError} alt="verified" />
      );
    },
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: '',
    text: 'Action',
    headerAlign: 'left',
    cellClasses: 'prospect-field',
    headerClasses: 'name-field',
    component: (cell, row) => (
      <Refresh
        width={20}
        height={20}
        className={`${
          !row.isDisabled ? 'pointer-not-allowed' : 'pointer'
        } gray-txt-11 pointer`}
        onClick={refreshDomain}
      />
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
];
