import { SequenceStepType } from '../../../../../components/sequence/enums';

export enum Icon {
  MAIL = 'mail',
  EYE = 'eye',
  REPLY = 'reply',
  LINK = 'link',
}

export type ActivityProps = {
  cell: ActivityData[];
  isEmailTable?: boolean;
  onClick?: (type: EngagementActivityType) => void;
};

export type ActivityData = {
  date: string;
  text: string;
  iconLabel: Icon;
  timeZone: string;
  type: EngagementActivityType;
  stepType?: SequenceStepType;
};

export enum EngagementActivityType {
  EmailSent = 'email-sent',
  EmailOpened = 'email-opened',
  LinkClicked = 'link-clicked',
  EmailReplied = 'email-replied',
}
