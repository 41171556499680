import React from 'react';
import {
  Dropdown as ReactBootstrapDropdown,
  DropdownProps as ReactBootstrapDropdownProps,
} from 'react-bootstrap';
import { ForwardRef } from '../../types/forward-ref';

export enum IconPlace {
  Left = 'left',
  Right = 'right',
}

type Value = {
  text: string;
  iconIdentifier?: string;
  iconPlace?: IconPlace;
  values?: Value[];
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

type DropdownProps = Omit<ReactBootstrapDropdownProps, 'bsPrefix'> & {
  values: Value[];
};

type DropdownStatic = {
  IconPlace: typeof IconPlace;
};

const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
  // eslint-disable-next-line
  ({ values, children, ...rest }, ref) => {
    return (
      <ReactBootstrapDropdown {...rest} bsPrefix="dropdown-custom">
        <ReactBootstrapDropdown.Toggle
          bsPrefix="dropdown-toggle-custom"
          variant="dropdown-custom"
          id="dropdown-basic"
        >
          {children}
        </ReactBootstrapDropdown.Toggle>
      </ReactBootstrapDropdown>
    );

    // <Dropdown bsPrefix="dropdown-custom" drop="down">
    //                     <Dropdown.Toggle
    //                       bsPrefix="dropdown-toggle-custom"
    //                       variant="dropdown-custom"
    //                       id="dropdown-basic"
    //                     >
    //                       Dropdown <i className="sh-ot-down"></i>
    //                     </Dropdown.Toggle>
    //
    //                     <Dropdown.Menu bsPrefix="dropdown-menu-custom">
    //                       <Dropdown.Item
    //                         bsPrefix="dropdown-item-custom"
    //                         href="#"
    //                         className="dropdown-submenu"
    //                       >
    //                         <span>Action</span>
    //                         <Dropdown.Menu bsPrefix="dropdown-menu-custom">
  },
) as ForwardRef<HTMLDivElement, DropdownProps> & DropdownStatic;

Dropdown.displayName = 'Dropdown';

Dropdown.IconPlace = IconPlace;

Dropdown.defaultProps = {
  drop: 'down',
};

export default Dropdown;

// eslint-disable-next-line
const getItems = (values: Value[]) => {
  return (
    <ReactBootstrapDropdown.Menu bsPrefix="dropdown-menu-custom">
      {values.map((value, index) => {
        return (
          <ReactBootstrapDropdown.Item
            key={index}
            bsPrefix="dropdown-item-custom"
            href="#"
            className="dropdown-submenu"
          >
            <span>Action</span>
          </ReactBootstrapDropdown.Item>
        );
      })}
    </ReactBootstrapDropdown.Menu>
  );
};
