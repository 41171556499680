import React from 'react';
import { Button } from '@saleshandy/design-system';

import { supportUrls } from '../../../../../shared/utils/urls';
import PremiumFeatureIndicator from '../../../../../shared/components/premium-feature-indicator';
import { Placement } from '../../../../../shared/design-system/components/overlay';
import { GlobalSettings } from '../../../../../shared/components/global-settings-wrapper';
import LinkBadge from '../../../../../shared/design-system/components/atoms/link-badge';

type IProps = {
  onAddWebhook: VoidFunction;
};

const WebhookHeader: React.FC<IProps> = ({ onAddWebhook }) => (
  <>
    <GlobalSettings.Header>
      <GlobalSettings.HeaderLeft>
        <GlobalSettings.HeaderTitle
          title="Webhook"
          isToolTip={true}
          tooltipText="Set up your triggers and receive notifications instantly."
        />

        <LinkBadge url={supportUrls.webhookBlogURL} />
      </GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderRight>
        <div className="webhook--header">
          <div className="premium-feature-wrapper">
            <PremiumFeatureIndicator
              placement={Placement.Bottom}
              tooltipClassname="premium-feature-tooltip"
            />
          </div>
          <Button onClick={onAddWebhook}>Add Webhook</Button>
        </div>
      </GlobalSettings.HeaderRight>
    </GlobalSettings.Header>
  </>
);

export default WebhookHeader;
