import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { IPropsComponent } from './types';
import { RootState } from '../../../../store/root-reducer';
import AgencyClientDropdown from './agency-client-dropdown';
import {
  AssignAgencyClientResourceRequestPayload,
  GetAgencyClientDropdownRequestPayload,
  GetSelectedAgencyClientRequestPayload,
} from '../../types/request-payload';
import {
  getAgencyClientsDropdown,
  getSelectedAgencyClient,
  updateAssignAgencyClientResource,
} from '../../extra-actions';

const mapStateToProps = (state: RootState) => ({
  getAgencyClientsDropdownRequestStatus:
    state.agencyClient.getAgencyClientsDropdownRequest.status,
  getAgencyClientsDropdownRequestMessage:
    state.agencyClient.getAgencyClientsDropdownRequest.message,
  getAgencyClientsDropdownRequestError:
    state.agencyClient.getAgencyClientsDropdownRequest.error,
  clients: state.agencyClient.clients,

  selectedAgencyClient: state.agencyClient.selectedAgencyClient,
  getSelectedAgencyClientsDropdownRequestStatus:
    state.agencyClient.selectedAgencyClientRequest.status,
  getSelectedAgencyClientsDropdownRequestMessage:
    state.agencyClient.selectedAgencyClientRequest.message,
  getSelectedAgencyClientsDropdownRequestError:
    state.agencyClient.selectedAgencyClientRequest.error,

  updateAssignAgencyClientResourceRequestStatus:
    state.agencyClient.updateAgencyClientResource.status,
  updateAssignAgencyClientResourceRequestMessage:
    state.agencyClient.updateAgencyClientResource.message,
  updateAssignAgencyClientResourceRequestError:
    state.agencyClient.updateAgencyClientResource.error,
});

const mapDispatchToProps = {
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
  sendGetSelectedAgencyClientRequest: (
    payload: GetSelectedAgencyClientRequestPayload,
  ) => getSelectedAgencyClient(payload),
  sendUpdateAssignAgencyClientResource: (
    payload: AssignAgencyClientResourceRequestPayload,
  ) => updateAssignAgencyClientResource(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any> &
  IPropsComponent;

export default connector(AgencyClientDropdown);
