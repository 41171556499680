import { DateTime } from 'luxon';
import moment from 'moment-timezone';
import { Images } from '../../../shared/app-constants';
import { constants } from '../../../shared/enums/constants';
import MasterLog from '../../../shared/enums/master-log-enum';
import { Order, SortOrder } from '../../../shared/enums/order';
import { PaginationOption, SortByKey } from '../enum/mailbox-emails.enum';
import { MailboxEmailsFiltersMeta } from '../type';
import {
  getIsRequestIdeal,
  getIsRequestPending,
} from '../../../shared/utils/get-request-status';

export const getMailboxEmailActivityLabel = (tab: MasterLog): string => {
  if (tab === MasterLog.MailboxEmailOpened) {
    return 'opened';
  }
  if (tab === MasterLog.MailboxEmailLinkClicked) {
    return 'clicked';
  }
  return '';
};

export const getMailboxEmailActivityIcon = (
  key: MasterLog,
  active: boolean,
) => {
  switch (key) {
    case MasterLog.MailboxEmailOpened:
      return active ? Images.OpenedBlue : Images.OpenedEnabled;
    case MasterLog.MailboxEmailLinkClicked:
      return active ? Images.ClickedBlue : Images.ClickedEnabled;
    default:
      break;
  }
  return null;
};

export const getInitialMailboxEmailsFilterMeta = (): MailboxEmailsFiltersMeta => ({
  page: 1,
  sortByKey: SortByKey.SentAt,
  order: Order.Desc,
  search: '',
  mailbox: 0,
  limit: constants.DEFAULT_PAGE_SIZE,
});

export const setSortBy = (sortBy: string): string => {
  if (sortBy === SortByKey.SentAt) {
    return SortByKey.SentAt;
  }
  if (sortBy === SortByKey.Opened) {
    return SortByKey.Opened;
  }
  if (sortBy === SortByKey.Clicked) {
    return SortByKey.Clicked;
  }
  if (sortBy === SortByKey.LastActivity) {
    return SortByKey.LastActivity;
  }

  return SortByKey.SentAt;
};

export const getOrderInString = (order: number): SortOrder => {
  if (order === Order.Asc) {
    return SortOrder.ASC;
  }

  if (order === Order.Desc) {
    return SortOrder.DESC;
  }

  return SortOrder.ASC;
};

export const getQueryParameters = (
  filters: MailboxEmailsFiltersMeta,
  search: string,
): string => {
  const queryParams = new URLSearchParams(search);

  Object.keys(filters).forEach((key) => {
    if (key === PaginationOption.PAGE) {
      queryParams.set(key, filters[key].toString());
      return;
    }

    if (key === PaginationOption.SORT_BY_KEY) {
      const sortBy = setSortBy(filters[key]);
      queryParams.set(key, sortBy);
      return;
    }

    if (key === PaginationOption.ORDER) {
      const order = getOrderInString(filters[key]);
      queryParams.set(key, order);
      return;
    }

    if (key === PaginationOption.SEARCH) {
      queryParams.set(key, filters[key]);
      return;
    }

    if (key === PaginationOption.OWNERS) {
      if (!filters[key]) {
        queryParams.set('owners', '');
      } else {
        queryParams.set('owners', filters[key].toString());
      }
    }
  });

  return queryParams.toString();
};

export const handleActivityItemsFilters = (tab, item): boolean =>
  item.key === tab;

export const getActivityWithLabel = (activityArr, t) => {
  const yesterday = DateTime.fromMillis(
    new Date().setDate(new Date().getDate() - 1),
  ).toFormat('dd LLLL yyyy');

  const today = DateTime.fromMillis(new Date().getTime()).toFormat(
    'dd LLLL yyyy',
  );

  const activity = [...activityArr];

  if (activity.length > 0) {
    if (activity[0].dayText === today) {
      activity[0].dayText = t('labels.today');
    }

    if (activity[0].dayText === yesterday) {
      activity[0].dayText = t('labels.yesterday');
    }

    if (activity[1] && activity[1].dayText === yesterday) {
      activity[1].dayText = t('labels.yesterday');
    }
  }

  return activity.map((item, index) => ({
    ...item,
    id: index,
  }));
};

export const getIsInitialMailboxEmailsRequestPending = ({
  filters,
  mailboxEmails,
  getMailboxEmailsListRequestStatus,
  getMailboxesListRequestStatus,
}): boolean => {
  const initialFilters = getInitialMailboxEmailsFilterMeta();

  return (
    JSON.stringify(initialFilters) === JSON.stringify(filters) &&
    mailboxEmails.length === 0 &&
    (getIsRequestPending(getMailboxEmailsListRequestStatus) ||
      getIsRequestIdeal(getMailboxEmailsListRequestStatus)) &&
    (getIsRequestPending(getMailboxesListRequestStatus) ||
      getIsRequestIdeal(getMailboxesListRequestStatus))
  );
};

export const isInitialAndCurrentFiltersSame = (filters) =>
  JSON.stringify(getInitialMailboxEmailsFilterMeta()) ===
  JSON.stringify(filters);

export const getIsFiltersChanged = ({ filters, page, limit }): boolean =>
  filters?.page === page && filters?.limit === limit;

export const getNotificationIconAndTooltip = (
  cell,
  t,
): {
  notificationIcon: string;
  notificationTooltip: string;
} => ({
  notificationIcon: cell ? 'bell' : 'bell-off',
  notificationTooltip: cell
    ? t('messages.notification_turned_on_tooltip_text')
    : t('messages.notification_turned_off_tooltip_text'),
});

export const getActionTooltipText = ({ action, notificationTooltip }) =>
  action.key === 'notification' ? notificationTooltip : action.displayName;

export const getActionImageIcon = ({ action, notificationIcon }) =>
  action.key === 'notification' ? notificationIcon : action.icon;

export const getOrderInStringForTableSort = (order: number) =>
  order === 1 ? 'asc' : 'desc';

export const getOrderInNumberForTableSort = (order) =>
  order === 'asc' ? 1 : -1;

export const generateName = (
  firstName: string,
  lastName: string,
  email: string,
): string => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }

  if (firstName || lastName) {
    return `${firstName || lastName}`;
  }

  return email;
};

export const getUpdatedFiltersBasedOnQuery = ({ filters, params }) => {
  const { page, sortByKey, order, search, owners: mailbox } = params;

  return {
    ...filters,
    ...(page && { page }),
    ...(sortByKey && { sortByKey }),
    order: order === SortOrder.ASC ? Order.Asc : Order.Desc,
    ...(search && { search }),
    ...(mailbox && { mailbox }),
  };
};

export const getDateTimeTooltipText = (
  date: Date | string,
  userTimezone: string,
  action: string,
): string => {
  const dateObj = moment(date);
  const timezone = `(GMT${dateObj
    .tz(userTimezone)
    .format('Z')}) ${userTimezone}`;

  return `Last ${action} on ${dateObj.format(
    'Do MMM YYYY',
  )} at ${dateObj.format('hh:mm A')} ${timezone}.`;
};
