import { PaginationQueryParams } from '../../../../../../shared/types/request';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import {
  CustomOutcome,
  DefaultOutcome,
} from '../../../../types/custom-outcome';

export type IProps = {
  customOutcomes: CustomOutcome[];
  defaultOutcomes: DefaultOutcome[];
  customOutcomePagination: {
    options: Required<PaginationQueryParams> & {
      totalElements: number;
      search?: string;
    };
    pagesCount: number;
    count: number;
  };
  defaultOutcomePagination: {
    options: Required<PaginationQueryParams> & {
      totalElements: number;
      search?: string;
    };
    pagesCount: number;
    count: number;
  };
  isRequestPending: boolean;
  onCreateHandler: (event: React.MouseEvent<HTMLElement>) => void;
  onUpdateHandler: (customField: CustomOutcome) => void;
  onDeleteHandler: (customField: CustomOutcome) => void;
  sendGetCustomOutcomeRequest: (
    pagination: PaginationQueryParams & { search?: string },
  ) => void;
  sendGetDefaultOutcomeRequest: (
    pagination: PaginationQueryParams & { search?: string },
  ) => void;
  subscriptionPlan: SubscriptionPlans;
  t: (x: string) => React.ReactNode;
  initialSearch: string;
  activeTabKey: string;
  setActiveTabKey: (val: string) => void;
};

export enum OutcomeContentTabs {
  CustomOutcomes = 'custom-outcomes',
  SystemOutcomes = 'system-outcomes',
}
