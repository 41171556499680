import React from 'react';
import { useTranslation } from 'react-i18next';
import CreateUpdateCustomOutcomeModal from '../..';
import { UpdateProspectCustomOutcomeModalProps } from './types';

const UpdateProspectCustomOutcomeModal: React.FC<UpdateProspectCustomOutcomeModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { customOutcome } = props;
  return (
    <CreateUpdateCustomOutcomeModal
      {...props}
      disableDropdownBox={false}
      modalHeaderTitle={
        customOutcome.isDefault
          ? t('labels.update_system_outcome')
          : t('labels.update_custom_outcome')
      }
      showWarning={true}
    />
  );
};

export default UpdateProspectCustomOutcomeModal;
