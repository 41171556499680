import React, { useRef } from 'react';
import { accessibleOnClick } from '../../../../../../../../../../../shared/utils/accessible-on-click';

export type IProps = {
  onSelect: (file: File) => void;
};

const ChooseFileInput: React.FC<IProps> = ({ onSelect }) => {
  const ref = useRef<HTMLInputElement>(null);

  const inputClicked = () => ref.current.click();

  const inputChangeHandler = ({ target: { files } }) =>
    files.length > 0 && onSelect(files[0]);

  return (
    <div className="choose-file-input" {...accessibleOnClick(inputClicked)}>
      <div className="choose-file">Choose a file</div>
      <input
        type="file"
        accept=".csv, text/csv"
        ref={ref}
        onChange={inputChangeHandler}
      />
    </div>
  );
};

export default ChooseFileInput;
