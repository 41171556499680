/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Button from '../../../../shared/design-system/components/atoms/button';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { EmailAccountMethod } from '../../../email-accounts/enums/email-account';
import AddEmailAccountModal from '../../../email-accounts/components/add-email-account-modal';
import type { IProps } from './email-daily-sending-limit-container';
import { resetEmailDailySendingLimitState } from './email-daily-sending-limit-slice';
import {
  zeroLimitChecker,
  softLimitChecker,
  hardLimitChecker,
  getLimitsForEmailAccount,
  lowerLimitChecker,
  saveButtonDisabledChecker,
  shouldAllowLimitUpdateChecker,
  getSettingsLimitForEmailAccount,
  executeBasedOnSubscriptionPlan,
  getEmailServiceProvider,
} from './helpers';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import { getIsRequestPending } from '../../../email-accounts/components/update-email-account/helpers';

import DailySendingLimitInput from './sub-components/daily-sending-limit-input';
import ZeroLimitErrorNudge from './sub-components/zero-limit-error';
import SoftLimitErrorNudge from './sub-components/soft-limit-error';
import HardLimitErrorNudge from './sub-components/hard-limit-error';
import LowerLimitErrorNudge from './sub-components/lower-limit-error';
import toaster, { Theme } from '../../../../shared/toaster';
import {
  RouteParams,
  UpdateEmailTabKeys,
} from '../../../email-accounts/components/update-email-account/types';
import NoSeatAvailableForEmailModal from '../../../email-accounts/components/no-seat-available-for-email-modal';
import { redirectWithoutRefresh } from '../../../../shared/utils';
import AddBulkImportModal from '../../../email-accounts/components/add-email-bulk-import-modal';
import FeatureCodes from '../../../../shared/enums/feature-codes';
import { getFeatureCodeValue } from '../../../../shared/utils/feature-code';

const EmailDailySendingLimit: React.FC<IProps> = ({
  subscriptionPlan,
  sendConnectEmailAccountRequest,
  connectEmailAccountRequestStatus,
  sendGetEspLimitRequest,
  espLimits,
  emailAccount,
  sendUpdateMaxSendingLimitRequest,
  sendGetEmailAccountSettingsRequest,
  emailAccountSettings,
  updateMaxSendingLimitStatus,
  resetUpdateMaxSendingLimitRequestState,
  firstName,
  sendGetConnectedUsersAndEmailAccountsRequest,
  getConnectedUsersAndEmailAccountsRequestStatus,
  resetConnectedUsersAndEmailAccountsRequest,
  sendGetEmailAccountSetupScoreRequest,
  isBulkUpdatingEmailAccounts,
  setSendingLimit: setSendingLimitBulk,
  setSendingLimitError,
  featureUsages,
}) => {
  const { t } = useTranslation();
  const params = useParams<RouteParams>();
  const { hashId } = params;
  const emailAccountIdParam = emailAccount?.id;

  const { hardLimit, softLimit } = getLimitsForEmailAccount({
    emailAccount,
    espLimits,
  });

  const maxLimit = parseInt(
    getFeatureCodeValue(
      featureUsages,
      FeatureCodes.EmailAccountSendingMaxDailySending,
    )?.remainingQuota || (isBulkUpdatingEmailAccounts ? '4000' : '5000'),
    10,
  );

  const {
    maxSendingLimit,
    rampUpStatus,
    rampUpLimit,
  } = getSettingsLimitForEmailAccount(emailAccountSettings);

  const [sendingLimit, setSendingLimit] = useState<number>(null);
  const [
    showAddEmailAccountModal,
    setShowAddEmailAccountModal,
  ] = useState<boolean>(false);

  const [zeroLimitError, setZeroLimitError] = useState<boolean>(false);
  const [softLimitError, setSoftLimitError] = useState<boolean>(false);
  const [hardLimitError, setHardLimitError] = useState<boolean>(false);
  const [lowerLimitError, setLowerLimitError] = useState<boolean>(false);
  const [softLimitAllowed, setSoftLimitAllowed] = useState<boolean>(false);
  const [
    showNoSeatAvailableForEmailModal,
    setShowNoSeatAvailableForEmailModal,
  ] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [showBulkModal, setShowBulkModal] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const isUpdateMaxSendingLimitLoading = getIsRequestPending(
    updateMaxSendingLimitStatus,
  );

  const hideEmailAccountModal = () => setShowAddEmailAccountModal(false);

  const hideSoftLimitModal = () => {
    setSoftLimitAllowed(false);
    setSoftLimitError(false);
  };

  const hideNoSeatAvailableForEmailModal = () => {
    setShowNoSeatAvailableForEmailModal(false);
  };

  const onConnectAnotherEmailAccount = () => {
    sendGetConnectedUsersAndEmailAccountsRequest();
  };

  const showConnectEmailAccountModal = () => {
    redirectWithoutRefresh('/email-accounts?connect-smtp=true');
  };

  const addEmailAccountHandler = (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => {
    executeBasedOnSubscriptionPlan(subscriptionPlan, () => {
      sendConnectEmailAccountRequest(method, emailAccountId);
    });
  };

  const changeSendingLimit = (value) => {
    if (value === '' && isBulkUpdatingEmailAccounts) {
      setSendingLimit(null);
      setZeroLimitError(false);
      setLowerLimitError(false);
      setHardLimitError(false);
      setSendingLimitError?.(false);
      setIsDirty(false);
      setSendingLimitBulk?.(null);
      setSendingLimitError?.(false);
      return;
    }

    setSendingLimit(value);

    if (zeroLimitChecker(value, isBulkUpdatingEmailAccounts, maxLimit)) {
      setZeroLimitError(true);
      setLowerLimitError(false);
      setHardLimitError(false);
      setSendingLimitError?.(true);
      return;
    }

    setZeroLimitError(false);

    if (lowerLimitChecker(value, rampUpStatus, rampUpLimit)) {
      setLowerLimitError(true);
      setZeroLimitError(false);
      setHardLimitError(false);
      setSendingLimitError?.(true);
      return;
    }

    setLowerLimitError(false);

    if (hardLimitChecker(value, hardLimit, maxLimit)) {
      setHardLimitError(true);
      setZeroLimitError(false);
      setLowerLimitError(false);
      setSendingLimitError?.(true);
      return;
    }

    setHardLimitError(false);

    if (!isDirty) {
      setIsDirty(true);
    }

    setSendingLimitBulk?.(value);
    setSendingLimitError?.(false);
  };

  const onUpdateLimitHandler = () => {
    if (
      softLimitChecker(sendingLimit, softLimit, hardLimit, softLimitAllowed)
    ) {
      setSoftLimitError(true);
      return;
    }

    if (
      shouldAllowLimitUpdateChecker(
        zeroLimitError,
        softLimitError,
        hardLimitError,
        lowerLimitError,
        softLimitAllowed,
        sendingLimit > maxLimit,
        isUpdateMaxSendingLimitLoading,
      )
    ) {
      return;
    }

    sendUpdateMaxSendingLimitRequest(emailAccountIdParam, sendingLimit);
  };

  const resetComponentState = () => {
    setSendingLimit(maxSendingLimit);
    setShowAddEmailAccountModal(false);
    setZeroLimitError(false);
    setSoftLimitError(false);
    setHardLimitError(false);
    setLowerLimitError(false);
    setSoftLimitAllowed(false);
  };

  const showBulkImportModal = () => setShowBulkModal(true);

  const hideBulkImportModal = () => setShowBulkModal(false);

  const showConnectAndSaveSmtpImapAccountModel = () => {
    setShowAddEmailAccountModal(false);
  };

  const showBulkImportCSV = () => {
    hideEmailAccountModal();
    showBulkImportModal();
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: updateMaxSendingLimitStatus,
      onSuccess: () => {
        toaster.success(t('messages.email_per_day_success_message'), {
          theme: Theme.New,
        });
        sendGetEmailAccountSettingsRequest(hashId);
        hideSoftLimitModal();
        resetUpdateMaxSendingLimitRequestState();
        sendGetEmailAccountSetupScoreRequest({
          emailAccountId: emailAccountIdParam,
          refresh: true,
        });
      },
    });
  }, [updateMaxSendingLimitStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getConnectedUsersAndEmailAccountsRequestStatus,
      onSuccess: () => {
        showConnectEmailAccountModal();
        resetConnectedUsersAndEmailAccountsRequest();
      },
    });
  }, [getConnectedUsersAndEmailAccountsRequestStatus]);

  const isConnectEmailAccountRequestPending =
    connectEmailAccountRequestStatus === RequestStatus.Pending;

  useEffect(() => {
    sendGetEspLimitRequest();
  }, []);

  useEffect(() => {
    if (!isBulkUpdatingEmailAccounts) {
      setSendingLimit(maxSendingLimit);
    }
  }, [maxSendingLimit]);

  // Cleaning state on unmounting component.
  useEffect(
    () => () => {
      resetEmailDailySendingLimitState();
    },
    [],
  );

  useEffect(
    () => () => {
      if (params.tab === UpdateEmailTabKeys.SENDING_SETTINGS) {
        resetComponentState();
      }
    },
    [params],
  );

  useEffect(() => {
    setIsDisabled(
      saveButtonDisabledChecker(
        zeroLimitError,
        hardLimitError,
        lowerLimitError,
        sendingLimit > maxLimit,
        isUpdateMaxSendingLimitLoading,
      ),
    );
  }, [
    zeroLimitError,
    hardLimitError,
    lowerLimitError,
    sendingLimit,
    isUpdateMaxSendingLimitLoading,
  ]);

  const emailServiceProvider = getEmailServiceProvider(emailAccount);

  return (
    <div className="email-account-daily-sending-limit">
      <DailySendingLimitInput
        t={t}
        sendingLimit={sendingLimit}
        changeSendingLimit={changeSendingLimit}
        zeroLimitError={zeroLimitError}
        isBulkUpdatingEmailAccounts={isBulkUpdatingEmailAccounts}
        maxLimit={maxLimit}
      />

      <ZeroLimitErrorNudge
        zeroLimitError={zeroLimitError}
        maxLimit={maxLimit}
        isBulkUpdatingEmailAccounts={isBulkUpdatingEmailAccounts}
        t={t}
      />

      <SoftLimitErrorNudge
        show={softLimitError}
        softLimit={softLimit}
        emailServiceProvider={emailServiceProvider}
        softLimitAllowed={softLimitAllowed}
        setSoftLimitAllowed={setSoftLimitAllowed}
        onSubmit={onUpdateLimitHandler}
        onClose={hideSoftLimitModal}
        isLoading={isUpdateMaxSendingLimitLoading}
      />

      <HardLimitErrorNudge
        hardLimitError={
          !isBulkUpdatingEmailAccounts &&
          (hardLimitError || sendingLimit > 1000)
        }
        hardLimit={hardLimit}
        onConnectAnotherEmailAccount={onConnectAnotherEmailAccount}
        emailServiceProvider={emailServiceProvider}
      />

      <LowerLimitErrorNudge t={t} lowerLimitError={lowerLimitError} />

      {isDirty && !isBulkUpdatingEmailAccounts && (
        <div className="row mt-3">
          <div className="col-md-12">
            <Button
              variant={Button.Variant.Primary}
              type={Button.Type.Submit}
              onClick={onUpdateLimitHandler}
              isLoading={isUpdateMaxSendingLimitLoading}
              className={`header-btn ${isDisabled ? 'disabled' : ''}`}
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
        </div>
      )}

      <AddEmailAccountModal
        onCSVSelect={showBulkImportCSV}
        show={showAddEmailAccountModal}
        onSubmit={addEmailAccountHandler}
        onClose={hideEmailAccountModal}
        isRequestPending={isConnectEmailAccountRequestPending}
        t={t}
        onSMTPAccountSelect={showConnectAndSaveSmtpImapAccountModel}
      />

      <AddBulkImportModal show={showBulkModal} onCancel={hideBulkImportModal} />

      <NoSeatAvailableForEmailModal
        show={showNoSeatAvailableForEmailModal}
        onClose={hideNoSeatAvailableForEmailModal}
        firstName={firstName}
      />
    </div>
  );
};

export default EmailDailySendingLimit;
