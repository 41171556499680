import React, { useState } from 'react';

type ProgressBarProps = {
  percentage: number;
};

const ProgressBar: React.FC<ProgressBarProps> = React.forwardRef<
  HTMLDivElement,
  ProgressBarProps
>(({ percentage }, ref) => {
  const [bgColor] = useState<string>('#3B82F6');

  return (
    <div ref={ref} className="cs-progress-bar-wrapper">
      <span style={{ width: `${percentage}%` }}>
        <span
          className="cs-progress-bar-wrapper__bar"
          style={{ backgroundColor: bgColor }}
        />
      </span>
    </div>
  );
});

export default ProgressBar;
