export enum WhitelabelTabs {
  LogoBranding = 'Logo & Branding',
  DomainConfiguration = 'Domain Configuration',
}

export enum ResourceType {
  Logo = 'logo',
  Favicon = 'favicon',
  Icon = 'icon',
}
