import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

import {
  createDoNotContactList,
  deleteDoNotContactList,
  getDoNotContactLists,
  getDoNotContactListDetails,
  updateDoNotContactList,
  updateDoNotContactListDetails,
  getDoNotContactListDetailsMeta,
  deleteDoNotContactListEmailsAndDomains,
  bulkDeleteDoNotContactListEmailsAndDomains,
  importDoNotContactListEmailsAndDomains,
} from '../../helpers/do-not-contact.api';

import {
  CreateDoNotContactListPayload,
  GetDoNotContactListsPayload,
  GetDoNotContactListDetailsPayload,
  UpdateDoNotContactListPayload,
  UpdateDoNotContactListDetailsPayload,
  DeleteDoNotContactListEmailsAndDomainsPayload,
  BulkDeleteDoNotContactListEmailsAndDomainsPayload,
  ImportEmailsAndDomainsViaCsvPayload,
} from '../../types/do-not-contact-list';

export const getDoNotContactListsRequest = createAsyncThunk<
  ResponseSuccess,
  GetDoNotContactListsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/getDoNotContactLists', async (payload, thunkAPI) => {
  try {
    return await getDoNotContactLists(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const createDoNotContactListRequest = createAsyncThunk<
  ResponseSuccess,
  CreateDoNotContactListPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/createDoNotContactList', async (payload, thunkAPI) => {
  try {
    return await createDoNotContactList(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const updateDoNotContactListRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateDoNotContactListPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/updateDoNotContactList', async (payload, thunkAPI) => {
  try {
    return await updateDoNotContactList(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const deleteDoNotContactListRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/deleteDoNotContactList', async (payload, thunkAPI) => {
  try {
    return await deleteDoNotContactList(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const getDoNotContactListDetailsMetaRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/getDoNotContactListDetailsMeta', async (payload, thunkAPI) => {
  try {
    return await getDoNotContactListDetailsMeta(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const getDoNotContactListDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  GetDoNotContactListDetailsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/getDoNotContactListDetails', async (payload, thunkAPI) => {
  try {
    return await getDoNotContactListDetails(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const updateDoNotContactListDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateDoNotContactListDetailsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/updateDoNotContactListDetails', async (payload, thunkAPI) => {
  try {
    return await updateDoNotContactListDetails(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const deleteDoNotContactListEmailsAndDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteDoNotContactListEmailsAndDomainsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/deleteDoNotContactListEmailsAndDomains', async (payload, thunkAPI) => {
  try {
    return await deleteDoNotContactListEmailsAndDomains(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});

export const bulkDeleteDoNotContactListEmailsAndDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  BulkDeleteDoNotContactListEmailsAndDomainsPayload,
  { rejectValue: ResponseErrorWithHandled }
>(
  'dnc/bulkDeleteDoNotContactListEmailsAndDomains',
  async (payload, thunkAPI) => {
    try {
      return await bulkDeleteDoNotContactListEmailsAndDomains(payload);
    } catch (e) {
      return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
    }
  },
);

export const importDoNotContactListEmailsAndDomainsRequest = createAsyncThunk<
  ResponseSuccess,
  ImportEmailsAndDomainsViaCsvPayload,
  { rejectValue: ResponseErrorWithHandled }
>('dnc/importDoNotContactListEmailsAndDomains', async (payload, thunkAPI) => {
  try {
    return await importDoNotContactListEmailsAndDomains(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e as ResponseErrorWithHandled);
  }
});
