import store from '../../../store';

const hasPermission = (requiredPermission: string) => {
  const state = store.getState();
  const permissions = state.home.permissions ?? [];
  requiredPermission = requiredPermission.replace(/\./g, '\\.');
  return permissions.findIndex(userPermission => {
    const regex = new RegExp(`^((ACCOUNT|TEAM|AGENCY)\\.)?${requiredPermission}`);
    return regex.test(userPermission);
  }) > -1;
};

export default hasPermission;
