import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AlertTriangle } from '@saleshandy/icons';

import Alert from '../../../../shared/design-system/ui/alert';
import { TrialExtendSource } from '../../../../shared/enums/trial-extend-source';

import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../shared/utils/access-control/has-permission';

import { isPlanDowngradedFromPaid } from '../../../home/utils/plan-transition';
import { getFreePlanBannerString } from '../../helpers/get-free-plan-banner-string';
import type { IProps } from './header-banner-container';
import { Routes } from '../../../../shared/routes';

const HeaderBanner: React.FC<IProps> = ({
  isEmailAccountPage = false,
  isTeamPage = false,
  firstName,
  email,
  planTransitionDetails,
  isEligibleToExtendTrial,
  sendExtendTrialPeriodRequest,
}) => {
  const { t } = useTranslation();

  const [ctaText, setCTAText] = useState('Upgrade now');

  const emailBody = `Hey ${firstName}, %0D%0A %0D%0A ${t(
    'messages.upgrade_to_pro_email_body',
  )}`;

  const onTrialExtended = () => {
    if (isEligibleToExtendTrial) {
      sendExtendTrialPeriodRequest(TrialExtendSource.Webapp);
    }
  };

  useEffect(() => {
    if (isPlanDowngradedFromPaid(planTransitionDetails)) {
      setCTAText('Resubscribe now');
    }
  }, []);

  return (
    <>
      {!hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ) ? (
        <div className="header-banner-container">
          <div className="icon">
            <AlertTriangle />
          </div>
          <span>
            <span>
              {getFreePlanBannerString(isEmailAccountPage, isTeamPage)}
            </span>
            <span>
              <a
                href={`mailto:${email}:?subject=${t(
                  'labels.upgrade_to_saleshandy_pro_email_sub',
                )}&body=${emailBody}`}
                target="_blank"
                className="ml-1 blue-txt-11"
                rel="noreferrer"
              >{` ${t('labels.contact_admin')} `}</a>
              and request for{' '}
              {isPlanDowngradedFromPaid(planTransitionDetails)
                ? 'resubscribe'
                : 'upgrade'}
            </span>
          </span>
        </div>
      ) : (
        <Alert
          icon={AlertTriangle}
          className="alert-msg"
          variant="warning"
          description={
            <>
              {getFreePlanBannerString(isEmailAccountPage, isTeamPage)}{' '}
              <Link
                className="upgrade-btn"
                to={Routes.Setting_Billing_Upgrade_Email_Outreach_Plan}
              >
                {ctaText}
              </Link>{' '}
              {isEligibleToExtendTrial && (
                <>
                  {' '}
                  <span>or</span>
                  <button
                    className="trial-btn"
                    type="button"
                    onClick={onTrialExtended}
                  >
                    {' '}
                    Extend your trial
                  </button>{' '}
                </>
              )}
            </>
          }
        />
      )}
    </>
  );
};

export default HeaderBanner;
