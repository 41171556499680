import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseSuccess, ResponseErrorWithHandled } from '../../shared/types';
import { ClientParams } from './types/types';
import {
  getAgencyClients,
  createAgencyClient,
  updateAgencyClient,
  agencyClientsDropdown,
  assignAgencyClientResource,
  selectedAgencyClientsDropdown,
  deleteAgencyClient,
  toggleClientStatus,
  getAgencyClientActiveSequences,
  agencyClientResetPassword,
} from './helpers/agency-client.api';
import {
  CreateAgencyClientRequestPayload,
  UpdateAgencyClientRequestPayload,
  AssignAgencyClientResourceRequestPayload,
  GetAgencyClientDropdownRequestPayload,
  GetSelectedAgencyClientRequestPayload,
} from './types/request-payload';

export const getAgencyClientsRequest = createAsyncThunk<
  ResponseSuccess,
  ClientParams,
  { rejectValue: ResponseErrorWithHandled }
>('/getAgencyClientsRequest', async (args, thunkAPI) => {
  try {
    return await getAgencyClients(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createAgencyClientRequest = createAsyncThunk<
  ResponseSuccess,
  CreateAgencyClientRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('/createAgencyClientRequest', async (args, thunkAPI) => {
  try {
    return await createAgencyClient(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateAgencyClientRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateAgencyClientRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('/updateAgencyClientRequest', async (args, thunkAPI) => {
  try {
    return await updateAgencyClient(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteAgencyClientRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('/deleteAgencyClientRequest', async (args, thunkAPI) => {
  try {
    return await deleteAgencyClient(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const toggleAgencyClientStatusRequest = createAsyncThunk<
  ResponseSuccess,
  number,
  { rejectValue: ResponseErrorWithHandled }
>('agencyClient/toggleAgencyClientStatusRequest', async (args, thunkAPI) => {
  try {
    return await toggleClientStatus(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAgencyClientActiveSequencesRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('/getAgencyClientActiveSequencesRequest', async (args, thunkAPI) => {
  try {
    return await getAgencyClientActiveSequences(args);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAgencyClientsDropdown = createAsyncThunk<
  ResponseSuccess,
  GetAgencyClientDropdownRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('agencyClient/agencyClientsDropdown', async (payload, thunkAPI) => {
  try {
    return await agencyClientsDropdown(payload);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSelectedAgencyClient = createAsyncThunk<
  ResponseSuccess,
  GetSelectedAgencyClientRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('agencyClient/selectedAgencyClientsDropdown', async (payload, thunkAPI) => {
  try {
    return await selectedAgencyClientsDropdown(payload);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateAssignAgencyClientResource = createAsyncThunk<
  ResponseSuccess,
  AssignAgencyClientResourceRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('agencyClient/assignAgencyClientResource', async (payload, thunkAPI) => {
  try {
    return await assignAgencyClientResource(payload);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const agencyClientResetPasswordRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>(
  'agencyClient/agencyClientResetPasswordRequest',
  async (payload, thunkAPI) => {
    try {
      return await agencyClientResetPassword(payload);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);
