import React from 'react';
import Button from '../button';
import Icon from '../icon';

const DeleteButton = ({ label = '', handleDelete }) => (
  <Button
    className="d-flex justify-content-center action-btn--delete"
    onClick={handleDelete}
    theme={Button.Theme.Ghost}
    iconLeft={<Icon className="font-20" identifier="trash" />}
  >
    {label}
  </Button>
);

export default DeleteButton;
