import { isEmpty } from 'lodash';
import { ClientAssociated } from '../../../types/do-not-contact-list';
import { getClientName } from '../../../../agency-client-management/helpers/helpers';
import { AgencyClientFilter } from '../../../../agency-client-management/types/types';

export const getListAssociatedByName = (
  client: Partial<ClientAssociated>,
): string => {
  if (isEmpty(client)) {
    return 'All Clients (Global)';
  }

  return getClientName(client as AgencyClientFilter, 72, {
    company: true,
    email: true,
  });
};
