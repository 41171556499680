import { BillingOption } from '../../../../../../../shared/utils/billing-options';
import {
  PlanPurchaseAction,
  PlanType,
  SubscriptionPlanTitle,
  SubscriptionPlans,
} from '../../../../../../../shared/utils/subscription-plans';

export enum UpgradePlanQueryParamsKey {
  Action = 'action',
  Plan = 'plan',
  Period = 'period',
}

export const isValueBasedPlan = (planType: PlanType) =>
  planType === PlanType.ValueBased;

export const getPlanIndex = (plans, planId) =>
  plans
    .filter(({ planCode }) => isValueBasedPlan(planCode))
    .findIndex(({ id }) => id === planId);

export const getPlanPeriod = (planCycle: BillingOption) =>
  planCycle === BillingOption.MONTHLY ? '1' : '12';

export const getSubscribeAction = (isUserModifyingSubscription: boolean) =>
  isUserModifyingSubscription
    ? PlanPurchaseAction.Upgrade
    : PlanPurchaseAction.Subscribe;

export const isPremiumFeatureBannerShow = (planCode) =>
  planCode === SubscriptionPlans.TRIAL ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_MONTHLY ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_YEARLY ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_MONTHLY_8_2023 ||
  planCode === SubscriptionPlans.OUTREACH_BASIC_YEARLY_8_2023;

export const isAnnuallyPlan = (planCycle: BillingOption) =>
  planCycle === BillingOption.ANNUALLY;

export const stepWisePlanMonthly = {
  step1: SubscriptionPlanTitle.OutreachScaleMonthly100k,
  step2: SubscriptionPlanTitle.OutreachScaleMonthly200k,
  step3: SubscriptionPlanTitle.OutreachScaleMonthly300k,
  step4: SubscriptionPlanTitle.OutreachScaleMonthly400k,
  step5: SubscriptionPlanTitle.OutreachScaleMonthly500k,
  step6: SubscriptionPlanTitle.Custom,
};

export const stepWisePlanYearly = {
  step1: SubscriptionPlanTitle.OutreachScaleYearly100k,
  step2: SubscriptionPlanTitle.OutreachScaleYearly200k,
  step3: SubscriptionPlanTitle.OutreachScaleYearly300k,
  step4: SubscriptionPlanTitle.OutreachScaleYearly400k,
  step5: SubscriptionPlanTitle.OutreachScaleYearly500k,
  step6: SubscriptionPlanTitle.Custom,
};

export const getCustomScalePlusPlanName = (step, isAnnualPlan) =>
  isAnnualPlan
    ? stepWisePlanYearly[`step${step}`]
    : stepWisePlanMonthly[`step${step}`];
