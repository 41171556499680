import React from 'react';
import { isEmpty } from 'lodash';
import { DoNotContactList } from '../../../../../../../types/do-not-contact-list';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../../../shared/design-system/components/overlay';
import { getClientName } from '../../../../../../../../agency-client-management/helpers/helpers';

type IProps = {
  doNotContactList: DoNotContactList;
};

const ClientAssociatedField: React.FC<IProps> = ({
  doNotContactList: { client },
}) => {
  if (isEmpty(client)) {
    return <p className="client-associated-field">All Clients (Global)</p>;
  }

  const clientRef = {
    id: client.id,
    firstName: client.firstName,
    lastName: client.lastName,
    email: client.email,
    companyName: client.companyName,
    status: client.status,
  };

  return (
    <p className="client-associated-field">
      <OverlayTooltip
        text={`${getClientName(clientRef, 60, {
          email: true,
          deleted: true,
        })}`}
        placement={Placement.BottomStart}
        className=" sequence-client-associated-tooltip tooltip-max"
      >
        <span>
          {getClientName(clientRef, 36, {
            company: true,
          })}
        </span>
      </OverlayTooltip>
    </p>
  );
};

export default ClientAssociatedField;
