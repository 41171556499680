import React from 'react';

export const PlayCircle = ({ width = 14, height = 16 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00004 1.33337C3.32404 1.33337 0.333374 4.32404 0.333374 8.00004C0.333374 11.676 3.32404 14.6667 7.00004 14.6667C10.676 14.6667 13.6667 11.676 13.6667 8.00004C13.6667 4.32404 10.676 1.33337 7.00004 1.33337ZM7.00004 13.3334C4.05937 13.3334 1.66671 10.9407 1.66671 8.00004C1.66671 5.05937 4.05937 2.66671 7.00004 2.66671C9.94071 2.66671 12.3334 5.05937 12.3334 8.00004C12.3334 10.9407 9.94071 13.3334 7.00004 13.3334Z"
      fill="currentColor"
    />
    <path
      d="M9.38994 7.99996L5.5 10.4312V5.56875L9.38994 7.99996Z"
      stroke="currentColor"
    />
  </svg>
);
