import React from 'react';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';
import type { IProps } from './type';

const TruncatedTextWithTooltip: React.FC<IProps> = ({
  string = '',
  length,
  placement = Placement.Bottom,
  tooltipClassName = '',
  textClassName = '',
}) =>
  string?.length > length ? (
    <OverlayTooltip
      text={string}
      placement={placement}
      className={tooltipClassName}
    >
      <span className={`${textClassName} pointer`}>{`${string
        .slice(0, length)
        .trim()}...`}</span>
    </OverlayTooltip>
  ) : (
    <span className={textClassName}>{string}</span>
  );

export default TruncatedTextWithTooltip;
