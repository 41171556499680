import React, { useState, useEffect } from 'react';
import Tabs, { TabPane } from 'rc-tabs';
import { GlobalSettings } from '../../../../shared/components/global-settings-wrapper';
import type { IProps } from './whitelabel-container';
import { WhitelabelTabs } from './utils/enums';
import LogoBranding from './components/logo-branding';
import DomainConfiguration from './components/domain-configuration';
import PremiumFeatureIndicator from '../../../../shared/components/premium-feature-indicator';
import { userHasWhitelabelAccessPermission } from '../../../../shared/utils/user-details';
import LinkBadge from '../../../../shared/design-system/components/atoms/link-badge';
import { supportUrls } from '../../../../shared/utils/urls';

const Whitelabel: React.FC<IProps> = ({ resetResourceState }) => {
  //* State */
  const [activeTabKey, setActiveTabKey] = useState<string>('logo-branding');

  //* Helper Functions */
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  useEffect(
    () => () => {
      resetResourceState();
    },
    [],
  );

  return (
    <>
      <GlobalSettings.Header className="globalSettings__Header">
        <GlobalSettings.HeaderLeft>
          <GlobalSettings.HeaderTitle
            title="Whitelabel"
            isToolTip={true}
            tooltipText="Add your own logo and URL to  personalize the client experience and build brand reputation"
          />
          <LinkBadge
            label="Know more"
            url={supportUrls.whitelabelKnowMoreURL}
          />
          <div>
            <PremiumFeatureIndicator
              show={!userHasWhitelabelAccessPermission()}
              tooltipTxt="This is a premium feature that is available on SCALE & higher plans. It's not included in your current plan."
            />
          </div>
        </GlobalSettings.HeaderLeft>
      </GlobalSettings.Header>
      <GlobalSettings.Content className="whitelabel">
        <Tabs
          activeKey={activeTabKey}
          prefixCls="bs-tabs"
          className="bs-tabs-small subscription-info-tabs"
          onChange={(key: string) => onTabChange(key)}
        >
          <TabPane tab={WhitelabelTabs.LogoBranding} key="logo-branding">
            <LogoBranding />
          </TabPane>
          <TabPane
            tab={WhitelabelTabs.DomainConfiguration}
            key="domain-configuration"
          >
            <DomainConfiguration />
          </TabPane>
        </Tabs>
      </GlobalSettings.Content>
    </>
  );
};

export default Whitelabel;
