import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from '@saleshandy/design-system';
import validator from 'validator';
import classNames from 'classnames';

import type { IProps } from './add-webhook-container';
import { WebhookTriggerEvent } from '../../../../enums/webhook';

import toaster, { Theme } from '../../../../../../shared/toaster';

import AddWebhookHeader from './components/add-webhook-header';
import AddWebhookForm from './components/add-webhook-form';

import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import WebhookReqResPreview from '../webhook-req-res-preview';
import { getFormattedDateTime } from '../webhook-details-and-logs/helper';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../shared/design-system/components/overlay';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { WebhookTestData } from '../../../../types/webhook';

const AddWebhook: React.FC<IProps> = ({
  hideHeader = false,
  defaultWebhook,
  action = 'create',

  sendCreateWebhookRequest,
  resetCreateWebhookRequest,
  createWebhookRequestStatus,
  createWebhookRequestError,

  sendUpdateWebhookRequest,
  resetUpdateWebhookRequest,
  updateWebhookRequestStatus,
  updateWebhookRequestError,

  sendTestWebhookRequest,
  resetTestWebhookRequest,
  resetWebhookTestResult,
  testWebhookRequestStatus,
  testWebhookRequestError,
  webhookTestResult,
}) => {
  const history = useHistory();

  const { webhookId } = useParams<{
    webhookId: string;
  }>();

  const [webhookName, setWebhookName] = useState('');
  const [webhookNameError, setWebhookNameError] = useState('');

  const [webhookURL, setWebhookURL] = useState('');
  const [webhookURLError, setWebhookURLError] = useState('');

  const [headers, setHeaders] = useState([{ key: '', value: '', id: 1 }]);

  const [eventsToSend, setEventsToSend] = useState<WebhookTriggerEvent[]>([]);

  const [webhookTestData, setWebhookTestData] = useState<WebhookTestData>(null);
  const [testDataRef, setTestDataRef] = useState<'webhook' | 'testResult'>(
    'webhook',
  );

  const [isExpanded, setIsExpanded] = useState(false);

  const onExpandOrCollapsed = () => {
    setIsExpanded(!isExpanded);
  };

  const onWebhookNameChange = (name) => {
    setWebhookName(name);

    if (name.length > 250) {
      setWebhookNameError('Maximum Character length reached');
    } else {
      setWebhookNameError('');
    }
  };

  const onWebhookURLChange = (url) => {
    setWebhookURL(url);

    if (!validator.isURL(url)) {
      setWebhookURLError('Invalid URL format');
    } else {
      setWebhookURLError('');
    }
  };

  const onAddHeader = () => {
    if (headers.length < 5) {
      setHeaders([...headers, { key: '', value: '', id: headers.length + 1 }]);
    }
  };

  const onDeleteHeader = (id: number) => {
    if (headers.length > 1) {
      const updatedHeaders = headers.filter((header) => header.id !== id);
      setHeaders(updatedHeaders);
    }
  };

  const onHeaderKeyChange = (id: number, key: string) => {
    const updatedHeaders = headers.map((header) =>
      header.id === id ? { ...header, key } : header,
    );
    setHeaders(updatedHeaders);
  };

  const onHeaderValueChange = (id: number, value: string) => {
    const updatedHeaders = headers.map((header) =>
      header.id === id ? { ...header, value } : header,
    );
    setHeaders(updatedHeaders);
  };

  const onWebhookTriggerEventChange = (
    checked: boolean,
    trigger: WebhookTriggerEvent,
  ) => {
    if (checked) {
      setEventsToSend([...eventsToSend, trigger]);
    } else {
      setEventsToSend(eventsToSend.filter((event) => event !== trigger));
    }
  };

  const generateHeadersObject = () =>
    headers.reduce((obj, item) => {
      if (item.key !== '' && item.value !== '') {
        obj[item.key] = item.value;
      }
      return obj;
    }, {});

  const checkIsError = () =>
    webhookName === '' ||
    webhookNameError !== '' ||
    webhookURL === '' ||
    webhookURLError !== '' ||
    eventsToSend.length === 0;

  const onSaveAndTest = () => {
    if (!checkIsError()) {
      if (action === 'create') {
        sendCreateWebhookRequest({
          name: webhookName,
          webhookUrl: webhookURL,
          webhookEvent: eventsToSend,
          headers: generateHeadersObject(),
        });
        return;
      }

      if (action === 'update' && webhookId) {
        sendUpdateWebhookRequest({
          webhookId,
          name: webhookName,
          webhookUrl: webhookURL,
          webhookEvent: eventsToSend,
          headers: generateHeadersObject(),
        });
        setTestDataRef('webhook');
      }
    }
  };

  const onTest = () => {
    if (!checkIsError()) {
      sendTestWebhookRequest({
        name: webhookName,
        webhookUrl: webhookURL,
        webhookEvent: eventsToSend,
        headers: generateHeadersObject(),
      });
      setTestDataRef('testResult');
    }
  };

  useEffect(() => {
    if (!hasPermission(Permissions.WEBHOOKS_WRITE)) {
      history.push('/settings/webhook');
    }
  }, []);

  useEffect(
    () => () => {
      resetWebhookTestResult();
    },
    [],
  );

  useEffect(() => {
    if (testDataRef === 'webhook' && defaultWebhook) {
      setWebhookTestData({
        failed: defaultWebhook?.failed,
        statusCode: defaultWebhook?.failed ? 400 : 200,
        statusText: defaultWebhook?.failed ? 'Not Found' : 'OK',
        meta: {
          status: defaultWebhook?.failed ? 'error' : 'success',
          message: defaultWebhook?.failed
            ? 'Webhook processing failed.'
            : 'Webhook received and processed successfully.',
          ...(defaultWebhook?.failed && {
            error_code: defaultWebhook?.failedData?.body?.code,
            error_message: defaultWebhook?.failedData?.body?.message,
          }),
        },
        lastTriggeredAt: `Last Triggered on ${getFormattedDateTime(
          defaultWebhook?.lastActivityAt?.toString(),
        )}`,
      });
      return;
    }
    if (testDataRef === 'testResult') {
      setWebhookTestData({
        failed: webhookTestResult?.failed,
        statusCode: webhookTestResult?.failed ? 400 : 200,
        statusText: webhookTestResult?.failed ? 'Not Found' : 'OK',
        meta: {
          status: webhookTestResult?.failed ? 'error' : 'success',
          message: webhookTestResult?.failed
            ? 'Webhook processing failed.'
            : 'Webhook received and processed successfully.',
          ...(webhookTestResult?.failed && {
            error_code: webhookTestResult?.testResponse?.code,
            error_message: webhookTestResult?.testResponse?.message,
          }),
        },
        lastTriggeredAt: `Last Triggered on ${getFormattedDateTime(
          webhookTestResult?.testData?.emailSentAt?.toString(),
        )}`,
      });
    }
  }, [defaultWebhook, webhookTestResult]);

  useEffect(() => {
    if (defaultWebhook) {
      setWebhookName(defaultWebhook?.name || '');
      setWebhookURL(defaultWebhook?.url || '');
      setHeaders(
        defaultWebhook?.headers
          ? Object.entries(defaultWebhook?.headers).map(([key, value], id) => ({
              key,
              value,
              id: id + 1,
            }))
          : [{ key: '', value: '', id: 1 }],
      );
      setEventsToSend(defaultWebhook?.events);
    }
  }, [defaultWebhook]);

  useEffect(() => {
    executeOnRequestStatus({
      status: createWebhookRequestStatus,
      onSuccess: () => {
        history.push('/settings/webhook');
        resetCreateWebhookRequest();
        toaster.success('Webhook has been saved successfully', {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        history.push('/settings/webhook');
        resetCreateWebhookRequest();
        executeOnErrorWithErrorCheck({
          error: createWebhookRequestError,
          onError: () => {
            toaster.error(createWebhookRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [createWebhookRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateWebhookRequestStatus,
      onSuccess: () => {
        resetUpdateWebhookRequest();
        toaster.success('Webhook has been saved successfully', {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        resetUpdateWebhookRequest();
        executeOnErrorWithErrorCheck({
          error: updateWebhookRequestError,
          onError: () => {
            toaster.error(updateWebhookRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [updateWebhookRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: testWebhookRequestStatus,
      onSuccess: () => {
        resetTestWebhookRequest();
      },
      onFailed: () => {
        resetTestWebhookRequest();
        executeOnErrorWithErrorCheck({
          error: testWebhookRequestError,
          onError: () => {
            toaster.error(testWebhookRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [testWebhookRequestStatus]);

  const isCreateWebhookRequestStatusPending = getIsRequestPending(
    createWebhookRequestStatus,
  );
  const isUpdateWebhookRequestStatusPending = getIsRequestPending(
    updateWebhookRequestStatus,
  );
  const isTestWebhookRequestStatusPending = getIsRequestPending(
    testWebhookRequestStatus,
  );
  const isError = checkIsError();

  const testBtn = (
    <Button
      variant="secondary"
      onClick={onTest}
      disabled={
        isTestWebhookRequestStatusPending || isError || eventsToSend?.length > 1
      }
      isLoading={isTestWebhookRequestStatusPending}
      loadingText="Test"
    >
      Test
    </Button>
  );

  if (!hasPermission(Permissions.WEBHOOKS_WRITE)) {
    return <></>;
  }

  const webhookContentWrapperClassNames = classNames([
    'webhook-content-wrapper',
    {
      isExpanded,
    },
  ]);

  return (
    <div className="webhook d-flex w-100">
      {!hideHeader && <AddWebhookHeader />}

      <div className={webhookContentWrapperClassNames}>
        <AddWebhookForm
          webhookName={webhookName}
          webhookNameError={webhookNameError}
          onWebhookNameChange={onWebhookNameChange}
          webhookURL={webhookURL}
          webhookURLError={webhookURLError}
          onWebhookURLChange={onWebhookURLChange}
          headers={headers}
          onAddHeader={onAddHeader}
          onDeleteHeader={onDeleteHeader}
          onHeaderKeyChange={onHeaderKeyChange}
          onHeaderValueChange={onHeaderValueChange}
          eventsToSend={eventsToSend}
          onWebhookTriggerEventChange={onWebhookTriggerEventChange}
        />

        {webhookTestData && (
          <WebhookReqResPreview
            label="Response"
            statusCode={webhookTestData?.statusCode}
            statusText={webhookTestData?.statusText}
            json={webhookTestData?.meta}
            lastTriggeredAt={webhookTestData?.lastTriggeredAt}
            failed={webhookTestData?.failed}
            isExpanded={isExpanded}
            onExpandOrCollapsed={onExpandOrCollapsed}
          />
        )}
      </div>
      <div className="webhook--footer">
        <Button
          onClick={onSaveAndTest}
          disabled={
            isCreateWebhookRequestStatusPending ||
            isUpdateWebhookRequestStatusPending ||
            isError
          }
          isLoading={
            isCreateWebhookRequestStatusPending ||
            isUpdateWebhookRequestStatusPending
          }
          loadingText="Save"
        >
          Save
        </Button>
        {eventsToSend?.length > 1 ? (
          <OverlayTooltip
            text="Select 1 event for testing"
            placement={Placement.Top}
          >
            {testBtn}
          </OverlayTooltip>
        ) : (
          testBtn
        )}
      </div>
    </div>
  );
};

export default AddWebhook;
