import React from 'react';
import { withTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import { CloudUpload } from '@saleshandy/icons';
import Modal from '../../../design-system/components/atoms/modal';
import { Images } from '../../../app-constants';
import ImageIcon from '../../../components/images/image-icon';
import { contactAdmin } from '../../contact-admin';
import Button from '../../../design-system/components/atoms/button';
import hasPermission from '../../access-control/has-permission';
import { Permissions } from '../../access-control/enums/permissions';
import { Routes } from '../../../routes';

type PlanErrorManagerState = {
  showPlanPermissionModal: boolean;
  title: string;
  contents: string[];
  showButtons: boolean;
};

type PlanErrorManagerProps = RouteComponentProps & {
  bindActions: (...args: any[]) => any;
  t: (x: string) => string;
};

class PlanErrorManager extends React.Component<
  PlanErrorManagerProps,
  PlanErrorManagerState
> {
  constructor(props) {
    super(props);

    this.state = {
      showPlanPermissionModal: false,
      title: '',
      contents: [],
      showButtons: false,
    };

    this.showUpgradePlanModal = this.showUpgradePlanModal.bind(this);
    this.hidePlanPermissionModal = this.hidePlanPermissionModal.bind(this);

    const { bindActions } = this.props;

    bindActions(this.showUpgradePlanModal);
  }

  handleSubmit = () => {
    const { history, t } = this.props;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      this.setState({ showPlanPermissionModal: false });
      history.push(Routes.Setting_Billing_Upgrade_Email_Outreach_Plan);
    } else {
      const emailBody = `${t('messages.upgrade_to_pro_email_body')}`;

      contactAdmin(t('labels.upgrade_to_saleshandy_pro_email_sub'), emailBody);
    }
  };

  showUpgradePlanModal(
    title: string,
    contents: string[],
    showButtons: boolean,
  ) {
    this.setState({
      showPlanPermissionModal: true,
      title,
      contents,
      showButtons,
    });
  }

  hidePlanPermissionModal() {
    this.setState({ showPlanPermissionModal: false });
  }

  render() {
    const {
      showPlanPermissionModal,
      title,
      contents,
      showButtons,
    } = this.state;
    const { t } = this.props;

    return (
      <Modal
        show={showPlanPermissionModal}
        className="upgrade-plan-modal"
        titleContent={
          <div className="d-flex">
            <CloudUpload width={24} height={24} className="blue-txt-11" />
            <div className="pl-2"> {title || t('labels.upgrade_required')}</div>
          </div>
        }
        onClose={this.hidePlanPermissionModal}
        onHide={this.hidePlanPermissionModal}
        onSubmit={this.handleSubmit}
        showCloseIcon={true}
        hideCancelButton={!showButtons}
        hideSubmitButton={!showButtons}
        cancelButtonText={t('labels.cancel')}
        cancelButtonVarient={Button.Variant.Outlined}
        submitButtonText={
          hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? t('labels.upgrade')
            : t('labels.contact_admin')
        }
      >
        <div className="bs-modal-content">
          <div className="bs-modal-inner-content">
            {contents && (
              <div className="contents">
                {contents.map((content) => (
                  <>
                    <p
                      key={content}
                      className="bs-mb-20 bs-mt-15 bs-modal-inner-content__contents"
                    >
                      {content}
                    </p>
                  </>
                ))}
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(withRouter(PlanErrorManager));
