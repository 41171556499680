import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

const AddWebhookLoader: React.FC = () => (
  <div className="webhook d-flex w-100">
    <div className="webhook-content-wrapper">
      <div className="webhook--form">
        <div className="webhook-form-inner">
          <div className="webhook-form-field">
            <div className="webhook-form-field-label">
              <p>
                <SkeletonLoading width={100} height={16} />
              </p>
            </div>
            <SkeletonLoading width={562} height={32} />
          </div>

          <div className="webhook-form-field">
            <div className="webhook-form-field-label">
              <p>
                <SkeletonLoading width={180} height={16} />
              </p>
            </div>
            <SkeletonLoading width={562} height={32} />
            <p className="webhook-form-field-helper-text">
              <SkeletonLoading width={300} height={16} />
            </p>
          </div>

          <div className="http-header-loader">
            <SkeletonLoading width={120} height={20} />
            <SkeletonLoading width={48} height={20} />
          </div>

          <div className="webhook-form-field">
            <div className="d-flex justify-content-between align-items-center">
              <div className="webhook-form-field-label justify-content-between">
                <SkeletonLoading width={180} height={16} />
              </div>
              <div className="example-data-btn">
                <SkeletonLoading width={100} height={16} />
              </div>
            </div>

            <div className="events-container-loader">
              <div className="d-flex align-items-center">
                <div className="mr-1 mb-1">
                  <SkeletonLoading width={20} height={20} borderRadius={4} />
                </div>
                <SkeletonLoading width={160} height={20} borderRadius={4} />
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-1 mb-1">
                  <SkeletonLoading width={20} height={20} borderRadius={4} />
                </div>
                <SkeletonLoading width={200} height={20} borderRadius={4} />
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-1 mb-1">
                  <SkeletonLoading width={20} height={20} borderRadius={4} />
                </div>
                <SkeletonLoading width={180} height={20} borderRadius={4} />
              </div>
              <div className="d-flex align-items-center">
                <div className="mr-1 mb-1">
                  <SkeletonLoading width={20} height={20} borderRadius={4} />
                </div>
                <SkeletonLoading width={220} height={20} borderRadius={4} />
              </div>
            </div>

            <p className="webhook-form-field-helper-text">
              <SkeletonLoading width={400} height={16} />
            </p>
          </div>
        </div>
      </div>

      <div className="webhook-req-res-preview">
        <div className="webhook-req-res-preview--header mb-24px">
          <div className="header-left">
            <SkeletonLoading width={100} height={20} />
            <SkeletonLoading width={120} height={20} borderRadius={10} />
          </div>
          <div className="header-right">
            <div className="webhook-response-cta">
              <SkeletonLoading width={20} height={20} />
            </div>
          </div>
        </div>

        <div className="webhook-req-res-preview--code code-loader">
          <SkeletonLoading height={280} />
        </div>
      </div>
    </div>
  </div>
);

export default AddWebhookLoader;
