import { withTranslation } from 'react-i18next';
import React, { useState } from 'react';
import classNames from 'classnames';
import { IProps, FieldContentTabs } from './types';
import Button from '../../../../../../shared/design-system/components/atoms/button';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import CustomFieldTabPanes from './custom-field-tabpanes';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';

const CustomFieldContent: React.FC<IProps> = ({
  customFields,
  defaultFields,
  customFieldPagination,
  defaultFieldPagination,
  sendGetCustomFieldsRequest,
  sendGetDefaultFieldsRequest,
  onUpdateHandler,
  onDeleteHandler,
  onCreateHandler,
  isRequestPending,
  subscriptionPlan,
  t,
  activeTabKey,
  setActiveTabKey,
}) => {
  const onTabChange = (tabKey) => {
    setActiveTabKey(tabKey);
  };

  const onCustomFieldPaginationOptionsChangeHandler = (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => {
    const { options } = customFieldPagination;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetCustomFieldsRequest(payload);
  };

  const onDefaultFieldPaginationOptionsChangeHandler = (
    newPartialOptions: Partial<PaginationQueryParams>,
  ) => {
    const { options } = customFieldPagination;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetDefaultFieldsRequest(payload);
  };

  const onAction = (key, row) =>
    key === 'edit' ? onUpdateHandler(row) : onDeleteHandler(row);

  const containerClassName = classNames([
    'custom-fields-content-container',
    {
      'custom-fields-container': activeTabKey === FieldContentTabs.CustomFields,
      'system-fields-container': activeTabKey === FieldContentTabs.SystemFields,
    },
  ]);

  return (
    <GlobalSettings.Content className={containerClassName}>
      {hasPermission(Permissions.FIELD_WRITE) && (
        <div className="add-custom-fields-container">
          <Button
            onClick={onCreateHandler}
            variant={Button.Variant.Primary}
            disabled={subscriptionPlan === SubscriptionPlans.FREE}
            className="header-btn"
          >
            {t('labels.add_prospect_fields')}
          </Button>
        </div>
      )}
      <CustomFieldTabPanes
        onCreateHandler={onCreateHandler}
        customFields={customFields}
        defaultFields={defaultFields}
        defaultFieldPagination={defaultFieldPagination}
        customFieldPagination={customFieldPagination}
        onActonHandler={onAction}
        onCustomFieldPaginationOptionsChangeHandler={
          onCustomFieldPaginationOptionsChangeHandler
        }
        onDefaultFieldPaginationOptionsChangeHandler={
          onDefaultFieldPaginationOptionsChangeHandler
        }
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        subscriptionPlan={subscriptionPlan}
        isRequestPending={isRequestPending}
      />
    </GlobalSettings.Content>
  );
};

export default withTranslation()(CustomFieldContent);
