import React from 'react';
import classnames from 'classnames';

import { IProps, StatusBadgeVariant } from './types';
import Badge from '../../atoms/badge/badge';

const StatusBadge: React.FC<IProps> = ({ label, variant }) => {
  const badgeClass = classnames([
    {
      'badge-dot-success': variant === StatusBadgeVariant.SUCCESS,
    },
    {
      'badge-dot-danger': variant === StatusBadgeVariant.DANGER,
    },
    {
      'badge-dot-secondary': variant === StatusBadgeVariant.SECONDARY,
    },
    {
      'badge-dot-orange': variant === StatusBadgeVariant.WARNING,
    },
    {
      'badge-dot-green': variant === StatusBadgeVariant.LIGHT_GREEN,
    },
    {
      'badge-dot-blue': variant === StatusBadgeVariant.BLUE,
    },
  ]);

  return (
    <div className="sequence-status d-flex align-items-center">
      <div className="sequence-status-badge">
        <Badge className={badgeClass} />
      </div>
      <p className="text-capitalize sequence-progress mb-0">{label}</p>
    </div>
  );
};

export default StatusBadge;
