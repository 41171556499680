import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Button, Pills } from '@saleshandy/design-system';
import { Cross } from '@saleshandy/icons';
import validator from 'validator';
import ContentEditable from 'react-contenteditable';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import Select from '../../../../../../../shared/design-system/components/select';
import EmailInput from '../../email-input';
import { UserRole } from '../../../../../enums/users-and-teams';
import {
  AddUserPayload,
  TeamsList,
} from '../../../../../types/users-and-teams';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import {
  capitalize,
  getIsRequestPending,
} from '../../../../../../../shared/utils';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import { Placement } from '../../../../../../../shared/design-system/components/overlay';
import { useScreenSize } from '../../../../../../../shared/hooks/useScreenSize';

type ChangeRoleType = {
  key: UserRole;
};

type AssignTeamType = TeamsList & {
  key: string;
};

type IProps = {
  show: boolean;
  teamsList: TeamsList[];
  onClose: VoidFunction;
  onSubmit: (payload: AddUserPayload) => void;
  addUserRequestStatus: RequestStatus;
  sendGetTeamsListRequest: VoidFunction;
  getTeamsListRequestStatus: RequestStatus;
  atmRole: string;
};

const InviteUserModal: React.FC<IProps> = ({
  show,
  teamsList,
  onClose,
  onSubmit,
  addUserRequestStatus,
  sendGetTeamsListRequest,
  getTeamsListRequestStatus,
  atmRole,
}) => {
  // Define a threshold value for conditional rendering
  const SCREEN_HEIGHT_THRESHOLD = 630;
  const { screenHeight } = useScreenSize();

  const searchInput = useRef<HTMLDivElement>(null);
  const [emails, setEmails] = useState<string[]>([]);
  const [emailValue, setEmailValue] = useState('');
  const [selectedRole, setSelectedRole] = useState(UserRole.Member);
  const [selectedTeamsKeys, setSelectedTeamsKeys] = useState<string[]>([]);
  const [assignTeamDropdown, setAssignTeamDropdown] = useState(false);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailInputAction, setEmailInputAction] = useState('');
  const [searchText, setSearchText] = useState('');
  const [isEditableDivFocused, setIsEditableDivFocused] = useState(false);

  const mappedTeamsForFilter = teamsList.map((team) => ({
    key: team.id.toString(),
    ...team,
  }));

  const roles = useMemo(
    () =>
      [
        {
          key: UserRole.Member,
        },
        {
          key: UserRole.Admin,
        },
        atmRole === UserRole.Owner ? { key: UserRole.Owner } : null,
      ].filter(Boolean),
    [atmRole],
  );

  const onToggle = (value: boolean) => {
    setAssignTeamDropdown(value);
    if (value && searchInput?.current) {
      searchInput.current.focus();
    }
    if (isEditableDivFocused && !value) {
      setIsEditableDivFocused(false);
      setSearchText('');
    }
  };

  const onSearchChange = (e) => {
    setSearchText(e.target.value);
    if (!assignTeamDropdown) {
      setAssignTeamDropdown(true);
    }
  };

  const onEmailValueChange = (value: string) => {
    if (value !== ',' && value !== ' ') {
      setEmailValue(value);
    }
  };

  const onAddEmail = () => {
    const addedEmails = emailValue
      .replaceAll(',', ' ')
      .split(' ')
      .filter((e) => e.trim());

    const emailRef = [];
    let emailValues = '';
    let isError = false;

    addedEmails.forEach((email, index) => {
      if (
        validator.isEmail(email) &&
        !emails.includes(email) &&
        !emailRef.includes(email)
      ) {
        emailRef.push(email);
      } else if (index === addedEmails.length - 1) {
        emailValues = emailValues.concat(`${email}`);
      } else {
        emailValues = emailValues.concat(`${email}, `);
      }
      isError = isError || !!emailValues;
    });
    setIsEmailError(isError);
    setEmailValue(emailValues);
    setEmails([...emails, ...emailRef]);
  };

  const onAction = (action: string) => {
    if (
      action === ',' ||
      action === ' ' ||
      action === 'Enter' ||
      action === 'Blur'
    ) {
      onAddEmail();
    }

    if (action === 'Paste') {
      setEmailInputAction('Paste');
    }
  };

  const onEmailRemove = (value: string) => {
    setEmails(emails.filter((email) => email !== value));
  };

  const onSendInvite = () => {
    onSubmit({
      emails,
      userRole: selectedRole,
      teamIds: selectedTeamsKeys.map((key) => Number(key)),
    });
  };

  const onTeamRemove = (key: string) => {
    setTimeout(() => {
      setAssignTeamDropdown(false);
    }, 10);

    setSelectedTeamsKeys(
      selectedTeamsKeys.filter((teamKey) => teamKey !== key),
    );
  };

  useEffect(() => {
    if (emailInputAction === 'Paste' && emailValue) {
      onAddEmail();
      setEmailInputAction('');
    }
  }, [emailInputAction, emailValue]);

  useEffect(() => {
    if (show && hasPermission(Permissions.ACCOUNT_TEAM_INVITE_WRITE)) {
      sendGetTeamsListRequest();
    }
    if (show) {
      setEmails([]);
      setEmailValue('');
      setSelectedRole(UserRole.Member);
      setSelectedTeamsKeys([]);
      setSearchText('');
      setIsEditableDivFocused(false);
    }
    return () => {
      setEmails([]);
      setEmailValue('');
      setSelectedRole(UserRole.Member);
      setSelectedTeamsKeys([]);
      setSearchText('');
      setIsEditableDivFocused(false);
    };
  }, [show]);

  const isAddUserRequestPending = getIsRequestPending(addUserRequestStatus);

  return (
    <Modal
      show={show}
      className="invite-user-modal"
      hideHeader
      hideFooter
      backdrop="static"
    >
      <div
        className="invite-user-modal--close-btn"
        {...accessibleOnClick(onClose)}
      >
        <Cross />
      </div>

      <div className="invite-user-modal--content">
        <div className="invite-user-modal--wrapper">
          <h1 className="invite-user-modal--title">Invite Users</h1>

          <EmailInput
            value={emailValue}
            emails={emails}
            onEmailRemove={onEmailRemove}
            handleOnChange={onEmailValueChange}
            onAction={onAction}
            isEmailError={isEmailError}
          />

          <div className="invite-user-modal--container change-role-action">
            <p className="user-action-label">Assign User Role</p>
            <Select<ChangeRoleType>
              className="users-change-role-select"
              options={roles}
              selectedOptionKey={selectedRole}
              selectedOptionRenderer={([option]) => (
                <span className="blue-txt-15">{capitalize(option?.key)}</span>
              )}
              onChange={([option]) => setSelectedRole(option.key)}
              optionRenderer={(option) => (
                <span className="blue-txt-15">{capitalize(option?.key)}</span>
              )}
            />
          </div>

          <div className="invite-user-modal--container assign-team-action">
            <p className="user-action-label">Assign Team</p>
            <Select<AssignTeamType>
              className="users-change-role-select"
              multiSelect
              options={mappedTeamsForFilter}
              show={assignTeamDropdown}
              onToggle={onToggle}
              disabled={!hasPermission(Permissions.ACCOUNT_TEAM_INVITE_WRITE)}
              selectedOptionKey={selectedTeamsKeys.map((key) => key)}
              placeholder={
                <ContentEditable
                  html={isEditableDivFocused ? searchText : 'Select'}
                  disabled={
                    !hasPermission(Permissions.ACCOUNT_TEAM_INVITE_WRITE)
                  }
                  onChange={onSearchChange}
                  className="select-search"
                  placeholder="Select"
                  innerRef={searchInput}
                  onFocus={() => {
                    if (!isEditableDivFocused) {
                      setIsEditableDivFocused(true);
                    }
                  }}
                />
              }
              optionFilterProp="name"
              filterOption={(_, option) =>
                option?.name?.toLowerCase().includes(searchText.toLowerCase())
              }
              selectedOptionRenderer={(option) => (
                <div className="email-input--pills">
                  {option?.map(
                    (team) =>
                      team && (
                        <Pills
                          theme="outline"
                          size="md"
                          label={`${team.name}`.trim()}
                          showCloseIcon={true}
                          onClose={() => onTeamRemove(team.key)}
                          key={team.id}
                        />
                      ),
                  )}

                  <ContentEditable
                    innerRef={searchInput}
                    html={searchText}
                    disabled={
                      !hasPermission(Permissions.ACCOUNT_TEAM_INVITE_WRITE)
                    }
                    onChange={onSearchChange}
                    className="select-search"
                    onBlur={() => {
                      if (isEditableDivFocused) {
                        setIsEditableDivFocused(false);
                      }
                      setSearchText('');
                    }}
                  />
                </div>
              )}
              onChange={(selectedTeams) => {
                if (searchText) {
                  setSearchText('');
                }
                searchInput?.current?.focus();
                setSelectedTeamsKeys(selectedTeams.map((option) => option.key));
              }}
              optionRenderer={(option) => (
                <span className="blue-txt-15">{option?.name}</span>
              )}
              isLoading={getIsRequestPending(getTeamsListRequestStatus)}
              showOptionsSeparator={true}
              placement={
                screenHeight < SCREEN_HEIGHT_THRESHOLD
                  ? Placement.Top
                  : Placement.Bottom
              }
              emptyText={
                searchText ? 'No Team Found' : 'Create new teams to assign'
              }
            />
          </div>
        </div>

        <Button
          isFullWidth
          onClick={onSendInvite}
          isLoading={isAddUserRequestPending}
          disabled={
            isAddUserRequestPending ||
            emails.length === 0 ||
            emails.length > 50 ||
            isEmailError
          }
          loadingText="Sending..."
        >
          Send Invite
        </Button>
      </div>
    </Modal>
  );
};

export default InviteUserModal;
