import React from 'react';
import { Avatar } from '@saleshandy/design-system';

import { Webhook } from '../../../../../../../types/webhook';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../../../shared/design-system/components/overlay';

type IProps = {
  webhook: Webhook;
};

const WebhookCreatedByField: React.FC<IProps> = ({ webhook }) => {
  const [firstName, lastName] = webhook?.createdBy?.split(' ');

  return (
    <p>
      <OverlayTooltip
        text={`${firstName || ''} ${lastName || ''}`.trim()}
        placement={Placement.Bottom}
      >
        <Avatar firstName={firstName || ''} lastName={lastName || ''} />
      </OverlayTooltip>
    </p>
  );
};

export default WebhookCreatedByField;
