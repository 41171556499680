import { createSlice } from '@reduxjs/toolkit';
import {
  getAgencyClientsRequest,
  createAgencyClientRequest,
  updateAgencyClientRequest,
  deleteAgencyClientRequest,
  getAgencyClientsDropdown,
  getSelectedAgencyClient,
  updateAssignAgencyClientResource,
  toggleAgencyClientStatusRequest,
  getAgencyClientActiveSequencesRequest,
  agencyClientResetPasswordRequest,
} from './extra-actions';
import { RequestStatus } from '../../shared/enums/request-status';
import {
  AgencyClient,
  AgencyClientFilter,
  AgencyClientPaginationOptions,
  AgencyClientSequence,
} from './types/types';
import { RequestState } from '../../shared/types/request-state';

interface State {
  getAgencyClientsRequest: RequestState;
  createAgencyClientRequest: RequestState;
  updateAgencyClientRequest: RequestState;
  deleteAgencyClientRequest: RequestState;
  toggleAgencyClientStatusRequest: RequestState;
  getAgencyClientActiveSequencesRequest: RequestState;
  agencyClientResetPasswordRequest: RequestState;

  getAgencyClientsDropdownRequest: RequestState;
  selectedAgencyClientRequest: RequestState;
  updateAgencyClientResource: RequestState;
  clients: AgencyClientFilter[];
  selectedAgencyClient: AgencyClientFilter;
  isLoading: boolean;
  agencyClients: AgencyClient[];
  agencyClientsPaginationOptions: AgencyClientPaginationOptions;
  agencyClientsSequences: AgencyClientSequence[];
  search: string;
  agencyClient: AgencyClient;
}

const initialState: State = {
  getAgencyClientsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createAgencyClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateAgencyClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteAgencyClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  toggleAgencyClientStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAgencyClientActiveSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  agencyClientResetPasswordRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  search: '',
  agencyClients: [],
  agencyClientsPaginationOptions: null,
  agencyClientsSequences: [],
  agencyClient: null,
  getAgencyClientsDropdownRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateAgencyClientResource: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  selectedAgencyClientRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  selectedAgencyClient: null,
  clients: [],
  isLoading: false,
};

const agencyClientManagementSlice = createSlice({
  name: 'agencyClient',
  initialState,
  reducers: {
    resetCreateAgencyClientRequest: (state) => {
      state.createAgencyClientRequest = initialState.createAgencyClientRequest;
    },
    resetUpdateAgencyClientRequest: (state) => {
      state.updateAgencyClientRequest = initialState.updateAgencyClientRequest;
    },
    resetDeleteAgencyClientRequest: (state) => {
      state.deleteAgencyClientRequest = initialState.deleteAgencyClientRequest;
    },
    resetGetAgencyClientActiveSequencesRequest: (state) => {
      state.getAgencyClientActiveSequencesRequest =
        initialState.getAgencyClientActiveSequencesRequest;
    },
    resetAgencyClientsSequences: (state) => {
      state.agencyClientsSequences = initialState.agencyClientsSequences;
    },
    resetAgencyClientResetPasswordRequest: (state) => {
      state.agencyClientResetPasswordRequest =
        initialState.agencyClientResetPasswordRequest;
    },
    resetAgencyClientAssignResource: (state) => {
      state.updateAgencyClientResource =
        initialState.updateAgencyClientResource;
    },
    resetToggleAgencyClientStatusRequest: (state) => {
      state.toggleAgencyClientStatusRequest =
        initialState.toggleAgencyClientStatusRequest;
    },
    resetAgencyClientManagementRequest: (state) => {
      state.getAgencyClientsDropdownRequest.status = RequestStatus.Ideal;
      state.getAgencyClientsDropdownRequest.error = null;
      state.isLoading = false;
    },
    saveSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Agency Clients List
    builder.addCase(getAgencyClientsRequest.pending, (state) => {
      state.getAgencyClientsRequest.status = RequestStatus.Pending;
      state.getAgencyClientsRequest.error = null;
    });
    builder.addCase(getAgencyClientsRequest.fulfilled, (state, action) => {
      state.getAgencyClientsRequest.status = RequestStatus.Succeeded;
      state.getAgencyClientsRequest.message = action.payload.message;
      state.agencyClients = action.payload.payload.items;
      state.agencyClientsPaginationOptions = action.payload.payload.meta;
    });
    builder.addCase(getAgencyClientsRequest.rejected, (state, action) => {
      state.getAgencyClientsRequest.status = RequestStatus.Failed;
      state.getAgencyClientsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Create Agency Client
    builder.addCase(createAgencyClientRequest.pending, (state) => {
      state.createAgencyClientRequest.status = RequestStatus.Pending;
      state.createAgencyClientRequest.error = null;
    });
    builder.addCase(createAgencyClientRequest.fulfilled, (state, action) => {
      state.createAgencyClientRequest.status = RequestStatus.Succeeded;
      state.createAgencyClientRequest.message = action.payload.message;
    });
    builder.addCase(createAgencyClientRequest.rejected, (state, action) => {
      state.createAgencyClientRequest.status = RequestStatus.Failed;
      state.createAgencyClientRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Agency Client
    builder.addCase(updateAgencyClientRequest.pending, (state) => {
      state.updateAgencyClientRequest.status = RequestStatus.Pending;
      state.updateAgencyClientRequest.error = null;
    });
    builder.addCase(updateAgencyClientRequest.fulfilled, (state, action) => {
      state.updateAgencyClientRequest.status = RequestStatus.Succeeded;
      state.updateAgencyClientRequest.message = action.payload.message;
    });
    builder.addCase(updateAgencyClientRequest.rejected, (state, action) => {
      state.updateAgencyClientRequest.status = RequestStatus.Failed;
      state.updateAgencyClientRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Agency Client
    builder.addCase(deleteAgencyClientRequest.pending, (state) => {
      state.deleteAgencyClientRequest.status = RequestStatus.Pending;
      state.deleteAgencyClientRequest.error = null;
    });
    builder.addCase(deleteAgencyClientRequest.fulfilled, (state, action) => {
      state.deleteAgencyClientRequest.status = RequestStatus.Succeeded;
      state.deleteAgencyClientRequest.message = action.payload.message;
    });
    builder.addCase(deleteAgencyClientRequest.rejected, (state, action) => {
      state.deleteAgencyClientRequest.status = RequestStatus.Failed;
      state.deleteAgencyClientRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Toggle Agency Client Status
    builder.addCase(toggleAgencyClientStatusRequest.pending, (state) => {
      state.toggleAgencyClientStatusRequest.status = RequestStatus.Pending;
      state.toggleAgencyClientStatusRequest.error = null;
    });
    builder.addCase(
      toggleAgencyClientStatusRequest.fulfilled,
      (state, action) => {
        state.toggleAgencyClientStatusRequest.status = RequestStatus.Succeeded;
        state.toggleAgencyClientStatusRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      toggleAgencyClientStatusRequest.rejected,
      (state, action) => {
        state.toggleAgencyClientStatusRequest.status = RequestStatus.Failed;
        state.toggleAgencyClientStatusRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Agency Client
    builder.addCase(getAgencyClientActiveSequencesRequest.pending, (state) => {
      state.getAgencyClientActiveSequencesRequest.status =
        RequestStatus.Pending;
      state.getAgencyClientActiveSequencesRequest.error = null;
    });
    builder.addCase(
      getAgencyClientActiveSequencesRequest.fulfilled,
      (state, action) => {
        state.getAgencyClientActiveSequencesRequest.status =
          RequestStatus.Succeeded;
        state.getAgencyClientActiveSequencesRequest.message =
          action.payload.message;
        state.agencyClientsSequences = action.payload.payload.clientSequences;
      },
    );
    builder.addCase(
      getAgencyClientActiveSequencesRequest.rejected,
      (state, action) => {
        state.getAgencyClientActiveSequencesRequest.status =
          RequestStatus.Failed;
        state.getAgencyClientActiveSequencesRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get agency clients dropdown
    builder.addCase(getAgencyClientsDropdown.pending, (state) => {
      state.getAgencyClientsDropdownRequest.status = RequestStatus.Pending;
      state.getAgencyClientsDropdownRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getAgencyClientsDropdown.fulfilled, (state, action) => {
      state.getAgencyClientsDropdownRequest.status = RequestStatus.Succeeded;
      state.getAgencyClientsDropdownRequest.message = action.payload.message;
      state.clients = action.payload.payload.clients?.map((client) => ({
        ...client,
        key: client.id,
      }));
      state.isLoading = false;
    });
    builder.addCase(getAgencyClientsDropdown.rejected, (state, action) => {
      state.getAgencyClientsDropdownRequest.status = RequestStatus.Failed;
      state.getAgencyClientsDropdownRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get selected agency client
    builder.addCase(getSelectedAgencyClient.pending, (state) => {
      state.selectedAgencyClientRequest.status = RequestStatus.Pending;
      state.selectedAgencyClientRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getSelectedAgencyClient.fulfilled, (state, action) => {
      state.selectedAgencyClientRequest.status = RequestStatus.Succeeded;
      state.selectedAgencyClientRequest.message = action.payload.message;
      let { connectedClient } = action.payload.payload;
      connectedClient = connectedClient ?? {};
      state.selectedAgencyClient = {
        ...connectedClient,
        ...(connectedClient.id && { key: connectedClient.id }),
      };
      state.isLoading = false;
    });
    builder.addCase(getSelectedAgencyClient.rejected, (state, action) => {
      state.selectedAgencyClientRequest.status = RequestStatus.Failed;
      state.selectedAgencyClientRequest.error = action.payload;
      state.isLoading = false;
    });

    // Update/Assign selected agency client
    builder.addCase(updateAssignAgencyClientResource.pending, (state) => {
      state.updateAgencyClientResource.status = RequestStatus.Pending;
      state.updateAgencyClientResource.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      updateAssignAgencyClientResource.fulfilled,
      (state, action) => {
        state.updateAgencyClientResource.status = RequestStatus.Succeeded;
        state.updateAgencyClientResource.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      updateAssignAgencyClientResource.rejected,
      (state, action) => {
        state.updateAgencyClientResource.status = RequestStatus.Failed;
        state.updateAgencyClientResource.error = action.payload;
        state.isLoading = false;
      },
    );

    // Send Reset Agency Client Password
    builder.addCase(agencyClientResetPasswordRequest.pending, (state) => {
      state.agencyClientResetPasswordRequest.status = RequestStatus.Pending;
      state.agencyClientResetPasswordRequest.error = null;
    });
    builder.addCase(
      agencyClientResetPasswordRequest.fulfilled,
      (state, action) => {
        state.agencyClientResetPasswordRequest.status = RequestStatus.Succeeded;
        state.agencyClientResetPasswordRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      agencyClientResetPasswordRequest.rejected,
      (state, action) => {
        state.agencyClientResetPasswordRequest.status = RequestStatus.Failed;
        state.agencyClientResetPasswordRequest.error = action.payload;
      },
    );
  },
});

export { getAgencyClientsDropdown };

export const {
  resetCreateAgencyClientRequest,
  resetUpdateAgencyClientRequest,
  resetDeleteAgencyClientRequest,
  resetGetAgencyClientActiveSequencesRequest,
  resetAgencyClientsSequences,
  resetAgencyClientAssignResource,
  resetAgencyClientManagementRequest,
  resetAgencyClientResetPasswordRequest,
  resetToggleAgencyClientStatusRequest,
  saveSearch,
} = agencyClientManagementSlice.actions;
export default agencyClientManagementSlice.reducer;
