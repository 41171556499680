import React from 'react';

export const EllipseRedDot = ({ width = '8', height = '8' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="4.00012" cy="3.99243" r="4" fill="#DC2626" />
  </svg>
);
