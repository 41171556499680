import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { MailboxEmailsProps } from './type';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';

const MailboxEmailsList = React.lazy(() =>
  retryLazy(() => retryLazy(() => import('./components/mailbox-emails-list'))),
);

const MailboxEmails: React.FC<MailboxEmailsProps> = () => (
  <Container fluid className="mailbox-emails p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/email-insights" component={MailboxEmailsList} />
          <Redirect to="/email-insights" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default MailboxEmails;
