import React from 'react';

export const SwapArrow = () => (
  <svg
    width="74"
    height="42"
    viewBox="0 0 74 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 20.5L73 20.5" stroke="#E5E7EB" strokeLinecap="round" />
    <rect x="16.5" y="0.5" width="41" height="41" rx="20.5" fill="white" />
    <path
      d="M33.25 28.5L29.5 24.75M29.5 24.75L33.25 21M29.5 24.75H40.75M40.75 13.5L44.5 17.25M44.5 17.25L40.75 21M44.5 17.25H33.25"
      stroke="#1F2937"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="16.5" y="0.5" width="41" height="41" rx="20.5" stroke="#E5E7EB" />
  </svg>
);
