import React from 'react';
import { AlertCircle, CircleCheck } from '@saleshandy/icons';
import { ImportDoNotContactListEmailsAndDomainsResponse } from '../../../../../../types/do-not-contact-list';

type IProps = {
  importDoNotContactListEmailsAndDomainsResponse: ImportDoNotContactListEmailsAndDomainsResponse;
};

const ImportReport: React.FC<IProps> = ({
  importDoNotContactListEmailsAndDomainsResponse,
}) => {
  const sections = [
    {
      topic: 'Imported Successfully',
      value: importDoNotContactListEmailsAndDomainsResponse?.success.count,
      icon: <CircleCheck className="lime-txt-7" />,
      link: null,
    },
    {
      topic: 'Skipped',
      value: importDoNotContactListEmailsAndDomainsResponse?.skipped.count,
      icon: <AlertCircle className="gold-txt-6" />,
      link:
        importDoNotContactListEmailsAndDomainsResponse?.skipped
          .reportFilePath || null,
    },
  ];

  return (
    <div className="import-report-wrapper d-flex justify-content-center align-items-center">
      <div className="import-report-content">
        <h3>Import Emails or Domains Report</h3>

        <div className="import-report-content-container">
          {sections.map(({ topic, value, icon, link }) => (
            <div className="content-inner d-flex justify-content-between">
              <p>{topic}</p>
              <div className="content-inner-right">
                <div className="count-icon-wrapper d-flex justify-content-end align-items-center">
                  <span className="count font-medium gray-txt-9">{value}</span>
                  <span className="icon">{icon}</span>
                </div>

                {link && value > 0 && (
                  <a
                    href={link}
                    download
                    className="import-report-link blue-txt-11"
                  >
                    Download Error Report
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImportReport;
