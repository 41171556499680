import React from 'react';
import { Button } from '@saleshandy/design-system';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

type IProps = {
  subscriptionPlan: SubscriptionPlans;
  onClick: VoidFunction;
};

const CustomDomainHeader: React.FC<IProps> = ({
  subscriptionPlan,
  onClick,
}) => (
  <GlobalSettings.Header>
    <GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderTitle title="Custom Tracking Domain" />
    </GlobalSettings.HeaderLeft>
    <GlobalSettings.HeaderRight>
      <div className="custom-domain-header">
        <div className="custom-domain-add-button">
          <Button
            onClick={onClick}
            disabled={subscriptionPlan === SubscriptionPlans.FREE}
          >
            Add Custom Domain
          </Button>
        </div>
      </div>
    </GlobalSettings.HeaderRight>
  </GlobalSettings.Header>
);

export default CustomDomainHeader;
