import React from 'react';

export const NogenderAvatar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
      fill="#EFF6FF"
    />
    <mask
      id="mask0_2347_7851"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="50"
      height="50"
    >
      <path
        d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_2347_7851)">
      <path
        d="M42 45.4504C42 51.5165 8 51.5165 8 45.4504C8 39.3842 21.4293 37 24.9858 37C28.5707 37 42 39.3842 42 45.4504Z"
        fill="#BFDBFE"
      />
      <path
        d="M24.8924 33.7866C20.5005 33.7866 16.9531 30.5186 16.9531 26.4725V19.3141C16.9531 15.268 20.5005 12 24.8924 12C29.2843 12 32.8317 15.268 32.8317 19.3141V26.4725C32.8317 30.5186 29.2562 33.7866 24.8924 33.7866Z"
        fill="#BFDBFE"
      />
    </g>
  </svg>
);
