export enum ReportsTabs {
  Sequence = 'sequence',
  Team = 'team',
}

export enum ReportsBy {
  ByProspects = 'prospect',
  ByEmail = 'email',
}

export enum SequenceReportSortBy {
  SequenceCreatedAt = 'createdAt',
  TotalProspects = 'totalProspects',
  ProspectsAdded = 'prospectAdded',
  ProspectContacted = 'prospectContacted',
  EmailsSent = 'emailSent',
  Opened = 'opened',
  Clicked = 'clicked',
  Replied = 'replied',
  Bounced = 'bounced',
  Unsubscribed = 'unSubscribed',
  Owner = 'userName',
  Uncategorized = 'Uncategorized',
  SequenceId = 'sequenceId',
  PositiveSentiment = 'positive',
  NegativeSentiment = 'negative',
  NeutralSentiment = 'neutral',
  Interested = 'Interested',
  NotInterested = 'Not Interested',
  MeetingBooked = 'Meeting Booked',
  OutOfOffice = 'Out of Office',
  Closed = 'Closed',
  NotNow = 'Not Now',
  DoNotContact = 'Do Not Contact',
}

export enum TeamStatsReportSortBy {
  ProspectsAdded = 'prospectAdded',
  ProspectsContacted = 'prospectContacted',
  EmailSent = 'emailSent',
  Opened = 'opened',
  Clicked = 'clicked',
  Replied = 'replied',
  Unsubscribed = 'unSubscribed',
  Bounced = 'bounced',
  Uncategorized = 'Uncategorized',
  Interested = 'Interested',
  NotInterested = 'Not Interested',
  MeetingBooked = 'Meeting Booked',
  OutOfOffice = 'Out of Office',
  Closed = 'Closed',
  NotNow = 'Not Now',
  DoNotContact = 'Do Not Contact',
}

export enum TableColumnsKey {
  SequenceProspectCols = 'sequence-prospect-cols',
  TeamProspectCols = 'team-prospect-cols',
}

export enum ReportCountBy {
  Relative = 'relative',
  Absolute = 'absolute',
}

export enum Granularity {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Quarterly = 'quarterly',
}

export type SequenceSummarySentimentDropdown = {
  name: string;
  value: number;
  trend: number;
  percentage: number;
  key: string;
}