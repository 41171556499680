import React from 'react';
import { ArrowUpRight } from '@saleshandy/icons';
import { WebhookTriggerEvent } from '../../../../../enums/webhook';
import Checkbox from '../../../../../../../shared/design-system/components/atoms/checkbox';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { supportUrls } from '../../../../../../../shared/utils/urls';

const events = [
  { label: 'When an email is sent', trigger: WebhookTriggerEvent.EmailSent },
  {
    label: 'When an email is opened',
    trigger: WebhookTriggerEvent.EmailOpened,
  },
  {
    label: 'When an email link is clicked',
    trigger: WebhookTriggerEvent.EmailLinkClicked,
  },
  {
    label: 'When a reply is received',
    trigger: WebhookTriggerEvent.ReplyReceived,
  },
  {
    label: 'When an email is bounced',
    trigger: WebhookTriggerEvent.EmailBounced,
  },
  {
    label: 'When a prospects is unsubscribed',
    trigger: WebhookTriggerEvent.ProspectUnsubscribed,
  },
  {
    label: 'When a prospect is finished',
    trigger: WebhookTriggerEvent.ProspectFinished,
  },
  {
    label: 'When a prospect outcome is changed',
    trigger: WebhookTriggerEvent.ProspectOutcomeUpdated,
  },
];

const EventToSendInput = ({ eventsToSend, onEventToSendChange }) => {
  const openExampleDataLink = () => {
    window.open(supportUrls.webhookExampleDataURL, '_blank');
  };

  return (
    <div className="event-to-send pdr-30">
      <div className="webhook-form-field">
        <div className="d-flex justify-content-between align-items-center">
          <div className="webhook-form-field-label justify-content-between">
            <p>Event to Send</p>
          </div>
          <div
            className="example-data-btn"
            {...accessibleOnClick(openExampleDataLink)}
          >
            <span>Example Data</span>
            <ArrowUpRight />
          </div>
        </div>
        <div className="events-container">
          {events.map((event) => (
            <div className="event">
              <Checkbox
                checked={eventsToSend.includes(event.trigger)}
                onChange={(checked) =>
                  onEventToSendChange(checked, event.trigger)
                }
                label={event.label}
              />
            </div>
          ))}
        </div>
        <p className="webhook-form-field-helper-text">
          Select the events that should trigger your webhook
        </p>
      </div>
    </div>
  );
};

export default EventToSendInput;
