import React, { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';
import { InfoCircle, Minus } from '@saleshandy/icons';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import type { IProps } from './types';
import StepContentArea from '../../../../../sequence/shared/modals/create-step-modal/components/step-content-area';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

const TaskNoteModal: React.FC<IProps> = ({
  taskId,
  show,
  onClose,
  onSubmit,
  taskNote,
  isLoading,
}) => {
  const [text, setText] = useState<string>(taskNote);

  const debouncedSave = useCallback(
    debounce(
      (t: string) =>
        t.length &&
        onSubmit({
          taskId,
          taskNote: t,
        }),
      1500,
    ),
    [],
  );

  const onSave = (t: string) => {
    if (t.length > 3000) return;

    setText(t);
    debouncedSave(t);
  };

  return (
    <Modal
      show={show}
      titleContent={
        <div className="d-flex justify-content-between">
          <div className="pl-4 pt-4 pb-4">
            <span className="semibold-3 gray-txt-10">Task Note</span>
            <OverlayTooltip
              text="Add instructions/tips for task assignee to execute this task successfully."
              className="tooltip-mdd"
            >
              <InfoCircle width={16} height={16} className="gray-txt-11 ml-1" />
            </OverlayTooltip>
          </div>
          <div className="pr-4 bs-mt-20">
            <Minus
              width={24}
              height={24}
              className="popover-arrow-color-txt pointer"
              onClick={onClose}
            />
          </div>
        </div>
      }
      backdrop="static"
      className="cs-task-note task-note-modal"
      onClose={onClose}
      onHide={onClose}
      isCustomCloseIcon={false}
      extraModalProps={{
        centered: true,
        enforceFocus: false,
        'aria-labelledby': 'contained-modal-title-vcenter',
      }}
      hideFooter
    >
      <StepContentArea
        stepContentHTML={text}
        maxLength={3000}
        onChange={(value) => onSave(value)}
        onHide={onClose}
        placeholder="Add Task Note"
        isLoading={isLoading}
        isAutoSaved
      />
    </Modal>
  );
};

export default TaskNoteModal;
