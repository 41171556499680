import React from 'react';

export const ArrowsMaximize = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5 13.5109C17.5 13.2437 17.1769 13.1098 16.9879 13.2988L15.9446 14.342C15.8275 14.4592 15.6375 14.4592 15.5204 14.342L13.3013 12.123C13.1841 12.0058 12.9942 12.0058 12.877 12.123L12.123 12.877C12.0058 12.9942 12.0058 13.1841 12.123 13.3013L14.342 15.5204C14.4592 15.6375 14.4592 15.8275 14.342 15.9446L13.2988 16.9879C13.1098 17.1769 13.2437 17.5 13.5109 17.5H17.2C17.3657 17.5 17.5 17.3657 17.5 17.2V13.5109ZM2.5 6.48907C2.5 6.75634 2.82314 6.89019 3.01213 6.7012L4.05537 5.65796C4.17253 5.54081 4.36247 5.54081 4.47963 5.65797L6.6987 7.87703C6.81586 7.99419 7.00581 7.99419 7.12297 7.87703L7.87703 7.12297C7.99419 7.00581 7.99419 6.81586 7.87703 6.6987L5.65797 4.47963C5.54081 4.36247 5.54081 4.17253 5.65797 4.05537L6.7012 3.01213C6.89019 2.82314 6.75634 2.5 6.48907 2.5H2.8C2.63431 2.5 2.5 2.63431 2.5 2.8V6.48907ZM17.5 2.8C17.5 2.63431 17.3657 2.5 17.2 2.5H13.5109C13.2437 2.5 13.1098 2.82314 13.2988 3.01213L14.342 4.05537C14.4592 4.17253 14.4592 4.36247 14.342 4.47963L12.123 6.6987C12.0058 6.81586 12.0058 7.00581 12.123 7.12297L12.877 7.87703C12.9942 7.99419 13.1841 7.99419 13.3013 7.87703L15.5204 5.65797C15.6375 5.54081 15.8275 5.54081 15.9446 5.65797L16.9879 6.7012C17.1769 6.89019 17.5 6.75634 17.5 6.48907V2.8ZM2.5 17.2C2.5 17.3657 2.63431 17.5 2.8 17.5H6.48907C6.75634 17.5 6.89019 17.1769 6.7012 16.9879L5.65796 15.9446C5.54081 15.8275 5.54081 15.6375 5.65797 15.5204L7.87703 13.3013C7.99419 13.1841 7.99419 12.9942 7.87703 12.877L7.12297 12.123C7.00581 12.0058 6.81586 12.0058 6.6987 12.123L4.47963 14.342C4.36247 14.4592 4.17253 14.4592 4.05537 14.342L3.01213 13.2988C2.82314 13.1098 2.5 13.2437 2.5 13.5109V17.2Z"
      fill="#6B7280"
    />
  </svg>
);
