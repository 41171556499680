export const constants = {
  DEFAULT_SEQUENCE_PAGE_SIZE: 15,
  DEFAULT_PAGE_SIZE: 25,
  SMALLER_PAGE_SIZE: 10,
  DEFAULT_PAGE_NUM: 0,
  PROSPECT_PAGE_SIZE: 25,
  DOWNLOAD_SAMPLE_FILE_URL:
    'https://v3artifacts.s3-us-west-2.amazonaws.com/downloads/sh-sample-csv.csv',
  MODAL_HANDLER: 'modal-handler',
  EMAIL_ACCOUNT_SETUP_SCORE_LIMIT: 100,
  SEQUENCE_STEP_MESSAGE_LIMIT: 3,
  SELECTED_SEQUENCE_LIMIT: 15,
  BULK_EDIT_EMAIL_ACCOUNT_LIMIT: 25000,
  BULK_DELETE_DNC_LIST_EMAILS_DOMAINS: 25000,
  DOWNLOAD_DNC_SAMPLE_FILE_URL:
    'https://v3artifacts.s3.us-west-2.amazonaws.com/Sample+CSV+for+DNC.csv',
  DOWNLOAD_SAMPLE_WHITELABEL_FILE_URL:
    'https://v3artifacts.s3.us-west-2.amazonaws.com/Sample+CSV+for+Client.csv',
};
