import React, { useCallback, useState } from 'react';
import classNames from 'classnames';

import { FileData } from '../../types';

import PreSelect from './components/pre-select';
import PostSelect from './components/post-select';
import toaster from '../../../../../../../../../shared/toaster';

type IProps = {
  handleCSVImport: (fileData: FileData) => void;
};

const FileSelectorInput: React.FC<IProps> = ({ handleCSVImport }) => {
  const [fileData, setFileData] = useState<FileData>(null);

  const onRemove = () => {
    setFileData(null);
    handleCSVImport(null);
  };

  const onSelect = useCallback(
    (file, headers) => {
      const payload = {
        file,
        headers,
      };

      handleCSVImport(payload);
      setFileData(payload);

      toaster.success(`${file.name} file selected successfully`);
    },
    [setFileData],
  );

  const clsNames = classNames([
    'file-selector-input--container',
    {
      'pre-select-stage-container': !fileData,
      'post-select-stage-container': fileData,
    },
  ]);

  return (
    <>
      <div className={clsNames}>
        {!fileData ? (
          <div className="file-selector-input--stage">
            <PreSelect onSelect={onSelect} />
          </div>
        ) : (
          <div className="file-selector-input--stage">
            <PostSelect fileName={fileData.file.name} onRemove={onRemove} />
          </div>
        )}
      </div>
    </>
  );
};

export default FileSelectorInput;
