import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Bolt } from '@saleshandy/icons';

const ChangeLogTooltip = (popoverText) => (
  <Tooltip id="popover-basic" className="bs-tooltip-inner">
    {popoverText}
  </Tooltip>
);

const ChangeLog = () => {
  const { t } = useTranslation();
  return (
    <OverlayTrigger
      overlay={ChangeLogTooltip(t('labels.changeLog'))}
      placement="bottom"
    >
      <div style={{ cursor: 'pointer' }} className="d-flex hover-on-header">
        <div id="headway-badge" />
        <Bolt height={20} width={20} className="d-flex bolt" />
      </div>
    </OverlayTrigger>
  );
};

export default ChangeLog;
