import React, { useState, useEffect, useRef } from 'react';
import { Button } from '@saleshandy/design-system';

import type { VerifyOTPProps } from '../types';
import { ChangeEmailModalFlow } from '../enums';

import Title from './sub-components/title';
import Description from './sub-components/description';
import Action from './sub-components/action';
import Footer from './sub-components/footer';
import Divider from './sub-components/divider';
import { getIsRequestPending } from '../../../../../../../shared/utils';
import ResendOTPButton from './sub-components/resend-otp-button';

const VerifyOTP: React.FC<VerifyOTPProps> = ({
  email,
  newEmail,
  onCancel,
  onSubmit,
  onModalStateChange,
  handleResendOtp,
  resendOtpForChangeEmailRequestStatus,
  verifyOtpForChangeEmailRequestStatus,
  canResendChangeEmailVerificationCode,
  error,
  resetVerifyOtpForChangeEmailRequest,
}) => {
  const [otp, setOTP] = useState<string[]>(['', '', '', '']);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([
    null,
    null,
    null,
    null,
  ]);

  const setRef = (input: HTMLInputElement | null, index: number) => {
    inputRefs.current[index] = input;
  };

  const handleInputChange = (value: string, index: number) => {
    const newOTP = [...otp];

    if (
      value === '' || // Empty Value
      /^[0-9]*$/.test(value) // Only allow numeric characters (0-9)
    ) {
      newOTP[index] = value;
      setOTP(newOTP);

      // Auto-focus the next input when a value is entered
      if (value && index < 3 && inputRefs.current[index + 1]) {
        inputRefs.current[index + 1].focus();
      } else if (!value && index > 0 && inputRefs.current[index - 1]) {
        // Auto-focus the previous input when a value is removed
        inputRefs.current[index - 1].focus();
      }
    }

    if (error) {
      resetVerifyOtpForChangeEmailRequest();
    }
  };

  const handleOnModalStateChange = () => {
    onModalStateChange(ChangeEmailModalFlow.NewEmail);
  };

  const handleOnSubmit = () => {
    onSubmit(Number(otp.join('')));
  };

  useEffect(() => {
    // Auto-focus the first input on mount
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }

    const handleKeyDown = (event: KeyboardEvent) => {
      const currentIndex = inputRefs.current.findIndex(
        (input) => input === document.activeElement,
      );

      if (event.key === 'ArrowLeft' && currentIndex > 0) {
        inputRefs.current[currentIndex - 1]?.focus();
      } else if (event.key === 'ArrowRight' && currentIndex < 3) {
        inputRefs.current[currentIndex + 1]?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      setOTP(['', '', '', '']);
    };
  }, []);

  const isResendOtpForChangeEmailRequestPending = getIsRequestPending(
    resendOtpForChangeEmailRequestStatus,
  );

  const isVerifyOtpForChangeEmailRequestPending = getIsRequestPending(
    verifyOtpForChangeEmailRequestStatus,
  );

  const isDisabled =
    isResendOtpForChangeEmailRequestPending ||
    isVerifyOtpForChangeEmailRequestPending;

  const errorMessage =
    error && error?.code === 1001 && otp.join('').length === 4
      ? error.message
      : '';

  return (
    <>
      <Title>
        <p>
          Your current email is <span className="bold">{email}</span>
        </p>
      </Title>

      <Description
        content={
          <>
            <span>
              To continue, please enter the 4-digit verification code sent to
            </span>{' '}
            <span className="bold">{newEmail}</span>{' '}
            <Button
              className="p-0 link-cta-btn link-inline"
              variant="link"
              onClick={handleOnModalStateChange}
              disabled={isDisabled}
            >
              Change
            </Button>
          </>
        }
      />

      <Description
        content={
          <div className="d-flex align-items-center">
            Didn’t receive a code?{' '}
            <ResendOTPButton
              isButtonDisabled={
                isDisabled || !canResendChangeEmailVerificationCode
              }
              isButtonLoading={isResendOtpForChangeEmailRequestPending}
              handleResendOtp={handleResendOtp}
              showError={!canResendChangeEmailVerificationCode}
            />
          </div>
        }
      />

      <Action>
        <div className="otp-input">
          <p className="otp-input-label">
            Please enter the code you received below
          </p>
          <div className={`otp-input-container ${errorMessage ? 'error' : ''}`}>
            {otp.map((value, index) => (
              <input
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                id={`otp-input-${index}`}
                type="text"
                maxLength={1}
                value={value}
                ref={(input) => setRef(input, index)}
                onChange={(e) => handleInputChange(e.target.value, index)}
              />
            ))}
          </div>
          {errorMessage && <p className="otp-input-error">{errorMessage}</p>}
        </div>
      </Action>

      <Divider />

      <Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
          className="secondary-cta-btn"
          disabled={isDisabled}
        >
          Cancel
        </Button>
        <Button
          loadingText="Verifying Code..."
          isLoading={isVerifyOtpForChangeEmailRequestPending}
          disabled={!otp || isDisabled || otp.join('').length !== 4}
          onClick={handleOnSubmit}
        >
          Change email
        </Button>
      </Footer>
    </>
  );
};

export default VerifyOTP;
