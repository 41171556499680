export type IProps = any;

export type LeadsFindRequestPayload = {
  start: number;
  take: number;
  name?: string;
  keyword?: string;
};

export const FilterComponentType = {
  SEARCH_INPUT: 'searchInput',
  DROP_DOWN: 'dropDown',
  MULTILEVEL_CHECKBOX: 'checkbox',
};

export const LeadFinderTabKey = {
  PEOPLE_TAB: 'people',
  SAVED_TAB: 'saved',
};

export const LeadRevealType = {
  EMAIL: 'email',
  EMAIL_PHONE: 'emailphone',
};

export const LeadRevealLimit = {
  VALID: 'valid',
  CREDIT_LIMIT_REACHED: 'credit-limit-reached',
  MORE_SELECTED: 'more-selected',
};

export const BulkLeadsActionType = {
  REVEAL: 'reveal',
  ADD_TO_PROSPECT: 'add_to_prospect',
  ADD_TO_SEQUENCE: 'add_tp_sequence',
  EXPORT: 'export',
};

export const BULK_ACTION_TIMEOUT = 15000;

export const RR_PAGINATION_LIMIT = 10000;
