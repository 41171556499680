import validator from 'validator';

export const validate = (
  name: string,
  value: string,
  hasPermissionToUpdateProfile: boolean,
) => {
  if (hasPermissionToUpdateProfile) {
    if (name === 'firstName') {
      if (!validator.isLength(value.trim(), { min: 1 })) {
        return 'Enter at least one character';
      }
      if (!validator.isLength(value, { max: 50 })) {
        return 'First name should be at most 50 characters long';
      }
    }
    if (name === 'lastName') {
      if (!validator.isLength(value.trim(), { min: 1 })) {
        return 'Enter at least one character';
      }
      if (!validator.isLength(value, { max: 50 })) {
        return 'Last name should be at most 50 characters long';
      }
    }
  }

  return '';
};
