import React from 'react';
import { InfoCircle } from '@saleshandy/icons';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';

const DNCListHelperText: Record<string, React.ReactNode> = {
  AgencyOwnerGlobalList: (
    <>
      All prospects matching domains/emails from this list will be blacklisted
      in <span className="dnc-sequence">all sequences</span> (including client’s
      sequences) and will not be contacted further.
    </>
  ),
  AgencyOwnerClientSpecificList: (
    <>
      All prospects matching domains/emails from this list will be blacklisted
      in <span className="dnc-sequence">all sequences of this client</span> and
      will not be contacted further.
    </>
  ),
  NonAgencyUserList: (
    <>
      <InfoCircle /> This DNC List will be applied to all the sequences
    </>
  ),
  AgencyClientOrNonAgencyUser: (
    <>
      All prospects matching domains/emails from this list will be blacklisted
      in <span className="dnc-sequence">all sequences</span> and will not be
      contacted further.
    </>
  ),
};

type GetListHelperTextParams = {
  isAgencyUser: boolean;
  isGlobalList: boolean;
  messageSpecificFor: 'list' | 'user';
};

export const getListHelperText = ({
  isAgencyUser,
  isGlobalList,
  messageSpecificFor,
}: GetListHelperTextParams): React.ReactNode => {
  // Case: Add/Modify List Modal
  if (messageSpecificFor === 'list') {
    if (isAgencyUser) {
      if (isGlobalList) {
        return DNCListHelperText.AgencyOwnerGlobalList;
      }
      return DNCListHelperText.AgencyOwnerClientSpecificList;
    }
    return DNCListHelperText.NonAgencyUserList;
  }

  // Case: Add Emails/Domains Input or Import via CSV
  if (messageSpecificFor === 'user') {
    if (isAgencyUser && hasPermission(Permissions.DNC_LIST_WRITE)) {
      if (isGlobalList) {
        return DNCListHelperText.AgencyOwnerGlobalList;
      }
      return DNCListHelperText.AgencyOwnerClientSpecificList;
    }
    return DNCListHelperText.AgencyClientOrNonAgencyUser;
  }

  return '';
};
