import React from 'react';
import { Images } from '../../../../../../shared/app-constants';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../shared/design-system/components/organisms/table/types';
import customDomainColumnData from './helpers/custom-domain-data';

type IProps = {
  customDomains: any[];
  isRequestPending: boolean;
  editClickHandler: (row) => void;
  deleteClickHandler: (row) => void;
  defaultClickHandler: (row) => void;
};
export enum CustomDomainRowActions {
  Edit = 'Edit',
  Delete = 'Delete',
  Default = 'Default',
}

const CustomDomainList: React.FC<IProps> = ({
  customDomains,
  editClickHandler,
  deleteClickHandler,
  isRequestPending,
  defaultClickHandler,
}) => {
  // static.
  const actions = [
    {
      key: CustomDomainRowActions.Edit,
      displayName: 'Edit',
      imageIcon: Images.Pencil,
    },
    {
      key: CustomDomainRowActions.Delete,
      displayName: 'Delete',
      imageIcon: Images.TrashLight,
    },
    {
      key: CustomDomainRowActions.Default,
      displayName: 'Set as Default',
      imageIcon: Images.FileCheck,
      conditionKey: 'isDefault',
      condition: false,
    },
  ];

  // handlers.

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onAction = (actionKey, row) => {
    switch (actionKey) {
      case CustomDomainRowActions.Edit:
        editClickHandler(row);
        break;

      case CustomDomainRowActions.Delete:
        deleteClickHandler(row);
        break;

      case CustomDomainRowActions.Default:
        defaultClickHandler(row.customDomain);
        break;

      default:
        break;
    }
  };
  const table = (
    <Table
      columns={customDomainColumnData}
      data={customDomains}
      onPaginationOptionsChange={null}
      actions={actions}
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onAction={(actionKey, row) => onAction(actionKey, row)}
      paginationOptions={{
        options: {
          limit: 1000,
          page: 0,
          totalElements: 1000,
        },
      }}
      headerVisibleForGenerateColumn={true}
      borderOverActions={false}
      pagination={PaginationShowHide.HIDE}
      isLoading={isRequestPending}
    />
  );
  // render.
  return table;
};

export default CustomDomainList;
