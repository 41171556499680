import moment from 'moment-timezone';
import { WebhookActivityType } from '../../../../enums/webhook';
import { getUserTimeZone } from '../../../../../../shared/utils/user-details';

export type WebhookActivityTypeOption = {
  key: WebhookActivityType;
  label: string;
};

export const webhookActivityTypeOptions: WebhookActivityTypeOption[] = [
  {
    key: WebhookActivityType.All,
    label: 'All',
  },
  {
    key: WebhookActivityType.Success,
    label: 'Succeeded',
  },
  {
    key: WebhookActivityType.Failed,
    label: 'Failed',
  },
];

export const getFormattedDateTime = (date: Date | string): string => {
  const dateObj = moment(date);
  return `${dateObj.format('Do MMM, YYYY')} ${dateObj.format('hh:mm A')}`;
};

export const getFormattedDateForWebhook = (
  dateValue: Date | string,
  showTimeZone = false,
): string => {
  const userTimezone = getUserTimeZone();

  if (dateValue && userTimezone) {
    const momentObj = moment.tz(dateValue, userTimezone);
    const date = momentObj.format('MMM DD');
    const year = momentObj.format('YYYY');
    const time = momentObj.format('hh:mm a').toUpperCase();
    const timezone = `(GMT${momentObj
      .tz(userTimezone)
      .format('Z')}) ${userTimezone}`;

    return `${date}, ${year} ${time} ${showTimeZone ? timezone : ''}`.trim();
  }

  return '';
};
