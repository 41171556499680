import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ModifySubscriptionSteps } from '../../../components/settings/components/billing-subscription/components/modify-subscription/types';
import { Images } from '../../app-constants';
import Button from '../../design-system/components/atoms/button';
import Modal from '../../design-system/components/atoms/modal';
import { Permissions } from '../../utils/access-control/enums/permissions';
import hasPermission from '../../utils/access-control/has-permission';
import { contactAdmin } from '../../utils/contact-admin';
import type { IProps } from './upgrade-plan-error-modal-container';
import { openChatSupport } from '../../utils/open-chat-support';
import { Routes } from '../../routes';

const UpgradePlanErrorModal: React.FC<IProps> = ({
  show,
  onClose,
  planName,
  restrictionOn,
  adminFirstName,
  senderFirstName,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const onSubmit = () => {
    const emailSubject = `${t('messages.upgrade_plan_error_email_subject')}`;

    const emailBody = `Hello ${adminFirstName}, %0D%0A %0D%0A${t(
      'messages.upgrade_plan_error_email_body1',
    )} %0D%0A %0D%0A${t(
      'messages.upgrade_plan_error_email_body2',
    )} %0D%0A %0D%0A${t('labels.thanks')} %26 ${t(
      'labels.regards',
    )}%2C %0D%0A${senderFirstName}`;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      history.push({
        pathname: Routes.Setting_Billing_Upgrade_Email_Outreach_Plan,
        state: {
          modifySubscriptionStep: ModifySubscriptionSteps.SelectPlan,
        },
      });
    } else {
      contactAdmin(emailSubject, emailBody);
    }
  };

  const onCloseHandler = () => {
    onClose();

    openChatSupport();
  };

  return (
    <Modal
      show={show}
      className="confirm-modal-width confirmation-modal no-seat-confirmation-modal"
      titleContent={
        <div className="confirmation-modal-header">
          <img src={Images.UploadCloudBlue} alt="Alert" />
          <h2>Upgrade plan</h2>
        </div>
      }
      showCloseIcon
      onSubmit={onSubmit}
      onClose={onClose}
      onCancel={onCloseHandler}
      submitButtonText={
        hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
          ? 'Upgrade'
          : t('labels.contact_admin')
      }
      cancelButtonText="Need help?"
      cancelButtonVarient={Button.Variant.Outlined}
      submitButtonClassName="header-btn"
      backdrop="static"
    >
      <div className="confirmation-modal-content">
        <p className="confirmation-modal-text">
          {`You are currently on the ${planName} plan in which there is no ${restrictionOn} feature included.`}
        </p>
        <p className="confirmation-modal-text">
          {`Upgrade your plan to continue creating new ${restrictionOn}s.`}
        </p>
      </div>
    </Modal>
  );
};

export default UpgradePlanErrorModal;
