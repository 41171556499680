import React from 'react';

export default function NoSearchResult({ children, title, description }) {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center no-search-result">
      {children}
      <h1 className="semibold-2 blue-txt-15 text-center mt-3 mb-2">{title}</h1>
      <div className="regular-2 gray-txt-15 text-center mb-0">
        {description}
      </div>
    </div>
  );
}
