import { ReactNode } from 'react';
import { ProspectOutcomeDisplayName } from '../../../../../sequence/enums';
import { OutcomeSentiment } from '../../../../enums/custom-outcome';

// import { IProps as IPropsContainer } from './custom-outcome-dropdown-container';

export type IPropsComponent = {
  className?: string;
  initialSelectedOption?: Option | null;
  showFooter: boolean;
  options: Option[];
  showTotal: boolean;
  onChange?: (option) => void;
  optionRenderer: (option) => ReactNode;
  selectedOptionRenderer?: (option) => JSX.Element;
  selectedOptionClassName?: string;
  changeOutcomeRequestPayload?: any;
  disableAutomaticOpening?: boolean;
  disabled?: boolean;
};

export enum CustomOutcomeDropdownPlacement {
  Top = 'top',
  Bottom = 'bottom',
  DynamicTop = 'DynamicTop',
  DynamicBottom = 'DynamicBottom',
}

export type IState = {
  selectedOption: any;
  show: boolean;
  showCreateProspectCustomOutcomeModal: boolean;
  elementBottom: number;
  placement: CustomOutcomeDropdownPlacement;
};

export type Option = {
  key: number | string;
  id: number;
  name: ProspectOutcomeDisplayName | string;
  emoji: string | ReactNode;
  total?: number;
  textColor: string;
  bgColor: string;
  isDefault: boolean;
  sentiment: OutcomeSentiment;
};
