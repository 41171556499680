import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { updateOOOSettingsRequest } from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  updateOOOSettingsRequest: RequestState;
};

const initialState: State = {
  updateOOOSettingsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
};

const outOfOfficeSlice = createSlice({
  name: 'outOfOffice',
  initialState,
  reducers: {
    resetUpdateOOOSettingsRequest: (state) => {
      state.updateOOOSettingsRequest = initialState.updateOOOSettingsRequest;
    },
  },
  extraReducers: (builder) => {
    // Update Out Of Office Settings
    builder.addCase(updateOOOSettingsRequest.pending, (state) => {
      state.updateOOOSettingsRequest.status = RequestStatus.Pending;
      state.updateOOOSettingsRequest.error = null;
    });
    builder.addCase(updateOOOSettingsRequest.fulfilled, (state, action) => {
      state.updateOOOSettingsRequest.status = RequestStatus.Succeeded;
      state.updateOOOSettingsRequest.message = action.payload.message;
    });
    builder.addCase(updateOOOSettingsRequest.rejected, (state, action) => {
      state.updateOOOSettingsRequest.status = RequestStatus.Failed;
      state.updateOOOSettingsRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const { resetUpdateOOOSettingsRequest } = outOfOfficeSlice.actions;
export default outOfOfficeSlice.reducer;
