import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button, SkeletonLoading } from '@saleshandy/design-system';
import classNames from 'classnames';

import type { IProps } from './webhook-logs-container';

import Select from '../../../../../../../../shared/design-system/components/select';
import WebhookReqResPreview from '../../../webhook-req-res-preview';
import {
  WebhookActivityTypeOption,
  getFormattedDateForWebhook,
  webhookActivityTypeOptions,
} from '../../helper';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../../../shared/utils';
import toaster, { Theme } from '../../../../../../../../shared/toaster';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import NoLogsFoundUI from '../no-logs-found';
import { OverlayTooltip } from '../../../../../../../../shared/design-system/components/overlay';
import WebhookCodeBlockLoader from '../webhook-code-block-loader';
import WebhookLogsListLoader from '../webhook-logs-list-loader';

const WebhookLogs: React.FC<IProps> = ({
  webhook,
  getWebhookRequestStatus,

  webhookActivityLogs,
  webhookActivityLogsPaginationOptions,
  getWebhookActivityLogsRequestStatus,
  getWebhookActivityLogsRequestError,
  sendGetWebhookActivityLogsRequest,
  resetGetWebhookActivityLogsRequest,
  resetWebhookActivityLogs,

  webhookActivityDetails,
  getWebhookActivityDetailsRequestStatus,
  getWebhookActivityDetailsRequestError,
  sendGetWebhookActivityDetailsRequest,
  resetGetWebhookActivityDetailsRequest,
  resetWebhookActivityDetails,

  resendFailedWebhookEventRequest,
  resetResendFailedWebhookEventRequest,
  resendFailedWebhookEventRequestStatus,
  resendFailedWebhookEventRequestError,
}) => {
  const { webhookId } = useParams<{
    webhookId: string;
  }>();

  const [selectedLogStatusKey, setSelectedLogStatusKey] = useState(
    webhookActivityTypeOptions[0].key,
  );
  const [selectedEventIndex, setSelectedEventIndex] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);

  const onExpandOrCollapsed = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    sendGetWebhookActivityLogsRequest({
      webhookId,
      action: 'reset',
    });
  }, []);

  useEffect(
    () => () => {
      resetWebhookActivityLogs();
      resetWebhookActivityDetails();
    },
    [],
  );

  useEffect(() => {
    executeOnRequestStatus({
      status: getWebhookActivityLogsRequestStatus,
      onSuccess: () => {
        resetGetWebhookActivityLogsRequest();
        if (webhookActivityLogs?.length > 0) {
          sendGetWebhookActivityDetailsRequest({
            webhookId,
            keyId: webhookActivityLogs?.[0]?.key,
          });
        }
      },
      onFailed: () => {
        resetGetWebhookActivityLogsRequest();
        executeOnErrorWithErrorCheck({
          error: getWebhookActivityLogsRequestError,
          onError: () => {
            toaster.error(getWebhookActivityLogsRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [getWebhookActivityLogsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getWebhookActivityDetailsRequestStatus,
      onSuccess: () => {
        resetGetWebhookActivityDetailsRequest();
      },
      onFailed: () => {
        resetGetWebhookActivityDetailsRequest();
        executeOnErrorWithErrorCheck({
          error: getWebhookActivityDetailsRequestError,
          onError: () => {
            toaster.error(getWebhookActivityDetailsRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [getWebhookActivityDetailsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: resendFailedWebhookEventRequestStatus,
      onSuccess: () => {
        setSelectedEventIndex(0);
        resetWebhookActivityDetails();
        sendGetWebhookActivityLogsRequest({
          webhookId,
          action: 'reset',
        });
        toaster.success('Webhook event has been resent successfully', {
          theme: Theme.New,
        });

        resetResendFailedWebhookEventRequest();
      },
      onFailed: () => {
        setSelectedEventIndex(0);
        resetWebhookActivityDetails();
        sendGetWebhookActivityLogsRequest({
          webhookId,
          action: 'reset',
        });
        resetResendFailedWebhookEventRequest();
        executeOnErrorWithErrorCheck({
          error: resendFailedWebhookEventRequestError,
          onError: () => {
            toaster.error(resendFailedWebhookEventRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });
  }, [resendFailedWebhookEventRequestStatus]);

  const isGetWebhookRequestStatusLoading = getIsRequestPending(
    getWebhookRequestStatus,
  );
  const isGetWebhookActivityLogsRequestStatusLoading = getIsRequestPending(
    getWebhookActivityLogsRequestStatus,
  );
  const isGetWebhookActivityDetailsRequestStatusLoading = getIsRequestPending(
    getWebhookActivityDetailsRequestStatus,
  );

  const webhookLogsClassNames = classNames([
    'webhook-logs',
    {
      isExpanded,
    },
  ]);

  const webhookSelectedClassNames = classNames([
    'webhook-logs--selected',
    {
      visible:
        isGetWebhookActivityLogsRequestStatusLoading ||
        isGetWebhookActivityDetailsRequestStatusLoading ||
        (webhookActivityDetails && !!webhookActivityDetails.statusCode),
    },
  ]);

  return (
    <div className={webhookLogsClassNames}>
      <div className="webhook-logs--list">
        <div className="selected-log-details">
          <h1>
            {isGetWebhookRequestStatusLoading ? (
              <SkeletonLoading width={155} height={20} />
            ) : (
              webhook?.name
            )}
          </h1>
          <p>
            {isGetWebhookRequestStatusLoading ? (
              <SkeletonLoading width={400} height={18} />
            ) : (
              webhook?.url
            )}
          </p>
        </div>
        <div className="webhook-logs-list-header">
          <h1>
            {isGetWebhookRequestStatusLoading ? (
              <SkeletonLoading width={100} height={18} />
            ) : (
              'Log list'
            )}
          </h1>
          {isGetWebhookActivityLogsRequestStatusLoading ? (
            <SkeletonLoading width={140} height={32} />
          ) : (
            <Select<WebhookActivityTypeOption>
              options={webhookActivityTypeOptions}
              selectedOptionKey={selectedLogStatusKey}
              optionRenderer={(option) => <span>{option?.label}</span>}
              selectedOptionRenderer={([option]) => (
                <span>{option?.label}</span>
              )}
              onChange={([option]) => {
                setSelectedLogStatusKey(option?.key);
                setSelectedEventIndex(0);
                resetGetWebhookActivityLogsRequest();
                resetWebhookActivityDetails();
                sendGetWebhookActivityLogsRequest({
                  webhookId,
                  action: 'reset',
                  ...(option?.key !== 'all' && { activityType: option?.key }),
                });
              }}
            />
          )}
        </div>

        {isGetWebhookActivityLogsRequestStatusLoading && (
          <WebhookLogsListLoader />
        )}

        {!isGetWebhookActivityLogsRequestStatusLoading &&
          webhookActivityLogs?.length === 0 && (
            <NoLogsFoundUI activityType={selectedLogStatusKey} />
          )}

        {!isGetWebhookActivityLogsRequestStatusLoading &&
          webhookActivityLogs?.length > 0 && (
            <div className="webhook-logs-list-rows">
              {webhookActivityLogs?.length > 0 &&
                webhookActivityLogs.map((log, index) => (
                  <div
                    className={`webhook-logs-list-row ${
                      selectedEventIndex === index ? 'active' : ''
                    }`}
                    {...accessibleOnClick(() => {
                      sendGetWebhookActivityDetailsRequest({
                        webhookId,
                        keyId: log?.key,
                      });
                      setSelectedEventIndex(index);
                    })}
                  >
                    <div className="left">
                      <div className={`log-type-dot ${log.activityType}`} />
                      <p>{log.event}</p>
                    </div>
                    <OverlayTooltip
                      text={getFormattedDateForWebhook(log.activityAt, true)}
                    >
                      <p>{getFormattedDateForWebhook(log.activityAt, false)}</p>
                    </OverlayTooltip>
                  </div>
                ))}
              {webhookActivityLogsPaginationOptions?.totalItems >
                webhookActivityLogs?.length && (
                <Button
                  className="my-4"
                  variant="secondary"
                  onClick={() => {
                    sendGetWebhookActivityLogsRequest({
                      webhookId,
                      action: 'load-more',
                      page:
                        webhookActivityLogsPaginationOptions?.currentPage + 1,
                      ...(selectedLogStatusKey !== 'all' && {
                        activityType: selectedLogStatusKey,
                      }),
                    });
                  }}
                >
                  Load More
                </Button>
              )}
            </div>
          )}
      </div>

      <div className={webhookSelectedClassNames}>
        {(isGetWebhookActivityLogsRequestStatusLoading ||
          isGetWebhookActivityDetailsRequestStatusLoading) && (
          <WebhookCodeBlockLoader />
        )}

        {!isGetWebhookActivityDetailsRequestStatusLoading &&
          webhookActivityDetails &&
          !!webhookActivityDetails.statusCode && (
            <WebhookReqResPreview
              label="Response"
              statusCode={webhookActivityDetails?.statusCode}
              statusText={webhookActivityDetails?.statusText}
              json={{
                status:
                  webhookActivityDetails?.statusCode !== 200
                    ? 'error'
                    : 'success',
                message:
                  webhookActivityDetails?.statusCode !== 200
                    ? 'Webhook processing failed.'
                    : 'Webhook received and processed successfully.',
              }}
              failed={webhookActivityDetails?.statusCode !== 200}
              isExpanded={isExpanded}
              onExpandOrCollapsed={onExpandOrCollapsed}
              showRetry={webhookActivityDetails?.statusCode !== 200}
              onRetry={() => {
                if (
                  !getIsRequestPending(resendFailedWebhookEventRequestStatus)
                ) {
                  resendFailedWebhookEventRequest(
                    webhookActivityLogs?.[selectedEventIndex]?.id,
                  );
                }
              }}
              showEventType
              event={webhookActivityDetails?.data?.event}
              showRequest
              requestJson={webhookActivityDetails?.data}
            />
          )}
      </div>
    </div>
  );
};

export default WebhookLogs;
