import React, { useState } from 'react';
import moment from 'moment-timezone';
import 'react-datepicker/dist/react-datepicker.css';
import { Alarm } from '@saleshandy/icons';
import type { IProps } from './types';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';
import Select from '../../../../../../shared/design-system/components/select';
import { mergedDateTime, snoozeOptions, generateTimeArray } from './helper';
import { Variant } from '../../../../../../shared/design-system/components/atoms/button/button';
import SnoozeDatePicker from './snooze-date-picker';

const SnoozeModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  isRequestPending,
}) => {
  const [selectedOption, setSelectedOption] = useState<{
    key: string;
    label: string;
    value: number;
  }>(snoozeOptions[0]);
  const [value, setValue] = useState<Date>(
    new Date(new Date().getTime() + selectedOption.value),
  );
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState(generateTimeArray()[0]);

  const onChange = (option) => {
    setSelectedOption(option);
    setValue(new Date(new Date().getTime() + option.value));
  };

  const onSelect = (d) => {
    setDate(d);
  };
  const onTimeSelect = (t) => {
    setTime(t);
  };

  const getDate = (): Date =>
    selectedOption?.key === 'custom' ? mergedDateTime(date, time) : value;

  return (
    <Modal
      show={show}
      className="snooze-modal"
      titleContent={
        <div>
          <Alarm width={23} height={23} className="orange-txt-14" />
          <span className="semibold-3 black-txt-1 ml-2">Snooze this task</span>
        </div>
      }
      backdrop="static"
      onSubmit={() => onSubmit(getDate())}
      onClose={onClose}
      submitButtonText="Snooze"
      submitButtonClassName="font-weight-medium snooze-submit-btn"
      cancelButtonClassName="font-weight-medium snooze-cancel-btn"
      isSubmitLoading={isRequestPending}
      isCancelDisabled={isRequestPending}
      isSubmitDisabled={isRequestPending}
      cancelButtonVarient={Variant.Outlined}
      extraModalProps={{
        centered: true,
        'aria-labelledby': 'contained-modal-title-vcenter',
      }}
    >
      <div>
        <span className="regular-1 font-medium gray-txt-15">Snooze for</span>
        <Select<{ key: string; label: string }>
          selectedOptionKey={selectedOption.key}
          options={snoozeOptions}
          onChange={([option]) => onChange(option)}
          optionRenderer={(option) => (
            <span className={option?.key === 'custom' && 'custom-option'}>
              {option?.label}
            </span>
          )}
          selectedOptionRenderer={([option]) => <span>{option?.label}</span>}
          className="snooze-select"
        />
      </div>

      {selectedOption?.key === 'custom' && (
        <div className="d-flex justify-content-between bs-mt-20">
          <div className="date-picker">
            <span className="regular-1 font-medium gray-txt-15">Date</span>
            <SnoozeDatePicker dt={date} onSelect={onSelect} />
          </div>
          <div className="time-picker">
            <span className="regular-1 font-medium gray-txt-15">Time</span>
            <Select<{ key: string; label: string }>
              selectedOptionKey={time?.key}
              options={generateTimeArray()}
              onChange={([option]) => onTimeSelect(option)}
              optionRenderer={(option) => <span>{option?.key}</span>}
              selectedOptionRenderer={([option]) => <span>{option?.key}</span>}
            />
          </div>
        </div>
      )}

      <div className="mt-4 d-flex flex-column">
        <span className="regular-1 font-medium gray-txt-15">Scheduled for</span>
        <span className="regular-2 font-medium popover-arrow-color-txt mt-1">
          {/* Friday, 18 August at 5:00 PM */}
          {moment(getDate()).format('dddd, DD MMMM [at] h:mm A')}
        </span>
      </div>
    </Modal>
  );
};

export default SnoozeModal;
