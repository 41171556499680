import React from 'react';
import { EmptyList, ImageIcon } from '@saleshandy/design-system';

type IProps = {
  imrSrc: string;
  title: string;
  desc: string;
};

const UsersAndTeamsEmptyScreen: React.FC<IProps> = ({
  imrSrc,
  title,
  desc,
}) => (
  <div className="users-and-teams-empty-list">
    <EmptyList>
      <EmptyList.Image>
        <ImageIcon src={imrSrc} alt="Image" />
      </EmptyList.Image>
      <EmptyList.Body>
        <EmptyList.Title>{title}</EmptyList.Title>
        <EmptyList.Description>{desc}</EmptyList.Description>
      </EmptyList.Body>
    </EmptyList>
  </div>
);

export default UsersAndTeamsEmptyScreen;
