import { ProspectOutcome } from '../../../../../../../enums';
import { StatusFilterDetail } from '../../../../../../../types';
import {
  getCustomProspectOutcomeOptions,
  getProspectOutcomeOptions,
} from '../../contact-list-content/helper';

export const getSelectBgAndBorderColor = (outcome: ProspectOutcome) => {
  let bgColor = '#F9FAFB';
  let borderColor = '#E5E7EB';
  let hoverBackgroundColor = '#F3F4F6';
  let hoverBorderColor = '#D1D5DB';

  const getOutcome = getProspectOutcomeOptions.find(
    ({ key }) => key === outcome,
  );

  if (getOutcome) {
    bgColor = getOutcome.backgroundColor;
    borderColor = getOutcome.borderColor;
    hoverBackgroundColor = getOutcome.hoverBackgroundColor;
    hoverBorderColor = getOutcome.hoverBorderColor;
  }

  return { bgColor, borderColor, hoverBackgroundColor, hoverBorderColor };
};

export const getOutcomeFromId = (outcomeId: number) =>
  getProspectOutcomeOptions.find(({ id }) => id === outcomeId);

export const getOutcomeFromIdOption = (outcomeId: number) =>
  getCustomProspectOutcomeOptions.find(({ id }) => id === outcomeId);

export const mapOutcomeToOption = (allOutcomes) =>
  allOutcomes.map((outcome) => {
    const option = {
      id: outcome.id,
      key: outcome.id,
      name: outcome.name,
      bgColor: outcome.bgColor,
      textColor: outcome.textColor,
      isDefault: outcome.isDefault,
      sentiment: outcome.sentiment,
      total: outcome?.total || 0,
      emoji: outcome.emoji,
    };
    return option;
  });

export const getOptionFromOutcome = (allOutcomes, outcomeId) => {
  const outcome = allOutcomes.find(({ id }) => id === outcomeId);

  if (!outcome) {
    return null;
  }
  const option = {
    id: outcome.id,
    key: outcome.id,
    name: outcome.name,
    bgColor: outcome.bgColor,
    textColor: outcome.textColor,
    isDefault: outcome.isDefault,
    sentiment: outcome.sentiment,
    total: outcome?.total || 0,
    emoji: outcome.emoji,
  };
  return option;
};

export const getWordWidthInRem = (word, fontSizeRem) => {
  const widthInRem = word.length * fontSizeRem;

  if (widthInRem > 10.5) {
    return 10;
  }
  if (widthInRem < 7) {
    return 7.5;
  }

  return widthInRem;
};

export const hasSelectedStatus = (outcome: StatusFilterDetail, selectedStatus: string) => {
  if (outcome?.type === selectedStatus) {
      return true;
  }
  if (outcome.isDropDownSegment && outcome.segmentOptions.length) {
      return outcome.segmentOptions.some(segment => segment?.type === selectedStatus);
  }
  return false;
}