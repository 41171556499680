import api from '../../../api';
import {
  CreateAndTestWebhookPayload,
  GetWebhookActivityDetailsPayload,
  GetWebhookActivityLogsPayload,
  GetWebhookListPayload,
  UpdateWebhookPayload,
  UpdateWebhookStatusPayload,
} from '../types/webhook';

export const getWebhook = async (webhookId: string) =>
  api.get(`/webhook/${webhookId}`);

export const getWebhookList = async (payload: GetWebhookListPayload) =>
  api.get(`/webhook`, { params: payload });

export const updateWebhookStatus = async ({
  webhookId,
  ...payload
}: UpdateWebhookStatusPayload) =>
  api.patch(`/webhook/update-status/${webhookId}`, payload);

export const createWebhook = async (payload: CreateAndTestWebhookPayload) =>
  api.post(`/webhook/create`, payload);

export const updateWebhook = async ({
  webhookId,
  ...payload
}: UpdateWebhookPayload) => api.patch(`/webhook/${webhookId}`, payload);

export const testWebhook = async (payload: CreateAndTestWebhookPayload) =>
  api.post(`/webhook/test`, payload);

export const resendFailedWebhookEvent = async (keyId: string) =>
  api.post(`/webhook/resend-failed-event/${keyId}`);

export const deleteWebhook = async (webhookId: string) =>
  api.delete(`/webhook/${webhookId}`);

export const getWebhookActivityLogs = async ({
  webhookId,
  action,
  ...params
}: GetWebhookActivityLogsPayload) =>
  api.get(`/webhook/activity/${webhookId}`, { params });

export const getWebhookActivityDetails = async ({
  webhookId,
  keyId,
}: GetWebhookActivityDetailsPayload) =>
  api.get(`/webhook/activity-details/${webhookId}/${keyId}`);
