import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import AddBulkImportModal from './add-bulk-import-modal';
import { importEmailAccountCsvRequest } from '../../extra-actions';

const mapStateToProps = (state: RootState) => ({
  importEmailAccountCsvRequestStatus:
    state.emailAccount.importEmailAccountCsvRequest.status,
});

const mapDispatchToProps = {
  importEmailAccountViaCsv: (file: File) =>
    importEmailAccountCsvRequest({ file }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onCancel: VoidFunction;
};

export default connector(AddBulkImportModal);
