import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { IProps } from './types/templates';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';

const TemplatesContent = React.lazy(() =>
  retryLazy(() => import('./components')),
);

const Templates: React.FC<IProps> = () => (
  <div className="templates-container">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/templates" component={TemplatesContent} />
          <Redirect to="/templates" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </div>
);

export default Templates;
