import validator from 'validator';
import { AgencyClientDisplayState, AgencyClientFilter } from '../types/types';
import { getTruncatedTextForCategory } from '../../unified-inbox/helpers/utils/get-truncated-text-for-category';
import { AgencyClientStatus } from '../enums/agency-client';

export const fields = [
  {
    fieldType: 'text',
    id: 1,
    label: 'First Name',
    name: 'firstName',
    placeholder: 'John',
  },
  {
    fieldType: 'text',
    id: 2,
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Doe',
  },
  {
    fieldType: 'email',
    id: 3,
    label: 'Client Email',
    name: 'email',
    placeholder: 'john@acme.com',
  },
  {
    fieldType: 'text',
    id: 4,
    label: 'Company Name',
    name: 'companyName',
    placeholder: 'Acme',
  },
];

const nameValidator = (
  value: string,
  key: string,
  checkIsAlpha = true,
  maxCharLimit = 25,
): string => {
  if (validator.isEmpty(value)) {
    return `${key} name is required.`;
  }
  if (checkIsAlpha && !validator.isAlpha(value)) {
    return `Alphabetic characters only.`;
  }
  if (!validator.isLength(value, { min: 1, max: maxCharLimit })) {
    return `Should be between 1-${maxCharLimit} characters.`;
  }
  return '';
};

export const validate = (name: string, value: string): string => {
  switch (name) {
    case 'firstName':
      return nameValidator(value, 'First');

    case 'lastName':
      return nameValidator(value, 'Last');

    case 'email':
      if (validator.isEmpty(value)) {
        return 'Email is required';
      }
      if (!validator.isEmail(value)) {
        return 'Please enter a valid email address.';
      }
      break;

    case 'companyName':
      return nameValidator(value, 'Company', false, 100);
    default:
      break;
  }

  return '';
};

export const getClientName = (
  client: AgencyClientFilter,
  truncateLength: number,
  show: AgencyClientDisplayState,
): string => {
  const { firstName, companyName, email, status } = client;
  let name = '';
  if (show.name) {
    name += firstName;
  }
  if (show.company) {
    if (name.length) {
      name += ' - ';
    }
    name += companyName;
  }
  if (show.email) {
    if (name.length) {
      name += ` (${email})`;
    } else {
      name = `${email}`;
    }
  }
  if (status === AgencyClientStatus.Deleted && show.deleted) {
    name += ' (deleted)';
  }
  return truncateLength > 0
    ? getTruncatedTextForCategory(name, truncateLength)
    : name;
};
