import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../../store/root-reducer';

import WebhookLogs from './webhook-logs';

import {
  getWebhookActivityLogsRequest,
  getWebhookActivityDetailsRequest,
  resendFailedWebhookEventRequest,
} from '../../../../extra-actions';
import {
  resetGetWebhookActivityLogsRequest,
  resetGetWebhookActivityDetailsRequest,
  resetWebhookActivityDetails,
  resetWebhookActivityLogs,
  resetResendFailedWebhookEventRequest,
} from '../../../../webhook-slice';
import {
  GetWebhookActivityLogsPayload,
  GetWebhookActivityDetailsPayload,
} from '../../../../../../types/webhook';

const mapStateToProps = (state: RootState) => ({
  getWebhookRequestStatus: state.webhook.getWebhookRequest.status,

  getWebhookActivityLogsRequestStatus:
    state.webhook.getWebhookActivityLogsRequest.status,
  getWebhookActivityLogsRequestMessage:
    state.webhook.getWebhookActivityLogsRequest.message,
  getWebhookActivityLogsRequestError:
    state.webhook.getWebhookActivityLogsRequest.error,

  getWebhookActivityDetailsRequestStatus:
    state.webhook.getWebhookActivityDetailsRequest.status,
  getWebhookActivityDetailsRequestMessage:
    state.webhook.getWebhookActivityDetailsRequest.message,
  getWebhookActivityDetailsRequestError:
    state.webhook.getWebhookActivityDetailsRequest.error,

  resendFailedWebhookEventRequestStatus:
    state.webhook.resendFailedWebhookEventRequest.status,
  resendFailedWebhookEventRequestMessage:
    state.webhook.resendFailedWebhookEventRequest.message,
  resendFailedWebhookEventRequestError:
    state.webhook.resendFailedWebhookEventRequest.error,

  webhook: state.webhook.webhook,
  webhookActivityLogs: state.webhook.webhookActivityLogs,
  webhookActivityDetails: state.webhook.webhookActivityDetails,
  webhookActivityLogsPaginationOptions:
    state.webhook.webhookActivityLogsPaginationOptions,
});

const mapDispatchToProps = {
  sendGetWebhookActivityLogsRequest: (payload: GetWebhookActivityLogsPayload) =>
    getWebhookActivityLogsRequest(payload),
  resetGetWebhookActivityLogsRequest: () =>
    resetGetWebhookActivityLogsRequest(),
  resetWebhookActivityLogs: () => resetWebhookActivityLogs(),

  sendGetWebhookActivityDetailsRequest: (
    payload: GetWebhookActivityDetailsPayload,
  ) => getWebhookActivityDetailsRequest(payload),
  resetGetWebhookActivityDetailsRequest: () =>
    resetGetWebhookActivityDetailsRequest(),

  resendFailedWebhookEventRequest: (keyId: string) =>
    resendFailedWebhookEventRequest(keyId),
  resetResendFailedWebhookEventRequest: () =>
    resetResendFailedWebhookEventRequest(),

  resetWebhookActivityDetails: () => resetWebhookActivityDetails(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(WebhookLogs);
