import {
  TaskStatusDisplayLabel,
  TaskStatusKey,
  TaskStatusParamsKey,
} from './enums';

export const getTaskStatusArray = () => [
  {
    label: TaskStatusDisplayLabel.DueToday,
    key: TaskStatusKey.DueToday,
  },
  {
    label: TaskStatusDisplayLabel.Upcoming,
    key: TaskStatusKey.Upcoming,
  },
  {
    label: TaskStatusDisplayLabel.Overdue,
    key: TaskStatusKey.Overdue,
  },
  {
    label: TaskStatusDisplayLabel.Completed,
    key: TaskStatusKey.Completed,
  },
  {
    label: TaskStatusDisplayLabel.Skipped,
    key: TaskStatusKey.Skipped,
  },
];

export const getTaskStatusParams = (
  status: TaskStatusKey,
): TaskStatusParamsKey => {
  if (status === TaskStatusKey.Upcoming) {
    return TaskStatusParamsKey.Upcoming;
  }
  if (status === TaskStatusKey.Skipped) {
    return TaskStatusParamsKey.Skipped;
  }
  if (status === TaskStatusKey.Completed) {
    return TaskStatusParamsKey.Completed;
  }
  if (status === TaskStatusKey.Overdue) {
    return TaskStatusParamsKey.Overdue;
  }

  return TaskStatusParamsKey.DueToday;
};
