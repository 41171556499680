/* eslint-disable consistent-return */
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { Container, Row, ThemeProvider } from 'react-bootstrap';
import { Switch } from 'react-router-dom';
import BlockPage from '../../shared/components/block-page';
import ConfigRoute from '../../shared/components/config-route';
import HelmetIcon from '../../shared/components/helmet-icon';
import { RequestStatus } from '../../shared/enums/request-status';
import {
  OnboardingSteps,
  ProfileProgressSteps,
  UserSettingCode,
} from '../../shared/types/user-setting';
import { AuthHelper, getIsRequestSucceeded } from '../../shared/utils';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../shared/utils/access-control/has-permission';
import { hasRedirectToBlockPage } from '../../shared/utils/access-control/has-redirect-to-block-page';
import { planError } from '../../shared/utils/errors/plan-permission-error/plan-error';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatusWithPrevStatusCheck,
} from '../../shared/utils/execute-on-request-status';
import { handleOnboarding } from '../../shared/utils/handle-onboarding-routing';
import {
  destroyChatSupport,
  initializeIntercom,
} from '../../shared/utils/helpscout-handlers';
import { initializeCello } from '../../shared/utils/initialize-third-party-integrations';
import hasResource from '../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../shared/utils/access-control/enums/resource-identifiers';
import { getNewSequenceName } from '../sequence/components/sequence-list/helper';
import HelpscoutBeaconEvents from '../../shared/enums/helpscout-beacon-events';
import { Source } from '../../shared/enums/source';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import { PaymentActionType } from '../../shared/design-system/components/atoms/banner/banner';
import toaster, { Theme } from '../../shared/toaster';
import { TrialExtendSource } from '../../shared/enums/trial-extend-source';
import growthHub from '../growth-hub';
import AgencyPortal from '../agency-portal';
import OnboardingModal from '../../shared/components/onboarding-modal';
import Alert from '../../shared/design-system/ui/alert';
import { accessibleOnClick } from '../../shared/utils/accessible-on-click';
import VerifyEmailCallback from '../auth/components/verify-email-callback';
import { openIntercomHome } from '../../shared/utils/open-chat-support';
import Tasks from '../tasks';
import { PageHeaderAlertTypes } from '../../shared/utils/page-header-alert-types';
import {
  redirectWithRefresh,
  redirectWithoutRefresh,
} from '../../shared/utils/redirect';
import { SubscriptionPlans } from '../../shared/utils/subscription-plans';
import EmailWarmup from '../email-warmup';
import leads from '../leads';
import MailboxEmails from '../mailbox-emails';
import Prospect from '../prospect';
import Reports from '../reports';
import Sequence from '../sequence';
import { getIsRequestPending } from '../sequence/helpers/helper';
import Setting from '../settings';
import Templates from '../templates';
import UnifiedInbox from '../unified-inbox';
import Header from './components/header';
import SideNavbar from './components/sidenavbar';
import WatchVideoModal from './components/watch-video-modal';
import type { IProps, IState } from './home-container';
import { isPlanDowngraded } from './utils/plan-transition';
import AgencyClient from '../agency-client-management/agency-client';
import { isWhitelabelWithoutClientView } from '../../shared/utils/user-details';
import { defaultAgencyObj } from './utils/helper';
import { getCurrentRoute } from '../../shared/utils/get-current-route';
import {
  initializeCustomScript,
  removeCustomScript,
} from '../../shared/utils/initialize-custom-script';
import EmailAccounts from '../email-accounts';

class Home extends React.Component<IProps, IState> {
  private unlisten;

  private originalFavicon: null;

  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      showWatchVideoModal: false,
      showGettingStarted: false,
      showHelpscoutWelcomeMessage: false,
      isViewSequenceBtnClicked: false,
      showOnboardingModal: false,
      willRedirectToLeadFinder: false,
    };
    this.originalFavicon = null;

    this.updateWatchVideoProfileProgressStep = this.updateWatchVideoProfileProgressStep.bind(
      this,
    );
    this.updateConnectEmailProfileProgressStep = this.updateConnectEmailProfileProgressStep.bind(
      this,
    );
    this.showHideWatchVideoModal = this.showHideWatchVideoModal.bind(this);
    this.showHideGettingStarted = this.showHideGettingStarted.bind(this);
    this.showHideHelpscoutWelcomeMessage = this.showHideHelpscoutWelcomeMessage.bind(
      this,
    );
    this.showHideOnboardingModal = this.showHideOnboardingModal.bind(this);
    this.createSequence = this.createSequence.bind(this);
    this.handleHelpscoutWelcomeMessage = this.handleHelpscoutWelcomeMessage.bind(
      this,
    );
    this.onViewSampleSequence = this.onViewSampleSequence.bind(this);
    this.initializeHelpScoutChatSupport = this.initializeHelpScoutChatSupport.bind(
      this,
    );
    this.initializeCelloReferral = this.initializeCelloReferral.bind(this);
    this.handleSendVerificationLinkRequest = this.handleSendVerificationLinkRequest.bind(
      this,
    );
    this.unlisten = React.createRef();
  }

  // eslint-disable-next-line react/sort-comp
  redirectSpamUrl = () => {
    window.open(
      'https://docs.saleshandy.com/en/articles/8743773-troubleshooting-didn-t-receive-your-verification-email',
      '_blank',
    );
  };

  componentDidMount() {
    const {
      sendGetUserSettingsRequest,
      sendGetAgencyConfigRequest,
      history,
      email,
      location,
      sendCompareAuthTokenRequest,
      getMyProfileRequestStatus,
    } = this.props;
    sendGetUserSettingsRequest();

    if (getMyProfileRequestStatus === RequestStatus.Succeeded) {
      const agencyConfigPayload = {
        baseUrl: window.location.origin,
        email,
      };
      sendGetAgencyConfigRequest(agencyConfigPayload);
    }

    // Store the original favicon URL
    const originalFavicon: any = document.querySelector("link[rel*='icon']");
    if (originalFavicon) {
      this.originalFavicon = originalFavicon.href;
    }

    this.unlisten.current = history.listen((locationParam) => {
      if (isPlanDowngraded(this.getPlanTransitionDetails())) {
        if (
          !hasRedirectToBlockPage(locationParam.pathname) &&
          locationParam.pathname !== '/login' &&
          locationParam.pathname !== '/signup' &&
          history.location.pathname !== '/block'
        ) {
          history.push('/block');
        }
      }
    });

    const params = new URLSearchParams(location.search);

    const authToken = params.get('authToken');

    if (authToken) {
      sendCompareAuthTokenRequest({
        authToken,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      sendGetUserSettingsRequest,
      sendGetPostLoadMetaRequest,
      getUserSettingsRequestStatus,
      getSampleSequenceRequestStatus,
      updateProfileProgressRequestStatus,
      location,
      createSequenceFromGettingStartedRequestStatus,
      gettingStatedCreatedSequenceId,
      hasUserConnectedEmailAccount,
      handleHasUserConnectedEmailAccount,
      compareAuthTokenRequestStatus,
      shouldLogoutUser,
      resetCompareAuthTokenRequest,
      history,
      agencyConfig,
      sendGetAgencyConfigRequest,
      shAccountId,
      email,
      client,
      extendTrialPeriodRequestStatus,
      extendTrialPeriodRequestMessage,
      onBoardingRequestStatus,
      onBoardingRequestError,
      resendVerificationEmailRequestStatus,
      resendVerificationEmailRequestError,
      outcomeChangeRequestStatus,
      outcomeChangeRequestMessage,
      getAgencyConfigRequestStatus,
    } = this.props;

    if (prevProps.email !== email) {
      const agencyConfigPayload = {
        baseUrl: window.location.origin,
        email,
      };

      sendGetAgencyConfigRequest(agencyConfigPayload);
    }

    this.initializeHelpScoutChatSupport();

    const { showWatchVideoModal, isViewSequenceBtnClicked } = this.state;

    executeOnRequestStatusWithPrevStatusCheck({
      status: getUserSettingsRequestStatus,
      prevStatus: prevProps.getUserSettingsRequestStatus,
      onSuccess: () => {
        const { willRedirectToLeadFinder } = this.state;

        sendGetPostLoadMetaRequest();
        this.redirectToBlankPage();
        this.onCompleteOnboardingProcess();
        this.checkPaymentPendingBanner();

        const customScriptForAgency = document.getElementById(
          'custom-script-for-agency',
        );
        if (
          shAccountId === 45555 &&
          client &&
          !isEmpty(client) &&
          !customScriptForAgency
        ) {
          initializeCustomScript();
        }

        if (willRedirectToLeadFinder) {
          return redirectWithoutRefresh('/leads');
        }

        if (hasUserConnectedEmailAccount) {
          this.updateConnectEmailProfileProgressStep();
        }

        const params = new URLSearchParams(location.search);
        const action = params.get('action');

        if (action === 'extendTrial') {
          this.onTrialExtended();
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: outcomeChangeRequestStatus,
      prevStatus: prevProps.outcomeChangeRequestStatus,
      onSuccess: () => {
        toaster.success('Prospect outcome updated successfully', {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        toaster.error(outcomeChangeRequestMessage, {
          theme: Theme.New,
        });
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: updateProfileProgressRequestStatus,
      prevStatus: prevProps.updateProfileProgressRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
        if (hasUserConnectedEmailAccount) {
          handleHasUserConnectedEmailAccount(false);
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: resendVerificationEmailRequestStatus,
      prevStatus: prevProps.resendVerificationEmailRequestStatus,
      onSuccess: () => {
        toaster.success('Email resent successfully.', {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: resendVerificationEmailRequestError,
          onError: () => {
            toaster.error(resendVerificationEmailRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getAgencyConfigRequestStatus,
      prevStatus: prevProps.getAgencyConfigRequestStatus,
    });

    if (agencyConfig?.colorPalette) {
      // Create Instance of the document element
      const documentInstance = document.documentElement;

      // Loop through the 'colorPalette' object
      Object.keys(agencyConfig.colorPalette).forEach((color) => {
        // Set CSS variables
        documentInstance.style.setProperty(
          `--${color}`,
          agencyConfig.colorPalette[color],
        );
      });
    }

    // Change the favicon

    executeOnRequestStatusWithPrevStatusCheck({
      status: createSequenceFromGettingStartedRequestStatus,
      prevStatus: prevProps.createSequenceFromGettingStartedRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(
          `/sequence/${gettingStatedCreatedSequenceId}/steps`,
        );
        this.showHideWatchVideoModal(false);
        this.handleHelpscoutWelcomeMessage();
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: getSampleSequenceRequestStatus,
      prevStatus: prevProps.getSampleSequenceRequestStatus,
      onSuccess: () => {
        if (showWatchVideoModal && isViewSequenceBtnClicked) {
          this.onViewSampleSequence();
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: compareAuthTokenRequestStatus,
      prevStatus: prevProps.compareAuthTokenRequestStatus,
      onSuccess: () => {
        const params = new URLSearchParams(location.search);
        params.delete('authToken');
        if (shouldLogoutUser) {
          AuthHelper.logout({
            fallbackTo: location.pathname,
            query: params.toString(),
          });
        } else {
          history.push(location.pathname);
        }
        resetCompareAuthTokenRequest();
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: extendTrialPeriodRequestStatus,
      prevStatus: prevProps.extendTrialPeriodRequestStatus,
      onSuccess: () => {
        toaster.success('Trial has been extended successfully', {
          theme: Theme.New,
        });

        redirectWithRefresh('/sequence');
      },
      onFailed: () => {
        if (extendTrialPeriodRequestMessage) {
          toaster.error(extendTrialPeriodRequestMessage, {
            theme: Theme.New,
          });
        }
      },
    });

    executeOnRequestStatusWithPrevStatusCheck({
      status: onBoardingRequestStatus,
      prevStatus: prevProps.onBoardingRequestStatus,
      onSuccess: () => {
        const { willRedirectToLeadFinder } = this.state;

        this.showHideOnboardingModal(false);
        sendGetUserSettingsRequest();

        const searchParam = new URLSearchParams('');
        searchParam.set('onboarding', 'completed');

        if (willRedirectToLeadFinder) {
          redirectWithoutRefresh('/leads');
        } else {
          history.push({
            search: searchParam.toString(),
          });
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: onBoardingRequestError,
          onError: () => {
            toaster.error(onBoardingRequestError.message, {
              theme: Theme.New,
            });
          },
        });
      },
    });

    if (window.Intercom) {
      window.Intercom('event', {
        type: HelpscoutBeaconEvents.PAGE_VIEWED,
        url: document.location.href,
        title: document.title,
      });
      window.Intercom(HelpscoutBeaconEvents.SUGGEST);
    }
    this.initializeCelloReferral();
  }

  componentWillUnmount() {
    this.unlisten.current();

    destroyChatSupport();
    removeCustomScript();
  }

  onViewSampleSequence() {
    const {
      sampleSequence,
      sendGetSampleSequenceRequest,
      isMemberAccount,
      sendFinishOnboardingRequest,
    } = this.props;

    if (this.isUserAlreadyInStepPage()) {
      this.showHideWatchVideoModal(false);
      return;
    }

    if (isMemberAccount) {
      sendFinishOnboardingRequest();
    }

    if (sampleSequence) {
      const sequenceId = sampleSequence?.id;
      this.showHideWatchVideoModal(false);
      redirectWithoutRefresh(`/sequence/${sequenceId}/steps`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      this.setState({ isViewSequenceBtnClicked: true });
      sendGetSampleSequenceRequest();
    }
  }

  onCompleteOnboardingProcess() {
    const {
      meta,
      sendFinishOnboardingRequest,
      handleHasSignUpCompleted,
      sendGetSampleSequenceRequest,
      location,
      history,
      isMemberAccount,
    } = this.props;
    const {
      showWatchVideoModal,
      showGettingStarted,
      willRedirectToLeadFinder,
    } = this.state;

    if (willRedirectToLeadFinder) {
      return;
    }

    if (!isEmpty(meta)) {
      const onboardingStep = meta.find(
        (setting) => setting.code === UserSettingCode.Onboarding,
      );

      if (onboardingStep?.value === OnboardingSteps.Step1) {
        if (isMemberAccount) {
          sendGetSampleSequenceRequest();
          this.showHideWatchVideoModal(true);
          return;
        }

        this.showHideOnboardingModal(true);

        const currentPath = location.pathname + location.search;
        handleOnboarding(meta, currentPath, location.search);
      }

      if (onboardingStep?.value === OnboardingSteps.Step2) {
        sendFinishOnboardingRequest();
        handleHasSignUpCompleted(true);
        this.showHideWatchVideoModal(true);
        this.showHideHelpscoutWelcomeMessage(true);

        const currentPath = location.pathname + location.search;
        handleOnboarding(meta, currentPath, 'videostarted');

        setTimeout(() => {
          history.push({
            search: '',
          });
        }, 2000);
      }

      const params = new URLSearchParams(location.search);
      if (
        params.get('redirect_to') === 'ltd-checkout' &&
        onboardingStep?.value === OnboardingSteps.Step0
      ) {
        history.push('/LTD-Checkout');
      }
    } else if (!showWatchVideoModal && !showGettingStarted) {
      this.showHideGettingStarted(true);
    }
  }

  onTrialExtended = () => {
    const {
      isEligibleToExtendTrial,
      sendExtendTrialPeriodRequest,
    } = this.props;

    if (isEligibleToExtendTrial) {
      sendExtendTrialPeriodRequest(TrialExtendSource.Email);
    }
  };

  getPlanTransitionDetails() {
    const { planTransitionDetails } = this.props;
    return planTransitionDetails;
  }

  updateWatchVideoProfileProgressStep = () => {
    const { profileProgress, updateProfileProgressRequest } = this.props;
    if (profileProgress) {
      const watchVideoStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName === ProfileProgressSteps.WatchVideo,
      );
      if (!watchVideoStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.WatchVideo,
          isCompleted: true,
        });
      }
    }
  };

  updateConnectEmailProfileProgressStep = () => {
    const { profileProgress, updateProfileProgressRequest } = this.props;
    if (profileProgress) {
      const connectEmailStep = profileProgress.find(
        (step) =>
          step.profileProgressStep.stepName ===
          ProfileProgressSteps.ConnectEmail,
      );
      if (!connectEmailStep.isCompleted) {
        updateProfileProgressRequest({
          step: ProfileProgressSteps.ConnectEmail,
          isCompleted: true,
        });
      }
    }
  };

  handleHelpscoutWelcomeMessage = () => {
    const { showHelpscoutWelcomeMessage } = this.state;

    window?.Beacon?.(
      'show-message',
      process.env.REACT_APP_HELPSCOUT_ONBOARDING_MESSAGE_ID,
      {
        delay: 5000,
        force: showHelpscoutWelcomeMessage,
      },
    );
    setTimeout(() => {
      this.showHideHelpscoutWelcomeMessage(false);
    }, 6000);
  };

  initializeHelpScoutChatSupport = () => {
    const { subscriptionDetails, icpStatus, location } = this.props;

    if (
      (subscriptionDetails || icpStatus) &&
      hasPermission(Permissions.CHAT_SUPPORT_SHOW)
    ) {
      initializeIntercom();

      setTimeout(() => {
        const params = new URLSearchParams(location.search);
        const chatOpen = params.get('chatopen');
        if (chatOpen === 'true') {
          openIntercomHome();
        }
      }, 1000);
    }
  };

  initializeCelloReferral = () => {
    initializeCello();
  };

  handleSendVerificationLinkRequest() {
    const { resendVerificationEmailRequest, email } = this.props;

    resendVerificationEmailRequest(email);
  }

  checkPaymentPendingBanner() {
    const { subscriptionDetails, setPageHeaderBannerAlert, t } = this.props;

    if (subscriptionDetails?.paymentActionRequired) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        if (
          subscriptionDetails?.paymentActionType === PaymentActionType.Initial
        ) {
          setPageHeaderBannerAlert({
            bannerType: PageHeaderAlertTypes.PAYMENT_PENDING,
            paymentActionUrl: subscriptionDetails?.paymentActionUrl,
            bannerMessageOne: t('messages.initial_payment_pending_text_one'),
            bannerMessageTwo: t('messages.initial_payment_pending_text_two'),
            ctaText: t('labels.take_action_now_cta'),
            paymentActionType: subscriptionDetails?.paymentActionType,
          });
        } else {
          setPageHeaderBannerAlert({
            bannerType: PageHeaderAlertTypes.PAYMENT_PENDING,
            paymentActionUrl: subscriptionDetails?.paymentActionUrl,
            bannerMessageOne: t('messages.initial_payment_pending_text_one'),
            bannerMessageTwo: t('messages.recurring_payment_pending_text_two'),
            ctaText: t('labels.take_action_now_cta'),
            paymentActionType: subscriptionDetails?.paymentActionType,
          });
        }
      }
    } else {
      setPageHeaderBannerAlert({
        bannerType: PageHeaderAlertTypes.NONE,
      });
    }
  }

  redirectToBlankPage() {
    const { planTransitionDetails, history } = this.props;

    if (isPlanDowngraded(planTransitionDetails)) {
      history.push('/block');
    }
  }

  isUserAlreadyInStepPage() {
    const { sampleSequence, match } = this.props;
    const { params }: any = match;

    if (params) {
      return sampleSequence?.id === +params?.sequenceId;
    }

    return false;
  }

  showHideGettingStarted(value: boolean) {
    this.setState({ showGettingStarted: value });
  }

  showHideWatchVideoModal(value: boolean) {
    const { showGettingStartedVideoModal } = this.props;

    this.setState({ showWatchVideoModal: value });
    showGettingStartedVideoModal(false);
  }

  showHideHelpscoutWelcomeMessage(value: boolean) {
    this.setState({ showHelpscoutWelcomeMessage: value });
  }

  showHideOnboardingModal(value: boolean) {
    this.setState({ showOnboardingModal: value });
  }

  createSequence(source: Source) {
    const { subscriptionDetails, sendCreateSequenceRequest } = this.props;
    if (subscriptionDetails?.planCode === SubscriptionPlans.FREE) {
      if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
        planError(2002);
      } else {
        planError(3002);
      }
    } else if (!hasResource(ResourceIdentifiers.SEQUENCES_CREATE)) {
      this.showHideWatchVideoModal(false);
    } else {
      sendCreateSequenceRequest(getNewSequenceName(), source);
    }
  }

  render() {
    const {
      getUserSettingsRequestStatus,
      meta,
      subscriptionDetails,
      firstName,
      agencyConfig,
      isGettingStartedVideoModalShow,
      getSampleSequenceRequestStatus,
      createSequenceFromGettingStartedRequestStatus,
      sendOnboardingRequest,
      verified,
      onBoardingRequestStatus,
      trackingId,
      userId,
      history,
      isMemberAccount,
      sendFinishOnboardingRequest,
      getAgencyConfigRequestStatus,
    } = this.props;

    const {
      userSubscriptionPlan,
      showOnboardingModal,
      showWatchVideoModal,
    } = this.state;

    const location = history?.location;
    const isLoading =
      getUserSettingsRequestStatus === RequestStatus.Pending ||
      getUserSettingsRequestStatus === RequestStatus.Ideal;

    if (isLoading && isEmpty(meta) && !subscriptionDetails) {
      return null;
    }

    // Classes
    const homeContainerClass = classNames([
      {
        'home-container': userSubscriptionPlan !== SubscriptionPlans.FREE,
      },
      {
        'home-container show-alert':
          userSubscriptionPlan === SubscriptionPlans.FREE,
      },
    ]);

    return (
      <Container fluid className={`p-0 ${!verified ? 'alert-message' : ''}`}>
        {getIsRequestSucceeded(getAgencyConfigRequestStatus) && (
          <HelmetIcon
            icon={agencyConfig?.favicon || defaultAgencyObj.favicon}
          />
        )}

        {/* <HelmetTitle
          isLoading={getIsRequestPending(getAgencyConfigRequestStatus)}
        /> */}
        {/* {agencyConfig?.logo?.favicon !== '' && (
          <HelmetIcon icon={agencyConfig?.logo?.favicon} />
        )} */}
        <ErrorBoundaryWrapper>
          <ThemeProvider prefixes={{ btn: 'btn-solid' }}>
            <Row className="m-0">
              <SideNavbar />
              <main className={homeContainerClass}>
                {!verified && (
                  <Alert
                    variant="alert"
                    description={
                      <span>
                        Check your registered email and authenticate your
                        profile by clicking on the link. If you have not
                        received it,{' '}
                        {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */}
                        <span
                          className="email-verify"
                          role="button"
                          onClick={this.redirectSpamUrl}
                        >
                          check spam folder
                        </span>
                        {' / '}
                        <span
                          className="email-verify"
                          {...accessibleOnClick(
                            this.handleSendVerificationLinkRequest,
                          )}
                        >
                          send again
                        </span>
                        .
                      </span>
                    }
                    className="alert-width email-verify-center"
                  />
                )}
                <Header />
                <Switch>
                  <ConfigRoute path="/leads" title="Leads" component={leads} />
                  <ConfigRoute
                    path="/sequence"
                    title="Sequence"
                    component={Sequence}
                  />
                  <ConfigRoute path="/tasks" title="Tasks" component={Tasks} />
                  <ConfigRoute
                    path="/conversations"
                    title="Unified Inbox"
                    component={UnifiedInbox}
                  />
                  <ConfigRoute
                    path="/clients"
                    title="Client Management"
                    component={AgencyClient}
                  />
                  <ConfigRoute
                    path="/email-accounts"
                    title="Email Accounts"
                    component={EmailAccounts}
                  />
                  <ConfigRoute
                    path="/settings"
                    title="Settings"
                    component={Setting}
                  />
                  <ConfigRoute
                    path="/prospects"
                    title="Prospects"
                    component={Prospect}
                  />
                  <ConfigRoute
                    path="/templates"
                    title="Template"
                    component={Templates}
                  />
                  <ConfigRoute
                    path="/email-insights"
                    title="1:1 Email Insights"
                    component={MailboxEmails}
                  />
                  <ConfigRoute
                    path="/reports"
                    title="Reports"
                    component={Reports}
                  />
                  <ConfigRoute
                    path="/growth-hub"
                    title="Growth Hub"
                    component={growthHub}
                  />
                  <ConfigRoute
                    path="/agency-portal"
                    title="Agency Portal"
                    component={AgencyPortal}
                  />
                  <ConfigRoute
                    path="/warmup"
                    title="Email Warmup"
                    component={EmailWarmup}
                  />
                  <ConfigRoute
                    path="/verify-email"
                    title="Verify Email"
                    component={VerifyEmailCallback}
                  />
                  <ConfigRoute
                    path="/block"
                    title="Block"
                    component={BlockPage}
                  />
                </Switch>

                {/* {agencyConfig?.showGettingStarted && (
                  <GettingStartedOnboarding
                    firstName={firstName}
                    profileProgress={profileProgress}
                    showHideWatchVideoModal={this.showHideWatchVideoModal}
                    handleActivateSequenceTooltip={
                      handleActivateSequenceTooltip
                    }
                    handleAddEmailAccountModal={handleAddEmailAccountModal}
                    createSequence={() =>
                      this.createSequence(Source.OnboardingChecklist)
                    }
                    showGettingStarted={showGettingStarted}
                  />
                )} */}

                {(showWatchVideoModal || isGettingStartedVideoModalShow) &&
                  isWhitelabelWithoutClientView() && (
                    <WatchVideoModal
                      show={
                        showWatchVideoModal || isGettingStartedVideoModalShow
                      }
                      isGettingStartedVideModalShowThroughIcon={
                        showWatchVideoModal
                      }
                      firstName={firstName}
                      onViewSampleSequence={this.onViewSampleSequence}
                      onCreateNewSequence={() =>
                        this.createSequence(Source.OnboardingVideo)
                      }
                      onClose={() => {
                        if (isMemberAccount) {
                          sendFinishOnboardingRequest();
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                          return this.showHideWatchVideoModal(false);
                        }

                        this.showHideWatchVideoModal(false);

                        if (
                          getCurrentRoute(location) !== '/settings/whitelabel'
                        ) {
                          this.showHideOnboardingModal(false);
                          this.handleHelpscoutWelcomeMessage();
                          this.setState({ isViewSequenceBtnClicked: false });
                          history.push({
                            search: 'videoclosed',
                          });
                          window.location.reload();
                          setTimeout(() => {
                            history.push({
                              search: '',
                            });
                          }, 2000);
                        }
                      }}
                      updateWatchVideoProfileProgressStep={
                        this.updateWatchVideoProfileProgressStep
                      }
                      isCreateSequenceRequestPending={getIsRequestPending(
                        createSequenceFromGettingStartedRequestStatus,
                      )}
                      isViewSampleSequenceRequestPending={getIsRequestPending(
                        getSampleSequenceRequestStatus,
                      )}
                    />
                  )}

                <OnboardingModal
                  show={showOnboardingModal}
                  firstName={firstName}
                  sendOnboardingRequest={sendOnboardingRequest}
                  onBoardingRequestStatus={onBoardingRequestStatus}
                  trackingId={trackingId}
                  shAccountId={userId}
                  checkIsLeadFinderICP={(s: boolean) =>
                    this.setState({
                      willRedirectToLeadFinder: s,
                    })
                  }
                />
              </main>
            </Row>
          </ThemeProvider>
        </ErrorBoundaryWrapper>
      </Container>
    );
  }
}

export default Home;
