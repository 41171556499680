import React from 'react';

export const Tag = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 3C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25V7.93934L9.51161 14.4509C9.57862 14.5061 9.66287 14.5365 9.75 14.5365C9.83713 14.5365 9.92138 14.5061 9.98839 14.4509L14.4509 9.98839C14.5061 9.92138 14.5365 9.83713 14.5365 9.75C14.5365 9.66287 14.5061 9.57862 14.4509 9.51161L7.93934 3H5.25ZM2.59835 2.59835C3.30161 1.89509 4.25544 1.5 5.25 1.5H8.25C8.44891 1.5 8.63968 1.57902 8.78033 1.71967L15.5303 8.46967C15.5402 8.47951 15.5497 8.48963 15.559 8.5C15.8665 8.84376 16.0365 9.28879 16.0365 9.75C16.0365 10.2112 15.8665 10.6562 15.559 11C15.5497 11.0104 15.5402 11.0205 15.5303 11.0303L11.0303 15.5303C11.0205 15.5402 11.0104 15.5497 11 15.559C10.6562 15.8665 10.2112 16.0365 9.75 16.0365C9.28879 16.0365 8.84376 15.8665 8.5 15.559C8.48963 15.5497 8.47951 15.5402 8.46967 15.5303L1.71967 8.78033C1.57902 8.63968 1.5 8.44891 1.5 8.25V5.25C1.5 4.25544 1.89509 3.30161 2.59835 2.59835Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.75 6.00586C6.33579 6.00586 6 6.34165 6 6.75586C6 7.17007 6.33579 7.50586 6.75 7.50586C7.16421 7.50586 7.5 7.17007 7.5 6.75586C7.5 6.34165 7.16421 6.00586 6.75 6.00586ZM4.5 6.75586C4.5 5.51322 5.50736 4.50586 6.75 4.50586C7.99264 4.50586 9 5.51322 9 6.75586C9 7.9985 7.99264 9.00586 6.75 9.00586C5.50736 9.00586 4.5 7.9985 4.5 6.75586Z"
      fill="currentColor"
    />
  </svg>
);
