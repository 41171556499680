import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { getActivityIcon } from '../../../../utils/get-prospect-icon';
import MasterLog from '../../../../enums/master-log-enum';
import IconAndLog from '../../atoms/icon-and-log';

const getIconForActivity = (key: MasterLog) => getActivityIcon(key);

const ActivityComponent = ({ dayText, activity }) => (
  <div className="mt-4 log-activity">
    <span className="day-text">{dayText}</span>

    {activity.map((activityObj: { log: string; key: MasterLog }) => {
      const parsed = sanitizeHtml(activityObj.log, {
        allowedTags: [
          'b',
          'i',
          'em',
          'strong',
          'a',
          'br',
          'img',
          'div',
          'span',
          'p',
        ],
        allowedAttributes: {
          a: ['href', 'target'],
          img: ['src', 'height'],
          div: ['class'],
          strong: ['class'],
          span: ['class'],
        },
      });
      return (
        <IconAndLog
          key={activityObj.key}
          icon={getIconForActivity(activityObj.key)}
          log={parsed}
          type={activityObj.key}
        />
      );
    })}
  </div>
);

export default ActivityComponent;
