import React from 'react';
import { produce } from 'immer';
import { Button } from '@saleshandy/design-system';
import Input from '../../../../../../shared/design-system/components/input';
import Select from '../../../../../../shared/design-system/components/select';
import ChangePasswordModal from '../change-password-modal';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { IState, IProps, TimezoneOption } from './types';
import toaster, { Theme } from '../../../../../../shared/toaster';
import { UpdatePasswordRequestPayload } from '../change-password-modal/types';
import { validate } from './validator';
import { UpdateUserProfilePayload } from '../../../../types/request-payload';
import { getTimezoneList } from '../../../../../../shared/utils/date-time';
import TimezoneOptionRenderer from '../../../../../../shared/design-system/components/atoms/timezone-option-renderer';
import HeaderBanner from '../../../header-banner';
import { SubscriptionPlans } from '../../../../../../shared/utils/subscription-plans';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import ChangeEmailModal from '../change-email-modal';
import DeleteAccountModal from '../delete-account-modal';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../shared/design-system/components/overlay';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

class MyProfileContent extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const { myProfile } = props;
    this.state = {
      values: {
        firstName: myProfile.firstName,
        lastName: myProfile.lastName,
      },
      errors: {
        firstName: '',
        lastName: '',
      },
      dirty: {
        firstName: false,
        lastName: false,
      },
      timeZoneKey: this.getTimeZoneOptions().find(
        (item) => item.name.toLowerCase() === myProfile.timeZone.toLowerCase(),
      ).name,
      changeEmailModal: false,
      showChangePasswordModal: false,
      deleteAccountModal: false,
    };

    this.onInputBlur = this.onInputBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.getTimeZoneOptions = this.getTimeZoneOptions.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.showChangePasswordModal = this.showChangePasswordModal.bind(this);
    this.hideChangePasswordModal = this.hideChangePasswordModal.bind(this);
    this.changePasswordHandler = this.changePasswordHandler.bind(this);
    this.showChangeEmailModal = this.showChangeEmailModal.bind(this);
    this.hideChangeEmailModal = this.hideChangeEmailModal.bind(this);
    this.showDeleteAccountModal = this.showDeleteAccountModal.bind(this);
    this.hideDeleteAccountModal = this.hideDeleteAccountModal.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const {
      updatePasswordRequestStatus,
      updatePasswordRequestMessage,
      updatePasswordRequestError,
      updateMyProfileRequestError,
      updateMyProfileRequestMessage,
      updateMyProfileRequestStatus,
      getMyProfileRequest,
    } = this.props;

    if (updatePasswordRequestStatus !== prevProps.updatePasswordRequestStatus) {
      if (updatePasswordRequestStatus === RequestStatus.Succeeded) {
        toaster.success(updatePasswordRequestMessage, { theme: Theme.New });
        this.hideChangePasswordModal();
      }

      if (
        updatePasswordRequestStatus === RequestStatus.Failed &&
        updatePasswordRequestError
      ) {
        toaster.error(updatePasswordRequestError.message, { theme: Theme.New });
      }
    }

    if (
      updateMyProfileRequestStatus !== prevProps.updateMyProfileRequestStatus
    ) {
      if (updateMyProfileRequestStatus === RequestStatus.Succeeded) {
        toaster.success(updateMyProfileRequestMessage, { theme: Theme.New });
        getMyProfileRequest();
      }

      if (
        updateMyProfileRequestStatus === RequestStatus.Failed &&
        updateMyProfileRequestError
      ) {
        toaster.error(updateMyProfileRequestError.message, {
          theme: Theme.New,
        });
      }
    }
  }

  onInputChange(value: string, event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;
    this.setState(
      produce((draft: IState) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e: React.FocusEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(
            name,
            draft.values[name],
            hasPermission(Permissions.MY_PROFILE_UPDATE),
          );
        }
      }),
    );
  }

  onSelectChange(selectedOption: TimezoneOption) {
    this.setState({ timeZoneKey: selectedOption.key });
  }

  onFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { dirty, errors, values, timeZoneKey } = this.state;
    const { sendUpdateMyProfileRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirty);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errors);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(
        key,
        values[key],
        hasPermission(Permissions.MY_PROFILE_UPDATE),
      );
      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (isError) {
      this.setState({ errors, dirty });
      return;
    }

    const timeZone = this.getTimeZoneOptions().find(
      (tz) => tz.key === timeZoneKey,
    ).name;

    const payload: UpdateUserProfilePayload = {
      ...values,
      timeZone,
    };

    sendUpdateMyProfileRequest(payload);
  }

  getTimeZoneOptions = () => {
    const timeZones = getTimezoneList();
    return timeZones.map((item) => ({
      key: item.name,
      ...item,
    }));
  };

  deleteAccountButton = () => (
    <Button
      type="submit"
      variant="secondary"
      className="my-profile-delete-btn"
      onClick={this.showDeleteAccountModal}
      disabled={!hasPermission(Permissions.ACCOUNT_DATA_DELETE)}
    >
      Delete Account
    </Button>
  );

  showChangeEmailModal() {
    this.setState({ changeEmailModal: true });
  }

  hideChangeEmailModal() {
    this.setState({ changeEmailModal: false });
  }

  showDeleteAccountModal() {
    this.setState({ deleteAccountModal: true });
  }

  hideDeleteAccountModal() {
    this.setState({ deleteAccountModal: false });
  }

  showChangePasswordModal(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();
    this.setState({ showChangePasswordModal: true });
  }

  hideChangePasswordModal() {
    this.setState({ showChangePasswordModal: false });
  }

  changePasswordHandler(payload: UpdatePasswordRequestPayload) {
    const { sendUpdatePasswordRequest } = this.props;
    sendUpdatePasswordRequest(payload);
  }

  render() {
    const {
      values,
      errors,
      timeZoneKey,
      changeEmailModal,
      showChangePasswordModal,
      deleteAccountModal,
    } = this.state;
    const {
      myProfile,
      subscriptionPlan,
      updateMyProfileRequestStatus,
      updatePasswordRequestStatus,
    } = this.props;

    const isUpdateMyProfileRequestStatusPending =
      updateMyProfileRequestStatus === RequestStatus.Pending;
    const isUpdatePasswordRequestStatusPending =
      updatePasswordRequestStatus === RequestStatus.Pending;

    return (
      <>
        <GlobalSettings.Header className="globalSettings__Header--margin">
          <GlobalSettings.HeaderLeft>
            <GlobalSettings.HeaderTitle title="Profile" />
          </GlobalSettings.HeaderLeft>
        </GlobalSettings.Header>
        {subscriptionPlan === SubscriptionPlans.FREE && <HeaderBanner />}
        <GlobalSettings.Content className="profile-container">
          <div className="form-container">
            <form onSubmit={this.onFormSubmit}>
              <Input
                name="firstName"
                label="First name"
                placeholder="Enter your first name"
                value={values.firstName}
                variant={errors.firstName && Input.Variant.Error}
                caption={errors.firstName}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="current-firstName"
                autoFocus
                disabled={!hasPermission(Permissions.MY_PROFILE_UPDATE)}
                className="my-profile-input"
              />
              <Input
                name="lastName"
                label="Last name"
                placeholder="Enter your last name"
                value={values.lastName}
                variant={errors.lastName && Input.Variant.Error}
                caption={errors.lastName}
                onChange={this.onInputChange}
                onBlur={this.onInputBlur}
                autoComplete="current-lastName"
                disabled={!hasPermission(Permissions.MY_PROFILE_UPDATE)}
                className="my-profile-input"
              />

              <div className="input-with-cta-wrapper">
                <Input
                  name="email"
                  label="Email"
                  disabled={true}
                  value={myProfile.email}
                  className="my-profile-input"
                />
                {hasPermission(Permissions.CREDENTIALS_UPDATE) && (
                  <Button
                    variant="secondary"
                    className="change-email-btn"
                    onClick={this.showChangeEmailModal}
                  >
                    Change Email
                  </Button>
                )}
              </div>

              {hasPermission(Permissions.MY_PROFILE_UPDATE) && (
                <div className="input-with-cta-wrapper">
                  <Input
                    name="password"
                    label="Password"
                    disabled={true}
                    value="************"
                    className="my-profile-input"
                  />

                  <Button
                    variant="secondary"
                    className="change-email-btn password-btn"
                    onClick={this.showChangePasswordModal}
                  >
                    Change Password
                  </Button>
                </div>
              )}

              <div className="my-profile-input timezone-wrapper">
                <span className="bs-input-label-prefix">Time Zone</span>
                <Select<TimezoneOption>
                  showSearch
                  className="timezone-select"
                  filterOption={(value, option) =>
                    option.name.toLowerCase().includes(value.toLowerCase())
                  }
                  selectedOptionRenderer={([option]) => (
                    <span>{option?.name}</span>
                  )}
                  optionRenderer={(option) => (
                    <TimezoneOptionRenderer timezone={option?.value} />
                  )}
                  options={this.getTimeZoneOptions()}
                  placeholder="Select Time Zone"
                  selectedOptionKey={timeZoneKey}
                  onChange={([selectedOption]) =>
                    this.onSelectChange(selectedOption)
                  }
                />
              </div>

              <Button
                type="submit"
                className="my-profile-submit-btn"
                loadingText="Saving..."
                isLoading={isUpdateMyProfileRequestStatusPending}
                disabled={isUpdateMyProfileRequestStatusPending}
              >
                Save
              </Button>
            </form>
          </div>

          {hasPermission(Permissions.ACCOUNT_PROFILE_DANGER_ZONE) && (
            <>
              <div className="danger-zone-header">
                <h1>Danger Zone</h1>
                <p>Delete your Saleshandy account permanently.</p>
              </div>

              {hasPermission(Permissions.ACCOUNT_DATA_DELETE) ? (
                this.deleteAccountButton()
              ) : (
                <OverlayTooltip
                  text="Your Saleshandy account must be on a free plan if you want to delete it."
                  placement={Placement.Right}
                  className="tooltip-xxl"
                >
                  {this.deleteAccountButton()}
                </OverlayTooltip>
              )}
            </>
          )}

          <ChangeEmailModal
            show={changeEmailModal}
            onClose={this.hideChangeEmailModal}
          />

          {hasPermission(Permissions.MY_PROFILE_UPDATE) &&
            showChangePasswordModal && (
              <ChangePasswordModal
                show={showChangePasswordModal}
                onClose={this.hideChangePasswordModal}
                onSubmit={this.changePasswordHandler}
                isLoading={isUpdatePasswordRequestStatusPending}
              />
            )}

          <DeleteAccountModal
            show={deleteAccountModal}
            onClose={this.hideDeleteAccountModal}
          />
        </GlobalSettings.Content>
      </>
    );
  }
}

export default MyProfileContent;
