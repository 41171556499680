/* eslint-disable radix */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { LayoutColumns } from '@saleshandy/icons';
import { IProps, ColumnsTogglerOption } from './types';
import { Placement } from '../../../overlay';
import Transfer from '../../../transfer';
import Checkbox from '../../../atoms/checkbox';
import Button from '../../../atoms/button';

const convertNumberToString = (arr: number[]): string[] =>
  arr.map((v) => v && v.toString());

const convertStringToNumber = (arr: string[]): number[] =>
  arr.map((v) => v && parseInt(v, 10));

const ColumnsSelector: React.FC<IProps> = ({
  columns,
  selectedColumnsIds,
  onColumnSelect,
  t,
}) => {
  const [show, setShow] = useState(false);
  const [selectedColumnsKeys, setSelectedColumnsKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedColumnsKeys(convertNumberToString(selectedColumnsIds));
  }, [show, selectedColumnsIds]);

  const options: ColumnsTogglerOption[] = columns.map(
    ({ id, text = '', isRequiredColumn = false }) => ({
      key: id?.toString(),
      text,
      isDisabled: isRequiredColumn,
    }),
  );

  return (
    <Transfer<ColumnsTogglerOption>
      showSearch
      className="columns-toggler-select"
      multiSelect
      placement={Placement.BottomEnd}
      options={options}
      selectedOptionKey={selectedColumnsKeys}
      optionFilterProp="key"
      filterOption={(value, option) =>
        option?.key?.toLowerCase().includes(value.toLowerCase())
      }
      customFilterOption={(_options, searchValue) =>
        _options.filter((option) =>
          option?.text?.toLowerCase().includes(searchValue?.toLowerCase()),
        )
      }
      searchPlaceholder="Search Column type"
      optionRenderer={(option) => (
        <span className="columns-name">{option.text}</span>
      )}
      onChange={(selectedOptions) => {
        setSelectedColumnsKeys(selectedOptions.map((option) => option?.key));
      }}
      showOptionsSeparator={true}
      header={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Checkbox
            checked={selectedColumnsKeys.length === options.length}
            intermediate={selectedColumnsKeys.length !== 0}
            label={t('labels.select_all')}
            onChange={(checked) => {
              if (checked) {
                setSelectedColumnsKeys(options.map((option) => option?.key));
              } else {
                const arr = options.filter(({ isDisabled }) => isDisabled);
                setSelectedColumnsKeys(arr.map((option) => option.key));
              }
            }}
          />
          <Button
            variant={Button.Variant.Outlined}
            size={Button.Size.Small}
            className="btn-linked-primary"
            onClick={() => {
              onColumnSelect(convertStringToNumber(selectedColumnsKeys));
              setShow(false);
            }}
          >
            {t('labels.apply')}
          </Button>
        </div>
      }
      show={show}
      onToggle={(value) => setShow(value)}
    >
      <LayoutColumns
        width={20}
        height={20}
        className={`pointer ${
          show ? 'blue-txt-11' : 'popover-arrow-color-txt'
        }`}
      />
    </Transfer>
  );
};

export default withTranslation()(ColumnsSelector);
