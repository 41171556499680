import { constants } from '../../../../enums/constants';
import { PaginationShowHide, ShowingLengthPerPageOption } from './types';

export const getFinalColumns = ({
  actions,
  dataColumns,
  generateActionsColumn,
  headerVisibleForGenerateColumn,
  borderOverActions,
  showColumnsToggler,
  generateTogglerColumn,
  showExpandColumn,
  generateExpandColumn,
}) => {
  let columns: any[] = dataColumns;

  if (actions) {
    columns = [
      ...columns,
      generateActionsColumn(
        actions,
        headerVisibleForGenerateColumn,
        borderOverActions,
      ),
    ];
  }

  if (showExpandColumn) {
    columns.unshift(generateExpandColumn());
  }

  if (showColumnsToggler) {
    columns.push(generateTogglerColumn());
  }

  columns = columns.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.dataField === value.dataField),
  );

  return columns;
};

export const getTotalSize = ({ paginationOptions, sequenceFixedPageSize }) => {
  let totalSize;

  if (paginationOptions.pagesCount) {
    totalSize = paginationOptions.count;
  } else {
    totalSize = paginationOptions.options.totalElements;
  }
  if (!totalSize) {
    totalSize = sequenceFixedPageSize
      ? constants.DEFAULT_SEQUENCE_PAGE_SIZE
      : constants.DEFAULT_PAGE_SIZE;
  }

  return totalSize;
};

export const getShouldShowPagination = ({
  pagination,
  totalSize,
  isNewPagination,
  sequenceFixedPageSize,
  fixedPageSize,
}) => {
  // for new pagination the initial per page limit is 50
  if (isNewPagination && totalSize <= constants.PROSPECT_PAGE_SIZE) {
    return false;
  }

  // we will not show pagination in case  totalSize is less then 25 because we don't require pagination there(only 1 page is sufficient)
  let defaultSize = sequenceFixedPageSize
    ? constants.DEFAULT_SEQUENCE_PAGE_SIZE
    : constants.DEFAULT_PAGE_SIZE;

    if(fixedPageSize){
      defaultSize = fixedPageSize || defaultSize;
    }

  if (totalSize <= defaultSize) {
    return false;
  }

  if (pagination === PaginationShowHide.SHOW) {
    return true;
  }

  if (pagination === PaginationShowHide.HIDE) {
    return false;
  }

  return null;
};

export const getKeyAndId = (id) => id || null;

export const getNoDataClassName = (data) =>
  data?.length === 0 ? 'no-data' : '';

export const showingLengthPerPageOptions: ShowingLengthPerPageOption[] = [
  {
    key: '25',
    value: 25,
  },
  {
    key: '50',
    value: 50,
  },
  {
    key: '100',
    value: 100,
  },
  {
    key: '250',
    value: 250,
  },
  {
    key: '500',
    value: 500,
  },
  {
    key: '1000',
    value: 1000,
  },
];
