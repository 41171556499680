import React, { ReactNode } from 'react';
import { Mail, Checkbox, Signal, Whatsapp, Linkedin } from '@saleshandy/icons';
import { StepType } from '../enums';
import { isMultichannelActive } from '../../../shared/utils/user-details';

type StepTypes = {
  label: StepType;
  icon: ReactNode;
  bgColor: string;
  isHide?: boolean;
};

export const stepBgColor = {
  [StepType.Email]: '#3B82F6',
  [StepType.LinkedIn]: '#178ACF',
  [StepType.Call]: '#8B5CF6',
  [StepType.WhatsApp]: '#65D072',
  [StepType.Task]: '#F97316',
};

export const getStepTypeArray = (
  usedThemeColor = false,
  iconColor = '#FFFFFF',
  iconWidth = 18,
  iconHeight = 18,
): StepTypes[] => [
  {
    label: StepType.Email,
    icon: (
      <Mail
        width={iconWidth}
        height={iconHeight}
        color={usedThemeColor ? stepBgColor[StepType.Email] : iconColor}
      />
    ),
    bgColor: stepBgColor[StepType.Email],
    isHide: false,
  },
  {
    label: StepType.LinkedIn,
    icon: (
      <Linkedin
        width={iconWidth}
        height={iconHeight}
        color={usedThemeColor ? stepBgColor[StepType.LinkedIn] : iconColor}
      />
    ),
    bgColor: stepBgColor[StepType.LinkedIn],
    isHide: !isMultichannelActive(),
  },
  {
    label: StepType.Call,
    icon: (
      <Signal
        width={iconWidth}
        height={iconHeight}
        color={usedThemeColor ? stepBgColor[StepType.Call] : iconColor}
      />
    ),
    bgColor: stepBgColor[StepType.Call],
    isHide: !isMultichannelActive(),
  },
  // {
  //   label: StepType.WhatsApp,
  //   icon: (
  //     <Whatsapp
  //       width={iconWidth}
  //       height={iconHeight}
  //       color={usedThemeColor ? stepBgColor[StepType.WhatsApp] : iconColor}
  //     />
  //   ),
  //   bgColor: stepBgColor[StepType.WhatsApp],
  //   isHide: !isMultichannelActive(),
  // },
  {
    label: StepType.Task,
    icon: (
      <Checkbox
        width={iconWidth}
        height={iconHeight}
        color={usedThemeColor ? stepBgColor[StepType.Task] : iconColor}
      />
    ),
    bgColor: stepBgColor[StepType.Task],
    isHide: !isMultichannelActive(),
  },
];
