import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';

import {
  CreateAndTestWebhookPayload,
  GetWebhookActivityDetailsPayload,
  GetWebhookActivityLogsPayload,
  GetWebhookListPayload,
  UpdateWebhookPayload,
  UpdateWebhookStatusPayload,
} from '../../types/webhook';

import {
  createWebhook,
  deleteWebhook,
  getWebhookList,
  getWebhook,
  resendFailedWebhookEvent,
  testWebhook,
  updateWebhook,
  updateWebhookStatus,
  getWebhookActivityLogs,
  getWebhookActivityDetails,
} from '../../helpers/webhook.api';

export const getWebhookRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/getWebhookRequest', async (payload, thunkAPI) => {
  try {
    return await getWebhook(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getWebhookListRequest = createAsyncThunk<
  ResponseSuccess,
  GetWebhookListPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/getWebhookListRequest', async (payload, thunkAPI) => {
  try {
    return await getWebhookList(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateWebhookStatusRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateWebhookStatusPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/updateWebhookStatusRequest', async (payload, thunkAPI) => {
  try {
    return await updateWebhookStatus(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const createWebhookRequest = createAsyncThunk<
  ResponseSuccess,
  CreateAndTestWebhookPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/createWebhookRequest', async (payload, thunkAPI) => {
  try {
    return await createWebhook(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateWebhookRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateWebhookPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/updateWebhookRequest', async (payload, thunkAPI) => {
  try {
    return await updateWebhook(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const testWebhookRequest = createAsyncThunk<
  ResponseSuccess,
  CreateAndTestWebhookPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/testWebhookRequest', async (payload, thunkAPI) => {
  try {
    return await testWebhook(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteWebhookRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/deleteWebhookRequest', async (payload, thunkAPI) => {
  try {
    return await deleteWebhook(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getWebhookActivityLogsRequest = createAsyncThunk<
  ResponseSuccess,
  GetWebhookActivityLogsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/getWebhookActivityLogsRequest', async (payload, thunkAPI) => {
  try {
    return await getWebhookActivityLogs(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getWebhookActivityDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  GetWebhookActivityDetailsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/getWebhookActivityDetailsRequest', async (payload, thunkAPI) => {
  try {
    return await getWebhookActivityDetails(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const resendFailedWebhookEventRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('webhook/resendFailedWebhookEventRequest', async (payload, thunkAPI) => {
  try {
    return await resendFailedWebhookEvent(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
