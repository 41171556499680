import { WebhookStatus } from '../../../enums/webhook';

export const getWebhookStatusFieldValues = (status: WebhookStatus) => {
  let className = 'pause-status';
  let tooltip = 'Active';
  let checked = false;

  if (status === WebhookStatus.Active) {
    className = 'active-status';
    tooltip = 'Pause';
    checked = true;
  }

  return {
    className,
    tooltip,
    checked,
  };
};
