import React from 'react';

export const NoUpcomingTrips = () => (
  <svg
    width="396"
    height="393"
    viewBox="0 0 396 393"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="No Upcoming Trips">
      <g id="Group 3">
        <mask
          id="mask0_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="36"
          y="85"
          width="333"
          height="190"
        >
          <path
            id="Clip 2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.563 118.592H76.1071C70.5904 118.592 66.0782 123.119 66.0782 128.653C66.0782 134.188 70.5904 138.714 76.1071 138.714H97.7133H104.045C109.562 138.714 114.074 143.243 114.074 148.777C114.074 151.543 112.943 154.063 111.125 155.88C109.314 157.706 106.801 158.839 104.045 158.839H90.5366H83.0784H46.6893C41.1726 158.839 36.6604 163.368 36.6604 168.901C36.6604 174.433 41.1726 178.962 46.6893 178.962H72.7402C64.8758 197.504 60.5205 217.907 60.5205 239.334C60.5205 247.108 61.0954 254.748 62.1981 262.213C62.6586 265.332 63.2143 268.425 63.864 271.484C82.7851 268.58 102.856 266.244 123.81 264.54L125.386 265.983L126.87 264.3C152.044 262.335 178.466 261.288 205.718 261.288C263.618 261.288 317.799 266.01 364.125 274.22C365.911 266.454 367.115 258.463 367.684 250.294C367.943 246.672 368.071 243.016 368.071 239.334C368.071 154.123 299.227 85.0522 214.296 85.0522C178.099 85.0522 144.831 97.6004 118.563 118.592Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_23230_99856)">
          <path
            id="Fill 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M29.3283 281.552H375.403V77.7202H29.3283V281.552Z"
            fill="#F1F2F7"
          />
        </g>
      </g>
      <g id="Group 6">
        <mask
          id="mask1_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="158"
          width="27"
          height="21"
        >
          <path
            id="Clip 5"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.1579 158.373C4.57082 158.373 0 162.994 0 168.638C0 174.282 4.57082 178.903 10.1579 178.903H16.2362C21.8247 178.903 26.3955 174.282 26.3955 168.638C26.3955 162.994 21.8247 158.373 16.2362 158.373H10.1579Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_23230_99856)">
          <path
            id="Fill 4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M-7.33209 186.235H33.7276V151.041H-7.33209V186.235Z"
            fill="#F1F2F7"
          />
        </g>
      </g>
      <g id="Group 9">
        <mask
          id="mask2_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="63"
          y="261"
          width="333"
          height="132"
        >
          <path
            id="Clip 8"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M126.647 264.502L125.162 266.175L123.585 264.741C102.638 266.435 82.5725 268.753 63.6543 271.641C78.3932 340.976 140.129 393 214.05 393C248.662 393 280.599 381.588 306.301 362.339H333.66C339.174 362.339 343.686 357.838 343.686 352.341C343.686 349.596 342.555 347.088 340.738 345.283C338.926 343.469 336.412 342.343 333.66 342.343H321.761C316.246 342.343 311.734 337.844 311.734 332.345C311.734 329.598 312.865 327.092 314.682 325.285C316.494 323.471 319.007 322.347 321.761 322.347H385.804C391.32 322.347 395.83 317.848 395.83 312.347C395.83 309.6 394.7 307.094 392.883 305.289C391.064 303.475 388.563 302.349 385.804 302.349H354.401C358.412 293.438 361.594 284.079 363.845 274.361C317.529 266.203 263.361 261.509 205.474 261.509C178.23 261.509 151.812 262.549 126.647 264.502Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_23230_99856)">
          <path
            id="Fill 7"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.3222 400.332H403.161V254.177H56.3222V400.332Z"
            fill="#E8EBF2"
          />
        </g>
      </g>
      <g id="Group 12">
        <mask
          id="mask3_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="126"
          y="243"
          width="241"
          height="32"
        >
          <path
            id="Clip 11"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M336.946 262.935L321.464 248.744L304.896 261.041L297.951 251.473L270.535 261.041L207.378 251.473L170.666 261.609C181.892 261.234 193.297 261.041 204.854 261.041C262.677 261.041 316.787 265.853 363.053 274.22C364.833 266.304 366.037 258.163 366.604 249.838L361.364 243.425L336.946 262.935ZM126.112 264.111C140.577 262.958 155.454 262.116 170.666 261.609L143.546 243.912L126.112 264.111Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask3_23230_99856)">
          <path
            id="Fill 10"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M118.78 281.552H373.937V236.093H118.78V281.552Z"
            fill="#E0E2EE"
          />
        </g>
      </g>
      <g id="Group 15">
        <mask
          id="mask4_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="123"
          y="263"
          width="4"
          height="3"
        >
          <path
            id="Clip 14"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.179 264.166L124.69 265.423L126.112 263.955C125.132 264.02 124.151 264.09 123.179 264.166Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask4_23230_99856)">
          <path
            id="Fill 13"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.847 272.754H133.444V256.623H115.847V272.754Z"
            fill="#F1F2F7"
          />
        </g>
      </g>
      <g id="Group 18">
        <mask
          id="mask5_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="61"
          y="237"
          width="63"
          height="35"
        >
          <path
            id="Clip 17"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.5895 262.002C62.05 265.127 62.6058 268.224 63.2525 271.287C82.1678 268.379 102.233 266.04 123.179 264.334L94.0106 237.56L61.5895 262.002Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask5_23230_99856)">
          <path
            id="Fill 16"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.2574 278.619H130.511V230.228H54.2574V278.619Z"
            fill="#E0E2EE"
          />
        </g>
      </g>
      <g id="Group 21">
        <mask
          id="mask6_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="159"
          y="319"
          width="115"
          height="11"
        >
          <path
            id="Clip 20"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M159.425 324.398C159.425 327.231 185.03 329.531 216.616 329.531C248.202 329.531 273.807 327.231 273.807 324.398C273.807 321.565 248.202 319.266 216.616 319.266C185.03 319.266 159.425 321.565 159.425 324.398Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask6_23230_99856)">
          <path
            id="Fill 19"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152.095 336.863H281.14V311.933H152.095V336.863Z"
            fill="#D8DBEA"
          />
        </g>
      </g>
      <g id="Group 24">
        <mask
          id="mask7_23230_99856"
          style={{
            maskType: 'luminance',
          }}
          maskUnits="userSpaceOnUse"
          x="353"
          y="341"
          width="21"
          height="22"
        >
          <path
            id="Clip 23"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M353.407 351.94C353.407 357.586 358.026 362.205 363.672 362.205C369.317 362.205 373.937 357.586 373.937 351.94C373.937 346.296 369.317 341.675 363.672 341.675C358.026 341.675 353.407 346.296 353.407 351.94Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask7_23230_99856)">
          <path
            id="Fill 22"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M346.075 369.537H381.269V334.343H346.075V369.537Z"
            fill="#E1E3EF"
          />
        </g>
      </g>
      <g id="Group 47">
        <path
          id="Fill 25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M215.105 199.772C210.33 199.772 206.459 195.901 206.459 191.126C206.459 186.353 210.33 182.483 215.105 182.483C219.879 182.483 223.751 186.353 223.751 191.126C223.751 195.901 219.879 199.772 215.105 199.772ZM215.104 178.903C208.353 178.903 202.878 184.376 202.878 191.127C202.878 197.881 208.353 203.354 215.104 203.354C221.855 203.354 227.331 197.881 227.331 191.127C227.331 184.376 221.855 178.903 215.104 178.903Z"
          fill="#D8DBEA"
        />
        <path
          id="Fill 27"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M248.239 305.748H181.973C175.374 305.748 170.021 300.398 170.021 293.798V232.859C170.021 209.067 189.49 189.6 213.281 189.6H216.929C240.723 189.6 260.189 209.067 260.189 232.859V293.798C260.189 300.398 254.839 305.748 248.239 305.748"
          fill="#D8DBEA"
        />
        <path
          id="Fill 29"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M265.619 291.23H264.971C262.34 291.23 260.189 289.077 260.189 286.448V252.456C260.189 249.826 262.34 247.674 264.971 247.674H271.454C274.083 247.674 276.234 249.826 276.234 252.456V280.613C276.234 286.452 271.457 291.23 265.619 291.23"
          fill="url(#paint0_linear_23230_99856)"
          fillOpacity="0.4"
        />
        <path
          id="Fill 31"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M165.24 291.23H164.592C158.751 291.23 153.974 286.452 153.974 280.613V252.456C153.974 249.825 156.125 247.674 158.756 247.674H165.24C167.87 247.674 170.021 249.825 170.021 252.456V286.448C170.021 289.077 167.87 291.23 165.24 291.23"
          fill="url(#paint1_linear_23230_99856)"
          fillOpacity="0.4"
        />
        <path
          id="Fill 33"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.376 289.301L214.339 298.489L185.301 289.301V269.451H243.376V289.301Z"
          fill="#BEC0D6"
        />
        <path
          id="Fill 35"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M197.052 294.582L195.69 295.94C194.558 297.068 192.725 297.068 191.591 295.94L190.231 294.582V281.887H197.052V294.582Z"
          fill="url(#paint2_linear_23230_99856)"
          fillOpacity="0.4"
        />
        <path
          id="Fill 37"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M195.02 292.348C195.02 293.111 194.402 293.726 193.641 293.726C192.879 293.726 192.261 293.111 192.261 292.348C192.261 291.585 192.879 290.97 193.641 290.97C194.402 290.97 195.02 291.585 195.02 292.348"
          fill="#F7FFF6"
        />
        <path
          id="Fill 39"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M236.024 294.582L234.662 295.94C233.53 297.068 231.697 297.068 230.563 295.94L229.202 294.582V281.887H236.024V294.582Z"
          fill="url(#paint3_linear_23230_99856)"
          fillOpacity="0.4"
        />
        <path
          id="Fill 41"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.991 292.348C233.991 293.111 233.374 293.726 232.613 293.726C231.85 293.726 231.233 293.111 231.233 292.348C231.233 291.585 231.85 290.97 232.613 290.97C233.374 290.97 233.991 291.585 233.991 292.348"
          fill="#F7FFF6"
        />
        <path
          id="Fill 43"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M255.859 235.095V241.559V254.978L179.315 218.318C184.404 205.311 197.119 196.05 211.867 196.05H218.347C237.572 196.05 255.859 215.854 255.859 235.095"
          fill="white"
        />
        <path
          id="Fill 45"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M179.313 238.474V232.01V218.591L255.857 255.253C250.77 268.258 238.054 277.519 223.305 277.519H216.826C197.6 277.519 179.313 257.715 179.313 238.474"
          fill="url(#paint4_linear_23230_99856)"
          fillOpacity="0.4"
        />
      </g>
      <g id="Bee">
        <path
          id="Stroke 3"
          d="M239.728 61.5896C188.931 65.8896 170.262 108.886 185.556 133.949C196.348 151.632 214.636 175.531 248.726 169.798C282.814 164.065 285.686 132.517 267.733 128.226C249.78 123.936 185.823 143.949 193.051 190.185C198.269 223.544 243.495 233.16 243.495 233.16"
          stroke="#D7DAE9"
          strokeWidth="2"
          strokeDasharray="7"
        />
        <path
          id="Fill 5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M269.089 35.194C269.089 35.194 276.368 16.1798 277.135 8.78312C277.539 4.9181 271.134 -5.84295 263.638 4.11102C256.141 14.065 259.561 26.2698 269.089 35.194"
          fill="#D8DBEA"
        />
        <path
          id="Fill 7_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.26 14.4863C234.733 11.6026 230.873 13.8509 229.228 18.3386C227.582 22.8278 229.88 31.3727 242.741 33.3205C255.603 35.2684 268.354 35.1936 268.354 35.1936C268.354 35.1936 251.787 17.3699 243.26 14.4863"
          fill="#D8DBEA"
        />
        <path
          id="Fill 9"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M267.645 38.9615C267.838 38.1667 268.094 37.405 268.38 36.6604C266.805 36.9091 265.204 37.2584 263.591 37.7312C261.843 38.2429 260.21 38.9141 258.675 39.6831C258.009 40.9421 257.469 42.2874 257.119 43.7319C254.958 52.6245 260.059 61.5874 268.63 64.5224C269.558 64.3384 270.491 64.1257 271.425 63.8526C274.035 63.088 276.443 62.0761 278.619 60.9033C270.249 57.231 265.432 48.0668 267.645 38.9615"
          fill="#D8DBEA"
        />
        <path
          id="Fill 11"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M255.408 43.5991C255.613 42.7206 255.891 41.8805 256.199 41.0597C248.784 45.8101 244.631 53.1826 245.316 57.4819C240.578 59.5185 237.56 61.234 237.56 61.234C237.56 61.234 241.135 60.9619 246.344 59.9607C249.134 64.0381 256.869 66.7683 265.422 65.7892C257.674 61.7827 253.301 52.665 255.408 43.5991"
          fill="#D8DBEA"
        />
        <path
          id="Fill 13_2"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M279.964 46.8228C278 46.3514 276.782 44.3783 277.255 42.4021C277.726 40.4396 279.712 39.2234 281.674 39.6947C283.648 40.1691 284.854 42.1498 284.384 44.1139C283.909 46.0886 281.939 47.2957 279.964 46.8228ZM288.57 44.1829C286.989 38.7075 279.721 35.8798 271.146 36.8481C270.788 37.6966 270.483 38.5916 270.265 39.5186C268.356 47.6459 272.766 55.8211 280.246 58.6567C286.487 54.6863 289.991 49.0643 288.57 44.1829Z"
          fill="#D8DBEA"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_23230_99856"
        x1="268.212"
        y1="225.896"
        x2="239.955"
        y2="236.306"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34D399" />
        <stop offset="1" stopColor="#3A93E4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23230_99856"
        x1="161.997"
        y1="225.896"
        x2="133.739"
        y2="236.307"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34D399" />
        <stop offset="1" stopColor="#3A93E4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_23230_99856"
        x1="193.641"
        y1="274.438"
        x2="182.363"
        y2="279.603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34D399" />
        <stop offset="1" stopColor="#3A93E4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_23230_99856"
        x1="232.613"
        y1="274.438"
        x2="221.334"
        y2="279.603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34D399" />
        <stop offset="1" stopColor="#3A93E4" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_23230_99856"
        x1="217.585"
        y1="189.127"
        x2="160.616"
        y2="263.125"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#34D399" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
    </defs>
  </svg>
);
