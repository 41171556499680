export enum SequenceStepConfig {
  MaxVariants = 26,
  MinActiveVariants = 1,
}

export enum SequenceStepExecutionDay {
  MinDay = 1,
  MaxDay = 999,
}

export enum SequenceStepExecutionType {
  AutomatedEmail = 1,
  ManualEmail = 2,
  Call = 3,
  Sms = 4,
  LinkedIn = 5,
  Twitter = 6,
}

export enum SequenceStepType {
  Email = 1,
  LinkedInConnectionRequest = 2,
  LinkedInMessage = 3,
  LinkedInInMail = 4,
  LinkedInViewProfile = 5,
  LinkedInPostInteraction = 6,
  Call = 7,
  Whatsapp = 8,
  Custom = 9,
}

export enum SequenceStepPriorityValue {
  Low = 4,
  Normal = 3,
  High = 2,
  Urgent = 1,
}

export enum SequenceStepPriority {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
  Urgent = 'Urgent',
}

export enum CallPurpose {
  Introduction = 1,
  Demo = 2,
  FollowUp = 3,
  Reminder = 4,
  Other = 5,
}

export enum SequenceStepAction {
  CreateStep = 'CreateStep',
  CreateVariant = 'CreateVariant',
  EditVariant = 'EditVariant',
}
