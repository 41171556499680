import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import {
  CreateDoNotContactListPayload,
  GetDoNotContactListsPayload,
  GetDoNotContactListDetailsPayload,
  UpdateDoNotContactListPayload,
  UpdateDoNotContactListDetailsPayload,
  DeleteDoNotContactListEmailsAndDomainsPayload,
  BulkDeleteDoNotContactListEmailsAndDomainsPayload,
  ImportEmailsAndDomainsViaCsvPayload,
} from '../types/do-not-contact-list';

export const getDoNotContactLists = async (
  params: GetDoNotContactListsPayload,
) => api.get(`/dnc`, { params });

export const createDoNotContactList = async (
  payload: CreateDoNotContactListPayload,
) => api.post(`/dnc`, payload);

export const updateDoNotContactList = async ({
  id,
  ...payload
}: UpdateDoNotContactListPayload) => api.patch(`/dnc/${id}`, payload);

export const deleteDoNotContactList = async (id: string) =>
  api.delete(`/dnc/${id}`);

export const getDoNotContactListDetailsMeta = async (id: string) =>
  api.get(`/dnc/${id}/meta`);

export const getDoNotContactListDetails = async ({
  id,
  ...params
}: GetDoNotContactListDetailsPayload) => api.get(`/dnc/${id}`, { params });

export const updateDoNotContactListDetails = async ({
  id,
  ...payload
}: UpdateDoNotContactListDetailsPayload) => api.post(`/dnc/${id}`, payload);

export const deleteDoNotContactListEmailsAndDomains = async ({
  dncListId,
  emailDomainsIds,
}: DeleteDoNotContactListEmailsAndDomainsPayload) =>
  api.patch(`/dnc/${dncListId}/list`, {
    ids: emailDomainsIds,
  });

export const bulkDeleteDoNotContactListEmailsAndDomains = async ({
  dncListId,
  deSelectedIds,
  dncDetailsFilter,
}: BulkDeleteDoNotContactListEmailsAndDomainsPayload) =>
  api.patch(`/dnc/${dncListId}/bulk-delete`, {
    deSelectedIds,
    dncDetailsFilter,
  });

export const importDoNotContactListEmailsAndDomains = async ({
  dncListId,
  file,
  emailsDomainsColumn,
}: ImportEmailsAndDomainsViaCsvPayload) => {
  const data = new FormData();
  data.append('file', file);
  data.append('mapping', emailsDomainsColumn);

  return api.post<ResponseSuccess>(`/dnc/${dncListId}/import`, data);
};
