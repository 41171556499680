import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  getSequenceList,
  getSelectedSequences,
  getSelectedUsers,
  getSequenceReportsSummary,
  getTeamReportsSummary,
  getSequenceReportsStats,
  getTeamReportsStats,
  exportSequenceReports,
  getGraphData,
  exportSequenceConsolidatedReports,
} from './helpers/reports.api';

import {
  ReportGraphPayload,
  SelectedSequencesOrUserPayload,
  SequenceReportsStatsPayload,
  SequenceReportsSummaryPayload,
  TeamReportsStatsPayload,
  TeamReportsSummaryPayload,
} from './types';

export const getSequenceListRequest = createAsyncThunk<
  ResponseSuccess,
  number[],
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSequenceList', async (args, thunkAPI) => {
  try {
    return await getSequenceList(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSelectedSequencesRequest = createAsyncThunk<
  ResponseSuccess,
  SelectedSequencesOrUserPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSelectedSequences', async (args, thunkAPI) => {
  try {
    return await getSelectedSequences(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSelectedUsersRequest = createAsyncThunk<
  ResponseSuccess,
  SelectedSequencesOrUserPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSelectedUsers', async (args, thunkAPI) => {
  try {
    return await getSelectedUsers(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceReportsSummaryRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceReportsSummaryPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSequenceReportsSummary', async (args, thunkAPI) => {
  try {
    return await getSequenceReportsSummary(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTeamReportsSummaryRequest = createAsyncThunk<
  ResponseSuccess,
  TeamReportsSummaryPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getTeamReportsSummary', async (args, thunkAPI) => {
  try {
    return await getTeamReportsSummary(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getSequenceReportsStatsRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceReportsStatsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getSequenceReportsStats', async (args, thunkAPI) => {
  try {
    return await getSequenceReportsStats(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTeamReportsStatsRequest = createAsyncThunk<
  ResponseSuccess,
  TeamReportsStatsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getTeamReportsStats', async (args, thunkAPI) => {
  try {
    return await getTeamReportsStats(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const exportSequenceReportsRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceReportsSummaryPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/exportSequenceReports', async (args, thunkAPI) => {
  try {
    return await exportSequenceReports(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const exportSequenceConsolidatedReportsRequest = createAsyncThunk<
  ResponseSuccess,
  SequenceReportsSummaryPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/exportSequenceConsolidatedReports', async (args, thunkAPI) => {
  try {
    return await exportSequenceConsolidatedReports(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const gerGraphDataRequest = createAsyncThunk<
  ResponseSuccess,
  ReportGraphPayload,
  { rejectValue: ResponseErrorWithHandled }
>('reports/getGraphDataRequest', async (args, thunkAPI) => {
  try {
    return await getGraphData(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
