import React, { useEffect } from 'react';
import validator from 'validator';
import { Spinner } from 'react-bootstrap';
import { ArrowsDiagonal, ArrowsMinimize2 } from '@saleshandy/icons';
import type { IProps } from './types';

const StepContentArea = React.forwardRef<any, IProps>(
  (
    {
      stepContentHTML,
      isContentError,
      placeholder = 'Briefly explain your reason for connecting.',
      maxLength,
      minLength = 0,
      isExpandOptionVisible,
      size,
      isLoading,
      isAutoSaved = false,
      onChange,
      onBlur,
      onExpandContentArea,
      onHide,
    },
    ref,
  ) => {
    const contentLength = stepContentHTML?.length || 0;
    const [isError, setIsError] = React.useState<boolean>(
      isContentError || false,
    );

    const handleChange = (e) => {
      const { value } = e.target;

      onChange(e.target.value);
      setIsError(value.length > maxLength);
    };

    const handleBlur = () => {
      onBlur?.();
      setIsError(
        (contentLength > 0 && contentLength < minLength) ||
          contentLength > maxLength ||
          (contentLength > 0 &&
            minLength !== 0 &&
            validator.isEmpty(stepContentHTML?.trim(), {
              ignore_whitespace: false,
            })),
      );
    };

    const getErrorString = () => {
      if (stepContentHTML?.trim()?.length === 0) {
        return 'Please enter a valid input. Blank spaces are not allowed.';
      }
      if (stepContentHTML?.trim()?.length < 2) {
        return 'Mandatory Field: At least 2 characters needed';
      }

      return `Character limit exceeds ${maxLength}`;
    };

    // useEffect
    useEffect(() => {
      handleBlur();
    }, [maxLength]);

    return (
      <>
        <div
          className={`content-area position-relative mt-2 ${
            isError && 'content-area--error'
          } ${`content-area--${size}`}`}
        >
          {!onHide && isExpandOptionVisible && (
            <div className="d-flex justify-content-end position-absolute expand">
              <span className="regular-1 gray-txt-11">
                <ArrowsDiagonal
                  width={16}
                  height={16}
                  className="gray-txt-15 pointer"
                  onClick={onExpandContentArea}
                />
              </span>
            </div>
          )}
          {onHide && isExpandOptionVisible && (
            <div className="d-flex justify-content-end position-absolute expand">
              <span className="regular-1 gray-txt-11">
                <ArrowsMinimize2
                  width={16}
                  height={16}
                  className="gray-txt-15 pointer"
                  onClick={onHide}
                />
              </span>
            </div>
          )}

          <textarea
            ref={ref}
            value={stepContentHTML}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={placeholder}
            className="content-area__input"
          />
          <div className="d-flex justify-content-end align-items-center">
            {isAutoSaved && (
              <div className="d-flex align-items-center">
                {isLoading ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    className="mr-3 gray-txt-11"
                  />
                ) : (
                  <span className="regular-1 gray-txt-11 mr-3">Auto Saved</span>
                )}
              </div>
            )}
            <span className="regular-1 gray-txt-11">
              {contentLength}/{maxLength}
            </span>
          </div>
        </div>
        {isError && (
          <span className="regular-1 red-txt-12 mt-1">{getErrorString()}</span>
        )}
      </>
    );
  },
);

export default StepContentArea;
