import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { loginRequest } from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
  showError?: boolean;
};

type State = {
  loginRequest: RequestState;

  token: string;
  trackingId: string;
  firstName: string;
  lastName: string;
  email: string;
};

const initialState: State = {
  loginRequest: {
    status: RequestStatus.Ideal,
    message: '',
    error: '',
    showError: false,
  },
  token: '',
  trackingId: null,
  firstName: null,
  lastName: null,
  email: null,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    hideError: (state) => {
      state.loginRequest.showError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginRequest.pending, (state) => {
      state.loginRequest.status = RequestStatus.Pending;
      state.loginRequest.showError = false;
    });
    builder.addCase(loginRequest.fulfilled, (state, action) => {
      state.loginRequest.status = RequestStatus.Succeeded;
      state.loginRequest.message = action.payload.message;
      state.token = action.payload.payload.accessToken;
      state.trackingId = action.payload.payload.trackingId;
      state.firstName = action.payload.payload.firstName;
      state.lastName = action.payload.payload.lastName;
      state.email = action.payload.payload.email;
    });
    builder.addCase(loginRequest.rejected, (state, action) => {
      state.loginRequest.status = RequestStatus.Failed;
      state.loginRequest.error = action.payload;
      state.loginRequest.showError = !action.payload.isHandled;
    });
  },
});

export const { hideError } = loginSlice.actions;
export { loginRequest };
export default loginSlice.reducer;
