import { UserRole, UserStatusKeys } from '../../../../../enums/users-and-teams';
import { Action } from '../../../../../../../shared/design-system/components/organisms/table/types';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';

export const getActionPermissions = (
  userId: number,
  action: Action,
  status: UserStatusKeys,
  rowId: number,
  rowRole?: string,
  currentUserATMRole?: string,
): boolean => {
  if (
    action.key === 'changeRole' &&
    currentUserATMRole === UserRole.Admin &&
    rowRole === UserRole.Owner
  ) {
    return false;
  }

  if (
    (currentUserATMRole !== UserRole.Admin && rowRole !== UserRole.Owner) ||
    (userId !== rowId &&
      action.key === 'changeRole' &&
      hasPermission(Permissions.ACCOUNT_USER_UPDATE) &&
      status !== UserStatusKeys.Failed)
  ) {
    return true;
  }

  if (
    action.key === 'assignTeam' &&
    hasPermission(Permissions.ACCOUNT_TEAM_INVITE_WRITE) &&
    status !== UserStatusKeys.Failed
  ) {
    return true;
  }

  if (
    userId !== rowId &&
    action.key === 'delete' &&
    hasPermission(Permissions.ACCOUNT_USER_DELETE)
  ) {
    return true;
  }

  if (
    userId !== rowId &&
    action.key === 'disable' &&
    hasPermission(Permissions.ACCOUNT_USER_UPDATE) &&
    status !== UserStatusKeys.Failed &&
    status !== UserStatusKeys.Invited &&
    status !== UserStatusKeys.InActive
  ) {
    return true;
  }

  if (
    userId !== rowId &&
    action.key === 'enable' &&
    hasPermission(Permissions.ACCOUNT_USER_UPDATE) &&
    status === UserStatusKeys.InActive
  ) {
    return true;
  }

  if (
    userId !== rowId &&
    action.key === 'resend' &&
    hasPermission(Permissions.ACCOUNT_USER_INVITE) &&
    status !== UserStatusKeys.Active &&
    status !== UserStatusKeys.InActive
  ) {
    return true;
  }

  if (action.key === 'copyUserId') {
    return true;
  }

  return false;
};
