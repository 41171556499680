import React, { useState, useEffect } from 'react';
import type { IProps } from './out-of-office-container';
import toaster, { Theme } from '../../../../shared/toaster';

import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
} from '../../../../shared/utils';

import OutOfOfficeContent from './components/out-of-office-content';
import { OOOSettings } from './types';
import { PauseProspectOption } from '../../enums/out-of-office';
import { UserSettingCode } from '../../../../shared/types/user-setting';

const OutOfOffice: React.FC<IProps> = ({
  meta,
  getUserSettingsRequest,

  sendUpdateOOOSettingsRequest,
  updateOOOSettingsRequestStatus,
  updateOOOSettingsRequestError,
  resetUpdateOOOSettingsRequest,

  sendGetUserSettingsRequest,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [oooSettings, setOOOSettings] = useState<OOOSettings>(null);

  useEffect(() => {
    executeOnRequestStatus({
      status: getUserSettingsRequest,
      onSuccess: () => {
        if (meta?.length > 0) {
          const outOfOfficeOption = meta.find(
            ({ code }) => code === UserSettingCode.OutOfOffice,
          )?.value;
          const pauseForDays = meta.find(
            ({ code }) => code === UserSettingCode.OutOfOfficePauseDays,
          )?.value;

          setOOOSettings({
            enableOutOfOffice: outOfOfficeOption !== '',
            pauseProspectOption:
              (outOfOfficeOption as PauseProspectOption) ||
              PauseProspectOption.PauseProspectAsPerReturnDate,
            pauseProspectForDays: Number(pauseForDays),
          });
        }
        setIsLoading(false);
      },
    });
  }, [getUserSettingsRequest]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateOOOSettingsRequestStatus,
      onSuccess: () => {
        toaster.success('Out of Office setting saved successfully', {
          theme: Theme.New,
        });
        resetUpdateOOOSettingsRequest();
        sendGetUserSettingsRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: updateOOOSettingsRequestError,
          onError: () => {
            toaster.error(updateOOOSettingsRequestError.message, {
              theme: Theme.New,
            });
            resetUpdateOOOSettingsRequest();
            sendGetUserSettingsRequest();
          },
        });
      },
    });
  }, [updateOOOSettingsRequestStatus]);

  if (isLoading) {
    return null;
  }

  return (
    <OutOfOfficeContent
      oooSettings={oooSettings}
      setOOOSettings={setOOOSettings}
      sendUpdateOOOSettingsRequest={sendUpdateOOOSettingsRequest}
      updateOOOSettingsRequestStatus={updateOOOSettingsRequestStatus}
    />
  );
};

export default OutOfOffice;
