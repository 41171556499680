import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/root-reducer';
import { updateApplicationState } from '../home/home-slice';
import AgencyPortal from './agency-portal';

const mapStateToProps = (state: RootState) => ({
  hasVisitedAgencyPortal: state.home.application.hasVisitedAgencyPortal,

  trackingId: state.home.trackingId,
  planName: state.home.subscription?.planName,
});

const mapDispatchToProps = {
  handleHasVisitedAgencyPortal: (hasVisitedAgencyPortal: boolean) =>
    updateApplicationState({ hasVisitedAgencyPortal }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(AgencyPortal);
