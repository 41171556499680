import React from 'react';
import { Pencil, Trash } from '@saleshandy/icons';
import { SkeletonLoading } from '@saleshandy/design-system';
import { SortOrder } from 'react-bootstrap-table-next';

import { DoNotContactList } from '../../../../../../../types/do-not-contact-list';
import { DoNotContactListsSortBy } from '../../../../../../../enums/do-not-contact-list';
import { Column } from '../../../../../../../../../shared/design-system/components/organisms/table/types';

import ListNameField from '../fields/list-name-field';
import TotalField from '../fields/total-field';
import ClientAssociatedField from '../fields/client-associated-field';
import AddedByField from '../fields/added-by-field';
import UpdatedAtField from '../fields/updated-at-field';
import { Permissions } from '../../../../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../../../../shared/utils/access-control/has-permission';
import { isAgency } from '../../../../../../../../../shared/utils/user-details';

type GetDoNotContactListColumns = {
  onSort: (sortBy: DoNotContactListsSortBy, order: SortOrder) => void;
  onAction: (key: string, doNotContactList: DoNotContactList) => void;
  isCellClickable: boolean;
};

const actions = [
  {
    key: 'edit',
    iconElement: <Pencil />,
    displayName: 'Modify',
    position: 'out',
    permission: Permissions.DNC_UPDATE,
  },
  {
    key: 'delete',
    iconElement: <Trash />,
    displayName: 'Delete',
    position: 'out',
    permission: Permissions.DNC_DELETE,
  },
];

export const getDoNotContactListColumns = ({
  onSort,
  onAction,
  isCellClickable,
}: GetDoNotContactListColumns): Column[] => {
  const cellClassName = isCellClickable ? 'cursor-pointer' : '';

  const isClientColVisible =
    hasPermission(Permissions.CLIENT_READ) && isAgency();

  const colWidth = {
    col1: isClientColVisible ? '30%' : '60.19%',
    col2: '5.77%',
    col3: '28.27%',
    col4: '5.77%',
    col5: isClientColVisible ? '16.35%' : '17.88%',
  };

  const getColWidth = (key: string) => ({
    minWidth: colWidth[key],
    width: colWidth[key],
  });

  return [
    {
      dataField: 'listName',
      text: 'List Name',
      headerAlign: 'start',
      cellClasses: `list-name-cell ${cellClassName}`,
      component: (_cell, row: DoNotContactList) => (
        <ListNameField doNotContactList={row} />
      ),
      headerStyle: () => getColWidth('col1'),
      headerLoadingSkeleton: <SkeletonLoading width={80} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={180} height={14} />,
    },
    {
      dataField: 'total',
      text: 'Total',
      headerAlign: 'start',
      cellClasses: `total-score-cell ${cellClassName}`,
      sort: true,
      onSort: (_field, order) => onSort(DoNotContactListsSortBy.Total, order),
      component: (_cell, row: DoNotContactList) => (
        <TotalField doNotContactList={row} />
      ),
      headerStyle: () => getColWidth('col2'),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    isClientColVisible && {
      dataField: 'client',
      text: 'Client Associated',
      headerAlign: 'start',
      cellClasses: `client-associated-cell ${cellClassName}`,
      sort: true,
      onSort: (_field, order) =>
        onSort(DoNotContactListsSortBy.ClientAssociated, order),
      component: (_cell, row: DoNotContactList) => (
        <ClientAssociatedField doNotContactList={row} />
      ),
      headerStyle: () => getColWidth('col3'),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'user',
      text: 'Added By',
      headerAlign: 'start',
      cellClasses: `added-by-cell ${cellClassName}`,
      component: (_cell, row: DoNotContactList) => (
        <AddedByField doNotContactList={row} />
      ),
      headerStyle: () => getColWidth('col4'),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
    {
      dataField: 'updatedAt',
      text: 'Updated At',
      headerAlign: 'start',
      cellClasses: 'updated-at-cell',
      component: (_cell, row: DoNotContactList) => (
        <UpdatedAtField
          doNotContactList={row}
          actions={actions}
          onAction={onAction}
        />
      ),
      sort: false,
      onSort: () => null,
      isCellClickable: false,
      headerStyle: () => getColWidth('col5'),
      headerLoadingSkeleton: <SkeletonLoading width={62} height={14} />,
      cellLoadingSkeleton: (
        <SkeletonLoading width={32} height={24} borderRadius={20} />
      ),
    },
  ].filter((col) => col);
};
