import React from 'react';
import { Building, Users } from '@saleshandy/icons';
import SearchingEmptyImage from '../../../../shared/components/images/searching-empty-image';

export default function SearchingEmpty() {
  return (
    <>
      <div className="searching-empty">
        <SearchingEmptyImage />
        <h3>
          Find your ideal leads with our database of 700M+ contacts across 60M+
          companies worldwide.
        </h3>
        <p className="regular-2 text-desc">
          Effortlessly uncover verified email addresses and phone numbers for
          your key leads <br /> using our search-by-name, keyword, or filter
          features.
        </p>
        <div className="leads-profile">
          <div className="professional-profile">
            <Users className="profile-icon" />
            <p className="profile-lable">700M+</p>
            <p className="profile-text">Professional Profiles</p>
          </div>
          <div className="professional-profile">
            <Building className="profile-icon" />
            <p className="profile-lable">60M+</p>
            <p className="profile-text">Companies</p>
          </div>
        </div>
      </div>
    </>
  );
}
