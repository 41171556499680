import React from 'react';

export const SuccessChecks = () => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
      fill="url(#paint0_linear_10841_248046)"
    />
    <path
      d="M120 150H30V53C34.242 52.9952 38.3089 51.308 41.3084 48.3085C44.308 45.3089 45.9952 41.242 46 37H104C103.996 39.1014 104.408 41.1828 105.213 43.1238C106.018 45.0648 107.2 46.8268 108.691 48.308C110.172 49.7991 111.934 50.9816 113.875 51.787C115.817 52.5924 117.898 53.0047 120 53V150Z"
      fill="white"
    />
    <path
      d="M75 102C88.2548 102 99 91.2548 99 78C99 64.7452 88.2548 54 75 54C61.7452 54 51 64.7452 51 78C51 91.2548 61.7452 102 75 102Z"
      fill="#10B981"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M87.4916 71.3504C87.9472 71.806 87.9472 72.5447 87.4916 73.0004L75.825 84.667C75.3693 85.1226 74.6307 85.1226 74.175 84.667L68.3417 78.8337C67.8861 78.3781 67.8861 77.6394 68.3417 77.1838C68.7973 76.7282 69.536 76.7282 69.9916 77.1838L75 82.1921L85.8417 71.3504C86.2973 70.8948 87.036 70.8948 87.4916 71.3504Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M81.6583 71.3504C82.1139 71.806 82.1139 72.5447 81.6583 73.0004L75.825 78.8337C75.3694 79.2893 74.6307 79.2893 74.1751 78.8337C73.7195 78.3781 73.7195 77.6394 74.1751 77.1838L80.0084 71.3504C80.464 70.8948 81.2027 70.8948 81.6583 71.3504ZM62.5084 77.1838C62.964 76.7282 63.7027 76.7282 64.1583 77.1838L69.9916 83.0171C70.4473 83.4727 70.4473 84.2114 69.9916 84.667C69.536 85.1226 68.7973 85.1226 68.3417 84.667L62.5084 78.8337C62.0528 78.3781 62.0528 77.6394 62.5084 77.1838Z"
      fill="white"
    />
    <path
      d="M88 108H62C60.3431 108 59 109.343 59 111C59 112.657 60.3431 114 62 114H88C89.6569 114 91 112.657 91 111C91 109.343 89.6569 108 88 108Z"
      fill="#DFEAFB"
    />
    <path
      d="M97 120H53C51.3431 120 50 121.343 50 123C50 124.657 51.3431 126 53 126H97C98.6569 126 100 124.657 100 123C100 121.343 98.6569 120 97 120Z"
      fill="#DFEAFB"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10841_248046"
        x1="75"
        y1="0"
        x2="75"
        y2="150"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF6FF" />
        <stop offset="1" stopColor="#DAE7FF" />
      </linearGradient>
    </defs>
  </svg>
);
