import React from 'react';
import produce from 'immer';
import { Link } from 'react-router-dom';

import type { IProps, IState } from './types';

import Input from '../../../../../../shared/design-system/components/input';
import Button from '../../../../../../shared/design-system/ui/button';
import { SHLogoDark } from '../../../../../../shared/svg';

import { validate } from '../../../login-form/validator';
import {
  getIsRequestPending,
  executeOnRequestStatusWithPrevStatusCheck,
} from '../../../../../../shared/utils';

class ResetPasswordForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        email: '',
      },
      errors: {
        email: '',
      },
      dirty: {
        email: false,
      },
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      values: { email },
    } = this.state;
    const { resetPasswordStatus, setEmail, showSuccessComponent } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: resetPasswordStatus,
      prevStatus: prevProps.resetPasswordStatus,
      onSuccess: () => {
        setEmail(email);
        showSuccessComponent();
      },
    });
  }

  componentWillUnmount() {
    const { hideError } = this.props;
    hideError?.();
  }

  onInputChange(value: string, e: React.ChangeEvent<HTMLInputElement>) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { dirty, errors, values } = this.state;
    const { sendResetPasswordRequest } = this.props;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const { email } = values;
    sendResetPasswordRequest(email);
  }

  hasErrors() {
    const { errors, dirty } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' || !dirty[key]) {
        isError = true;
      }
    });

    return isError;
  }

  render() {
    const { values, errors } = this.state;
    const {
      resetPasswordStatus,
      resetPasswordError,
      resetPasswordShowError,
    } = this.props;

    const isResetPasswordBtnLoading = getIsRequestPending(resetPasswordStatus);
    const isResetPasswordBtnDisabled =
      isResetPasswordBtnLoading || this.hasErrors();

    return (
      <div className="auth-wrapper">
        <div className="auth-container gap-0">
          <div className="sh-logo">
            <SHLogoDark width={200} />
          </div>
          <h1 className="auth-container--title mb-2">Reset your password</h1>
          <p className="auth-container--sub-title mb-4">
            Enter the email address associated with your account, and we’ll send
            you instructions to reset your password.
          </p>

          <form
            onSubmit={this.onFormSubmit}
            className="auth-container--form mt-2"
          >
            <div className="auth-form-row">
              <div className="auth-form-input email">
                <Input
                  name="email"
                  label="Work Email"
                  type="email"
                  placeholder="johndoe@example.com"
                  value={values.email}
                  variant={errors.email && Input.Variant.Error}
                  caption={
                    errors.email ||
                    (resetPasswordShowError ? resetPasswordError.message : '')
                  }
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  tabIndex={0}
                  autoComplete="current-email"
                />
              </div>
            </div>

            <Button
              type="submit"
              isFullWidth
              isLoading={isResetPasswordBtnLoading}
              disabled={isResetPasswordBtnDisabled}
              loadingText="Sending link, please wait..."
            >
              Reset Password
            </Button>
          </form>

          <div className="bottom-navigation">
            <p>
              <Link to="/login" tabIndex={0}>
                Return to Log in!
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPasswordForm;
