import React, { useState, useRef, useEffect } from 'react';
import { Mail, Pencil } from '@saleshandy/icons';
import { Avatar } from '@saleshandy/design-system';

import classNames from 'classnames';
import { saveProspectDealValue } from '../../../../../components/sequence/helpers/sequence.api';
import { OverlayTooltip, Placement } from '../../overlay';
import toaster from '../../../../toaster';
import { trimLeadingZeros } from '../../../../utils/trim-leading-zeros';
import { getOutcomeIcon } from '../../../../../components/sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import {
  getOptionFromOutcome,
  mapOutcomeToOption,
} from '../../../../../components/sequence/components/sequence-single-content/components/sequence-single-contacts/components/prospect-outcome-select/utils/helper';
import GenericCustomOutcomeDropdown from '../../../../../components/settings/components/custom-outcomes/components/custom-outcome-dropdown';

const CellCard = ({ list, bulkText, isOutcomeVisible = true }) => {
  const initialDealValue = list[0]?.sequenceProspectDealValue
    ? `$${list[0].sequenceProspectDealValue}`
    : `$0`;

  const iconContainerRef = useRef(null);
  const inputRef = useRef(null);

  const [dealValue, setDealValue] = useState(initialDealValue);
  const [isEditMode, setEditMode] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [selectedOutcome, setSelectedOutcome] = useState(null);

  const calculateInputWidth = (inputValue) => {
    const initialInputValue = `$ ${inputValue.substr(1)}`;
    const inputElement = inputRef.current;
    const characterWidth = 14 * 0.6;
    const minWidth = 5;
    let initialWidth = Math.max(
      minWidth,
      initialInputValue.length * characterWidth,
    );
    if (initialWidth < characterWidth * 3) {
      initialWidth = characterWidth * 3;
    }
    if (inputElement) {
      inputElement.style.width = `${initialWidth + 10}px`;
    }
  };
  const onInputChange = (event) => {
    const { value } = event.target;
    setIsEdited(true);
    let updatedValue = value;
    if (updatedValue.charAt(0) !== '$') {
      updatedValue = `$ ${updatedValue.substr(1)}`;
    }

    const isValidInput = /^\$\s?\d*$/.test(updatedValue);
    if (isValidInput) {
      setDealValue(updatedValue);
      calculateInputWidth(updatedValue);
    }
  };

  const deactivateEditMode = async () => {
    setEditMode(false);
  };

  const onRevert = () => {
    const previousTitle = '$1000';
    setDealValue(previousTitle);
    inputRef.current.blur();
    deactivateEditMode();
  };

  const onInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      inputRef.current.blur();
    } else if (event.key === 'Escape') {
      onRevert();
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        iconContainerRef.current &&
        !iconContainerRef.current.contains(event.target)
      ) {
        deactivateEditMode();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    calculateInputWidth(initialDealValue);
  }, [initialDealValue]);

  useEffect(() => {
    calculateInputWidth(dealValue);
  }, [dealValue]);

  useEffect(() => {
    calculateInputWidth(dealValue);
  }, [selectedOutcome]);

  const onSave = async () => {
    if (dealValue.trim().length > 1) {
      const { prospectId } = list[0];
      const sequenceId = list[0]?.id;
      deactivateEditMode();

      if (isEdited) {
        let trimmedValue = trimLeadingZeros(parseInt(dealValue.substr(1), 10));
        trimmedValue = `$${trimmedValue}`;
        setDealValue(trimmedValue);
        calculateInputWidth(trimmedValue);
        try {
          await saveProspectDealValue(
            sequenceId,
            parseInt(prospectId, 10),
            parseInt(trimmedValue.substr(1), 10),
          );
          toaster.success('Deal value updated successfully');
        } catch (err) {
          toaster.error('Deal value updation failed');
        }
        setIsEdited(false);
      }
    } else {
      setDealValue(initialDealValue);
      calculateInputWidth(initialDealValue);
    }
  };

  const onInputBlur = () => {
    onSave();
  };

  const activateEditMode = () => {
    setEditMode(true);
  };

  const handleDealIcon = () => {
    if (inputRef.current) {
      // Do something with the input box, for example, focus it
      inputRef.current.focus();
    }
    activateEditMode();
  };

  const isDealValueVisible = (outcomeId: number) => {
    if (selectedOutcome) {
      return (
        [2, 4, 6].includes(outcomeId) && [2, 4, 6].includes(selectedOutcome?.id)
      );
    }

    return [2, 4, 6].includes(outcomeId);
  };

  const iconClassNames = classNames('gray-txt-12', 'regular-4', {
    'icon-deal-remove': isEditMode,
    'deal-icon-wrap': !isEditMode,
  });

  const inputValueClassNames = classNames(
    'deal-value-input',
    'popover-arrow-color-txt',
    {
      'deal-value-icon-btn-cls-border': !isEditMode,
      'deal-value-icon-btn-cls': isEditMode,
    },
  );

  let component = [];
  if (list.length < 3) {
    component = list.map(
      ({
        id,
        value,
        prospectId,
        outcomeId,
        stepNumber,
        userFirstName,
        userLastName,
        sequenceProspectDealValue,
        allOutcomes,
      }) => (
        <div key={id}>
          <div className="d-flex align-items-center">
            <Mail className="gray-txt-15 mr-2" />
            <span className="regular-2 gray-txt-15 mr-2">{stepNumber}</span>
            <span className="regular-2 font-medium popover-arrow-color-txt">
              {value}
            </span>
          </div>

          {isOutcomeVisible && (
            <div className="d-flex align-items-center bs-mt-12">
              <Avatar
                firstName={userFirstName}
                lastName={userLastName}
                size="md"
                className="mr-2"
              />
              <GenericCustomOutcomeDropdown
                showFooter={false}
                options={mapOutcomeToOption(allOutcomes)}
                showTotal={false}
                className="prospect-outcome-select"
                onChange={(option) => {
                  setSelectedOutcome(option);
                }}
                changeOutcomeRequestPayload={{
                  sequenceId: id,
                  prospectId,
                }}
                initialSelectedOption={getOptionFromOutcome(
                  allOutcomes,
                  outcomeId,
                )}
                optionRenderer={(option) => (
                  <div className="d-flex align-items-center">
                    {getOutcomeIcon(option?.name, false, {
                      isDefault: option.isDefault,
                      sentiment: option.sentiment,
                    })}
                    <span className="ml-1">{option.name}</span>
                  </div>
                )}
              />
              {isDealValueVisible(outcomeId) &&
                sequenceProspectDealValue !== null && (
                  <div className="deal-value-prospect d-flex align-items-center ml-1">
                    <input
                      className={inputValueClassNames}
                      value={dealValue}
                      onChange={onInputChange}
                      onBlur={onInputBlur}
                      onKeyUp={onInputKeyPress}
                      ref={inputRef}
                      maxLength={8}
                      readOnly={!isEditMode}
                    />
                    <OverlayTooltip
                      text="Modify Deal Value for this Prospect"
                      className="tooltip__max-width"
                      placement={Placement.Bottom}
                    >
                      <Pencil
                        ref={iconContainerRef}
                        className={`${iconClassNames} `}
                        onClick={handleDealIcon}
                      />
                    </OverlayTooltip>
                  </div>
                )}
            </div>
          )}
        </div>
      ),
    );
  } else {
    const nameList = list.map(({ value, index }) => (
      <div key={index}>{value}</div>
    ));
    component[0] = (
      <OverlayTooltip
        text={nameList}
        placement={Placement.Right}
        className="cell-card__tooltip"
      >
        <div>{`${list.length} ${bulkText}`}</div>
      </OverlayTooltip>
    );
  }

  return <span style={{ display: 'inline-block' }}>{component}</span>;
};

export default CellCard;
