import React from 'react';

export const ArrowBackUp = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28033 3.2226C7.57322 3.51549 7.57322 3.99037 7.28033 4.28326L5.56066 6.00293H12C12.9946 6.00293 13.9484 6.39802 14.6516 7.10128C15.3549 7.80454 15.75 8.75837 15.75 9.75293C15.75 10.7475 15.3549 11.7013 14.6516 12.4046C13.9484 13.1078 12.9946 13.5029 12 13.5029H11.25C10.8358 13.5029 10.5 13.1671 10.5 12.7529C10.5 12.3387 10.8358 12.0029 11.25 12.0029H12C12.5967 12.0029 13.169 11.7659 13.591 11.3439C14.0129 10.922 14.25 10.3497 14.25 9.75293C14.25 9.15619 14.0129 8.5839 13.591 8.16194C13.169 7.73998 12.5967 7.50293 12 7.50293H5.56066L7.28033 9.2226C7.57322 9.51549 7.57322 9.99037 7.28033 10.2833C6.98744 10.5762 6.51256 10.5762 6.21967 10.2833L3.21967 7.28326C2.92678 6.99037 2.92678 6.51549 3.21967 6.2226L6.21967 3.2226C6.51256 2.92971 6.98744 2.92971 7.28033 3.2226Z"
      fill="currentColor"
    />
  </svg>
);
