import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getApiTokenListRequest,
  createApiTokenRequest,
  revokeApiTokenRequest,
  getCobaltSessionTokenRequest,
  cobaltEventsSubscribeRequest,
  getIntegrationAdminSettingRequest,
  changeIntegrationSettingRequest,
  generateIntegrationApiTokenRequest,
} from './extra-actions';
import { ApiToken, IntegrationSetting } from './types';
import { getCobaltSessionToken } from '../integrations/utils/helper';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  getApiTokenListRequest: RequestState;
  createApiTokenRequest: RequestState;
  revokeApiTokenRequest: RequestState;
  getCobaltSessionTokenRequest: RequestState;
  cobaltEventsSubscribeRequest: RequestState;
  getIntegrationAdminSettingRequest: RequestState;
  changeIntegrationSettingRequest: RequestState;
  generateIntegrationApiTokenRequest: RequestState;
  apiTokenList: ApiToken[];
  generatedApiToken: string;
  cobaltToken: string;
  isLoading: boolean;
  integrationSettings: IntegrationSetting;
}

const initialState: State = {
  getApiTokenListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createApiTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  revokeApiTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getCobaltSessionTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  cobaltEventsSubscribeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getIntegrationAdminSettingRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  changeIntegrationSettingRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  generateIntegrationApiTokenRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  apiTokenList: [],
  generatedApiToken: '',
  cobaltToken: getCobaltSessionToken(),
  isLoading: false,
  integrationSettings: null,
};

const apiTokensSlice = createSlice({
  name: 'apiToken',
  initialState,
  reducers: {
    resetCreateApiTokenRequest: (state) => {
      state.createApiTokenRequest.status = RequestStatus.Ideal;
      state.createApiTokenRequest.error = null;
      state.generatedApiToken = '';
    },
  },
  extraReducers: (builder) => {
    // get api token
    builder.addCase(getApiTokenListRequest.pending, (state) => {
      state.getApiTokenListRequest.status = RequestStatus.Pending;
      state.getApiTokenListRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getApiTokenListRequest.fulfilled, (state, action) => {
      state.getApiTokenListRequest.status = RequestStatus.Succeeded;
      state.getApiTokenListRequest.message = action.payload.message;
      state.apiTokenList = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getApiTokenListRequest.rejected, (state, action) => {
      state.getApiTokenListRequest.status = RequestStatus.Failed;
      state.getApiTokenListRequest.error = action.payload;
      state.isLoading = false;
    });

    // Create api token
    builder.addCase(createApiTokenRequest.pending, (state) => {
      state.createApiTokenRequest.status = RequestStatus.Pending;
      state.createApiTokenRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(createApiTokenRequest.fulfilled, (state, action) => {
      state.createApiTokenRequest.status = RequestStatus.Succeeded;
      state.createApiTokenRequest.message = action.payload.message;
      state.generatedApiToken = action.payload.payload.hashToken;
      state.isLoading = false;
    });
    builder.addCase(createApiTokenRequest.rejected, (state, action) => {
      state.createApiTokenRequest.status = RequestStatus.Failed;
      state.createApiTokenRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // revoke api token
    builder.addCase(revokeApiTokenRequest.pending, (state) => {
      state.revokeApiTokenRequest.status = RequestStatus.Pending;
      state.revokeApiTokenRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(revokeApiTokenRequest.fulfilled, (state, action) => {
      state.revokeApiTokenRequest.status = RequestStatus.Succeeded;
      state.revokeApiTokenRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(revokeApiTokenRequest.rejected, (state, action) => {
      state.revokeApiTokenRequest.status = RequestStatus.Failed;
      state.revokeApiTokenRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get Cobalt Session Token
    builder.addCase(getCobaltSessionTokenRequest.pending, (state) => {
      state.getCobaltSessionTokenRequest.status = RequestStatus.Pending;
      state.getCobaltSessionTokenRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getCobaltSessionTokenRequest.fulfilled, (state, action) => {
      state.getCobaltSessionTokenRequest.status = RequestStatus.Succeeded;
      state.getCobaltSessionTokenRequest.message = action.payload.message;
      state.cobaltToken = action.payload.payload.token;
      state.isLoading = false;
    });
    builder.addCase(getCobaltSessionTokenRequest.rejected, (state, action) => {
      state.getCobaltSessionTokenRequest.status = RequestStatus.Failed;
      state.getCobaltSessionTokenRequest.error = action.payload;
      state.isLoading = false;
    });

    // Subscribe Cobalt Event
    builder.addCase(cobaltEventsSubscribeRequest.pending, (state) => {
      state.cobaltEventsSubscribeRequest.status = RequestStatus.Pending;
      state.cobaltEventsSubscribeRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(cobaltEventsSubscribeRequest.fulfilled, (state, action) => {
      state.cobaltEventsSubscribeRequest.status = RequestStatus.Succeeded;
      state.cobaltEventsSubscribeRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(cobaltEventsSubscribeRequest.rejected, (state, action) => {
      state.cobaltEventsSubscribeRequest.status = RequestStatus.Failed;
      state.cobaltEventsSubscribeRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get Integration Setting
    builder.addCase(getIntegrationAdminSettingRequest.pending, (state) => {
      state.getIntegrationAdminSettingRequest.status = RequestStatus.Pending;
      state.getIntegrationAdminSettingRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      getIntegrationAdminSettingRequest.fulfilled,
      (state, action) => {
        state.getIntegrationAdminSettingRequest.status =
          RequestStatus.Succeeded;
        state.integrationSettings = action.payload.payload;
        state.getIntegrationAdminSettingRequest.message =
          action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getIntegrationAdminSettingRequest.rejected,
      (state, action) => {
        state.getIntegrationAdminSettingRequest.status = RequestStatus.Failed;
        state.getIntegrationAdminSettingRequest.error = action.payload;
        state.isLoading = false;
      },
    );

    // Change Integration Setting
    builder.addCase(changeIntegrationSettingRequest.pending, (state) => {
      state.changeIntegrationSettingRequest.status = RequestStatus.Pending;
      state.changeIntegrationSettingRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      changeIntegrationSettingRequest.fulfilled,
      (state, action) => {
        state.changeIntegrationSettingRequest.status = RequestStatus.Succeeded;
        state.changeIntegrationSettingRequest.message = action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      changeIntegrationSettingRequest.rejected,
      (state, action) => {
        state.changeIntegrationSettingRequest.status = RequestStatus.Failed;
        state.changeIntegrationSettingRequest.error = action.payload;
        state.isLoading = false;
      },
    );

    // Generate Integration API Token
    builder.addCase(generateIntegrationApiTokenRequest.pending, (state) => {
      state.generateIntegrationApiTokenRequest.status = RequestStatus.Pending;
      state.generateIntegrationApiTokenRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(
      generateIntegrationApiTokenRequest.fulfilled,
      (state, action) => {
        state.generateIntegrationApiTokenRequest.status =
          RequestStatus.Succeeded;
        state.generateIntegrationApiTokenRequest.message =
          action.payload.message;
        state.isLoading = false;
      },
    );
    builder.addCase(
      generateIntegrationApiTokenRequest.rejected,
      (state, action) => {
        state.generateIntegrationApiTokenRequest.status = RequestStatus.Failed;
        state.generateIntegrationApiTokenRequest.error = action.payload;
        state.isLoading = false;
      },
    );
  },
});

export const { resetCreateApiTokenRequest } = apiTokensSlice.actions;

export default apiTokensSlice.reducer;
