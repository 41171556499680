import { ConnectedProps, connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import ResetPassword from './reset-password';
import { resetPasswordRequest } from './extra-actions';

const mapStateToProps = (state: RootState) => ({
  resetPasswordStatus: state.resetPassword.status,
  resetPasswordError: state.resetPassword.error,
});

const mapDispatchToProps = {
  sendResetPasswordRequest: (email: string) => resetPasswordRequest({ email }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(ResetPassword));
