import React, { useEffect, useMemo, useState } from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import classNames from 'classnames';

import {
  UserList,
  Users as UsersIcon,
  Trash,
  Forbid,
  Send,
  Copy,
  CircleCheck,
} from '@saleshandy/icons';
import type { IProps } from './types';
import type { User } from '../../../../types/users-and-teams';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import {
  Action,
  Column,
  PaginationShowHide,
} from '../../../../../../shared/design-system/components/organisms/table/types';
import toaster, { Theme } from '../../../../../../shared/toaster';

import UserNameCell from './cells/user-name-cell';
import TeamsCell from './cells/teams-cell';
import ActionsCell from './cells/actions-cell';
import ChangeRoleModal from './modals/change-role-modal';
import AssignTeamModal from './modals/assign-team-modal';
import DeleteUserModal from './modals/delete-user-modal';
import DisableUserModal from './modals/disable-user-modal';
import InviteUserModal from './modals/invite-user-modal';
import AgencyInviteUserModal from './modals/agency-invite-user-modal';
import { UserRole, UserSortBy } from '../../../../enums/users-and-teams';
import {
  capitalize,
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { getInviteUserMessage } from './utils/get-invite-user-message';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import { AgencyInviteUserRequestPayload } from '../../../../types/request-payload';
import { shouldShowUpgradePlanModal } from '../../utils/should-show-upgrade-plan-modal';

const Users: React.FC<IProps> = ({
  filters,
  onFilterChange,
  users,
  usersPaginationOptions,
  userTimezone,
  teamsList,
  inviteUserModal,
  hideInviteUserModal,
  atmRole,
  userId,
  keepAllTeamsAssigned,
  tableRef,

  sendGetUsersRequest,
  sendGetAssignTeamSettingRequest,
  getUsersRequestStatus,

  sendChangeUserRoleRequest,
  changeUserRoleRequestStatus,
  changeUserRoleRequestError,
  resetChangeUserRoleRequest,

  sendAssignTeamRequest,
  resetAssignTeamRequest,
  assignTeamRequestStatus,
  assignTeamRequestError,

  sendGetTeamsListRequest,
  getTeamsListRequestStatus,

  sendAddUserRequest,
  resetAddUserRequest,
  addUserRequestStatus,
  addUserRequestError,
  userInvitedCount,

  sendDeleteUserRequest,
  resetDeleteUserRequest,
  deleteUserRequestStatus,
  deleteUserRequestError,

  sendDisableUserRequest,
  resetDisableUserRequest,
  disableUserRequestStatus,
  disableUserRequestError,

  sendResendInviteRequest,
  resetResendInviteRequest,
  resendInviteRequestStatus,
  resendInviteRequestError,

  sendReactivateUserRequest,
  resetReactivateUserRequest,
  reactivateUserRequestStatus,
  reactivateUserRequestError,

  sendAgencyInviteUserRequest,
  resetAgencyInviteUserRequest,
  agencyInviteUserRequestStatus,
  agencyInviteUserRequestError,
  setPremiumFeatureMeta,
}) => {
  const [actionUser, setActionUser] = useState<User>(null);
  const [changeRoleModal, setChangeRoleModal] = useState(false);
  const [assignTeamModal, setAssignTeamModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [disableUserModal, setDisableUserModal] = useState(false);

  const hideChangeRoleModal = () => {
    setChangeRoleModal(false);
  };

  const hideAssignTeamModal = () => {
    setAssignTeamModal(false);
  };

  const hideDeleteUserModal = () => {
    setDeleteUserModal(false);
  };

  const hideDisableUserModal = () => {
    setDisableUserModal(false);
  };

  const onChangeRole = (userRole: UserRole) => {
    sendChangeUserRoleRequest({
      id: actionUser.id,
      userRole,
    });
  };

  const onAssignTeam = (teamIds: number[], isDefaultTeamMember: boolean) => {
    sendAssignTeamRequest({
      id: actionUser.id,
      teamIds,
      isDefaultTeamMember,
    });
  };

  const onAgencyInviteUser = (payload: AgencyInviteUserRequestPayload) => {
    sendAgencyInviteUserRequest(payload);
  };

  const onDeleteUser = () => {
    sendDeleteUserRequest(actionUser.id);
  };

  const onDisableUser = () => {
    sendDisableUserRequest(actionUser.id);
  };

  const getUpgradePlanModalTitleAndBody = (key: string) => {
    if (key === 'changeRole') {
      return {
        title: 'Upgrade your plan to manage roles.',
        body: [
          'The Outreach Starter doesn’t allow you to manage roles.',
          'Please upgrade to the Outreach Pro or Higher plans to manage roles.',
        ],
      };
    }

    if (key === 'assignTeam') {
      return {
        title: 'Upgrade your plan to manage teams.',
        body: [
          'The Outreach Starter doesn’t allow you to manage teams.',
          'Please upgrade to the Outreach Pro or Higher plans to manage teams.',
        ],
      };
    }

    if (key === 'disable' || key === 'enable') {
      return {
        title: 'Upgrade your plan to manage users.',
        body: [
          'The Outreach Starter doesn’t allow you to manage users.',
          'Please upgrade to the Outreach Pro or Higher plans to manage users.',
        ],
      };
    }

    if (key === 'resend') {
      return {
        title: 'Upgrade your plan to invite users.',
        body: [
          'The Outreach Starter doesn’t allow you to invite users.',
          'Please upgrade to the Outreach Pro or Higher plans to invite users.',
        ],
      };
    }

    return;
  };

  const onAction = (key: string, row: User) => {
    if (key === 'copyUserId') {
      navigator.clipboard.writeText(row?.hashId?.toString());
      toaster.success('User ID Successfully Copied', { theme: Theme.New });
      return;
    }

    if (key === 'delete') {
      setDeleteUserModal(true);
      setActionUser(row);
      return;
    }

    if (
      !hasPermission(Permissions.ACCOUNT_USER_INVITE) &&
      shouldShowUpgradePlanModal()
    ) {
      // create a separate function to get the title and body text based on key passed
      const { title, body } = getUpgradePlanModalTitleAndBody(key);
      setPremiumFeatureMeta({
        show: true,
        title,
        body,
      });
      return;
    }

    if (key === 'changeRole') {
      setChangeRoleModal(true);
      setActionUser(row);
      return;
    }
    if (key === 'assignTeam') {
      sendGetTeamsListRequest();
      setAssignTeamModal(true);
      setActionUser(row);
      return;
    }
    if (key === 'disable') {
      setDisableUserModal(true);
      setActionUser(row);
      return;
    }
    if (key === 'enable') {
      sendReactivateUserRequest(row.id);
      return;
    }
    if (key === 'resend') {
      sendResendInviteRequest(row.id);
    }
  };

  const onSortChange = (_sortBy, order) => {
    onFilterChange({
      sortBy: UserSortBy.LastLogin,
      sortOrder: order === 'asc' ? 1 : -1,
    });
  };

  const actions: Action[] = useMemo(
    () => [
      {
        key: 'changeRole',
        customIcon: true,
        iconElement: <UserList />,
        displayName: 'Change Role',
      },
      {
        key: 'assignTeam',
        customIcon: true,
        iconElement: <UsersIcon />,
        displayName: 'Assign Team',
      },
      {
        key: 'delete',
        customIcon: true,
        iconElement: <Trash />,
        displayName: 'Delete',
      },
      {
        key: 'disable',
        customIcon: true,
        iconElement: <Forbid />,
        displayName: 'Disable',
      },
      {
        key: 'enable',
        customIcon: true,
        iconElement: <CircleCheck />,
        displayName: 'Enable',
      },
      {
        key: 'resend',
        customIcon: true,
        iconElement: <Send />,
        displayName: 'Resend Invite',
      },
      {
        key: 'copyUserId',
        customIcon: true,
        iconElement: <Copy />,
        displayName: 'Copy User Id',
      },
    ],
    [],
  );

  const columns: Column[] = useMemo(
    () =>
      [
        {
          dataField: 'firstName',
          text: 'Name',
          align: 'left',
          component: (_cell, row, rowIndex) => (
            <UserNameCell
              isLastRow={rowIndex + 1 === users.length}
              user={row}
              userId={userId}
            />
          ),
          headerLoadingSkeleton: <SkeletonLoading width={34} height={14} />,
          cellLoadingSkeleton: (
            <div>
              <SkeletonLoading width={102} height={14} />
              <SkeletonLoading width={168} height={10} />
            </div>
          ),
        },
        {
          dataField: 'role',
          text: 'User Role',
          align: 'left',
          component: (cell) => (
            <span className="text-cell">{capitalize(cell)}</span>
          ),
          headerLoadingSkeleton: <SkeletonLoading width={32} height={14} />,
          cellLoadingSkeleton: <SkeletonLoading width={59} height={14} />,
        },
        hasPermission(Permissions.ACCOUNT_TEAM_MEMBER_READ) ||
        hasPermission(Permissions.TEAM_MEMBER_READ) ||
        hasPermission(Permissions.TEAM_TEAM_MEMBER_READ)
          ? {
              dataField: 'teams',
              text: 'Teams',
              align: 'left',
              component: (cell) => <TeamsCell teams={cell} />,
              headerLoadingSkeleton: <SkeletonLoading width={68} height={14} />,
              cellLoadingSkeleton: (
                <div className="d-flex">
                  <SkeletonLoading width={66} height={14} className="mr-2" />
                  <SkeletonLoading width={66} height={14} />
                </div>
              ),
            }
          : null,
        {
          dataField: 'lastSeen',
          text: 'Last Login',
          align: 'left',
          sort: true,
          onSort: onSortChange,
          component: (_cell, row, rowIndex) => (
            <ActionsCell
              userId={userId}
              row={row}
              actions={actions}
              onAction={onAction}
              userTimezone={userTimezone}
              isLastRow={rowIndex + 1 === users.length}
              currentUserATMRole={atmRole}
            />
          ),
          headerLoadingSkeleton: <SkeletonLoading width={70} height={14} />,
          cellLoadingSkeleton: <SkeletonLoading width={99} height={14} />,
        },
      ].filter((col) => col !== null) as Column[],
    [users],
  );

  const onPaginationOptionsChangeHandler = ({ page, limit }) => {
    onFilterChange({
      pageNum: filters.pageSize !== limit ? 1 : page,
      pageSize: limit,
    });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: changeUserRoleRequestStatus,
      onSuccess: () => {
        toaster.success('User Role Successfully Changed', { theme: Theme.New });
        sendGetUsersRequest(filters);
        setActionUser(null);
        hideChangeRoleModal();
        resetChangeUserRoleRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: changeUserRoleRequestError,
          onError: () => {
            toaster.error(changeUserRoleRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        setActionUser(null);
        hideChangeRoleModal();
        resetChangeUserRoleRequest();
      },
    });
  }, [changeUserRoleRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: assignTeamRequestStatus,
      onSuccess: () => {
        toaster.success('Team Successfully Assigned', { theme: Theme.New });
        sendGetUsersRequest(filters);
        setActionUser(null);
        hideAssignTeamModal();
        resetAssignTeamRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: assignTeamRequestError,
          onError: () => {
            toaster.error(assignTeamRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        setActionUser(null);
        hideAssignTeamModal();
        resetAssignTeamRequest();
      },
    });
  }, [assignTeamRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteUserRequestStatus,
      onSuccess: () => {
        toaster.success('User Successfully Deleted', { theme: Theme.New });
        sendGetUsersRequest(filters);
        setActionUser(null);
        hideDeleteUserModal();
        resetDeleteUserRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: deleteUserRequestError,
          onError: () => {
            toaster.error(deleteUserRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        setActionUser(null);
        hideDeleteUserModal();
        resetDeleteUserRequest();
      },
    });
  }, [deleteUserRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: disableUserRequestStatus,
      onSuccess: () => {
        toaster.success('User Successfully Disabled', { theme: Theme.New });
        sendGetUsersRequest(filters);
        setActionUser(null);
        hideDisableUserModal();
        resetDisableUserRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: disableUserRequestError,
          onError: () => {
            toaster.error(disableUserRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        setActionUser(null);
        hideDisableUserModal();
        resetDisableUserRequest();
      },
    });
  }, [disableUserRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: reactivateUserRequestStatus,
      onSuccess: () => {
        toaster.success('User Successfully Enabled', { theme: Theme.New });
        sendGetUsersRequest(filters);
        resetReactivateUserRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: reactivateUserRequestError,
          onError: () => {
            toaster.error(reactivateUserRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        resetReactivateUserRequest();
      },
    });
  }, [reactivateUserRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: resendInviteRequestStatus,
      onSuccess: () => {
        toaster.success('Invite Successfully Sent', { theme: Theme.New });
        sendGetUsersRequest(filters);
        resetResendInviteRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: resendInviteRequestError,
          onError: () => {
            toaster.error(resendInviteRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        resetResendInviteRequest();
      },
    });
  }, [resendInviteRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: addUserRequestStatus,
      onSuccess: () => {
        getInviteUserMessage(userInvitedCount);
        sendGetUsersRequest(filters);
        hideInviteUserModal();
        resetAddUserRequest();
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: addUserRequestError,
          onError: () => {
            toaster.error(addUserRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        hideInviteUserModal();
        resetAddUserRequest();
      },
    });
  }, [addUserRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: agencyInviteUserRequestStatus,
      onSuccess: () => {
        sendGetUsersRequest(filters);
        hideInviteUserModal();
        resetAgencyInviteUserRequest();
        toaster.success('Invite Successfully Sent', {
          theme: Theme.New,
        });
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: agencyInviteUserRequestError,
          onError: () => {
            toaster.error(agencyInviteUserRequestError.message, {
              theme: Theme.New,
            });
          },
        });
        hideInviteUserModal();
        resetAgencyInviteUserRequest();
      },
    });
  }, [agencyInviteUserRequestStatus]);

  const isLoading = getIsRequestPending(getUsersRequestStatus);

  const tableWrapperClasses = classNames([
    {
      'four-cols-users-table': columns.length === 4,
      'three-cols-users-table': columns.length === 3,
    },
  ]);

  return (
    <div className="users-and-teams--users">
      <Table
        paginationOptions={{
          options: {
            totalElements: usersPaginationOptions?.totalItems,
            page: filters.pageNum,
            limit: filters.pageSize,
          },
        }}
        onPaginationOptionsChange={onPaginationOptionsChangeHandler}
        columns={columns}
        data={users || []}
        headerVisibleForGenerateColumn={true}
        borderOverActions={false}
        isNewPagination={true}
        pagination={PaginationShowHide.SHOW}
        bodyWrapperClasses="users-and-teams--table-body-wrapper"
        isLoading={isLoading}
        tableWrapperClasses={tableWrapperClasses}
        tableRef={tableRef}
      />

      <ChangeRoleModal
        show={changeRoleModal}
        defaultRole={actionUser?.role}
        onSubmit={onChangeRole}
        onClose={hideChangeRoleModal}
        isLoading={getIsRequestPending(changeUserRoleRequestStatus)}
        atmRole={atmRole}
      />

      {assignTeamModal && (
        <AssignTeamModal
          show={assignTeamModal}
          onSubmit={onAssignTeam}
          teamsList={teamsList}
          actionUser={actionUser}
          onClose={hideAssignTeamModal}
          keepAllTeamsAssigned={keepAllTeamsAssigned}
          isLoading={getIsRequestPending(assignTeamRequestStatus)}
          getTeamsListRequestStatus={getTeamsListRequestStatus}
          sendGetAssignTeamSettingRequest={sendGetAssignTeamSettingRequest}
        />
      )}

      <DeleteUserModal
        show={deleteUserModal}
        onSubmit={onDeleteUser}
        onClose={hideDeleteUserModal}
        isLoading={getIsRequestPending(deleteUserRequestStatus)}
      />

      <DisableUserModal
        show={disableUserModal}
        onSubmit={onDisableUser}
        onClose={hideDisableUserModal}
        isLoading={getIsRequestPending(disableUserRequestStatus)}
      />

      {hasPermission(Permissions.AGENCY_USER_INVITE) ? (
        <AgencyInviteUserModal
          show={inviteUserModal}
          onClose={hideInviteUserModal}
          onSubmit={onAgencyInviteUser}
          isLoading={getIsRequestPending(agencyInviteUserRequestStatus)}
        />
      ) : (
        <InviteUserModal
          show={inviteUserModal}
          teamsList={teamsList}
          onClose={hideInviteUserModal}
          onSubmit={sendAddUserRequest}
          addUserRequestStatus={addUserRequestStatus}
          sendGetTeamsListRequest={sendGetTeamsListRequest}
          getTeamsListRequestStatus={getTeamsListRequestStatus}
          atmRole={atmRole}
        />
      )}
    </div>
  );
};

export default Users;
