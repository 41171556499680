import React from 'react';
import store from '../../../store';

import Logo from '../../../shared/components/logo';
import { SHLogoDark } from '../../../shared/svg';

const TopLogo = ({ prefix, children = <></> }) => {
  const state = store.getState();

  const { agencyConfig } = state.home;

  return (
    <div className="top-logo d-flex justify-content-center align-items-center">
      <span className="semibold-4 blue-txt-6 mr-2 pr-1">{prefix}</span>
      {agencyConfig?.agencyName === 'saleshandy' ? (
        <SHLogoDark />
      ) : (
        <Logo
          logoTextStyles={{
            color: '#212529',
            fontSize: '1.25rem',
          }}
          isTertiary
        />
      )}
      {children}
    </div>
  );
};

export default TopLogo;
