import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

const IndividualLeadsSkeleton = () => (
  <>
    <div className="individual-leads-skeleton">
      <div className="d-flex align-items-center w-25">
        <div className="span-space-remove">
          <SkeletonLoading width={16} height={16} />
        </div>
        <div className="span-space-remove">
          <SkeletonLoading
            width={50}
            height={50}
            circle
            className="user-avatar"
          />
        </div>
        <div className="user-details">
          <span className="span-space-remove">
            <SkeletonLoading width={72} height={14} className="" />
          </span>
          <span className="span-space-remove">
            <SkeletonLoading width={150} height={8} className="mt-6" />
          </span>
          <span className="span-space-remove">
            <SkeletonLoading width={120} height={8} className="mt-6" />
          </span>
          <div className="d-flex align-items-center mt-6">
            <span className="span-space-remove">
              <SkeletonLoading width={14} height={14} circle />
            </span>
            <span className="span-space-remove">
              <SkeletonLoading width={14} height={14} circle className="ml-2" />
            </span>
            <span className="span-space-remove">
              <SkeletonLoading width={14} height={14} circle className="ml-2" />
            </span>
          </div>
        </div>
      </div>
      <div className="company-details">
        <div className="d-flex align-items-center">
          <div className="span-space-remove">
            <SkeletonLoading width={20} height={20} />
          </div>
          <div className="span-space-remove">
            <SkeletonLoading width={72} height={12} className="ml-2" />
          </div>
        </div>
        <div className="span-space-remove mt-6">
          <SkeletonLoading width={90} height={8} className="" />
        </div>
        <div className="span-space-remove">
          <SkeletonLoading width={50} height={8} className="" />
        </div>
        <div className="span-space-remove">
          <SkeletonLoading width={100} height={8} className="" />
        </div>
      </div>
      <div className="w-25 inform-details-container">
        <div>
          <SkeletonLoading width={80} height={8} className="" />
        </div>
        <div className="inform-details">
          <SkeletonLoading width={100} height={8} className="inform-details" />
        </div>
        <SkeletonLoading width={130} height={8} className="email-phone" />
      </div>
      <div className="credits-btn-skeleton d-flex align-items-center justify-content-end w-25">
        <SkeletonLoading width={144} height={28} />
      </div>
    </div>
  </>
);

export default IndividualLeadsSkeleton;
