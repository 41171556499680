import React from 'react';
import { useTranslation } from 'react-i18next';
import { AlertTriangle } from '@saleshandy/icons';
import Alert from '../../../../../shared/design-system/ui/alert';
import errorDivContent from '../email-sending-limit-nudge-content';

const HardLimitErrorNudge = ({
  hardLimitError,
  hardLimit,
  onConnectAnotherEmailAccount,
  emailServiceProvider,
}) => {
  const { t } = useTranslation();

  return (
    hardLimitError && (
      <div className="row mt-2">
        <div className="col-md-10">
          <Alert
            variant="warning"
            description={errorDivContent(
              hardLimit,
              onConnectAnotherEmailAccount,
              emailServiceProvider,
              t,
            )}
            icon={AlertTriangle}
          />
        </div>
      </div>
    )
  );
};

export default HardLimitErrorNudge;
