import api from '../../../api';
import { EmailAccountsFilters } from '../components/email-accounts-list/types';
import { EmailAccountMethod } from '../enums/email-account';
import {
  UpdateEmailAccountRampUpSettingsRequestPayload,
  UpdateEmailAccountRequestPayload,
  VerifyEmailAccountRequestPayload,
  EmailTimeIntervalSettingsRequest,
} from '../types/request-payload';
import {
  TestConnectionRequest,
  ConnectSmtpImapAccountRequest,
  UpdateSMTPIMAPAccountRequest,
} from '../types/smtp-imap';

/**
 * Email Account Related API's ---------------------------------------------
 */

// Get Email Accounts List For Table
export const getEmailAccounts = async ({
  order,
  clientIds,
  ...params
}: EmailAccountsFilters) =>
  api.get(`/email-account`, {
    params: {
      order: order === 1 ? 'ASC' : 'DESC',
      ...(clientIds.length > 0 && { clientIds: clientIds.join(',') }),
      ...params,
    },
  });

// Get Email Accounts List for Filters & Select Dropdowns
export const getEmailAccountsList = async () => api.get(`/email-account/list`);

export const connectEmailAccount = async (
  method: string,
  emailAccountId?: number,
) => {
  let requestUrl = `/email-account/connect/${method}`;
  if (emailAccountId) {
    requestUrl = `${requestUrl}/${emailAccountId}`;
  }
  return api.get(requestUrl);
};

// Verify Email Account
export const verifyEmailAccount = async (
  method: EmailAccountMethod,
  payload: VerifyEmailAccountRequestPayload,
) => api.post(`/email-account/verify/${method}`, payload);

// Verify Whitelabel Email Account
export const verifyWhitelabelEmailAccount = async (
  method: EmailAccountMethod,
  payload: VerifyEmailAccountRequestPayload,
) => api.post(`/email-account/whitelabel/verify/${method}`, payload);

// Get Email Account Details
export const getEmailAccount = async (hashId: string | number) =>
  api.get(`/email-account/${hashId}`);

// Get Email Account Settings
export const getEmailAccountSettings = async (hashId: string | number) =>
  api.get(`/email-account/email-account-settings/${hashId}`);

// Update Email Account Details
export const updateEmailAccount = async (
  emailAccountId: number,
  payload: UpdateEmailAccountRequestPayload,
  // TODO: change endpoint in backend as well as frontend.
) => api.patch(`/email-account/${emailAccountId}`, payload);

// Delete Email Account
export const deleteEmailAccount = async (emailAccountId: number) =>
  api.delete(`/email-account/${emailAccountId}`);

// Disconnect Email Account
export const disconnectEmailAccount = async (emailAccountId: number) =>
  api.get(`/email-account/${emailAccountId}/disconnect`);

// Set Default Email Account
export const setDefaultEmailAccount = async (emailAccountId: number) =>
  api.get(`/email-account/${emailAccountId}/make-default`);

/**
 * Email Account Bulk Import/Update Related API's ----------------------------------------
 */

// Import Email Accounts via CSV
export const importEmailAccountCsv = (file: File) => {
  const data = new FormData();
  data.append('file', file);

  return api.post('/email-account/bulk-connect', data);
};

// Bulk Update Email Accounts
export const bulkEmailUpdate = (payload: any) =>
  api.post('/email-account/bulk-operation', payload);

/**
 * Email Account Sending Settings Related API's ----------------------------------------
 */

type PayloadProps = Pick<
  UpdateEmailAccountRampUpSettingsRequestPayload,
  'rampUpInitialSendingLimit' | 'rampUpPercent' | 'rampUpStatus'
>;

// Update Email Account RampUp Settings
export const updateEmailAccountRampUpSettings = async (
  emailAccountId: number,
  payload: PayloadProps,
) =>
  api.patch(`/email-account/ramp-up-settings/${emailAccountId}`, {
    rampUpInitialSendingLimit: Number(payload.rampUpInitialSendingLimit),
    rampUpPercent: Number(payload.rampUpPercent),
    rampUpStatus: payload.rampUpStatus,
  });

// Update Email Account Time Interval Settings
export const emailTimeIntervalSettings = async (
  emailAccountId: number,
  payload: EmailTimeIntervalSettingsRequest,
) => api.patch(`/email-account/interval-settings/${emailAccountId}`, payload);

/**
 * Email Account Setup Score Related API's --------------------------------------------
 */

// Get Email Account Setup Score
export const getEmailAccountSetupScore = async (
  emailAccountId: number,
  refresh: boolean,
) =>
  api.get(`/email-account/email-setup-score/${emailAccountId}`, {
    params: { ...(refresh && { refresh }) },
  });

// Update Email Account Age Manually
export const updateEmailAge = async (
  emailAccountId: number,
  ageInMonths: number,
) => api.patch(`/email-account/${emailAccountId}/email-age`, { ageInMonths });

/**
 * SMTP/IMAP Email Account Related API's ------------------------------------------------
 */

// Test SMTP/IMAP Email Account
export const testSmtpImapConnection = async (payload: TestConnectionRequest) =>
  api.post('/email-account/smtp-imap/test-connection', payload);

// Connect SMTP/IMAP Email Account
export const connectSmtpImapAccount = async (
  payload: ConnectSmtpImapAccountRequest,
) => api.post('/email-account/smtp-imap/connect', payload);

// Get SMTP/IMAP Email Account Details
export const getSmtpImapAccountDetails = async (emailAccountId: number) =>
  api.get(`/email-account/smtp-imap/${emailAccountId}`);

// Update SMTP/IMAP Email Account Details
export const updateSmtpImapAccountDetails = async (
  emailAccountId: number,
  payload: UpdateSMTPIMAPAccountRequest,
) => api.patch(`/email-account/smtp-imap/${emailAccountId}`, payload);

/**
 * MISC -------------------
 */

export const uploadImage = async (
  formData: FormData,
  onUploadProgress: (e: any) => void,
) => api.post('/attachment-broker/upload', formData, { onUploadProgress });
