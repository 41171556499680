import React from 'react';
import ProspectTag from '.';
import { ProspectTagProps } from './prospect-tag';
import Icon from '../icon';

type ProspectTagWithCancelProps = ProspectTagProps & {
  onCancel: (tag: any) => void;
};

/**
 * Enhanced Prospect Tag.
 * Enriched with the CTA to cancel a tag.
 */
const ProspectTagWithCancel: React.FC<ProspectTagWithCancelProps> = ({
  onCancel,
  ...props
}) => {
  return (
    <ProspectTag {...props}>
      <div className="d-flex flex-row align-items-center ml-1">
        <Icon identifier="close" onClick={onCancel} />
      </div>
    </ProspectTag>
  );
};

export default ProspectTagWithCancel;
