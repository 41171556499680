import { SequenceStepType } from '../../../../sequence/enums';
import { isLinkedInType } from '../../tasks-content/utils/helper';

const getContactName = (firstName, lastName) => {
  if (firstName && lastName) {
    return `${firstName} ${lastName}`;
  }
  if (firstName) {
    return firstName;
  }
  return lastName;
};

const getAttributes = (attributes) =>
  attributes?.map(({ attributeValue, fieldRef }) => ({
    value: attributeValue,
    label: fieldRef.label,
    isDefault: fieldRef.isDefault,
  }));

export const getProspectDetails = (attributes) => {
  const field = {
    Email: '',
    'First Name': '',
    'Last Name': '',
    LinkedIn: '',
    Twitter: '',
    Facebook: '',
    Website: '',
    Name: '',
  };

  getAttributes(attributes)?.forEach((contactField) => {
    if (
      contactField.label === 'Email' ||
      contactField.label === 'First Name' ||
      contactField.label === 'Last Name' ||
      contactField.label === 'LinkedIn' ||
      contactField.label === 'Twitter' ||
      contactField.label === 'Facebook' ||
      contactField.label === 'Website'
    ) {
      field[contactField.label] = contactField.value;
    }
  });

  field.Name =
    getContactName(field['First Name'], field['Last Name']) || 'No Name...';

  return field;
};

export const getTaskContentLabel = (taskType: SequenceStepType) => {
  if (
    taskType === SequenceStepType.LinkedInConnectionRequest ||
    taskType === SequenceStepType.LinkedInMessage
  ) {
    return 'LinkedIn Connection Message';
  }

  if (taskType === SequenceStepType.LinkedInInMail) {
    return 'LinkedIn InMail Message';
  }

  return null;
};

export const removeLinkedinPrefix = (text, type) => {
  if (isLinkedInType(type)) {
    return text?.substring(9);
  }

  return text;
};

export const getButtonLabel = (taskType: SequenceStepType) => {
  if (taskType === SequenceStepType.LinkedInConnectionRequest) {
    return 'Send Request';
  }
  if (taskType === SequenceStepType.LinkedInMessage) {
    return 'Send Message';
  }
  if (taskType === SequenceStepType.LinkedInInMail) {
    return 'Send InMail';
  }

  return 'View Profile';
};

export const stepContentMaxLength = {
  [SequenceStepType.LinkedInConnectionRequest]: 300,
  [SequenceStepType.LinkedInMessage]: 8000,
  [SequenceStepType.LinkedInInMail]: 1900,
  [SequenceStepType.LinkedInViewProfile]: 3000,
  [SequenceStepType.LinkedInPostInteraction]: 3000,
  [SequenceStepType.Call]: 3000,
  [SequenceStepType.Custom]: 3000,
};
