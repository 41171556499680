import { connect, ConnectedProps } from 'react-redux';
import {
  getSequenceListRequest,
  getStepListRequest,
} from '../../../../../extra-actions';
import {
  resetSelectedContacts,
  resetSequenceAndStepData,
} from '../../../../../prospect-slice';
import { RootState } from '../../../../../../../store/root-reducer';
import AddToSequenceModal from './add-to-sequence-modal';

const mapStateToProps = (state: RootState) => ({
  sequenceList: state.prospect.sequences,
  getSequenceListRequestStatus: state.prospect.getSequenceListRequest.status,
  getSequenceListRequestError: state.prospect.getSequenceListRequest.error,
  stepList: state.prospect.steps,
  getStepListRequestStatus: state.prospect.getStepListRequest.status,
  getStepListRequestError: state.prospect.getStepListRequest.error,
  addToStepRequestStatus: state.prospect.addToStepRequest.status,
});

const mapDispatchToProps = {
  sendGetSequenceList: () => getSequenceListRequest(),
  sendGetSequenceStepList: (sequenceId) => getStepListRequest({ sequenceId }),
  resetStepAndSequence: () => resetSequenceAndStepData(),
  resetSelectedContacts: () => resetSelectedContacts(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  onHide: () => void;
  show: boolean;
  addToSequence: (sequenceId, stepId) => void;
  sequence?: Record<string, number | string>;
  isSubscribed?: boolean;
  currentSequenceId?: number;
};

export default connector(AddToSequenceModal);
