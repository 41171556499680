import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../../store/root-reducer';

import DoNotContactListDetailsInput from './do-not-contact-list-details-input';

import { UpdateDoNotContactListDetailsPayload } from '../../../../../../types/do-not-contact-list';

import { updateDoNotContactListDetailsRequest } from '../../../../extra-actions';
import { resetUpdateDoNotContactListDetailsRequest } from '../../../../do-not-contact-slice';

const mapStateToProps = (state: RootState) => ({
  updateDoNotContactListDetailsRequestStatus:
    state.doNotContact.updateDoNotContactListDetailsRequest.status,
  updateDoNotContactListDetailsRequestMessage:
    state.doNotContact.updateDoNotContactListDetailsRequest.message,
  updateDoNotContactListDetailsRequestError:
    state.doNotContact.updateDoNotContactListDetailsRequest.error,

  updateDoNotContactListDetailsResponse:
    state.doNotContact.updateDoNotContactListDetailsResponse,
});

const mapDispatchToProps = {
  sendUpdateDoNotContactListDetailsRequest: (
    payload: UpdateDoNotContactListDetailsPayload,
  ) => updateDoNotContactListDetailsRequest(payload),

  resetUpdateDoNotContactListDetailsRequest: () =>
    resetUpdateDoNotContactListDetailsRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  doNotContactListId: string;
  isGlobalList: boolean;
};

export default connector(DoNotContactListDetailsInput);
