import React, { useState, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';

import type { ValidateCurrentPasswordProps } from '../types';
import { ChangeEmailModalFlow } from '../enums';

import Input from '../../../../../../../shared/design-system/components/input';

import Title from './sub-components/title';
import Description from './sub-components/description';
import Action from './sub-components/action';
import Footer from './sub-components/footer';
import Divider from './sub-components/divider';
import {
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../../shared/utils';

const ValidateCurrentPassword: React.FC<ValidateCurrentPasswordProps> = ({
  email,
  onCancel,
  onSubmit,
  isCurrentPasswordValid: isValid,
  onModalStateChange,
  verifyCurrentPasswordRequestStatus,
  resetIsCurrentPasswordValid,
}) => {
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [isCurrentPasswordValid, setIsCurrentPasswordValid] = useState<boolean>(
    isValid,
  );

  const onShowPasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const onPasswordChange = (value: string) => {
    setPassword(value);

    if (value === '') {
      setIsCurrentPasswordValid(true);
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit(password);
    }
  };

  const handleOnModalStateChange = () => {
    resetIsCurrentPasswordValid();
    onModalStateChange(ChangeEmailModalFlow.ResetPassword);
  };

  const handleOnSubmit = () => {
    onSubmit(password);
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: verifyCurrentPasswordRequestStatus,
      onSuccess: () => {
        setIsCurrentPasswordValid(isValid);
      },
    });
  }, [verifyCurrentPasswordRequestStatus]);

  useEffect(
    () => () => {
      setPassword('');
      setIsCurrentPasswordValid(true);
      resetIsCurrentPasswordValid();
    },
    [],
  );

  const isVerifyCurrentPasswordRequestPending = getIsRequestPending(
    verifyCurrentPasswordRequestStatus,
  );

  return (
    <>
      <Title>
        <p>
          Your current email is <span className="bold">{email}</span>
        </p>
      </Title>

      <Description content="Please enter your current login password to continue changing email." />

      <Action>
        <div className="my-profile-input-container">
          <Input
            name="password"
            label="Current Password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter your current password"
            value={password}
            onChange={onPasswordChange}
            onKeyPress={onKeyPress}
            autoComplete="current-password"
            className="my-profile-input modal-input"
            variant={!isCurrentPasswordValid && Input.Variant.Error}
            icons={[
              {
                place: Input.IconPlace.Right,
                identifier: showPassword ? 'eye-alt' : 'eye',
                className: 'pointer blue-txt-15',
                onClick: onShowPasswordToggle,
              },
            ]}
          />

          {!isCurrentPasswordValid && (
            <div className="my-profile-input-error">
              <span>The password you entered is incorrect.</span>

              <Button
                className="p-0 link-cta-btn"
                variant="link"
                onClick={handleOnModalStateChange}
              >
                Forgot Password?
              </Button>
            </div>
          )}
        </div>
      </Action>

      <Divider />

      <Footer>
        <Button
          variant="secondary"
          onClick={onCancel}
          className="secondary-cta-btn"
          disabled={isVerifyCurrentPasswordRequestPending}
        >
          Cancel
        </Button>
        <Button
          loadingText="Verifying..."
          isLoading={isVerifyCurrentPasswordRequestPending}
          disabled={!password || isVerifyCurrentPasswordRequestPending}
          onClick={handleOnSubmit}
        >
          Continue
        </Button>
      </Footer>
    </>
  );
};

export default ValidateCurrentPassword;
