import React from 'react';
import { NavLink } from 'react-router-dom';

type IProps = {
  to: string;
  label: string;
  icon: React.FC<any>;
};

const SettingsNavigationItem: React.FC<IProps> = ({
  to,
  label,
  icon: IconComponent,
}) => (
  <NavLink
    className="settings-navigation--item"
    to={to}
    activeClassName="active"
  >
    <div className="settings-navigation--item-inner">
      <IconComponent />
      <span>{label}</span>
    </div>
  </NavLink>
);

export default SettingsNavigationItem;
