/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FilterComponentType } from '../../type';
import Checkbox from '../../../../shared/design-system/components/atoms/checkbox';

const Autocomplete = (props) => {
  const {
    isExpand,
    placeholder,
    filterKey,
    name,
    className,
    handleFoundValueExceptFromList,
    handleQuery,
  } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);

  // For exact match option
  const [exactMatch, setExactMatch] = useState(null);

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      const trimmedValue = e.target?.value?.trim();
      if (trimmedValue === '') {
        return;
      }
      setInputValue(trimmedValue);
      handleQuery({
        filterKey,
        filterValue: trimmedValue,
        filterType: FilterComponentType.SEARCH_INPUT,
      });
    }
  };

  useEffect(() => {
    let initValue = '';
    if (['location', 'department', 'industry'].includes(filterKey)) {
      initValue = handleFoundValueExceptFromList(filterKey);
    } else {
      initValue = queryParams.get(filterKey) || '';
    }
    setInputValue(initValue);
  }, [location?.search]);

  useEffect(() => {
    if (inputRef && isExpand) {
      inputRef.current.focus();
    }
  }, [isExpand]);

  // for handling show exact match
  const handleShowExactMatch = (checked: boolean) => {
    handleQuery({
      filterKey,
      filterValue: inputValue,
      filterType: FilterComponentType.SEARCH_INPUT,
      showExactMatch: checked,
    });
    setExactMatch(checked);
  };

  // for handling show exact match in referesh or location changes in url
  useEffect(() => {
    if (queryParams.getAll(filterKey)[0]?.includes(`"`)) {
      setExactMatch(true);
    } else {
      setExactMatch(false);
    }
  }, [location?.search]);

  return (
    <div className={`input-container ${className}`}>
      {name && <p className="blue-txt-15 label mb-1">{name}</p>}
      <input
        ref={inputRef}
        type="text"
        autoComplete="off"
        id={`filter-${filterKey}-input`}
        className="auto-search-input"
        onChange={(e) => setInputValue(e?.target?.value)}
        onKeyDown={onKeyDown}
        value={inputValue}
        placeholder={placeholder}
      />
      {!['contactInfo', 'link'].includes(filterKey) && (
        <Checkbox
          value={filterKey}
          className="w-100 show-exact-match-check"
          onChange={(checked) => {
            setExactMatch(checked);
            handleShowExactMatch(checked);
          }}
          checked={exactMatch}
          label={
            <div className="label-container d-inline-block mt-2">
              <p className="checkbox-lable m-0">Show Exact Match</p>
            </div>
          }
        />
      )}
    </div>
  );
};
export default Autocomplete;
