import React from 'react';

type IProps = {
  title: string;
};

const SettingsNavigationTitle: React.FC<IProps> = ({ title }) => (
  <span className="settings-navigation--title">{title}</span>
);

export default SettingsNavigationTitle;
