import React from 'react';
import produce from 'immer';
import { Link } from 'react-router-dom';

import Button from '../../../../../../shared/design-system/ui/button';
import Input from '../../../../../../shared/design-system/components/input';
import PasswordValidationChecklist from '../../../../../../shared/components/password-validation-checklist';
import { SHLogoDark } from '../../../../../../shared/svg';

import { validate } from './validator';
import { IProps, IState } from './types';
import {
  getIsRequestPending,
  executeOnRequestStatusWithPrevStatusCheck,
} from '../../../../../../shared/utils';

class ChangePasswordForm extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        password: '',
        confirmPassword: '',
      },
      errors: {
        password: '',
        confirmPassword: '',
      },
      dirty: {
        password: false,
        confirmPassword: false,
      },
      showPassword: {
        password: false,
        confirmPassword: false,
      },
    };

    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const { changePasswordStatus, showSuccessComponent } = this.props;

    executeOnRequestStatusWithPrevStatusCheck({
      status: changePasswordStatus,
      prevStatus: prevProps.changePasswordStatus,
      onSuccess: () => {
        showSuccessComponent();
      },
    });
  }

  componentWillUnmount() {
    const { hideError } = this.props;

    hideError?.();
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
      }),
    );
    this.onInputBlur(e);
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name], draft.values);
          if (name !== 'confirmPassword' && draft.dirty.confirmPassword) {
            draft.errors.confirmPassword = validate(
              'confirmPassword',
              draft.values.confirmPassword,
              draft.values,
            );
          }
        }
      }),
    );
  }

  onFormSubmit(e) {
    e.preventDefault();

    const { sendChangePasswordRequest } = this.props;

    const { dirty, errors, values } = this.state;

    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key], values);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    this.setState({ errors: errorsRef, dirty: dirtyRef });

    if (isError) {
      return;
    }

    const {
      values: { password },
    } = this.state;

    sendChangePasswordRequest(password);
  }

  togglePasswordVisibility(name) {
    this.setState(
      produce((draft) => {
        draft.showPassword[name] = !draft.showPassword[name];
      }),
    );
  }

  hasErrors() {
    const { errors, dirty } = this.state;

    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' || !dirty[key]) {
        isError = true;
      }
    });

    return isError;
  }

  render() {
    const { values, errors, showPassword, dirty } = this.state;
    const {
      changePasswordStatus,
      changePasswordError,
      changePasswordShowError,
    } = this.props;

    const isLoading = getIsRequestPending(changePasswordStatus);

    return (
      <div className="auth-wrapper">
        <div className="auth-container gap-0">
          <div className="sh-logo">
            <SHLogoDark width={200} />
          </div>

          <h1 className="auth-container--title mb-2">Set your password</h1>
          <p className="auth-container--sub-title mb-4">
            A strong password helps prevent unauthorized access to your account.
          </p>

          <form
            onSubmit={this.onFormSubmit}
            className="auth-container--form mt-2"
          >
            <div className="auth-form-row">
              <div className="auth-form-input password">
                <Input
                  name="password"
                  label="New Password"
                  placeholder="Enter your new password"
                  type={showPassword.password ? 'text' : 'password'}
                  value={values.password}
                  variant={errors.password && Input.Variant.Error}
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  autoComplete="current-password"
                  autoFocus
                  icons={[
                    {
                      place: Input.IconPlace.Right,
                      identifier: showPassword.password ? 'eye-alt' : 'eye',
                      className: 'pointer',
                      onClick: () => this.togglePasswordVisibility('password'),
                    },
                  ]}
                />
              </div>
            </div>
            <div className="auth-form-row">
              <div className="auth-form-input password">
                <Input
                  name="confirmPassword"
                  label="Confirm new password"
                  placeholder="Confirm your new password"
                  type={showPassword.confirmPassword ? 'text' : 'password'}
                  value={values.confirmPassword}
                  variant={errors.confirmPassword && Input.Variant.Error}
                  caption={
                    errors.confirmPassword ||
                    (changePasswordShowError ? changePasswordError.message : '')
                  }
                  onChange={this.onInputChange}
                  onBlur={this.onInputBlur}
                  autoComplete="current-confirm-password"
                  icons={[
                    {
                      place: Input.IconPlace.Right,
                      identifier: showPassword.confirmPassword
                        ? 'eye-alt'
                        : 'eye',
                      className: 'pointer',
                      onClick: () =>
                        this.togglePasswordVisibility('confirmPassword'),
                    },
                  ]}
                />
                <PasswordValidationChecklist
                  password={values.password}
                  isDirty={dirty.password}
                />
              </div>
            </div>

            <Button
              type="submit"
              isFullWidth
              isLoading={isLoading}
              disabled={this.hasErrors() || isLoading}
              loadingText="Setting password, please wait..."
            >
              Submit
            </Button>
          </form>

          <div className="bottom-navigation">
            <p>
              <Link to="/login" tabIndex={0}>
                Return to Log in!
              </Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePasswordForm;
