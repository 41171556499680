import React from 'react';

export const Stackoverflow = ({ width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.747 12.2909H4.75586V11.1593H10.747V12.2909ZM10.6954 10.8061L4.87558 9.58463L5.11515 8.42302L10.9348 9.64449L10.6954 10.8061ZM11.0307 9.30919L5.64203 6.7945L6.14492 5.71675L11.5336 8.23143L11.0307 9.30919ZM11.7132 7.956L7.13882 4.1481L7.89316 3.23805L12.4796 7.04595L11.7132 7.956ZM9.12651 2.04045L10.0847 1.33398L13.629 6.11191L12.6711 6.81838L9.12651 2.04045ZM11.881 13.4227V9.91471H13.1234V14.6675H2.37305V9.91471H3.61783V13.4227H11.881Z"
      fill="currentcolor"
    />
  </svg>
);
