import React from 'react';
import { Button } from '@saleshandy/design-system';

import { Connectivity } from '../../../../../shared/svg';

type IProps = {
  onAddWebhook: VoidFunction;
};

const WebhookEmptyUI: React.FC<IProps> = ({ onAddWebhook }) => (
  <div className="webhook--empty-ui">
    <div className="webhook-empty-ui-image">
      <Connectivity />
    </div>
    <h1>
      Get started with our outgoing webhook feature by setting up your first
      trigger
    </h1>
    <Button variant="secondary" onClick={onAddWebhook}>
      Add Webhook
    </Button>
  </div>
);

export default WebhookEmptyUI;
