import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import { UpdateOOOSettingsPayload } from '../../types/out-of-office';
import { updateOOOSettings } from '../../helpers/out-of-office.api';

export const updateOOOSettingsRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateOOOSettingsPayload,
  { rejectValue: ResponseErrorWithHandled }
>('outOfOffice/updateOOOSettingsRequest', async (payload, thunkAPI) => {
  try {
    return await updateOOOSettings(payload);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
