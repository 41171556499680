import React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { IProps } from './types';
import ErrorBoundaryUI from '../../error-boundary/error-boundary';
import store from '../../../store';
import { version } from '../../../../package.json';

const ErrorBoundaryWrapper: React.FC<IProps> = ({ children }) => {
  const state = store.getState();

  return (
    <ErrorBoundary
      fallback={<ErrorBoundaryUI />}
      showDialog
      beforeCapture={(scope) => {
        scope.setTag('isFallbackUIShown', true);
        scope.setTag('version', version);
        console.log('User Email - ', state?.home?.email);
      }}
      onError={(error: Error) => {
        console.log('Error Message: ', error?.message);
        if (
          error?.message?.includes('NotFoundError') &&
          (error?.message?.includes('insertBefore') ||
            error?.message?.includes('removeChild'))
        ) {
          console.log('Auto Reloading...');
          window.location.reload();
        }
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
