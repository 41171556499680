/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-curly-brace-presence */
import { useState } from 'react';
// import SearchInput from '../../../../shared/design-system/components/atoms/search-input';
// import { IconPlace } from '../../../../shared/design-system/components/dropdown';
import { FilterComponentType } from '../../type';
import { SearchIcon } from '../../../../shared/svg';
import useFirstEffect from '../../../../shared/hooks/useFirstEffect';

export default function KeywordInputComponent({
  handleQuery,
  keywordInputRef,
}) {
  // eslint-disable-next-line no-restricted-globals
  const queryParams = new URLSearchParams(location?.search);
  const [search, setSearch] = useState(queryParams.get('keyword') || '');

  const handlePreQuery = (value = '') => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      // If the trimmed value is empty, don't proceed with the API call
      return;
    }

    handleQuery({
      filterKey: 'keywords',
      filterValue: value,
      filterType: FilterComponentType.SEARCH_INPUT,
    });
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      const trimmedValue = e.target?.value?.trim();

      if (!trimmedValue) {
        // If the trimmed value is empty, don't proceed with the API call
        return;
      }

      setSearch(trimmedValue);
      handlePreQuery(trimmedValue);
    }
  };

  useFirstEffect(() => {
    setSearch(queryParams.get('keyword') || '');
    // eslint-disable-next-line no-restricted-globals
  }, [location?.search]);

  return (
    <>
      <div>
        <div className="auto-search-container">
          <span className="search-icon">
            <SearchIcon width={16} height={16} />
          </span>
          <input
            ref={keywordInputRef}
            type="text"
            className="auto-search-custome-input"
            onChange={(e) => setSearch(e?.target?.value)}
            onKeyDown={onKeyDown}
            value={search}
            placeholder={'Search a name or keyword'}
          />
          {search && (
            <span
              onClick={() => {
                setSearch('');
                handleQuery({
                  filterKey: 'keyword',
                  filterValue: '',
                  filterType: FilterComponentType.SEARCH_INPUT,
                });
              }}
              className="clear-icon"
            >
              <i className="sh-close-o" />
            </span>
          )}
        </div>
      </div>
    </>
  );
}
