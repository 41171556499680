import { Dispatch, SetStateAction } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import { EmailAccountMethod } from '../../../email-accounts/enums/email-account';
import { resetConnectedUsersAndEmailAccountsRequest } from '../billing-subscription/billing-subscription-slice';
import { getConnectedUsersAndEmailAccountsRequest } from '../billing-subscription/extra-actions';
import {
  connectEmailAccountRequest,
  getEmailAccountSettingsRequest,
  getEmailAccountSetupScoreRequest,
} from '../../../email-accounts/extra-actions';
import EmailDailySendingLimit from './email-daily-sending-limit';
import { resetUpdateMaxSendingLimitRequestState } from './email-daily-sending-limit-slice';
import {
  getEspLimitsRequest,
  updateMaxSendingLimitRequest,
} from './extra-actions';
import { EmailAccountSetupScorePayload } from '../../../email-accounts/types/request-payload';
import { FeatureUsage } from '../../../../shared/types/feature-usage';

const mapStateToProps = (state: RootState) => ({
  subscriptionPlan: state.home.subscription?.planCode,
  role: state.home.role,
  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  getEspLimitsRequestStatus:
    state.emailDailySendingLimit.getEspLimitRequest.status,
  espLimits: state.emailDailySendingLimit.espLimits,
  emailAccount: state.emailAccount.emailAccount,
  emailAccountSettings: state.emailAccount.emailAccountSettings,
  updateMaxSendingLimitStatus:
    state.emailDailySendingLimit.updateMaxSendingLimitRequest.status,
  firstName: state.home.adminDetails?.firstName,
  getConnectedUsersAndEmailAccountsRequestStatus:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsRequest
      .status,
  totalEmailAccounts:
    state.billingAndSubscription.getConnectedUsersAndEmailAccountsResponse
      ?.totalEmailAccounts,
  emailAccountQuota: state.home.subscription?.emailAccountQuota,
});

const mapDispatchToProps = {
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
  sendGetEspLimitRequest: () => getEspLimitsRequest(),
  sendUpdateMaxSendingLimitRequest: (emailAccountId: number, limit: number) =>
    updateMaxSendingLimitRequest({ emailAccountId, limit }),
  sendGetEmailAccountSettingsRequest: (hashId: string) =>
    getEmailAccountSettingsRequest(hashId),
  resetUpdateMaxSendingLimitRequestState: () =>
    resetUpdateMaxSendingLimitRequestState(),
  sendGetConnectedUsersAndEmailAccountsRequest: () =>
    getConnectedUsersAndEmailAccountsRequest(),
  resetConnectedUsersAndEmailAccountsRequest: () =>
    resetConnectedUsersAndEmailAccountsRequest(),

  sendGetEmailAccountSetupScoreRequest: (
    payload: EmailAccountSetupScorePayload,
  ) => getEmailAccountSetupScoreRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  isBulkUpdatingEmailAccounts?: boolean;
  setSendingLimit?: Dispatch<SetStateAction<number>>;
  setSendingLimitError?: Dispatch<SetStateAction<boolean>>;
  featureUsages: FeatureUsage[];
};

export default connector(EmailDailySendingLimit);
