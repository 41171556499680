import { SequenceProgress } from '../../../../../components/sequence/enums';
import { StatusBadgeVariant } from '../status-badge/types';

export const getBadgeVariant = (sequenceProgress, t) => {
  let variant;
  let label;

  switch (sequenceProgress) {
    case SequenceProgress.Active:
      variant = StatusBadgeVariant.SUCCESS;
      label = t('messages.active');
      break;
    case SequenceProgress.Pause:
      variant = StatusBadgeVariant.DANGER;
      label = t('messages.paused');
      break;
    case SequenceProgress.Draft:
      variant = StatusBadgeVariant.SECONDARY;
      label = t('messages.draft');
      break;
    default:
      break;
  }

  return { variant, label };
};
