/* eslint-disable no-restricted-syntax */
import React from 'react';
import { withTranslation } from 'react-i18next';
import produce from 'immer';
import { UserStar } from '@saleshandy/icons';
import { validate } from './validator';
import Select from '../../../../../../shared/design-system/components/select';
import Input from '../../../../../../shared/design-system/components/input';
import Modal from '../../../../../../shared/design-system/components/atoms/modal';

import {
  CreateUpdateCustomOutcomeModalState,
  CreateUpdateCustomOutcomeModalProps,
  DropdownCreateUpdateOption,
} from './types';
import { CreateUpdateModalData } from '../../types';
import { capitalize } from '../../../../../../shared/utils';
import { Images } from '../../../../../../shared/app-constants';
import {
  OutcomeSentiment,
  OutcomeSentimentBGColor,
} from '../../../../enums/custom-outcome';

const options: DropdownCreateUpdateOption[] = [
  { name: OutcomeSentiment.Positive, key: OutcomeSentiment.Positive },
  { name: OutcomeSentiment.Negative, key: OutcomeSentiment.Negative },
  { name: OutcomeSentiment.Neutral, key: OutcomeSentiment.Neutral },
];

class CreateUpdateCustomOutcomeModal extends React.Component<
  CreateUpdateCustomOutcomeModalProps,
  CreateUpdateCustomOutcomeModalState
> {
  constructor(props) {
    super(props);

    const { customOutcome } = this.props;

    this.state = {
      values: {
        fieldName: customOutcome ? customOutcome.name : '',
        sentiment: customOutcome
          ? customOutcome.sentiment
          : OutcomeSentiment.Positive,
      },
      errors: {
        fieldName: '',
        sentiment: '',
      },
      dirty: {
        fieldName: false,
        sentiment: false,
      },
      isDefault: customOutcome && customOutcome.isDefault,
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.onInputBlur = this.onInputBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
  }

  onInputChange(value, e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        draft.values[name] = value;
        draft.dirty[name] = true;
        draft.errors[name] = validate(name, value);
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onSelectChange(_option: DropdownCreateUpdateOption) {
    const { values: stateValues, dirty: dirtyValues } = this.state;
    this.setState({
      values: { ...stateValues, sentiment: _option.key },
      dirty: { ...dirtyValues, sentiment: true },
    });
  }

  onInputBlur(e) {
    const { name } = e.target;
    this.setState(
      produce((draft) => {
        if (draft.dirty[name]) {
          draft.errors[name] = validate(name, draft.values[name]);
        }
      }),
    );
  }

  onSubmit(e) {
    e.preventDefault();
    const { dirty, errors, values } = this.state;
    const dirtyKeys = Object.keys(dirty);

    const tempDirtyKeys = { ...dirty };
    const tempErrors = { ...errors };

    for (const key of dirtyKeys) {
      tempDirtyKeys[key] = true;
    }

    const keys = Object.keys(errors);
    let isError = false;

    for (const key of keys) {
      const keyValue = values[key]?.trim();
      const error = validate(key, keyValue);
      tempErrors[key] = error;
      isError = isError || !!error;
    }

    if (isError) {
      this.setState({ errors: tempErrors, dirty: tempDirtyKeys });
      return;
    }

    const { onSave } = this.props;

    const payload: CreateUpdateModalData = {
      label: values.fieldName,
      sentiment: values.sentiment,
    };

    onSave(payload);
  }

  isSubmitDisabled() {
    const { values, errors, dirty } = this.state;
    const { isRequestPending, customOutcome } = this.props;

    return (
      isRequestPending ||
      !(dirty.fieldName || dirty.sentiment) ||
      (values.fieldName === customOutcome?.name &&
        values.sentiment === customOutcome?.sentiment) ||
      !!errors.fieldName ||
      !!errors.sentiment
    );
  }

  render() {
    const { values, errors, isDefault } = this.state;
    const {
      show,
      modalHeaderTitle,
      onClose,
      isRequestPending,
      t,
      disableDropdownBox,
    } = this.props;

    return (
      <Modal
        show={show}
        className="add-prospect-outcome"
        titleContent={
          <div className="modal-header-custom">
            <div className="sidenav__icon">
              <UserStar color="#1D4ED8" width={23} height={23} />
            </div>
            <h4 className="ml-2">{modalHeaderTitle}</h4>
          </div>
        }
        onClose={onClose}
        onSubmit={this.onSubmit}
        showCloseIcon={false}
        cancelButtonText={t(`Cancel`)}
        submitButtonText={t(`Save`)}
        isSubmitLoading={isRequestPending}
        isSubmitDisabled={this.isSubmitDisabled()}
        backdrop="static"
        showSeparator={true}
        extraModalProps={{
          centered: true,
        }}
      >
        <div className="prospect-modal-body">
          <Input
            disabled={!!isDefault}
            name="fieldName"
            label={t(`Outcome Name`)}
            placeholder="ex: Demo Given"
            value={values.fieldName}
            size={Input.Size.Medium}
            variant={errors.fieldName && Input.Variant.Error}
            onChange={this.onInputChange}
            onBlur={this.onInputBlur}
          />
          <div>
            {errors.fieldName && (
              <span className="error-label">
                <img src={Images.AlertTriangleRed} alt="Alert" />
                {t(`messages.${errors.fieldName}`)}
              </span>
            )}
          </div>
          <span className="select-label">{t(`Sentiment`)}</span>{' '}
          <Select<DropdownCreateUpdateOption>
            options={options}
            selectedOptionKey={values.sentiment}
            optionRenderer={(option) => {
              if (option) {
                return (
                  <div className="sentiment-content align-items-center">
                    <div
                      className="sentiment-dot"
                      style={{
                        background: OutcomeSentimentBGColor[option.name],
                      }}
                    />
                    <div>{capitalize(option.name)}</div>
                  </div>
                );
              }
              return '-';
            }}
            showOptionsSeparator={true}
            onChange={([option]) => {
              this.onSelectChange(option);
            }}
            selectedOptionRenderer={([option]) => {
              if (option) {
                return (
                  <div className="sentiment-content align-items-center">
                    <div
                      className="sentiment-dot"
                      style={{
                        background: OutcomeSentimentBGColor[option.name],
                      }}
                    />
                    <div>{capitalize(option.name)}</div>
                  </div>
                );
              }
              return '-';
            }}
            disabled={disableDropdownBox}
          />
        </div>
      </Modal>
    );
  }
}

export default withTranslation()(CreateUpdateCustomOutcomeModal);
