import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  addTeamRequest,
  addUserRequest,
  assignTeamRequest,
  changeUserRoleRequest,
  deleteTeamRequest,
  deleteUserRequest,
  disableUserRequest,
  reactivateUserRequest,
  getMembersListRequest,
  getTeamsListRequest,
  getTeamsRequest,
  getUsersRequest,
  modifyTeamRequest,
  resendInviteRequest,
  getAssignTeamSetting,
  deleteUserFromTeamRequest,
  agencyInviteUserRequest,
  getTeamsAndMembersListRequest,
} from './extra-actions';
import {
  Team,
  MembersList,
  TeamsList,
  User,
  UserPaginationOptions,
  TeamsAndUsersList,
} from '../../types/users-and-teams';
import { UserSettingCode } from '../../../../shared/types/user-setting';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getUsersRequest: RequestState;
  changeUserRoleRequest: RequestState;
  getTeamsListRequest: RequestState;
  assignTeamRequest: RequestState;
  deleteUserRequest: RequestState;
  disableUserRequest: RequestState;
  resendInviteRequest: RequestState;
  reactivateUserRequest: RequestState;
  addUserRequest: RequestState;
  getTeamsRequest: RequestState;
  getMembersListRequest: RequestState;
  getTeamsAndMembersListRequest: RequestState;
  modifyTeamRequest: RequestState;
  deleteTeamRequest: RequestState;
  addTeamRequest: RequestState;
  deleteUserFromTeamRequest: RequestState;
  agencyInviteUserRequest: RequestState;
  userInvitedCount: number;
  getAssignTeamSetting: RequestState;
  users: User[];
  usersPaginationOptions: UserPaginationOptions;
  teamsList: TeamsList[];

  teams: Team[];
  teamsPaginationOptions: UserPaginationOptions;
  membersList: MembersList[];
  keepAllTeamsAssigned: boolean;
  teamsAndUsersList: TeamsAndUsersList[];
};

const initialState: State = {
  getUsersRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  changeUserRoleRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTeamsListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  assignTeamRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  disableUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resendInviteRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  reactivateUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTeamsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getMembersListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTeamsAndMembersListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  modifyTeamRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteTeamRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  addTeamRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteUserFromTeamRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  agencyInviteUserRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  userInvitedCount: 0,
  getAssignTeamSetting: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  users: [],
  usersPaginationOptions: null,
  teamsList: [],
  teams: [],
  teamsPaginationOptions: null,
  membersList: [],
  keepAllTeamsAssigned: false,
  teamsAndUsersList: [],
};

const usersAndTeamsSlice = createSlice({
  name: 'usersAndTeams',
  initialState,
  reducers: {
    resetGetUsersRequest: (state) => {
      state.getUsersRequest = initialState.getUsersRequest;
    },
    resetChangeUserRoleRequest: (state) => {
      state.changeUserRoleRequest = initialState.changeUserRoleRequest;
    },
    resetAssignTeamRequest: (state) => {
      state.assignTeamRequest = initialState.assignTeamRequest;
    },
    resetDeleteUserRequest: (state) => {
      state.deleteUserRequest = initialState.deleteUserRequest;
    },
    resetDisableUserRequest: (state) => {
      state.disableUserRequest = initialState.disableUserRequest;
    },
    resetResendInviteRequest: (state) => {
      state.resendInviteRequest = initialState.resendInviteRequest;
    },
    resetReactivateUserRequest: (state) => {
      state.reactivateUserRequest = initialState.reactivateUserRequest;
    },
    resetAddUserRequest: (state) => {
      state.addUserRequest = initialState.addUserRequest;
      state.userInvitedCount = initialState.userInvitedCount;
    },
    resetGetTeamsListRequest: (state) => {
      state.getTeamsListRequest = initialState.getTeamsListRequest;
    },
    resetGetMembersListRequest: (state) => {
      state.getMembersListRequest = initialState.getMembersListRequest;
    },
    resetGetTeamsAndMembersListRequest: (state) => {
      state.getTeamsAndMembersListRequest =
        initialState.getTeamsAndMembersListRequest;
    },
    resetTeamsAndUsersListData: (state) => {
      state.teamsAndUsersList = initialState.teamsAndUsersList;
    },
    resetGetTeamsRequest: (state) => {
      state.getTeamsRequest = initialState.getTeamsRequest;
    },
    resetDeleteTeamRequest: (state) => {
      state.deleteTeamRequest = initialState.deleteTeamRequest;
    },
    resetAddTeamRequest: (state) => {
      state.addTeamRequest = initialState.addTeamRequest;
    },
    resetModifyTeamRequest: (state) => {
      state.modifyTeamRequest = initialState.modifyTeamRequest;
    },
    resetDeleteUserFromTeamRequest: (state) => {
      state.deleteUserFromTeamRequest = initialState.deleteUserFromTeamRequest;
    },
    resetAgencyInviteUserRequest: (state) => {
      state.agencyInviteUserRequest = initialState.agencyInviteUserRequest;
    },
  },
  extraReducers: (builder) => {
    // Get Users List
    builder.addCase(getUsersRequest.pending, (state: State) => {
      state.getUsersRequest.status = RequestStatus.Pending;
      state.getUsersRequest.error = null;
    });
    builder.addCase(getUsersRequest.fulfilled, (state: State, action) => {
      state.getUsersRequest.status = RequestStatus.Succeeded;
      state.getUsersRequest.message = action.payload.message;
      state.users = action.payload.payload.items;
      state.usersPaginationOptions = action.payload.payload.meta;
    });
    builder.addCase(getUsersRequest.rejected, (state: State, action) => {
      state.getUsersRequest.status = RequestStatus.Failed;
      state.getUsersRequest.error = action.payload;
    });

    // Change User Role
    builder.addCase(changeUserRoleRequest.pending, (state: State) => {
      state.changeUserRoleRequest.status = RequestStatus.Pending;
      state.changeUserRoleRequest.error = null;
    });
    builder.addCase(changeUserRoleRequest.fulfilled, (state: State, action) => {
      state.changeUserRoleRequest.status = RequestStatus.Succeeded;
      state.changeUserRoleRequest.message = action.payload.message;
    });
    builder.addCase(changeUserRoleRequest.rejected, (state: State, action) => {
      state.changeUserRoleRequest.status = RequestStatus.Failed;
      state.changeUserRoleRequest.error = action.payload;
    });

    // Assign Team Request
    builder.addCase(assignTeamRequest.pending, (state: State) => {
      state.assignTeamRequest.status = RequestStatus.Pending;
      state.assignTeamRequest.error = null;
    });
    builder.addCase(assignTeamRequest.fulfilled, (state: State, action) => {
      state.assignTeamRequest.status = RequestStatus.Succeeded;
      state.assignTeamRequest.message = action.payload.message;
    });
    builder.addCase(assignTeamRequest.rejected, (state: State, action) => {
      state.assignTeamRequest.status = RequestStatus.Failed;
      state.assignTeamRequest.error = action.payload;
    });

    // Delete User Request
    builder.addCase(deleteUserRequest.pending, (state: State) => {
      state.deleteUserRequest.status = RequestStatus.Pending;
      state.deleteUserRequest.error = null;
    });
    builder.addCase(deleteUserRequest.fulfilled, (state: State, action) => {
      state.deleteUserRequest.status = RequestStatus.Succeeded;
      state.deleteUserRequest.message = action.payload.message;
    });
    builder.addCase(deleteUserRequest.rejected, (state: State, action) => {
      state.deleteUserRequest.status = RequestStatus.Failed;
      state.deleteUserRequest.error = action.payload;
    });

    // Disable User Request
    builder.addCase(disableUserRequest.pending, (state: State) => {
      state.disableUserRequest.status = RequestStatus.Pending;
      state.disableUserRequest.error = null;
    });
    builder.addCase(disableUserRequest.fulfilled, (state: State, action) => {
      state.disableUserRequest.status = RequestStatus.Succeeded;
      state.disableUserRequest.message = action.payload.message;
    });
    builder.addCase(disableUserRequest.rejected, (state: State, action) => {
      state.disableUserRequest.status = RequestStatus.Failed;
      state.disableUserRequest.error = action.payload;
    });

    // Resend Invite Request
    builder.addCase(resendInviteRequest.pending, (state: State) => {
      state.resendInviteRequest.status = RequestStatus.Pending;
      state.resendInviteRequest.error = null;
    });
    builder.addCase(resendInviteRequest.fulfilled, (state: State, action) => {
      state.resendInviteRequest.status = RequestStatus.Succeeded;
      state.resendInviteRequest.message = action.payload.message;
    });
    builder.addCase(resendInviteRequest.rejected, (state: State, action) => {
      state.resendInviteRequest.status = RequestStatus.Failed;
      state.resendInviteRequest.error = action.payload;
    });

    // Reactivate User Request
    builder.addCase(reactivateUserRequest.pending, (state: State) => {
      state.reactivateUserRequest.status = RequestStatus.Pending;
      state.reactivateUserRequest.error = null;
    });
    builder.addCase(reactivateUserRequest.fulfilled, (state: State, action) => {
      state.reactivateUserRequest.status = RequestStatus.Succeeded;
      state.reactivateUserRequest.message = action.payload.message;
    });
    builder.addCase(reactivateUserRequest.rejected, (state: State, action) => {
      state.reactivateUserRequest.status = RequestStatus.Failed;
      state.reactivateUserRequest.error = action.payload;
    });

    // Add User Request
    builder.addCase(addUserRequest.pending, (state: State) => {
      state.addUserRequest.status = RequestStatus.Pending;
      state.addUserRequest.error = null;
    });
    builder.addCase(addUserRequest.fulfilled, (state: State, action) => {
      state.addUserRequest.status = RequestStatus.Succeeded;
      state.addUserRequest.message = action.payload.message;
      state.userInvitedCount = action.payload.payload?.length || 0;
    });
    builder.addCase(addUserRequest.rejected, (state: State, action) => {
      state.addUserRequest.status = RequestStatus.Failed;
      state.addUserRequest.error = action.payload;
    });

    // Get Team Request
    builder.addCase(getTeamsRequest.pending, (state: State) => {
      state.getTeamsRequest.status = RequestStatus.Pending;
      state.getTeamsRequest.error = null;
    });
    builder.addCase(getTeamsRequest.fulfilled, (state: State, action) => {
      state.getTeamsRequest.status = RequestStatus.Succeeded;
      state.getTeamsRequest.message = action.payload.message;
      state.teams = action.payload.payload.items;
      state.teamsPaginationOptions = action.payload.payload.meta;
    });
    builder.addCase(getTeamsRequest.rejected, (state: State, action) => {
      state.getTeamsRequest.status = RequestStatus.Failed;
      state.getTeamsRequest.error = action.payload;
    });

    // Get Teams List
    builder.addCase(getTeamsListRequest.pending, (state: State) => {
      state.getTeamsListRequest.status = RequestStatus.Pending;
      state.getTeamsListRequest.error = null;
    });
    builder.addCase(getTeamsListRequest.fulfilled, (state: State, action) => {
      state.getTeamsListRequest.status = RequestStatus.Succeeded;
      state.getTeamsListRequest.message = action.payload.message;
      state.teamsList = action.payload.payload;
    });
    builder.addCase(getTeamsListRequest.rejected, (state: State, action) => {
      state.getTeamsListRequest.status = RequestStatus.Failed;
      state.getTeamsListRequest.error = action.payload;
    });

    // Get Members List
    builder.addCase(getMembersListRequest.pending, (state: State) => {
      state.getMembersListRequest.status = RequestStatus.Pending;
      state.getMembersListRequest.error = null;
    });
    builder.addCase(getMembersListRequest.fulfilled, (state: State, action) => {
      state.getMembersListRequest.status = RequestStatus.Succeeded;
      state.getMembersListRequest.message = action.payload.message;
      state.membersList = action.payload.payload;
    });
    builder.addCase(getMembersListRequest.rejected, (state: State, action) => {
      state.getMembersListRequest.status = RequestStatus.Failed;
      state.getMembersListRequest.error = action.payload;
    });

    // Get Teams And Users List
    builder.addCase(getTeamsAndMembersListRequest.pending, (state: State) => {
      state.getTeamsAndMembersListRequest.status = RequestStatus.Pending;
      state.getTeamsAndMembersListRequest.error = null;
    });
    builder.addCase(
      getTeamsAndMembersListRequest.fulfilled,
      (state: State, action) => {
        state.getTeamsAndMembersListRequest.status = RequestStatus.Succeeded;
        state.getTeamsAndMembersListRequest.message = action.payload.message;
        state.teamsAndUsersList = action.payload.payload;
      },
    );
    builder.addCase(
      getTeamsAndMembersListRequest.rejected,
      (state: State, action) => {
        state.getTeamsAndMembersListRequest.status = RequestStatus.Failed;
        state.getTeamsAndMembersListRequest.error = action.payload;
      },
    );

    // Modify Team Request
    builder.addCase(modifyTeamRequest.pending, (state: State) => {
      state.modifyTeamRequest.status = RequestStatus.Pending;
      state.modifyTeamRequest.error = null;
    });
    builder.addCase(modifyTeamRequest.fulfilled, (state: State, action) => {
      state.modifyTeamRequest.status = RequestStatus.Succeeded;
      state.modifyTeamRequest.message = action.payload.message;
      state.membersList = action.payload.payload;
    });
    builder.addCase(modifyTeamRequest.rejected, (state: State, action) => {
      state.modifyTeamRequest.status = RequestStatus.Failed;
      state.modifyTeamRequest.error = action.payload;
    });

    // Delete Team Request
    builder.addCase(deleteTeamRequest.pending, (state: State) => {
      state.deleteTeamRequest.status = RequestStatus.Pending;
      state.deleteTeamRequest.error = null;
    });
    builder.addCase(deleteTeamRequest.fulfilled, (state: State, action) => {
      state.deleteTeamRequest.status = RequestStatus.Succeeded;
      state.deleteTeamRequest.message = action.payload.message;
      state.membersList = action.payload.payload;
    });
    builder.addCase(deleteTeamRequest.rejected, (state: State, action) => {
      state.deleteTeamRequest.status = RequestStatus.Failed;
      state.deleteTeamRequest.error = action.payload;
    });

    // Add Team
    builder.addCase(addTeamRequest.pending, (state: State) => {
      state.addTeamRequest.status = RequestStatus.Pending;
      state.addTeamRequest.error = null;
    });
    builder.addCase(addTeamRequest.fulfilled, (state: State, action) => {
      state.addTeamRequest.status = RequestStatus.Succeeded;
      state.addTeamRequest.message = action.payload.message;
    });
    builder.addCase(addTeamRequest.rejected, (state: State, action) => {
      state.addTeamRequest.status = RequestStatus.Failed;
      state.addTeamRequest.error = action.payload;
    });

    // Delete User From Team
    builder.addCase(deleteUserFromTeamRequest.pending, (state: State) => {
      state.deleteUserFromTeamRequest.status = RequestStatus.Pending;
      state.deleteUserFromTeamRequest.error = null;
    });
    builder.addCase(
      deleteUserFromTeamRequest.fulfilled,
      (state: State, action) => {
        state.deleteUserFromTeamRequest.status = RequestStatus.Succeeded;
        state.deleteUserFromTeamRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      deleteUserFromTeamRequest.rejected,
      (state: State, action) => {
        state.deleteUserFromTeamRequest.status = RequestStatus.Failed;
        state.deleteUserFromTeamRequest.error = action.payload;
      },
    );

    // Get Assign Team Setting
    builder.addCase(getAssignTeamSetting.pending, (state: State) => {
      state.getAssignTeamSetting.status = RequestStatus.Pending;
      state.getAssignTeamSetting.error = null;
    });
    builder.addCase(getAssignTeamSetting.fulfilled, (state: State, action) => {
      state.getAssignTeamSetting.status = RequestStatus.Succeeded;
      state.getAssignTeamSetting.message = action.payload.message;

      const setting = action.payload.payload?.find(
        (item) => item.code === UserSettingCode.DefaultMember,
      );

      if (setting) {
        state.keepAllTeamsAssigned = Boolean(Number(setting?.value) || 0);
      } else {
        state.keepAllTeamsAssigned = false;
      }
    });
    builder.addCase(
      agencyInviteUserRequest.rejected,
      (state: State, action) => {
        state.agencyInviteUserRequest.status = RequestStatus.Failed;
        state.agencyInviteUserRequest.error = action.payload;
      },
    );

    builder.addCase(agencyInviteUserRequest.pending, (state: State) => {
      state.agencyInviteUserRequest.status = RequestStatus.Pending;
      state.agencyInviteUserRequest.error = null;
    });
    builder.addCase(
      agencyInviteUserRequest.fulfilled,
      (state: State, action) => {
        state.agencyInviteUserRequest.status = RequestStatus.Succeeded;
        state.agencyInviteUserRequest.message = action.payload.message;
      },
    );
    builder.addCase(getAssignTeamSetting.rejected, (state: State, action) => {
      state.getAssignTeamSetting.status = RequestStatus.Failed;
      state.getAssignTeamSetting.error = action.payload;
    });
  },
});

export const {
  resetGetUsersRequest,
  resetChangeUserRoleRequest,
  resetAssignTeamRequest,
  resetDeleteUserRequest,
  resetDisableUserRequest,
  resetResendInviteRequest,
  resetReactivateUserRequest,
  resetAddUserRequest,
  resetGetMembersListRequest,
  resetGetTeamsRequest,
  resetDeleteTeamRequest,
  resetAddTeamRequest,
  resetModifyTeamRequest,
  resetDeleteUserFromTeamRequest,
  resetAgencyInviteUserRequest,
  resetGetTeamsAndMembersListRequest,
  resetTeamsAndUsersListData,
} = usersAndTeamsSlice.actions;
export default usersAndTeamsSlice.reducer;
