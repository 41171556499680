/* eslint-disable react/button-has-type */
import React, { useRef, forwardRef } from 'react';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { ChevronLeft, ChevronRight, ChevronDown } from '@saleshandy/icons';
import Input from '../../../../../../shared/design-system/components/input';

type IProps = {
  dt: Date;
  onSelect: (d: Date) => void;
};

const SnoozeDatePicker: React.FC<IProps> = ({ dt, onSelect }) => {
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const CustomInput = forwardRef((props: any, ref) => (
    <Input
      {...props}
      ref={ref}
      className="custom-input"
      onClick={props.onClick}
      icons={[
        {
          place: Input.IconPlace.Right,
          component: (
            <ChevronDown
              width={16}
              height={16}
              className="popover-arrow-color-txt"
              onClick={props.onClick}
            />
          ),
        },
      ]}
    />
  ));

  const handleSelect = (d) => {
    onSelect(d);
    setIsOpen(false);
  };

  return (
    <DatePicker
      minDate={new Date()}
      open={isOpen}
      selected={dt}
      onInputClick={() => setIsOpen(true)}
      onChange={(d) => handleSelect(d)}
      onClickOutside={() => setIsOpen(false)}
      placeholderText="DD/MM/YYYY"
      dateFormat="MMM dd, yyyy"
      id="start-date"
      autoComplete="off"
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            padding: '10px 0 10px 10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <span className="regular-2 font-medium popover-arrow-color-txt">
              {moment(date).format('MMMM YYYY')}
            </span>
          </div>

          <div className="d-flex gap-8">
            <ChevronLeft
              role="button"
              width={24}
              height={24}
              onClick={!prevMonthButtonDisabled && decreaseMonth}
              className="gray-txt-11"
            />
            <ChevronRight
              role="button"
              width={24}
              height={24}
              onClick={!nextMonthButtonDisabled && increaseMonth}
              className="gray-txt-11"
            />
          </div>
        </div>
      )}
      customInput={<CustomInput inputRef={inputRef} />}
    />
  );
};

export default SnoozeDatePicker;
