import React from 'react';
import {
  Carousel as ShCarousel,
  CarouselProps,
} from '@saleshandy/design-system';

const Carousel: React.FC<CarouselProps> = ({ children, ...rest }) => (
  <ShCarousel {...rest}>{children}</ShCarousel>
);

export default Carousel;
