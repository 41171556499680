import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import { Images } from '../../../../shared/app-constants';

const DeniedInvitation = () => {
  const { t } = useTranslation();

  const onRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="vw-100 d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column justify-content-center align-items-center denied-container">
        <img src={Images.AccessDenied} alt="access-denied" />
        <span className="semibold-3 popover-arrow-color-txt">
          Access Denied
        </span>
        <span className="regular-3 font-medium gray-txt-15 text-center">
          The account is currently on the Basic Plan. Please reach out to your
          Admin to upgrade the account and continue using Saleshandy.
        </span>
        <Button onClick={onRefresh} className="mt-4">
          {t('messages.return_to_application')}
        </Button>
      </div>
    </div>
  );
};

export default DeniedInvitation;
