import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CornerUpLeft,
  LayersSubtract,
  Cross,
  Checkbox,
} from '@saleshandy/icons';
import Table from '../../../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../../../shared/design-system/components/organisms/table/types';
import toaster from '../../../../../../../../shared/toaster';
import { OnProspectSequenceActions } from '../../../../../../enums/prospect';
import AddToSequenceModal from '../../../modals/add-to-sequence-modal';
import { getProspectSequenceColumn } from './helper/prospect-column-data';
import { Images } from '../../../../../../../../shared/app-constants';
import EmptyList from '../../../../../../../../shared/design-system/components/molecules/empty-list/empty-list';
import Spinner, {
  SpinnerAnimation,
} from '../../../../../../../../shared/design-system/components/atoms/spinner/spinner';
import { getIsRequestPending } from '../../../../../../../../shared/utils/get-request-status';
import { RequestStatus } from '../../../../../../../../shared/enums/request-status';
import { executeOnRequestStatus } from '../../../../../../../../shared/utils';
import store from '../../../../../../../../store/store';
import { mapSequenceStatusToContactFilter } from '../../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';

type IProps = {
  selectedProspectSequences: any[];
  isSingleProspectSubscribed: boolean;
  sendSequenceMarkAsRepliedRequest: (stepId: number) => void;
  sendRemoveFromSequenceRequest: (sequenceId: number) => void;
  sendAddToStepRequest: (sequenceId, stepId) => void;
  sendGetSingleProspectSequencesDetailsRequest: () => void;
  sendSequenceMarkAsFinishedRequest: (stepId: number) => void;
  getSingleProspectSequencesDetailsStatus: RequestStatus;
  allOutcomes: any;
};

const ProspectSequenceListTable: React.FC<IProps> = ({
  selectedProspectSequences,
  sendSequenceMarkAsRepliedRequest,
  sendAddToStepRequest,
  sendRemoveFromSequenceRequest,
  sendGetSingleProspectSequencesDetailsRequest,
  isSingleProspectSubscribed,
  sendSequenceMarkAsFinishedRequest,
  getSingleProspectSequencesDetailsStatus,
  allOutcomes,
}) => {
  const { t } = useTranslation();
  const state = store.getState();
  const outcomeChangeRequestStatus =
    state.sequence.onOutcomeChangeRequest.status;

  const [showAddToStepModal, setShowAddToStepModal] = useState<boolean>(false);
  const [sequence, setSequence] = useState<Record<string, number | string>>(
    null,
  );

  //* useEffect
  useEffect(() => {
    sendGetSingleProspectSequencesDetailsRequest();
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: outcomeChangeRequestStatus,
      onSuccess: () => {
        sendGetSingleProspectSequencesDetailsRequest();
      },
    });
  }, [outcomeChangeRequestStatus]);

  const hideAddToStepModal = () => {
    setShowAddToStepModal(false);
  };

  const handleAddToSequenceStepModal = () => {
    if (!isSingleProspectSubscribed) {
      toaster.error(
        'It is not possible to add unsubscribed contacts to the sequence',
      );
    } else {
      setShowAddToStepModal(true);
    }
  };

  const addToSequenceStepRequest = (sequenceId, stepId) => {
    sendAddToStepRequest(sequenceId, stepId);
  };

  const data = selectedProspectSequences.map(({ step, sequenceProspect }) => {
    const sequences = {
      value: step.sequence.title,
      id: sequenceProspect.sequenceId,
      stepId: step.id,
      stepNumber: `Step ${step.number}`,
      prospectId: sequenceProspect?.prospect?.id,
      sequenceProspectDealValue: sequenceProspect?.dealValue,
      outcomeId: sequenceProspect?.categoryId,
      userFirstName:
        step.sequence.user && step.sequence.user.firstName
          ? step.sequence.user.firstName
          : '',
      userLastName:
        step.sequence.user && step.sequence.user.firstName
          ? step.sequence.user.lastName
          : '',
      allOutcomes: allOutcomes || [],
    };
    return {
      id: step.id,
      sequences,
      status: mapSequenceStatusToContactFilter(sequenceProspect?.status),
    };
  });

  data.reverse();

  const onAction = (action, row) => {
    switch (action) {
      case OnProspectSequenceActions.MarkAsReplied:
        sendSequenceMarkAsRepliedRequest(row.sequences.stepId);
        break;

      case OnProspectSequenceActions.MoveToStep:
        setSequence({
          id: row.sequences.id,
          name: row.sequences.value,
        });
        handleAddToSequenceStepModal();
        break;

      case OnProspectSequenceActions.RemoveFromSequence:
        sendRemoveFromSequenceRequest(row.sequences.id);
        break;

      case OnProspectSequenceActions.MarkAsFinished:
        sendSequenceMarkAsFinishedRequest(row.sequences.stepId);
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      key: OnProspectSequenceActions.MarkAsReplied,
      displayName: 'Mark as Replied',
      isSVGIcon: true,
      SVGIcon: <CornerUpLeft className="gray-txt-15" />,
    },
    {
      key: OnProspectSequenceActions.MoveToStep,
      displayName: 'Move to Step',
      isSVGIcon: true,
      SVGIcon: <LayersSubtract className="gray-txt-15" />,
    },
    {
      key: OnProspectSequenceActions.RemoveFromSequence,
      displayName: 'Remove from Sequence',
      isSVGIcon: true,
      SVGIcon: <Cross className="gray-txt-15" />,
    },
    {
      key: OnProspectSequenceActions.MarkAsFinished,
      displayName: 'Mark as Finished',
      isSVGIcon: true,
      SVGIcon: <Checkbox className="gray-txt-15" />,
    },
  ];

  if (getIsRequestPending(getSingleProspectSequencesDetailsStatus)) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Spinner animation={SpinnerAnimation.Border} />
      </div>
    );
  }

  if (selectedProspectSequences.length === 0) {
    return (
      <div className="empty-sequence">
        <EmptyList
          description={t('messages.assign_prospect_to_sequence')}
          title={t('messages.prospect_sequences_are_empty')}
          imgSrc={Images.EmptyData1}
        >
          <></>
        </EmptyList>
      </div>
    );
  }

  return (
    <>
      <Table
        columns={getProspectSequenceColumn()}
        data={data}
        onPaginationOptionsChange={null}
        actions={actions}
        onAction={(key, row) => onAction(key, row)}
        paginationOptions={{
          options: {
            limit: 1000,
            page: 0,
            totalElements: 1000,
          },
        }}
        pagination={PaginationShowHide.HIDE}
        bodyWrapperClasses="prospect=details-table-body"
      />
      {showAddToStepModal && (
        <AddToSequenceModal
          show={showAddToStepModal}
          onHide={hideAddToStepModal}
          addToSequence={addToSequenceStepRequest}
          sequence={sequence}
          isSubscribed={isSingleProspectSubscribed}
        />
      )}
    </>
  );
};

export default ProspectSequenceListTable;
