import React from 'react';
import { DoNotContactList } from '../../../../../../../types/do-not-contact-list';

type IProps = {
  doNotContactList: DoNotContactList;
};

const TotalFieldField: React.FC<IProps> = ({ doNotContactList: { total } }) => (
  <p className="total-field">{total}</p>
);

export default TotalFieldField;
