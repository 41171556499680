import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import type { IProps } from './setting-container';
import SettingNavigation from './components/setting-navigation';
import hasPermission from '../../shared/utils/access-control/has-permission';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';
import { Routes } from '../../shared/routes';
import { GlobalSettings } from '../../shared/components/global-settings-wrapper';
import { getGlobalSettingsWrapperCustomClassName } from './helpers/helper';

const MyProfile = React.lazy(() =>
  retryLazy(() => import('./components/my-profile')),
);
const UsersAndTeams = React.lazy(() =>
  retryLazy(() => import('./components/users-and-teams')),
);
const EmailAccounts = React.lazy(() =>
  retryLazy(() => import('./components/email-account')),
);
// const UpdateEmailAccount = React.lazy(() =>
//   retryLazy(
//     () => import('./components/email-account/components/update-email-account'),
//   ),
// );
const Schedule = React.lazy(() =>
  retryLazy(() => import('./components/schedule')),
);
const OutOfOffice = React.lazy(() =>
  retryLazy(() => import('./components/out-of-office')),
);
const CustomField = React.lazy(() =>
  retryLazy(() => import('./components/custom-fields')),
);
const CustomOutcome = React.lazy(() =>
  retryLazy(() => import('./components/custom-outcomes')),
);
const CustomDomain = React.lazy(() =>
  retryLazy(() => import('./components/custom-domain')),
);
const AdminSettings = React.lazy(() =>
  retryLazy(() => import('./components/admin-settings')),
);
const BillingSubscription = React.lazy(() =>
  retryLazy(() => import('./components/billing-subscription')),
);
const DoNotContactList = React.lazy(() =>
  retryLazy(() => import('./components/do-not-contact')),
);
const Integrations = React.lazy(() =>
  retryLazy(() => import('./components/integrations')),
);
const APITokens = React.lazy(() =>
  retryLazy(() => import('./components/api-tokens')),
);
const Webhook = React.lazy(() =>
  retryLazy(() => import('./components/webhook')),
);
const Whitelabel = React.lazy(() =>
  retryLazy(() => import('./components/whitelabel')),
);

const Setting: React.FC<IProps> = () => (
  <Container fluid className="setting-container">
    <Row className="h-100">
      <Col className="h-100">
        <Row className="setting-content">
          <div className="settings-navigation">
            <SettingNavigation />
          </div>
          <div className="setting-components">
            <GlobalSettings.Wrapper
              className={getGlobalSettingsWrapperCustomClassName()}
            >
              <ErrorBoundaryWrapper>
                <Suspense>
                  <Switch>
                    <Route path="/settings/profile" component={MyProfile} />
                    <Route
                      exact
                      path="/settings/email-accounts"
                      component={EmailAccounts}
                    />
                    {/* <Route
                      exact
                      path="/settings/email-accounts/:hashId/:tab"
                      component={UpdateEmailAccount}
                    /> */}
                    <Route
                      path="/settings/custom-fields"
                      component={CustomField}
                    />
                    <Route
                      path="/settings/custom-outcomes"
                      component={CustomOutcome}
                    />
                    <Route
                      path="/settings/custom-domain"
                      component={CustomDomain}
                    />
                    <Route path="/settings/schedules" component={Schedule} />

                    <Route
                      path="/settings/out-of-office"
                      component={OutOfOffice}
                    />

                    {(hasPermission(Permissions.ACCOUNT_USER_READ) ||
                      hasPermission(Permissions.TEAM_SHOW)) && (
                      <Route path="/settings/users" component={UsersAndTeams} />
                    )}

                    {hasPermission(Permissions.ACCOUNT_USER_READ) && (
                      <Route
                        path="/settings/admin-settings"
                        component={AdminSettings}
                      />
                    )}
                    <Route
                      path="/settings/dnc-list"
                      component={DoNotContactList}
                    />
                    <Route
                      path="/settings/billing"
                      component={BillingSubscription}
                    />
                    <Route
                      path={Routes.Setting_Billing_Upgrade_Plan}
                      component={BillingSubscription}
                    />
                    <Route
                      path="/settings/integrations"
                      component={Integrations}
                    />
                    <Route
                      exact
                      path="/settings/whitelabel"
                      component={Whitelabel}
                    />

                    <Route path="/settings/webhook" component={Webhook} />
                    <Route
                      path="/settings/webhook/:webhookId"
                      component={Webhook}
                    />
                    <Route path="/settings/webhook/add" component={Webhook} />

                    <Route path="/settings/api-keys" component={APITokens} />

                    <Redirect to="/settings/profile" />
                  </Switch>
                </Suspense>
              </ErrorBoundaryWrapper>
            </GlobalSettings.Wrapper>
          </div>
        </Row>
      </Col>
    </Row>
  </Container>
);

export default Setting;
