import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';

export type CacheMetadata = {
  queryHash: string;
  timestamp: number;
};

interface ApiCacheState {
  [apiName: string]: CacheMetadata;
}

const apiCacheSlice = createSlice({
  name: 'apiCache',
  initialState: {} as ApiCacheState,
  reducers: {
    setApiCacheMetadata: (
      state,
      action: PayloadAction<{ apiName: string; metadata: CacheMetadata }>,
    ) => {
      const { apiName, metadata } = action.payload;
      state[apiName] = metadata;
    },
    clearAPICacheMetadata: (state, action: PayloadAction<string>) => {
      const apiName = action.payload;
      delete state[apiName];
    },
  },
});

export const {
  setApiCacheMetadata,
  clearAPICacheMetadata,
} = apiCacheSlice.actions;
export const selectApiCacheMetadata = (state: RootStateOrAny) => state.apiCache;
export const apiCacheReducer = apiCacheSlice.reducer;
