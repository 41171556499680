import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';
import {
  deleteDNS,
  deleteResource,
  editCompanyName,
  editResource,
  getDNS,
  getResource,
  saveCompanyName,
  saveDNS,
  uploadResource,
} from './utils/whitelabel.api';
import { ResourceType } from './utils/enums';

export const saveCompanyNameRequest = createAsyncThunk<
  ResponseSuccess,
  { companyName: string },
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/saveCompanyNameRequest', async ({ companyName }, thunkAPI) => {
  try {
    return await saveCompanyName(companyName);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const editCompanyNameRequest = createAsyncThunk<
  ResponseSuccess,
  { companyName: string },
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/editompanyNameRequest', async ({ companyName }, thunkAPI) => {
  try {
    return await editCompanyName(companyName);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getResourceRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/getResourceRequest', async (_args, thunkAPI) => {
  try {
    return await getResource();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const uploadResourceRequest = createAsyncThunk<
  ResponseSuccess,
  { resource: File; resourceType: ResourceType },
  { rejectValue: ResponseErrorWithHandled }
>(
  'whitelabel/uploadResourceRequest',
  async ({ resource, resourceType }, thunkAPI) => {
    try {
      return await uploadResource(resource, resourceType);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const editResourceRequest = createAsyncThunk<
  ResponseSuccess,
  { resource: File; resourceType: ResourceType },
  { rejectValue: ResponseErrorWithHandled }
>(
  'whitelabel/editResourceRequest',
  async ({ resource, resourceType }, thunkAPI) => {
    try {
      return await editResource(resource, resourceType);
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const deleteResourceRequest = createAsyncThunk<
  ResponseSuccess,
  { resourceType: ResourceType },
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/deleteResourceRequest', async ({ resourceType }, thunkAPI) => {
  try {
    return await deleteResource(resourceType);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getDNSRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/getDNSRequest', async (args, thunkAPI) => {
  try {
    return await getDNS();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const saveDNSRequest = createAsyncThunk<
  ResponseSuccess,
  { domain: string },
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/saveDNSRequest', async ({ domain }, thunkAPI) => {
  try {
    return await saveDNS(domain);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const deleteDNSRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('whitelabel/deleteDNSRequest', async (args, thunkAPI) => {
  try {
    return await deleteDNS();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
