import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';

import {
  createDoNotContactListRequest,
  deleteDoNotContactListRequest,
  getDoNotContactListsRequest,
  getDoNotContactListDetailsRequest,
  updateDoNotContactListRequest,
  updateDoNotContactListDetailsRequest,
  getDoNotContactListDetailsMetaRequest,
  deleteDoNotContactListEmailsAndDomainsRequest,
  bulkDeleteDoNotContactListEmailsAndDomainsRequest,
  importDoNotContactListEmailsAndDomainsRequest,
} from './extra-actions';
import {
  BulkSelectedDNCListEmailsAndDomains,
  DoNotContactList,
  DoNotContactListEmailsAndDomains,
  DoNotContactListMeta,
  ImportDoNotContactListEmailsAndDomainsResponse,
  PaginationOptions,
} from '../../types/do-not-contact-list';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getDoNotContactListsRequest: RequestState;
  createDoNotContactListRequest: RequestState;
  updateDoNotContactListRequest: RequestState;
  deleteDoNotContactListRequest: RequestState;
  getDoNotContactListDetailsMetaRequest: RequestState;
  getDoNotContactListDetailsRequest: RequestState;
  updateDoNotContactListDetailsRequest: RequestState;
  updateDoNotContactListDetailsResponse: {
    alreadyAvailableItems: number;
    duplicateOrInvalidItems: number;
    skippedItemsDueToLimitExceeded: number;
    successfullyAddedItems: number;
    totalItems: number;
  };
  deleteDoNotContactListEmailsAndDomainsRequest: RequestState;
  bulkDeleteDoNotContactListEmailsAndDomainsRequest: RequestState;
  importDoNotContactListEmailsAndDomainsRequest: RequestState;
  doNotContactLists: DoNotContactList[];
  doNotContactListsPaginationOptions: PaginationOptions;
  doNotContactListDetailsMeta: DoNotContactListMeta;
  doNotContactListDetails: DoNotContactListEmailsAndDomains[];
  doNotContactListDetailsPaginationOptions: PaginationOptions;
  createdDoNotContactListId: number;
  importDoNotContactListEmailsAndDomainsResponse: ImportDoNotContactListEmailsAndDomainsResponse;

  bulkSelectedDNCListEmailsAndDomains: BulkSelectedDNCListEmailsAndDomains;
  selectedDNCListEmailsAndDomains: DoNotContactListEmailsAndDomains[];
};

const initialState: State = {
  getDoNotContactListsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createDoNotContactListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateDoNotContactListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteDoNotContactListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getDoNotContactListDetailsMetaRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getDoNotContactListDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateDoNotContactListDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateDoNotContactListDetailsResponse: null,
  deleteDoNotContactListEmailsAndDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  bulkDeleteDoNotContactListEmailsAndDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  importDoNotContactListEmailsAndDomainsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  doNotContactLists: [],
  doNotContactListsPaginationOptions: null,
  doNotContactListDetailsMeta: null,
  doNotContactListDetails: [],
  doNotContactListDetailsPaginationOptions: null,
  createdDoNotContactListId: null,
  importDoNotContactListEmailsAndDomainsResponse: null,

  bulkSelectedDNCListEmailsAndDomains: null,
  selectedDNCListEmailsAndDomains: [],
};

const doNotContactSlice = createSlice({
  name: 'doNotContact',
  initialState,
  reducers: {
    resetGetDoNotContactListsRequest: (state) => {
      state.getDoNotContactListsRequest =
        initialState.getDoNotContactListsRequest;
    },
    resetCreateDoNotContactListRequest: (state) => {
      state.createDoNotContactListRequest =
        initialState.createDoNotContactListRequest;
    },
    resetCreatedDoNotContactListId: (state) => {
      state.createdDoNotContactListId = initialState.createdDoNotContactListId;
    },
    resetUpdateDoNotContactListRequest: (state) => {
      state.updateDoNotContactListRequest =
        initialState.updateDoNotContactListRequest;
    },
    resetDeleteDoNotContactListRequest: (state) => {
      state.deleteDoNotContactListRequest =
        initialState.deleteDoNotContactListRequest;
    },
    resetGetDoNotContactListDetailsMetaRequest: (state) => {
      state.getDoNotContactListDetailsMetaRequest =
        initialState.getDoNotContactListDetailsMetaRequest;
    },
    resetDoNotContactListDetailsMeta: (state) => {
      state.doNotContactListDetailsMeta =
        initialState.doNotContactListDetailsMeta;
    },
    resetGetDoNotContactListDetailsRequest: (state) => {
      state.getDoNotContactListDetailsRequest =
        initialState.getDoNotContactListDetailsRequest;
    },
    resetUpdateDoNotContactListDetailsRequest: (state) => {
      state.updateDoNotContactListDetailsRequest =
        initialState.updateDoNotContactListDetailsRequest;
      state.updateDoNotContactListDetailsResponse =
        initialState.updateDoNotContactListDetailsResponse;
    },
    resetDeleteDoNotContactListEmailsAndDomainsRequest: (state) => {
      state.deleteDoNotContactListEmailsAndDomainsRequest =
        initialState.deleteDoNotContactListEmailsAndDomainsRequest;
    },
    resetBulkDeleteDoNotContactListEmailsAndDomainsRequest: (state) => {
      state.bulkDeleteDoNotContactListEmailsAndDomainsRequest =
        initialState.bulkDeleteDoNotContactListEmailsAndDomainsRequest;
    },
    resetImportDoNotContactListEmailsAndDomainsRequest: (state) => {
      state.importDoNotContactListEmailsAndDomainsRequest =
        initialState.importDoNotContactListEmailsAndDomainsRequest;
    },

    // DNC List Emails And Domains Bulk Action Handlers
    onBulkSelectDNCListEmailsAndDomains: (state, action) => {
      state.bulkSelectedDNCListEmailsAndDomains = action.payload;
    },
    clearDNCListEmailsAndDomainsSelection: (state) => {
      state.bulkSelectedDNCListEmailsAndDomains =
        initialState.bulkSelectedDNCListEmailsAndDomains;
      state.selectedDNCListEmailsAndDomains =
        initialState.selectedDNCListEmailsAndDomains;
    },
    selectAllDNCListEmailsAndDomains: (state, action) => {
      if (action.payload.status === true) {
        if (
          state.bulkSelectedDNCListEmailsAndDomains
            ?.isAllDNCListEmailsAndDomainsSelected
        ) {
          action.payload.rows.forEach(({ id: contactId }) => {
            state.bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds = state.bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds.filter(
              (id) => contactId !== id,
            );
          });
        }
        state.selectedDNCListEmailsAndDomains = state.selectedDNCListEmailsAndDomains.concat(
          action.payload.rows,
        );
      } else {
        if (
          state.bulkSelectedDNCListEmailsAndDomains
            ?.isAllDNCListEmailsAndDomainsSelected
        ) {
          action.payload.rows.forEach(({ id }) => {
            state.bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds = state.bulkSelectedDNCListEmailsAndDomains?.deSelectedDNCListEmailsAndDomainsIds.concat(
              [id],
            );
          });
        }
        state.selectedDNCListEmailsAndDomains = state.selectedDNCListEmailsAndDomains.reduce(
          (acc, row) => {
            let isPresent = false;
            action.payload.rows.forEach(({ id }) => {
              if (id === row.id) {
                isPresent = true;
              }
            });
            if (isPresent) {
              return acc;
            }
            return [...acc, row];
          },
          [],
        );
      }
    },
    selectSingleDNCListEmailsAndDomains: (state, action) => {
      if (action.payload.status === true) {
        if (
          state.bulkSelectedDNCListEmailsAndDomains
            ?.isAllDNCListEmailsAndDomainsSelected
        ) {
          state.bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds = state.bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds.filter(
            (id) => id !== action.payload.row.id,
          );
        }
        state.selectedDNCListEmailsAndDomains = state.selectedDNCListEmailsAndDomains.concat(
          [action.payload.row],
        );
      } else {
        if (
          state.bulkSelectedDNCListEmailsAndDomains
            ?.isAllDNCListEmailsAndDomainsSelected
        ) {
          state.bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds = state.bulkSelectedDNCListEmailsAndDomains?.deSelectedDNCListEmailsAndDomainsIds.concat(
            [action.payload.row.id],
          );
        }
        state.selectedDNCListEmailsAndDomains = state.selectedDNCListEmailsAndDomains.filter(
          ({ id }) => id !== action.payload.row.id,
        );
      }
    },
    resetSelectedDNCListEmailsAndDomains: (state) => {
      state.selectedDNCListEmailsAndDomains =
        initialState.selectedDNCListEmailsAndDomains;
    },
  },
  extraReducers: (builder) => {
    // Get Do Not Contact Lists Request
    builder.addCase(getDoNotContactListsRequest.pending, (state) => {
      state.getDoNotContactListsRequest.status = RequestStatus.Pending;
      state.getDoNotContactListsRequest.error = null;
    });
    builder.addCase(getDoNotContactListsRequest.fulfilled, (state, action) => {
      state.getDoNotContactListsRequest.status = RequestStatus.Succeeded;
      state.getDoNotContactListsRequest.message = action.payload.message;
      state.doNotContactLists = action.payload.payload.dncList;
      state.doNotContactListsPaginationOptions = action.payload.payload.meta;
    });
    builder.addCase(getDoNotContactListsRequest.rejected, (state, action) => {
      state.getDoNotContactListsRequest.status = RequestStatus.Failed;
      state.getDoNotContactListsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Create Do Not Contact List Request
    builder.addCase(createDoNotContactListRequest.pending, (state) => {
      state.createDoNotContactListRequest.status = RequestStatus.Pending;
      state.createDoNotContactListRequest.error = null;
    });
    builder.addCase(
      createDoNotContactListRequest.fulfilled,
      (state, action) => {
        state.createDoNotContactListRequest.status = RequestStatus.Succeeded;
        state.createDoNotContactListRequest.message = action.payload.message;
        state.createdDoNotContactListId = action.payload.payload.dncListData.id;
      },
    );
    builder.addCase(createDoNotContactListRequest.rejected, (state, action) => {
      state.createDoNotContactListRequest.status = RequestStatus.Failed;
      state.createDoNotContactListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Do Not Contact List Request
    builder.addCase(updateDoNotContactListRequest.pending, (state) => {
      state.updateDoNotContactListRequest.status = RequestStatus.Pending;
      state.updateDoNotContactListRequest.error = null;
    });
    builder.addCase(
      updateDoNotContactListRequest.fulfilled,
      (state, action) => {
        state.updateDoNotContactListRequest.status = RequestStatus.Succeeded;
        state.updateDoNotContactListRequest.message = action.payload.message;
      },
    );
    builder.addCase(updateDoNotContactListRequest.rejected, (state, action) => {
      state.updateDoNotContactListRequest.status = RequestStatus.Failed;
      state.updateDoNotContactListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Do Not Contact List Request
    builder.addCase(deleteDoNotContactListRequest.pending, (state) => {
      state.deleteDoNotContactListRequest.status = RequestStatus.Pending;
      state.deleteDoNotContactListRequest.error = null;
    });
    builder.addCase(
      deleteDoNotContactListRequest.fulfilled,
      (state, action) => {
        state.deleteDoNotContactListRequest.status = RequestStatus.Succeeded;
        state.deleteDoNotContactListRequest.message = action.payload.message;
      },
    );
    builder.addCase(deleteDoNotContactListRequest.rejected, (state, action) => {
      state.deleteDoNotContactListRequest.status = RequestStatus.Failed;
      state.deleteDoNotContactListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Do Not Contact List Details Meta Request
    builder.addCase(getDoNotContactListDetailsMetaRequest.pending, (state) => {
      state.getDoNotContactListDetailsMetaRequest.status =
        RequestStatus.Pending;
      state.getDoNotContactListDetailsMetaRequest.error = null;
    });
    builder.addCase(
      getDoNotContactListDetailsMetaRequest.fulfilled,
      (state, action) => {
        state.getDoNotContactListDetailsMetaRequest.status =
          RequestStatus.Succeeded;
        state.getDoNotContactListDetailsMetaRequest.message =
          action.payload.message;
        state.doNotContactListDetailsMeta = action.payload.payload;
      },
    );
    builder.addCase(
      getDoNotContactListDetailsMetaRequest.rejected,
      (state, action) => {
        state.getDoNotContactListDetailsMetaRequest.status =
          RequestStatus.Failed;
        state.getDoNotContactListDetailsMetaRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Do Not Contact List Details Request
    builder.addCase(getDoNotContactListDetailsRequest.pending, (state) => {
      state.getDoNotContactListDetailsRequest.status = RequestStatus.Pending;
      state.getDoNotContactListDetailsRequest.error = null;
    });
    builder.addCase(
      getDoNotContactListDetailsRequest.fulfilled,
      (state, action) => {
        state.getDoNotContactListDetailsRequest.status =
          RequestStatus.Succeeded;
        state.getDoNotContactListDetailsRequest.message =
          action.payload.message;
        state.doNotContactListDetails = action.payload.payload.dncListDetails;
        state.doNotContactListDetailsPaginationOptions =
          action.payload.payload.meta;
      },
    );
    builder.addCase(
      getDoNotContactListDetailsRequest.rejected,
      (state, action) => {
        state.getDoNotContactListDetailsRequest.status = RequestStatus.Failed;
        state.getDoNotContactListDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Do Not Contact List Details Request
    builder.addCase(updateDoNotContactListDetailsRequest.pending, (state) => {
      state.updateDoNotContactListDetailsRequest.status = RequestStatus.Pending;
      state.updateDoNotContactListDetailsRequest.error = null;
    });
    builder.addCase(
      updateDoNotContactListDetailsRequest.fulfilled,
      (state, action) => {
        state.updateDoNotContactListDetailsRequest.status =
          RequestStatus.Succeeded;
        state.updateDoNotContactListDetailsRequest.message =
          action.payload.message;
        state.updateDoNotContactListDetailsResponse = action.payload.payload;
      },
    );
    builder.addCase(
      updateDoNotContactListDetailsRequest.rejected,
      (state, action) => {
        state.updateDoNotContactListDetailsRequest.status =
          RequestStatus.Failed;
        state.updateDoNotContactListDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Delete Do Not Contact List Details
    builder.addCase(
      deleteDoNotContactListEmailsAndDomainsRequest.pending,
      (state) => {
        state.deleteDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Pending;
        state.deleteDoNotContactListEmailsAndDomainsRequest.error = null;
      },
    );
    builder.addCase(
      deleteDoNotContactListEmailsAndDomainsRequest.fulfilled,
      (state, action) => {
        state.deleteDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Succeeded;
        state.deleteDoNotContactListEmailsAndDomainsRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      deleteDoNotContactListEmailsAndDomainsRequest.rejected,
      (state, action) => {
        state.deleteDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Failed;
        state.deleteDoNotContactListEmailsAndDomainsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Bulk Delete Do Not Contact List Details
    builder.addCase(
      bulkDeleteDoNotContactListEmailsAndDomainsRequest.pending,
      (state) => {
        state.bulkDeleteDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Pending;
        state.bulkDeleteDoNotContactListEmailsAndDomainsRequest.error = null;
      },
    );
    builder.addCase(
      bulkDeleteDoNotContactListEmailsAndDomainsRequest.fulfilled,
      (state, action) => {
        state.bulkDeleteDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Succeeded;
        state.bulkDeleteDoNotContactListEmailsAndDomainsRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      bulkDeleteDoNotContactListEmailsAndDomainsRequest.rejected,
      (state, action) => {
        state.bulkDeleteDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Failed;
        state.bulkDeleteDoNotContactListEmailsAndDomainsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Import DNC List Emails And Domains via CSV
    builder.addCase(
      importDoNotContactListEmailsAndDomainsRequest.pending,
      (state) => {
        state.importDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Pending;
        state.importDoNotContactListEmailsAndDomainsRequest.error = null;
      },
    );
    builder.addCase(
      importDoNotContactListEmailsAndDomainsRequest.fulfilled,
      (state, action) => {
        state.importDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Succeeded;
        state.importDoNotContactListEmailsAndDomainsRequest.message =
          action.payload.message;
        state.importDoNotContactListEmailsAndDomainsResponse =
          action.payload.payload;
      },
    );
    builder.addCase(
      importDoNotContactListEmailsAndDomainsRequest.rejected,
      (state, action) => {
        state.importDoNotContactListEmailsAndDomainsRequest.status =
          RequestStatus.Failed;
        state.importDoNotContactListEmailsAndDomainsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const {
  resetCreateDoNotContactListRequest,
  resetCreatedDoNotContactListId,
  resetDeleteDoNotContactListRequest,
  resetGetDoNotContactListsRequest,
  resetGetDoNotContactListDetailsRequest,
  resetDoNotContactListDetailsMeta,
  resetUpdateDoNotContactListRequest,
  resetUpdateDoNotContactListDetailsRequest,
  resetDeleteDoNotContactListEmailsAndDomainsRequest,
  resetBulkDeleteDoNotContactListEmailsAndDomainsRequest,
  resetImportDoNotContactListEmailsAndDomainsRequest,

  clearDNCListEmailsAndDomainsSelection,
  onBulkSelectDNCListEmailsAndDomains,
  resetGetDoNotContactListDetailsMetaRequest,
  resetSelectedDNCListEmailsAndDomains,
  selectAllDNCListEmailsAndDomains,
  selectSingleDNCListEmailsAndDomains,
} = doNotContactSlice.actions;

export default doNotContactSlice.reducer;
