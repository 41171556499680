import React from 'react';

export const Github = ({ width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.00016 1.4668C4.31905 1.4668 1.3335 4.4518 1.3335 8.13346C1.3335 11.079 3.2435 13.5779 5.89294 14.4596C6.22572 14.5212 6.3335 14.3146 6.3335 14.139V12.8979C4.47905 13.3012 4.09294 12.1112 4.09294 12.1112C3.78961 11.3407 3.35239 11.1357 3.35239 11.1357C2.74739 10.7218 3.3985 10.7307 3.3985 10.7307C4.06794 10.7774 4.42016 11.4179 4.42016 11.4179C5.01461 12.4368 5.97961 12.1424 6.36016 11.9718C6.41961 11.5412 6.59239 11.2468 6.7835 11.0807C5.30294 10.9112 3.74627 10.3396 3.74627 7.78569C3.74627 7.05735 4.00683 6.46291 4.43294 5.99624C4.36405 5.82791 4.13572 5.14957 4.49794 4.2318C4.49794 4.2318 5.05794 4.05291 6.33183 4.91513C6.8635 4.76735 7.4335 4.69346 8.00016 4.69069C8.56683 4.69346 9.13738 4.76735 9.67016 4.91513C10.9429 4.05291 11.5018 4.2318 11.5018 4.2318C11.8646 5.15013 11.6363 5.82846 11.5674 5.99624C11.9952 6.46291 12.2535 7.05791 12.2535 7.78569C12.2535 10.3462 10.6941 10.9101 9.20961 11.0751C9.4485 11.2818 9.66683 11.6874 9.66683 12.3096V14.139C9.66683 14.3162 9.7735 14.5246 10.1118 14.459C12.7591 13.5762 14.6668 11.0779 14.6668 8.13346C14.6668 4.4518 11.6818 1.4668 8.00016 1.4668Z"
      fill="currentcolor"
    />
  </svg>
);
