import React from 'react';

export const SparklingMail = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_24220_27703)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.26673 7.73334C1.97218 7.73334 1.7334 7.97212 1.7334 8.26667V13.5999C1.7334 13.8945 1.97218 14.1333 2.26673 14.1333H9.73332C10.0279 14.1333 10.2666 13.8945 10.2666 13.5999V8.26667C10.2666 7.97212 10.0279 7.73334 9.73332 7.73334H2.26673ZM0.666748 8.26667C0.666748 7.38302 1.38308 6.66669 2.26673 6.66669H9.73332C10.617 6.66669 11.3333 7.38302 11.3333 8.26667V13.5999C11.3333 14.4836 10.617 15.1999 9.73332 15.1999H2.26673C1.38308 15.1999 0.666748 14.4836 0.666748 13.5999V8.26667Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.133333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.756255 7.97882C0.919641 7.73374 1.25077 7.66751 1.49585 7.8309L5.99996 10.8336L10.5041 7.8309C10.7492 7.66751 11.0803 7.73374 11.2437 7.97882C11.4071 8.2239 11.3408 8.55503 11.0957 8.71841L6.2958 11.9184C6.11665 12.0378 5.88327 12.0378 5.70412 11.9184L0.904173 8.71841C0.659094 8.55503 0.592869 8.2239 0.756255 7.97882Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.133333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5.33331C14.3682 5.33331 14.6667 5.63179 14.6667 5.99998C14.6667 6.17679 14.7369 6.34636 14.8619 6.47138C14.987 6.59641 15.1565 6.66665 15.3333 6.66665C15.7015 6.66665 16 6.96512 16 7.33331C16 7.7015 15.7015 7.99998 15.3333 7.99998C15.1565 7.99998 14.987 8.07022 14.8619 8.19524C14.7369 8.32027 14.6667 8.48984 14.6667 8.66665C14.6667 9.03484 14.3682 9.33331 14 9.33331C13.6318 9.33331 13.3333 9.03484 13.3333 8.66665C13.3333 8.48984 13.2631 8.32027 13.1381 8.19524C13.013 8.07022 12.8435 7.99998 12.6667 7.99998C12.2985 7.99998 12 7.7015 12 7.33331C12 6.96512 12.2985 6.66665 12.6667 6.66665C12.8435 6.66665 13.013 6.59641 13.1381 6.47138C13.2631 6.34636 13.3333 6.17679 13.3333 5.99998C13.3333 5.63179 13.6318 5.33331 14 5.33331Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9781 0C11.469 0 11.867 0.397969 11.867 0.888889C11.867 1.12464 11.9606 1.35073 12.1273 1.51743C12.294 1.68413 12.5201 1.77778 12.7558 1.77778C13.2468 1.77778 13.6447 2.17575 13.6447 2.66667C13.6447 3.15759 13.2468 3.55556 12.7558 3.55556C12.5201 3.55556 12.294 3.64921 12.1273 3.81591C11.9606 3.9826 11.867 4.2087 11.867 4.44444C11.867 4.93536 11.469 5.33333 10.9781 5.33333C10.4871 5.33333 10.0892 4.93536 10.0892 4.44444C10.0892 4.2087 9.99553 3.9826 9.82883 3.81591C9.66213 3.64921 9.43604 3.55556 9.20029 3.55556C8.70937 3.55556 8.3114 3.15759 8.3114 2.66667C8.3114 2.17575 8.70937 1.77778 9.20029 1.77778C9.43604 1.77778 9.66213 1.68413 9.82883 1.51743C9.99553 1.35073 10.0892 1.12464 10.0892 0.888889C10.0892 0.397969 10.4871 0 10.9781 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_24220_27703">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
