import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import { resetPasswordRequest, hideError } from '../../reset-password-slice';
import ResetPasswordForm from './reset-password-form';

const mapStateToProps = (state: RootState) => ({
  resetPasswordStatus: state.resetPassword.status,
  resetPasswordError: state.resetPassword.error,
  resetPasswordShowError: state.resetPassword.showError,
});

const mapDispatchToProps = {
  hideError: () => hideError(),
  sendResetPasswordRequest: (email: string) => resetPasswordRequest({ email }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    showSuccessComponent: () => void;
    setEmail: (email: string) => void;
  };

export default withRouter(connector(ResetPasswordForm));
