import React from 'react';
import ReactReduxLoadingBar, {
  LoadingBarContainerProps as ReactReduxLoadingBarContainerProps,
} from 'react-redux-loading-bar';
import classNames from 'classnames';

type LoadingBarProps = ReactReduxLoadingBarContainerProps;

const LoadingBar: React.FC<LoadingBarProps> = ({ className, ...rest }) => {
  const loadingBarClass = classNames(['loading-bar', className]);
  return <ReactReduxLoadingBar {...rest} className={loadingBarClass} />;
};

export default LoadingBar;
