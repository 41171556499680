import React, { useEffect, useMemo, useState } from 'react';
import { UserList } from '@saleshandy/icons';
import { UserRole } from '../../../../../enums/users-and-teams';
import Select from '../../../../../../../shared/design-system/components/select';
import ConfirmationModalV3 from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { capitalize } from '../../../../../../../shared/utils';

type IProps = {
  show: boolean;
  defaultRole: UserRole;
  onSubmit: (userRole: UserRole) => void;
  onClose: () => void;
  isLoading: boolean;
  atmRole: string;
};

type ChangeRoleType = {
  key: UserRole;
};

const ChangeRoleModal: React.FC<IProps> = ({
  show,
  defaultRole,
  onSubmit,
  onClose,
  isLoading,
  atmRole,
}) => {
  const [selectedRole, setSelectedRole] = useState(defaultRole);

  const roles = useMemo(
    () =>
      [
        {
          key: UserRole.Member,
        },
        {
          key: UserRole.Admin,
        },
        atmRole === UserRole.Owner ? { key: UserRole.Owner } : null,
      ].filter(Boolean),
    [atmRole],
  );

  useEffect(() => {
    if (show) {
      setSelectedRole(defaultRole || UserRole.Member);
    }
  }, [show]);

  return (
    <ConfirmationModalV3
      show={show}
      hideTitleIcon
      icon={<UserList className="blue-txt-11" />}
      title="Change Role"
      showCloseIcon={false}
      className="action-modal"
      customJSX={
        <div className="user-action change-role-action">
          <p className="user-action-label">User Role</p>
          <Select<ChangeRoleType>
            className="users-change-role-select"
            options={roles}
            selectedOptionKey={selectedRole}
            selectedOptionRenderer={([option]) => (
              <span className="blue-txt-15">{capitalize(option?.key)}</span>
            )}
            onChange={([option]) => setSelectedRole(option.key)}
            optionRenderer={(option) => (
              <span className="blue-txt-15">{capitalize(option?.key)}</span>
            )}
          />
        </div>
      }
      cancelButtonText="Cancel"
      submitButtonText="Change"
      onSubmit={() => onSubmit(selectedRole)}
      onClose={onClose}
      isSubmitDisabled={isLoading}
      isSubmitLoading={isLoading}
      cancelButtonClassName="cancel-btn"
    />
  );
};

export default ChangeRoleModal;
