import React, { useState } from 'react';
import { Filter, Refresh } from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';
import { useTranslation } from 'react-i18next';
import type { IProps } from './tasks-filter-bar-container';
import SearchInput from '../../../../../../shared/design-system/components/atoms/search-input';
import { IconPlace } from '../../../../../../shared/design-system/components/dropdown';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import TasksBulkActions from '../tasks-bulk-actions';
import TaskTypeSelect from './components/task-type-select';
import { TaskAction, TaskStatusKey } from '../../../../utils/enums';
import Vector from '../../../../../../shared/svg/vector';
import RefreshButton from '../../../../../../shared/design-system/components/atoms/refresh-button/refresh-button';

const TasksFilterBar: React.FC<IProps> = ({
  status,
  onFilterToggle,
  onSearch,
  onTaskTypeFilterApply,
  onAction,
  onRefresh,
  onStartAllTasks,
  tasksCount,
}) => {
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState<string>('');

  //* Search
  const handleClearSearch = () => {
    setSearchValue('');
    onSearch('');
  };
  const onSearchChangeHandler = (e: string) => {
    setSearchValue(e);
  };

  return (
    <div className="filter-container d-flex justify-content-between">
      <div className="d-flex align-items-center">
        <SearchInput
          onSearch={() => onSearch(searchValue)}
          searchValue={searchValue}
          setSearchValue={onSearchChangeHandler}
          onClearSearch={handleClearSearch}
          placeholder="Search here..."
          searchIconPlace={IconPlace?.Left}
        />

        <OverlayTooltip
          text={t('labels.apply_filters')}
          placement={Placement.Bottom}
          className="cell-card__tooltip"
        >
          <div
            className="task-filter-icon-container"
            {...accessibleOnClick(onFilterToggle)}
          >
            <Filter width={20} height={20} className="gray-txt-15" />
          </div>
        </OverlayTooltip>
      </div>

      <div className="d-flex align-items-center">
        <OverlayTooltip
          text={t('labels.refresh')}
          placement={Placement.Bottom}
          className="cell-card__tooltip"
        >
          <div
            className="task-filter-icon-container"
            {...accessibleOnClick(onRefresh)}
          >
            <Refresh width={20} height={20} className="gray-txt-15" />
          </div>
        </OverlayTooltip>

        <div className="bs-ml-20">
          <TaskTypeSelect onApply={onTaskTypeFilterApply} />
        </div>
        {tasksCount !== 0 &&
          !(
            status === TaskStatusKey.Completed ||
            status === TaskStatusKey.Skipped
          ) && (
            <>
              <div className="filter-border" />
              <Button onClick={onStartAllTasks}>
                <Vector />
                <span className="ml-1">Start {tasksCount} Tasks</span>
              </Button>
            </>
          )}
      </div>
    </div>
  );
};

export default TasksFilterBar;
