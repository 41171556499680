import { ReactNode } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteProps, withRouter, RouteComponentProps } from 'react-router';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { RootState } from '../../../store/root-reducer';
import connectEmailAccountOptions from './connect-email-account-options';
import { sendUpdatePreferredAppRequest } from './extra-actions';
import { UpdatePreferredAppRequest } from './types';
import { EmailAccountMethod } from '../../../components/email-accounts/enums/email-account';

const mapStateToProps = (state: RootState) => ({
  sendUpdatePreferredAppRequestStatus:
    state.updatePreferredApp.sendUpdatePreferredAppRequest.status,
  sendUpdatePreferredAppRequestError:
    state.updatePreferredApp.sendUpdatePreferredAppRequest.error,
});

const mapDispatchToProps = {
  sendUpdatePreferredApp: (payload: UpdatePreferredAppRequest) =>
    sendUpdatePreferredAppRequest(payload),
  hideLoading: () => hideLoading(),
  showLoading: () => showLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps &
  Omit<RouteProps, 'render'> & {
    sendConnectEmailAccountRequest: (method: EmailAccountMethod) => void;
    skipConnectEmailAccount: () => void;
    title?: string;
    body?: string;
    t?: (x: string) => ReactNode;
  };

export default withRouter(connector(connectEmailAccountOptions));
