import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { getUserSettingsRequest } from '../../../../../components/home/extra-actions';
import { resetResumeLeadFinderSubscriptionResponse } from '../../../../../components/settings/components/billing-subscription/billing-subscription-slice';
import { resumeLeadFinderSubscriptionRequest } from '../../../../../components/settings/components/billing-subscription/extra-actions';
import { ReactivateResumeSubscriptionRequest } from '../../../../../components/settings/components/billing-subscription/types';
import { RootState } from '../../../../../store/root-reducer';
import { PauseLeadFinderManager } from '..';

const mapStateToProps = (state: RootState) => ({
  sendGetResumeLeadFinderSubscriptionStatus:
    state.billingAndSubscription.resumeLeadFinderSubscriptionRequest.status,
  sendGetResumeLeadFinderSubscriptionError:
    state.billingAndSubscription.resumeLeadFinderSubscriptionRequest.error,
  sendGetResumeLeadFinderSubscriptionResponse:
    state.billingAndSubscription.resumeLeadFinderSubscriptionResponse,
  leadFinderSubscriptionPlanStatus: state.home.leadFinderSubscription?.status,
  leadFinderPauseEndsAt: state.home.leadFinderSubscription?.pauseEndsAt,
  leadFinderCustomerId: state.home.leadFinderSubscription?.customerId,
  firstName: state.home.adminDetails?.firstName,
});

const mapDispatchToProps = {
  resetResumeLeadFinderSubscriptionResponse: () =>
    resetResumeLeadFinderSubscriptionResponse(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  sendResumeLeadFinderSubscriptionRequest: (
    payload: ReactivateResumeSubscriptionRequest,
  ) => resumeLeadFinderSubscriptionRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    bindActions: (...args: any[]) => any;
    t: (x: string) => string;
  };

export type LocationStateType = {
  isModifySubscription: boolean;
};

export default connector(PauseLeadFinderManager);
