import React from 'react';

export const SHLogoDarkSM = () => (
  <svg
    width="121"
    height="22"
    viewBox="0 0 121 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7716_54172)">
      <g clipPath="url(#clip1_7716_54172)">
        <path
          d="M5.87989 0C5.06323 0 4.65425 0.98738 5.23171 1.56485L20.4354 16.7685C21.0128 17.346 22.0002 16.937 22.0002 16.1203V0.916667C22.0002 0.410406 21.5898 0 21.0835 0H5.87989Z"
          fill="#0137FC"
        />
        <path
          d="M10.7432 19.9097C11.1763 20.3428 11.9169 20.0361 11.9169 19.4236V10.7708C11.9169 10.3911 11.6091 10.0833 11.2294 10.0833H2.57664C1.96414 10.0833 1.6574 10.8239 2.0905 11.257L10.7432 19.9097Z"
          fill="#0137FC"
        />
        <path
          d="M4.71777 21.2176C5.00651 21.5063 5.5002 21.3018 5.5002 20.8935V16.9583C5.5002 16.7052 5.295 16.5 5.04187 16.5H1.10671C0.698382 16.5 0.493889 16.9937 0.782623 17.2824L4.71777 21.2176Z"
          fill="#0137FC"
        />
      </g>
      <path
        d="M34.6036 10.3972C33.2786 10.2088 32.8243 9.98286 32.8243 9.38008C32.8243 8.81501 33.3164 8.43833 34.1493 8.43833C35.02 8.43833 35.4743 8.77736 35.5879 9.53082H37.9351C37.7079 7.42121 36.1557 6.66779 34.1114 6.66779C32.2186 6.66779 30.4393 7.60956 30.4393 9.53082C30.4393 11.339 31.3857 12.0171 33.8465 12.3938C35.1714 12.5821 35.7015 12.8458 35.7015 13.4863C35.7015 14.1266 35.2472 14.5034 34.2629 14.5034C33.165 14.5034 32.7486 14.0137 32.635 13.1849H30.25C30.3257 15.1815 31.8021 16.2739 34.2629 16.2739C36.6858 16.2739 38.1622 15.2191 38.1622 13.2602C38.2 11.3013 36.9129 10.7363 34.6036 10.3972Z"
        fill="#111827"
      />
      <path
        d="M43.7277 6.70546C41.4563 6.70546 39.7148 7.38354 39.5634 9.56849H41.9863C42.0998 8.77738 42.5541 8.55135 43.5763 8.55135C44.7499 8.55135 45.0906 9.15411 45.0906 10.2465V10.6233H44.0306C41.1913 10.6233 39.2227 11.4144 39.2227 13.5616C39.2227 15.4828 40.6234 16.3116 42.327 16.3116C43.7656 16.3116 44.5984 15.7466 45.1285 14.9931V16.0856H47.627V10.1336C47.5892 7.60957 45.9992 6.70546 43.7277 6.70546ZM45.0527 12.8835C45.0527 13.9007 44.182 14.5034 43.0463 14.5034C42.0998 14.5034 41.7213 14.0514 41.7213 13.4109C41.7213 12.4315 42.592 12.0925 44.0685 12.0925H45.0527V12.8835Z"
        fill="#111827"
      />
      <path
        d="M51.1478 2.74998H48.6113V16.1233H51.1478V2.74998Z"
        fill="#111827"
      />
      <path
        d="M56.94 6.70546C54.2521 6.70546 52.1699 8.58902 52.1699 11.4897V11.6404C52.1699 14.5787 54.2142 16.3116 56.9778 16.3116C59.5143 16.3116 61.1043 15.2191 61.4071 13.2226H58.9843C58.8329 14.0136 58.2271 14.5034 57.0535 14.5034C55.6528 14.5034 54.8199 13.637 54.7443 12.0925H61.4071V11.3767C61.445 8.09929 59.325 6.70546 56.94 6.70546ZM54.8199 10.5102C55.0092 9.19176 55.8042 8.43833 56.94 8.43833C58.1514 8.43833 58.8707 9.11639 58.9464 10.5102H54.8199Z"
        fill="#111827"
      />
      <path
        d="M66.7833 10.3972C65.4583 10.2088 65.004 9.98284 65.004 9.38006C65.004 8.815 65.4961 8.43831 66.329 8.43831C67.1997 8.43831 67.654 8.77734 67.7676 9.5308H70.1147C69.8876 7.42119 68.3354 6.66777 66.2911 6.66777C64.3982 6.66777 62.619 7.60955 62.619 9.5308C62.619 11.339 63.5654 12.0171 66.0261 12.3938C67.3511 12.5821 67.8811 12.8458 67.8811 13.4863C67.8811 14.1266 67.4269 14.5034 66.4425 14.5034C65.3447 14.5034 64.9283 14.0137 64.8147 13.1849H62.4297C62.5054 15.1815 63.9818 16.2739 66.4425 16.2739C68.8654 16.2739 70.3419 15.2191 70.3419 13.2602C70.3797 11.3013 69.1304 10.7362 66.7833 10.3972Z"
        fill="#111827"
      />
      <path
        d="M76.9654 6.70546C75.4131 6.70546 74.4289 7.45888 73.9742 8.36298V2.74998H71.4004V16.1233H73.9742V10.8116C73.9742 9.45545 74.8071 8.77736 75.9052 8.77736C77.0408 8.77736 77.5329 9.38008 77.5329 10.6232V16.1233H80.1073V10.2466C80.0694 7.79792 78.7824 6.70546 76.9654 6.70546Z"
        fill="#111827"
      />
      <path
        d="M85.6357 6.70546C83.3646 6.70546 81.623 7.38354 81.4716 9.56849H83.8947C84.008 8.77738 84.4621 8.55135 85.4843 8.55135C86.6579 8.55135 86.9986 9.15411 86.9986 10.2465V10.6233H85.939C83.0992 10.6233 81.1309 11.4144 81.1309 13.5616C81.1309 15.4828 82.5318 16.3116 84.2354 16.3116C85.6737 16.3116 86.5065 15.7466 87.0366 14.9931V16.0856H89.5351V10.1336C89.4977 7.60957 87.8694 6.70546 85.6357 6.70546ZM86.9612 12.8835C86.9612 13.9007 86.0904 14.5034 84.9542 14.5034C84.008 14.5034 83.6293 14.0514 83.6293 13.4109C83.6293 12.4315 84.5001 12.0925 85.9764 12.0925H86.9612V12.8835Z"
        fill="#111827"
      />
      <path
        d="M96.0851 6.70546C94.5329 6.70546 93.5487 7.45888 93.094 8.36299V6.89382H90.5195V16.0856H93.094V10.8116C93.094 9.45547 93.9268 8.77738 95.025 8.77738C96.1605 8.77738 96.6526 9.3801 96.6526 10.6233V16.1232H99.2271V10.2465C99.1891 7.79792 97.9021 6.70546 96.0851 6.70546Z"
        fill="#111827"
      />
      <path
        d="M107.215 8.28765C106.685 7.34587 105.776 6.70546 104.3 6.70546C101.991 6.70546 100.249 8.476 100.249 11.4897V11.6404C100.249 14.6917 102.028 16.3116 104.262 16.3116C105.625 16.3116 106.76 15.5205 107.215 14.5788V16.1233H109.751V2.74998H107.215V8.28765ZM107.29 11.565C107.29 13.4109 106.344 14.315 105.019 14.315C103.732 14.315 102.861 13.4109 102.861 11.6027V11.452C102.861 9.60613 103.656 8.66434 105.095 8.66434C106.495 8.66434 107.29 9.60613 107.29 11.4144V11.565Z"
        fill="#111827"
      />
      <path
        d="M117.852 6.93155L115.769 12.4315L113.536 6.93155H110.772L114.444 15.1815L112.816 19.25H115.315L120.312 6.93155H117.852Z"
        fill="#111827"
      />
    </g>
    <defs>
      <clipPath id="clip0_7716_54172">
        <rect width="120.214" height="22" fill="white" />
      </clipPath>
      <clipPath id="clip1_7716_54172">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
