import React from 'react';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../../../shared/design-system/components/overlay';

import { Webhook } from '../../../../../../../types/webhook';

import { getFormattedTime } from '../../../../../../../../../shared/utils';
import { getUserTimeZone } from '../../../../../../../../../shared/utils/user-details';
import { getDateTimeTooltipText } from '../../../../../../../../mailbox-emails/helpers/helper';

type IProps = {
  webhook: Webhook;
};

const WebhookLastSentField: React.FC<IProps> = ({
  webhook: { lastActivityAt },
}) => {
  const latestActivity = getFormattedTime(lastActivityAt?.toString() || '');
  const userTimezone = getUserTimeZone();

  return (
    <p>
      {lastActivityAt && userTimezone ? (
        <OverlayTooltip
          className="webhook-lg-tooltip"
          text={getDateTimeTooltipText(lastActivityAt, userTimezone, 'sent')}
          placement={Placement.Bottom}
        >
          <span>{latestActivity || '-'}</span>
        </OverlayTooltip>
      ) : (
        '-'
      )}
    </p>
  );
};

export default WebhookLastSentField;
