import React from 'react';

export const NoMessages = ({ size = 'sm' }) =>
  size === 'lg' ? (
    <svg
      width="106"
      height="100"
      viewBox="0 0 106 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 68.1485L18.1909 64.373L33.2929 70.2079L19.5638 77.7588L0 68.1485Z"
        fill="url(#paint0_linear_8739_108725)"
      />
      <path
        d="M105.027 68.1485L86.8359 64.373L71.734 70.2079L85.463 77.7588L105.027 68.1485Z"
        fill="url(#paint1_linear_8739_108725)"
      />
      <path
        d="M33.293 70.208H71.7342L85.4632 77.759H19.564L33.293 70.208Z"
        fill="url(#paint2_linear_8739_108725)"
      />
      <path
        d="M38.8571 37.5593C45.0436 33.9875 52.9542 36.1072 56.5259 42.2936C60.0976 48.48 57.978 56.3906 51.7916 59.9624L44.5164 64.1627L42.5637 72.9126C42.323 73.9912 40.8827 74.2119 40.3301 73.2547L36.9696 67.4342C31.9051 68.1427 26.6855 65.79 23.9714 61.089C20.3996 54.9025 22.5193 46.9919 28.7057 43.4202L38.8571 37.5593Z"
        fill="url(#paint3_linear_8739_108725)"
      />
      <circle
        cx="2.02101"
        cy="2.02101"
        r="2.02101"
        transform="matrix(-0.866025 0.5 0.5 0.866025 47.8149 44.9897)"
        fill="#F4F7FC"
      />
      <circle
        cx="2.02101"
        cy="2.02101"
        r="2.02101"
        transform="matrix(-0.866025 0.5 0.5 0.866025 40.814 49.0317)"
        fill="#F4F7FC"
      />
      <circle
        cx="2.02101"
        cy="2.02101"
        r="2.02101"
        transform="matrix(-0.866025 0.5 0.5 0.866025 33.813 53.0737)"
        fill="#F4F7FC"
      />
      <path
        d="M60.5059 12.9448C51.3386 10.4884 41.9158 15.9287 39.4594 25.0959C37.0031 34.2632 42.4433 43.686 51.6106 46.1424L62.3925 49.0314L67.9069 59.5883C68.5868 60.8897 70.511 60.6776 70.891 59.2593L73.2021 50.6342C79.9446 49.8014 85.8332 44.9876 87.6997 38.0219C90.156 28.8546 84.7158 19.4318 75.5485 16.9755L60.5059 12.9448Z"
        fill="url(#paint4_linear_8739_108725)"
      />
      <circle
        cx="53.4645"
        cy="28.8486"
        r="2.68505"
        transform="rotate(15 53.4645 28.8486)"
        fill="#F4F7FC"
      />
      <circle
        cx="63.839"
        cy="31.6283"
        r="2.68505"
        transform="rotate(15 63.839 31.6283)"
        fill="#F4F7FC"
      />
      <circle
        cx="74.2131"
        cy="34.4081"
        r="2.68505"
        transform="rotate(15 74.2131 34.4081)"
        fill="#F4F7FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7598 21.2757C36.3129 21.1432 36.6537 20.5874 36.5212 20.0344C36.3887 19.4814 35.833 19.1405 35.2799 19.2731L32.6097 19.9129C32.0567 20.0455 31.7158 20.6012 31.8484 21.1542C31.9809 21.7072 32.5366 22.0481 33.0896 21.9156L35.7598 21.2757ZM45.6603 8.74752C45.6592 9.31619 45.1973 9.77629 44.6286 9.77519C44.0599 9.77408 43.5998 9.31218 43.6009 8.74351L43.6063 5.99771C43.6074 5.42903 44.0693 4.96893 44.638 4.97004C45.2066 4.97115 45.6667 5.43305 45.6656 6.00172L45.6603 8.74752ZM37.0611 15.1022C36.932 15.6561 36.3783 16.0003 35.8245 15.8712L33.1504 15.2476C32.5966 15.1184 32.2524 14.5648 32.3815 14.011C32.5107 13.4571 33.0643 13.1129 33.6181 13.242L36.2922 13.8656C36.846 13.9948 37.1903 14.5484 37.0611 15.1022ZM40.417 10.0596C40.6707 10.5686 40.4637 11.1868 39.9548 11.4405C39.4458 11.6942 38.8276 11.4872 38.5739 10.9782L37.3491 8.52073C37.0954 8.01177 37.3024 7.39353 37.8114 7.13987C38.3203 6.8862 38.9386 7.09317 39.1922 7.60213L40.417 10.0596Z"
        fill="#CCD0DB"
      />
      <path
        d="M19.564 77.7588H85.4632V90.1149L73.2833 95.5959C66.8324 98.4988 59.839 99.9998 52.7651 99.9998H52.5136H52.2621C45.1882 99.9998 38.1948 98.4988 31.7439 95.5959L19.564 90.1149V77.7588Z"
        fill="url(#paint5_linear_8739_108725)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8739_108725"
          x1="17.5177"
          y1="77.3528"
          x2="17.5079"
          y2="68.3453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0D3DB" />
          <stop offset="1" stopColor="#D6DBE7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8739_108725"
          x1="87.5092"
          y1="77.3528"
          x2="87.5189"
          y2="68.3453"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0D3DB" />
          <stop offset="1" stopColor="#D6DBE7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8739_108725"
          x1="-2.40246"
          y1="101.442"
          x2="-6.60345"
          y2="73.0123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA2AD" />
          <stop offset="1" stopColor="#CED3E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8739_108725"
          x1="45.9248"
          y1="31.6118"
          x2="39.2896"
          y2="83.151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E8F3" />
          <stop offset="1" stopColor="#CCD1DD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8739_108725"
          x1="39.6776"
          y1="66.1222"
          x2="88.7751"
          y2="11.6052"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7ABB6" />
          <stop offset="1" stopColor="#C7CCD7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8739_108725"
          x1="54.2381"
          y1="133.672"
          x2="54.1468"
          y2="94.87"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D9E3" />
          <stop offset="1" stopColor="#D2D5DC" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width="64"
      height="60"
      viewBox="0 0 64 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 40.8893L10.9146 38.624L19.9757 42.1249L11.7383 46.6555L0 40.8893Z"
        fill="url(#paint0_linear_8739_106693)"
      />
      <path
        d="M63.0164 40.8893L52.1018 38.624L43.0406 42.1249L51.278 46.6555L63.0164 40.8893Z"
        fill="url(#paint1_linear_8739_106693)"
      />
      <path
        d="M19.9754 42.125H43.0402L51.2776 46.6556H11.738L19.9754 42.125Z"
        fill="url(#paint2_linear_8739_106693)"
      />
      <path
        d="M23.3152 22.5352C27.0271 20.3921 31.7735 21.6639 33.9165 25.3758C36.0596 29.0876 34.7878 33.834 31.0759 35.977L26.7108 38.4972L25.5392 43.7471C25.3948 44.3943 24.5306 44.5267 24.199 43.9525L22.1828 40.4602C19.144 40.8852 16.0123 39.4736 14.3838 36.653C12.2408 32.9411 13.5125 28.1948 17.2244 26.0517L23.3152 22.5352Z"
        fill="url(#paint3_linear_8739_106693)"
      />
      <circle
        cx="1.2126"
        cy="1.2126"
        r="1.2126"
        transform="matrix(-0.866025 0.5 0.5 0.866025 28.6897 26.9937)"
        fill="#F4F7FC"
      />
      <circle
        cx="1.2126"
        cy="1.2126"
        r="1.2126"
        transform="matrix(-0.866025 0.5 0.5 0.866025 24.4893 29.4189)"
        fill="#F4F7FC"
      />
      <circle
        cx="1.2126"
        cy="1.2126"
        r="1.2126"
        transform="matrix(-0.866025 0.5 0.5 0.866025 20.2883 31.8438)"
        fill="#F4F7FC"
      />
      <path
        d="M36.3042 7.76697C30.8039 6.29315 25.1502 9.55731 23.6764 15.0577C22.2026 20.558 25.4667 26.2117 30.9671 27.6855L37.4362 29.4189L40.7449 35.7531C41.1528 36.5339 42.3073 36.4067 42.5354 35.5557L43.922 30.3806C47.9675 29.881 51.5007 26.9926 52.6205 22.8132C54.0944 17.3129 50.8302 11.6592 45.3298 10.1854L36.3042 7.76697Z"
        fill="url(#paint4_linear_8739_106693)"
      />
      <circle
        cx="32.0794"
        cy="17.309"
        r="1.61103"
        transform="rotate(15 32.0794 17.309)"
        fill="#F4F7FC"
      />
      <circle
        cx="38.304"
        cy="18.9775"
        r="1.61103"
        transform="rotate(15 38.304 18.9775)"
        fill="#F4F7FC"
      />
      <circle
        cx="44.5276"
        cy="20.645"
        r="1.61103"
        transform="rotate(15 44.5276 20.645)"
        fill="#F4F7FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4552 12.7655C21.787 12.686 21.9915 12.3526 21.912 12.0208C21.8325 11.6889 21.4991 11.4844 21.1673 11.5639L19.5651 11.9479C19.2333 12.0274 19.0288 12.3608 19.1083 12.6926C19.1878 13.0244 19.5213 13.229 19.8531 13.1495L21.4552 12.7655ZM27.3957 5.24862C27.395 5.58983 27.1179 5.86589 26.7767 5.86522C26.4355 5.86456 26.1594 5.58742 26.1601 5.24622L26.1633 3.59874C26.1639 3.25753 26.4411 2.98147 26.7823 2.98214C27.1235 2.9828 27.3996 3.25994 27.3989 3.60114L27.3957 5.24862ZM22.2361 9.06149C22.1587 9.39378 21.8265 9.60034 21.4942 9.52285L19.8897 9.14869C19.5575 9.0712 19.3509 8.73901 19.4284 8.40672C19.5059 8.07443 19.8381 7.86788 20.1704 7.94537L21.7748 8.31952C22.1071 8.39701 22.3136 8.72921 22.2361 9.06149ZM24.2496 6.03595C24.4018 6.34133 24.2776 6.71227 23.9722 6.86447C23.6668 7.01667 23.2959 6.89249 23.1437 6.58711L22.4088 5.11261C22.2566 4.80723 22.3808 4.43629 22.6862 4.28409C22.9915 4.13189 23.3625 4.25607 23.5147 4.56145L24.2496 6.03595Z"
        fill="#CCD0DB"
      />
      <path
        d="M11.738 46.6553H51.2776V54.0689L44.1072 57.2956C40.146 59.0782 35.8516 59.9999 31.5078 59.9999V59.9999V59.9999C27.164 59.9999 22.8696 59.0782 18.9084 57.2956L11.738 54.0689V46.6553Z"
        fill="url(#paint5_linear_8739_106693)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8739_106693"
          x1="10.5106"
          y1="46.4119"
          x2="10.5048"
          y2="41.0074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0D3DB" />
          <stop offset="1" stopColor="#D6DBE7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_8739_106693"
          x1="52.5058"
          y1="46.4119"
          x2="52.5116"
          y2="41.0074"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D0D3DB" />
          <stop offset="1" stopColor="#D6DBE7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_8739_106693"
          x1="-1.44182"
          y1="60.8651"
          x2="-3.96241"
          y2="43.8076"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9DA2AD" />
          <stop offset="1" stopColor="#CED3E0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_8739_106693"
          x1="27.5559"
          y1="18.9667"
          x2="23.5747"
          y2="49.8902"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E8F3" />
          <stop offset="1" stopColor="#CCD1DD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_8739_106693"
          x1="23.8073"
          y1="39.6734"
          x2="53.2658"
          y2="6.96319"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7ABB6" />
          <stop offset="1" stopColor="#C7CCD7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_8739_106693"
          x1="32.5425"
          y1="80.203"
          x2="32.4877"
          y2="56.922"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4D9E3" />
          <stop offset="1" stopColor="#D2D5DC" />
        </linearGradient>
      </defs>
    </svg>
  );
