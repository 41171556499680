import React from 'react';
import { DeleteCustomOutcomeDropdown, IProps } from './types';
import Select from '../../../../../../shared/design-system/components/select';
import {
  capitalize,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import { Placement } from '../../../../../../shared/design-system/components/overlay';
import { getOutcomeIcon } from '../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import { getDeleteOutcomeDropdownOptions } from '../custom-outcome-content/helpers/custom-outcome-column-data';
import DeleteAlertModal from '../../../../../../shared/components/delete-alert-modal';

const DeleteCustomOutcomeModal: React.FC<IProps> = ({
  show,
  onSubmit,
  onClose,
  title,
  content,
  isRequestPending,
  onOutcomeIdUpdate,
  getAllOutcomesRequestStatus,
  sendAllOutcomesRequest,
  options,
  actionCustomOutcome,
}) => {
  const [
    selectedCustomOutcomeUpdateId,
    setSelectedCustomOutcomeUpdateId,
  ] = React.useState(1);

  React.useEffect(() => {
    sendAllOutcomesRequest();
  }, []);

  React.useEffect(() => {
    executeOnRequestStatus({
      status: getAllOutcomesRequestStatus,
      onSuccess: () => {},
    });
  }, [getAllOutcomesRequestStatus]);

  const isFetching = getIsRequestPending(getAllOutcomesRequestStatus);

  return (
    <DeleteAlertModal
      show={show}
      title={title}
      onClose={onClose}
      isSubmitLoading={isRequestPending}
      onSubmit={onSubmit}
      contents={[content, 'Please select a new outcome for them.']}
    >
      <Select<DeleteCustomOutcomeDropdown>
        showSearch
        className="delete-outcome-modal-transfer"
        filterOption={(value, option) =>
          option.name.toLowerCase().includes(value.toLowerCase())
        }
        selectedOptionRenderer={([option]) => <span>{option?.name}</span>}
        optionRenderer={(option) => (
          <div className="sentiment-content">
            <div className="sentiment-icon">
              {getOutcomeIcon(option.name, false, {
                isDefault: option.isDefault,
                sentiment: option.sentiment,
                width: 16,
                height: 16,
              })}
            </div>
            <div>{capitalize(option.name)}</div>
          </div>
        )}
        options={getDeleteOutcomeDropdownOptions(
          options,
          actionCustomOutcome?.id,
        )}
        placeholder="Select Outcome"
        searchPlaceholder="Search"
        placement={Placement.BottomEnd}
        selectedOptionKey={selectedCustomOutcomeUpdateId}
        onChange={([selectedOption]) => {
          setSelectedCustomOutcomeUpdateId(selectedOption?.key);
          onOutcomeIdUpdate(selectedOption?.key);
        }}
        isLoading={isFetching}
      />
    </DeleteAlertModal>
  );
};

export default DeleteCustomOutcomeModal;
