import React from 'react';
import { DoNotContactListEmailsAndDomains } from '../../../../../../../types/do-not-contact-list';
import TruncatedTextWithTooltip from '../../../../../../../../../shared/components/truncated-text-with-tooltip';

type IProps = {
  doNotContactListDetail: DoNotContactListEmailsAndDomains;
};

const EmailDomainNameField: React.FC<IProps> = ({
  doNotContactListDetail: { value },
}) => (
  <TruncatedTextWithTooltip
    length={60}
    string={value}
    tooltipClassName="tooltip-xxl"
  />
);

export default EmailDomainNameField;
