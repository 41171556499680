import axios, { AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import { getToken } from '../utils';
import { showSystemErrorNotification } from '../utils/errors';

export function useAxios(
  axiosParams: Omit<AxiosRequestConfig, 'baseURL'>,
  initialResponse: any,
) {
  const [axiosConfig, setAxiosConfig] = useState<
    Omit<AxiosRequestConfig, 'baseURL'>
  >(axiosParams);
  const [response, setResponse] = useState(initialResponse);
  const [loading, setLoading] = useState(true);

  const axiosConfigSetter = (config: Omit<AxiosRequestConfig, 'baseURL'>) => {
    setAxiosConfig({ ...axiosConfig, ...config });
  };

  useEffect(() => {
    const token = getToken();
    axios({
      method: axiosConfig.method,
      url: axiosConfig.url,
      params: axiosConfig.params,
      baseURL: `${process.env.REACT_APP_API_URL}/`,
      headers: {
        authorization: `bearer ${token}`,
      },
      data: axiosConfig.data,
    })
      .then((res) => {
        setResponse(res.data.payload);
        setLoading(false);
      })
      .catch(() => {
        showSystemErrorNotification('Something Went Wrong');
        setLoading(false);
      });
  }, [axiosConfig]);

  return {
    axiosConfigSetter,
    response,
    loading,
  };
}
