import { createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseErrorWithHandled, ResponseSuccess } from '../../shared/types';
import {
  getTaskStatusCount,
  getTaskerData,
  getTasks,
  updateTasks,
  updateTaskNote,
} from './helper/tasks.api';
import {
  GetTaskerParams,
  GetTasksParams,
  UpdateTask,
  UpdateTaskNoteRequest,
} from './utils/types';

export const getTasksRequest = createAsyncThunk<
  ResponseSuccess,
  GetTasksParams,
  { rejectValue: ResponseErrorWithHandled }
>('contact/getTasksRequest', async (args, thunkAPI) => {
  try {
    return await getTasks(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTaskStatusCountRequest = createAsyncThunk<
  ResponseSuccess,
  GetTasksParams,
  { rejectValue: ResponseErrorWithHandled }
>('contact/getTaskStatusCountRequest', async (args, thunkAPI) => {
  try {
    return await getTaskStatusCount(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateTasksRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateTask,
  { rejectValue: ResponseErrorWithHandled }
>('contact/updateTaskRequest', async (args, thunkAPI) => {
  try {
    return await updateTasks(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getTaskerDataRequest = createAsyncThunk<
  ResponseSuccess,
  GetTaskerParams,
  { rejectValue: ResponseErrorWithHandled }
>('contact/getTaskerDataRequest', async (args, thunkAPI) => {
  try {
    return await getTaskerData(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateTaskNoteRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateTaskNoteRequest,
  { rejectValue: ResponseErrorWithHandled }
>('contact/updateTaskNoteRequest', async (args, thunkAPI) => {
  try {
    return await updateTaskNote(args);
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
