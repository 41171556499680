export const getEntriesMessage = (
  type: 'added' | 'deleted',
  entry: Record<string, any> = null,
  entries: any[] = [],
  isBulkAction = false,
): string => {
  if (entry !== null) {
    return `Entry ${type} successfully`;
  }

  if (isBulkAction) {
    return `Entries ${type} successfully`;
  }

  if (entries && entries.length > 0) {
    if (entries.length === 1) {
      return `Entry ${type} successfully`;
    }
    return `Entries ${type} successfully`;
  }

  return '';
};

export const getEntriesTitle = (
  entry: Record<string, any> = null,
  entries: any[] = [],
  isBulkAction = false,
): string => {
  if (entry !== null) {
    return `Entry`;
  }

  if (isBulkAction) {
    return `Entries`;
  }

  if (entries && entries.length > 0) {
    if (entries.length === 1) {
      return `Entry`;
    }
    return `Entries`;
  }

  return '';
};
