/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp } from '@saleshandy/icons';
import { Button } from 'react-bootstrap';
import IndividualEmailField from './individual-email-field';
import { ProspectContactInfoCategory } from './enum';
import { FormMode } from '../../organisms/form/types';
import { ContactType } from './types';
import IndividualPhoneNumberField from './individual-phone-field';

export default function ProspectContactFieldComponent({
  setFieldValue,
  values,
  handleBlur,
  errors,
  touched,
  setFieldTouched,
  formMode,
  fieldName,
  primaryEmailChangeInProspectRequest,
  deleteEmailFromProspectRequest,
  deletePhoneFromProspectRequest,
  selectedProspectId,
}) {
  const [contacts, setContacts] = useState(values);
  const [isExpandedContacts, setIsExpandedContacts] = useState(false);
  const [visibleContacts, setVisibleContacts] = useState(3);
  let sortedData = [];

  const addContactHandler = () => {
    if (fieldName === ContactType.Email) {
      const newContact = {
        email: '',
        emailType: ProspectContactInfoCategory.Work,
        isPrimary: false,
      };

      setContacts([...contacts, newContact]);
    } else {
      const newContact = {
        phonenumber: '',
        phoneType: ProspectContactInfoCategory.Work,
        isPrimary: contacts?.length === 0,
      };

      setContacts([...contacts, newContact]);
    }
    if (
      contacts.length >= 3 &&
      formMode === FormMode.Edit &&
      !isExpandedContacts
    ) {
      setVisibleContacts(contacts.length + 1);
      setIsExpandedContacts(true);
    }
    if (isExpandedContacts) {
      setVisibleContacts(visibleContacts + 1);
    }
  };

  const handleMoreVisibleContacts = () => {
    setVisibleContacts(contacts.length);
    setIsExpandedContacts(true);
  };

  const handleLessVisibleContacts = () => {
    setVisibleContacts(3);
    setIsExpandedContacts(false);
  };

  useEffect(() => {
    if (fieldName === 'email') {
      setFieldValue('email', contacts);
    } else {
      setFieldValue('phonenumber', contacts);
    }
    if (formMode === FormMode.Add) {
      setVisibleContacts(contacts.length);
    }
    if(contacts?.length <= 3){
      setIsExpandedContacts(false);
    }
  }, [contacts]);

  if(ContactType.Email === "email"){
    sortedData = contacts.filter((item) => item.isPrimary).concat(
      contacts.filter((item) => !item.isPrimary)
    );
  }

  useEffect(() => {
    setContacts(sortedData);
  }, [sortedData?.length])
  
  return (
    <div className="bs-input-container prospect-form-contact-field input-default-container col-12">
      <label className="bs-input-label-prefix prospect-label">
        {fieldName === ContactType.Email ? 'Email' : 'Phone Number'}
      </label>
      {contacts?.slice(0, visibleContacts).map((email, index) => (
        <div key={email}>
          {fieldName === ContactType.Email && (
            <IndividualEmailField
              email={email}
              emails={contacts}
              setEmails={setContacts}
              index={index}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldTouched={setFieldTouched}
              formMode={formMode}
              primaryEmailChangeInProspectRequest={
                primaryEmailChangeInProspectRequest
              }
              deleteEmailFromProspectRequest={deleteEmailFromProspectRequest}
              selectedProspectId={selectedProspectId}              
              setIsExpandedContacts={setIsExpandedContacts}
            />
          )}
          {fieldName === ContactType.PhoneNumber && (
            <IndividualPhoneNumberField
              phonenumber={email}
              PhoneNumbers={contacts}
              setPhoneNumbers={setContacts}
              index={index}
              handleBlur={handleBlur}
              errors={errors}
              touched={touched}
              setFieldTouched={setFieldTouched}
              deletePhoneFromProspectRequest={deletePhoneFromProspectRequest}
              formMode={formMode}
              selectedProspectId={selectedProspectId}   
              setIsExpandedContacts={setIsExpandedContacts}           
            />
          )}
        </div>
      ))}
      <div className="d-flex justify-content-between align-items-center">
        <Button
          variant="link"
          role="button"
          className="p-0 add-email blue-txt-11"
          onClick={() => addContactHandler()}
        >
          + Add {fieldName === ContactType.Email ? 'Email' : 'Phone Number'}
        </Button>
        {formMode === FormMode.Edit && (
          <div>
            {!isExpandedContacts && contacts.length > 3 && (
              // eslint-disable-next-line
              <div
                className="more-contact gray-txt-12 regular-1"
                onClick={() => handleMoreVisibleContacts()}
              >
                +{contacts?.length - 3} more{' '}
                <ChevronDown width={14} height={14} className="ml-1" />
              </div>
            )}
            {isExpandedContacts && (
              // eslint-disable-next-line
              <div
                className="more-contact gray-txt-12 regular-1"
                onClick={() => handleLessVisibleContacts()}
              >
                See less <ChevronUp width={14} height={14} className="ml-1" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
