export enum EmailAccountWarmupSortKey {
  Deliverability = 'deliverability',
  SentToday = 'sent-today',
  ReceivedToday = 'received-today',
}

export enum EmailAccountWarmupOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export enum EmailWarmupStatus {
  Paused = 0,
  Active = 1,
  Blocked = 2,
}
