import {
  Book,
  Briefcase,
  Copy,
  GraduationHat,
  Mail,
  PhoneSignal,
} from '@saleshandy/icons';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from '../../../../shared/design-system/components/overlay';

export default function ViewMore({ lead }) {
  const getTooltip = (text: string) => (
    <Tooltip id="popover-basic" className="bs-tooltip-inner">
      {text}
    </Tooltip>
  );

  const leadProffesionalInfo = lead?.job_history ?? [];
  const leadEducationInfo = lead?.education ?? [];
  const skills = lead?.skills ?? [];
  const emails = lead?.emails ?? [];
  const phones = lead?.phones ?? [];

  const [isContactCopied, setIsContactCopied] = useState(false);

  const onContactCopied = (contact) => {
    navigator.clipboard.writeText(contact);
    setIsContactCopied(true);

    setTimeout(() => {
      setIsContactCopied(false);
    }, 2000);
  };
  return (
    <>
      <div className="view-more d-flex justify-between">
        <div className="lead-company-details">
          {leadProffesionalInfo && leadProffesionalInfo?.length > 0 && (
            <div className="d-flex">
              <Briefcase className="icon-size" />
              <div className="user-description">
                {leadProffesionalInfo &&
                  leadProffesionalInfo?.map((current) => (
                    <p
                      className="lead-description"
                      key={`${lead?.id}-${current?.title}-${current?.company}`}
                    >
                      {current?.title} @ {current?.company}
                    </p>
                  ))}
              </div>
            </div>
          )}
          {leadEducationInfo && leadEducationInfo?.length > 0 && (
            <div className="d-flex user-graduationhat-container">
              <div>
                <GraduationHat className="icon-size" />
              </div>
              <div className="user-graduationhat">
                {leadEducationInfo &&
                  leadEducationInfo.map((currentEducation) => (
                    <p
                      className="lead-description"
                      key={`${lead?.id}-${currentEducation?.start}-${currentEducation?.degree}`}
                    >
                      {currentEducation?.start} - {currentEducation?.end}{' '}
                      {currentEducation?.degree} in {currentEducation?.major}:{' '}
                      {currentEducation?.school}
                    </p>
                  ))}
              </div>
            </div>
          )}
          {skills && skills?.length > 0 && (
            <div className="d-flex">
              <Book className="icon-size" />
              <p className="lead-description user-skill w-100">
                Skills - <span>{skills.join(', ')}</span>
              </p>
            </div>
          )}
        </div>
        {(phones?.length > 0 || emails?.length > 0) && (
          <div className="details lead-company-social-details">
            <p className="more-contact-details">More Contact Details</p>
            {phones &&
              phones?.map((currentPhone) => (
                <div
                  className="d-flex align-items-center user-phone-number"
                  key={`${lead?.id}-${currentPhone?.number}`}
                >
                  <PhoneSignal className="icon-color" />
                  <span className="m-details">
                    {currentPhone?.number}
                    <OverlayTrigger
                      placement={Placement.Bottom}
                      overlay={getTooltip(!isContactCopied ? 'Copy' : 'Copied')}
                    >
                      <Copy
                        className="icon-color copy-icon"
                        onClick={() => {
                          onContactCopied(currentPhone?.number);
                        }}
                      />
                    </OverlayTrigger>
                  </span>
                </div>
              ))}
            {emails &&
              emails?.map((currentEmail) => (
                <div
                  className="d-flex align-items-center user-email"
                  key={`${lead?.id}-${currentEmail?.email}`}
                >
                  <Mail className="icon-color" />
                  <span className="m-details">
                    {currentEmail?.email}
                    <OverlayTrigger
                      placement={Placement.Bottom}
                      overlay={getTooltip(!isContactCopied ? 'Copy' : 'Copied')}
                    >
                      <Copy
                        className="icon-color copy-icon"
                        onClick={() => {
                          onContactCopied(currentEmail?.email);
                        }}
                      />
                    </OverlayTrigger>
                  </span>
                </div>
              ))}
          </div>
        )}
      </div>
    </>
  );
}
