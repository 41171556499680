import React from 'react';
import { Avatar } from '@saleshandy/design-system';
import { DoNotContactList } from '../../../../../../../types/do-not-contact-list';
import { OverlayTooltip } from '../../../../../../../../../shared/design-system/components/overlay';

type IProps = {
  doNotContactList: DoNotContactList;
};

const AddedByField: React.FC<IProps> = ({ doNotContactList: { addedBy } }) => {
  const [firstName = '', lastName = ''] = addedBy?.split(' ');
  return (
    <div className="added-by-field">
      <OverlayTooltip text={addedBy}>
        <Avatar firstName={firstName || ''} lastName={lastName || ''} />
      </OverlayTooltip>
    </div>
  );
};

export default AddedByField;
