/* eslint-disable react/no-danger */
import React, { useMemo, useEffect } from 'react';
import { Button } from '@saleshandy/design-system';
import type { IProps } from './master-class-chapter-content-container';
import { ArrowsMaximize } from '../../../../shared/svg/arrows-maximize';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { ArrowsMinimize } from '../../../../shared/svg';
import { OverlayTooltip } from '../../../../shared/design-system/components/overlay';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';
import { getIsRequestPending } from '../../../sequence/helpers/helper';

const MasterClassChapterContent: React.FC<IProps> = ({
  topic,
  currentLessonId,
  academyLessons,
  isMaximized,
  onNextTopic,
  onPreviousTopic,
  onMaximizeMinimize,
  updateAcademyLessonProgress,
  updateAcademyTopicProgressRequestStatus,
}) => {
  const lastLessonId = useMemo(
    () => academyLessons && academyLessons[0]?.lessons.slice(-1),
    [],
  );
  const lastTopicId = useMemo(
    () => lastLessonId && lastLessonId[0]?.topics[0].id,
    [],
  );
  const firstTopicId = useMemo(
    () => academyLessons && academyLessons[0]?.lessons[0]?.topics[0]?.id,
    [],
  );

  //* Functions
  const onNext = () => {
    updateAcademyLessonProgress({
      courseId: 1,
      lessonId: currentLessonId,
      topicId: topic.id,
    });
  };

  //* useEffect
  useEffect(() => {
    executeOnRequestStatus({
      status: updateAcademyTopicProgressRequestStatus,
      onSuccess: () => {
        onNextTopic();
      },
    });
  }, [updateAcademyTopicProgressRequestStatus]);

  return (
    <div className="master-class-content">
      <div className="master-class-content__header d-flex justify-content-between">
        <span className="semibold-3 popover-arrow-color-txt">
          {topic?.title}
        </span>

        <OverlayTooltip text={isMaximized ? 'Collapse' : 'Expand'}>
          <div className="regular-4" {...accessibleOnClick(onMaximizeMinimize)}>
            {isMaximized ? <ArrowsMinimize /> : <ArrowsMaximize />}
          </div>
        </OverlayTooltip>
      </div>
      <div className="master-class-content__section">
        <div
          dangerouslySetInnerHTML={{
            __html: topic?.body,
          }}
        />
      </div>
      <div className="master-class-content__footer d-flex justify-content-between">
        <Button
          variant="secondary"
          onClick={onPreviousTopic}
          disabled={firstTopicId === topic?.id}
        >
          Previous
        </Button>
        <div className="d-flex">
          {lastTopicId !== topic?.id && (
            <Button variant="tertiary" className="mr-1" onClick={onNextTopic}>
              Skip
            </Button>
          )}
          <Button
            onClick={onNext}
            isLoading={getIsRequestPending(
              updateAcademyTopicProgressRequestStatus,
            )}
          >
            {lastTopicId === topic?.id ? 'Complete' : 'Next'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MasterClassChapterContent;
