class PauseLeadFinderHelper {
  private _showLeadFinderResumePlanModal;

  bindActions = (showLeadFinderResumePlanModal) => {
    // eslint-disable-next-line no-underscore-dangle
    this._showLeadFinderResumePlanModal = showLeadFinderResumePlanModal;
  };

  // eslint-disable-next-line no-underscore-dangle
  showModal = () => this._showLeadFinderResumePlanModal();
}

export default PauseLeadFinderHelper;
