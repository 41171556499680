import React, {
  ReactNode,
  Ref,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import composeRefs from '@seznam/compose-react-refs';
import classNames from 'classnames';
import Transfer, {
  TransferOverlayTriggerProps,
} from '../transfer/transfer-overlay-trigger';
import withResizeDetectorRef from '../../../utils/HOC/with-resize-detector-ref';
import SelectBar, { SelectBarProps } from './select-bar';
import { ExtendedOption } from '../transfer/transfer';

const SelectBarWithResizeDetector = withResizeDetectorRef<
  SelectBarProps,
  HTMLDivElement
>(SelectBar);

type SelectProps<T> = Omit<TransferOverlayTriggerProps<T>, 'children'> &
  Pick<SelectBarProps, 'placeholder' | 'disabled'> & {
    selectedOptionRenderer: (options: T[]) => ReactNode;
    // eslint-disable-next-line react/require-default-props
    selectBarRef?: Ref<HTMLDivElement>;
    // eslint-disable-next-line react/require-default-props
    optionsListRef?: Ref<HTMLDivElement>;
  };

const Select = <T extends ExtendedOption>({
  style,
  placeholder,
  disabled,
  selectedOptionRenderer,
  show: showProp,
  rootClose: rootCloseProp,
  onToggle: onToggleProp,
  options,
  selectedOptionKey,
  selectBarRef,
  optionsListRef,
  onChange,
  className,
  ...rest
}: SelectProps<T>) => {
  const selectBarInternalRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [show, setShow] = useState(false);

  let selectedOptionKeyArr = [];

  if (selectedOptionKey) {
    selectedOptionKeyArr = Array.isArray(selectedOptionKey)
      ? selectedOptionKey
      : [selectedOptionKey];
  }
  const selectedOption = useMemo(
    () =>
      selectedOptionKeyArr.map((key) =>
        options.find((option) => option.key === key),
      ),
    [selectedOptionKeyArr, options],
  );

  let selectBarContent;

  if (selectedOption.length > 0) {
    selectBarContent = selectedOptionRenderer(selectedOption);
  }

  const handleShow = showProp === undefined;

  const onChangeHandler = useCallback(
    (selectOptions) => {
      handleShow && setShow(false);
      onChange?.(selectOptions);
    },
    [handleShow, setShow, onChange],
  );

  const onToggleHandler = useCallback(
    (value) => {
      handleShow && setShow(value);
      onToggleProp?.(value);
    },
    [handleShow, setShow, onToggleProp],
  );

  const onResizeHandler = useCallback(() => {
    // width parameter of onResize callback is not equal to the width
    // got from the below getBoundingClientRect() method.
    const {
      width: selectBarWidth,
    } = selectBarInternalRef.current.getBoundingClientRect();
    setWidth(selectBarWidth);
  }, [selectBarInternalRef, setWidth]);

  const transferClass = classNames(['transfer-select-box', className]);

  return (
    <Transfer<T>
      {...rest}
      className={transferClass}
      options={options}
      selectedOptionKey={selectedOptionKey}
      style={{ ...style, width: `${width}px` }}
      ref={optionsListRef}
      onChange={onChangeHandler}
      show={!disabled && ((handleShow && show) || showProp)}
      rootClose={(handleShow && true) || rootCloseProp}
      onToggle={!disabled && onToggleHandler}
    >
      <SelectBarWithResizeDetector
        ref={composeRefs(selectBarRef, selectBarInternalRef)}
        onResize={onResizeHandler}
        content={selectBarContent}
        placeholder={placeholder}
        disabled={disabled}
        show={show}
      />
    </Transfer>
  );
};

Select.defaultProps = {
  // eslint-disable-next-line
  showSearch: false,
};

export default Select;
