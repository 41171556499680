import { InfoCircle } from '@saleshandy/icons';
import React from 'react';
import { LinkBadgeProps } from './type';

const LinkBadge: React.FC<LinkBadgeProps> = ({
  label = 'Know more',
  url,
  className,
  Icon = <InfoCircle className="link-badge_Icon" />,
}) => (
  <a href={url} target="_blank" rel="noreferrer" className="link-badge-anchor">
    <button type="button" className={`link-badge-button  ${className}`}>
      {Icon}
      {label}
    </button>
  </a>
);

export default LinkBadge;
