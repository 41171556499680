import React from 'react';
import { Clock, Trash } from '@saleshandy/icons';
import { useHistory } from 'react-router-dom';
import SearchingEmptyImage from '../../../../shared/components/images/searching-empty-image';
import toaster from '../../../../shared/toaster';
import {
  capitalizeFirstLetter,
  getTimeAgoString,
  mapObjectToString,
} from '../../helpers/helper';
import { LEAD_RECENT_SEARCH_MESSAGES } from '../../helpers/leads-message';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';

export default function RecentSearch({
  storedUrls,
  setStoredUrls,
  loginUserEmail,
}) {
  const history = useHistory();

  const deleteUrlByUrl = (urlToDelete) => {
    const updatedUrls = storedUrls.filter((url) => url.url !== urlToDelete);

    setStoredUrls(updatedUrls);

    const allStoredUrls = JSON.parse(localStorage.getItem('storedUrls')) || {};
    allStoredUrls[loginUserEmail] = updatedUrls;
    localStorage.setItem('storedUrls', JSON.stringify(allStoredUrls));

    return toaster.success(
      LEAD_RECENT_SEARCH_MESSAGES.recent_search_delete_msg,
    );
  };
  return (
    <>
      <div className="recent-search">
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <div>
            <div className="icon-alignment pt-4">
              <SearchingEmptyImage />
            </div>
            <h3>
              Search our database of 700M+ contacts across 60M+ companies
              worldwide.
            </h3>
            <div>
              <p className="service-activity">Recent Searches</p>
              <p className="latest-activity">
                {storedUrls?.length > 0
                  ? 'Your latest prospecting activity'
                  : 'No recent search found!'}
              </p>
            </div>
          </div>
          <div
            className={`${
              storedUrls?.length === 0 ? 'table-nodata' : 'table-border'
            }`}
          >
            {storedUrls &&
              storedUrls?.map((url) => (
                <div
                  className="table-data"
                  key={url.url}
                  {...accessibleOnClick(() => history.push(url?.url))}
                >
                  {/* eslint-disable-next-line  */}
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="clock-bg">
                        <Clock className="clock-icon" />
                      </div>
                    </div>
                    <div className="prospect-details">
                      <div className="d-flex align-items-center">
                        <p className="bold-text w-100 mb-0">
                          {capitalizeFirstLetter(
                            mapObjectToString(url?.payload).split(',')[0],
                          )}
                          {mapObjectToString(url?.payload).split(',')?.length >
                            1 && ','}{' '}
                          <span className="normal-text mb-0">
                            {mapObjectToString(url?.payload)
                              .split(',')
                              .slice(1)
                              .join(',')}
                          </span>
                        </p>
                      </div>
                      <p className="mb-0 days">
                        {getTimeAgoString(url?.timestamp)}
                      </p>
                    </div>
                  </div>
                  <div>
                    <Trash
                      className="Trash-icon ml-2 cursor-pointer"
                      onClick={(e) => {
                        e?.stopPropagation();
                        deleteUrlByUrl(url?.url);
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
