import React from 'react';
import DeleteAlertModal from '../../../../../../../shared/components/delete-alert-modal';

const DeleteTeamModal = ({ show, onSubmit, onClose, isLoading }) => (
  <DeleteAlertModal
    show={show}
    title="Delete Team"
    contents={[
      'Are you sure you want to delete the selected team?',
      'Once deleted, all members will be unassigned from the team and team will be removed from the account.',
    ]}
    onSubmit={onSubmit}
    onClose={onClose}
    isSubmitLoading={isLoading}
  />
);

export default DeleteTeamModal;
