import React from 'react';

export const DNCEmptyList = () => (
  <svg
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28137 51.4706C7.07661 45.042 4.22455 44.262 1.52259 38.1707C1.40868 37.9166 1.30843 37.6624 1.21275 37.3989C-0.104061 33.7432 0.429042 29.459 2.60246 26.2516C5.63705 21.7732 11.1549 19.8645 16.3447 18.5335C21.539 17.1978 27.0751 16.0147 31.0027 12.3128C32.8891 10.5335 34.2788 8.27812 36.0695 6.40175C37.8602 4.52537 40.2979 2.98175 42.8586 3.26829C46.1028 3.62877 48.3855 6.79458 49.2832 9.97888C50.1853 13.1586 50.1307 16.5508 50.9736 19.749C52.336 24.8836 55.9219 29.1355 59.9498 32.537C63.9777 35.9385 68.5159 38.6699 72.6303 41.9697C77.5467 45.912 82.2444 51.8369 79.4696 58.2887C78.2986 61.0108 75.9976 63.0582 73.2819 64.172C66.7935 66.834 60.1822 64.99 53.7257 63.6174C38.3181 60.3516 33.8024 65.5226 22.204 74.038C18.5588 76.7139 13.6834 78.2067 9.50063 76.5198C5.80992 75.027 3.31755 71.2558 2.5794 67.295C1.8367 63.3343 2.63864 59.2165 4.02835 55.4406C4.51589 54.1142 4.90774 52.7832 5.27682 51.4706H5.28137Z"
      fill="#EFF6FF"
    />
    <path
      d="M11.0844 18.3327C6.96514 19.3217 4.40328 23.4791 5.39639 27.6153C6.38544 31.7345 10.5428 34.2964 14.679 33.3032C18.8152 32.31 21.3561 28.1401 20.3672 24.0205C19.3783 19.901 15.2038 17.3435 11.0844 18.3327ZM12.0051 22.1674C12.4662 22.0567 12.9498 22.0855 13.3945 22.25C13.8392 22.4145 14.2251 22.7074 14.5031 23.0915C14.7811 23.4757 14.9388 23.9337 14.9562 24.4076C14.9735 24.8814 14.8497 25.3498 14.6005 25.7532C14.3513 26.1566 13.9879 26.4769 13.5564 26.6735C13.1249 26.8701 12.6447 26.9341 12.1768 26.8574C11.7089 26.7807 11.2742 26.5667 10.9281 26.2427C10.5819 25.9186 10.3398 25.4991 10.2324 25.0372C9.92679 23.7646 10.7323 22.4731 12.0051 22.1674ZM14.3091 31.7626C12.9417 32.0931 11.502 31.9379 10.2364 31.3237C11.0267 29.8942 12.176 28.9274 13.5492 28.5977C14.9223 28.268 16.3979 28.5867 17.7384 29.5223C16.8898 30.6442 15.6775 31.4362 14.3091 31.7626Z"
      fill="#CAE2FF"
    />
    <g clipPath="url(#clip0_38184_72528)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.4584 46.9713L19.7066 37.4096C19.2726 37.1291 18.9167 36.7486 18.6708 36.3019C18.4248 35.8552 18.2963 35.3562 18.2969 34.8496C18.2969 33.0719 19.7831 31.627 21.6098 31.627H50.984C52.8107 31.627 54.2969 33.0718 54.2969 34.8478C54.2969 35.8808 53.7699 36.8378 52.8871 37.4096L38.1353 46.9713C37.5769 47.3332 36.9369 47.5142 36.2968 47.5142C35.6568 47.5142 35.0168 47.3333 34.4584 46.9713ZM36.2969 49.5652C37.3695 49.5652 38.4098 49.2583 39.3055 48.6778L54.0566 39.1165C54.1382 39.0635 54.2183 39.0082 54.2968 38.9509V54.6187C54.2968 56.383 52.8304 57.8407 50.9828 57.8407H21.6109C19.7963 57.8407 18.2969 56.4151 18.2969 54.6187V38.951C18.3752 39.0082 18.4551 39.0633 18.5366 39.1162L33.2883 48.6778C34.184 49.2583 35.2243 49.5652 36.2969 49.5652Z"
        fill="#BFDBFE"
      />
    </g>
    <path
      d="M62.6545 56.0282C60.1979 54.985 57.3423 56.1298 56.2948 58.5965C55.2516 61.053 56.3964 63.9086 58.8631 64.9561C61.3297 66.0036 64.1794 64.8445 65.2228 62.3879C66.2663 59.9312 65.1111 57.0715 62.6545 56.0282ZM61.6833 58.3151C61.9582 58.4319 62.1923 58.6277 62.3559 58.8777C62.5194 59.1277 62.605 59.4207 62.6018 59.7194C62.5986 60.0181 62.5067 60.3092 62.3379 60.5556C62.169 60.8021 61.9308 60.9928 61.6534 61.1037C61.376 61.2145 61.0719 61.2405 60.7797 61.1783C60.4875 61.1161 60.2204 60.9685 60.0122 60.7543C59.804 60.54 59.6641 60.2688 59.6103 59.9749C59.5565 59.6811 59.5912 59.3779 59.7099 59.1038C60.0322 58.3448 60.9242 57.9928 61.6833 58.3151ZM59.2532 64.0373C58.437 63.6921 57.7661 63.0739 57.3555 62.2885C58.2915 61.8608 59.2359 61.8022 60.0548 62.1499C60.8737 62.4977 61.5014 63.2122 61.8295 64.1884C60.9792 64.4384 60.0685 64.385 59.2532 64.0373Z"
      fill="#BFDBFE"
    />
    <path
      d="M58.2973 36.7339L51.2973 25.2339C50.4639 25.2339 48.6973 25.9339 48.2973 28.7339C47.7973 32.2339 49.7973 35.2339 52.7973 37.2339C55.1973 38.8339 57.4639 37.5672 58.2973 36.7339Z"
      fill="#EFF6FF"
    />
    <circle
      cx="55.9484"
      cy="30.1008"
      r="7.71406"
      stroke="#9CC8FF"
      strokeWidth="1.875"
    />
    <path
      d="M52.4241 22.7308L60.1144 37.1501"
      stroke="#9CC8FF"
      strokeWidth="1.875"
    />
    <defs>
      <clipPath id="clip0_38184_72528">
        <rect
          width="36"
          height="35"
          fill="white"
          transform="translate(18.2969 27.2339)"
        />
      </clipPath>
    </defs>
  </svg>
);
