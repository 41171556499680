import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FileX } from '@saleshandy/icons';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import Input from '../../../../../../../shared/design-system/components/input';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import RadioButtonGroup from '../../../../../../../shared/design-system/components/radio-button-group';
import AgencyClientDropdown from '../../../../../../agency-client-management/components/agency-client-dropdown';

import { DoNoContactListApplicableTo } from '../../../../../enums/do-not-contact-list';

import { shouldSaveButtonDisabled, validate } from './helper';

import {
  AddModifyProps,
  AddModifyStateValues,
  AddModifyStateErrors,
  AddModifyStateDirty,
} from './types';
import { AgencyClientStatus } from '../../../../../../agency-client-management/enums/agency-client';
import { AgencyClientFilter } from '../../../../../../agency-client-management/types/types';
import { UpdateDoNotContactListPayload } from '../../../../../types/do-not-contact-list';
import { getClientName } from '../../../../../../agency-client-management/helpers/helpers';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import { isAgency } from '../../../../../../../shared/utils/user-details';
import { getListHelperText } from '../../../helper/get-list-helper-text';
import Alert from '../../../../../../../shared/design-system/ui/alert';

const AddModifyDoNotContactList: React.FC<AddModifyProps> = ({
  show,
  action,
  defaultDoNotContactList,
  isLoading,
  onClose,
  onAddDoNotContactList,
  onUpdateDoNotContactList,

  agencyClients,
  sendGetAgencyClientsDropdownRequest,
}) => {
  const [modalMeta, setModalMeta] = useState({
    title: 'Add List',
    submitButtonText: 'Add',
  });

  const [values, setValues] = useState<AddModifyStateValues>({
    dncListName: '',
    applicableTo: DoNoContactListApplicableTo.AllClients,
  });

  const [errors, setErrors] = useState<AddModifyStateErrors>({
    dncListName: '',
    applicableTo: '',
  });

  const [dirty, setDirty] = useState<AddModifyStateDirty>({
    dncListName: false,
    applicableTo: true,
  });

  const [selectedClient, setSelectedClient] = useState<AgencyClientFilter>({
    id: -1,
    firstName: '',
    lastName: '',
    email: '',
    companyName: '',
    status: AgencyClientStatus.Active,
  });

  const onInputChange = (
    value: string,
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();

    const { name } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    setDirty({
      ...dirty,
      [name]: true,
    });

    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: validate(name, value),
      });
    }
  };

  const onInputBlur = (e) => {
    const { name } = e.target;

    setErrors({
      ...errors,
      [name]: validate(name, values[name]),
    });
  };

  const onListApplicableToChange = (value: DoNoContactListApplicableTo) => {
    setValues({ ...values, applicableTo: value });
  };

  const handleSubmit = () => {
    const dirtyRef = { ...dirty };
    const dirtyKeys = Object.keys(dirtyRef);

    dirtyKeys.forEach((key) => {
      dirtyRef[key] = true;
    });

    const errorsRef = { ...errors };
    const errorsKeys = Object.keys(errorsRef);
    let isError = false;

    errorsKeys.forEach((key) => {
      const error = validate(key, values[key]);

      errorsRef[key] = error;
      isError = isError || !!error;
    });

    if (
      values.applicableTo === DoNoContactListApplicableTo.SpecificClient &&
      selectedClient.id === -1
    ) {
      isError = true;
    }

    setErrors(errorsRef);
    setDirty(dirtyRef);

    if (isError) {
      return;
    }

    if (action === 'add') {
      const isGlobalAssociation =
        values.applicableTo === DoNoContactListApplicableTo.AllClients;
      onAddDoNotContactList?.({
        dncListName: values.dncListName,
        isGlobalAssociation,
        ...(values.applicableTo ===
          DoNoContactListApplicableTo.SpecificClient && {
          clientId: selectedClient.id,
        }),
      });
      return;
    }

    if (action === 'modify') {
      const isGlobalAssociation =
        values.applicableTo === DoNoContactListApplicableTo.AllClients;

      let payload: UpdateDoNotContactListPayload = {
        id: defaultDoNotContactList.id,
        isGlobalAssociation,
      };

      if (defaultDoNotContactList.name !== values.dncListName) {
        payload = { ...payload, dncListName: values.dncListName };
      }

      if (
        !isGlobalAssociation &&
        defaultDoNotContactList?.client?.id !== selectedClient.id
      ) {
        payload = { ...payload, clientId: selectedClient.id };
      }

      onUpdateDoNotContactList(payload);
    }
  };

  const hasErrors = () => {
    let isError = false;

    Object.keys(errors).forEach((key) => {
      if (errors[key] !== '' || (action !== 'modify' && !dirty[key])) {
        isError = true;
      }
    });

    if (
      values.applicableTo === DoNoContactListApplicableTo.SpecificClient &&
      selectedClient.id === -1
    ) {
      isError = true;
    }

    return isError;
  };

  useLayoutEffect(() => {
    if (show && action === 'modify') {
      setModalMeta({
        title: 'Modify List',
        submitButtonText: 'Save',
      });
    }
  }, [show]);

  useEffect(() => {
    if (show && defaultDoNotContactList) {
      const isGlobalList = isEmpty(defaultDoNotContactList.client);
      setValues({
        dncListName: defaultDoNotContactList?.name,
        applicableTo: isGlobalList
          ? DoNoContactListApplicableTo.AllClients
          : DoNoContactListApplicableTo.SpecificClient,
      });

      if (!isGlobalList) {
        setSelectedClient(
          defaultDoNotContactList?.client as AgencyClientFilter,
        );
      }
    }
  }, [show, defaultDoNotContactList]);

  useEffect(() => {
    if (!show) {
      setModalMeta({
        title: 'Add List',
        submitButtonText: 'Add',
      });
      setValues({
        dncListName: '',
        applicableTo: DoNoContactListApplicableTo.AllClients,
      });
      setErrors({
        dncListName: '',
        applicableTo: '',
      });
      setDirty({
        dncListName: false,
        applicableTo: true,
      });
      setSelectedClient({
        id: -1,
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        status: AgencyClientStatus.Active,
      });
    }
  }, [show]);

  useEffect(() => {
    if (show && hasPermission(Permissions.CLIENT_READ) && isAgency()) {
      sendGetAgencyClientsDropdownRequest({
        status: AgencyClientStatus.Active,
        search: '',
      });
    }
  }, [show]);

  const renderRadioButton = (title: string) => (
    <div className="radio-input-btn">
      <h2>{title}</h2>
    </div>
  );

  const isAgencyUser = hasPermission(Permissions.CLIENT_READ) && isAgency();

  const helperAlertWarningClassname = classNames([
    'helper-alert-warning-wrapper',
    {
      'rounded-text-color': !isAgencyUser,
    },
  ]);

  return (
    <Modal
      show={show}
      className="h-100 add-modify-dnc-list-modal"
      onClose={onClose}
      hideFooter={false}
      submitButtonText={modalMeta.submitButtonText}
      backdrop="static"
      hideHeader={true}
      onSubmit={handleSubmit}
      cancelButtonClassName="cancel-btn"
      submitButtonClassName="submit-btn"
      isSubmitDisabled={
        isLoading ||
        hasErrors() ||
        shouldSaveButtonDisabled(
          action,
          values,
          defaultDoNotContactList,
          selectedClient,
        )
      }
      isSubmitLoading={isLoading}
      extraModalProps={{
        enforceFocus: false,
      }}
    >
      <div className="add-modify-dnc-list-modal--container">
        <div className="title-container d-flex align-items-center">
          <FileX width={24} height={24} />
          <h5>{modalMeta.title}</h5>
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Input
            name="dncListName"
            label="List Name"
            placeholder="E.g. USA DNC List "
            value={values.dncListName}
            variant={errors.dncListName && Input.Variant.Error}
            caption={errors.dncListName}
            onChange={onInputChange}
            onBlur={onInputBlur}
            autoFocus
          />

          {isAgencyUser && (
            <>
              <div className="radio-input-wrapper">
                <h1>Applicable to</h1>
                <div className="radio-input-container">
                  <RadioButtonGroup
                    name="applicableTo"
                    value={values.applicableTo}
                    options={[
                      {
                        label: renderRadioButton('All Clients (Global)'),
                        value: DoNoContactListApplicableTo.AllClients,
                        disabled: false,
                      },
                      {
                        label: renderRadioButton('Specific Client'),
                        value: DoNoContactListApplicableTo.SpecificClient,
                        disabled: false,
                      },
                    ]}
                    onChange={onListApplicableToChange}
                  />
                </div>
              </div>

              {values.applicableTo ===
                DoNoContactListApplicableTo.SpecificClient && (
                <AgencyClientDropdown
                  options={agencyClients}
                  initialSelectedOption={selectedClient}
                  className="agency-select-dropdown"
                  selectedOptionClassName="w-100"
                  onChange={(option) => {
                    if (option) {
                      setSelectedClient(option);
                    }
                  }}
                  optionRenderer={(option) => (
                    <div className="d-flex align-items-center">
                      <span>
                        {getClientName(option, 60, {
                          company: true,
                          email: true,
                          deleted: true,
                        })}
                      </span>
                    </div>
                  )}
                  disabled={false}
                />
              )}
            </>
          )}

          <Alert
            variant="alert"
            description={
              <>
                {getListHelperText({
                  isAgencyUser,
                  messageSpecificFor: 'list',
                  isGlobalList:
                    values.applicableTo ===
                    DoNoContactListApplicableTo.AllClients,
                })}
              </>
            }
            className={helperAlertWarningClassname}
          />
        </form>
      </div>
    </Modal>
  );
};

export default AddModifyDoNotContactList;
