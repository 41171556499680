import { EmailInboxFilters } from '../../type';

const emailInboxFilterKey = 'email-inbox-filters';
const emailInboxMetaDetailsKey = 'email-inbox-meta-details';

export const setEmailInboxFiltersInLocalStore = (
  filters: EmailInboxFilters,
) => {
  localStorage.setItem(emailInboxFilterKey, JSON.stringify(filters));
};

export const getEmailInboxFiltersFromLocalStore = (): EmailInboxFilters =>
  JSON.parse(localStorage.getItem(emailInboxFilterKey));

export const removeEmailInboxFiltersFromLocalStore = (): void => {
  localStorage.removeItem(emailInboxFilterKey);
};

export const getEmailInboxMetaDetailsFromLocalStore = () =>
  JSON.parse(localStorage.getItem(emailInboxMetaDetailsKey));
export const setEmailInboxMetaDetailsInLocalStore = (metaDetails: any) =>
  localStorage.setItem(emailInboxMetaDetailsKey, JSON.stringify(metaDetails));
export const removeEmailInboxMetaDetailsFromLocalStore = () => {
  localStorage.removeItem(emailInboxMetaDetailsKey);
};
