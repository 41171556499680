import React, { useState } from 'react';
import { Accordion, Button } from '@saleshandy/design-system';
import { ChevronDown, ChevronUp, LayoutList } from '@saleshandy/icons';
import Transfer from '../../../../../../../../shared/design-system/components/transfer';
import type { TaskTypeFilter, IProps } from './types';
import {
  getIsAllCheckboxChecked,
  getSelectedOptionKey,
  onLinkedInOptionClicked,
  tasksTypeFilterOptions,
} from './helper';
import { Placement } from '../../../../../../../../shared/design-system/components/overlay';
import Checkbox from '../../../../../../../../shared/design-system/components/atoms/checkbox';
import { accessibleOnClick } from '../../../../../../../../shared/utils/accessible-on-click';
import { onUserOptionClicked } from '../../../../../../../../shared/components/teams-filter/utils/on-select-deselect';
import { getUniqueNumbers } from '../../../../../../../../shared/utils';
import {
  getTaskTypeFiltersInLocalStore,
  removeTaskTypeFiltersInLocalStore,
  setTaskTypeFiltersInLocalStore,
} from '../../../../utils/helper';

const TaskTypeSelect: React.FC<IProps> = ({ onApply }) => {
  const [show, setShow] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedTypeIds, setSelectedTypesIds] = useState<number[]>(
    getTaskTypeFiltersInLocalStore() || [2, 3, 4, 5, 6, 7, 9],
  );
  const [isFilterDirty, setIsFilterDirty] = useState(false);

  const { isSelectAllCheckboxChecked, intermediate } = getIsAllCheckboxChecked(
    tasksTypeFilterOptions,
    selectedTypeIds,
  );

  const onSelectDeselect = (option: TaskTypeFilter) => {
    if (!option?.subTypes?.length) {
      onUserOptionClicked(option.id, selectedTypeIds, setSelectedTypesIds);
    }
    if (option?.subTypes?.length > 0) {
      onLinkedInOptionClicked(option, selectedTypeIds, setSelectedTypesIds);
    }
    if (!isFilterDirty) {
      setIsFilterDirty(true);
    }
  };

  const onSelectAll = (checked: boolean) => {
    const allTaskTypeIds = tasksTypeFilterOptions.reduce(
      (taskTypeIds, option) => {
        if (option?.subTypes?.length > 0) {
          taskTypeIds.push(...option.subTypes.map((t) => t.id));
        } else if (!option?.subTypes?.length) {
          taskTypeIds.push(option.id);
        }
        return taskTypeIds;
      },
      [],
    );

    if (checked) {
      setSelectedTypesIds(
        getUniqueNumbers([...selectedTypeIds, ...allTaskTypeIds]),
      );
    } else {
      setSelectedTypesIds(
        selectedTypeIds.filter((id) => !allTaskTypeIds.includes(id)),
      );
    }
    if (!isFilterDirty) {
      setIsFilterDirty(true);
    }
  };

  const handleApply = () => {
    if (isSelectAllCheckboxChecked) {
      removeTaskTypeFiltersInLocalStore();
    } else {
      setTaskTypeFiltersInLocalStore(selectedTypeIds);
    }

    onApply(selectedTypeIds);
  };

  return (
    <Transfer<TaskTypeFilter>
      show={show}
      onToggle={setShow}
      className="task-type-filter-select team-filter-select"
      multiSelect
      showSearch={false}
      placement={Placement.BottomStart}
      options={tasksTypeFilterOptions}
      selectedOptionKey={getSelectedOptionKey(
        tasksTypeFilterOptions,
        selectedTypeIds,
      )}
      onSelect={onSelectDeselect}
      onDeselect={onSelectDeselect}
      optionRenderer={(option: TaskTypeFilter) =>
        option?.subTypes ? (
          <Accordion
            allowMultipleExpanded
            allowZeroExpanded
            preExpanded={[option.id]}
          >
            <Accordion.Item uuid={option.id}>
              <Accordion.ItemHeading>
                <Accordion.ItemButton>
                  <span>{option.label}</span>
                  <Accordion.ItemState>
                    {({ expanded }) =>
                      expanded ? <ChevronUp /> : <ChevronDown />
                    }
                  </Accordion.ItemState>
                </Accordion.ItemButton>
              </Accordion.ItemHeading>
              {option?.subTypes?.length > 0 && (
                <Accordion.ItemPanel>
                  {option.subTypes.map((taskType) => (
                    <div
                      key={taskType.id}
                      className="team-member"
                      {...accessibleOnClick(() => {
                        onUserOptionClicked(
                          taskType.id,
                          selectedTypeIds,
                          setSelectedTypesIds,
                        );
                      })}
                    >
                      <Checkbox
                        checked={selectedTypeIds.includes(taskType.id)}
                      />
                      <span className="team-member-name">{taskType.label}</span>
                    </div>
                  ))}
                </Accordion.ItemPanel>
              )}
            </Accordion.Item>
          </Accordion>
        ) : (
          <div
            className="single-team-member"
            {...accessibleOnClick(() =>
              onUserOptionClicked(
                option.id,
                selectedTypeIds,
                setSelectedTypesIds,
              ),
            )}
          >
            <span className="team-member-name">{option.label}</span>
          </div>
        )
      }
      showOptionsSeparator={true}
      clickListenerOn="checkbox"
      {...(!isEmpty && {
        footer: (
          <div className="team-filter-footer d-flex justify-content-between align-items-center">
            <Checkbox
              checked={isSelectAllCheckboxChecked}
              intermediate={intermediate}
              label="Select All"
              onChange={onSelectAll}
            />
            <Button
              onClick={() => {
                handleApply();
                setShow(false);
              }}
              disabled={selectedTypeIds.length === 0}
              className="apply-btn"
            >
              Apply
            </Button>
          </div>
        ),
      })}
    >
      <Button
        className="task-type-btn transfer-select-btn"
        variant="tertiary"
        onClick={() => setShow(!show)}
      >
        <div className="transfer-select-btn-content">
          <div className="select-icon">
            <LayoutList width={16} height={16} />
          </div>
          {selectedTypeIds.length === 7
            ? 'All Tasks'
            : `${selectedTypeIds.length} Task Types Selected`}
        </div>
        <div className="bs-select-box-icon-wrapper">
          {show ? <ChevronUp /> : <ChevronDown />}
        </div>
      </Button>
    </Transfer>
  );
};

export default TaskTypeSelect;
