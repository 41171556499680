import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import { OutcomeContentTabs } from '../types';
import { OutcomeSentimentBGColor } from '../../../../../enums/custom-outcome';
import { DeleteCustomOutcomeDropdown } from '../../delete-custom-outcome-modal/types';
import { Outcome } from '../../../../../types/outcome';
import { getOutcomeIcon } from '../../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';

export const getCustomOutcomesColumns = () => [
  {
    dataField: 'processedName',
    text: 'Outcome Name',
    component: (cell) => {
      if (cell) {
        const { name, sentiment, isDefault } = cell;
        return (
          <div className="field-name-container">
            <div className="field-name-content">
              <div>
                {getOutcomeIcon(name, false, {
                  isDefault,
                  sentiment,
                  width: 16,
                  height: 16,
                })}
              </div>
              <div>{name}</div>
            </div>
          </div>
        );
      }
      return '-';
    },
    headerLoadingSkeleton: <SkeletonLoading width={54} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
  },
  {
    dataField: 'processedSentiment',
    text: 'Sentiment',
    component: (cell) => {
      if (cell) {
        const { sentiment, bgColor, textColor } = cell;
        return (
          <div
            className="field-hashed-id-content"
            style={{ background: bgColor, color: textColor }}
          >
            <div
              className="field-dot"
              style={{ background: OutcomeSentimentBGColor[sentiment] }}
            />
            <div className="field-sentiment">{sentiment}</div>
          </div>
        );
      }
      return '-';
    },
    headerLoadingSkeleton: <SkeletonLoading width={34} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
  },
];

export const generateOutcomesActionsColumn = (tab: OutcomeContentTabs) =>
  tab === OutcomeContentTabs.CustomOutcomes &&
  (hasPermission(Permissions.OUTCOME_UPDATE) ||
    hasPermission(Permissions.OUTCOME_DELETE))
    ? [
        hasPermission(Permissions.OUTCOME_UPDATE) && {
          displayName: 'Edit',
          icon: 'pencil',
          key: 'edit',
          position: 'out',
        },
        hasPermission(Permissions.OUTCOME_DELETE) && {
          displayName: 'Delete',
          icon: 'trash',
          key: 'delete',
          position: 'out',
        },
      ].filter((obj) => obj)
    : null;

export const getDefaultTableWrapper = (
  t: OutcomeContentTabs,
  customFieldPaginationCount: number,
  defaultFieldPaginationCount: number,
) => {
  switch (t) {
    case OutcomeContentTabs.CustomOutcomes:
      return customFieldPaginationCount > 25 ? 'paginated-table' : '';
    case OutcomeContentTabs.SystemOutcomes:
      return defaultFieldPaginationCount > 25 ? 'paginated-table' : '';
    default:
      return '';
  }
};

export const getDeleteOutcomeDropdownOptions = (
  allOutcomes: Outcome[],
  actionOutcomeId: number,
  isSort = true,
): DeleteCustomOutcomeDropdown[] => {
  const options: DeleteCustomOutcomeDropdown[] = [];
  allOutcomes.forEach((outcome: Outcome) => {
    const currentOption: DeleteCustomOutcomeDropdown = {
      key: outcome.id,
      name: outcome.name,
      sentiment: outcome.sentiment,
      bgColor: outcome.bgColor,
      textColor: outcome.textColor,
      emoji: outcome.emoji,
      isDefault: outcome.isDefault,
    };
    if (currentOption.key !== actionOutcomeId) {
      options.push(currentOption);
    }
  });
  return isSort ? options.sort((a, b) => a.key - b.key) : options;
};
