import React from 'react';
import DeleteAlertModal from '../../../../../../../shared/components/delete-alert-modal';

const DeleteUserModal = ({ show, onSubmit, onClose, isLoading }) => (
  <DeleteAlertModal
    show={show}
    title="Delete User"
    contents={[
      'Are you sure you want to delete the selected user permanently?',
      'If You delete this user, User will no longer be a part of a Saleshandy Account.',
    ]}
    onSubmit={onSubmit}
    onClose={onClose}
    isSubmitLoading={isLoading}
  />
);

export default DeleteUserModal;
