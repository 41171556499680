import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import ConfirmationModalV3 from '../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../shared/design-system/components/atoms/confirmation-modal/enum';
import { getIsRequestPending } from '../../shared/utils/get-request-status';
import { EmailAccountMethod } from '../email-accounts/enums/email-account';
import type { IProps } from './email-warmup-container';
import {
  connectEmailAccountHandler,
  emailWarmupToggleHandler,
  getEnableEmailWarmupConsent,
  onTunrOnOffRequestSuccessHandler,
  reconnectEmailAccountHandler,
  showRequestError,
} from './helpers/helper';
import { EmailAccountWarmupList } from './types';
import { executeOnRequestStatus } from '../../shared/utils/execute-on-request-status';
import { redirectWithRefresh } from '../../shared/utils';
import { showToasterWithErrorCheck } from '../email-accounts/utils/helpers';
import Suspense from '../../shared/components/suspense';
import Checkbox from '../../shared/design-system/components/atoms/checkbox';
import { UserSettingCode } from '../../shared/types/user-setting';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import { supportUrls } from '../../shared/utils/urls';
import EmailWarmupCollaboration from './components/email-warmup-collaboration';

const EmailWarmup: React.FC<IProps> = ({
  authUrl,
  subscriptionPlan,
  sendTurnOnOffEmailAccountWarmupRequest,
  sendConnectEmailAccountRequest,
  turnOnOffEmailAccountWarmupRequestStatus,
  turnOnOffEmailAccountWarmupRequestError,
  connectEmailAccountRequestStatus,
  connectEmailAccountRequestError,
  resetTurnOnOffEmailAccountWarmupState,
  showLoading,
  hideLoading,
  meta,
  updateEnableEmailWarmupConsent,
  sendGetUserSettingsRequest,
  updateUserSettingsRequestStatus,
}) => {
  const history = useHistory();
  const { t } = useTranslation();

  const [
    reconnectEmailAccountModal,
    setReconnectEmailAccountModal,
  ] = useState<boolean>(false);
  const [enableEmailWarmupModal, setEnableEmailWarmupModal] = useState<boolean>(
    false,
  );
  const [
    disconnectEmailWarmupModal,
    setDisconnectEmailWarmupModal,
  ] = useState<boolean>(false);
  const [emailAccountId, setEmailAccountId] = useState<number>(null);
  const [
    emailAccountMethod,
    setEmailAccountMethod,
  ] = useState<EmailAccountMethod>(null);
  const [
    isEmailWarmupEnableConsentChecked,
    setIsEmailWarmupEnableConsentChecked,
  ] = useState<boolean>(false);
  const showEmailWarmupEnableConsent = getEnableEmailWarmupConsent(meta);
  const [trulyInboxRefModal, setTrulyInboxRefModal] = useState(false);

  // Reconnect Email Account Modal Handlers
  const showReconnectEmailAccountModal = (
    id: number,
    method: EmailAccountMethod,
  ) => {
    setReconnectEmailAccountModal(true);
    setEmailAccountId(id);
    setEmailAccountMethod(method);
  };

  const hideReconnectEmailAccountModal = () => {
    setReconnectEmailAccountModal(false);
  };

  // Disconnect Email Account Modal Handlers
  const showDisconnectEmailWarmupModal = (id: number) => {
    setDisconnectEmailWarmupModal(true);
    setEmailAccountId(id);
  };

  const hideDisconnectEmailWarmupModal = () => {
    setDisconnectEmailWarmupModal(false);
  };

  // TrulyInbox Modal Handlers
  const showTrulyInboxRefModal = () => {
    setTrulyInboxRefModal(true);
  };

  const hideTrulyInboxRefModal = () => {
    setTrulyInboxRefModal(false);
  };

  const onJoinTrulyInboxClick = () => {
    window.open(process.env.REACT_APP_TRULYINBOX_SIGNUP_URL, '_blank');
  };

  // Enable Email Account Modal Handlers
  const showEnableEmailWarmupModal = (id: number) => {
    setEnableEmailWarmupModal(true);
    setEmailAccountId(id);
  };

  const hideEnableEmailWarmupModal = () => {
    setEnableEmailWarmupModal(false);
  };

  // Disconnect Email Account
  const onDisconnectEmailWarmup = () => {
    sendTurnOnOffEmailAccountWarmupRequest({
      id: emailAccountId,
    });
  };

  // Connect SMTP/IMAP Email Account
  const showConnectSmtpImapEmailAccountModal = () => {
    history.push(`/email-accounts?connect-smtp=true`);
  };

  // Connect Email Account
  const onConnectEmailWarmup = (id: number) => {
    connectEmailAccountHandler({
      id,
      sendTurnOnOffEmailAccountWarmupRequest,
    });
    if (showEmailWarmupEnableConsent && isEmailWarmupEnableConsentChecked) {
      updateEnableEmailWarmupConsent({
        settings: [
          {
            code: UserSettingCode.ShowEmailWarmupEnableConsent,
            value: '0',
          },
        ],
      });
    }
  };

  // Reconnect Email Account
  const onReconnectEmailAccount = () => {
    reconnectEmailAccountHandler({
      subscriptionPlan,
      emailAccountId,
      emailAccountMethod,
      showConnectSmtpImapEmailAccountModal,
      sendConnectEmailAccountRequest,
    });
  };

  // Email Warmup Toggle
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onEmailWarmupToggle = (check: boolean, row: EmailAccountWarmupList) => {
    emailWarmupToggleHandler({
      row,
      check,
      onConnectEmailWarmup,
      showReconnectEmailAccountModal,
      showDisconnectEmailWarmupModal,
      showEmailWarmupEnableConsent,
      showEnableEmailWarmupModal,
      showTrulyInboxRefModal,
    });
  };

  useEffect(() => {
    executeOnRequestStatus({
      status: turnOnOffEmailAccountWarmupRequestStatus,
      onPending: () => {
        showLoading();
      },
      onSuccess: () => {
        hideLoading();
        onTunrOnOffRequestSuccessHandler({
          disconnectEmailWarmupModal,
          hideDisconnectEmailWarmupModal,
          t,
        });
        resetTurnOnOffEmailAccountWarmupState();
        hideEnableEmailWarmupModal();
      },
      onFailed: () => {
        hideLoading();
        resetTurnOnOffEmailAccountWarmupState();
        showRequestError(turnOnOffEmailAccountWarmupRequestError);
        hideEnableEmailWarmupModal();
      },
    });
  }, [turnOnOffEmailAccountWarmupRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: connectEmailAccountRequestStatus,
      onSuccess: () => {
        redirectWithRefresh(authUrl);
      },
      onFailed: () => {
        showToasterWithErrorCheck(connectEmailAccountRequestError);
      },
    });
  }, [connectEmailAccountRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateUserSettingsRequestStatus,
      onSuccess: () => {
        sendGetUserSettingsRequest();
      },
    });
  }, [updateUserSettingsRequestStatus]);

  return (
    <>
      <Container fluid className="warmup-container">
        <Row>
          <Col className="p-0">
            <ErrorBoundaryWrapper>
              <Suspense>
                <Switch>
                  <Route
                    exact
                    path="/warmup"
                    render={() => <EmailWarmupCollaboration />}
                  />
                  <Redirect to="/warmup" />
                </Switch>
              </Suspense>
            </ErrorBoundaryWrapper>
          </Col>
        </Row>

        <ConfirmationModalV3
          show={reconnectEmailAccountModal}
          title={t('messages.reconnect_email_account_title')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          content={t('messages.reconnect_email_account_content')}
          onClose={hideReconnectEmailAccountModal}
          onSubmit={onReconnectEmailAccount}
          isSubmitLoading={getIsRequestPending(
            connectEmailAccountRequestStatus,
          )}
          isSubmitDisabled={getIsRequestPending(
            connectEmailAccountRequestStatus,
          )}
          submitButtonText={t('labels.connect_now')}
          cancelButtonText={t('labels.cancel')}
        />

        <ConfirmationModalV3
          show={disconnectEmailWarmupModal}
          title={t('messages.disconnect_email_account_title')}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          content={t('messages.disconnect_email_account_content')}
          onClose={hideDisconnectEmailWarmupModal}
          onSubmit={onDisconnectEmailWarmup}
          isSubmitLoading={getIsRequestPending(
            turnOnOffEmailAccountWarmupRequestStatus,
          )}
          isSubmitDisabled={getIsRequestPending(
            turnOnOffEmailAccountWarmupRequestStatus,
          )}
          submitButtonText={t('labels.yes')}
          cancelButtonText={t('labels.cancel')}
        />

        <ConfirmationModalV3
          show={enableEmailWarmupModal}
          title={t('messages.enable_email_warmup_title')}
          showCloseIcon={false}
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          content={`${t('messages.enable_email_warmup_desc_1')} ${t(
            'messages.enable_email_warmup_desc_2',
          )}`}
          onClose={hideEnableEmailWarmupModal}
          onSubmit={() => onConnectEmailWarmup(emailAccountId)}
          isSubmitLoading={getIsRequestPending(
            turnOnOffEmailAccountWarmupRequestStatus,
          )}
          isSubmitDisabled={getIsRequestPending(
            turnOnOffEmailAccountWarmupRequestStatus,
          )}
          submitButtonText={t('labels.enable')}
          cancelButtonText={t('labels.cancel')}
          customJSX={
            <div>
              <Checkbox
                checked={isEmailWarmupEnableConsentChecked}
                onChange={setIsEmailWarmupEnableConsentChecked}
                label={t('messages.dont_show_this_message_again')}
                className="enable-email-warmup-consent"
              />
            </div>
          }
        />
        <ConfirmationModalV3
          show={trulyInboxRefModal}
          title="Warm up Your Gmail Account for Free with TrulyInbox!"
          iconType={ConfirmationModalIconType.INFO_CIRCLE_YELLOW}
          contents={[
            'Saleshandy does not support Email Warm-up for Gmail accounts, but our partner TrulyInbox does!',
            'Sign up with TrulyInbox to enjoy unlimited email warm-up for Gmail for free.',
          ]}
          onClose={hideTrulyInboxRefModal}
          onSubmit={onJoinTrulyInboxClick}
          submitButtonText="Join TrulyInbox"
          cancelButtonText={t('labels.cancel')}
        />
      </Container>
    </>
  );
};

export default EmailWarmup;
