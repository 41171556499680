import api from '../../../api';
import { ResponseSuccess } from '../../../shared/types';
import {
  GetTaskerParams,
  GetTasksParams,
  UpdateTask,
  UpdateTaskNoteRequest,
} from '../utils/types';

export const getTaskStatusCount = async (params: GetTasksParams) =>
  api.get<ResponseSuccess>('/tasks/status-count', { params });

export const getTasks = async (params: GetTasksParams) =>
  api.get<ResponseSuccess>('/tasks', { params });

export const updateTasks = async (payload: UpdateTask) =>
  api.post<ResponseSuccess>('/tasks/task-update', payload);

export const getTaskerData = async (params: GetTaskerParams) =>
  api.get<ResponseSuccess>('/tasks/task', { params });

export const updateTaskNote = async ({
  taskId,
  taskNote,
}: UpdateTaskNoteRequest) =>
  api.patch<ResponseSuccess>(`/tasks/task-note/${taskId}`, {
    taskNote,
  });
