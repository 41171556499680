import React from 'react';
import { Spinner } from 'reactstrap';
import ActivityComponent from '../../molecules/activity-component';
import InfiniteScrollCustom from '../../atoms/infinite-scroll-custom/infinite-scroll-custom';
import { ActivityPropTypes } from './types';

const Activity: React.FC<ActivityPropTypes> = ({
  onLoadMore,
  hasMore,
  lengthOfPage,
  heightOfScroll,
  activityArr,
  wrapperId,
  scrollableTarget,
  wrapperStyle,
}) => {
  return (
    <InfiniteScrollCustom
      dataLength={lengthOfPage}
      next={onLoadMore}
      hasMore={hasMore}
      height={heightOfScroll}
      loader={
        <div className="text-center mt-5">
          <Spinner />
        </div>
      }
      wrapperId={wrapperId}
      wrapperStyle={wrapperStyle}
      scrollableTarget={scrollableTarget}
    >
      {activityArr.map(({ dayText, activity }, index: React.Key) => {
        return (
          <ActivityComponent
            key={index}
            dayText={dayText}
            activity={activity}
          />
        );
      })}
    </InfiniteScrollCustom>
  );
};

export default Activity;
