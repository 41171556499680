import React from 'react';
import { DoNotContactList } from '../../../../../../../types/do-not-contact-list';
import TruncatedTextWithTooltip from '../../../../../../../../../shared/components/truncated-text-with-tooltip';

type IProps = {
  doNotContactList: DoNotContactList;
};

const ListNameField: React.FC<IProps> = ({ doNotContactList: { name } }) => (
  <TruncatedTextWithTooltip
    length={50}
    string={name}
    textClassName="list-name-field"
    tooltipClassName="tooltip-xxl"
  />
);

export default ListNameField;
