import React from 'react';

export const BrokenLink = () => (
  <svg
    width="204"
    height="150"
    viewBox="0 0 204 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M59.5405 24.3846C59.5405 24.3846 82.4111 41.7403 117.18 9.2883C148.059 -19.5315 173.271 25.9397 173.448 47.7151C173.676 75.9226 142.569 98.493 157.666 117.02C172.762 135.547 127.728 166.142 103.457 139.664C73.2642 106.727 65.0847 133.489 47.8752 133.489C35.5237 133.489 10.165 102.799 27.2895 79.9659C41.6995 60.7526 33.8405 54.3761 30.0347 47.7151C24.5447 38.1084 37.5824 12.0332 59.5405 24.3846Z"
      fill="#EFF6FF"
    />
    <path
      d="M151.359 62.7378C152.588 63.1946 153.932 63.225 155.21 62.9316C160.565 61.7024 178.979 58.4478 175.779 72.7167C175.082 75.5477 164.298 91.402 179.656 97.1895C180.781 97.6189 181.972 97.8534 183.176 97.8827C187.904 97.9801 197.275 96.8637 201.197 92.8715C205.934 88.0489 200.759 90.3359 200.759 90.3359C200.759 90.3359 182.748 98.1956 176.386 89.857C175.678 88.9292 175.227 87.5816 175.098 86.4215C174.874 84.4102 175.767 81.7523 176.94 79.4385C178.528 76.307 186.151 62.2202 169.6 58.1165C165.787 57.2085 160.25 56.3929 153.109 59.3992C148.944 61.1527 149.867 62.1834 151.359 62.7378Z"
      fill="#DBEAFE"
    />
    <path
      d="M155.044 57.4792L150.251 59.2734C148.596 59.8927 147.756 61.7363 148.376 63.3912L148.613 64.0261C149.233 65.6809 151.076 66.5204 152.731 65.901L157.525 64.1069C159.18 63.4875 160.019 61.6439 159.4 59.9891L159.162 59.3542C158.543 57.6993 156.699 56.8599 155.044 57.4792Z"
      fill="#0E84E5"
    />
    <path
      d="M125.618 62.6707L109.808 68.5878C108.981 68.8975 108.561 69.8193 108.871 70.6467L108.933 70.8131C109.243 71.6405 110.165 72.0602 110.992 71.7505L126.802 65.8335C127.629 65.5238 128.049 64.602 127.739 63.7746L127.677 63.6082C127.368 62.7808 126.446 62.361 125.618 62.6707Z"
      fill="#BFDBFE"
    />
    <path
      d="M130.031 74.4603L114.22 80.3774C113.393 80.6871 112.973 81.6089 113.283 82.4363L113.345 82.6027C113.655 83.4301 114.577 83.8498 115.404 83.5402L131.214 77.623C132.042 77.3134 132.461 76.3916 132.152 75.5641L132.089 75.3977C131.78 74.5703 130.858 74.1506 130.031 74.4603Z"
      fill="#BFDBFE"
    />
    <path
      d="M144.627 54.6248L131.814 59.4205C130.159 60.0398 129.319 61.8834 129.939 63.5382L134.313 75.2273C134.933 76.8822 136.776 77.7216 138.431 77.1023L151.245 72.3066C152.9 71.6873 153.739 69.8437 153.12 68.1888L148.745 56.4997C148.126 54.8449 146.282 54.0054 144.627 54.6248Z"
      fill="#60A5FA"
    />
    <path
      d="M133.137 57.6915L120.324 62.4872C118.669 63.1066 117.829 64.9502 118.449 66.605L123.668 80.5506C124.287 82.2054 126.131 83.0449 127.786 82.4255L140.599 77.6299C142.254 77.0105 143.094 75.1669 142.474 73.5121L137.255 59.5665C136.636 57.9116 134.792 57.0722 133.137 57.6915Z"
      fill="#60A5FA"
    />
    <path
      d="M135.143 54.8615L135.043 54.8991C133.94 55.312 133.38 56.5411 133.793 57.6443L141.105 77.1806C141.518 78.2838 142.747 78.8434 143.85 78.4305L143.95 78.3929C145.054 77.98 145.613 76.751 145.2 75.6477L137.889 56.1115C137.476 55.0082 136.247 54.4486 135.143 54.8615Z"
      fill="#3B82F6"
    />
    <path
      d="M4.48564 67.3522C4.48564 67.3522 30.2199 60.1201 26.4837 76.782C25.8349 79.4184 16.495 92.9931 27.6202 99.5107C30.0091 100.91 32.7659 101.53 35.5343 101.516C40.4978 101.49 48.3666 100.534 51.9016 96.9364C56.6392 92.1137 51.4635 94.4007 51.4635 94.4007C51.4635 94.4007 36.6839 100.48 29.0803 95.6341C26.484 93.9794 25.3072 90.7755 26.0123 87.7785C26.3726 86.2898 26.9214 84.8532 27.6452 83.5033C29.2988 80.4061 36.8563 66.285 20.305 62.1813C16.4923 61.2733 10.9545 60.4577 3.81409 63.464C-3.32632 66.4703 4.48564 67.3522 4.48564 67.3522Z"
      fill="#DBEAFE"
    />
    <path
      d="M55.3616 90.6723L50.5677 92.4665C48.9129 93.0858 48.0735 94.9294 48.6928 96.5843L48.9304 97.2192C49.5498 98.8741 51.3934 99.7135 53.0482 99.0942L57.8421 97.3C59.497 96.6806 60.3364 94.837 59.7171 93.1822L59.4794 92.5472C58.8601 90.8924 57.0165 90.053 55.3616 90.6723Z"
      fill="#60A5FA"
    />
    <path
      d="M63.4614 82.7849L56.3566 85.444C54.7017 86.0633 53.8623 87.9069 54.4817 89.5618L57.9075 98.7155C58.5269 100.37 60.3705 101.21 62.0253 100.59L69.1301 97.9314C70.785 97.312 71.6244 95.4684 71.0051 93.8136L67.5792 84.6599C66.9598 83.005 65.1162 82.1656 63.4614 82.7849Z"
      fill="#3B82F6"
    />
    <path
      d="M75.2892 75.7373L64.6939 79.7027C63.0391 80.322 62.1996 82.1656 62.819 83.8205L68.0383 97.7661C68.6576 99.4209 70.5012 100.26 72.1561 99.641L82.7513 95.6756C84.4062 95.0563 85.2456 93.2127 84.6263 91.5578L79.407 77.6122C78.7876 75.9574 76.944 75.1179 75.2892 75.7373Z"
      fill="#60A5FA"
    />
    <path
      d="M77.5333 72.8494L77.4328 72.887C76.3296 73.2999 75.7699 74.529 76.1828 75.6322L83.4945 95.1685C83.9074 96.2717 85.1365 96.8313 86.2397 96.4184L86.3402 96.3808C87.4434 95.9679 88.0031 94.7388 87.5902 93.6356L80.2785 74.0994C79.8656 72.9961 78.6365 72.4365 77.5333 72.8494Z"
      fill="#208AF2"
    />
    <path
      d="M104.589 60.4033C104.207 60.3609 103.857 60.1722 103.612 59.8768C103.366 59.5815 103.245 59.2025 103.274 58.8196L104.064 44.3799C104.112 43.5174 104.84 42.8621 105.667 42.9383L106.818 43.0449C107.645 43.1212 108.237 43.8981 108.121 44.7546L106.178 59.0876C106.134 59.4694 105.944 59.8193 105.647 60.0644C105.351 60.3094 104.972 60.4308 104.589 60.4033Z"
      fill="#60A5FA"
    />
    <path
      d="M97.763 61.6484C97.6443 61.6833 97.5199 61.6944 97.397 61.6812C97.274 61.668 97.1548 61.6307 97.0462 61.5715C96.9377 61.5122 96.8418 61.4321 96.7642 61.3358C96.6866 61.2395 96.6287 61.1288 96.5939 61.0101L93.7707 52.0279C93.6886 51.7724 93.7112 51.4948 93.8335 51.256C93.9559 51.0172 94.1679 50.8367 94.4232 50.754L95.1541 50.5335C95.2796 50.4969 95.4112 50.4867 95.5407 50.5035C95.6703 50.5203 95.795 50.5638 95.9068 50.6313C96.0187 50.6987 96.1154 50.7886 96.1908 50.8953C96.2662 51.002 96.3186 51.1232 96.3448 51.2512L98.4372 60.4541C98.4955 60.7015 98.4585 60.9617 98.3336 61.1831C98.2086 61.4044 98.0049 61.5705 97.763 61.6484Z"
      fill="#60A5FA"
    />
    <path
      d="M93.9534 66.8282C93.4476 67.455 92.4303 67.4475 91.6472 66.8113L77.7439 55.5152C76.9135 54.8405 76.6684 53.7236 77.205 53.0592L77.9529 52.1327C78.4896 51.468 79.5917 51.5225 80.3776 52.2525L93.533 64.4752C94.2739 65.1635 94.4592 66.2015 93.9534 66.8282Z"
      fill="#60A5FA"
    />
    <path
      d="M100.379 96.0043C100.737 96.1427 101.027 96.4146 101.189 96.7628C101.351 97.111 101.371 97.5084 101.246 97.8712L96.7935 111.63C96.5275 112.452 95.656 112.899 94.8762 112.614L93.7894 112.217C93.0097 111.932 92.6355 111.03 92.9664 110.232L98.5052 96.8702C98.6458 96.5123 98.9191 96.2224 99.2682 96.0611C99.6173 95.8998 100.015 95.8794 100.379 96.0043Z"
      fill="#60A5FA"
    />
    <path
      d="M107.297 96.5439C107.42 96.5405 107.543 96.5615 107.659 96.6057C107.774 96.6499 107.88 96.7164 107.97 96.8014C108.06 96.8864 108.132 96.9883 108.183 97.1012C108.233 97.2142 108.261 97.3359 108.264 97.4596L108.7 106.865C108.714 107.133 108.621 107.396 108.442 107.595C108.263 107.795 108.011 107.915 107.743 107.93L106.98 107.957C106.85 107.96 106.72 107.936 106.599 107.887C106.478 107.837 106.369 107.764 106.278 107.67C106.187 107.576 106.116 107.464 106.07 107.342C106.025 107.22 106.005 107.089 106.012 106.959L106.34 97.5266C106.347 97.2726 106.449 97.0304 106.626 96.8483C106.804 96.6662 107.043 96.5576 107.297 96.5439Z"
      fill="#60A5FA"
    />
    <path
      d="M112.303 92.5088C112.952 92.032 113.934 92.299 114.528 93.1141L125.086 107.586C125.716 108.45 125.668 109.592 124.979 110.098L124.019 110.803C123.331 111.309 122.279 110.974 121.706 110.068L112.108 94.8906C111.568 94.0364 111.654 92.9855 112.303 92.5088Z"
      fill="#60A5FA"
    />
  </svg>
);
