import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import {
  resendOtpForChangeEmailRequest,
  sendOtpForChangeEmailRequest,
  verifyCurrentPasswordRequest,
  verifyOtpForChangeEmailRequest,
} from '../../extra-actions';
import {
  resetVerifyCurrentPasswordRequest,
  resetSendOtpForChangeEmailRequest,
  resetResendOtpForChangeEmailRequest,
  resetVerifyOtpForChangeEmailRequest,
  resetIsCurrentPasswordValid,
} from '../../my-profile-slice';
import { resetPasswordRequest } from '../../../../../auth/components/reset-password/extra-actions';
import { resetPasswordRequestState } from '../../../../../auth/components/reset-password/reset-password-slice';

import ChangeEmailModal from './change-email-modal';

const mapStateToProps = (state: RootState) => ({
  email: state.myProfile.myProfile.email,
  isCurrentPasswordValid: state.myProfile.isCurrentPasswordValid,
  canResendChangeEmailVerificationCode:
    state.myProfile.canResendChangeEmailVerificationCode,

  verifyCurrentPasswordRequestStatus:
    state.myProfile.verifyCurrentPasswordRequest.status,
  verifyCurrentPasswordRequestMessage:
    state.myProfile.verifyCurrentPasswordRequest.message,
  verifyCurrentPasswordRequestError:
    state.myProfile.verifyCurrentPasswordRequest.error,

  sendOtpForChangeEmailRequestStatus:
    state.myProfile.sendOtpForChangeEmailRequest.status,
  sendOtpForChangeEmailRequestMessage:
    state.myProfile.sendOtpForChangeEmailRequest.message,
  sendOtpForChangeEmailRequestError:
    state.myProfile.sendOtpForChangeEmailRequest.error,

  resendOtpForChangeEmailRequestStatus:
    state.myProfile.resendOtpForChangeEmailRequest.status,
  resendOtpForChangeEmailRequestMessage:
    state.myProfile.resendOtpForChangeEmailRequest.message,
  resendOtpForChangeEmailRequestError:
    state.myProfile.resendOtpForChangeEmailRequest.error,

  verifyOtpForChangeEmailRequestStatus:
    state.myProfile.verifyOtpForChangeEmailRequest.status,
  verifyOtpForChangeEmailRequestMessage:
    state.myProfile.verifyOtpForChangeEmailRequest.message,
  verifyOtpForChangeEmailRequestError:
    state.myProfile.verifyOtpForChangeEmailRequest.error,

  resetPasswordRequestStatus: state.resetPassword.status,
  resetPasswordRequestMessage: state.resetPassword.message,
  resetPasswordRequestError: state.resetPassword.error,
});

const mapDispatchToProps = {
  verifyCurrentPasswordRequest: (password: string) =>
    verifyCurrentPasswordRequest({ password }),
  resetVerifyCurrentPasswordRequest: () => resetVerifyCurrentPasswordRequest(),
  resetIsCurrentPasswordValid: () => resetIsCurrentPasswordValid(),

  sendOtpForChangeEmailRequest: (newEmail: string) =>
    sendOtpForChangeEmailRequest(newEmail),
  resetSendOtpForChangeEmailRequest: () => resetSendOtpForChangeEmailRequest(),

  resendOtpForChangeEmailRequest: () => resendOtpForChangeEmailRequest(),
  resetResendOtpForChangeEmailRequest: () =>
    resetResendOtpForChangeEmailRequest(),

  verifyOtpForChangeEmailRequest: (code: number) =>
    verifyOtpForChangeEmailRequest(code),
  resetVerifyOtpForChangeEmailRequest: () =>
    resetVerifyOtpForChangeEmailRequest(),

  sendResetPasswordRequest: (email: string) => resetPasswordRequest({ email }),
  resetPasswordRequestState: () => resetPasswordRequestState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: () => void;
};

export default connector(ChangeEmailModal);
