import React from 'react';
import { FormMode } from '../../organisms/form/types';

export default function ProspectLabelField({ index, formMode }) {
  return (
    <>
      {index === 0 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Contact Information
        </div>
      )}
      {index === 4 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Work Information
        </div>
      )}
      {index === 8 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Social Profiles
        </div>
      )}
      {index === 12 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Location Information
        </div>
      )}
      {index === 15 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Company Information
        </div>
      )}
      {formMode === FormMode.Add && index === 23 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Custom Fields
        </div>
      )}
      {formMode === FormMode.Edit && index === 24 && (
        <div className="prospect-label-field bs-input-container input-default-container">
          Custom Fields
        </div>
      )}
    </>
  );
}
