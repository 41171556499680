import { RequestStatus } from '../../../shared/enums/request-status';
import { showGeneralErrorNotification } from '../../../shared/utils/errors';
import hasResource from '../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../shared/utils/access-control/enums/resource-identifiers';
import { starterPlanRestriction } from '../../../shared/utils/plan-restriction';

export const showGeneralErrorMessage = (requestError) => {
  if (requestError) {
    showGeneralErrorNotification(requestError.message);
  }
};

export const getIsInitialRequestPending = ({
  initialFilters,
  filters,
  templates,
  isFilterDirty,
  getTemplatesRequestStatus,
}): boolean =>
  JSON.stringify(initialFilters) === JSON.stringify(filters) &&
  templates === null &&
  !isFilterDirty.current &&
  (getTemplatesRequestStatus === RequestStatus.Pending ||
    getTemplatesRequestStatus === RequestStatus.Ideal);

export const getIsRequestPending = (status) => status === RequestStatus.Pending;

export const getIsRequestPendingForEmailComposeModal = ({
  createTemplateRequestStatus,
  updateTemplateRequestStatus,
}) =>
  getIsRequestPending(createTemplateRequestStatus) ||
  getIsRequestPending(updateTemplateRequestStatus);

export const getIsDisabledForEmailComposeModal = ({
  filters,
  selectedTemplateId,
}) => filters.shared && selectedTemplateId;

export const getShouldRemountTable = ({ payload, filters }) =>
  payload.shared !== undefined && payload.shared !== filters.shared;

export const onCreateTemplate = ({ onShowUpgradePlanModal, onAddTemplate }) => {
  if (starterPlanRestriction()) {
    onShowUpgradePlanModal();
    return;
  }

  if (hasResource(ResourceIdentifiers.TEMPLATES_CREATE)) {
    onAddTemplate();
  }
};


