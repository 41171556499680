import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import CreateUpdateCustomFieldModal from '../..';
import { CreateProspectCustomFieldModalProps } from './types';

const CreateProspectCustomFieldModal: React.FC<CreateProspectCustomFieldModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <CreateUpdateCustomFieldModal
      {...props}
      modalHeaderTitle={t(`Create Custom Field`)}
      disableDropdownBox={false}
      showWarning={false}
    />
  );
};

export default withTranslation()(CreateProspectCustomFieldModal);
