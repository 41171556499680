import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

const WebhookLogsListLoader: React.FC = () => (
  <div className="webhook-logs-list-rows">
    <div className="webhook-logs-list-row">
      <SkeletonLoading width={155} height={20} />
      <SkeletonLoading width={149} height={20} />
    </div>
    <div className="webhook-logs-list-row">
      <SkeletonLoading width={118} height={20} />
      <SkeletonLoading width={149} height={20} />
    </div>
    <div className="webhook-logs-list-row">
      <SkeletonLoading width={92} height={20} />
      <SkeletonLoading width={149} height={20} />
    </div>
    <div className="webhook-logs-list-row">
      <SkeletonLoading width={185} height={20} />
      <SkeletonLoading width={149} height={20} />
    </div>
  </div>
);
export default WebhookLogsListLoader;
