import React, { ReactNode } from 'react';
import EmptyDataIcon1 from '../../../components/images/empty-data-icon-1';

type TransferEmptyProps = {
  text?: string;
  customEmptyElement?: ReactNode;
};

const TransferEmpty: React.FC<TransferEmptyProps> = ({
  text,
  customEmptyElement = null,
}) => (
  <div className="transfer-empty">
    {customEmptyElement || (
      <>
        <EmptyDataIcon1 />
        <span className="regular-2 gray-txt-6">{text}</span>
      </>
    )}
  </div>
);

TransferEmpty.defaultProps = {
  text: 'No Data',
};

export default TransferEmpty;
