export enum OnboardingType {
  Agency = 'Agency',
  Business = 'Business',
  Individual = 'Individual',
}

export enum SaleshandyUsageOptions {
  SalesTeam = 'Sales Team',
  MarketingTeam = 'Marketing Team',
  Agency_Consultant = 'Agency/Consultant',
  Recruiters = 'Recruiters',
  Other = 'Other',
}

export enum JobRoleOptions {
  BusinessDevelopmentRepresentative = 'Business Development Representative',
  BusinessDevelopmentManager = 'Business Development Manager',
  SalesDevelopmentRepresentative = 'Sales Development Representative',
  SalesDevelopmentManager = 'Sales Development Manager',
  AccountExecutive = 'Account Executive',
  SalesAccountManager = 'Sales account manager',
  MarketingExecutive = 'Marketing Executive',
  GrowthManager = 'Growth Manager',
  MarketingManager = 'Marketing Manager',
  Recruiter = 'Recruiter',
  Consultant = 'Consultant',
  Founder = 'Founder',
  Other = 'Other',
}

export enum UseCaseOptions {
  LeadGeneration = 'Lead Generation',
  LeadQualification = 'Lead Qualification',
  BookMoreMeetings_BuildSalesPipeline = 'Book more meetings / Build sales pipeline',
  OutreachToCandidates_Recruitment = 'Outreach to candidates / Recruitment',
  FindEmailAddressesFromSaleshandyConnect = 'Find email addresses from Saleshandy connect',
  PromoteProduct_Services = 'Promote your product & services',
  OneTimeColdEmailOutreach = 'One time cold email outreach to my list',
  LinkBuilding_PR = 'Link Building / PR',
  FindleadsUsingLeadFinder = 'Find leads using Lead Finder',
  Other = 'Other',
}

export enum ProspectSizeOptions {
  PROSPECT_0_2 = '0 - 2K',
  PROSPECT_2_40 = '2K - 40K',
  PROSPECT_40_100 = '40K - 100K',
  PROSPECT_100_500 = '100K - 500K',
  PROSPECT_500 = '500K+',
  Other = 'Other',
}

export enum IndustryOptions {
  Saas = 'SaaS',
  ItServices = 'Information Technology & Services',
  ComputerSoftware = 'Computer Software',
  SalesMarketingAgency = 'Sales / Marketing agency',
  Internet = 'Internet',
  StaffingRecruitment = 'Staffing & Recruitment',
  MarketingAndAdvertising = 'Marketing & Advertising',
  ELearningEdTech = 'E-learning / EdTech',
  ManagementConsulting = 'Management Consulting',
  RealEstate = 'Real Estate',
  Finance = 'Finance',
  Other = 'Other',
}

export enum ColdEmailToolOptions {
  Used = 'Yes, I have',
  NotUsed = 'No, I have not',
  NotReally = 'Not exactly, but I’ve used an email marketing tool',
}

export enum OutreachProspectOptions {
  EmailOnly = 'Email Only',
  Multichannel = 'Multi-Channel (Email + LinkedIn + Call + Others)',
}

export enum AgencyTypeOptions {
  LeadGenerationAgency = 'Lead Generation Agency',
  SalesAgency = 'Sales Agency',
  DigitalMarketingAgency = 'Digital Marketing Agency',
  SocialMediaAgency = 'Social Media Agency',
  RecruitmentAgency = 'Recruitment Agency',
  Other = 'Other',
}

export enum ClientSizeOptions {
  CLIENT_0_5 = '0 - 5',
  CLIENT_6_20 = '6 - 20',
  CLIENT_21_50 = '21 - 50',
  More_Than_50 = 'More than 50',
}

export enum EmailSendOptions {
  EMAIL_0_30 = '0 - 30K',
  EMAIL_30_100 = '30K - 100K',
  EMAIL_100_250 = '100K - 250K',
  More_Than_250 = 'More than 250K',
}

export enum FindUsOptions {
  LinkedIn = 'LinkedIn',
  Blog = 'Blog',
  Google = 'Google',
  Ads = 'Ads',
  YouTube = 'YouTube',
  Recommendation = 'Recommendation',
  Other = 'Others',
}

export enum BusinessUseCaseOptions {
  GenerateB2BLeadsBookMeetings = 'Generate B2B Leads / Book Meetings',
  PromoteProductsServices = 'Promote Products / Services',
  OneTimeEmailOutreach = 'One-time Email Outreach',
  OutreachCandidates = 'Outreach Candidates',
  LinkBuilding = 'Link Building',
  Other = 'Other',
}

export enum TeamSizeOptions {
  TEAM_0_5 = '0 - 5',
  TEAM_6_20 = '6 - 20',
  TEAM_21_50 = '21 - 50',
  More_Than_50 = 'More than 50',
}

export enum AverageDealValueOptions {
  DEAL_0_1 = '0 - $1K',
  DEAL_1_5 = '$1K - $5K',
  DEAL_5_20 = '$5K - $20K',
  More_Than_20 = 'More than $20K',
}

export enum UsagePreferenceOptions {
  ColdEmailOutreach = 'Cold Outreach',
  FindLead = 'Lead Finder',
  FindLeadColdOutreach = 'Find Leads & Cold Outreach',
}

export enum OccupationOptions {
  Freelancer = 'Freelancer',
  Influencer = 'Influencer',
  ConsultantAdvisor = 'Consultant / Advisor',
  Other = 'Other',
}

export enum IndividualUseCaseOptions {
  GenerateLeadsForBusiness = 'Generate Leads for my Business',
  EngageProspects = 'Engage with Prospects',
  OnetimeEmailOutreach = 'One-time Email Outreach',
  RecruitTalent = 'Recruit Talent',
  Other = 'Other',
}

export enum LeadSizeOptions {
  LEAD_0_1 = '0 - 1K',
  LEAD_1_5 = '1K - 5K',
  LEAD_5_20 = '5K - 20K',
  More_Than_20 = 'More than 20K',
}
