import React from 'react';

export const Download = ({ width = 24, height = 24, className = '' }) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.33333 13.3271C3.79357 13.3271 4.16667 13.7002 4.16667 14.1605V15.8271C4.16667 16.0482 4.25446 16.2601 4.41074 16.4164C4.56702 16.5727 4.77899 16.6605 5 16.6605H15C15.221 16.6605 15.433 16.5727 15.5893 16.4164C15.7455 16.2601 15.8333 16.0482 15.8333 15.8271V14.1605C15.8333 13.7002 16.2064 13.3271 16.6667 13.3271C17.1269 13.3271 17.5 13.7002 17.5 14.1605V15.8271C17.5 16.4902 17.2366 17.1261 16.7678 17.5949C16.2989 18.0638 15.663 18.3271 15 18.3271H5C4.33696 18.3271 3.70107 18.0638 3.23223 17.5949C2.76339 17.1261 2.5 16.4902 2.5 15.8271V14.1605C2.5 13.7002 2.8731 13.3271 3.33333 13.3271Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.24408 8.57123C5.56951 8.24579 6.09715 8.24579 6.42259 8.57123L10 12.1486L13.5774 8.57123C13.9028 8.24579 14.4305 8.24579 14.7559 8.57123C15.0814 8.89666 15.0814 9.4243 14.7559 9.74974L10.5893 13.9164C10.433 14.0727 10.221 14.1605 10 14.1605C9.77899 14.1605 9.56702 14.0727 9.41074 13.9164L5.24408 9.74974C4.91864 9.4243 4.91864 8.89666 5.24408 8.57123Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0003 2.50635C10.4606 2.50635 10.8337 2.87944 10.8337 3.33968V13.3397C10.8337 13.7999 10.4606 14.173 10.0003 14.173C9.54009 14.173 9.16699 13.7999 9.16699 13.3397V3.33968C9.16699 2.87944 9.54009 2.50635 10.0003 2.50635Z"
      fill="currentColor"
    />
  </svg>
);
