import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import AdminSettings from './admin-settings';
import {
  getAdminSettingsRequest,
  updateAdminSettingsRequest,
} from './extra-actions';
import { UpdateAdminSettingsRequestPayload } from '../../types/request-payload';
import {
  resetAdminSettings,
  resetUpdateAdminSettings,
} from './admin-settings-slice';
import {
  changeIntegrationSettingRequest,
  getIntegrationAdminSettingRequest,
} from '../api-tokens/extra-actions';

const mapStateToProps = (state: RootState) => ({
  getAdminSettingsRequestStatus:
    state.adminSettings.getAdminSettingsRequest.status,
  getAdminSettingsRequestMessage:
    state.adminSettings.getAdminSettingsRequest.message,
  getAdminSettingsRequestError:
    state.adminSettings.getAdminSettingsRequest.error,

  updateAdminSettingsStatus:
    state.adminSettings.updateAdminSettingsRequest.status,
  updateAdminSettingsMessage:
    state.adminSettings.updateAdminSettingsRequest.message,
  updateAdminSettingsError:
    state.adminSettings.updateAdminSettingsRequest.error,

  integrationAdminSetting: state.apiToken.integrationSettings?.adminSetting,
  getIntegrationAdminSettingRequestStatus:
    state.apiToken.getIntegrationAdminSettingRequest.status,
  changeIntegrationSettingRequestStatus:
    state.apiToken.changeIntegrationSettingRequest.status,

  adminSettings: state.adminSettings.adminSettings,
  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {
  sendGetAdminDetailsRequest: () => getAdminSettingsRequest(),
  sendUpdateAdminDetailsRequest: (payload: UpdateAdminSettingsRequestPayload) =>
    updateAdminSettingsRequest(payload),
  resetUpdateAdminSettings: () => resetUpdateAdminSettings(),
  resetAdminSettings: () => resetAdminSettings(),
  getIntegrationSetting: () => getIntegrationAdminSettingRequest(),
  sendChangeIntegrationSetting: (isAllUser: boolean) =>
    changeIntegrationSettingRequest({ isAllUser }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(AdminSettings);
