import { OnboardingQuestionType } from './type';
import {
  ColdEmailToolOptions,
  AgencyTypeOptions,
  ClientSizeOptions,
  EmailSendOptions,
  BusinessUseCaseOptions,
  UsagePreferenceOptions,
  OccupationOptions,
  IndividualUseCaseOptions,
  LeadSizeOptions,
  OnboardingType,
  FindUsOptions,
} from '../../enums/onboarding-question-options';

// Pre-shuffled arrays for options
const shuffledOccupationOptions = [
  { value: OccupationOptions.Freelancer },
  { value: OccupationOptions.Influencer },
  { value: OccupationOptions.ConsultantAdvisor },
];

const shuffledBusinessUseCaseOptions = [
  { value: BusinessUseCaseOptions.GenerateB2BLeadsBookMeetings },
  { value: BusinessUseCaseOptions.PromoteProductsServices },
  { value: BusinessUseCaseOptions.OneTimeEmailOutreach },
  { value: BusinessUseCaseOptions.OutreachCandidates },
  { value: BusinessUseCaseOptions.LinkBuilding },
];

const shuffledIndividualUseCaseOptions = [
  { value: IndividualUseCaseOptions.GenerateLeadsForBusiness },
  { value: IndividualUseCaseOptions.EngageProspects },
  { value: IndividualUseCaseOptions.OnetimeEmailOutreach },
  { value: IndividualUseCaseOptions.RecruitTalent },
];

const shuffledAgencyTypeOptions = [
  { value: AgencyTypeOptions.LeadGenerationAgency },
  { value: AgencyTypeOptions.SalesAgency },
  { value: AgencyTypeOptions.DigitalMarketingAgency },
  { value: AgencyTypeOptions.SocialMediaAgency },
  { value: AgencyTypeOptions.RecruitmentAgency },
];

const onboardingTypeOptions = [
  {
    label: 'Clients',
    value: OnboardingType.Agency,
    description: 'I want to outreach for my clients',
  },
  {
    label: OnboardingType.Business,
    value: OnboardingType.Business,
    description: 'I want to grow my business using cold emails',
  },
  {
    label: 'Personal Use',
    value: OnboardingType.Individual,
    description: 'I want to do cold emailing for personal use',
  },
];
//! FUnction to get the agency onboarding questions
export const getAgencyOnboardingQuestions = (): OnboardingQuestionType[] => {
  const onboardingQuestions = [
    {
      question: '',
      key: 'userType',
      options: [...onboardingTypeOptions],
    },
    {
      question: 'What type of agency are you?',
      key: 'agencyType',
      options: [
        ...shuffledAgencyTypeOptions,
        { value: AgencyTypeOptions.Other },
      ],
    },
    {
      question: 'How many clients do you serve?',
      key: 'clientsCount',
      options: [
        { value: ClientSizeOptions.CLIENT_0_5 },
        { value: ClientSizeOptions.CLIENT_6_20 },
        { value: ClientSizeOptions.CLIENT_21_50 },
        { value: ClientSizeOptions.More_Than_50 },
      ],
    },
    {
      question: 'How many emails are you likely to send every month?',
      key: 'monthlyemailsCount',
      options: [
        { value: EmailSendOptions.EMAIL_0_30 },
        { value: EmailSendOptions.EMAIL_30_100 },
        { value: EmailSendOptions.EMAIL_100_250 },
        { value: EmailSendOptions.More_Than_250 },
      ],
    },
    {
      question: 'How did you find us?',
      key: 'source',
      options: [
        { value: FindUsOptions.LinkedIn },
        { value: FindUsOptions.Blog },
        { value: FindUsOptions.Google },
        { value: FindUsOptions.Ads },
        { value: FindUsOptions.YouTube },
        { value: FindUsOptions.Recommendation },
        { value: FindUsOptions.Other, type: 'text' },
      ],
    },
  ];

  return onboardingQuestions.map((item, index) => ({
    ...item,
    questionNumber: index + 1,
  })) as OnboardingQuestionType[];
};

//! Function to get the business onboarding questions
export const getBusinessOnboardingQuestions = (): OnboardingQuestionType[] => {
  const onboardingQuestions = [
    {
      question: '',
      key: 'userType',
      options: [...onboardingTypeOptions],
    },
    {
      question: 'What is your primary goal for using Saleshandy?',
      key: 'useCase',
      options: [
        { value: BusinessUseCaseOptions.GenerateB2BLeadsBookMeetings },
        { value: BusinessUseCaseOptions.PromoteProductsServices },
        { value: BusinessUseCaseOptions.OneTimeEmailOutreach },
        { value: BusinessUseCaseOptions.OutreachCandidates },
        { value: BusinessUseCaseOptions.LinkBuilding },
        { value: BusinessUseCaseOptions.Other },
      ],
    },
    {
      question: 'Have you used a cold outreach tool like Saleshandy before?',
      key: 'priorColdEmailToolExperience',
      options: [
        { value: ColdEmailToolOptions.Used },
        { value: ColdEmailToolOptions.NotUsed },
        { value: ColdEmailToolOptions.NotReally },
      ],
    },
    {
      question: 'How would you use Saleshandy?',
      key: 'usagePreference',
      options: [
        { value: UsagePreferenceOptions.ColdEmailOutreach },
        { value: UsagePreferenceOptions.FindLead },
        { value: UsagePreferenceOptions.FindLeadColdOutreach },
      ],
    },
    {
      question: 'How did you find us?',
      key: 'source',
      options: [
        { value: FindUsOptions.LinkedIn },
        { value: FindUsOptions.Blog },
        { value: FindUsOptions.Google },
        { value: FindUsOptions.Ads },
        { value: FindUsOptions.YouTube },
        { value: FindUsOptions.Recommendation },
        { value: FindUsOptions.Other, type: 'text' },
      ],
    },
  ];

  return onboardingQuestions.map((item, index) => ({
    ...item,
    questionNumber: index + 1,
  })) as OnboardingQuestionType[];
};

//! Function to get the individual onboarding questions
export const getIndividualOnboardingQuestions = (): OnboardingQuestionType[] => {
  const onboardingQuestions = [
    {
      question: '',
      key: 'userType',
      options: [...onboardingTypeOptions],
      questionNumber: 1,
    },
    {
      question: 'Please select your occupation',
      key: 'occupation',
      options: [
        ...shuffledOccupationOptions,
        { value: OccupationOptions.Other },
      ],
      questionNumber: 2,
    },
    {
      question: 'What is your primary goal for using Saleshandy?',
      key: 'useCase',
      options: [
        ...shuffledIndividualUseCaseOptions,
        { value: IndividualUseCaseOptions.Other },
      ],
      questionNumber: 3,
    },
    {
      question: 'How would you use Saleshandy?',
      key: 'usagePreference',
      options: [
        { value: UsagePreferenceOptions.ColdEmailOutreach },
        { value: UsagePreferenceOptions.FindLead },
        { value: UsagePreferenceOptions.FindLeadColdOutreach },
      ],
      questionNumber: 4,
    },
    {
      question: 'How many emails are you likely to send every month?',
      key: 'monthlyemailsCount',
      options: [
        { value: EmailSendOptions.EMAIL_0_30 },
        { value: EmailSendOptions.EMAIL_30_100 },
        { value: EmailSendOptions.EMAIL_100_250 },
        { value: EmailSendOptions.More_Than_250 },
      ],
      questionNumber: 5,
    },
    {
      question: 'How many leads do you need?',
      key: 'leadsSizeCount',
      options: [
        { value: LeadSizeOptions.LEAD_0_1 },
        { value: LeadSizeOptions.LEAD_1_5 },
        { value: LeadSizeOptions.LEAD_5_20 },
        { value: LeadSizeOptions.More_Than_20 },
      ],
      questionNumber: 5,
    },
    {
      question: 'How did you find us?',
      key: 'source',
      options: [
        { value: FindUsOptions.LinkedIn },
        { value: FindUsOptions.Blog },
        { value: FindUsOptions.Google },
        { value: FindUsOptions.Ads },
        { value: FindUsOptions.YouTube },
        { value: FindUsOptions.Recommendation },
        { value: FindUsOptions.Other, type: 'text' },
      ],
      questionNumber: 6,
    },
  ];

  return onboardingQuestions as OnboardingQuestionType[];
};

export const getOnboardingQuestions = (
  type: OnboardingType,
): OnboardingQuestionType[] => {
  if (type === OnboardingType.Agency) {
    return getAgencyOnboardingQuestions();
  }
  if (type === OnboardingType.Business) {
    return getBusinessOnboardingQuestions();
  }

  return getIndividualOnboardingQuestions();
};

export const getInitialOnboardingValues = (type: OnboardingType) => {
  const ques = getOnboardingQuestions(type);

  const obj = ques.reduce(
    (acc, item) => {
      acc[item.key] = '';
      return acc;
    },
    {
      userType: '',
      agencyType: '',
      clientsCount: '',
      monthlyemailsCount: '',
      useCase: '',
      priorColdEmailToolExperience: '',
      teamSize: '',
      avgDealSize: '',
      usagePreference: '',
      occupation: '',
      leadsSizeCount: '',
    },
  );

  return obj;
};

export const getTotalQuestionCount = (type: OnboardingType) => {
  if (type === OnboardingType.Agency) {
    return getAgencyOnboardingQuestions().length - 1;
  }
  if (type === OnboardingType.Business) {
    return getBusinessOnboardingQuestions().length - 1;
  }
  return getOnboardingQuestions(type).length - 2;
};

export const isOutreachLead = (selectedValue) =>
  [
    BusinessUseCaseOptions.OneTimeEmailOutreach,
    BusinessUseCaseOptions.OutreachCandidates,
    BusinessUseCaseOptions.LinkBuilding,
  ].includes(selectedValue);
