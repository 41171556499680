import React from 'react';
import validator from 'validator';
import { isEmpty } from 'lodash';
import { Formik, Form, FormikProps } from 'formik';
import { withTranslation } from 'react-i18next';
import Input from '../../input';
import Button from '../../atoms/button';
import { FormField, InputValidationType, IProps } from './types';
import InputNumber from '../../input-number';
import ProspectContactFieldComponent from '../../atoms/prospect-contact-field/prospect-contact-field';
import { ProspectContactInfoCategory } from '../../atoms/prospect-contact-field/enum';
import ProspectLabelField from '../../atoms/prospect-contact-field/prospect-label-field';

class CustomForm extends React.Component<IProps> {
  private formRef = React.createRef<FormikProps<{ [x: string]: any }>>();

  constructor(props: IProps) {
    super(props);
    this.generateInputFields = this.generateInputFields.bind(this);
    this.getNameKey = this.getNameKey.bind(this);
  }

  generateValidationObject = () => {
    const { fields } = this.props;
    return fields
      .filter((field) => field.validator)
      .reduce(
        (obj, item) => Object.assign(obj, { [item.name]: item.validator }),
        {},
      );
  };

  isValidPhoneNumber = (phone: string) => {
    const phoneRegex = /^(?:\+?[0-9](?:[0-9().\-\s]{6,30})?|[0-9(][0-9().\-\s]{5,13}[0-9])$/;
    const digitLengthRegex = /^(?:\D*\d\D*){7,15}$/;

    if (phoneRegex.test(phone) && digitLengthRegex.test(phone)) {
      return true;
    }
    return false;
  };

  validateMaxCharLimit = (value) => value.toString().length <= 1000;

  getNameKey = (str) => str.toString().toLowerCase().split(' ').join('-');

  truncateText = (text: string, maxLength: number): string => {
    if (text.length > maxLength) {
      // Truncate the text and add "..." at the end
      return `${text.substring(0, maxLength)}...`;
    }

    // Text length is within the limit, no need to truncate
    return text;
  };

  generateInputFields(
    values,
    handleChange,
    errors,
    touched,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    primaryEmailChangeInProspectRequest,
    deleteEmailFromProspectRequest,
    deletePhoneFromProspectRequest,
    selectedProspectId,
  ) {
    const { fields, formMode } = this.props;
    return fields.map((field: FormField, index: number) => {
      if (typeof field.component === 'string' && field.name === 'email') {
        return (
          <ProspectContactFieldComponent
            setFieldValue={setFieldValue}
            values={values.email}
            handleBlur={handleBlur}
            errors={errors}
            fieldName="email"
            touched={touched}
            setFieldTouched={setFieldTouched}
            formMode={formMode}
            primaryEmailChangeInProspectRequest={
              primaryEmailChangeInProspectRequest
            }
            deleteEmailFromProspectRequest={deleteEmailFromProspectRequest}
            deletePhoneFromProspectRequest={deletePhoneFromProspectRequest}
            selectedProspectId={selectedProspectId}
          />
        );
      }
      if (typeof field.component === 'string' && field.name === 'phonenumber') {
        return (
          <ProspectContactFieldComponent
            setFieldValue={setFieldValue}
            values={values.phonenumber}
            fieldName="phonenumber"
            handleBlur={handleBlur}
            errors={errors}
            touched={touched}
            setFieldTouched={setFieldTouched}
            formMode={formMode}
            primaryEmailChangeInProspectRequest={
              primaryEmailChangeInProspectRequest
            }
            deleteEmailFromProspectRequest={deleteEmailFromProspectRequest}
            deletePhoneFromProspectRequest={deletePhoneFromProspectRequest}
            selectedProspectId={selectedProspectId}
          />
        );
      }
      if (typeof field.component === 'string') {
        switch (field.component) {
          case 'text':
            return (
              <>
                <ProspectLabelField index={index} formMode={formMode} />
                <Input
                  className={field.styleClassNames}
                  key={this.getNameKey(field.name)}
                  id={field.name}
                  variant={
                    errors[field.name] &&
                    touched[field.name] &&
                    Input.Variant.Error
                  }
                  onBlur={handleBlur}
                  name={field.name}
                  label={this.truncateText(field.displayName, 20)}
                  value={values[field.name]}
                  onChange={(_val, e) => handleChange(e)}
                  type={field.component}
                  disabled={!!field.disabled}
                  caption={
                    <span className="form-input-error-caption red-txt-12">
                      {errors[field.name] &&
                        touched[field.name] &&
                        errors[field.name]}
                    </span>
                  }
                />
              </>
            );

          case 'number':
            return (
              <>
                <ProspectLabelField index={index} formMode={formMode} />
                <InputNumber
                  disabled={!!field.disabled}
                  containerClassName={field.styleClassNames}
                  key={this.getNameKey(field.name)}
                  id={field.name}
                  variant={
                    errors[field.name] &&
                    touched[field.name] &&
                    InputNumber.Variant.Error
                  }
                  onBlur={handleBlur}
                  name={field.name}
                  label={this.truncateText(field.displayName, 20)}
                  value={values[field.name]}
                  onChange={(_val, e) => handleChange(e)}
                  caption={
                    <span className="form-input-error-caption red-txt-12">
                      {errors[field.name] &&
                        touched[field.name] &&
                        errors[field.name]}
                    </span>
                  }
                />
              </>
            );
          default:
            break;
        }
      }
      return field.component instanceof React.Component && field.component;
    });
  }

  render() {
    const {
      fields,
      formBodyClasses,
      formContainerClasses,
      onSubmit,
      onCancel,
      buttonClassName,
      submitStatus,
      buttonTitle,
      primaryEmailChangeInProspectRequest,
      deleteEmailFromProspectRequest,
      deletePhoneFromProspectRequest,
      t,
    } = this.props;
    const validationObject = this.generateValidationObject();
    if (!fields) {
      return null;
    }
    /* eslint-disable */

    const initialValues: any = fields
      .map((field: any) =>
        field.value
          ? { [field.name]: field.value }
          : field.name === 'phonenumber'
          ? {
              [field.name]: [],
            }
          : field.name === 'email'
          ? {
              [field.name]: [
                {
                  email: '',
                  emailType: ProspectContactInfoCategory.Work,
                  isPrimary: true,
                },
              ],
            }
          : {
              [field.name]: '',
            },
      )
      .reduce((acc, field) => ({ ...acc, ...field }));
    /* eslint-enable */

    return (
      <Formik
        innerRef={this.formRef}
        validate={(values) => {
          const errors = {};
          const validationObjectKeys = Object.keys(validationObject);
          validationObjectKeys.forEach((key) => {
            if (validationObject[key] === InputValidationType.isEmail) {
              values.email.forEach((v, _index) => {
                if (!validator[validationObject[key]](v.email)) {
                  errors[`email${_index}`] = 'Enter a valid email address';
                }
              });
            }
            if (validationObject[key] === InputValidationType.isMobilePhone) {
              values.phonenumber.forEach((v, _index) => {
                if (!this.isValidPhoneNumber(v.phonenumber)) {
                  errors[`phonenumber${_index}`] = 'Enter a valid phone number';
                }
              });
            }
            if (
              validationObject[key] === InputValidationType.MaxCharLimit &&
              !this.validateMaxCharLimit(values[key])
            ) {
              errors[key] = 'Characters limit exceeds over 1000';
            }
          });
          return errors;
        }}
        validateOnBlur
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          errors,
          touched,
          dirty,
          handleBlur,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form
            className={`${formContainerClasses} d-flex h-100 justify-content-between flex-column prospect-form-field`}
          >
            <div className={`prospect-form-field-content ${formBodyClasses}`}>
              <div className="row">
                {this.generateInputFields(
                  values,
                  handleChange,
                  errors,
                  touched,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  primaryEmailChangeInProspectRequest,
                  deleteEmailFromProspectRequest,
                  deletePhoneFromProspectRequest,
                  this.props?.selectedProspectId,
                )}
              </div>
            </div>
            <div className="d-flex justify-content-end prospect-modal-footer">
              <Button
                className="pull-left mr-3 cancel-btn"
                variant={Button.Variant.Default}
                type={Button.Type.Button}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                className={`pull-right header-btn save-btn ${
                  buttonClassName || ''
                }`}
                variant={Button.Variant.Primary}
                type={Button.Type.Submit}
                isLoading={!!submitStatus && submitStatus}
                disabled={
                  !isEmpty(errors) || !dirty || (!!submitStatus && submitStatus)
                }
              >
                {buttonTitle || t('labels.save')}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withTranslation()(CustomForm);
