import React, { useState } from 'react';
import {
  AlertCircle,
  ArrowsMaximize,
  ArrowsMinimize,
  Check,
  Copy,
  Repeat,
} from '@saleshandy/icons';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';

type IProps = {
  label: string;
  statusCode: number;
  statusText: string;
  json: Record<string, string>;
  lastTriggeredAt?: string;
  failed: boolean;
  isExpanded: boolean;
  onExpandOrCollapsed: VoidFunction;
  showRetry?: boolean;
  onRetry?: VoidFunction;
  showEventType?: boolean;
  event?: string;
  showRequest?: boolean;
  requestJson?: Record<string, any>;
};

const WebhookReqResPreview: React.FC<IProps> = ({
  label,
  statusCode,
  statusText,
  json,
  lastTriggeredAt,
  failed,
  isExpanded,
  onExpandOrCollapsed,
  showRetry = false,
  onRetry,
  showEventType = false,
  event = '',
  showRequest = false,
  requestJson,
}) => {
  const [copyState, setCopyState] = useState(false);

  const formattedJson = JSON.stringify(json, null, 2);
  const formattedRequestJson = JSON.stringify(requestJson, null, 2);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setCopyState(true);

    setTimeout(() => {
      setCopyState(false);
    }, 1000);
  };

  const headerRight = (
    <div className="header-right">
      {showRetry && (
        <OverlayTooltip text="Resend">
          <div
            className="webhook-response-cta"
            {...accessibleOnClick(() => onRetry?.())}
          >
            <Repeat />
          </div>
        </OverlayTooltip>
      )}

      {isExpanded ? (
        <OverlayTooltip text="Collapse">
          <div
            className="webhook-response-cta"
            {...accessibleOnClick(onExpandOrCollapsed)}
          >
            <ArrowsMinimize />
          </div>
        </OverlayTooltip>
      ) : (
        <OverlayTooltip text="Expand">
          <div
            className="webhook-response-cta"
            {...accessibleOnClick(onExpandOrCollapsed)}
          >
            <ArrowsMaximize />
          </div>
        </OverlayTooltip>
      )}
    </div>
  );

  return (
    <div className={`webhook-req-res-preview ${failed ? 'failed' : 'success'}`}>
      {showEventType && (
        <div className="webhook-req-res-preview--header mb-24px">
          <div className="header-left">
            <h1>{event}</h1>
          </div>
          {headerRight}
        </div>
      )}

      <div className="webhook-req-res-preview--header">
        <div className="header-left">
          <h1 className={showEventType ? 'log-view' : ''}>{label}</h1>
          <div
            className={`webhook-response-badge ${
              failed ? 'failed' : 'success'
            }`}
          >
            {failed ? (
              <>
                <span>{`HTTP ${statusCode} ${statusText}`}</span>
                <AlertCircle />
              </>
            ) : (
              <>
                <span>{`HTTP ${statusCode} ${statusText}`}</span>
                <Check />
              </>
            )}
          </div>
        </div>
        {!showEventType && headerRight}
      </div>

      {lastTriggeredAt && <p className="last-triggered">{lastTriggeredAt}</p>}

      <div className="webhook-req-res-preview--code">
        <SyntaxHighlighter
          language="json"
          wrapLongLines
          useInlineStyles={false}
        >
          {formattedJson}
        </SyntaxHighlighter>
        <OverlayTooltip
          key={copyState ? 'Copied' : 'Copy'}
          text={copyState ? 'Copied' : 'Copy'}
        >
          <div
            className="json-copy-btn"
            {...accessibleOnClick(() => copyText(formattedJson))}
          >
            {copyState ? <Check /> : <Copy />}
          </div>
        </OverlayTooltip>
      </div>

      {showRequest && (
        <>
          <div className="webhook-req-res-preview--header mt-3">
            <div className="header-left">
              <h1 className={showEventType ? 'log-view' : ''}>Request</h1>
            </div>
          </div>
          <div className="webhook-req-res-preview--code">
            <SyntaxHighlighter
              language="json"
              wrapLongLines
              useInlineStyles={false}
            >
              {formattedRequestJson}
            </SyntaxHighlighter>
            <OverlayTooltip
              key={copyState ? 'Copied' : 'Copy'}
              text={copyState ? 'Copied' : 'Copy'}
            >
              <div
                className="json-copy-btn"
                {...accessibleOnClick(() => copyText(formattedRequestJson))}
              >
                {copyState ? <Check /> : <Copy />}
              </div>
            </OverlayTooltip>
          </div>
        </>
      )}
    </div>
  );
};

export default WebhookReqResPreview;
