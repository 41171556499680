import React from 'react';
import { GraduationHat } from '@saleshandy/icons';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';
import { openUrl } from '../../../../../../shared/utils/open-url';
import { OverlayTooltip } from '../../../../../../shared/design-system/components/overlay/tooltip/overlay-tooltip';
import { supportUrls } from '../../../../../../shared/utils/urls';
import { Placement } from '../../../../../../shared/design-system/components/overlay';

const MasterClass: React.FC = () => (
  <OverlayTooltip text="Cold Email Masterclass" placement={Placement.Bottom}>
    <div
      {...accessibleOnClick(() => openUrl(supportUrls.coldEmailMasterClass))}
      className="d-flex hover-on-header"
    >
      <GraduationHat className="d-flex graduation-hat" />
    </div>
  </OverlayTooltip>
);

export default MasterClass;
