import React, { useMemo } from 'react';
import { Pencil, Bullet, Trash } from '@saleshandy/icons';

import {
  Webhook,
  WebhookPaginationOptions,
} from '../../../../../../types/webhook';

import Table from '../../../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../../../shared/design-system/components/organisms/table/types';

import { getWebhookTableColumns } from './utils/get-webhook-table-columns';

type IProps = {
  webhooks: Webhook[];
  webhookPaginationOptions: WebhookPaginationOptions;
  isLoading: boolean;
  onPaginationChange: (page: number, limit: number) => void;
  onAction: (key: string, webhook: Webhook) => void;
};

const WebhookTable: React.FC<IProps> = ({
  webhooks,
  webhookPaginationOptions,
  isLoading,
  onPaginationChange,
  onAction,
}) => {
  const onWebhookStatusChanged = (webhook: Webhook) => {
    onAction('statusUpdate', webhook);
  };

  const onWebhookNameClicked = (webhook: Webhook) => {
    onAction('edit', webhook);
  };

  const onPaginationOptionsChange = ({ page, limit }) => {
    if (
      webhookPaginationOptions.currentPage === page &&
      webhookPaginationOptions.itemsPerPage === limit
    ) {
      return;
    }
    onPaginationChange(page, limit);
  };

  const actions = useMemo(
    () => [
      { key: 'edit', iconElement: <Pencil />, displayName: 'Edit' },
      { key: 'viewLog', iconElement: <Bullet />, displayName: 'View Log' },
      { key: 'delete', iconElement: <Trash />, displayName: 'Delete' },
    ],
    [],
  );

  const columns = useMemo(
    () =>
      getWebhookTableColumns({
        itemCount: webhookPaginationOptions?.itemsCount ?? 0,
        onWebhookStatusChanged,
        onWebhookNameClicked,
      }),
    [webhookPaginationOptions],
  );

  return (
    <Table
      tableWrapperClasses="webhook--table"
      columns={columns}
      data={webhooks}
      actions={actions}
      onAction={onAction}
      paginationOptions={{
        options: {
          limit: webhookPaginationOptions?.itemsPerPage,
          page: webhookPaginationOptions?.currentPage,
          totalElements: webhookPaginationOptions?.totalItems,
        },
      }}
      isNewPagination={true}
      pagination={PaginationShowHide.SHOW}
      onPaginationOptionsChange={onPaginationOptionsChange}
      headerVisibleForGenerateColumn={true}
      isLoading={isLoading}
      onRowClickHandler={onWebhookNameClicked}
    />
  );
};

export default WebhookTable;
