import React from 'react';

const Vector = ({ width = 18, height = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={`0 0 ${width} ${height}`}
    fill="none"
  >
    <path
      d="M5.81176 3.26459C4.81119 2.64402 4 3.1524 4 4.39918V13.5999C4 14.8479 4.81119 15.3557 5.81176 14.7357L13.2493 10.1237C14.2502 9.50291 14.2502 8.49714 13.2493 7.87649L5.81176 3.26459Z"
      fill="white"
    />
  </svg>
);

export default Vector;
