/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { Accordion } from '@saleshandy/design-system';
import { CircleCheck, Check } from '@saleshandy/icons';
import some from 'lodash/some';
import filter from 'lodash/filter';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import type { IProps } from './master-class-lessons-container';
import { getPercentageValue } from '../../../home/components/header/utils/helper';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import { executeOnRequestStatus } from '../../../../shared/utils/execute-on-request-status';

const MasterClassLessons: React.FC<IProps> = ({
  currentLessonId,
  currentTopicId,
  onTopicSelect,
  academyLessons,
  academyTopicsProgress,
  updateAcademyTopicProgressRequestStatus,
  getAcademyTopicsProgress,
  resetUpdateAcademyProgressStatus,
}) => {
  const lessons = useMemo(() => academyLessons && academyLessons[0]?.lessons, [
    academyLessons,
  ]);
  const ref = useRef<any>();
  const accordionKey = 3829;

  //* State
  const [, setSelectedTopic] = useState({
    lesson: currentLessonId,
    topic: currentTopicId,
  });
  const [, setOpenedLessons] = useState<number[]>([]);

  //* Function
  const onSelectTopic = (lessonId, topicId, topic) => {
    setSelectedTopic({ lesson: lessonId, topic: topicId });
    onTopicSelect(topic, lessonId);
  };

  const isTopicSelected = (lessonId: number, topicId: number): boolean =>
    lessonId === currentLessonId && topicId === currentTopicId;

  const isTopicCompleted = (lessonId: number, topicId: number): boolean =>
    some(academyTopicsProgress, { lessonId, topicId });

  const getTopicProgressClassName = (
    lessonId: number,
    topicId: number,
  ): string => {
    if (isTopicCompleted(lessonId, topicId)) {
      return 'chapter-track-completed';
    }
    if (isTopicSelected(lessonId, topicId)) {
      return 'chapter-track-current';
    }

    return '';
  };

  const getLessonProgress = (lessonId: number) =>
    filter(academyTopicsProgress, { lessonId })?.length;

  const getLessonProgressValue = (lessonId: number, numberOfTopics: number) =>
    getPercentageValue(getLessonProgress(lessonId), numberOfTopics);

  const onAccordionChange = (uuid) => {
    if (!uuid.includes(currentLessonId)) {
      uuid.push(currentLessonId);
    }

    setOpenedLessons(uuid);
  };

  //* useEffect
  useEffect(() => {
    executeOnRequestStatus({
      status: updateAcademyTopicProgressRequestStatus,
      onSuccess: () => {
        getAcademyTopicsProgress();
        resetUpdateAcademyProgressStatus();
      },
    });
  }, [updateAcademyTopicProgressRequestStatus]);

  useEffect(() => {
    ref.current?.click();
  }, [currentTopicId]);

  return (
    <div key={accordionKey}>
      <Accordion
        allowMultipleExpanded
        allowZeroExpanded
        onChange={(uuid) => onAccordionChange(uuid)}
      >
        {lessons &&
          lessons.map((lesson) => (
            <Accordion.Item key={lesson.id} uuid={lesson.id}>
              <Accordion.ItemHeading>
                <Accordion.ItemButton>
                  <div ref={ref} className="d-flex">
                    <div className="circular-progress mr-2">
                      {getLessonProgressValue(
                        lesson.id,
                        lesson.topics.length,
                      ) === 100 ? (
                        <CircleCheck className="green-txt-14 regular-5 circle-check-icon" />
                      ) : (
                        <CircularProgressbar
                          value={getLessonProgressValue(
                            lesson.id,
                            lesson.topics.length,
                          )}
                          strokeWidth={10}
                          styles={buildStyles({
                            pathColor: '#059669',
                          })}
                        />
                      )}
                    </div>
                    <div className="d-flex flex-column w-75">
                      <span className="semibold-2 popover-arrow-color-txt hover-underline ">
                        {lesson.name}
                      </span>
                      <span className="mt-1 font-10 semibold-1 gray-txt-15">
                        {lesson.topics.length} Lessons
                      </span>
                    </div>
                  </div>
                </Accordion.ItemButton>
              </Accordion.ItemHeading>
              <Accordion.ItemPanel>
                <div className="chapter">
                  {lesson.topics.map((topic) => (
                    <div
                      className={`${
                        isTopicSelected(lesson.id, topic.id) && 'blue-17'
                      } chapter-item d-flex pointer`}
                      {...accessibleOnClick(() =>
                        onSelectTopic(lesson.id, topic.id, topic),
                      )}
                    >
                      <div>
                        {isTopicCompleted(lesson.id, topic.id) ? (
                          <div className="chapter-track chapter-track-completed">
                            <Check className="gray-txt-1 regular-1 check-icon" />
                          </div>
                        ) : (
                          <div
                            className={`chapter-track ${getTopicProgressClassName(
                              lesson.id,
                              topic.id,
                            )}`}
                          />
                        )}
                      </div>
                      <span className="regular-2 font-medium popover-arrow-color-txt hover-underline">
                        {topic.title}
                      </span>
                    </div>
                  ))}
                </div>
              </Accordion.ItemPanel>
            </Accordion.Item>
          ))}
      </Accordion>
    </div>
  );
};

export default MasterClassLessons;
