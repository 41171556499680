import React, { useEffect, useMemo, useState } from 'react';
import { Download } from '@saleshandy/icons';

import type { IProps } from './import-emails-domains-via-csv-container';
import { FileData, MapFieldDropdownOption } from './types';

import Modal from '../../../../../../../shared/design-system/components/atoms/modal/modal';
import FileSelectorInput from './components/file-selector-input';
import Select from '../../../../../../../shared/design-system/components/select';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import { isAgency } from '../../../../../../../shared/utils/user-details';
import { executeOnRequestStatus } from '../../../../../../../shared/utils';
import ImportReport from './components/import-report';
import { getListHelperText } from '../../../helper/get-list-helper-text';
import Alert from '../../../../../../../shared/design-system/ui/alert';
import { constants } from '../../../../../../../shared/enums/constants';

const ImportEmailsDomainsViaCsv: React.FC<IProps> = ({
  show,
  isLoading,
  onCancel,
  onSubmit,
  isGlobalList,
  importDoNotContactListEmailsAndDomainsRequestStatus,
  importDoNotContactListEmailsAndDomainsResponse,
}) => {
  const [fileData, setFileData] = useState<FileData>(null);
  const [
    selectedMappedHeader,
    setSelectedMappedHeader,
  ] = useState<MapFieldDropdownOption>(null);
  const [isImportedSuccessfully, setIsImportedSuccessfully] = useState(false);
  const handleOnCancel = () => {
    onCancel();
    setIsImportedSuccessfully(false);
    setFileData(null);
    setSelectedMappedHeader(null);
  };

  const handleOnSubmit = () => {
    if (isImportedSuccessfully) {
      handleOnCancel();
    } else {
      onSubmit(fileData.file, selectedMappedHeader.header);
    }
  };

  const getDefaultSelectedHeader = (
    headers: MapFieldDropdownOption[],
  ): MapFieldDropdownOption => {
    if (headers.length === 1) {
      return headers[0];
    }

    const defaultHeader = headers.find(({ header }) =>
      /(email|domain)/i.test(header),
    );
    return defaultHeader || null;
  };

  const handleCSVImport = (file: FileData) => {
    setFileData(file);

    if (!file) {
      setSelectedMappedHeader(null);
    }
  };

  const generateMapFieldDropdownOptions = useMemo((): MapFieldDropdownOption[] => {
    if (fileData) {
      const headers = fileData.headers.map((header, index) => ({
        key: index.toString(),
        header,
      }));

      setSelectedMappedHeader(getDefaultSelectedHeader(headers));

      return headers;
    }

    return [];
  }, [fileData]);

  useEffect(() => {
    executeOnRequestStatus({
      status: importDoNotContactListEmailsAndDomainsRequestStatus,
      onSuccess: () => {
        setIsImportedSuccessfully(true);
        setFileData(null);
        setSelectedMappedHeader(null);
      },
      onFailed: () => {
        setFileData(null);
        setSelectedMappedHeader(null);
      },
    });
  }, [importDoNotContactListEmailsAndDomainsRequestStatus]);

  const isAgencyUser = hasPermission(Permissions.CLIENT_READ) && isAgency();

  return (
    <Modal
      show={show}
      onClose={handleOnCancel}
      onSubmit={handleOnSubmit}
      titleContent={isImportedSuccessfully ? 'Import Report' : 'Add via CSV'}
      showCloseIcon
      submitButtonText={isImportedSuccessfully ? 'Done' : 'Import'}
      isSubmitLoading={isLoading}
      submitButtonClassName="header-btn"
      isSubmitDisabled={
        !isImportedSuccessfully && (!selectedMappedHeader || isLoading)
      }
      hideCancelButton={isImportedSuccessfully}
      className="dnc-upload-csv-modal"
      backdrop="static"
      extraModalProps={{
        enforceFocus: false,
      }}
      {...(!isImportedSuccessfully && {
        footerContent: (
          <div>
            <a
              className="sample-file-btn"
              href={constants.DOWNLOAD_DNC_SAMPLE_FILE_URL}
              download
            >
              <div className="icon-wrapper">
                <Download />
              </div>
              <p>Try Sample File</p>
            </a>
          </div>
        ),
      })}
    >
      {isImportedSuccessfully ? (
        <ImportReport
          importDoNotContactListEmailsAndDomainsResponse={
            importDoNotContactListEmailsAndDomainsResponse
          }
        />
      ) : (
        <>
          <FileSelectorInput handleCSVImport={handleCSVImport} />

          <p className="import-help-text">
            Only the first 10,000 valid items from the selected column will be
            processed and Max file size allowed is 5 MB
          </p>

          <div className="import-column-mapping">
            <h3>Map Domain / Email Column</h3>

            <Select<MapFieldDropdownOption>
              selectedOptionKey={selectedMappedHeader?.key}
              options={generateMapFieldDropdownOptions}
              selectedOptionRenderer={([option]) => (
                <span className="semibold-10">{option?.header}</span>
              )}
              optionRenderer={(option) => (
                <span className="semibold-10">{option?.header}</span>
              )}
              onChange={([selectedOption]) => {
                setSelectedMappedHeader(selectedOption);
              }}
              showSearch
              optionFilterProp="header"
              filterOption={(value, option) =>
                option?.header?.toLowerCase().includes(value.toLowerCase())
              }
              placeholder="Select field"
              disabled={!fileData || fileData?.headers?.length === 0}
            />
          </div>

          <Alert
            variant="alert"
            description={
              <>
                {getListHelperText({
                  isAgencyUser,
                  isGlobalList,
                  messageSpecificFor: 'user',
                })}
              </>
            }
            className="helper-alert-warning-wrapper"
          />
        </>
      )}
    </Modal>
  );
};
export default ImportEmailsDomainsViaCsv;
