import api from '../../../api';
import {
  UpdateUserProfilePayload,
  UpdatePasswordPayload,
  verifyCurrentPasswordPayload,
} from '../types/request-payload';
import { ResponseSuccess } from '../../../shared/types/response';

export const updatePassword = async (payload: UpdatePasswordPayload) =>
  api.patch<ResponseSuccess>(`/user/password`, payload);

export const updateMyProfile = async ({
  firstName,
  lastName,
  ...payload
}: UpdateUserProfilePayload) =>
  api.patch<ResponseSuccess>(`/user/`, {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    ...payload,
  });

export const getMyProfile = async () => api.get<ResponseSuccess>(`/user/`);

export const verifyCurrentPassword = async (
  payload: verifyCurrentPasswordPayload,
) => api.post<ResponseSuccess>('/user/verify-current-password', payload);

export const sendOtpForChangeEmail = async (newEmail: string) =>
  api.post<ResponseSuccess>('/user/change-email/otp', { newEmail });

export const resendOtpForChangeEmail = async () =>
  api.post<ResponseSuccess>('/user/change-email/otp/resend');

export const verifyOtpForChangeEmail = async (code: number) =>
  api.post<ResponseSuccess>('/user/change-email/otp/verify', { code });

export const deleteMyProfile = async (currentPassword: string) =>
  api.delete<ResponseSuccess>(`/user`, { params: { currentPassword } });
