const hasVisitedAgencyPortal = 'hasVisitedAgencyPortal';

export const getHasVisitedAgencyPortal = () =>
  localStorage.getItem(hasVisitedAgencyPortal);

export const setHasVisitedAgencyPortal = (value: string) =>
  localStorage.setItem(hasVisitedAgencyPortal, value);

export const removeHasVisitedAgencyPortal = () =>
  localStorage.removeItem(hasVisitedAgencyPortal);
