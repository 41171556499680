import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  Webhook,
  WebhookActivityDetails,
  WebhookActivityLog,
  WebhookPaginationOptions,
  WebhookTestResult,
} from '../../types/webhook';
import {
  createWebhookRequest,
  updateWebhookRequest,
  deleteWebhookRequest,
  getWebhookListRequest,
  getWebhookRequest,
  testWebhookRequest,
  updateWebhookStatusRequest,
  getWebhookActivityLogsRequest,
  getWebhookActivityDetailsRequest,
  resendFailedWebhookEventRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: any;
};

type State = {
  getWebhookRequest: RequestState;
  getWebhookListRequest: RequestState;
  updateWebhookStatusRequest: RequestState;
  createWebhookRequest: RequestState;
  updateWebhookRequest: RequestState;
  testWebhookRequest: RequestState;
  deleteWebhookRequest: RequestState;
  getWebhookActivityLogsRequest: RequestState;
  getWebhookActivityDetailsRequest: RequestState;
  resendFailedWebhookEventRequest: RequestState;

  webhook: Webhook;
  webhooks: Webhook[];
  webhookPaginationOptions: WebhookPaginationOptions;
  webhookTestResult: WebhookTestResult;

  webhookActivityLogs: WebhookActivityLog[];
  webhookActivityLogsPaginationOptions: {
    currentPage: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
  };
  webhookActivityDetails: WebhookActivityDetails;
};

const initialState: State = {
  getWebhookRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getWebhookListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateWebhookStatusRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createWebhookRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateWebhookRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  testWebhookRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteWebhookRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getWebhookActivityLogsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getWebhookActivityDetailsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  resendFailedWebhookEventRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  webhook: null,
  webhooks: [],
  webhookPaginationOptions: null,
  webhookTestResult: null,

  webhookActivityLogs: [],
  webhookActivityLogsPaginationOptions: null,
  webhookActivityDetails: null,
};

const webhookSlice = createSlice({
  name: 'webhook',
  initialState,
  reducers: {
    resetGetWebhookRequest: (state) => {
      state.getWebhookRequest = initialState.getWebhookRequest;
    },
    updateWebhookData: (state, action) => {
      state.webhook = {
        ...state.webhook,
        ...action.payload,
      };
    },
    resetWebhookData: (state) => {
      state.webhook = initialState.webhook;
    },
    resetGetWebhookListRequest: (state) => {
      state.getWebhookListRequest = initialState.getWebhookListRequest;
    },
    resetUpdateWebhookStatusRequest: (state) => {
      state.updateWebhookStatusRequest =
        initialState.updateWebhookStatusRequest;
    },
    resetCreateWebhookRequest: (state) => {
      state.createWebhookRequest = initialState.createWebhookRequest;
    },
    resetUpdateWebhookRequest: (state) => {
      state.updateWebhookRequest = initialState.updateWebhookRequest;
    },
    resetTestWebhookRequest: (state) => {
      state.testWebhookRequest = initialState.testWebhookRequest;
    },
    resetWebhookTestResult: (state) => {
      state.webhookTestResult = initialState.webhookTestResult;
    },
    resetDeleteWebhookRequest: (state) => {
      state.deleteWebhookRequest = initialState.deleteWebhookRequest;
    },
    resetGetWebhookActivityLogsRequest: (state) => {
      state.getWebhookActivityLogsRequest =
        initialState.getWebhookActivityLogsRequest;
    },
    resetWebhookActivityLogs: (state) => {
      state.webhookActivityLogs = initialState.webhookActivityLogs;
    },
    resetGetWebhookActivityDetailsRequest: (state) => {
      state.getWebhookActivityDetailsRequest =
        initialState.getWebhookActivityDetailsRequest;
    },
    resetWebhookActivityDetails: (state) => {
      state.webhookActivityDetails = initialState.webhookActivityDetails;
    },
    resetResendFailedWebhookEventRequest: (state) => {
      state.resendFailedWebhookEventRequest =
        initialState.resendFailedWebhookEventRequest;
    },
  },
  extraReducers: (builder) => {
    // Get Webhook
    builder.addCase(getWebhookRequest.pending, (state) => {
      state.getWebhookRequest.status = RequestStatus.Pending;
      state.getWebhookRequest.error = null;
    });
    builder.addCase(getWebhookRequest.fulfilled, (state, action) => {
      state.getWebhookRequest.status = RequestStatus.Succeeded;
      state.getWebhookRequest.message = action.payload.message;
      state.webhook = action.payload.payload;
    });
    builder.addCase(getWebhookRequest.rejected, (state, action) => {
      state.getWebhookRequest.status = RequestStatus.Failed;
      state.getWebhookRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get List of Webhooks
    builder.addCase(getWebhookListRequest.pending, (state) => {
      state.getWebhookListRequest.status = RequestStatus.Pending;
      state.getWebhookListRequest.error = null;
    });
    builder.addCase(getWebhookListRequest.fulfilled, (state, action) => {
      state.getWebhookListRequest.status = RequestStatus.Succeeded;
      state.getWebhookListRequest.message = action.payload.message;
      state.webhooks = action.payload.payload.webhooks.map((webhook) => ({
        ...webhook,
        // eslint-disable-next-line no-underscore-dangle
        id: webhook._id,
      }));
      state.webhookPaginationOptions = action.payload.payload.meta;
    });
    builder.addCase(getWebhookListRequest.rejected, (state, action) => {
      state.getWebhookListRequest.status = RequestStatus.Failed;
      state.getWebhookListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Webhook Status
    builder.addCase(updateWebhookStatusRequest.pending, (state) => {
      state.updateWebhookStatusRequest.status = RequestStatus.Pending;
      state.updateWebhookStatusRequest.error = null;
    });
    builder.addCase(updateWebhookStatusRequest.fulfilled, (state, action) => {
      state.updateWebhookStatusRequest.status = RequestStatus.Succeeded;
      state.updateWebhookStatusRequest.message = action.payload.message;
    });
    builder.addCase(updateWebhookStatusRequest.rejected, (state, action) => {
      state.updateWebhookStatusRequest.status = RequestStatus.Failed;
      state.updateWebhookStatusRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Create Webhook
    builder.addCase(createWebhookRequest.pending, (state) => {
      state.createWebhookRequest.status = RequestStatus.Pending;
      state.createWebhookRequest.error = null;
    });
    builder.addCase(createWebhookRequest.fulfilled, (state, action) => {
      state.createWebhookRequest.status = RequestStatus.Succeeded;
      state.createWebhookRequest.message = action.payload.message;
    });
    builder.addCase(createWebhookRequest.rejected, (state, action) => {
      state.createWebhookRequest.status = RequestStatus.Failed;
      state.createWebhookRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Update Webhook
    builder.addCase(updateWebhookRequest.pending, (state) => {
      state.updateWebhookRequest.status = RequestStatus.Pending;
      state.updateWebhookRequest.error = null;
    });
    builder.addCase(updateWebhookRequest.fulfilled, (state, action) => {
      state.updateWebhookRequest.status = RequestStatus.Succeeded;
      state.updateWebhookRequest.message = action.payload.message;
    });
    builder.addCase(updateWebhookRequest.rejected, (state, action) => {
      state.updateWebhookRequest.status = RequestStatus.Failed;
      state.updateWebhookRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Test Webhook
    builder.addCase(testWebhookRequest.pending, (state) => {
      state.testWebhookRequest.status = RequestStatus.Pending;
      state.testWebhookRequest.error = null;
    });
    builder.addCase(testWebhookRequest.fulfilled, (state, action) => {
      state.testWebhookRequest.status = RequestStatus.Succeeded;
      state.testWebhookRequest.message = action.payload.message;
      state.webhookTestResult = action.payload.payload;
    });
    builder.addCase(testWebhookRequest.rejected, (state, action) => {
      state.testWebhookRequest.status = RequestStatus.Failed;
      state.testWebhookRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Delete Webhook
    builder.addCase(deleteWebhookRequest.pending, (state) => {
      state.deleteWebhookRequest.status = RequestStatus.Pending;
      state.deleteWebhookRequest.error = null;
    });
    builder.addCase(deleteWebhookRequest.fulfilled, (state, action) => {
      state.deleteWebhookRequest.status = RequestStatus.Succeeded;
      state.deleteWebhookRequest.message = action.payload.message;
    });
    builder.addCase(deleteWebhookRequest.rejected, (state, action) => {
      state.deleteWebhookRequest.status = RequestStatus.Failed;
      state.deleteWebhookRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Webhook Activity Logs
    builder.addCase(getWebhookActivityLogsRequest.pending, (state) => {
      state.getWebhookActivityLogsRequest.status = RequestStatus.Pending;
      state.getWebhookActivityLogsRequest.error = null;
    });
    builder.addCase(
      getWebhookActivityLogsRequest.fulfilled,
      (state, action) => {
        state.getWebhookActivityLogsRequest.status = RequestStatus.Succeeded;
        state.getWebhookActivityLogsRequest.message = action.payload.message;
        if (action.meta.arg.action === 'reset') {
          state.webhookActivityLogs = action.payload.payload.logs;
        } else if (action.meta.arg.action === 'load-more') {
          state.webhookActivityLogs = [
            ...state.webhookActivityLogs,
            ...action.payload.payload.logs,
          ];
        }
        state.webhookActivityLogsPaginationOptions =
          action.payload.payload.meta;
      },
    );
    builder.addCase(getWebhookActivityLogsRequest.rejected, (state, action) => {
      state.getWebhookActivityLogsRequest.status = RequestStatus.Failed;
      state.getWebhookActivityLogsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Webhook Activity Details
    builder.addCase(getWebhookActivityDetailsRequest.pending, (state) => {
      state.getWebhookActivityDetailsRequest.status = RequestStatus.Pending;
      state.getWebhookActivityDetailsRequest.error = null;
    });
    builder.addCase(
      getWebhookActivityDetailsRequest.fulfilled,
      (state, action) => {
        state.getWebhookActivityDetailsRequest.status = RequestStatus.Succeeded;
        state.getWebhookActivityDetailsRequest.message = action.payload.message;
        state.webhookActivityDetails = action.payload.payload.eventDetails;
      },
    );
    builder.addCase(
      getWebhookActivityDetailsRequest.rejected,
      (state, action) => {
        state.getWebhookActivityDetailsRequest.status = RequestStatus.Failed;
        state.getWebhookActivityDetailsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Resend Failed Webhook Event
    builder.addCase(resendFailedWebhookEventRequest.pending, (state) => {
      state.resendFailedWebhookEventRequest.status = RequestStatus.Pending;
      state.resendFailedWebhookEventRequest.error = null;
    });
    builder.addCase(
      resendFailedWebhookEventRequest.fulfilled,
      (state, action) => {
        state.resendFailedWebhookEventRequest.status = RequestStatus.Succeeded;
        state.resendFailedWebhookEventRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      resendFailedWebhookEventRequest.rejected,
      (state, action) => {
        state.resendFailedWebhookEventRequest.status = RequestStatus.Failed;
        state.resendFailedWebhookEventRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const {
  resetGetWebhookListRequest,
  resetGetWebhookRequest,
  updateWebhookData,
  resetWebhookData,
  resetUpdateWebhookStatusRequest,
  resetCreateWebhookRequest,
  resetUpdateWebhookRequest,
  resetTestWebhookRequest,
  resetWebhookTestResult,
  resetDeleteWebhookRequest,
  resetGetWebhookActivityLogsRequest,
  resetWebhookActivityLogs,
  resetGetWebhookActivityDetailsRequest,
  resetWebhookActivityDetails,
  resetResendFailedWebhookEventRequest,
} = webhookSlice.actions;

export default webhookSlice.reducer;
