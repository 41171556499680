import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { GetTaskerParams, GetTasksParams, UpdateTask } from '../../utils/types';
import {
  getTaskerDataRequest,
  getTaskStatusCountRequest,
  updateTasksRequest,
} from '../../extra-actions';
import TaskerModal from './tasker-modal';
import { TaskAction, TaskStatusKey } from '../../utils/enums';
import { SequenceStepType } from '../../../sequence/enums';
import { resetTaskerState } from '../../tasks-slice';
import { getCustomOutcomeRequest } from '../../../settings/components/custom-outcomes/extra-actions';

const mapStateToProps = (state: RootState) => ({
  getTaskerDataRequestStatus: state.tasks.getTaskerDataRequest.status,
  taskActionRequestStatus: state.tasks.updateTasksRequest.status,
  taskerData: state.tasks.taskerData,
  allOutcomes: state.customOutcome.outcomes,
  outcomeChangeRequestStatus: state.sequence.onOutcomeChangeRequest.status,
  updateTaskNoteRequestStatus: state.tasks.updateTaskNoteRequest.status,
});

const mapDispatchToProps = {
  updateTasksRequest: (payload: UpdateTask) => updateTasksRequest(payload),
  getTasksStatusCountRequest: (params: GetTasksParams) =>
    getTaskStatusCountRequest(params),
  getTaskerDataRequest: (params: GetTaskerParams) =>
    getTaskerDataRequest(params),
  sendGetAllOutcomesRequest: () => getCustomOutcomeRequest(),
  resetTaskerState: () => resetTaskerState(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    show: boolean;
    onHide: VoidFunction;
    taskStatus: TaskStatusKey;
    tasksCount: number;
    paginationOptions: { pageLimit: number; pageNumber: number };
    taskId: number;
    filters: GetTasksParams;
    onTaskNoteClick: (tId: number, tNote: string) => void;
    onOutcomeChange: (outcome: { key: string; label: string }) => void;
    updateTasks: (
      tId: number,
      tAction: TaskAction,
      tType: SequenceStepType,
      extraPayload?: any,
    ) => void;
    resetTaskId: VoidFunction;
    onRefresh: VoidFunction;
  };

export default withRouter(connector(TaskerModal));
