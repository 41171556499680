export type SidebarNavItem = {
  eventKey: string;
  route: string;
  text: string;
  permission: string;
  tooltipText: string;
  isDisabled?: boolean;
};

export type IState = {
  expandSidebar: boolean;
};

export const UNREAD_MAX_LIMIT = 200;

// eslint-disable-next-line import/no-cycle
export type { IProps } from './sidenavbar-container';
