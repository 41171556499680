import { Button } from '@saleshandy/design-system';
import React, { ReactElement } from 'react';

type IProps = {
  icon: ReactElement;
  title: string;
  desc?: string;
  btnLabel?: string;
  btnHandler?: VoidFunction;
};

const DoNotContactListEmptyUI: React.FC<IProps> = ({
  icon,
  title,
  desc,
  btnLabel,
  btnHandler,
}) => (
  <div className="do-not-contact-list-empty-ui">
    <div className="do-not-contact-list-empty-ui-icon">{icon}</div>
    <div className="do-not-contact-list-empty-ui-content">
      <h1>{title}</h1>
      {desc && <p>{desc}</p>}
    </div>
    {btnLabel && btnHandler && (
      <Button variant="secondary" onClick={btnHandler}>
        {btnLabel}
      </Button>
    )}
  </div>
);

export default DoNotContactListEmptyUI;
