import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import type { IProps } from './types';
import { FileData } from '../../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/select-file/types';
import PreFileSelect from '../pre-file-select';
import PostFileSelect from '../post-file-select';

const SelectEmailAccountsFile: React.FC<IProps> = ({ onFileSelect }) => {
  //* State
  const [fileData, setFileData] = useState<FileData>(null);
  const [successCount, setSuccessCount] = useState<number>(0);
  const [skippedCount, setSkippedCount] = useState<number>(0);
  const [skRows, setSkRows] = useState<any[]>([]);

  //* Function
  const onRemoveHandler = () => {
    setSuccessCount(0);
    setSkippedCount(0);
    setFileData(null);
  };

  const setSuccessAndSkippedCount = (
    sCount: number,
    skCount: number,
    skippedRows: any[],
  ) => {
    setSkippedCount(skCount);
    setSuccessCount(sCount);
    setSkRows(skippedRows);
  };

  const onSelectSuccess = useCallback(
    (file, headers, firstRow, count) => {
      const payload = {
        file,
        headers,
        firstRow,
        importedContactsCount: count - 1,
      };
      setFileData(payload);
      onFileSelect(payload);
    },
    [setFileData],
  );

  //* Utility Variable
  const className = classnames([
    'select-file',
    {
      'select-file--pre': !fileData,
    },
    {
      'select-file--post': fileData,
    },
  ]);

  return (
    <div className={className}>
      {!fileData ? (
        <PreFileSelect
          onSelectSuccess={onSelectSuccess}
          getSkippedCount={(
            sCount: number,
            skCount: number,
            skippedRows: any[],
          ) => setSuccessAndSkippedCount(sCount, skCount, skippedRows)}
        />
      ) : (
        <PostFileSelect
          onRemove={onRemoveHandler}
          fileName={fileData.file.name}
          successCount={successCount}
          skippedCount={skippedCount}
          skRows={skRows}
        />
      )}
    </div>
  );
};

export default SelectEmailAccountsFile;

// duplicate
// email column empty
