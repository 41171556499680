import React from 'react';
import {
  OverlayPopover,
  Placement,
  TriggerType,
} from '../../../shared/design-system/components/overlay';

type IProps = {
  emailsCount: {
    connectedEmailsCount: number;
    disconnectedEmailsCount: number;
  };
};

const EmailAccountsCount: React.FC<IProps> = ({ emailsCount }) => {
  const renderEmailAccounts = (
    type: 'active' | 'inactive',
    label: string,
    count: number,
  ) => (
    <div className="connect-email-accounts-item">
      <div className="connect-email-accounts-item--inner">
        <div className={type} />
        <p>{label}</p>
      </div>
      <p>{count}</p>
    </div>
  );

  return (
    <div className="email-accounts-header ml-2">
      <OverlayPopover
        className="p-0 connected-email-accounts-popover"
        content={
          <div className="connected-email-accounts">
            {renderEmailAccounts(
              'active',
              'Active Accounts',
              emailsCount.connectedEmailsCount,
            )}
            {renderEmailAccounts(
              'inactive',
              'Inactive Accounts',
              emailsCount.disconnectedEmailsCount,
            )}
          </div>
        }
        trigger={TriggerType.Hover}
        placement={Placement.BottomStart}
      >
        <div className="connected-email-accounts-badge">
          <span>
            {emailsCount.connectedEmailsCount +
              emailsCount.disconnectedEmailsCount}
          </span>
        </div>
      </OverlayPopover>
    </div>
  );
};

export default EmailAccountsCount;
