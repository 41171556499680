import React from 'react';
import { Button } from '@saleshandy/design-system';
import { InfoCircle } from '@saleshandy/icons';

import { OutOfOfficeConfirmationProps } from '../types';

import Modal from '../../../../../shared/design-system/components/atoms/modal';

import Title from '../../my-profile/components/change-email-modal/change-email-modal-flow/sub-components/title';
import Description from '../../my-profile/components/change-email-modal/change-email-modal-flow/sub-components/description';
import Divider from '../../my-profile/components/change-email-modal/change-email-modal-flow/sub-components/divider';
import Footer from '../../my-profile/components/change-email-modal/change-email-modal-flow/sub-components/footer';

const OutOfOfficeConfirmation: React.FC<OutOfOfficeConfirmationProps> = ({
  showConfirmationPopup,
  hideConfirmationPopup,
  onOutOfOfficeSettingToggledOff,
}) => (
  <Modal
    show={showConfirmationPopup}
    onClose={hideConfirmationPopup}
    hideHeader
    hideFooter
    backdrop="static"
    className="change-email-modal"
  >
    <Title>
      <div className="popup-title">
        <div className="popup-title-icon">
          <InfoCircle />
        </div>
        <h1>Are you sure you want to turn this off?</h1>
      </div>
    </Title>

    <Description content="Turning this toggle off will keep sending the emails to the prospects who are out of office." />
    <Description content="This change will affect prospects on the account level, which means prospects in all the active sequences will be impacted." />

    <Divider />

    <Footer>
      <Button
        variant="secondary"
        onClick={hideConfirmationPopup}
        className="secondary-cta-btn width-100"
      >
        Cancel
      </Button>
      <Button onClick={onOutOfOfficeSettingToggledOff} className="width-100">
        Yes
      </Button>
    </Footer>
  </Modal>
);

export default OutOfOfficeConfirmation;
