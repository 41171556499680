import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import Users from './users';
import {
  UsersFilter,
  ChangeUserRolePayload,
  AssignTeamPayload,
  AddUserPayload,
} from '../../../../types/users-and-teams';
import {
  addUserRequest,
  assignTeamRequest,
  changeUserRoleRequest,
  deleteUserRequest,
  disableUserRequest,
  getAssignTeamSetting,
  getTeamsListRequest,
  getUsersRequest,
  resendInviteRequest,
  reactivateUserRequest,
  agencyInviteUserRequest,
} from '../../extra-actions';
import {
  resetAddUserRequest,
  resetAssignTeamRequest,
  resetChangeUserRoleRequest,
  resetDeleteUserRequest,
  resetDisableUserRequest,
  resetGetUsersRequest,
  resetResendInviteRequest,
  resetReactivateUserRequest,
  resetAgencyInviteUserRequest,
} from '../../users-and-teams-slice';
import { AgencyInviteUserRequestPayload } from '../../../../types/request-payload';

const mapStateToProps = (state: RootState) => ({
  users: state.usersAndTeams.users,
  usersPaginationOptions: state.usersAndTeams.usersPaginationOptions,

  userTimezone: state.home.timeZone,
  atmRole: state.home.atmRole,
  userId: state.home.userId,
  userInvitedCount: state.usersAndTeams.userInvitedCount,
  keepAllTeamsAssigned: state.usersAndTeams.keepAllTeamsAssigned,

  teamsList: state.usersAndTeams.teamsList,

  getUsersRequestStatus: state.usersAndTeams.getUsersRequest.status,
  getUsersRequestError: state.usersAndTeams.getUsersRequest.error,
  getUsersRequestMessage: state.usersAndTeams.getUsersRequest.message,

  changeUserRoleRequestStatus: state.usersAndTeams.changeUserRoleRequest.status,
  changeUserRoleRequestError: state.usersAndTeams.changeUserRoleRequest.error,
  changeUserRoleRequestMessage:
    state.usersAndTeams.changeUserRoleRequest.message,

  assignTeamRequestStatus: state.usersAndTeams.assignTeamRequest.status,
  assignTeamRequestError: state.usersAndTeams.assignTeamRequest.error,
  assignTeamRequestMessage: state.usersAndTeams.assignTeamRequest.message,

  addUserRequestStatus: state.usersAndTeams.addUserRequest.status,
  addUserRequestError: state.usersAndTeams.addUserRequest.error,
  addUserRequestMessage: state.usersAndTeams.addUserRequest.message,

  getTeamsListRequestStatus: state.usersAndTeams.getTeamsListRequest.status,
  getTeamsListRequestError: state.usersAndTeams.getTeamsListRequest.error,
  getTeamsListRequestMessage: state.usersAndTeams.getTeamsListRequest.message,

  deleteUserRequestStatus: state.usersAndTeams.deleteUserRequest.status,
  deleteUserRequestError: state.usersAndTeams.deleteUserRequest.error,
  deleteUserRequestMessage: state.usersAndTeams.deleteUserRequest.message,

  disableUserRequestStatus: state.usersAndTeams.disableUserRequest.status,
  disableUserRequestError: state.usersAndTeams.disableUserRequest.error,
  disableUserRequestMessage: state.usersAndTeams.disableUserRequest.message,

  resendInviteRequestStatus: state.usersAndTeams.resendInviteRequest.status,
  resendInviteRequestError: state.usersAndTeams.resendInviteRequest.error,
  resendInviteRequestMessage: state.usersAndTeams.resendInviteRequest.message,

  reactivateUserRequestStatus: state.usersAndTeams.reactivateUserRequest.status,
  reactivateUserRequestError: state.usersAndTeams.reactivateUserRequest.error,
  reactivateUserRequestMessage:
    state.usersAndTeams.reactivateUserRequest.message,

  agencyInviteUserRequestStatus:
    state.usersAndTeams.agencyInviteUserRequest.status,
  agencyInviteUserRequestMessage:
    state.usersAndTeams.agencyInviteUserRequest.message,
  agencyInviteUserRequestError:
    state.usersAndTeams.agencyInviteUserRequest.error,
});

const mapDispatchToProps = {
  sendGetUsersRequest: (filters: UsersFilter) => getUsersRequest(filters),
  resetGetUsersRequest: () => resetGetUsersRequest(),

  sendChangeUserRoleRequest: (payload: ChangeUserRolePayload) =>
    changeUserRoleRequest(payload),
  resetChangeUserRoleRequest: () => resetChangeUserRoleRequest(),

  sendGetTeamsListRequest: () => getTeamsListRequest(),

  sendAssignTeamRequest: (payload: AssignTeamPayload) =>
    assignTeamRequest(payload),
  resetAssignTeamRequest: () => resetAssignTeamRequest(),

  sendDeleteUserRequest: (id: number) => deleteUserRequest(id),
  resetDeleteUserRequest: () => resetDeleteUserRequest(),

  sendDisableUserRequest: (id: number) => disableUserRequest(id),
  resetDisableUserRequest: () => resetDisableUserRequest(),

  sendResendInviteRequest: (id: number) => resendInviteRequest(id),
  resetResendInviteRequest: () => resetResendInviteRequest(),

  sendReactivateUserRequest: (id: number) => reactivateUserRequest(id),
  resetReactivateUserRequest: () => resetReactivateUserRequest(),

  sendGetAssignTeamSettingRequest: (id: number) => getAssignTeamSetting({ id }),

  sendAddUserRequest: (payload: AddUserPayload) => addUserRequest(payload),
  resetAddUserRequest: () => resetAddUserRequest(),

  sendAgencyInviteUserRequest: (payload: AgencyInviteUserRequestPayload) =>
    agencyInviteUserRequest(payload),
  resetAgencyInviteUserRequest: () => resetAgencyInviteUserRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  filters: UsersFilter;
  inviteUserModal: boolean;
  hideInviteUserModal: VoidFunction;
  onFilterChange: (payload: UsersFilter) => void;
  tableRef: any;
  setPremiumFeatureMeta: (payload: {
    show: boolean;
    title: string;
    body: any[];
  }) => void;
};

export default connector(Users);
