import { EmailInboxFilters } from '../type';
import { getDefaultFilters } from './utils/get-default-filters';
import { getIsRequestPending } from '../../../shared/utils/get-request-status';
import { SELECT_ALL_EMAIL_THREADS_LIMIT } from '../enum/unified-inbox.enum';
import { TeamsAndUsersList } from '../../settings/types/users-and-teams';

/* Max Days Range for Filter */
export const MAX_DAYS_RANGE = 90;

/* Function to check whether the filters are modified or not.
It returns true, if the filters has been modified, else return false  */
export const checkIsFilterModified = (filter: EmailInboxFilters) =>
  JSON.stringify(filter) !== JSON.stringify(getDefaultFilters());

/* Function to check is the filters get API has been succeeded or not,
  It will be used to determine, weather to show or hide loading state UI  */
export const getIsFilterLoading = ({
  getEmailCategoriesRequestStatus,
  getEmailAccountsListForFilterRequestStatus,
  getSequencesListForFilterRequestStatus,
  getAgencyClientsDropdownRequestStatus,
  getTeamsAndMembersListRequestStatus,
}) => ({
  agencyClientFilterLoading: getIsRequestPending(
    getAgencyClientsDropdownRequestStatus,
  ),
  sequencesFilterLoading: getIsRequestPending(
    getSequencesListForFilterRequestStatus,
  ),
  emailAccountsFilterLoading: getIsRequestPending(
    getEmailAccountsListForFilterRequestStatus,
  ),
  categoriesFilterLoading: getIsRequestPending(getEmailCategoriesRequestStatus),
  ownersFilterLoading: getIsRequestPending(getTeamsAndMembersListRequestStatus),
});

export const getUnifiedInboxPathName = (hashId: string): string =>
  hashId ? `/conversations/${hashId}` : '/conversations';

export const getTotalSelectedEmailCount = (totalItems: number) =>
  totalItems > SELECT_ALL_EMAIL_THREADS_LIMIT
    ? SELECT_ALL_EMAIL_THREADS_LIMIT
    : totalItems;

export const getAppliedFiltersCount = (
  unifiedInboxFilters: EmailInboxFilters,
): number => {
  const filterKeys: (keyof EmailInboxFilters)[] = [
    'sequences',
    'clients',
    'emailAccounts',
    'outcomes',
    'owners',
    'date',
  ];

  return filterKeys.reduce((count, key) => {
    const filterValue = unifiedInboxFilters[key];
    if (Array.isArray(filterValue) && filterValue.length > 0) {
      count += 1;
    }
    return count;
  }, 0);
};

export const updateUserIdsWithNames = (
  ids: {
    id: number;
    firstName: string;
  }[],
  teams: TeamsAndUsersList[],
) => {
  const idMap = new Map();

  // Populate the idMap with the first matching user names from the teams array
  teams.forEach((team) => {
    team.users.forEach((user) => {
      if (!idMap.has(user.id)) {
        idMap.set(user.id, user.userName);
      }
    });
  });

  // Update the ids array with names from the idMap
  return ids.map((item) => {
    if (idMap.has(item.id)) {
      return {
        ...item,
        firstName: idMap.get(item.id),
      };
    }
    return item;
  });
};
