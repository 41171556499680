export const getUniqueNumbers = (numbers: number[]): number[] => {
  const uniqueNumbers = [];

  numbers.forEach((number) => {
    if (uniqueNumbers.indexOf(number) === -1) {
      uniqueNumbers.push(number);
    }
  });

  return uniqueNumbers;
};
