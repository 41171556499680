/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Icon from '../../../../shared/design-system/components/atoms/icon/icon';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { redirectWithRefresh } from '../../../../shared/utils';
import { SequenceAction } from '../../enums';
import SequenceSingleTitle from './components/sequence-single-title';
import { IProps } from './types';
import { planError } from '../../../../shared/utils/errors/plan-permission-error/plan-error';
import { SubscriptionPlans } from '../../../../shared/utils/subscription-plans';
import { UserRole } from '../../../../shared/enums/user-roles';
import hasResource from '../../../../shared/utils/access-control/has-resource';
import { ResourceIdentifiers } from '../../../../shared/utils/access-control/enums/resource-identifiers';

type IState = {
  showPauseResumeSequenceModal: boolean;
  action: SequenceAction;
  showConnectEmailAccountModal: boolean;
};

class SequenceSingleHeader extends React.Component<IProps, IState> {
  switchRef: React.RefObject<any>;

  constructor(props) {
    super(props);
    this.state = {
      showPauseResumeSequenceModal: false,
      action: null,
      showConnectEmailAccountModal: false,
    };
    this.showPauseResumeSequenceModal = this.showPauseResumeSequenceModal.bind(
      this,
    );
    this.hidePauseResumeSequenceModal = this.hidePauseResumeSequenceModal.bind(
      this,
    );
    this.onSwitchChange = this.onSwitchChange.bind(this);
    this.onPauseResumeSequenceModalSubmit = this.onPauseResumeSequenceModalSubmit.bind(
      this,
    );
    this.onSequenceTitleChange = this.onSequenceTitleChange.bind(this);
    this.skipConnectEmailAccount = this.skipConnectEmailAccount.bind(this);
    this.switchRef = React.createRef();
  }

  componentDidUpdate(prevProps: Readonly<IProps>) {
    const {
      pauseResumeSequenceRequestStatus,
      pauseResumeSequenceRequestError,
      sendGetSequenceRequest,
      sequenceId,
      connectEmailAccountRequestStatus,
      authUrl,
    } = this.props;

    if (
      pauseResumeSequenceRequestStatus !==
      prevProps.pauseResumeSequenceRequestStatus
    ) {
      if (pauseResumeSequenceRequestStatus === RequestStatus.Succeeded) {
        // changing a state when prop changes!!
        this.hidePauseResumeSequenceModal();
        sendGetSequenceRequest(sequenceId);
      }

      if (
        pauseResumeSequenceRequestStatus === RequestStatus.Failed &&
        pauseResumeSequenceRequestError
      ) {
        if (pauseResumeSequenceRequestError.code === 4001) {
          // eslint-disable-next-line react/no-unused-state
          this.setState({ showConnectEmailAccountModal: true });
          this.hidePauseResumeSequenceModal();
        }
      }
    }

    if (
      connectEmailAccountRequestStatus !==
        prevProps.connectEmailAccountRequestStatus &&
      connectEmailAccountRequestStatus === RequestStatus.Succeeded
    ) {
      redirectWithRefresh(authUrl);
    }
  }

  onSequenceTitleChange(title: string) {
    const { sequenceId, sendRenameSequenceRequest } = this.props;
    sendRenameSequenceRequest(sequenceId, title);
  }

  onSwitchChange(checked: boolean) {
    const { subscriptionPlan, role } = this.props;
    if (subscriptionPlan === SubscriptionPlans.FREE) {
      if (role === UserRole.ADMIN) {
        planError(2003);
      } else {
        planError(3003);
      }
    } else {
      const action = checked ? SequenceAction.Resume : SequenceAction.Pause;
      this.showPauseResumeSequenceModal(action);
    }
  }

  onPauseResumeSequenceModalSubmit() {
    const { action } = this.state;
    const { sequenceId, sendPauseResumeSequenceRequest } = this.props;

    hasResource(ResourceIdentifiers.SEQUENCES_PAUSE_RESUME) &&
      sendPauseResumeSequenceRequest(sequenceId, action);
  }

  hidePauseResumeSequenceModal() {
    this.setState({ showPauseResumeSequenceModal: false });
  }

  showPauseResumeSequenceModal(action: SequenceAction) {
    this.setState({ action, showPauseResumeSequenceModal: true });
  }

  skipConnectEmailAccount() {
    this.setState({ showConnectEmailAccountModal: false });
  }

  render() {
    const { title, sequenceId } = this.props;

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <div className="single-sequence-header">
            <Link className="single-sequence-header--title" to="/sequence">
              Sequence
            </Link>
            <div className="single-sequence-header--icon">
              <Icon identifier="chevron-right" />
            </div>
            <SequenceSingleTitle
              title={title}
              onChange={this.onSequenceTitleChange}
              sequenceId={sequenceId}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(SequenceSingleHeader);
