export enum ProductTour {
  CreateSequence = 'p-t__create-sequence',
  AddStep = 'p-t__add-step',
  TemplateButton = 'p-t__template-button',
  FirstTemplate = 'p-t__first-template',
  UseTemplate = 'p-t__use-template',
  SaveStep = 'p-t__save-step',
  SequenceProspectsTab = 'p-t__sequence-prospects-tab',
  AddProspect = 'p-t__add-prospect',
  ImportViaCSV = 'p-t__import-via-csv',
  DownloadSampleCSV = 'p-t__download-sample-csv',
  ChooseFile = 'p-t__choose-file',
  UploadFileNextStep = 'p-t__upload-file-next-step',
  MapFieldsNextStep = 'p-t__map-fields-next-step',
  ReviewAndImport = 'p-t__review-and-import',
  ImportProspectReport = 'p-t__import-prospect-report',
  SequenceSettingsTab = 'p-t__sequence-settings-tab',
  ConnectEmailAccountToSequence = 'p-t__connect-email',
  ConnectNewEmailAccount = 'p-t__connect-new-email',
  SelectEmailService = 'p-t__select-email-service',
  NavigateToSequences = 'p-t__navigate-to-sequences',
  VisitFirstSequence = 'p-t__visit-first-sequence',
  ActiveSequence = 'p-t__active-sequence',
  NavigateToLeadFinder = 'p-t__navigate-to-lead-finder',
}
