import { AxiosResponse } from 'axios';
import { ResponseSuccess } from '../../../shared/types';
import api from '../../../api';
import {
  ReportGraphPayload,
  SelectedSequencesOrUserPayload,
  SequenceReportsStatsPayload,
  SequenceReportsSummaryPayload,
  TeamReportsStatsPayload,
  TeamReportsSummaryPayload,
} from '../types';

// Get Sequence List
export const getSequenceList = async (owners?: number[]) =>
  api.get<ResponseSuccess>('sequences/list-owners', {
    params: { numberOfSteps: 'gt:0', ...(owners.length > 0 && { owners }) },
  });

// Get Selected Sequences
export const getSelectedSequences = async (
  payload: SelectedSequencesOrUserPayload,
) => api.post<ResponseSuccess>('/reports/selected-sequence', payload);

// Get Selected Users
export const getSelectedUsers = async (
  payload: SelectedSequencesOrUserPayload,
) => api.post<ResponseSuccess>('/reports/selected-users', payload);

// Get Sequence Reports Summary
export const getSequenceReportsSummary = async ({
  reportsBy,
  ...payload
}: SequenceReportsSummaryPayload) =>
  api.post<ResponseSuccess>(`reports/${reportsBy}/sequence-summary`, payload);

// Get Team Reports Summary
export const getTeamReportsSummary = async ({
  reportsBy,
  ...payload
}: TeamReportsSummaryPayload) =>
  api.post<ResponseSuccess>(`reports/${reportsBy}/sequence-summary`, payload);

// Get Sequence Reports Stats
export const getSequenceReportsStats = async ({
  reportsBy,
  ...payload
}: SequenceReportsStatsPayload) =>
  api.post<ResponseSuccess>(`reports/${reportsBy}/sequence-stats`, {
    ...payload,
    page: payload.page,
  });

// Get Team Reports Stats
export const getTeamReportsStats = async ({
  reportsBy,
  ...payload
}: TeamReportsStatsPayload) =>
  api.post<ResponseSuccess>(`reports/team/${reportsBy}`, {
    ...payload,
    page: payload.page,
  });

export const exportSequenceReports = async ({
  reportsBy,
  ...payload
}: SequenceReportsSummaryPayload): Promise<AxiosResponse<ResponseSuccess>> =>
  api.post<ResponseSuccess>(
    `reports/sequence-stats/${reportsBy}/export`,
    payload,
  );

export const exportTeamsReports = async ({
  reportsBy,
  ...payload
}: TeamReportsSummaryPayload): Promise<AxiosResponse<ResponseSuccess>> =>
  api.post<ResponseSuccess>(`reports/team/${reportsBy}/export`, payload);

export const exportSequenceConsolidatedReports = async ({
  reportsBy,
  ...payload
}: SequenceReportsSummaryPayload): Promise<AxiosResponse<ResponseSuccess>> =>
  api.post<ResponseSuccess>(
    `reports/sequence-stats/consolidated-export`,
    payload,
  );

export const getGraphData = async ({
  reportsBy,
  ...payload
}: ReportGraphPayload) =>
  api.post<ResponseSuccess>(`reports/${reportsBy}/sequence-analytics`, payload);
