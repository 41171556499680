import React from 'react';
import { AlertTriangle, Check, CloudUpload, Trash } from '@saleshandy/icons';

import ImageIcon from '../../../../../../../../../../../shared/components/images/image-icon';
import { Images } from '../../../../../../../../../../../shared/app-constants';
import { accessibleOnClick } from '../../../../../../../../../../../shared/utils/accessible-on-click';

type IProps = {
  fileName: string;
  onRemove: () => void;
};

const getPostSelectState = (error: boolean) => {
  if (error) {
    return {
      importIcon: <AlertTriangle />,
      importMessage: 'Import failed.',
      actionIcon: <CloudUpload />,
      actionMessage: 'Upload again',
    };
  }
  return {
    importIcon: <Check />,
    importMessage: 'Successfully uploaded.',
    actionIcon: <Trash />,
    actionMessage: 'Remove file',
  };
};

const PostSelect: React.FC<IProps> = ({ fileName, onRemove }) => {
  const {
    importIcon,
    importMessage,
    actionIcon,
    actionMessage,
  } = getPostSelectState(false);

  return (
    <div className="file-selector-input--stage-content post-select-content">
      <div className="icon-wrapper">
        <ImageIcon src={Images.Sheets} />
      </div>

      <div className="filename">{fileName}</div>

      <div className="import-wrapper">
        <div className="import-icon">{importIcon}</div>
        <span className="import-message">{importMessage}</span>
      </div>

      <div className="action-wrapper" {...accessibleOnClick(onRemove)}>
        <div className="action-icon">{actionIcon}</div>
        <span className="action-message">{actionMessage}</span>
      </div>
    </div>
  );
};

export default PostSelect;
