import { isEmpty } from 'lodash';
import validator from 'validator';
import {
  DoNotContactList,
  DoNotContactListMeta,
  ModalActions,
} from '../../../../../types/do-not-contact-list';
import { DoNoContactListApplicableTo } from '../../../../../enums/do-not-contact-list';
import { AddModifyStateValues } from './types';
import { AgencyClientFilter } from '../../../../../../agency-client-management/types/types';

export const validate = (name: string, value: string): string => {
  switch (name) {
    case 'dncListName':
      if (validator.isEmpty(value)) {
        return 'List name is required.';
      }

      if (!validator.isLength(value, { min: 1, max: 100 })) {
        return 'Should be between 1-100 characters.';
      }

      return '';

    default:
      break;
  }

  return '';
};

const isNameChanged = (stateValue: string, defaultValue: string) =>
  stateValue !== defaultValue;

const isGlobalList = (defaultList: DoNotContactList | DoNotContactListMeta) =>
  isEmpty(defaultList.client);

const isApplicableToChanged = (applicableTo: DoNoContactListApplicableTo) =>
  applicableTo === DoNoContactListApplicableTo.AllClients;

const isClientChanged = (stateClientId: number, defaultClientId: number) =>
  stateClientId !== defaultClientId;

export const shouldSaveButtonDisabled = (
  action: ModalActions,
  values: AddModifyStateValues,
  defaultDoNotContactList: DoNotContactList | DoNotContactListMeta,
  selectedClient: AgencyClientFilter,
): boolean => {
  if (action === 'modify' && defaultDoNotContactList) {
    /**
     * list name value in default list object is 'XYZ',
     * but list name value in state variable is 'ABC',
     * then the list name value is changed,
     * so Save button should not be disabled.
     */
    if (isNameChanged(values.dncListName, defaultDoNotContactList.name)) {
      return false;
    }

    /**
     * list applicable value in default list object is 'All Clients',
     * but list applicable value in state variable is 'Specific Clients',
     * then the list applicable value is changed,
     * so Save button should not be disabled.
     */
    if (isGlobalList(defaultDoNotContactList)) {
      return !(
        values.applicableTo === DoNoContactListApplicableTo.SpecificClient
      );
    }

    /**
     * list applicable value in default list object is 'Specific Clients',
     * but list applicable value in state variable is 'All Clients',
     * then the list applicable value is changed,
     * so Save button should not be disabled.
     */
    if (isApplicableToChanged(values.applicableTo)) {
      return false;
    }

    /**
     * list applicable value in default list object & state variable is 'Specific Clients',
     * but client id value in default list object is 101,
     * and client id value in state variable is 201,
     * then the client id value is changed,
     * so Save button should not be disabled.
     */
    if (
      isClientChanged(selectedClient?.id, defaultDoNotContactList.client?.id)
    ) {
      return false;
    }

    return true;
  }

  return false;
};
