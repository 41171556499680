/* eslint-disable react/destructuring-assignment */
import React, { memo } from 'react';
import Select, { components } from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import makeAnimated from 'react-select/animated';
import { SelectFilterProps } from './type';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const animatedComponents = makeAnimated();

const Option = (props) => (
  <components.Option {...props} className="d-flex align-items-center">
    <div className="d-flex align-items-center pointer">
      {props?.isMultiSelect && (
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
      )}{' '}
      <span className="ml-2 regular-2 popover-arrow-color-txt">
        {props.children}
      </span>
    </div>
  </components.Option>
);

/**
 * Styles to pass in `react-select`
 */
const textStyle = {
  fontSize: '14px',
  fontStyle: 'normal',
  lineHeight: '20px',
  color: '#6b7280',
};

const SelectFilter: React.FC<SelectFilterProps> = ({
  placeholder,
  options,
  labelKey = 'name',
  onSelect,
  uniqueKey = 'id',
  values = [],
  placement = 'bottom',
  isMultiSelect,
}) => {
  const getOptionLabel = (option) => option[labelKey];
  const getOptionValue = (option) => option[uniqueKey];

  const customStyles = {
    control: (base) => ({
      ...base,
      border: '1px solid #E5E7EB',
    }),
    placeholder: (base) => ({
      ...base,
      ...textStyle,
    }),
    option: (base, state) => ({
      ...base,
      ...textStyle,
      color: '#1F2937',
      wordWrap: 'break-word',
      backgroundColor: state.isSelected && '#EFF6FF',
      '&:active': {
        backgroundColor: '#EFF6FF',
      },
    }),
    multiValue: (base) => ({
      ...base,
      marginRight: '8px',
      borderRadius: '4px',
      border: '1px solid #D1D5DB',
      backgroundColor: '#FFF',
    }),
    multiValueLabel: (base) => ({
      ...base,
      ...textStyle,
      fontWeight: 500,
      color: '#1F2937',
    }),
    multiValueRemove: (base) => ({
      ...base,
      marginRight: '0px',
      color: '#6B7280',
      '&:hover': {
        backgroundColor: '#dbeafe',
        color: '#1d4ed8',
      },
    }),
  };

  return (
    <Select
      value={values}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      name="colors"
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      components={{
        Option: (props) => <Option isMultiSelect={isMultiSelect} {...props} />,
      }}
      isOptionSelected={(option) =>
        values.filter((op) => op[uniqueKey] === option[uniqueKey]).length > 0
      }
      onChange={onSelect}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder}
      menuPlacement={placement}
      styles={customStyles}
    />
  );
};

export default memo(SelectFilter);
