export const generateRandomNumber = (a: number, b: number): number => {
  return a + Math.floor(Math.random() * (b - a + 1));
};

export const generateRandomNumberOfLength = (length: number): number => {
  return generateRandomNumber(
    Math.pow(10, length - 1),
    Math.pow(10, length) - 1,
  );
};

export const generateRandomString = (length: number): string => {
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';

  for (let i = length; i > 0; i--) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }

  return result;
};

export type GenerateIdArgs = {
  prefix: string;
  variableLength?: number;
};

export const generateId = ({ prefix, variableLength = 10 }: GenerateIdArgs) =>
  `${prefix}-${generateRandomString(variableLength)}`;

export const truncate = (
  str: string,
  n: number,
  useWordBoundary = true,
): string => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1);
  return (
    (useWordBoundary
      ? subString.substr(0, subString.lastIndexOf(' '))
      : subString) + '...'
  );
};

export const capitalize = (str = '') =>
  str.charAt(0).toUpperCase() + str.slice(1);
