export const getSequenceReportCellData = (value) => {
  if (value && value !== null) {
    return value;
  }
  return 0;
};
export const getSequenceReportCellDataWithPercentage = (value) => {
  if (value && value !== null) {
    return `(${value}%)`;
  }
  return '(0%)';
};

export const getSequenceReportCellDataWithDealValue = (
  value,
  showZeroDealValue = false,
) => {
  if (value && value !== null) {
    return `$${value}`;
  }
  if (Number(value) === 0 && showZeroDealValue) {
    return `$0`;
  }
  return '';
};

export const getSelectedColumns = (key: string) =>
  JSON.parse(localStorage.getItem(key));

export const setSelectedColumns = (key: string, cols: number[]) =>
  localStorage.setItem(key, JSON.stringify(cols));

const sequenceReportsFilter = 'sequence-reports-filter';
const teamPerformanceFilters = 'team-performance-filters';

export const getSequenceReportsFilters = () => {
  const filters = localStorage.getItem(sequenceReportsFilter);

  if (filters) {
    return JSON.parse(filters);
  }

  return null;
};
export const setSequenceReportsFiltersToLocalStore = (filters) =>
  localStorage.setItem(sequenceReportsFilter, JSON.stringify(filters));
export const removeSequenceReportsFilters = () =>
  localStorage.removeItem(sequenceReportsFilter);

export const getTeamPerformanceFilters = () => {
  const filters = localStorage.getItem(teamPerformanceFilters);

  if (filters) {
    return JSON.parse(filters);
  }

  return null;
};

export const setTeamPerformanceFilters = (filters) =>
  localStorage.setItem(teamPerformanceFilters, JSON.stringify(filters));

export const removeTeamPerformanceFilters = () =>
  localStorage.removeItem(teamPerformanceFilters);
