import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  deleteCustomOutcomeRequest,
  getCustomOutcomesWithPaginationRequest,
  createCustomOutcomeRequest,
  updateCustomOutcomeRequest,
  getCustomOutcomeRequest,
  resetProspectOutcomeData,
} from './custom-outcome-slice';
import {
  CreateOutcomeRequestPayload,
  UpdateOutcomeRequestPayload,
} from '../../types/request-payload';
import CustomOutcome from './custom-outcome';
import { PaginationQueryParams } from '../../../../shared/types/request';
import { getDefaultOutcomesWithPaginationRequest } from './extra-actions';

const mapStateToProps = (state: RootState) => ({
  getCustomOutcomeRequestStatus:
    state.customOutcome.getCustomOutcomesWithPaginationRequest.status,

  getCustomOutcomeRequestMessage:
    state.customOutcome.getCustomOutcomesWithPaginationRequest.message,
  getCustomOutcomeRequestError:
    state.customOutcome.getCustomOutcomesWithPaginationRequest.error,

  getDefaultOutcomeRequestStatus:
    state.customOutcome.getDefaultOutcomesWithPaginationRequest.status,

  getDefaultOutcomeRequestMessage:
    state.customOutcome.getDefaultOutcomesWithPaginationRequest.message,
  getDefaultOutcomeRequestError:
    state.customOutcome.getDefaultOutcomesWithPaginationRequest.error,

  createCustomOutcomeRequestStatus:
    state.customOutcome.createCustomOutcomeRequest.status,
  createCustomOutcomeRequestMessage:
    state.customOutcome.createCustomOutcomeRequest.message,
  createCustomOutcomeRequestError:
    state.customOutcome.createCustomOutcomeRequest.error,

  updateCustomOutcomeRequestStatus:
    state.customOutcome.updateCustomOutcomeRequest.status,
  updateCustomOutcomeRequestMessage:
    state.customOutcome.updateCustomOutcomeRequest.message,
  updateCustomOutcomeRequestError:
    state.customOutcome.updateCustomOutcomeRequest.error,

  deleteCustomOutcomeRequestStatus:
    state.customOutcome.deleteCustomOutcomeRequest.status,
  deleteCustomOutcomeRequestMessage:
    state.customOutcome.deleteCustomOutcomeRequest.message,
  deleteCustomOutcomeRequestError:
    state.customOutcome.deleteCustomOutcomeRequest.error,

  getAllCustomOutcomeRequestStatus:
    state.customOutcome.getCustomOutcomeRequest.status,
  getAllCustomOutcomeRequestMessage:
    state.customOutcome.getCustomOutcomeRequest.message,
  getAllCustomOutcomeRequestError:
    state.customOutcome.getCustomOutcomeRequest.error,

  outcomes: state.customOutcome.outcomes,
  customOutcome: state.customOutcome.customOutcome,
  defaultOutcome: state.customOutcome.defaultOutcome,
  defaultOutcomePagination: {
    ...state.customOutcome.defaultOutcomePagination,
    pagesCount: null,
    options: {
      ...state.customOutcome.defaultOutcomePagination.options,
      totalElements: state.customOutcome.defaultOutcomePagination.count,
    },
  },
  customOutcomePagination: {
    ...state.customOutcome.customOutcomePagination,
    pagesCount: null,
    options: {
      ...state.customOutcome.customOutcomePagination.options,
      totalElements: state.customOutcome.customOutcomePagination.count,
    },
  },
  isLoading: state.customOutcome.isLoading,

  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
});

const mapDispatchToProps = {
  sendGetCustomOutcomeRequest: (
    params: PaginationQueryParams & { search?: string },
  ) => getCustomOutcomesWithPaginationRequest(params),
  sendGetDefaultOutcomeRequest: (
    params: PaginationQueryParams & { search?: string },
  ) => getDefaultOutcomesWithPaginationRequest(params),

  sendCreateCustomOutcomeRequest: (payload: CreateOutcomeRequestPayload) =>
    createCustomOutcomeRequest(payload),

  sendUpdateCustomOutcomeRequest: (
    customOutcomeId: number,
    payload: UpdateOutcomeRequestPayload,
  ) =>
    updateCustomOutcomeRequest({
      customOutcomeId,
      label: payload.label,
      sentiment: payload.sentiment,
    }),

  sendDeleteCustomOutcomeRequest: (
    customOutcomeId: number,
    newOutcomeId: number,
  ) => deleteCustomOutcomeRequest({ customOutcomeId, newOutcomeId }),

  sendGetAllOutcomesRequest: () => getCustomOutcomeRequest(),
  resetProspectOutcomeData: () => resetProspectOutcomeData(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    t: (x: string) => React.ReactNode;
  };

export default connector(CustomOutcome);
