import React from 'react';
import ClickedWithBackground from '../components/images/clicked-with-background';
import OpenedWithBackground from '../components/images/opened-with-background';
import PausedWithBackground from '../components/images/paused-with-background';
import ProspectActivityEmailBouncedColored from '../components/images/prospect-activity-email-bounced-colored';
import ProspectActivityEmailFailedColored from '../components/images/prospect-activity-email-failed-colored';
import ProspectActivityEmailOpenedColored from '../components/images/prospect-activity-email-opened-colored';
import ProspectActivityEmailReceivedColored from '../components/images/prospect-activity-email-reply-colored';
import ProspectActivityEmailSent from '../components/images/prospect-activity-email-sent';
import ProspectActivityEmailSentColored from '../components/images/prospect-activity-email-sent-colored';
import ProspectActivityEmailUnsubscribedColored from '../components/images/prospect-activity-email-unsubscribed-colored';
import ProspectActivityLinkClickedColored from '../components/images/prospect-activity-link-clicked-colored';
import ProspectBlacklistedColored from '../components/images/prospect-blacklisted-colored';
import ProspectRemovedFromBlacklistColored from '../components/images/prospect-removed-from-blacklist-colored';
import ResumeWithBackground from '../components/images/resume-with-background';
import MasterLog from '../enums/master-log-enum';
import { Exchange } from '@saleshandy/icons';

// eslint-disable-next-line import/prefer-default-export
export const getActivityIcon = (key: MasterLog) => {
  switch (key) {
    case MasterLog.EmailBounced:
      return <ProspectActivityEmailBouncedColored />;
    case MasterLog.EmailFailed:
      return <ProspectActivityEmailFailedColored />;
    case MasterLog.EmailOpened:
      return <ProspectActivityEmailOpenedColored />;
    case MasterLog.EmailSent:
      return <ProspectActivityEmailSentColored />;
    case MasterLog.LinkClicked:
      return <ProspectActivityLinkClickedColored />;
    case MasterLog.ReplyReceivedAuto:
    case MasterLog.ReplyReceivedManual:
      return <ProspectActivityEmailReceivedColored />;
    case MasterLog.UnsubscribedAuto:
    case MasterLog.UnsubscribedManual:
      return <ProspectActivityEmailUnsubscribedColored />;
    case MasterLog.Blacklisted:
      return <ProspectBlacklistedColored />;
    case MasterLog.RemovedFromBlacklist:
      return <ProspectRemovedFromBlacklistColored />;
    case MasterLog.MailboxEmailOpened:
      return <OpenedWithBackground />;
    case MasterLog.MailboxEmailLinkClicked:
      return <ClickedWithBackground />;
    case MasterLog.SequenceContactPaused:
      return <PausedWithBackground />;
    case MasterLog.SequenceContactResumed:
      return <ResumeWithBackground />;
    case MasterLog.UniboxEmailOpened:
      return <OpenedWithBackground />;
    case MasterLog.UniboxEmailLinkClicked:
      return <ClickedWithBackground />;
    case MasterLog.PrimaryEmailChangedAuto:
    case MasterLog.PrimaryEmailChangedManual:
      return <Exchange />;
    default:
      return <ProspectActivityEmailSent />;
  }
};
