import React, { useState } from 'react';
import SearchInput from '../../../../../../../shared/design-system/components/atoms/search-input';
import { IconPlace } from '../../../../../../../shared/design-system/components/input/enums';
import { DoNotContactListDetailsFilter } from '../../../../../types/do-not-contact-list';

type IProps = {
  filters: DoNotContactListDetailsFilter;
  onFiltersChange: (
    payload: Partial<DoNotContactListDetailsFilter>,
    resetSelection: boolean,
  ) => void;
};

const DoNotContactListDetailsSearch: React.FC<IProps> = ({
  filters,
  onFiltersChange,
}) => {
  const [search, setSearch] = useState(filters.search || '');

  const onSearch = () => {
    onFiltersChange({ search, page: 1 }, true);
  };

  const onClearSearch = () => {
    setSearch('');
    onFiltersChange({ search: '', page: 1 }, true);
  };

  return (
    <SearchInput
      searchValue={search}
      setSearchValue={setSearch}
      onSearch={onSearch}
      onClearSearch={onClearSearch}
      searchIconPlace={IconPlace.Left}
      searchIconFixed
      placeholder="Search by domain or email"
      className="dnc-search-input lg"
    />
  );
};

export default DoNotContactListDetailsSearch;
