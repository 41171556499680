import React, { useState, useEffect } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  Alarm,
  Check,
  Checkbox,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Copy,
  Cross,
  ForwardStep,
  Linkedin,
  Mail,
  Note,
  Send,
  Signal,
  TwitterBw,
  User,
  Whatsapp,
  World,
} from '@saleshandy/icons';
import { Accordion, Button } from '@saleshandy/design-system';
import type { IProps } from './tasker-modal-container';
import { SequenceStepType } from '../../../sequence/enums';
import {
  getCallOutcomeOptions,
  isLinkedInType,
} from '../tasks-content/utils/helper';
import { stepBgColor } from '../../../sequence/helpers/step-types';
import { Priority } from '../../../../shared/components/priority';
import { getStepPriority } from '../../../sequence/helpers/sequence-step';
import {
  getButtonLabel,
  getProspectDetails,
  getTaskContentLabel,
  removeLinkedinPrefix,
  stepContentMaxLength,
} from './utils/helper';
import TruncatedTextWithTooltip from '../../../../shared/components/truncated-text-with-tooltip';
import Select from '../../../../shared/design-system/components/select';
import { getTaskStatusParams } from '../../utils/helper';
import { getSubjectStr } from '../../../sequence/components/sequence-single-content/components/sequence-single-step-variant-item/helper';
import { TaskAction, TaskStatusKey } from '../../utils/enums';
import { accessibleOnClick } from '../../../../shared/utils/accessible-on-click';
import {
  executeOnRequestStatus,
  getFormattedDateWithMoment,
  getIsRequestPending,
} from '../../../../shared/utils';
import { getStepType } from '../../../sequence/shared/modals/create-step-modal/utils/helper';
import Spinner from '../../../../shared/design-system/components/atoms/custom-spinner';
import toaster, { Theme } from '../../../../shared/toaster';
import GenericCustomOutcomeDropdown from '../../../settings/components/custom-outcomes/components/custom-outcome-dropdown';
import {
  getOptionFromOutcome,
  mapOutcomeToOption,
} from '../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/prospect-outcome-select/utils/helper';
import { getOutcomeIcon } from '../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/components/helpers/step-filter-mapper';
import {
  OverlayTooltip,
  Placement,
} from '../../../../shared/design-system/components/overlay';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';

const getTaskIcon = (type: SequenceStepType) => {
  if (isLinkedInType(type)) {
    return <Linkedin width={20} height={20} className="gray-txt-1" />;
  }
  if (type === SequenceStepType.Whatsapp) {
    return <Whatsapp width={20} height={20} className="gray-txt-1" />;
  }
  if (type === SequenceStepType.Call) {
    return <Signal width={20} height={20} className="gray-txt-1" />;
  }
  if (type === SequenceStepType.Email) {
    return <Mail width={20} height={20} className="gray-txt-1" />;
  }

  return <Checkbox width={20} height={20} className="gray-txt-1" />;
};

const TaskerModal: React.FC<IProps> = ({
  show,
  taskStatus,
  tasksCount,
  taskerData,
  paginationOptions,
  taskId,
  allOutcomes,
  getTaskerDataRequestStatus,
  outcomeChangeRequestStatus,
  updateTaskNoteRequestStatus,
  filters,
  onHide,
  onTaskNoteClick,
  getTaskerDataRequest,
  updateTasks,
  onOutcomeChange,
  sendGetAllOutcomesRequest,
  resetTaskerState,
  resetTaskId,
  onRefresh,
}) => {
  const getInitialTaskNumber = (index = null): number => {
    if (paginationOptions.pageNumber > 1) {
      return (
        paginationOptions.pageLimit * (paginationOptions.pageNumber - 1) +
        1 +
        index
      );
    }

    return index + 1 || 1;
  };

  //! State
  const [callOutcome, setCallOutcome] = useState<{
    key: string;
    label: string;
  }>(null);
  const [taskerDataIndex, setTaskerDataIndex] = useState(0);
  const [currentTaskNumber, setCurrentTaskNumber] = useState(
    getInitialTaskNumber(),
  );

  const [currentData, setCurrentData] = useState(taskerData?.[0]);
  const [currentPage, setCurrentPage] = useState(paginationOptions.pageNumber);
  const [isContentCopied, setIsContentCopied] = useState(false);

  //! Helper Functions
  const getTaskerDataIndex = (): number => {
    if (currentPage === 1) {
      return tasksCount - 1;
    }

    return paginationOptions.pageLimit - 1;
  };
  const getTooltip = (text: string, exterClass?: string) => (
    <Tooltip id="popover-basic" className={`bs-tooltip-inner ${exterClass}`}>
      {text}
    </Tooltip>
  );
  const onContentCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsContentCopied(true);

    setTimeout(() => {
      setIsContentCopied(false);
    }, 2000);
  };
  const resetState = () => {
    setTaskerDataIndex(0);
    setCurrentTaskNumber(1);
    setCurrentData(taskerData[0]);
    setCurrentPage(paginationOptions.pageNumber);
  };
  const closeModal = () => {
    resetState();
    onHide();
  };

  const handleOutcomeChange = (option) => {
    setCallOutcome(option);
    onOutcomeChange(option);
  };

  const apiCall = (pageNumber = currentPage) => {
    getTaskerDataRequest({
      status: getTaskStatusParams(taskStatus),
      pageLimit: paginationOptions.pageLimit,
      pageNumber,
      ...filters,
    });
  };
  const openLink = (url: string) => window.open(url, '_blank');

  const getTaskContent = (): string => {
    const contentText =
      currentData?.taskPayload?.payload?.connectionNote ||
      currentData?.taskPayload?.payload?.message ||
      currentData?.taskPayload?.payload?.content;

    return contentText?.length ? currentData?.taskPayload?.payload : null;
  };
  const getTaskInstruction = (): string => currentData?.instruction || null;

  const handleUpdateTask = (tAction: TaskAction) => {
    updateTasks(currentData?.id, tAction, currentData?.type, {
      taskPayloads: [getTaskContent()],
      instructions: [getTaskInstruction()],
    });
  };

  const sendLinkedinRequest = () => {
    const prospectDetails = getProspectDetails(
      currentData?.prospect?.attributes,
    );

    if (!prospectDetails?.LinkedIn) {
      return toaster.error('LinkedIn profile not found', { theme: Theme.New });
    }

    return window.open(prospectDetails?.LinkedIn, '_blank');
  };

  const onNextPage = () => {
    setCurrentPage((prev) => prev + 1);
    setTaskerDataIndex(0);
    setCurrentTaskNumber((prev) => prev + 1);
    resetTaskId();
    apiCall(currentPage + 1);
  };
  const onPrevPage = () => {
    setCurrentPage((prev) => prev - 1);
    setTaskerDataIndex(getTaskerDataIndex());
    setCurrentTaskNumber((prev) => prev - 1);
    resetTaskId();
    apiCall(currentPage - 1);
  };

  const onNextTask = () => {
    if (taskerDataIndex === tasksCount - 1) {
      return;
    }

    if (taskerDataIndex === taskerData?.length - 1) {
      onNextPage();
      return;
    }

    setCurrentTaskNumber((prev) => prev + 1);
    setTaskerDataIndex((prev) => prev + 1);
    setCurrentData(taskerData[taskerDataIndex + 1]);
  };

  const onPrevTask = () => {
    if (currentTaskNumber === 1) {
      return;
    }

    if (taskerDataIndex === 0 && currentPage > 1) {
      onPrevPage();
      return;
    }

    setCurrentTaskNumber((prev) => prev - 1);
    setTaskerDataIndex((prev) => prev - 1);
    setCurrentData(taskerData[taskerDataIndex - 1]);
  };

  //! UseEffect
  useEffect(() => {
    apiCall();
    sendGetAllOutcomesRequest();
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: outcomeChangeRequestStatus,
      onSuccess: () => {
        apiCall();
      },
    });
  }, [outcomeChangeRequestStatus]);
  useEffect(() => {
    executeOnRequestStatus({
      status: updateTaskNoteRequestStatus,
      onSuccess: () => {
        apiCall();
      },
    });
  }, [updateTaskNoteRequestStatus]);

  useEffect(() => {
    if (getIsRequestPending(getTaskerDataRequestStatus)) {
      return;
    }

    if (!tasksCount) {
      closeModal();
    }

    //* If last task get removed */
    if (
      taskerData.length === taskerDataIndex &&
      tasksCount > currentTaskNumber &&
      taskerData.length > 1
    ) {
      onNextPage();
      return;
    }

    //* If first task from > 1 page get removed */
    if (taskerData.length === taskerDataIndex) {
      onPrevTask();
    }

    setCurrentData(taskerData[taskerDataIndex]);
  }, [tasksCount]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getTaskerDataRequestStatus,
      onSuccess: () => {
        resetTaskerState();

        if (taskId) {
          const index = taskerData?.findIndex((task) => task.id === taskId);

          if (index === -1) {
            onRefresh();
            closeModal();
            return;
          }

          setTaskerDataIndex(index);
          setCurrentTaskNumber(getInitialTaskNumber(index));
          setCurrentData(taskerData[index]);
          return;
        }

        setCurrentData(taskerData[taskerDataIndex]);
      },
    });
  }, [getTaskerDataRequestStatus]);

  //! Render Functions
  const renderTaskIcon = () => (
    <div
      style={{
        backgroundColor: stepBgColor[getStepType(currentData?.type)],
        width: '40px',
        height: '40px',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {getTaskIcon(currentData?.type)}
    </div>
  );
  const renderTaskerPagination = () => (
    <div className="tasker-pagination-wrapper d-flex justify-content-between">
      <ChevronLeft
        width={20}
        height={20}
        className={`popover-arrow-color-txt ${
          currentTaskNumber === 1 ? 'cursor-not-allowed' : 'pointer'
        }`}
        onClick={onPrevTask}
      />
      <span className="regular-2 font-medium gray-txt-15">
        {currentTaskNumber} of {tasksCount} Tasks
      </span>
      <ChevronRight
        width={20}
        height={20}
        className={`popover-arrow-color-txt ${
          taskerDataIndex === tasksCount - 1 ? 'cursor-not-allowed' : 'pointer'
        }`}
        onClick={onNextTask}
      />
    </div>
  );
  const renderTaskPriority = () => {
    const { text, variant, className } = getStepPriority(currentData?.priority);

    return <Priority text={text} variant={variant} className={className} />;
  };
  const renderProspectDetails = () => {
    const prospectDetails = getProspectDetails(
      currentData?.prospect?.attributes,
    );

    return (
      <div className="position-relative d-flex align-items-center">
        <div className="pl-4 pr-4">
          <div className="d-flex align-items-center">
            <User width={16} height={16} className="gray-txt-15" />
            <span className="regular-2 popover-arrow-color-txt line-height-20 ml-2">
              {prospectDetails.Name}
            </span>
            {prospectDetails.LinkedIn && (
              <Linkedin
                width={16}
                height={16}
                className="gray-txt-15 ml-2 pointer"
                onClick={() => openLink(prospectDetails.LinkedIn)}
              />
            )}
            {prospectDetails.Twitter && (
              <TwitterBw
                width={16}
                height={16}
                className="gray-txt-15 ml-2 pointer"
                onClick={() => openLink(prospectDetails.Twitter)}
              />
            )}
            {prospectDetails.Website && (
              <World
                width={16}
                height={16}
                className="gray-txt-15 ml-2 pointer"
                onClick={() => openLink(prospectDetails.Website)}
              />
            )}
          </div>

          {prospectDetails.Email && (
            <div className="d-flex align-items-center mt-2">
              <Mail width={16} height={16} className="gray-txt-15" />
              <span className="regular-2 popover-arrow-color-txt line-height-20 ml-2">
                {prospectDetails.Email}
              </span>
            </div>
          )}

          <div className="d-flex align-items-center mt-2">
            <Send width={16} height={16} className="gray-txt-15" />
            <span className="regular-2 gray-txt-15 line-height-20 ml-2">
              Step {currentData?.sequenceProspectTask?.step?.number}
            </span>
            <TruncatedTextWithTooltip
              string={currentData?.sequenceProspectTask?.step?.sequence?.title}
              length={45}
              textClassName="regular-2 font-medium popover-arrow-color-txt ml-2"
            />
          </div>

          <div className="mt-2">
            <GenericCustomOutcomeDropdown
              showFooter={false}
              options={mapOutcomeToOption(allOutcomes)}
              showTotal={false}
              className="prospect-outcome-select"
              changeOutcomeRequestPayload={{
                sequenceId: currentData?.sequenceProspectTask?.step?.sequenceId,
                prospectId: currentData?.prospect?.id,
              }}
              initialSelectedOption={getOptionFromOutcome(
                allOutcomes,
                currentData?.sequenceProspectTask?.sequenceProspect?.categoryId,
              )}
              optionRenderer={(option) => (
                <div className="d-flex align-items-center">
                  {getOutcomeIcon(option?.name, false, {
                    isDefault: option.isDefault,
                    sentiment: option.sentiment,
                  })}
                  <span className="ml-1">{option.name}</span>
                </div>
              )}
              disabled={!hasPermission(Permissions.UNIBOX_CATEGORY_UPDATE)}
            />
          </div>
        </div>

        <div className="task-note__wrapper">
          <div
            className="task-note__wrapper-icon pointer"
            {...accessibleOnClick(() =>
              onTaskNoteClick(currentData?.id, currentData?.note),
            )}
          >
            <Note width={16} height={16} className="orange-txt-14" />
            <span className="regular-2 font-medium orange-txt-14 ml-1">
              Task Note
            </span>
          </div>
        </div>
      </div>
    );
  };
  const renderTaskInstruction = () => (
    <>
      <div className="cs-accordion blue-17">
        <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={['a']}>
          <Accordion.Item uuid="a">
            <Accordion.ItemHeading>
              <Accordion.ItemButton>
                <span className="semibold-1 gray-txt-15">Task Instruction</span>
                <Accordion.ItemState>
                  {({ expanded }) =>
                    expanded ? <ChevronUp /> : <ChevronDown />
                  }
                </Accordion.ItemState>
              </Accordion.ItemButton>
            </Accordion.ItemHeading>
            <Accordion.ItemPanel>
              <span className="regular-2 popover-arrow-color-txt">
                {currentData?.instruction}
              </span>
            </Accordion.ItemPanel>
          </Accordion.Item>
        </Accordion>
      </div>

      <div className="cs-acc-bottom" />
    </>
  );
  const renderTaskContent = () => {
    const contentText =
      currentData?.taskPayload?.payload?.connectionNote ||
      currentData?.taskPayload?.payload?.message ||
      currentData?.taskPayload?.payload?.content;

    if (!contentText?.length) {
      return null;
    }

    return (
      <div className="task__content bs-mb-20 bs-mt-20">
        {currentData?.type === SequenceStepType.LinkedInInMail && (
          <div className="bs-mb-20">
            <span className="semibold-1 gray-txt-15">
              {getTaskContentLabel(currentData?.type)}
            </span>
            <div className="task__content-area size-sm mt-1 d-flex align-items-center justify-content-between">
              <div className="cs-txt-ell">
                <span className="regular-2 popover-arrow-color-txt">
                  {currentData?.taskPayload?.payload?.subject}
                </span>
              </div>

              <div className="d-flex align-items-center justify-content-end">
                <OverlayTrigger
                  placement={Placement.Bottom}
                  overlay={getTooltip(!isContentCopied ? 'Copy' : 'Copied')}
                >
                  <Copy
                    width={16}
                    height={16}
                    className="gray-txt-15 pointer"
                    onClick={() =>
                      onContentCopy(currentData?.taskPayload?.payload?.subject)
                    }
                  />
                </OverlayTrigger>

                <span className="regular-1 gray-txt-11 ml-1">
                  {currentData?.taskPayload?.payload?.subject?.length}/{200}
                </span>
              </div>
            </div>
          </div>
        )}

        <>
          <span className="semibold-1 gray-txt-15">
            {getTaskContentLabel(currentData?.type)}
          </span>
          <div className="task__content-area mt-1 d-flex flex-column justify-content-between">
            <div className="">
              <span className="regular-2 popover-arrow-color-txt">
                {contentText}
              </span>
            </div>

            <div className="d-flex align-items-center justify-content-end">
              <OverlayTrigger
                placement={Placement.Bottom}
                overlay={getTooltip(!isContentCopied ? 'Copy' : 'Copied')}
              >
                <Copy
                  width={16}
                  height={16}
                  className="gray-txt-15 pointer"
                  onClick={() => onContentCopy(contentText)}
                />
              </OverlayTrigger>
              <span className="regular-1 gray-txt-11 ml-1">
                {contentText?.length}/{stepContentMaxLength[currentData?.type]}
              </span>
            </div>
          </div>
        </>
      </div>
    );
  };
  const renderCallOutcomeSelect = () => (
    <div className="pl-4 pr-4 mb-4">
      <span className="semibold-1 gray-txt-15">Call Outcome</span>
      <Select<{ key: string; label: string }>
        options={getCallOutcomeOptions}
        placeholder="Select call outcome"
        selectedOptionKey={callOutcome?.key}
        onChange={([option]) => handleOutcomeChange(option)}
        optionRenderer={(option) => <span>{option?.label}</span>}
        selectedOptionRenderer={([option]) => <span>{option?.label}</span>}
        placement={Placement.Top}
      />
    </div>
  );
  const getDateColumnCellValue = (tStatus: TaskStatusKey, date: string) => {
    if (tStatus === TaskStatusKey.DueToday) {
      return 'Due Today';
    }
    if (tStatus === TaskStatusKey.Upcoming) {
      return `Due in ${getFormattedDateWithMoment(date)}`;
    }
    if (tStatus === TaskStatusKey.Overdue) {
      return `Due ${getFormattedDateWithMoment(date)} ago`;
    }

    return `${getFormattedDateWithMoment(date)}
        ${getFormattedDateWithMoment(date).toLowerCase() !== 'today' && 'ago'}`;
  };

  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      className="tasker-modal prospects-filters-modal"
      backdrop="static"
      enforceFocus={false}
    >
      {getIsRequestPending(getTaskerDataRequestStatus) ? (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <Spinner />
        </div>
      ) : (
        <>
          <Modal.Header>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                {renderTaskIcon()}
                <div className="d-flex flex-column bs-ml-12">
                  <TruncatedTextWithTooltip
                    length={25}
                    string={removeLinkedinPrefix(
                      getSubjectStr(
                        currentData?.type,
                        currentData?.taskPayload?.payload?.title,
                        currentData?.taskPayload?.payload?.purpose,
                      ),
                      currentData?.type,
                    )}
                    textClassName="semibold-2 popover-arrow-color-txt"
                  />
                  <div className="mt-1 d-flex align-items-center">
                    <span className="regular-1 font-medium gray-txt-15 mr-2">
                      {getDateColumnCellValue(taskStatus, currentData?.dueAt)}
                    </span>
                    {renderTaskPriority()}
                  </div>
                </div>
              </div>

              <div className="">{renderTaskerPagination()}</div>
            </div>
            <Button className="close" onClick={closeModal}>
              <Cross />
            </Button>
          </Modal.Header>
          <Modal.Body>
            {/* If LikedIn URL not Found */}
            {isLinkedInType(currentData?.type) &&
              !getProspectDetails(currentData?.prospect?.attributes)
                ?.LinkedIn && (
                <div className="d-flex justify-content-center align-items-center url-banner">
                  <span className="semibold-1 line-height-18 red-txt-12 text-center">
                    LinkedIn URL is invalid / not available
                  </span>
                </div>
              )}

            {/* Prospect Details Section */}
            <div className="mt-3">{renderProspectDetails()}</div>

            {/* Task Instruction */}
            {currentData?.instruction && (
              <div className="bs-mt-20">{renderTaskInstruction()}</div>
            )}

            {/* Task Content */}
            {renderTaskContent()}

            {/* Call Task Outcome */}
            {currentData?.type === SequenceStepType.Call && (
              <div className="bs-mt-20">{renderCallOutcomeSelect()}</div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div
              className="icon-container pointer"
              {...accessibleOnClick(() =>
                handleUpdateTask(TaskAction.SkipTask),
              )}
            >
              <ForwardStep
                width={16}
                height={16}
                className="gray-txt-15 mr-2"
              />
              <span className="regular-2 font-medium gray-txt-15">Skip</span>
            </div>

            <div className="d-flex align-items-center">
              <OverlayTooltip
                text="Snooze"
                placement={Placement.Top}
                className="cell-card__tooltip"
              >
                <div
                  className="icon-container"
                  {...accessibleOnClick(() =>
                    handleUpdateTask(TaskAction.SnoozeTask),
                  )}
                >
                  <Alarm
                    width={16}
                    height={16}
                    className="gray-txt-15 pointer"
                  />
                </div>
              </OverlayTooltip>

              <Button
                variant="secondary"
                className="ml-3"
                onClick={() => handleUpdateTask(TaskAction.ExecuteTask)}
              >
                <Check width={16} height={16} className="mr-2" />
                Complete
              </Button>

              {isLinkedInType(currentData?.type) && (
                <Button
                  className="ml-3"
                  onClick={sendLinkedinRequest}
                  disabled={
                    !getProspectDetails(currentData?.prospect?.attributes)
                      ?.LinkedIn
                  }
                >
                  {getButtonLabel(currentData?.type)}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export default TaskerModal;
