import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  getEmailAccountSetupScoreRequest,
  getEmailAccountsListRequest,
} from '../../../email-accounts/extra-actions';
// eslint-disable-next-line import/no-cycle
import CustomDomain from './custom-domain';
import {
  resetCustomDomainState,
  resetCreateCustomTrackingDomain,
  resetCustomTrackingDomainState,
  resetDeleteCustomTrackingDomain,
  resetUpdateCustomTrackingDomain,
} from './custom-domain-slice';

import {
  createCustomDomainRequest,
  deleteCustomDomainRequest,
  getCustomDomainsRequest,
  setDefaultCustomDomainRequest,
  updateCustomDomainRequest,
} from './extra-actions';
import { EmailAccountSetupScorePayload } from '../../../email-accounts/types/request-payload';

const mapStateToProps = (state: RootState) => ({
  getEmailAccountsListRequestStatus:
    state.emailAccount.getEmailAccountsListRequest.status,
  getEmailAccountsListRequestMessage:
    state.emailAccount.getEmailAccountsListRequest.message,
  getEmailAccountsListRequestError:
    state.emailAccount.getEmailAccountsListRequest.error,

  getCustomDomainsRequestStatus:
    state.customDomain.getCustomDomainsRequest.status,
  getCustomDomainsRequestMessage:
    state.customDomain.getCustomDomainsRequest.message,
  getCustomDomainsRequestError:
    state.customDomain.getCustomDomainsRequest.error,

  createCustomDomainRequestStatus:
    state.customDomain.createCustomDomainRequest.status,
  createCustomDomainRequestMessage:
    state.customDomain.createCustomDomainRequest.message,
  createCustomDomainRequestError:
    state.customDomain.createCustomDomainRequest.error,

  deleteCustomDomainRequestStatus:
    state.customDomain.deleteCustomDomainRequest.status,
  deleteCustomDomainRequestMessage:
    state.customDomain.deleteCustomDomainRequest.message,
  deleteCustomDomainRequestError:
    state.customDomain.deleteCustomDomainRequest.error,

  updateCustomDomainRequestStatus:
    state.customDomain.updateCustomDomainRequest.status,
  updateCustomDomainRequestMessage:
    state.customDomain.updateCustomDomainRequest.message,
  updateCustomDomainRequestError:
    state.customDomain.updateCustomDomainRequest.error,

  setDefaultCustomDomainRequestStatus:
    state.customDomain.setDefaultCustomDomainRequest.status,
  setDefaultCustomDomainRequestMessage:
    state.customDomain.setDefaultCustomDomainRequest.message,
  setDefaultCustomDomainRequestError:
    state.customDomain.setDefaultCustomDomainRequest.error,

  emailAccounts: state.emailAccount.emailAccountsList,
  customDomains: state.customDomain.customDomains,
  isLoading: state.customDomain.isLoading,

  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
  agencyConfig: state.home.agencyConfig,
  userEmail: state.home.email,
});

const mapDispatchToProps = {
  sendGetCustomDomainsRequest: () => getCustomDomainsRequest(),
  sendGetEmailAccountsListRequest: () => getEmailAccountsListRequest(),
  sendCreateCustomDomainRequest: (args) => createCustomDomainRequest(args),
  sendDeleteCustomDomainRequest: (args) => deleteCustomDomainRequest(args),
  sendUpdateCustomDomainRequest: (args) => updateCustomDomainRequest(args),
  sendSetDefaultCustomDomainRequest: (args) =>
    setDefaultCustomDomainRequest(args),
  resetCustomDomainState: () => resetCustomDomainState(),
  sendGetEmailAccountSetupScoreRequest: (
    payload: EmailAccountSetupScorePayload,
  ) => getEmailAccountSetupScoreRequest(payload),

  resetCustomTrackingDomainState: () => resetCustomTrackingDomainState(),
  resetCreateCustomTrackingDomain: () => resetCreateCustomTrackingDomain(),
  resetUpdateCustomTrackingDomain: () => resetUpdateCustomTrackingDomain(),
  resetDeleteCustomTrackingDomain: () => resetDeleteCustomTrackingDomain(),

  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(CustomDomain);
