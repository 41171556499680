import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  createScheduleRequest,
  updateScheduleRequest,
  getScheduleRequest,
  deleteScheduleRequest,
  setDefaultScheduleRequest,
} from './schedule-slice';
import Schedule from './schedule';

import { UpdateScheduleRequestPayload } from '../../types/request-payload';

const mapStateToProps = (state: RootState) => ({
  createScheduleRequestStatus: state.schedule.createScheduleRequest.status,
  createScheduleRequestMessage: state.schedule.createScheduleRequest.message,
  createScheduleRequestError: state.schedule.createScheduleRequest.error,

  getScheduleRequestStatus: state.schedule.getScheduleRequest.status,
  getScheduleRequesttMessage: state.schedule.getScheduleRequest.message,
  getScheduleRequestError: state.schedule.getScheduleRequest.error,

  updateScheduleRequestStatus: state.schedule.updateScheduleRequest.status,
  updateScheduleRequestMessage: state.schedule.updateScheduleRequest.message,
  updateScheduleRequestError: state.schedule.updateScheduleRequest.error,

  deleteScheduleRequestStatus: state.schedule.deleteScheduleRequest.status,
  deleteScheduleRequestMessage: state.schedule.deleteScheduleRequest.message,
  deleteScheduleRequestError: state.schedule.deleteScheduleRequest.error,

  setDefaultScheduleRequestStatus:
    state.schedule.setDefaultScheduleRequest.status,
  setDefaultScheduleRequestMessage:
    state.schedule.setDefaultScheduleRequest.message,
  setDefaultScheduleRequestError:
    state.schedule.setDefaultScheduleRequest.error,

  schedules: state.schedule.schedules,
  isLoading: state.schedule.isLoading,

  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
});

const mapDispatchToProps = {
  sendGetSchedulesRequest: () => getScheduleRequest(),
  sendCreateScheduleRequest: () => createScheduleRequest(),
  sendUpdateScheduleRequest: (
    scheduleId: number,
    payload: UpdateScheduleRequestPayload,
  ) => updateScheduleRequest({ scheduleId, ...payload }),
  sendDeleteScheduleRequest: (scheduleId: number) =>
    deleteScheduleRequest(scheduleId),
  sendSetDefaultScheduleRequest: (scheduleId: number) =>
    setDefaultScheduleRequest(scheduleId),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default connector(Schedule);
