import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { OverlayPopover, Placement } from '../../overlay';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';
import './prospect-tag.scss';
import TruncatedTextWithTooltip from '../../../../components/truncated-text-with-tooltip';

export type ProspectTagProps = {
  // * Name of the tag
  name: string;
  // * Text to display on tool tip.
  tooltipText: React.ReactNode | string;
  // * Name of the class for the tag container.
  className?: string;
  // * Children to render alongside the tag.
  children?: React.ReactNode;
  isPopoverVisible?: boolean;
};

const OverlayPopoverContent = ({ data }) => (
  <div className="overlay-popover-content-wrap">
    {data.map((tag, index) => (
      <div className="text-wrap">
        {/* eslint-disable-next-line react/no-array-index-key */}
        <span key={index} className="regular-2">
          {tag.props.children}
        </span>
      </div>
    ))}
  </div>
);

const ProspectTag: React.FC<ProspectTagProps> = ({
  name,
  tooltipText,
  className,
  children,
  isPopoverVisible,
}) => {
  const [
    isVisibleOverlayPopover,
    setIsVisibleOverlayPopover,
  ] = useState<boolean>(false);

  const onToggle = () => {
    setIsVisibleOverlayPopover(!isVisibleOverlayPopover);
  };

  const showOverlayPopover = () => {
    setIsVisibleOverlayPopover(true);
  };

  const popoverWrapperClassName = classNames([
    'bs-tag',
    'bs-tag-blue-17',
    'd-flex flex-row',
    className,
  ]);

  if (isPopoverVisible) {
    return (
      <OverlayPopover
        show={isVisibleOverlayPopover}
        onToggle={onToggle}
        className="prospect-tag-popover"
        content={<OverlayPopoverContent data={tooltipText} />}
        placement={Placement.Top}
        rootClose={true}
      >
        <div
          className={popoverWrapperClassName}
          {...accessibleOnClick(showOverlayPopover)}
        >
          <span className="regular-2">{name}</span>
          {children}
        </div>
      </OverlayPopover>
    );
  }

  return (
    <div
      className={popoverWrapperClassName}
      {...accessibleOnClick(showOverlayPopover)}
    >
      <span className="regular-2">
        <TruncatedTextWithTooltip
          string={name}
          length={10}
          placement={Placement.Top}
        />
      </span>
      {children}
    </div>
  );
};

export default memo(ProspectTag);
