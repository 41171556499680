import { ProspectsFilters } from '../../../../../shared/types/prospects-filters';
import { checkIsFilterApplied } from '../../../../sequence/components/sequence-single-content/components/sequence-single-contacts/components/contact-list-content/helper';
import { getCallTitle } from '../../../../sequence/components/sequence-single-content/components/sequence-single-step-variant-item/helper';
import { SequenceStepType } from '../../../../sequence/enums';
import { CallOutcome, TaskAction, TaskStatusKey } from '../../../utils/enums';

export const getCallOutcomeOptions = [
  {
    key: CallOutcome.Interested,
    label: 'Interested (Positive)',
  },
  {
    key: CallOutcome.CallbackLater,
    label: 'Callback Later',
  },
  {
    key: CallOutcome.Followup,
    label: 'Follow-up',
  },
  {
    key: CallOutcome.NotInterested,
    label: 'Not Interested',
  },
  {
    key: CallOutcome.WrongNumber,
    label: 'Wrong Number',
  },
  {
    key: CallOutcome.NoAnswer,
    label: 'No Answer',
  },
  {
    key: CallOutcome.LeftVoicemail,
    label: 'Left Voicemail',
  },
  {
    key: CallOutcome.NotInService,
    label: 'Not In Service',
  },
];

export const getTaskTitle = (type, purpose = null) => {
  if (type === SequenceStepType.LinkedInConnectionRequest) {
    return 'Connection Request';
  }
  if (type === SequenceStepType.LinkedInMessage) {
    return 'Message';
  }
  if (type === SequenceStepType.LinkedInInMail) {
    return 'InMail';
  }
  if (type === SequenceStepType.LinkedInViewProfile) {
    return 'View Profile';
  }
  if (type === SequenceStepType.LinkedInPostInteraction) {
    return 'Post Interaction';
  }
  if (type === SequenceStepType.Call) {
    return getCallTitle(purpose);
  }
  if (type === SequenceStepType.Whatsapp) {
    return 'WhatsApp Message';
  }

  return 'Custom Task';
};

export const isLinkedInType = (type: SequenceStepType) =>
  type === SequenceStepType.LinkedInConnectionRequest ||
  type === SequenceStepType.LinkedInMessage ||
  type === SequenceStepType.LinkedInPostInteraction ||
  type === SequenceStepType.LinkedInInMail ||
  type === SequenceStepType.LinkedInViewProfile;

export const getDateColumnTitle = (taskStatus: TaskStatusKey) => {
  if (
    taskStatus === TaskStatusKey.DueToday ||
    taskStatus === TaskStatusKey.Upcoming ||
    taskStatus === TaskStatusKey.Overdue
  ) {
    return 'Due Date';
  }
  if (taskStatus === TaskStatusKey.Completed) {
    return 'Completion Date';
  }

  return 'Skipped Date';
};

export const removeAgo = (string) => string.replace(/\s*ago\s*/, '');

export const getTaskDate = (taskStatus: TaskStatusKey, cell) => {
  if (
    taskStatus === TaskStatusKey.DueToday ||
    taskStatus === TaskStatusKey.Upcoming ||
    taskStatus === TaskStatusKey.Overdue
  ) {
    return cell?.dueAt;
  }

  if (taskStatus === TaskStatusKey.Completed) {
    return cell?.completedAt;
  }

  return cell?.skippedAt;
};

export const isActionsVisible = (taskStatus: TaskStatusKey) =>
  taskStatus === TaskStatusKey.Completed ||
  taskStatus === TaskStatusKey.Skipped;

export const getToasterMessage = (taskAction: TaskAction) => {
  if (taskAction === TaskAction.ExecuteTask) {
    return 'Task marked completed successfully.';
  }
  if (taskAction === TaskAction.SkipTask) {
    return 'Task skipped successfully.';
  }

  return 'Task snoozed successfully.';
};

export const isCompletedOrSkippedTab = (taskStatus: TaskStatusKey) =>
  taskStatus === TaskStatusKey.Completed ||
  taskStatus === TaskStatusKey.Skipped;

// Tasks Filters
export const setTasksFiltersInLocalStore = (filters: ProspectsFilters) => {
  localStorage.setItem('t-f', JSON.stringify(filters));
};
export const getTasksFiltersFromLocalStore = (): ProspectsFilters =>
  JSON.parse(localStorage.getItem('t-f'));
export const removeTasksFiltersFromLocalStore = (): void => {
  localStorage.removeItem('t-f');
};

export const setTaskTypeFiltersInLocalStore = (taskTypes: number[]) => {
  localStorage.setItem('t_t-f', JSON.stringify(taskTypes));
};
export const getTaskTypeFiltersInLocalStore = () =>
  JSON.parse(localStorage.getItem('t_t-f'));

export const removeTaskTypeFiltersInLocalStore = () => {
  localStorage.removeItem('t_t-f');
};

// Extract ids using reduce method
export const getSelectedTasksId = (selectedTasks) =>
  selectedTasks.reduce((accumulator, currentValue) => {
    accumulator.push(currentValue.id);
    return accumulator;
  }, []);

export const getTaskActionTitle = (taskAction: TaskAction, selectedTaskIds) => {
  if (!selectedTaskIds) {
    return taskAction === TaskAction.ExecuteTask
      ? 'Complete this task'
      : 'Skip this task';
  }

  return taskAction === TaskAction.ExecuteTask
    ? `Complete this ${selectedTaskIds} task(s)`
    : `Skip this ${selectedTaskIds} task(s)`;
};

export const getCallTaskDesc = (selectedTaskIds) =>
  `To mark ${
    selectedTaskIds > 0 ? `${selectedTaskIds} Calling task(s)` : 'calling task'
  } as complete, select call outcome`;

export const getCompleteActionDesc = (selectedTaskIds) =>
  `Are you sure you want to mark ${
    selectedTaskIds > 0 ? `these ${selectedTaskIds} tasks` : 'this task'
  } as complete?`;

export const isCallTask = (type: SequenceStepType) =>
  type === SequenceStepType.Call;

export const isAnyCallTaskSelected = (selectedTasks) =>
  selectedTasks?.filter((item) => item.taskTitle.type === SequenceStepType.Call)
    ?.length || 0;

export const getConfirmationModalClass = (
  taskAction: TaskAction,
  taskType: SequenceStepType,
  selectedTasks,
) => {
  if (
    taskAction === TaskAction.ExecuteTask &&
    isAnyCallTaskSelected(selectedTasks)
  ) {
    return 'task-confirmation-modal-tertiary';
  }

  return taskAction === TaskAction.ExecuteTask &&
    taskType === SequenceStepType.Call
    ? 'task-confirmation-modal-call'
    : 'task-confirmation-modal-second';
};

export const getTaskTableClass = (tasksFilters, selectedTasks) => {
  if (checkIsFilterApplied(tasksFilters) && selectedTasks) {
    return 'lg-table';
  }

  if (checkIsFilterApplied(tasksFilters)) {
    return 'sm-table';
  }

  if (selectedTasks) {
    return 'md-table';
  }

  return '';
};
