/* eslint-disable no-restricted-syntax */
import { SequenceStep } from '../types';
import {
  SequenceStepExecutionDay,
  SequenceStepPriority,
  SequenceStepPriorityValue,
} from '../enums/sequence-step';

export const isFirstStep = (step: SequenceStep): boolean => step.number === 1;

export const getMinRelativeDays = (): number => SequenceStepExecutionDay.MinDay;

export const getDefaultRelativeExecutionDay = (
  stepNumber: number,
  lastAbsoluteDay: number,
): number => {
  if (stepNumber === 1) {
    return 1;
  }

  const resultDay = lastAbsoluteDay + 2;

  return resultDay >= SequenceStepExecutionDay.MaxDay
    ? SequenceStepExecutionDay.MaxDay
    : resultDay;
};

export const getLastAbsoluteDay = (array, updatedArr = null) => {
  if (updatedArr) {
    return updatedArr;
  }

  let lastAbsoluteDay = 1; // Initialize the last absolute day to 0

  for (const item of array) {
    // If relativeDays is not 0, update lastAbsoluteDay by adding relativeDays
    lastAbsoluteDay += item.relativeDays;
  }

  return lastAbsoluteDay;
};

export const getLastAbsoluteDayForUpdateStep = (array, stepNumber) => {
  if (stepNumber === 1) {
    return 0;
  }

  let lastAbsoluteDay = 1; // Initialize the last absolute day to 0

  array
    .filter((step) => step.number < stepNumber)
    .forEach((item) => {
      lastAbsoluteDay += item.relativeDays;
    });

  return lastAbsoluteDay;
};

export const getStepPriorityText = (priority: number) => {
  if (priority === SequenceStepPriorityValue.Low) {
    return SequenceStepPriority.Low;
  }
  if (priority === SequenceStepPriorityValue.Normal) {
    return SequenceStepPriority.Normal;
  }
  if (priority === SequenceStepPriorityValue.High) {
    return SequenceStepPriority.High;
  }

  return SequenceStepPriority.Urgent;
};

const getTagClassName = (priority: number) => {
  if (priority === SequenceStepPriorityValue.Low) {
    return 'priority__low';
  }
  if (priority === SequenceStepPriorityValue.Normal) {
    return 'priority__normal';
  }
  if (priority === SequenceStepPriorityValue.High) {
    return 'priority__high';
  }

  return 'priority__urgent';
};

export const getStepPriorityVariant = (text: SequenceStepPriority) => {
  if (text === SequenceStepPriority.Low) {
    return 'secondary';
  }
  if (text === SequenceStepPriority.High) {
    return 'tertiary';
  }
  if (text === SequenceStepPriority.Urgent) {
    return 'danger';
  }

  return 'primary';
};

export const getStepPriority = (priority: number) => {
  const text = getStepPriorityText(priority);
  const className = getTagClassName(priority);
  const variant:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'danger' = getStepPriorityVariant(text);

  return { text, variant, className };
};
