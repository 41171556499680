export enum FileConfig {
  MaxFileSizeInMB = 5,
  MaxRows = 10000,
}

export const AllowedExtensions = ['csv'];

export const CSVFileValidationErrors = {
  ExtensionNotAvailable: `File extension is not available.`,
  TypeNotSupported: `The file type you are trying to upload is not supported.`,
  SizeExceeds: `File size exceeds the ${FileConfig.MaxFileSizeInMB} MB limit. Please upload a smaller file.`,
  OneRowRequired: `At least 1 row is required`,
  MaxRowsExceeds: `The CSV file exceeds 10,000 rows. Please upload a smaller file. `,
};
