import React from 'react';

export const NoLogsFound = () => (
  <svg
    width="81"
    height="81"
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="40.2926" cy="38.9825" r="30.4762" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1 16.1443C49.1545 16.6014 49.5691 16.9278 50.0262 16.8734C50.4833 16.8189 50.8097 16.4042 50.7552 15.9471L50.4923 13.7402C50.4379 13.2831 50.0232 12.9567 49.5661 13.0111C49.109 13.0656 48.7826 13.4803 48.8371 13.9374L49.1 16.1443ZM58.2391 25.2839C57.782 25.2295 57.4556 24.8148 57.5101 24.3577C57.5645 23.9006 57.9792 23.5742 58.4363 23.6287L60.6433 23.8916C61.1004 23.9461 61.4268 24.3608 61.3723 24.8178C61.3179 25.2749 60.9032 25.6013 60.4461 25.5469L58.2391 25.2839ZM53.9398 17.772C53.5067 17.616 53.2821 17.1385 53.4381 16.7054L54.1913 14.6143C54.3473 14.1812 54.8248 13.9566 55.2579 14.1126C55.691 14.2686 55.9156 14.7461 55.7596 15.1792L55.0064 17.2703C54.8504 17.7034 54.3729 17.928 53.9398 17.772ZM57.6781 20.9449C57.245 21.1009 56.7675 20.8763 56.6115 20.4432C56.4555 20.0101 56.6801 19.5326 57.1132 19.3766L59.2042 18.6234C59.6373 18.4674 60.1148 18.692 60.2708 19.1251C60.4268 19.5581 60.2022 20.0357 59.7691 20.1917L57.6781 20.9449Z"
      fill="#CCD0DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.5011 56.3979C37.2901 56.3979 37.9297 57.0375 37.9297 57.8265C37.9297 58.6155 37.2901 59.2551 36.5011 59.2551H24.1202C23.3312 59.2551 22.6916 58.6155 22.6916 57.8265C22.6916 57.0375 23.3312 56.3979 24.1202 56.3979H36.5011ZM36.5011 63.0649C37.2901 63.0649 37.9297 63.7045 37.9297 64.4935C37.9297 65.2825 37.2901 65.9221 36.5011 65.9221H16.5011C15.7121 65.9221 15.0725 65.2825 15.0725 64.4935C15.0725 63.7045 15.7121 63.0649 16.5011 63.0649H36.5011ZM37.9297 71.2528C37.9297 70.4638 37.2901 69.8242 36.5011 69.8242H16.5011C15.7121 69.8242 15.0725 70.4638 15.0725 71.2528C15.0725 72.0418 15.7121 72.6814 16.5011 72.6814H36.5011C37.2901 72.6814 37.9297 72.0418 37.9297 71.2528ZM19.3585 56.3979C20.1475 56.3979 20.7871 57.0375 20.7871 57.8265C20.7871 58.6155 20.1475 59.2551 19.3585 59.2551H16.5014C15.7124 59.2551 15.0728 58.6155 15.0728 57.8265C15.0728 57.0375 15.7124 56.3979 16.5014 56.3979H19.3585Z"
      fill="#DBE0E7"
    />
    <path
      d="M64.8078 58.3052L50.5586 44.2251L47.7371 47.0805L61.9862 61.1606L64.8078 58.3052Z"
      fill="url(#paint0_linear_36075_135354)"
    />
    <path
      d="M70.2504 62.0882L59.1645 51.1339C59.1028 51.0729 59.0033 51.0735 58.9423 51.1352L54.7464 55.3815C54.6854 55.4432 54.686 55.5427 54.7477 55.6037L65.8336 66.5581C65.8953 66.6191 65.9948 66.6185 66.0558 66.5567L70.2517 62.3104C70.3127 62.2487 70.3121 62.1492 70.2504 62.0882Z"
      fill="url(#paint1_linear_36075_135354)"
    />
    <path
      d="M37.9296 51.306C47.7326 51.306 55.6795 43.3591 55.6795 33.5561C55.6795 23.7531 47.7326 15.8062 37.9296 15.8062C28.1266 15.8062 20.1797 23.7531 20.1797 33.5561C20.1797 43.3591 28.1266 51.306 37.9296 51.306Z"
      fill="url(#paint2_linear_36075_135354)"
    />
    <path
      d="M37.9299 48.41C46.1333 48.41 52.7835 41.7598 52.7835 33.5563C52.7835 25.3529 46.1333 18.7026 37.9299 18.7026C29.7264 18.7026 23.0762 25.3529 23.0762 33.5563C23.0762 41.7598 29.7264 48.41 37.9299 48.41Z"
      fill="#DBE0E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.0113 26.6087C40.1101 26.6515 41.8393 28.3451 41.948 30.4644C42.0058 31.5925 41.615 32.6641 40.8474 33.482C40.3747 33.9856 39.7822 34.3551 39.1363 34.5615V35.9449C39.1363 36.6195 38.5957 37.1663 37.9289 37.1663C37.2621 37.1663 36.7215 36.6195 36.7215 35.9449V33.5293C36.7215 32.8547 37.2621 32.3078 37.9289 32.3078C38.3743 32.3078 38.7889 32.1275 39.0963 31.8C39.4034 31.4728 39.5597 31.0434 39.5365 30.5909C39.5156 30.1846 39.3415 29.8016 39.0461 29.5125C38.7505 29.2231 38.3657 29.0593 37.9627 29.0511C37.1456 29.0347 36.447 29.6361 36.3347 30.4506C36.3243 30.5256 36.3191 30.6026 36.3191 30.6793C36.3191 31.3539 35.7785 31.9007 35.1117 31.9007C34.4449 31.9007 33.9043 31.3539 33.9043 30.6793C33.9043 30.4898 33.9173 30.3006 33.9431 30.1129C34.2206 28.1013 35.9266 26.6079 37.9291 26.6079C37.9565 26.6079 37.9838 26.6082 38.0113 26.6087ZM39.1355 39.2829C39.1355 39.9575 38.5949 40.5043 37.9281 40.5043C37.2613 40.5043 36.7207 39.9575 36.7207 39.2829C36.7207 38.6084 37.2613 38.0615 37.9281 38.0615C38.5949 38.0615 39.1355 38.6084 39.1355 39.2829Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_36075_135354"
        x1="51.3822"
        y1="50.6824"
        x2="55.1033"
        y2="47.6696"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_36075_135354"
        x1="57.529"
        y1="58.3521"
        x2="63.7088"
        y2="54.3436"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_36075_135354"
        x1="29.261"
        y1="51.306"
        x2="47.3377"
        y2="18.7368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
    </defs>
  </svg>
);
