import moment from 'moment-timezone';
import { SequenceConfig } from '../../enums';

export const getNewSequenceName = (): string =>
  `${SequenceConfig.NewSequenceTitle} - ${moment(new Date()).format(
    'ddd Do MMM',
  )}`;

const sequenceFilters = 'sequence-filters';

export const getSequenceFilterFromLocalStorage = () =>
  localStorage.getItem(sequenceFilters);

export const setSequenceFilterFromLocalStorage = (filters) =>
  localStorage.setItem(sequenceFilters, JSON.stringify(filters));

export const removeSequenceFilterFromLocalStorage = () =>
  localStorage.removeItem(sequenceFilters);
