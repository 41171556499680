import React from 'react';
import { IProps } from './types';
import {
  OverlayPopover,
  Placement,
  TriggerType,
} from '../../../../../../shared/design-system/components/overlay';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

const CustomOutcomeHeader: React.FC<IProps> = ({ headerName, totalCount }) => {
  const renderOutcomeCount = (label: string, count: number) => (
    <div className="render-outcome-count-item">
      <div className="render-outcome-count-item--inner">
        <p>{label}</p>
      </div>
      <p>{count}</p>
    </div>
  );

  return (
    <GlobalSettings.Header>
      <GlobalSettings.HeaderLeft>
        <GlobalSettings.HeaderTitle title={headerName} />
        <div className="custom-outcomes-grid-header">
          {totalCount && false && (
            <OverlayPopover
              className="p-0 outcome-count-popover"
              content={
                <div className="outcome-count">
                  {renderOutcomeCount(
                    'Custom Outcomes',
                    totalCount.customCount,
                  )}
                  {renderOutcomeCount(
                    'System Outcomes',
                    totalCount.systemCount,
                  )}
                </div>
              }
              trigger={TriggerType.Hover}
              placement={Placement.BottomStart}
            >
              <div className="total-outcomes-badge">
                <span>{totalCount.customCount + totalCount.systemCount}</span>
              </div>
            </OverlayPopover>
          )}
        </div>
      </GlobalSettings.HeaderLeft>
    </GlobalSettings.Header>
  );
};

export default CustomOutcomeHeader;
