import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';
import {
  getHasVisitedEmailAccounts,
  setHasVisitedEmailAccounts,
} from './helpers/get-set-has-visited';

const EmailAccountsList = React.lazy(() =>
  retryLazy(() => retryLazy(() => import('./components/email-accounts-list'))),
);
const UpdateEmailAccount = React.lazy(() =>
  retryLazy(() => import('./components/update-email-account')),
);

const EmailAccounts: React.FC = () => {
  useEffect(() => {
    const hasVisitedEmailAccountsInLS = getHasVisitedEmailAccounts();
    if (hasVisitedEmailAccountsInLS === 'true') {
      setHasVisitedEmailAccounts('false');
    }
  }, []);

  return (
    <Container fluid className="h-100 email-accounts--wrapper">
      <ErrorBoundaryWrapper>
        <Suspense>
          <Switch>
            <Route exact path="/email-accounts" component={EmailAccountsList} />
            <Route
              exact
              path="/email-accounts/:hashId/:tab"
              component={UpdateEmailAccount}
            />
            <Redirect to="/email-accounts" />
          </Switch>
        </Suspense>
      </ErrorBoundaryWrapper>
    </Container>
  );
};

export default EmailAccounts;
