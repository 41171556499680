import React from 'react';
import CellCard from '../../../../../../../../../shared/design-system/components/atoms/cell-card';
import ContactStatusTag from '../../../../../../../../../shared/design-system/components/atoms/contact-status-tag/contact-status-tag';

export const getProspectSequenceColumn = () => [
  {
    dataField: 'sequences',
    text: '',
    headerAlign: 'left',
    style: { borderTop: 'none', paddingRight: '0px' },
    headerStyle: {
      display: 'none',
    },
    component: (cell) => <CellCard list={[cell]} bulkText="Sequences" />,
  },
  {
    dataField: 'status',
    text: '',
    headerAlign: 'left',
    style: { borderTop: 'none', width: '5rem' },
    headerStyle: {
      display: 'none',
    },
    component: (cell) => (
      <div className="bs-mt-2">
        <ContactStatusTag status={cell} isSequenceProspectTag={true} />
      </div>
    ),
  },
];
