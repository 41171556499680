import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../store/root-reducer';

import {
  ProspectsFilters,
  ProspectFilterResetArg,
  Filters,
} from '../../../../../../../shared/types/prospects-filters';
import ProspectsFiltersModal from './prospects-filters-modal';
import withProspectsFiltersAPI from '../../../../../helpers/withProspectsFiltersAPI';
import { getContactSequenceListRequest } from '../../../../../../sequence/extra-actions';
import {
  ProspectPagination,
  ProspectParams,
  ProspectCountParams,
} from '../../../../../types';
import {
  getStepDetailsListRequest,
  getTagsRequest,
} from '../../../../../extra-actions';
import { ContactsPagination } from '../../../../../../sequence/types';
import {
  getMembersListRequest,
  getTeamsAndMembersListRequest,
} from '../../../../../../settings/components/users-and-teams/extra-actions';
import { UserStatusKeys } from '../../../../../../settings/enums/users-and-teams';
import { changeProspectPagination } from '../../../../../prospect-slice';
import { getCustomOutcomeRequest } from '../../../../../../settings/components/custom-outcomes/extra-actions';
import { EmailInboxFilters } from '../../../../../../unified-inbox/type';
import {
  getEmailAccountsListForFilterRequest,
  getEmailCategoriesRequest,
  getSequencesListForFilterRequest,
} from '../../../../../../unified-inbox/extra-actions';
import { getAgencyClientsDropdown } from '../../../../../../agency-client-management/extra-actions';
import { GetAgencyClientDropdownRequestPayload } from '../../../../../../agency-client-management/types/request-payload';

export type SelectContact = {
  status: boolean;
  row: any;
};

const mapStateToProps = (state: RootState) => ({
  getProspectRequestStatus: state.prospect.getProspectRequest.status,
  getProspectRequestError: state.prospect.getProspectRequest.error,
  getProspectCountRequestStatus: state.prospect.getProspectCountRequest.status,
  getProspectCountRequestError: state.prospect.getProspectCountRequest.error,
  tags: state.prospect.tags,
  sequences: state.sequence.contactSequence,
  owners: state.usersAndTeams.membersList,
  teamsAndUsersList: state.usersAndTeams.teamsAndUsersList,
  allOutcomes: state.customOutcome.outcomes,
  stepsDetails: state.prospect.stepsDetails,

  getEmailAccountsListForFilterRequestStatus:
    state.unifiedInbox.getEmailAccountsListForFilterRequest.status,
  emails: state.unifiedInbox.emails,

  getAgencyClientsDropdownRequestStatus:
    state.agencyClient.getAgencyClientsDropdownRequest.status,
  getAgencyClientsDropdownRequestMessage:
    state.agencyClient.getAgencyClientsDropdownRequest.message,
  getAgencyClientsDropdownRequestError:
    state.agencyClient.getAgencyClientsDropdownRequest.error,
  clients: state.agencyClient.clients,

  uniboxSequences: state.unifiedInbox.sequences,
  uniboxCategory: state.unifiedInbox.emailCategories,
});

const mapDispatchToProps = {
  sendGetSequenceList: () => getContactSequenceListRequest(),
  sendGetOwnersList: () => getMembersListRequest([UserStatusKeys.Active]),
  sendGetTagsRequest: () => getTagsRequest(),
  sendGetTeamsAndMembersListRequest: () => getTeamsAndMembersListRequest(),
  sendGetAllOutcomesRequest: () => getCustomOutcomeRequest(),
  sendGetEmailAccounts: () => getEmailAccountsListForFilterRequest(),
  changeProspectPagination: (paginationOptions: ProspectPagination) =>
    changeProspectPagination(paginationOptions),
  sendGetStepDetailsListRequest: (sequenceId: string) =>
    getStepDetailsListRequest(sequenceId),
  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),
  sendGetEmailCategoriesRequest: () => getEmailCategoriesRequest(),
  sendGetSequencesListForFilterRequest: () =>
    getSequencesListForFilterRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  hide: () => void;
  isUniboxFilters?: boolean;
  prospectsFilters: ProspectsFilters;
  getProspects: (params?: ProspectParams | EmailInboxFilters | any) => void;
  getProspectsCount: (params?: ProspectCountParams) => void;
  resetProspectsFilters: (payload: ProspectFilterResetArg) => void;
  setProspectsFilters: (filters: ProspectsFilters) => void;
  handleClearSelection?: VoidFunction;
  paginationOptions: ProspectPagination | ContactsPagination;
  unifiedInboxContext?: boolean;
  /**
   * Display all filters except
   */
  except?: Filters[];
  selectedStatus: string;
  isATMOwnerList?: boolean;
  isTasksFilter?: boolean;
  handleClearAll?: VoidFunction;
  sequenceId?: string;
};

export default connector(withProspectsFiltersAPI(ProspectsFiltersModal));
