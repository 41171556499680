import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';

const WhitelabelSkeletonView = () => (
  <div className="p-4">
    <SkeletonLoading width={1200} height={112} />
    <SkeletonLoading width={1200} height={296} className="mt-3" />
    <SkeletonLoading width={1200} height={296} className="mt-3" />
  </div>
);

export default WhitelabelSkeletonView;
