import React from 'react';
import { IProps } from './types';
import { GlobalSettings } from '../../../../../../shared/components/global-settings-wrapper';

const CustomFieldHeader: React.FC<IProps> = ({ headerName }) => (
  <GlobalSettings.Header>
    <GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderTitle title={headerName} />
    </GlobalSettings.HeaderLeft>
  </GlobalSettings.Header>
);

export default CustomFieldHeader;
