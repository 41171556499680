import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/root-reducer';
import LogoSecondary from './logo-secondary';

const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home.agencyConfig,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(LogoSecondary);
