import { RouteComponentProps, withRouter } from 'react-router';
import { ConnectedProps, connect } from 'react-redux';
import Login from './login';
import { RootState } from '../../../../store/root-reducer';

export const mapStateToProps = (state: RootState) => ({
  agencyConfig: state.home?.agencyConfig,
  agencyConfigRequestStatus: state.home?.getAgencyConfigRequest.status,
});

const connector = connect(mapStateToProps);

export type IProps = ConnectedProps<typeof connector> & RouteComponentProps;

export default withRouter(connector(Login));
