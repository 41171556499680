import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import { UpdateTask } from '../../../../utils/types';
import { updateTasksRequest } from '../../../../extra-actions';
import ReassignModal from './reassign-modal';
import { getTaskAssigneeListRequest } from '../../../../../sequence/extra-actions';

const mapStateToProps = (state: RootState) => ({
  taskActionRequestStatus: state.tasks.updateTasksRequest.status,
  selectedTasks: state.tasks.selectedTasks,
  taskAssigneeList: state.sequence.taskAssigneeList,
  userId: state.home.userId,
});

const mapDispatchToProps = {
  updateTasksRequest: (payload: UpdateTask) => updateTasksRequest(payload),
  sendGetTaskAssigneeListRequest: (userId: number) =>
    getTaskAssigneeListRequest({ userId }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onClose: VoidFunction;
  onSubmit: (id: number) => void;
};

export default connector(ReassignModal);
