import React from 'react';

export const NoDNCListFound = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37.4762" cy="40.7633" r="30.4762" fill="#F5F5F7" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.3332 35.2316C16.7419 35.0682 16.9407 34.6044 16.7773 34.1958C16.6139 33.7871 16.1502 33.5883 15.7415 33.7517L13.7684 34.5407C13.3597 34.7041 13.1609 35.1678 13.3243 35.5765C13.4877 35.9851 13.9514 36.1839 14.3601 36.0205L16.3332 35.2316ZM22.5121 24.5283C22.575 24.9639 22.2728 25.368 21.8372 25.4308C21.4016 25.4936 20.9975 25.1914 20.9347 24.7558L20.6314 22.6526C20.5686 22.217 20.8708 21.8129 21.3064 21.7501C21.742 21.6873 22.146 21.9895 22.2088 22.4251L22.5121 24.5283ZM16.64 30.3599C16.6031 30.7985 16.2176 31.1241 15.7791 31.0872L13.6615 30.909C13.223 30.8721 12.8974 30.4867 12.9342 30.0481C12.9711 29.6096 13.3566 29.284 13.7951 29.3209L15.9127 29.499C16.3512 29.5359 16.6768 29.9213 16.64 30.3599ZM18.6456 26.1213C18.8968 26.4826 18.8076 26.9792 18.4462 27.2305C18.0848 27.4817 17.5882 27.3924 17.337 27.0311L16.124 25.2863C15.8727 24.9249 15.962 24.4283 16.3234 24.1771C16.6847 23.9259 17.1813 24.0151 17.4325 24.3765L18.6456 26.1213Z"
      fill="#CCD0DB"
    />
    <g clip-path="url(#clip0_38132_60367)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.8147 44.0467L22.2923 35.3046C21.8944 35.0481 21.5682 34.7002 21.3427 34.2918C21.1172 33.8834 20.9995 33.4272 21 32.964C21 31.3386 22.3623 30.0176 24.0368 30.0176H50.9632C52.6377 30.0176 54 31.3386 54 32.9624C54 33.9068 53.5169 34.7818 52.7077 35.3046L39.1853 44.0467C38.6734 44.3776 38.0867 44.5431 37.4999 44.5431C36.9133 44.5431 36.3266 44.3776 35.8147 44.0467ZM37.5 46.4183C38.4832 46.4183 39.4369 46.1377 40.2579 45.6069L53.7798 36.8651C53.8546 36.8167 53.928 36.7662 53.9999 36.7138V51.0386C53.9999 52.6516 52.6558 53.9844 50.9621 53.9844H24.0379C22.3745 53.9844 21 52.681 21 51.0386V36.7138C21.0718 36.7661 21.1451 36.8165 21.2197 36.8649L34.7422 45.6069C35.5632 46.1377 36.5168 46.4183 37.5 46.4183Z"
        fill="url(#paint0_linear_38132_60367)"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M71.5352 49.7821C71.5352 48.9931 70.8956 48.3535 70.1066 48.3535H64.1371C63.3481 48.3535 62.7085 48.9931 62.7085 49.7821C62.7085 50.5711 63.3481 51.2107 64.1371 51.2107H70.1066C70.8956 51.2107 71.5352 50.5711 71.5352 49.7821ZM71.5352 56.4491C71.5352 55.6601 70.8956 55.0205 70.1066 55.0205H59.7238C58.9348 55.0205 58.2952 55.6601 58.2952 56.4491C58.2952 57.2381 58.9348 57.8777 59.7238 57.8777H70.1066C70.8956 57.8777 71.5352 57.2381 71.5352 56.4491ZM60.1769 48.3535C60.9659 48.3535 61.6055 48.9931 61.6055 49.7821C61.6055 50.5711 60.9659 51.2107 60.1769 51.2107H59.7241C58.9351 51.2107 58.2955 50.5711 58.2955 49.7821C58.2955 48.9931 58.9351 48.3535 59.7241 48.3535H60.1769Z"
      fill="#DBE0E7"
    />
    <circle cx="62" cy="25" r="10.5" stroke="#DBE0E7" stroke-width="3" />
    <path
      d="M57.1112 14.7779L67.7779 34.7779"
      stroke="#DBE0E7"
      stroke-width="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_38132_60367"
        x1="29.7348"
        y1="51.6184"
        x2="30.9364"
        y2="29.4537"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#A7ABB6" />
        <stop offset="1" stop-color="#C7CCD7" />
      </linearGradient>
      <clipPath id="clip0_38132_60367">
        <rect
          width="33"
          height="32"
          fill="white"
          transform="translate(21 26.001)"
        />
      </clipPath>
    </defs>
  </svg>
);
