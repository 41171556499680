import React from 'react';
import { Help } from '@saleshandy/icons';

import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';

import TextInput from './text-input';
import HttpHeadersInput from './http-headers-input';
import EventToSendInput from './event-to-send-input';
import { WebhookTriggerEvent } from '../../../../../enums/webhook';

type IProps = {
  webhookName: string;
  webhookNameError: string;
  onWebhookNameChange: (value: string) => void;

  webhookURL: string;
  webhookURLError: string;
  onWebhookURLChange: (value: string) => void;

  headers: { key: string; value: string; id: number }[];
  onAddHeader: () => void;
  onDeleteHeader: (id: number) => void;
  onHeaderKeyChange: (id: number, key: string) => void;
  onHeaderValueChange: (id: number, value: string) => void;

  eventsToSend: WebhookTriggerEvent[];
  onWebhookTriggerEventChange: (
    checked: boolean,
    event: WebhookTriggerEvent,
  ) => void;
};

const AddWebhookForm: React.FC<IProps> = ({
  webhookName,
  webhookNameError,
  onWebhookNameChange,

  webhookURL,
  webhookURLError,
  onWebhookURLChange,

  headers,
  onAddHeader,
  onDeleteHeader,
  onHeaderKeyChange,
  onHeaderValueChange,

  eventsToSend,
  onWebhookTriggerEventChange,
}) => (
  <div className="webhook--form">
    <div className="webhook-form-inner">
      <div className="webhook-form-field pdr-30">
        <div className="webhook-form-field-label">
          <p>Name</p>
        </div>
        <TextInput
          name="webhookName"
          placeholder="Enter Webhook Name"
          value={webhookName}
          isError={webhookNameError !== ''}
          errorMsg={webhookNameError}
          onChange={onWebhookNameChange}
        />
      </div>

      <div className="webhook-form-field pdr-30">
        <div className="webhook-form-field-label">
          <p>Webhook URL</p>
          <OverlayTooltip
            className="webhook-url-tooltip"
            placement={Placement.Right}
            text="Webhook URL is the endpoint where your webhook will send data. Make sure to enter a valid URL that can receive and process the data sent by your webhook"
          >
            <Help />
          </OverlayTooltip>
        </div>
        <TextInput
          name="webhookURL"
          placeholder="https://"
          value={webhookURL}
          isError={webhookURLError !== ''}
          errorMsg={webhookURLError}
          onChange={onWebhookURLChange}
          type="textarea"
        />
        <p className="webhook-form-field-helper-text">
          Enter the URL where your webhook should send data
        </p>
      </div>

      <HttpHeadersInput
        headers={headers}
        onAddHeader={onAddHeader}
        onDeleteHeader={onDeleteHeader}
        onHeaderKeyChange={onHeaderKeyChange}
        onHeaderValueChange={onHeaderValueChange}
      />

      <EventToSendInput
        eventsToSend={eventsToSend}
        onEventToSendChange={onWebhookTriggerEventChange}
      />
    </div>
  </div>
);

export default AddWebhookForm;
