import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../../../shared/types';
import { getInitialRequestState } from '../../../../shared/utils/get-initail-request-state';
import { DNSDetails, WhitelabelResource } from './utils/types';
import {
  deleteDNSRequest,
  editCompanyNameRequest,
  editResourceRequest,
  deleteResourceRequest,
  getDNSRequest,
  getResourceRequest,
  saveCompanyNameRequest,
  saveDNSRequest,
  uploadResourceRequest,
} from './extra-actions';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  saveCompanyNameRequest: RequestState;
  editCompanyNameRequest: RequestState;
  getResourceRequest: RequestState;
  uploadResourceRequest: RequestState;
  editResourceRequest: RequestState;
  deleteResourceRequest: RequestState;
  getDNSRequest: RequestState;
  saveDNSRequest: RequestState;
  deleteDNSRequest: RequestState;
  resources: WhitelabelResource;
  dnsDetails: DNSDetails;
};

const initialState: State = {
  saveCompanyNameRequest: getInitialRequestState(),
  editCompanyNameRequest: getInitialRequestState(),
  getResourceRequest: getInitialRequestState(),
  uploadResourceRequest: getInitialRequestState(),
  editResourceRequest: getInitialRequestState(),
  deleteResourceRequest: getInitialRequestState(),
  getDNSRequest: getInitialRequestState(),
  saveDNSRequest: getInitialRequestState(),
  deleteDNSRequest: getInitialRequestState(),
  resources: {
    companyName: '',
    logo: null,
    icon: null,
    favicon: null,
  },
  dnsDetails: {
    id: null,
    domain: '',
    isVerified: null,
  },
};

const whitelabelSlice = createSlice({
  name: 'whitelabel',
  initialState,
  reducers: {
    resetResourceState: (state) => {
      state.saveCompanyNameRequest = initialState.saveCompanyNameRequest;
      state.uploadResourceRequest = initialState.uploadResourceRequest;
      state.editCompanyNameRequest = initialState.editCompanyNameRequest;
      state.editResourceRequest = initialState.editResourceRequest;
      state.deleteResourceRequest = initialState.deleteResourceRequest;
    },
  },
  extraReducers: (builder) => {
    // saveCompanyNameRequest
    builder.addCase(saveCompanyNameRequest.pending, (state) => {
      state.saveCompanyNameRequest.status = RequestStatus.Pending;
      state.saveCompanyNameRequest.error = null;
    });
    builder.addCase(saveCompanyNameRequest.fulfilled, (state, action) => {
      state.saveCompanyNameRequest.status = RequestStatus.Succeeded;
      state.saveCompanyNameRequest.message = action.payload.message;
      state.saveCompanyNameRequest.error = null;
    });
    builder.addCase(saveCompanyNameRequest.rejected, (state, action) => {
      state.saveCompanyNameRequest.status = RequestStatus.Failed;
      state.saveCompanyNameRequest.message = action.payload.message;
      state.saveCompanyNameRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // editCompanyNameRequest
    builder.addCase(editCompanyNameRequest.pending, (state) => {
      state.editCompanyNameRequest.status = RequestStatus.Pending;
      state.editCompanyNameRequest.error = null;
    });
    builder.addCase(editCompanyNameRequest.fulfilled, (state, action) => {
      state.editCompanyNameRequest.status = RequestStatus.Succeeded;
      state.editCompanyNameRequest.message = action.payload.message;
      state.editCompanyNameRequest.error = null;
    });
    builder.addCase(editCompanyNameRequest.rejected, (state, action) => {
      state.editCompanyNameRequest.status = RequestStatus.Failed;
      state.editCompanyNameRequest.message = action.payload.message;
      state.editCompanyNameRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // getResourceRequest
    builder.addCase(getResourceRequest.pending, (state) => {
      state.getResourceRequest.status = RequestStatus.Pending;
      state.getResourceRequest.error = null;
    });
    builder.addCase(getResourceRequest.fulfilled, (state, action) => {
      state.getResourceRequest.status = RequestStatus.Succeeded;
      state.resources = action.payload.payload;
      state.getResourceRequest.error = null;
    });
    builder.addCase(getResourceRequest.rejected, (state, action) => {
      state.getResourceRequest.status = RequestStatus.Failed;
      state.getResourceRequest.message = action.payload.message;
      state.getResourceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // uploadResourceRequest
    builder.addCase(uploadResourceRequest.pending, (state) => {
      state.uploadResourceRequest.status = RequestStatus.Pending;
      state.uploadResourceRequest.error = null;
    });
    builder.addCase(uploadResourceRequest.fulfilled, (state, action) => {
      state.uploadResourceRequest.status = RequestStatus.Succeeded;
      state.uploadResourceRequest.message = action.payload.message;
      state.uploadResourceRequest.error = null;
    });
    builder.addCase(uploadResourceRequest.rejected, (state, action) => {
      state.uploadResourceRequest.status = RequestStatus.Failed;
      state.uploadResourceRequest.message = action.payload.message;
      state.uploadResourceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // editResourceRequest
    builder.addCase(editResourceRequest.pending, (state) => {
      state.editResourceRequest.status = RequestStatus.Pending;
      state.editResourceRequest.error = null;
    });
    builder.addCase(editResourceRequest.fulfilled, (state, action) => {
      state.editResourceRequest.status = RequestStatus.Succeeded;
      state.editResourceRequest.message = action.payload.message;
      state.editResourceRequest.error = null;
    });
    builder.addCase(editResourceRequest.rejected, (state, action) => {
      state.editResourceRequest.status = RequestStatus.Failed;
      state.editResourceRequest.message = action.payload.message;
      state.editResourceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // deleteResourceRequest
    builder.addCase(deleteResourceRequest.pending, (state) => {
      state.deleteResourceRequest.status = RequestStatus.Pending;
      state.deleteResourceRequest.error = null;
    });
    builder.addCase(deleteResourceRequest.fulfilled, (state, action) => {
      state.deleteResourceRequest.status = RequestStatus.Succeeded;
      state.editResourceRequest.message = action.payload.message;
      state.deleteResourceRequest.error = null;
    });
    builder.addCase(deleteResourceRequest.rejected, (state, action) => {
      state.deleteResourceRequest.status = RequestStatus.Failed;
      state.deleteResourceRequest.message = action.payload.message;
      state.deleteResourceRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // getDNSRequest
    builder.addCase(getDNSRequest.pending, (state) => {
      state.getDNSRequest.status = RequestStatus.Pending;
      state.getDNSRequest.error = null;
    });
    builder.addCase(getDNSRequest.fulfilled, (state, action) => {
      state.getDNSRequest.status = RequestStatus.Succeeded;
      state.dnsDetails = action.payload.payload;
      state.getDNSRequest.error = null;
    });
    builder.addCase(getDNSRequest.rejected, (state, action) => {
      state.getDNSRequest.status = RequestStatus.Failed;
      state.getDNSRequest.message = action.payload.message;
      state.getDNSRequest.error = !action.payload.isHandled && action.payload;
    });

    // saveDNSRequest
    builder.addCase(saveDNSRequest.pending, (state) => {
      state.saveDNSRequest.status = RequestStatus.Pending;
      state.saveDNSRequest.error = null;
    });
    builder.addCase(saveDNSRequest.fulfilled, (state, action) => {
      state.saveDNSRequest.status = RequestStatus.Succeeded;
      state.saveDNSRequest.error = null;
    });
    builder.addCase(saveDNSRequest.rejected, (state, action) => {
      state.saveDNSRequest.status = RequestStatus.Failed;
      state.saveDNSRequest.message = action.payload.message;
      state.saveDNSRequest.error = !action.payload.isHandled && action.payload;
    });

    // deleteDNSRequest
    builder.addCase(deleteDNSRequest.pending, (state) => {
      state.deleteDNSRequest.status = RequestStatus.Pending;
      state.deleteDNSRequest.error = null;
    });
    builder.addCase(deleteDNSRequest.fulfilled, (state, action) => {
      state.deleteDNSRequest.status = RequestStatus.Succeeded;
      state.deleteDNSRequest.error = null;
    });
    builder.addCase(deleteDNSRequest.rejected, (state, action) => {
      state.deleteDNSRequest.status = RequestStatus.Failed;
      state.deleteDNSRequest.message = action.payload.message;
      state.deleteDNSRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const { resetResourceState } = whitelabelSlice.actions;
export default whitelabelSlice.reducer;
