import {
  BulkSelectedDNCListEmailsAndDomains,
  DoNotContactListEmailsAndDomains,
} from '../../../../../../../types/do-not-contact-list';
import { constants } from '../../../../../../../../../shared/enums/constants';

export const checkIsTableLastPage = (pageNumber: number, pageSize: number) => {
  const lastPage = constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS / pageSize;

  return lastPage >= pageNumber;
};

export const isCurrentPageUnderTheSelectAllPage = (
  bulkSelectedDNCListEmailsAndDomains: BulkSelectedDNCListEmailsAndDomains,
  pageNumber: number,
  pageSize: number,
) => {
  const lastPage = constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS / pageSize;

  return (
    bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected &&
    lastPage >= pageNumber
  );
};

// * Get Should Show Selected Row Info Banner Handler
export const getShouldShowSelectedRowInfoBanner = (
  selectedDNCListEmailsAndDomains: DoNotContactListEmailsAndDomains[],
  bulkSelectedDNCListEmailsAndDomains: BulkSelectedDNCListEmailsAndDomains,
  isBulkEmailAccountsSelected: boolean,
) =>
  selectedDNCListEmailsAndDomains.length > 0 ||
  bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected ||
  isBulkEmailAccountsSelected;

export const getSelectedEmailsDomainsCount = (
  selectedDNCListEmailsAndDomains: DoNotContactListEmailsAndDomains[],
  bulkSelectedDNCListEmailsAndDomains: BulkSelectedDNCListEmailsAndDomains,
  totalEmailsAndDomains: number,
) => {
  if (
    bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected
  ) {
    const count =
      totalEmailsAndDomains > constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS
        ? constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS
        : totalEmailsAndDomains;

    return (
      count -
      bulkSelectedDNCListEmailsAndDomains?.deSelectedDNCListEmailsAndDomainsIds
        ?.length
    );
  }

  return selectedDNCListEmailsAndDomains.length;
};

export const getFormattedEmailsDomainsCount = (
  selectedEmailsDomainsCount: number,
) =>
  selectedEmailsDomainsCount === 1
    ? `1 entry selected.`
    : `${selectedEmailsDomainsCount
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} entries selected.`;
