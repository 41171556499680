import React from 'react';

const TeamBadge: React.FC<{ id: number; name: string }> = ({ id, name }) => (
  <div className="team-badge" key={id}>
    <p>{name}</p>
  </div>
);

const TeamsCell: React.FC<{ teams: { id: number; name: string }[] }> = ({
  teams,
}) => {
  if (!teams || teams?.length === 0) {
    return <span />;
  }

  const teamsToBeDisplayed = [];
  const maxCharLimit = 25;
  let length = 0;

  teams.forEach((team) => {
    const newLength = length + team.name.length;
    if (newLength <= maxCharLimit) {
      length = newLength;
      teamsToBeDisplayed.push(team);
    }
  });

  const totalTeamsToBeHidden = teams.length - teamsToBeDisplayed.length;

  return (
    <div className="teams-badges-wrapper">
      {teamsToBeDisplayed?.map((item) => (
        <TeamBadge key={item.id} id={item.id} name={item.name} />
      ))}
      {totalTeamsToBeHidden > 0 && (
        <TeamBadge id={0} name={`+${totalTeamsToBeHidden}`} />
      )}
    </div>
  );
};

export default TeamsCell;
