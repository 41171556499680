import React from 'react';
import { Link } from 'react-router-dom';
import { Images } from '../../../shared/app-constants';
import { EmailWarmupHeaderProps } from '../types';

const EmailWarmupHeader: React.FC<EmailWarmupHeaderProps> = ({
  emailWarmupHeaderWithReport,
}) => (
  <>
    <div className="d-flex align-items-center">
      {emailWarmupHeaderWithReport ? (
        <>
          <Link to="/warmup" className="text-decoration-none">
            <h2 className="semibold-3 m-0 mr-2">Email Warm-up</h2>
          </Link>
        </>
      ) : (
        <>
          <h2 className="semibold-3 m-0 mr-2">Email Warm-up</h2>
        </>
      )}
      {emailWarmupHeaderWithReport && (
        <>
          <img className="ml-2 mr-2" src={Images.ChevronRight} alt="Arrow" />
          <h2 className="semibold-3 m-0">Report</h2>
        </>
      )}
    </div>
  </>
);

export default EmailWarmupHeader;
