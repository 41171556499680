import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CreateOutcomeRequestPayload,
  DeleteOutcomeRequestPayload,
  UpdateOutcomeRequestPayload,
} from '../../types/request-payload';
import {
  ResponseSuccess,
  ResponseErrorWithHandled,
} from '../../../../shared/types';
import {
  getCustomOutcomes,
  createCustomOutcome,
  updateCustomOutcome,
  deleteCustomOutcome,
  getCustomOutcomesWithPagination,
  getDefaultOutcomesWithPagination,
  getAllOutcomes,
} from '../../helpers/outcome.api';
import { PaginationQueryParams } from '../../../../shared/types/request';

export const getCustomOutcomeRequest = createAsyncThunk<
  ResponseSuccess,
  null,
  { rejectValue: ResponseErrorWithHandled }
>('customOutcome/getCustomOutcomeRequest', async (args, thunkAPI) => {
  try {
    return await getCustomOutcomes();
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getCustomOutcomesWithPaginationRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams & {search?: string},
  { rejectValue: ResponseErrorWithHandled }
>(
  'customOutcome/getCustomOutcomesWithPaginationRequest',
  async ({ search, ...args }, thunkAPI) => {
    try {
      const requestArgs = {
        ...args,
        ...(search && { search })
      };
      return await getCustomOutcomesWithPagination(requestArgs);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const getDefaultOutcomesWithPaginationRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams & {search?: string},
  { rejectValue: ResponseErrorWithHandled }
>(
  'customOutcome/getDefaultOutcomesWithPaginationRequest',
  async ({ search, ...args }, thunkAPI) => {
    try {
      const requestArgs = {
        ...args,
        ...(search && { search })
      };
      return await getDefaultOutcomesWithPagination(requestArgs);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const createCustomOutcomeRequest = createAsyncThunk<
  ResponseSuccess,
  CreateOutcomeRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('customOutcome/createCustomOutcomeRequest', async (payload, thunkAPI) => {
  try {
    return await createCustomOutcome(payload);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

type UpdateCustomOutcomePayloadCreatorArgs = UpdateOutcomeRequestPayload & {
  customOutcomeId: number;
};

export const updateCustomOutcomeRequest = createAsyncThunk<
  ResponseSuccess,
  UpdateCustomOutcomePayloadCreatorArgs,
  { rejectValue: ResponseErrorWithHandled }
>(
  'customOutcome/updateCustomOutcomeRequest',
  async ({ customOutcomeId, ...payload }, thunkAPI) => {
    try {
      return await updateCustomOutcome(customOutcomeId, payload);
    } catch (e: any) {
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const deleteCustomOutcomeRequest = createAsyncThunk<
  ResponseSuccess,
  DeleteOutcomeRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('customOutcome/deleteCustomOutcomeRequest', async ({customOutcomeId, newOutcomeId}, thunkAPI) => {
  try {
    return await deleteCustomOutcome(customOutcomeId, newOutcomeId);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getAllOutcomesRequest = createAsyncThunk<
  ResponseSuccess,
  PaginationQueryParams & {search?: string},
  { rejectValue: ResponseErrorWithHandled }
>('api-token/getAllOutcomesRequest', async ({ search, ...args }, thunkAPI) => {
  try {
    const requestArgs = {
      ...args,
      ...(search && { search })
    };
    return await getAllOutcomes(requestArgs);
  } catch (e: any) {
    return thunkAPI.rejectWithValue(e);
  }
});
