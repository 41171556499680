import React from 'react';
import { SkeletonLoading } from '@saleshandy/design-system';
import Table from '../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../shared/design-system/components/organisms/table/types';
import { getNumericalFormattedDate } from '../../../../../../shared/functions/date';
import { IProps } from './types';

const ApiTokenList: React.FC<IProps> = ({
  apiTokens,
  onAction,
  isRequestPending,
}) => {
  const apiTokenColumnData = [
    {
      dataField: 'label',
      text: 'Label',
      headerAlign: 'left',
      headerLoadingSkeleton: <SkeletonLoading width={54} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
    },
    {
      dataField: 'createdAt',
      text: 'Created date',
      headerAlign: 'left',
      headerLoadingSkeleton: <SkeletonLoading width={54} height={14} />,
      cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
    },
  ];

  apiTokenColumnData.splice(1, 0, {
    dataField: 'user',
    text: 'Created By',
    headerAlign: 'left',
    headerLoadingSkeleton: <SkeletonLoading width={54} height={14} />,
    cellLoadingSkeleton: <SkeletonLoading width={72} height={14} />,
  });

  const actions = [
    { key: 'revoke', icon: 'trash', displayName: 'Delete', position: 'out' },
  ];

  const apiTokenData = apiTokens.map((item) => ({
    key: item.id,
    user: item.user,
    id: item.id,
    createdAt: getNumericalFormattedDate(new Date(item.createdAt)),
    label: item.label,
  }));

  return (
    <Table
      columns={apiTokenColumnData}
      data={apiTokenData}
      actions={actions}
      onAction={onAction}
      onPaginationOptionsChange={null}
      headerVisibleForGenerateColumn={true}
      paginationOptions={{
        options: {
          limit: 1000,
          page: 0,
          totalElements: 1000,
        },
      }}
      pagination={PaginationShowHide.HIDE}
      isLoading={isRequestPending}
    />
  );
};

export default ApiTokenList;
