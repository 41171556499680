import React from 'react';
import { Images } from '../../app-constants';
import ImageIcon from './image-icon';

const ProspectActivityEmailBouncedColored = () => (
  <ImageIcon
    src={Images.ProspectActivityEmailBouncedColored}
    alt="empty-data/"
  />
);

export default ProspectActivityEmailBouncedColored;
