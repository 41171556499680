import React from 'react';
import { Button, EmptyList } from '@saleshandy/design-system';
import { TeamsUI } from '../../../../../shared/svg';
import { redirectWithoutRefresh } from '../../../../../shared/utils';
import { contactAdmin } from '../../../../../shared/utils/contact-admin';
import hasPermission from '../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../shared/utils/access-control/enums/permissions';
import { Routes } from '../../../../../shared/routes';

const TeamsPlanBasedRestriction: React.FC<{
  adminFirstName: string;
  userName: string;
}> = ({ adminFirstName, userName }) => {
  const getBtnLabel = () => {
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      return 'Upgrade Now';
    }

    return 'Contact Owner';
  };

  const handleUpgradePlanBtn = () => {
    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      redirectWithoutRefresh(
        Routes.Setting_Billing_Upgrade_Email_Outreach_Plan,
      );
    } else {
      const emailSubject = 'Upgrade Required to manage teams inside Saleshandy';
      const content =
        'I have discovered that Saleshandy provides team management but we don’t have an access for it in current plan. I would request you to upgrade the plan so that we can start using this and take maximum out of it and manage our teams better.';
      const emailBody = `Hey ${adminFirstName}, %0D%0A %0D%0A${content} %0D%0A %0D%0AThanks, %0D%0A %0D%0A${userName}`;

      contactAdmin(emailSubject, emailBody);
    }
  };

  return (
    <div className="users-and-teams-empty-list plan-based-restriction">
      <EmptyList>
        <EmptyList.Image>
          <TeamsUI />
        </EmptyList.Image>
        <EmptyList.Body>
          <EmptyList.Title>
            Create Unlimited Teams for Successful Collaboration
          </EmptyList.Title>
          <EmptyList.Description>
            Unleash the power of teamwork with our exclusive Teams feature.
            Upgrade now to Scale or higher plans to collaborate seamlessly and
            boost productivity together!{' '}
            <span role="img" aria-label="rocket-emoji">
              🚀
            </span>
          </EmptyList.Description>
          <Button onClick={handleUpgradePlanBtn} className="mb-5">
            {getBtnLabel()}
          </Button>
        </EmptyList.Body>
      </EmptyList>
    </div>
  );
};

export default TeamsPlanBasedRestriction;
