import React from 'react';

import { Webhook } from '../../../../../../../types/webhook';

import Switch, {
  Size,
} from '../../../../../../../../../shared/design-system/components/atoms/switch';
import { Placement } from '../../../../../../../../../shared/design-system/components/overlay';
import { getWebhookStatusFieldValues } from '../../../../../utils/get-webhook-status-field-values';

type IProps = {
  webhook: Webhook;
  onChangeHandler: (webhook: Webhook) => void;
};

const WebhookStatusField: React.FC<IProps> = ({ webhook, onChangeHandler }) => {
  const { className, tooltip, checked } = getWebhookStatusFieldValues(
    webhook.status,
  );

  return (
    <Switch
      checked={checked}
      onChange={() => onChangeHandler(webhook)}
      size={Size.Small}
      className={className}
      tooltip={tooltip}
      placement={Placement.Bottom}
    />
  );
};

export default WebhookStatusField;
