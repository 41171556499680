import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloudUpload } from '@saleshandy/icons';
import { DragAndDrop } from '@saleshandy/design-system';
import Papa from 'papaparse';
import classNames from 'classnames';

import {
  AllowedExtensions,
  CSVFileValidationErrors,
  FileConfig,
} from '../../../../enum';

import ChooseFileInput from '../choose-file-input';
import toaster from '../../../../../../../../../../../shared/toaster';

type IProps = {
  onSelect?: (file: File, headers: string[]) => void;
};

const PreSelect: React.FC<IProps> = ({ onSelect }) => {
  const { t } = useTranslation();

  const [chooseFileInputKey, setChooseFileInputKey] = useState(0);

  const [dragging, setDragging] = useState(false);

  const resetChooseFileInputKey = () => {
    setChooseFileInputKey(1 - chooseFileInputKey);
  };

  const handleCsvError = (message: string) => {
    toaster.error(message);
    resetChooseFileInputKey();
  };

  const onFileSelect = (file: File) => {
    let headers: string[];
    let firstRow: string[];
    let count = 0;
    let failedMessage = '';

    const extension: string[] = file?.name?.split('.');

    if (!extension || extension.length === 0) {
      failedMessage = CSVFileValidationErrors.ExtensionNotAvailable;
      return handleCsvError(failedMessage);
    }

    const isExtensionAllowed = !AllowedExtensions.includes(
      extension[extension.length - 1].trim().toLowerCase(),
    );

    if (isExtensionAllowed) {
      failedMessage = CSVFileValidationErrors.TypeNotSupported;
      return handleCsvError(failedMessage);
    }

    if (file.size > FileConfig.MaxFileSizeInMB * 1024 * 1024) {
      failedMessage = CSVFileValidationErrors.SizeExceeds;
      return handleCsvError(failedMessage);
    }

    Papa.parse<string>(file, {
      step: ({ data }, parser) => {
        if (count > FileConfig.MaxRows) {
          failedMessage = CSVFileValidationErrors.MaxRowsExceeds;
          return parser.abort();
        }

        if (count === 0) {
          headers = data;
        }
        if (count === 1) {
          firstRow = data;
        }
        count += 1;
        return null;
      },
      complete: () => {
        if (!failedMessage && !firstRow) {
          failedMessage = CSVFileValidationErrors.OneRowRequired;
        }
        if (failedMessage) {
          return handleCsvError(failedMessage);
        }
        onSelect(file, headers);
        return null;
      },
      skipEmptyLines: 'greedy',
    });

    return null;
  };

  const dragAndDropClassName = classNames([
    {
      dragging,
    },
  ]);

  return (
    <DragAndDrop
      dragging={dragging}
      setDragging={setDragging}
      handleDroppedFiles={(files) => onFileSelect(files[0])}
      backgroundVariant="solid"
      title={t('messages.drop_your_csv_here')}
      className={dragAndDropClassName}
    >
      <div className="file-selector-input--stage-content pre-select-content">
        <div className="icon-wrapper">
          <CloudUpload />
        </div>
        <p className="title">Drag & Drop your CSV of Emails or Domains</p>
        <p className="or-label">OR</p>
        <ChooseFileInput key={chooseFileInputKey} onSelect={onFileSelect} />
      </div>
    </DragAndDrop>
  );
};

export default PreSelect;
