import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import { GetTasksParams, UpdateTask } from '../../../../utils/types';
import {
  getTasksRequest,
  getTaskStatusCountRequest,
  updateTasksRequest,
} from '../../../../extra-actions';
import TasksFilterBar from './tasks-filter-bar';
import { TaskAction, TaskStatusKey } from '../../../../utils/enums';

const mapStateToProps = (state: RootState) => ({
  getTasksRequestStatus: state.tasks.getTasksRequest.status,
  taskActionRequestStatus: state.tasks.updateTasksRequest.status,
});

const mapDispatchToProps = {
  getTasksRequest: (params: GetTasksParams) => getTasksRequest(params),
  updateTasksRequest: (payload: UpdateTask) => updateTasksRequest(payload),
  getTasksStatusCountRequest: (params: GetTasksParams) =>
    getTaskStatusCountRequest(params),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    onFilterToggle: VoidFunction;
    onSearch: (searchValue: string) => void;
    onTaskTypeFilterApply: (selectedIds: number[]) => void;
    onAction: (action: TaskAction) => void;
    onStartAllTasks: VoidFunction;
    onRefresh: VoidFunction;
    tasksCount: number;
    status: TaskStatusKey;
  };

export default withRouter(connector(TasksFilterBar));
