import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IProps } from './types';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';

const ReportsContent = React.lazy(() =>
  retryLazy(() => import('./components/reports-content')),
);

const Reports: React.FC<IProps> = () => (
  <Container fluid className="reports-container reports p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/reports/:tab" component={ReportsContent} />
          <Route exact path="/reports" component={ReportsContent} />
          <Redirect to="/reports" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

function isPropsAreEqual(prevProps: IProps, nextProps: IProps) {
  return isEqual(prevProps, nextProps);
}

export default memo(Reports, isPropsAreEqual);
