import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import {
  deleteCustomFieldRequest,
  getCustomFieldsWithPaginationRequest,
  createCustomFieldRequest,
  updateCustomFieldRequest,
  resetCustomFieldsData,
} from './custom-field-slice';
import {
  CreateCustomFieldRequestPayload,
  UpdateCustomFieldRequestPayload,
} from '../../types/request-payload';
import CustomField from './custom-field';
import { PaginationQueryParams } from '../../../../shared/types/request';
import { getDefaultFieldsWithPaginationRequest } from './extra-actions';

const mapStateToProps = (state: RootState) => ({
  getCustomFieldRequestStatus:
    state.customField.getCustomFieldsWithPaginationRequest.status,

  getCustomFieldRequestMessage:
    state.customField.getCustomFieldsWithPaginationRequest.message,
  getCustomFieldRequestError:
    state.customField.getCustomFieldsWithPaginationRequest.error,

  getDefaultFieldRequestStatus:
    state.customField.getDefaultFieldsWithPaginationRequest.status,

  getDefaultFieldRequestMessage:
    state.customField.getDefaultFieldsWithPaginationRequest.message,
  getDefaultFieldRequestError:
    state.customField.getDefaultFieldsWithPaginationRequest.error,

  createCustomFieldRequestStatus:
    state.customField.createCustomFieldRequest.status,
  createCustomFieldRequestMessage:
    state.customField.createCustomFieldRequest.message,
  createCustomFieldRequestError:
    state.customField.createCustomFieldRequest.error,

  updateCustomFieldRequestStatus:
    state.customField.updateCustomFieldRequest.status,
  updateCustomFieldRequestMessage:
    state.customField.updateCustomFieldRequest.message,
  updateCustomFieldRequestError:
    state.customField.updateCustomFieldRequest.error,

  deleteCustomFieldRequestStatus:
    state.customField.deleteCustomFieldRequest.status,
  deleteCustomFieldRequestMessage:
    state.customField.deleteCustomFieldRequest.message,
  deleteCustomFieldRequestError:
    state.customField.deleteCustomFieldRequest.error,

  customField: state.customField.customField,
  defaultField: state.customField.defaultField,
  defaultFieldPagination: {
    ...state.customField.defaultFieldPagination,
    pagesCount: null,
    options: {
      ...state.customField.defaultFieldPagination.options,
      totalElements: state.customField.defaultFieldPagination.count,
    },
  },
  customFieldPagination: {
    ...state.customField.customFieldPagination,
    pagesCount: null,
    options: {
      ...state.customField.customFieldPagination.options,
      totalElements: state.customField.customFieldPagination.count,
    },
  },
  isLoading: state.customField.isLoading,

  subscriptionPlan: state.home.subscription?.planCode,
  planTransitionDetails: state.home.subscription?.planTransitionDetails,
});

const mapDispatchToProps = {
  sendGetCustomFieldRequest: (params: PaginationQueryParams) =>
    getCustomFieldsWithPaginationRequest(params),
  sendGetDefaultFieldRequest: (params: PaginationQueryParams) =>
    getDefaultFieldsWithPaginationRequest(params),

  sendCreateCustomFieldRequest: (payload: CreateCustomFieldRequestPayload) =>
    createCustomFieldRequest(payload),

  sendUpdateCustomFieldRequest: (
    customFieldId: number,
    payload: UpdateCustomFieldRequestPayload,
  ) =>
    updateCustomFieldRequest({
      customFieldId,
      label: payload.label,
      fallbackText: payload.fallbackText,
    }),

  sendDeleteCustomFieldRequest: (customFieldId: number) =>
    deleteCustomFieldRequest(customFieldId),

  resetCustomFieldsData: () => resetCustomFieldsData(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    t: (x: string) => React.ReactNode;
  };

export default connector(CustomField);
