import React, { memo, useMemo, useState, useRef, useEffect } from 'react';
import { DateTime } from 'luxon';
import { DateFilterProps } from './type';
import { stringToDate } from '../../../../functions/date';
import Icon from '../../atoms/icon';
import { getUserTimeZone } from '../../../../utils/user-details';
import DatePickerComponent from '../../atoms/date-picker/DatePicker';

const DateFilter: React.FC<DateFilterProps> = ({ onSelect, values = [] }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const datePickerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target as Node) &&
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  const onDateChange = (dates: Date[]) => {
    onSelect([dates[0] || null, dates[1] || null]);
    if (dates[1]) {
      setIsOpen(false);
    }
  };

  const handleInputClick = () => {
    setIsOpen((prevState) => !prevState);
  };

  const startDate = useMemo(
    () =>
      values[0]?.date
        ? stringToDate(values[0].date)
        : DateTime.fromJSDate(new Date()).setZone(getUserTimeZone()).toJSDate(),
    [values],
  );

  const endDate = useMemo(
    () => (values[1]?.date ? stringToDate(values[1].date) : null),
    [values],
  );

  const Input = React.forwardRef<
    HTMLInputElement,
    React.HTMLProps<HTMLInputElement>
  >(({ value, onChange }, ref) => (
    <div
      className="bs-input date-picker-container"
      style={{ backgroundColor: '#f9fafb' }}
    >
      <input
        placeholder="Select"
        onClick={handleInputClick}
        className="input bs-input"
        onFocus={() => setIsOpen(true)}
        onChange={onChange}
        value={value.toString().length > 15 ? value : ''}
        ref={ref}
        readOnly
        style={{ backgroundColor: '#f9fafb' }}
      />
      <div className="d-flex align-items-center p-2">
        <Icon
          className="cal-icon mr-1 sh-close close-icon"
          identifier="close"
          onClick={() => {
            onSelect([]);
            if (inputRef.current) {
              inputRef.current.value = '';
            }
          }}
        />
        <Icon
          className="cal-icon"
          identifier="calendar-today"
          onClick={handleInputClick}
        />
      </div>
    </div>
  ));

  return (
    <div
      className={`mt-1 prospect-filter-date-picker ${isOpen ? 'open' : ''}`}
      style={{ height: '4rem' }}
      ref={inputRef}
    >
      <Input
        value={
          startDate && endDate
            ? `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
            : ''
        }
        onClick={handleInputClick}
        ref={inputRef}
      />
      {isOpen && (
        <div
          style={{
            position: 'relative',
            transform: 'translate(0px, -350.75px)',
          }}
          ref={datePickerRef}
        >
          <DatePickerComponent
            start_date={startDate}
            end_date={endDate}
            onDateChange={onDateChange}
          />
        </div>
      )}
    </div>
  );
};

export default memo(DateFilter);
