import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../store/root-reducer';
import ImportEmailsDomainsViaCsv from './import-emails-domains-via-csv';

const mapStateToProps = (state: RootState) => ({
  importDoNotContactListEmailsAndDomainsRequestStatus:
    state.doNotContact.importDoNotContactListEmailsAndDomainsRequest.status,
  importDoNotContactListEmailsAndDomainsResponse:
    state.doNotContact.importDoNotContactListEmailsAndDomainsResponse,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  isLoading: boolean;
  isGlobalList: boolean;
  onCancel: VoidFunction;
  onSubmit: (file: File, emailsDomainsColumn: string) => void;
};

export default connector(ImportEmailsDomainsViaCsv);
