import React from 'react';

type IProps = {
  content: React.ReactNode;
  className?: string;
};

const Description: React.FC<IProps> = ({ className = '', content }) => (
  <div className={`change-email-modal--description ${className}`}>
    <p>{content}</p>
  </div>
);

export default Description;
