import React from 'react';
import Pagination from '../../pagination';
import { constants } from '../../../../enums/constants';

const PaginationWrapper: React.FC<any> = (props) => {
  const {
    dataSize,
    page,
    showRowsPerPage,
    isNewPagination,
    onPageChange,
    sequenceFixedPageSize,
    fixedPageSize,
  } = props;

  let defaultSize = sequenceFixedPageSize
    ? constants.DEFAULT_SEQUENCE_PAGE_SIZE
    : constants.DEFAULT_PAGE_SIZE;
  if(fixedPageSize){
    defaultSize = fixedPageSize || defaultSize;
  }
  const perPageLimit = isNewPagination ? showRowsPerPage : defaultSize;
  const totalPage = Math.ceil(dataSize / perPageLimit);

  return (
    <Pagination
      currentPage={page}
      totalPages={totalPage}
      onChange={onPageChange}
    />
  );
};

export default PaginationWrapper;
