import React from 'react';
import { Images } from '../../../shared/app-constants';
import { TaskStatusKey } from '../utils/enums';

const EmptyList = ({ activeTab }) => {
  const getTabString = () => {
    if (activeTab === TaskStatusKey.DueToday) {
      return 'pending';
    }
    if (activeTab === TaskStatusKey.Upcoming) {
      return 'upcoming';
    }
    if (activeTab === TaskStatusKey.Overdue) {
      return 'overdue';
    }
    if (activeTab === TaskStatusKey.Completed) {
      return 'completed';
    }

    return 'skipped';
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center empty-task-list">
      <img src={Images.EmptyData6} alt="empty-data" />
      <div className="mt-4 d-flex flex-column text-center">
        <span className="semibold-3 popover-arrow-color-txt">Hurrah !!!</span>
        <span className="regular-2 font-medium gray-txt-15 mt-2">
          {` You don’t have any ${getTabString()} tasks.`}
        </span>
      </div>
    </div>
  );
};

export default EmptyList;
