export enum TaskStatusDisplayLabel {
  DueToday = 'Due Today',
  Upcoming = 'Upcoming',
  Overdue = 'Overdue',
  Completed = 'Completed',
  Skipped = 'Skipped',
}

export enum TaskStatusKey {
  DueToday = 'dueToday',
  Upcoming = 'upcoming',
  Overdue = 'overdue',
  Completed = 'completed',
  Skipped = 'skipped',
}

export enum TaskStatusParamsKey {
  Upcoming = 1,
  DueToday = 2,
  Completed = 3,
  Skipped = 4,
  Overdue = 5,
}

export enum TaskAction {
  ExecuteTask = 0,
  SnoozeTask = 1,
  SkipTask = 2,
  ReassignTask = 3,
}

export enum CallOutcome {
  Interested = 'Interested',
  CallbackLater = 'Callback Later',
  Followup = 'Followup',
  NotInterested = 'Not Interested',
  WrongNumber = 'Wrong Number',
  NoAnswer = 'No Answer',
  LeftVoicemail = 'Left Voicemail',
  NotInService = 'Not In Service',
}
