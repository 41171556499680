import React from 'react';
import { Images } from '../../app-constants';
import ImageIcon from './image-icon';

const ProspectRemovedFromBlacklistColored = () => (
  <ImageIcon
    src={Images.ProspectRemovedFromBlacklistColored}
    alt="empty-data/"
  />
);

export default ProspectRemovedFromBlacklistColored;
