import React from 'react';

export const MessageCircle = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12.384 4.992c-1.968-.08-3.89.48-5.405 1.555-1.514 1.075-2.51 2.58-2.842 4.22-.332 1.636.013 3.332.992 4.779a1 1 0 01.12.876l-.747 2.242 2.99-.637a1 1 0 01.645.079c1.714.832 3.72 1.086 5.64.706 1.92-.38 3.594-1.363 4.726-2.735 1.128-1.37 1.644-3.035 1.482-4.689-.162-1.656-.997-3.228-2.388-4.415-1.394-1.189-3.245-1.901-5.213-1.981zm-9.44 16.012a.996.996 0 00.281-.024l4.357-.927c2.04.907 4.363 1.161 6.584.72 2.338-.462 4.433-1.669 5.88-3.424 1.45-1.76 2.146-3.947 1.93-6.156-.217-2.207-1.325-4.244-3.081-5.742-1.754-1.496-4.04-2.36-6.43-2.457-2.39-.097-4.75.58-6.644 1.922-1.895 1.345-3.204 3.277-3.645 5.454-.408 2.017-.038 4.086 1.025 5.87l-1.144 3.433a.995.995 0 00.198 1 .995.995 0 00.69.331z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M12 11a1 1 0 011 1v.01a1 1 0 11-2 0V12a1 1 0 011-1zM8 11a1 1 0 011 1v.01a1 1 0 11-2 0V12a1 1 0 011-1zM16 11a1 1 0 011 1v.01a1 1 0 11-2 0V12a1 1 0 011-1z"
      clipRule="evenodd"
    />
  </svg>
);
