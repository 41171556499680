export enum SortByKey {
  SentAt = 'sentAt',
  Opened = 'opened',
  Clicked = 'clicked',
  LastActivity = 'lastActivity',
}

export enum RecipientType {
  to = 'to',
  cc = 'cc',
  bcc = 'bcc',
}

export enum MailboxEmailsActivites {
  Opened = 'opened',
  Clicked = 'clicked',
}

export enum PaginationOption {
  PAGE = 'page',
  SORT_BY_KEY = 'sortByKey',
  ORDER = 'order',
  SEARCH = 'search',
  OWNERS = 'mailbox',
}
