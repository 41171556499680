const agencyClientRedirectURLOnLogout = 'agency-client-redirect-url-on-logout';

export const getAgencyClientRedirectURLOnLogout = () =>
  localStorage.getItem(agencyClientRedirectURLOnLogout);

export const setAgencyClientRedirectURLOnLogout = () =>
  localStorage.setItem(agencyClientRedirectURLOnLogout, '/agency/login');

export const clearAgencyClientRedirectURLOnLogout = () =>
  localStorage.removeItem(agencyClientRedirectURLOnLogout);
