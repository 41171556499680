import React from 'react';
import { AlertTriangle } from '@saleshandy/icons';
import { Webhook } from '../../../../../../../types/webhook';
import TruncatedTextWithTooltip from '../../../../../../../../../shared/components/truncated-text-with-tooltip';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../../../shared/design-system/components/overlay';
import { WebhookFailedMessages } from '../../../../../../../enums/webhook';

type IProps = {
  webhook: Webhook;
};

const WebhookUrlField: React.FC<IProps> = ({ webhook }) => (
  <p>
    <TruncatedTextWithTooltip
      length={24}
      string={webhook?.url || ''}
      placement={Placement.Bottom}
      tooltipClassName="webhook-lg-tooltip"
    />
    {webhook?.failed && (
      <OverlayTooltip
        text={
          WebhookFailedMessages[webhook.failedData.body.status] ||
          WebhookFailedMessages.generic
        }
        placement={Placement.Bottom}
        className="webhook-lg-tooltip"
      >
        <div className="webhook-failed-icon">
          <AlertTriangle />
        </div>
      </OverlayTooltip>
    )}
  </p>
);

export default WebhookUrlField;
