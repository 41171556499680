export const DEPARTMENT_DATA = [
  {
    key: 'FN',
    value: 'Finance',
    children: [
      { key: 'FN000', value: 'Accounting' },
      { key: 'FN001', value: 'Investment' },
      { key: 'FN002', value: 'Tax' },
    ],
  },
  {
    key: 'HR',
    value: 'Hr',
    children: [
      { key: 'HR000', value: 'Compensation' },
      { key: 'HR001', value: 'Employee Development' },
      { key: 'HR002', value: 'Recruiting' },
    ],
  },
  {
    key: 'HL',
    value: 'Health',
    children: [
      { key: 'HL000', value: 'Dental' },
      { key: 'HL001', value: 'Doctor' },
      { key: 'HL002', value: 'Fitness' },
      { key: 'HL003', value: 'Nursing' },
      { key: 'HL004', value: 'Therapy' },
      { key: 'HL005', value: 'Wellness' },
    ],
  },
  {
    key: 'LG',
    value: 'Legal',
    children: [
      { key: 'LG000', value: 'Judicial' },
      { key: 'LG001', value: 'Lawyer' },
      { key: 'LG002', value: 'Paralegal' },
    ],
  },
  {
    key: 'MR',
    value: 'Marketing',
    children: [
      { key: 'MR000', value: 'Brand Marketing' },
      { key: 'MR001', value: 'Content Marketing' },
      { key: 'MR002', value: 'Product Marketing' },
      { key: 'MR003', value: 'Events' },
      { key: 'MR004', value: 'Media Relations' },
      { key: 'MR005', value: 'Broadcasting' },
      { key: 'MR006', value: 'Editorial' },
      { key: 'MR007', value: 'Journalism' },
      { key: 'MR008', value: 'Video' },
      { key: 'MR009', value: 'Writing' },
    ],
  },
  {
    key: 'OP',
    value: 'Operations',
    children: [
      { key: 'OP000', value: 'Logistics' },
      { key: 'OP001', value: 'Office Management' },
      { key: 'OP002', value: 'Product' },
      { key: 'OP003', value: 'Project Management' },
      { key: 'OP004', value: 'Customer Success' },
      { key: 'OP005', value: 'Support' },
    ],
  },
  {
    key: 'PR',
    value: 'Product & Engineering',
    children: [
      { key: 'PR000', value: 'Graphic Design' },
      { key: 'PR001', value: 'Product Design' },
      { key: 'PR002', value: 'Web Design' },
      { key: 'PR003', value: 'Data' },
      { key: 'PR004', value: 'Devops' },
      { key: 'PR005', value: 'Electrical' },
      { key: 'PR006', value: 'Mechanical' },
      { key: 'PR007', value: 'Network' },
      { key: 'PR008', value: 'Information Technology' },
      { key: 'PR009', value: 'Project Engineering' },
      { key: 'PR010', value: 'Quality Assurance' },
      { key: 'PR011', value: 'Security' },
      { key: 'PR012', value: 'Software' },
      { key: 'PR013', value: 'Systems' },
      { key: 'PR014', value: 'Web' },
    ],
  },
  {
    key: 'SL',
    value: 'Sales',
    children: [
      { key: 'SL000', value: 'Accounts' },
      { key: 'SL001', value: 'Business Development' },
      { key: 'SL002', value: 'Pipeline' },
    ],
  },
];

export const LOCATION_DATA = [
  {
    key: 'AF',
    value: 'Africa',
    children: [
      { key: 'AF000', value: 'Algeria' },
      { key: 'AF001', value: 'Angola' },
      { key: 'AF002', value: 'Benin' },
      { key: 'AF003', value: 'Botswana' },
      { key: 'AF004', value: 'Burkina Faso' },
      { key: 'AF005', value: 'Burundi' },
      { key: 'AF006', value: 'Cameroon' },
      { key: 'AF007', value: 'Cape Verde' },
      { key: 'AF008', value: 'Central African Republic' },
      { key: 'AF009', value: 'Chad' },
      { key: 'AF010', value: 'Comoros' },
      { key: 'AF011', value: 'Congo (DRC)' },
      { key: 'AF012', value: 'Congo (Republic)' },
      { key: 'AF013', value: 'Djibouti' },
      { key: 'AF014', value: 'Egypt' },
      { key: 'AF015', value: 'Equatorial Guinea' },
      { key: 'AF016', value: 'Eritrea' },
      { key: 'AF017', value: 'Ethiopia' },
      { key: 'AF018', value: 'Gabon' },
      { key: 'AF019', value: 'Gambia' },
      { key: 'AF020', value: 'Ghana' },
      { key: 'AF021', value: 'Guinea' },
      { key: 'AF022', value: 'Guinea-Bissau' },
      { key: 'AF023', value: 'Kenya' },
      { key: 'AF024', value: 'Lesotho' },
      { key: 'AF025', value: 'Liberia' },
      { key: 'AF026', value: 'Libya' },
      { key: 'AF027', value: 'Madagascar' },
      { key: 'AF028', value: 'Malawi' },
      { key: 'AF029', value: 'Mali' },
      { key: 'AF030', value: 'Mauritania' },
      { key: 'AF031', value: 'Mauritius' },
      { key: 'AF032', value: 'Morocco' },
      { key: 'AF033', value: 'Mozambique' },
      { key: 'AF034', value: 'Namibia' },
      { key: 'AF035', value: 'Niger' },
      { key: 'AF036', value: 'Nigeria' },
      { key: 'AF037', value: 'Rwanda' },
      { key: 'AF038', value: 'Sao Tome and Principe' },
      { key: 'AF039', value: 'Senegal' },
      { key: 'AF040', value: 'Seychelles' },
      { key: 'AF041', value: 'Sierra Leone' },
      { key: 'AF042', value: 'Somalia' },
      { key: 'AF043', value: 'South Africa' },
      { key: 'AF044', value: 'Sudan' },
      { key: 'AF045', value: 'Swaziland' },
      { key: 'AF046', value: 'Tanzania' },
      { key: 'AF047', value: 'Togo' },
      { key: 'AF048', value: 'Tunisia' },
      { key: 'AF049', value: 'Uganda' },
      { key: 'AF050', value: 'Zambia' },
      { key: 'AF051', value: 'Zimbabwe' },
    ],
  },
  {
    key: 'AS',
    value: 'Asia',
    children: [
      { key: 'AS000', value: 'Afghanistan' },
      { key: 'AS001', value: 'Armenia' },
      { key: 'AS002', value: 'Azerbaijan' },
      { key: 'AS003', value: 'Bahrain' },
      { key: 'AS004', value: 'Bangladesh' },
      { key: 'AS005', value: 'Bhutan' },
      { key: 'AS006', value: 'Brunei Darussalam' },
      { key: 'AS007', value: 'Cambodia' },
      { key: 'AS008', value: 'China' },
      { key: 'AS009', value: 'Georgia (C)' },
      { key: 'AS010', value: 'India' },
      { key: 'AS011', value: 'Indonesia' },
      { key: 'AS012', value: 'Iran' },
      { key: 'AS013', value: 'Israel' },
      { key: 'AS014', value: 'Japan' },
      { key: 'AS015', value: 'Jordan' },
      { key: 'AS016', value: 'Kazakhstan' },
      { key: 'AS017', value: 'Kuwait' },
      { key: 'AS018', value: 'Kyrgyzstan' },
      { key: 'AS019', value: 'Laos' },
      { key: 'AS020', value: 'Lebanon' },
      { key: 'AS021', value: 'Malaysia' },
      { key: 'AS022', value: 'Maldives' },
      { key: 'AS023', value: 'Mongolia' },
      { key: 'AS024', value: 'Myanmar' },
      { key: 'AS025', value: 'Nepal' },
      { key: 'AS026', value: 'North Korea' },
      { key: 'AS027', value: 'Oman' },
      { key: 'AS028', value: 'Pakistan' },
      { key: 'AS029', value: 'Philippines' },
      { key: 'AS030', value: 'Qatar' },
      { key: 'AS031', value: 'Saudi Arabia' },
      { key: 'AS032', value: 'Singapore' },
      { key: 'AS033', value: 'South Korea' },
      { key: 'AS034', value: 'Sri Lanka' },
      { key: 'AS035', value: 'Syria' },
      { key: 'AS036', value: 'Taiwan' },
      { key: 'AS037', value: 'Tajikistan' },
      { key: 'AS038', value: 'Thailand' },
      { key: 'AS039', value: 'Turkey' },
      { key: 'AS040', value: 'Turkmenistan' },
      { key: 'AS041', value: 'United Arab Emirates' },
      { key: 'AS042', value: 'Uzbekistan' },
      { key: 'AS043', value: 'Vietnam' },
      { key: 'AS044', value: 'Yemen' },
    ],
  },
  {
    key: 'CM',
    value: 'CAN > Metro Areas',
    children: [
      { key: 'CM000', value: 'AB > Calgary Area' },
      { key: 'CM001', value: 'AB > Edmonton Area' },
      { key: 'CM002', value: 'BC > Abbotsford Area' },
      { key: 'CM003', value: 'BC > Kelowna Area' },
      { key: 'CM004', value: 'BC > Vancouver Area' },
      { key: 'CM005', value: 'BC > Victoria Area' },
      { key: 'CM006', value: 'MB > Winnipeg Area' },
      { key: 'CM007', value: 'NB > Moncton Area' },
      { key: 'CM008', value: 'NB > St. John Area' },
      { key: 'CM009', value: 'NL > St. John Area' },
      { key: 'CM010', value: 'NS > Halifax Area' },
      { key: 'CM011', value: 'NT > Yellowknife Area' },
      { key: 'CM012', value: 'ON > Barrie Area' },
      { key: 'CM013', value: 'ON > Brantford Area' },
      { key: 'CM014', value: 'ON > Guelph Area' },
      { key: 'CM015', value: 'ON > Hamilton Area' },
      { key: 'CM016', value: 'ON > Kingston Area' },
      { key: 'CM017', value: 'ON > Kitchener Area' },
      { key: 'CM018', value: 'ON > London Area' },
      { key: 'CM019', value: 'ON > Ottawa Area' },
      { key: 'CM020', value: 'ON > Peterborough Area' },
      { key: 'CM021', value: 'ON > St. Catherines Area' },
      { key: 'CM022', value: 'ON > Sudbury Area' },
      { key: 'CM023', value: 'ON > Thunder Bay Area' },
      { key: 'CM024', value: 'ON > Toronto Area' },
      { key: 'CM025', value: 'ON > Windsor Area' },
      { key: 'CM026', value: 'PEI > Charlottetown Area' },
      { key: 'CM027', value: 'QC > Chicoutimi Area' },
      { key: 'CM028', value: 'QC > Montreal Area' },
      { key: 'CM029', value: 'QC > Quebec Area' },
      { key: 'CM030', value: 'QC > Sherbrooke Area' },
      { key: 'CM031', value: 'QC > Trois Rivieres Area' },
      { key: 'CM032', value: 'SK > Regina Area' },
      { key: 'CM033', value: 'SK > Saskatoon Area' },
    ],
  },
  {
    key: 'CP',
    value: 'CAN > Provinces',
    children: [
      { key: 'CP000', value: 'Alberta' },
      { key: 'CP001', value: 'British Columbia' },
      { key: 'CP002', value: 'Manitoba' },
      { key: 'CP003', value: 'New Brunswick' },
      { key: 'CP004', value: 'Newfoundland' },
      { key: 'CP005', value: 'Northwest Territories' },
      { key: 'CP006', value: 'Nova Scotia' },
      { key: 'CP007', value: 'Nunavut' },
      { key: 'CP008', value: 'Ontario' },
      { key: 'CP009', value: 'Prince Edward Island' },
      { key: 'CP010', value: 'Quebec' },
      { key: 'CP011', value: 'Saskatchewan' },
      { key: 'CP012', value: 'Yukon' },
    ],
  },
  {
    key: 'EU',
    value: 'Europe',
    children: [
      { key: 'EU000', value: 'Albania' },
      { key: 'EU001', value: 'Andorra' },
      { key: 'EU002', value: 'Austria' },
      { key: 'EU003', value: 'Belarus' },
      { key: 'EU004', value: 'Belgium' },
      { key: 'EU005', value: 'Bosnia Herzegovina' },
      { key: 'EU006', value: 'Bulgaria' },
      { key: 'EU007', value: 'Croatia' },
      { key: 'EU008', value: 'Cyprus' },
      { key: 'EU009', value: 'Czech Republic' },
      { key: 'EU010', value: 'Denmark' },
      { key: 'EU011', value: 'Estonia' },
      { key: 'EU012', value: 'Finland' },
      { key: 'EU013', value: 'France' },
      { key: 'EU014', value: 'Germany' },
      { key: 'EU015', value: 'Greece' },
      { key: 'EU016', value: 'Hungary' },
      { key: 'EU017', value: 'Iceland' },
      { key: 'EU018', value: 'Ireland' },
      { key: 'EU019', value: 'Italy' },
      { key: 'EU020', value: 'Latvia' },
      { key: 'EU021', value: 'Liechtenstein' },
      { key: 'EU022', value: 'Lithuania' },
      { key: 'EU023', value: 'Luxembourg' },
      { key: 'EU024', value: 'Macedonia (FYROM)' },
      { key: 'EU025', value: 'Malta' },
      { key: 'EU026', value: 'Moldova' },
      { key: 'EU027', value: 'Monaco' },
      { key: 'EU028', value: 'Netherlands' },
      { key: 'EU029', value: 'Norway' },
      { key: 'EU030', value: 'Poland' },
      { key: 'EU031', value: 'Portugal' },
      { key: 'EU032', value: 'Romania' },
      { key: 'EU033', value: 'Russia' },
      { key: 'EU034', value: 'San Marino' },
      { key: 'EU035', value: 'Slovakia' },
      { key: 'EU036', value: 'Slovenia' },
      { key: 'EU037', value: 'Spain' },
      { key: 'EU038', value: 'Sweden' },
      { key: 'EU039', value: 'Switzerland' },
      { key: 'EU040', value: 'Ukraine' },
      { key: 'EU041', value: 'United Kingdom' },
    ],
  },
  {
    key: 'NA',
    value: 'North America',
    children: [
      { key: 'NA000', value: 'Antigua and Barbuda' },
      { key: 'NA001', value: 'Bahamas' },
      { key: 'NA002', value: 'Barbados' },
      { key: 'NA003', value: 'Belize' },
      { key: 'NA004', value: 'Canada' },
      { key: 'NA005', value: 'Costa Rica' },
      { key: 'NA006', value: 'Cuba' },
      { key: 'NA007', value: 'Dominica' },
      { key: 'NA008', value: 'Dominican Republic' },
      { key: 'NA009', value: 'El Salvador' },
      { key: 'NA010', value: 'Grenada' },
      { key: 'NA011', value: 'Guatemala' },
      { key: 'NA012', value: 'Haiti' },
      { key: 'NA013', value: 'Honduras' },
      { key: 'NA014', value: 'Jamaica' },
      { key: 'NA015', value: 'Mexico' },
      { key: 'NA016', value: 'Nicaragua' },
      { key: 'NA017', value: 'Panama' },
      { key: 'NA018', value: 'Saint Kitts and Nevis' },
      { key: 'NA019', value: 'Saint Vincent and the Grenadines' },
      { key: 'NA020', value: 'Trinidad and Tobago' },
      { key: 'NA021', value: 'United States' },
    ],
  },
  {
    key: 'OC',
    value: 'Oceania',
    children: [
      { key: 'OC000', value: 'Australia' },
      { key: 'OC001', value: 'Cook Islands' },
      { key: 'OC002', value: 'Fiji' },
      { key: 'OC003', value: 'Kiribati' },
      { key: 'OC004', value: 'New Zealand' },
      { key: 'OC005', value: 'Papua New Guinea' },
      { key: 'OC006', value: 'Samoa' },
      { key: 'OC007', value: 'Solomon Islands' },
      { key: 'OC008', value: 'Timor-Leste' },
      { key: 'OC009', value: 'Tonga' },
      { key: 'OC010', value: 'Vanuatu' },
    ],
  },
  {
    key: 'SA',
    value: 'South America',
    children: [
      { key: 'SA000', value: 'Argentina' },
      { key: 'SA001', value: 'Bolivia' },
      { key: 'SA002', value: 'Brazil' },
      { key: 'SA003', value: 'Chile' },
      { key: 'SA004', value: 'Colombia' },
      { key: 'SA005', value: 'Ecuador' },
      { key: 'SA006', value: 'Guyana' },
      { key: 'SA007', value: 'Paraguay' },
      { key: 'SA008', value: 'Peru' },
      { key: 'SA009', value: 'Suriname' },
      { key: 'SA010', value: 'Uruguay' },
      { key: 'SA011', value: 'Venezuela' },
    ],
  },
  {
    key: 'UM',
    value: 'US > Metro Areas',
    children: [
      { key: 'UM000', value: 'TX > Abilene Area' },
      { key: 'UM001', value: 'TX > Amarillo Area' },
      { key: 'UM002', value: 'TX > Austin Area' },
      { key: 'UM003', value: 'TX > Beaumont Area' },
      { key: 'UM004', value: 'TX > Bryan Area' },
      { key: 'UM005', value: 'TX > Corpus Christi Area' },
      { key: 'UM006', value: 'TX > Dallas Area' },
      { key: 'UM007', value: 'TX > El Paso Area' },
      { key: 'UM008', value: 'TX > Harlingen Area' },
      { key: 'UM009', value: 'TX > Houston Area' },
      { key: 'UM010', value: 'TX > Laredo Area' },
      { key: 'UM011', value: 'TX > Lubbock Area' },
      { key: 'UM012', value: 'TX > Odessa Area' },
      { key: 'UM013', value: 'TX > San Angelo Area' },
      { key: 'UM014', value: 'TX > San Antonio Area' },
      { key: 'UM015', value: 'TX > Tyler Area' },
      { key: 'UM016', value: 'TX > Victoria Area' },
      { key: 'UM017', value: 'TX > Wichita Falls Area' },
      { key: 'UM018', value: 'UT > Salt Lake City Area' },
      { key: 'UM019', value: 'VA > Charlottesville Area' },
      { key: 'UM020', value: 'VA > Hampton Roads Area' },
      { key: 'UM021', value: 'VA > Harrisonburg Area' },
      { key: 'UM022', value: 'VA > Richmond Area' },
      { key: 'UM023', value: 'VA > Roanoke Area' },
      { key: 'UM024', value: 'VT > Burlington Area' },
      { key: 'UM025', value: 'WA > Seattle Area' },
      { key: 'UM026', value: 'WA > Spokane Area' },
      { key: 'UM027', value: 'WA > Tri Cities Area' },
      { key: 'UM028', value: 'WI > Green Bay Area' },
      { key: 'UM029', value: 'WI > La Crosse Area' },
      { key: 'UM030', value: 'WI > Madison Area' },
      { key: 'UM031', value: 'WI > Milwaukee Area' },
      { key: 'UM032', value: 'WI > Wausau Area' },
      { key: 'UM033', value: 'WV > Bluefield Area' },
      { key: 'UM034', value: 'WV > Charleston Area' },
      { key: 'UM035', value: 'WV > Clarksburg Area' },
      { key: 'UM036', value: 'WV > Parkersburg Area' },
      { key: 'UM037', value: 'WY > Casper Area' },
      { key: 'UM038', value: 'WY > Cheyenne Area' },
      { key: 'UM039', value: 'CA > Los Angeles Area' },
      { key: 'UM040', value: 'CA > Monterey Bay Area' },
      { key: 'UM041', value: 'CA > Palm Springs Area' },
      { key: 'UM042', value: 'CA > Sacramento Area' },
      { key: 'UM043', value: 'CA > San Diego Area' },
      { key: 'UM044', value: 'CA > San Francisco Area' },
      { key: 'UM045', value: 'CA > San Jose Area' },
      { key: 'UM046', value: 'CA > Santa Barbara Area' },
      { key: 'UM047', value: 'CO > Colorado Springs Area' },
      { key: 'UM048', value: 'CO > Denver Area' },
      { key: 'UM049', value: 'CO > Grand Junction Area' },
      { key: 'UM050', value: 'CT > Hartford Area' },
      { key: 'UM051', value: 'DC > Washington Area' },
      { key: 'UM052', value: 'FL > Fort Myers Area' },
      { key: 'UM053', value: 'FL > Gainesville Area' },
      { key: 'UM054', value: 'FL > Jacksonville Area' },
      { key: 'UM055', value: 'FL > Miami Area' },
      { key: 'UM056', value: 'FL > Orlando Area' },
      { key: 'UM057', value: 'FL > Panama City Area' },
      { key: 'UM058', value: 'FL > Tallahassee Area' },
      { key: 'UM059', value: 'FL > Tampa Bay Area' },
      { key: 'UM060', value: 'FL > West Palm Beach Area' },
      { key: 'UM061', value: 'GA > Albany Area' },
      { key: 'UM062', value: 'GA > Atlanta Area' },
      { key: 'UM063', value: 'GA > Augusta Area' },
      { key: 'UM064', value: 'GA > Columbus Area' },
      { key: 'UM065', value: 'GA > Macon Area' },
      { key: 'UM066', value: 'GA > Savannah Area' },
      { key: 'UM067', value: 'HI > Honolulu Area' },
      { key: 'UM068', value: 'IA > Cedar Rapids Area' },
      { key: 'UM069', value: 'IA > Davenport Area' },
      { key: 'UM070', value: 'IA > Des Moines Area' },
      { key: 'UM071', value: 'IA > Ottumwa Area' },
      { key: 'UM072', value: 'IA > Sioux City Area' },
      { key: 'UM073', value: 'ID > Boise City Area' },
      { key: 'UM074', value: 'ID > Idaho Falls Area' },
      { key: 'UM075', value: 'ID > Twin Falls Area' },
      { key: 'UM076', value: 'IL > Chicago Area' },
      { key: 'UM077', value: 'IL > Peoria Area' },
      { key: 'UM078', value: 'IL > Quincy Area' },
      { key: 'UM079', value: 'IL > Rockford Area' },
      { key: 'UM080', value: 'IL > Springfield Area' },
      { key: 'UM081', value: 'IN > Evansville Area' },
      { key: 'UM082', value: 'IN > Fort Wayne Area' },
      { key: 'UM083', value: 'IN > Indianapolis Area' },
      { key: 'UM084', value: 'IN > Lafayette Area' },
      { key: 'UM085', value: 'IN > South Bend Area' },
      { key: 'UM086', value: 'IN > Terre Haute Area' },
      { key: 'UM087', value: 'KS > Topeka Area' },
      { key: 'UM088', value: 'KS > Wichita Area' },
      { key: 'UM089', value: 'KY > Bowling Green Area' },
      { key: 'UM090', value: 'KY > Lexington Area' },
      { key: 'UM091', value: 'KY > Louisville Area' },
      { key: 'UM092', value: 'KY > Paducah Area' },
      { key: 'UM093', value: 'LA > Alexandria Area' },
      { key: 'UM094', value: 'LA > Baton Rouge Area' },
      { key: 'UM095', value: 'LA > Lafayette Area' },
      { key: 'UM096', value: 'LA > Lake Charles Area' },
      { key: 'UM097', value: 'LA > Monroe Area' },
      { key: 'UM098', value: 'LA > New Orleans Area' },
      { key: 'UM099', value: 'LA > Shreveport Area' },
      { key: 'UM100', value: 'MA > Boston Area' },
      { key: 'UM101', value: 'MA > Springfield Area' },
      { key: 'UM102', value: 'MD > Baltimore Area' },
      { key: 'UM103', value: 'MD > Salisbury Area' },
      { key: 'UM104', value: 'ME > Bangor Area' },
      { key: 'UM105', value: 'ME > Portland Area' },
      { key: 'UM106', value: 'ME > PresqueIsle Area' },
      { key: 'UM107', value: 'MI > Alpena Area' },
      { key: 'UM108', value: 'MI > Detroit Area' },
      { key: 'UM109', value: 'MI > Flint Area' },
      { key: 'UM110', value: 'MI > Grand Rapids Area' },
      { key: 'UM111', value: 'MI > Lansing Area' },
      { key: 'UM112', value: 'MI > Marquette Area' },
      { key: 'UM113', value: 'MI > Traverse City Area' },
      { key: 'UM114', value: 'MN > Duluth Area' },
      { key: 'UM115', value: 'MN > Mankato Area' },
      { key: 'UM116', value: 'MN > Minneapolis Area' },
      { key: 'UM117', value: 'MN > Rochester Area' },
      { key: 'UM118', value: 'MO > Jefferson City Area' },
      { key: 'UM119', value: 'MO > Joplin Area' },
      { key: 'UM120', value: 'MO > Kansas City Area' },
      { key: 'UM121', value: 'MO > Saint Joseph Area' },
      { key: 'UM122', value: 'MO > Saint Louis Area' },
      { key: 'UM123', value: 'MO > Springfield Area' },
      { key: 'UM124', value: 'MS > Biloxi Area' },
      { key: 'UM125', value: 'MS > Columbus Area' },
      { key: 'UM126', value: 'MS > Greenville Area' },
      { key: 'UM127', value: 'MS > Hattiesburg Area' },
      { key: 'UM128', value: 'MS > Jackson Area' },
      { key: 'UM129', value: 'MS > Meridian Area' },
      { key: 'UM130', value: 'MT > Billings Area' },
      { key: 'UM131', value: 'MT > Butte Area' },
      { key: 'UM132', value: 'MT > Glendive Area' },
      { key: 'UM133', value: 'MT > Great Falls Area' },
      { key: 'UM134', value: 'MT > Helena Area' },
      { key: 'UM135', value: 'MT > Missoula Area' },
      { key: 'UM136', value: 'NC > Charlotte Area' },
      { key: 'UM137', value: 'NC > Greenville Area' },
      { key: 'UM138', value: 'NC > Raleigh Area' },
      { key: 'UM139', value: 'NC > Wilmington Area' },
      { key: 'UM140', value: 'NC > Winston Salem Area' },
      { key: 'UM141', value: 'ND > Bismarck Area' },
      { key: 'UM142', value: 'ND > Fargo Area' },
      { key: 'UM143', value: 'NE > Lincoln Area' },
      { key: 'UM144', value: 'NE > North Platte Area' },
      { key: 'UM145', value: 'NE > Omaha Area' },
      { key: 'UM146', value: 'NM > Albuquerque Area' },
      { key: 'UM147', value: 'NV > Las Vegas Area' },
      { key: 'UM148', value: 'NV > Reno Area' },
      { key: 'UM149', value: 'NY > Albany Area' },
      { key: 'UM150', value: 'NY > Binghamton Area' },
      { key: 'UM151', value: 'NY > Buffalo Area' },
      { key: 'UM152', value: 'NY > Elmira Area' },
      { key: 'UM153', value: 'NY > New York Area' },
      { key: 'UM154', value: 'NY > Rochester Area' },
      { key: 'UM155', value: 'NY > Syracuse Area' },
      { key: 'UM156', value: 'NY > Utica Area' },
      { key: 'UM157', value: 'NY > Watertown Area' },
      { key: 'UM158', value: 'OH > Cincinnati Area' },
      { key: 'UM159', value: 'OH > Cleveland Area' },
      { key: 'UM160', value: 'OH > Columbus Area' },
      { key: 'UM161', value: 'OH > Dayton Area' },
      { key: 'UM162', value: 'OH > Lima Area' },
      { key: 'UM163', value: 'OH > Toledo Area' },
      { key: 'UM164', value: 'OH > Wheeling Area' },
      { key: 'UM165', value: 'OH > Youngstown Area' },
      { key: 'UM166', value: 'OH > Zanesville Area' },
      { key: 'UM167', value: 'OK > Ardmore Area' },
      { key: 'UM168', value: 'OK > Oklahoma City Area' },
      { key: 'UM169', value: 'OK > Tulsa Area' },
      { key: 'UM170', value: 'OR > Bend Area' },
      { key: 'UM171', value: 'OR > Eugene Area' },
      { key: 'UM172', value: 'OR > Medford Area' },
      { key: 'UM173', value: 'OR > Portland Area' },
      { key: 'UM174', value: 'PA > Erie Area' },
      { key: 'UM175', value: 'PA > Harrisburg Area' },
      { key: 'UM176', value: 'PA > Johnstown Area' },
      { key: 'UM177', value: 'PA > Philadelphia Area' },
      { key: 'UM178', value: 'PA > Pittsburgh Area' },
      { key: 'UM179', value: 'PA > Wilkes Barre Area' },
      { key: 'UM180', value: 'PR > Aguadilla Area' },
      { key: 'UM181', value: 'PR > Mayaguez Area' },
      { key: 'UM182', value: 'PR > Ponce Area' },
      { key: 'UM183', value: 'PR > San Juan Area' },
      { key: 'UM184', value: 'RI > Providence Area' },
      { key: 'UM185', value: 'SC > Charleston Area' },
      { key: 'UM186', value: 'SC > Columbia Area' },
      { key: 'UM187', value: 'SC > Florence Area' },
      { key: 'UM188', value: 'SC > Greenville Area' },
      { key: 'UM189', value: 'SD > Rapid City Area' },
      { key: 'UM190', value: 'SD > Sioux Falls Area' },
      { key: 'UM191', value: 'TN > Chattanooga Area' },
      { key: 'UM192', value: 'TN > Jackson Area' },
      { key: 'UM193', value: 'TN > Knoxville Area' },
      { key: 'UM194', value: 'TN > Memphis Area' },
      { key: 'UM195', value: 'TN > Nashville Area' },
      { key: 'UM196', value: 'TN > Tri Cities Area' },
      { key: 'UM197', value: 'TX > Abilene Area' },
      { key: 'UM198', value: 'TX > Amarillo Area' },
      { key: 'UM199', value: 'TX > Austin Area' },
      { key: 'UM200', value: 'TX > Beaumont Area' },
      { key: 'UM201', value: 'TX > Bryan Area' },
      { key: 'UM202', value: 'TX > Corpus Christi Area' },
      { key: 'UM203', value: 'TX > Dallas Area' },
      { key: 'UM204', value: 'TX > El Paso Area' },
      { key: 'UM205', value: 'TX > Harlingen Area' },
      { key: 'UM206', value: 'TX > Houston Area' },
      { key: 'UM207', value: 'TX > Laredo Area' },
      { key: 'UM208', value: 'TX > Lubbock Area' },
      { key: 'UM209', value: 'TX > Odessa Area' },
      { key: 'UM210', value: 'TX > San Angelo Area' },
      { key: 'UM211', value: 'TX > San Antonio Area' },
      { key: 'UM212', value: 'TX > Tyler Area' },
      { key: 'UM213', value: 'TX > Victoria Area' },
      { key: 'UM214', value: 'TX > Wichita Falls Area' },
      { key: 'UM215', value: 'UT > Salt Lake City Area' },
      { key: 'UM216', value: 'VA > Charlottesville Area' },
      { key: 'UM217', value: 'VA > Hampton Roads Area' },
      { key: 'UM218', value: 'VA > Harrisonburg Area' },
      { key: 'UM219', value: 'VA > Richmond Area' },
      { key: 'UM220', value: 'VA > Roanoke Area' },
      { key: 'UM221', value: 'VT > Burlington Area' },
      { key: 'UM222', value: 'WA > Seattle Area' },
      { key: 'UM223', value: 'WA > Spokane Area' },
      { key: 'UM224', value: 'WA > Tri Cities Area' },
      { key: 'UM225', value: 'WI > Green Bay Area' },
      { key: 'UM226', value: 'WI > La Crosse Area' },
      { key: 'UM227', value: 'WI > Madison Area' },
      { key: 'UM228', value: 'WI > Milwaukee Area' },
      { key: 'UM229', value: 'WI > Wausau Area' },
      { key: 'UM230', value: 'WV > Bluefield Area' },
      { key: 'UM231', value: 'WV > Charleston Area' },
      { key: 'UM232', value: 'WV > Clarksburg Area' },
      { key: 'UM233', value: 'WV > Parkersburg Area' },
      { key: 'UM234', value: 'WY > Casper Area' },
      { key: 'UM235', value: 'WY > Cheyenne Area' },
    ],
  },
  {
    key: 'US',
    value: 'US > States',
    children: [
      { key: 'US000', value: 'Alabama, US' },
      { key: 'US001', value: 'Alaska, US' },
      { key: 'US002', value: 'Arizona, US' },
      { key: 'US003', value: 'Arkansas, US' },
      { key: 'US004', value: 'California, US' },
      { key: 'US005', value: 'Colorado, US' },
      { key: 'US006', value: 'Connecticut, US' },
      { key: 'US007', value: 'Delaware, US' },
      { key: 'US008', value: 'Florida, US' },
      { key: 'US009', value: 'Georgia, US' },
      { key: 'US010', value: 'Hawaii, US' },
      { key: 'US011', value: 'Idaho, US' },
      { key: 'US012', value: 'Illinois, US' },
      { key: 'US013', value: 'Indiana, US' },
      { key: 'US014', value: 'Iowa, US' },
      { key: 'US015', value: 'Kansas, US' },
      { key: 'US016', value: 'Kentucky, US' },
      { key: 'US017', value: 'Louisiana, US' },
      { key: 'US018', value: 'Maine, US' },
      { key: 'US019', value: 'Maryland, US' },
      { key: 'US020', value: 'Massachusetts, US' },
      { key: 'US021', value: 'Michigan, US' },
      { key: 'US022', value: 'Minnesota, US' },
      { key: 'US023', value: 'Mississippi, US' },
      { key: 'US024', value: 'Missouri, US' },
      { key: 'US025', value: 'Montana, US' },
      { key: 'US026', value: 'Nebraska, US' },
      { key: 'US027', value: 'Nevada, US' },
      { key: 'US028', value: 'New Hampshire, US' },
      { key: 'US029', value: 'New Jersey, US' },
      { key: 'US030', value: 'New Mexico, US' },
      { key: 'US031', value: 'New York, US' },
      { key: 'US032', value: 'North Carolina, US' },
      { key: 'US033', value: 'North Dakota, US' },
      { key: 'US034', value: 'Ohio, US' },
      { key: 'US035', value: 'Oklahoma, US' },
      { key: 'US036', value: 'Oregon, US' },
      { key: 'US037', value: 'Pennsylvania, US' },
      { key: 'US038', value: 'Rhode Island, US' },
      { key: 'US039', value: 'South Carolina, US' },
      { key: 'US040', value: 'South Dakota, US' },
      { key: 'US041', value: 'Tennessee, US' },
      { key: 'US042', value: 'Texas, US' },
      { key: 'US043', value: 'Utah, US' },
      { key: 'US044', value: 'Vermont, US' },
      { key: 'US045', value: 'Virginia, US' },
      { key: 'US046', value: 'Washington, US' },
      { key: 'US047', value: 'West Virginia, US' },
      { key: 'US048', value: 'Wisconsin, US' },
      { key: 'US049', value: 'Wyoming, US' },
      { key: 'US050', value: 'District of Columbia' },
      { key: 'US051', value: 'American Samoa' },
      { key: 'US052', value: 'Canal Zone' },
      { key: 'US053', value: 'Guam' },
      { key: 'US054', value: 'Puerto Rico' },
      { key: 'US055', value: 'Virgin Islands' },
    ],
  },
];

export const INDUSTRY_DATA = [
  {
    key: 'AF',
    value: 'Agriculture & Fishing',
    children: [
      { key: 'AF000', value: 'Agriculture' },
      { key: 'AF001', value: 'Crops' },
      { key: 'AF002', value: 'Farming Animals & Livestock' },
      { key: 'AF003', value: 'Fishery & Aquaculture' },
      { key: 'AF004', value: 'Ranching' },
    ],
  },
  {
    key: 'BS',
    value: 'Business Services',
    children: [
      { key: 'BS000', value: 'Accounting & Accounting Services' },
      { key: 'BS001', value: 'Auctions' },
      { key: 'BS002', value: 'Business Services - General' },
      { key: 'BS003', value: 'Call Centers & Business Centers' },
      { key: 'BS004', value: 'Career Planning' },
      { key: 'BS005', value: 'Career' },
      { key: 'BS006', value: 'Commercial Printing' },
      { key: 'BS007', value: 'Debt Collection' },
      { key: 'BS008', value: 'Design' },
      { key: 'BS009', value: 'Event Services' },
      { key: 'BS010', value: 'Facilities Management & Services' },
      { key: 'BS011', value: 'Food Service' },
      { key: 'BS012', value: 'Fraud Detection' },
      { key: 'BS013', value: 'Geography & Positioning' },
      { key: 'BS014', value: 'Human Resources & Staffing' },
      { key: 'BS015', value: 'Information Services' },
      { key: 'BS016', value: 'Management Consulting' },
      { key: 'BS017', value: 'Marketing & Advertising' },
      { key: 'BS018', value: 'Multimedia & Graphic Design' },
      { key: 'BS019', value: 'Outsourcing' },
      { key: 'BS020', value: 'Sales' },
      { key: 'BS021', value: 'Security & Investigations Products & Services' },
      { key: 'BS022', value: 'Staffing & Recruiting' },
      { key: 'BS023', value: 'Translation & Localization' },
      { key: 'BS024', value: 'Writing & Editing' },
    ],
  },
  {
    key: 'CM',
    value: 'Cities, Towns & Municipalities',
    children: [
      { key: 'CM000', value: 'Cities Towns & Municipalities - General' },
      { key: 'CM001', value: 'Communities' },
      { key: 'CM002', value: 'Local' },
      { key: 'CM003', value: 'Parking' },
      { key: 'CM004', value: 'Public Safety' },
    ],
  },
  {
    key: 'CN',
    value: 'Construction',
    children: [
      { key: 'CN000', value: 'Architecture Engineering & Design' },
      { key: 'CN001', value: 'Construction - General' },
      { key: 'CN002', value: 'Mechanical Engineering' },
      { key: 'CN003', value: 'Mechanical or Industrial Engineering' },
    ],
  },
  {
    key: 'CS',
    value: 'Consumer Services',
    children: [
      { key: 'CS000', value: 'Car & Truck Rental' },
      { key: 'CS001', value: 'Child Care' },
      { key: 'CS002', value: 'Consumer Services - General' },
      { key: 'CS003', value: 'Funeral Homes & Funeral Related Services' },
      { key: 'CS004', value: 'Hair Salons' },
      { key: 'CS005', value: 'Laundry & Dry Cleaning Services' },
      { key: 'CS006', value: 'Photography' },
      { key: 'CS007', value: 'Privacy' },
      { key: 'CS008', value: 'Travel Agencies & Services' },
      { key: 'CS009', value: 'Veterinary Care' },
      { key: 'CS010', value: 'Wedding' },
    ],
  },
  {
    key: 'CL',
    value: 'Cultural',
    children: [
      { key: 'CL000', value: 'Cultural - General' },
      { key: 'CL001', value: 'Fine Arts' },
      { key: 'CL002', value: 'Libraries' },
      { key: 'CL003', value: 'Museums & Art Galleries' },
      { key: 'CL004', value: 'Performing Arts' },
    ],
  },
  {
    key: 'ED',
    value: 'Education',
    children: [
      { key: 'ED000', value: 'Colleges & Universities' },
      { key: 'ED001', value: 'E-learning' },
      { key: 'ED002', value: 'Education - General' },
      { key: 'ED003', value: 'Higher Education' },
      { key: 'ED004', value: 'K-12 Schools' },
      { key: 'ED005', value: 'Professional Training & Coaching' },
      { key: 'ED006', value: 'Training' },
    ],
  },
  {
    key: 'EU',
    value: 'Energy, Utilities & Waste Treatment',
    children: [
      { key: 'EU000', value: 'Electricity & Energy' },
      { key: 'EU001', value: 'Environmental Services' },
      { key: 'EU002', value: 'Forestry' },
      { key: 'EU003', value: 'Natural Resources' },
      { key: 'EU004', value: 'Nuclear' },
      { key: 'EU005', value: 'Oil & Gas Exploration & Services' },
      { key: 'EU006', value: 'Renewables & Environment' },
      { key: 'EU007', value: 'Utilities' },
      { key: 'EU008', value: 'Water Energy & Waste Treatment' },
      { key: 'EU009', value: 'Energy, Utilities & Waste Treatment - General' },
    ],
  },
  {
    key: 'FI',
    value: 'Finance',
    children: [
      { key: 'FI000', value: 'Banking' },
      { key: 'FI001', value: 'Credit Cards & Transaction Processing' },
      { key: 'FI002', value: 'Finance - General' },
      { key: 'FI003', value: 'Financial Services' },
      { key: 'FI004', value: 'Investment Banking' },
      { key: 'FI005', value: 'Venture Capital & Private Equity' },
    ],
  },
  {
    key: 'GV',
    value: 'Government',
    children: [
      { key: 'GV000', value: 'Corrections Facilities' },
      { key: 'GV001', value: 'Government - General' },
      { key: 'GV002', value: 'International Affairs' },
      { key: 'GV003', value: 'Law Enforcement' },
      { key: 'GV004', value: 'Military' },
      { key: 'GV005', value: 'Public Policy' },
    ],
  },
  {
    key: 'HC',
    value: 'Healthcare',
    children: [
      { key: 'HC000', value: 'Biotechnology' },
      { key: 'HC001', value: 'Clinical Trials' },
      { key: 'HC002', value: 'Drug Manufacturing & Research' },
      { key: 'HC003', value: 'Drug Stores & Pharmacies' },
      { key: 'HC004', value: 'Emergency Medical Transportation & Services' },
      { key: 'HC005', value: 'Hospitals & Healthcare' },
      { key: 'HC006', value: 'Medical Practice' },
      { key: 'HC007', value: 'Medicine' },
      { key: 'HC008', value: 'Mental Health Care' },
      { key: 'HC009', value: 'Pharmaceuticals' },
      { key: 'HC010', value: 'Psychology' },
      { key: 'HC011', value: 'Healthcare - General' },
    ],
  },
  {
    key: 'HT',
    value: 'Hospitality',
    children: [
      { key: 'HT000', value: 'Amusement Parks Arcades & Attractions' },
      { key: 'HT001', value: 'Cruise Lines' },
      { key: 'HT002', value: 'Elder & Disabled Care' },
      { key: 'HT003', value: 'Entertainment' },
      { key: 'HT004', value: 'Fitness & Dance Facilities' },
      { key: 'HT005', value: 'Gambling & Casinos' },
      { key: 'HT006', value: 'Gaming' },
      { key: 'HT007', value: 'Hospitality - General' },
      { key: 'HT008', value: 'Hotels and Resorts' },
      { key: 'HT009', value: 'Lodging & Resorts' },
      { key: 'HT010', value: 'Movie Theaters' },
      { key: 'HT011', value: 'Recreation' },
      { key: 'HT012', value: 'Restaurants' },
      { key: 'HT013', value: 'Sports' },
      { key: 'HT014', value: 'Zoos & National Parks' },
    ],
  },
  {
    key: 'MF',
    value: 'Manufacturing',
    children: [
      { key: 'MF000', value: 'Aerospace & Defense' },
      { key: 'MF001', value: 'Apparel & Fashion' },
      { key: 'MF002', value: 'Appliances' },
      { key: 'MF003', value: 'Automotive' },
      { key: 'MF004', value: 'Boats & Submarines' },
      { key: 'MF005', value: 'Building Materials' },
      { key: 'MF006', value: 'Business Supplies & Equipment' },
      { key: 'MF007', value: 'Chemicals' },
      { key: 'MF008', value: 'Cleaning Products' },
      { key: 'MF009', value: 'Computer Hardware' },
      { key: 'MF010', value: 'Consumer Electronics' },
      { key: 'MF011', value: 'Consumer Goods' },
      {
        key: 'MF012',
        value: 'Cosmetics Beauty Supply & Personal Care Products',
      },
      { key: 'MF013', value: 'Dairy' },
      { key: 'MF014', value: 'Electronics & Electronics Manufacturing' },
      { key: 'MF015', value: 'Flash Storage' },
      { key: 'MF016', value: 'Food & Beverages' },
      { key: 'MF017', value: 'Furniture' },
      { key: 'MF018', value: 'Glass & Concrete' },
      { key: 'MF019', value: 'Hand Power and Lawn-care Tools' },
      { key: 'MF020', value: 'Health & Nutrition Products' },
      { key: 'MF021', value: 'Health Wellness & Fitness' },
      { key: 'MF022', value: 'Household Goods' },
      { key: 'MF023', value: 'Industrial Machinery Equipment & Automation' },
      { key: 'MF024', value: 'Lumber & Wood Production' },
      { key: 'MF025', value: 'Luxury Goods & Jewelry' },
      { key: 'MF026', value: 'Manufacturing - General' },
      { key: 'MF027', value: 'Maritime' },
      { key: 'MF028', value: 'Medical Devices & Equipment' },
      { key: 'MF029', value: 'Miscellaneous Building Materials' },
      { key: 'MF030', value: 'Network Security Hardware & Software' },
      { key: 'MF031', value: 'Office Products' },
      { key: 'MF032', value: 'Paper & Forest Products' },
      { key: 'MF033', value: 'Personal Computers & Peripherals' },
      { key: 'MF034', value: 'Pet Products' },
      { key: 'MF035', value: 'Petrochemicals' },
      { key: 'MF036', value: 'Plastics & Packaging & Containers' },
      { key: 'MF037', value: 'Plumbing & HVAC Equipment' },
      { key: 'MF038', value: 'Power Conversion & Protection Equipment' },
      { key: 'MF039', value: 'Semiconductor & Semiconductor Equipment' },
      { key: 'MF040', value: 'Shipbuilding' },
      { key: 'MF041', value: 'Telecommunication Equipment' },
      { key: 'MF042', value: 'Test & Measurement Equipment' },
      { key: 'MF043', value: 'Textiles & Apparel' },
      { key: 'MF044', value: 'Tires & Rubber' },
      { key: 'MF045', value: 'Tobacco' },
      { key: 'MF046', value: 'Toys & Games' },
      { key: 'MF047', value: 'Wine & Spirits' },
      { key: 'MF048', value: 'Wire & Cable' },
    ],
  },
  {
    key: 'MI',
    value: 'Media & Internet',
    children: [
      { key: 'MI000', value: 'Adult' },
      { key: 'MI001', value: 'Animation' },
      { key: 'MI002', value: 'Broadcasting & Media' },
      { key: 'MI003', value: 'Classifieds' },
      { key: 'MI004', value: 'Ebook & Audiobooks' },
      { key: 'MI005', value: 'Ecommerce & Internet Retail' },
      { key: 'MI006', value: 'Film Video & Media Production & Services' },
      { key: 'MI007', value: 'Internet & Digital Media' },
      { key: 'MI008', value: 'Internet-related Services' },
      { key: 'MI009', value: 'Music & Music Related Services' },
      { key: 'MI010', value: 'Newspapers & News Services' },
      { key: 'MI011', value: 'Podcast' },
      { key: 'MI012', value: 'Public Relations & Communication' },
      { key: 'MI013', value: 'Publishing' },
      { key: 'MI014', value: 'Radio Stations' },
      { key: 'MI015', value: 'Search Engines & Internet Portals' },
      { key: 'MI016', value: 'Social Media' },
      { key: 'MI017', value: 'Software Engineering' },
      { key: 'MI018', value: 'Streaming' },
      { key: 'MI019', value: 'Television Stations' },
      { key: 'MI020', value: 'Video Chat & Online Messaging' },
      { key: 'MI021', value: 'Media & Internet - General' },
    ],
  },
  {
    key: 'MN',
    value: 'Metals & Mining',
    children: [
      { key: 'MN000', value: 'Metals & Mining - General' },
      { key: 'MN001', value: 'Mining' },
    ],
  },
  {
    key: 'OR',
    value: 'Organizations',
    children: [
      {
        key: 'OR000',
        value: 'Non-Profit & Charitable Organizations & Foundations',
      },
      { key: 'OR001', value: 'Organizations - General' },
      { key: 'OR002', value: 'Parenting & Youth' },
      { key: 'OR003', value: 'Philanthropy' },
      { key: 'OR004', value: 'Religious Organizations' },
    ],
  },
  {
    key: 'RE',
    value: 'Real Estate',
    children: [
      { key: 'RE000', value: 'Commercial Real Estate' },
      { key: 'RE001', value: 'Real Estate - General' },
    ],
  },
  {
    key: 'RT',
    value: 'Research & Technology',
    children: [
      { key: 'RT000', value: 'AR/VR' },
      { key: 'RT001', value: 'Artificial Intelligence & Machine Learning' },
      { key: 'RT002', value: 'Cryptocurrency' },
      { key: 'RT003', value: 'Data & Big Data' },
      { key: 'RT004', value: 'Drones' },
      { key: 'RT005', value: 'Genetics' },
      { key: 'RT006', value: 'Mobile' },
      { key: 'RT007', value: 'Nanotechnology' },
      { key: 'RT008', value: 'Neuroscience' },
      { key: 'RT009', value: 'Quantum Computing' },
      { key: 'RT010', value: 'Research - General' },
      { key: 'RT011', value: 'Robotics' },
      { key: 'RT012', value: 'Sharing Economy' },
      { key: 'RT013', value: 'Technology' },
      { key: 'RT014', value: 'Think Tanks' },
    ],
  },
  {
    key: 'RL',
    value: 'Retail',
    children: [
      {
        key: 'RL000',
        value: 'Department Stores Shopping Centers & Superstores',
      },
      { key: 'RL001', value: 'Flowers Gifts & Specialty' },
      { key: 'RL002', value: 'Footwear' },
      { key: 'RL003', value: 'Gas Stations Convenience & Liquor Stores' },
      { key: 'RL004', value: 'Gift & Gift Products' },
      { key: 'RL005', value: 'Grocery & Supermarkets' },
      { key: 'RL006', value: 'Home Improvement & Hardware' },
      { key: 'RL007', value: 'Optometry & Eyewear' },
      {
        key: 'RL008',
        value:
          'Rental - Other - Furniture A/V Construction & Industrial Equipment',
      },
      { key: 'RL009', value: 'Rental - Video & DVD' },
      { key: 'RL010', value: 'Retail - General' },
      { key: 'RL011', value: 'Sporting & Recreational Equipment' },
      { key: 'RL012', value: 'Vitamins Supplements & Health Stores' },
      { key: 'RL013', value: 'Record, Video & Book Stores' },
      { key: 'RL014', value: 'Jewelry & Watch Retail' },
      { key: 'RL015', value: 'Consumer Electronics & Computers Retail' },
      { key: 'RL016', value: 'Apparel & Accessories Retail' },
    ],
  },
  {
    key: 'SW',
    value: 'IT & Software',
    children: [
      { key: 'SW000', value: 'Business Intelligence Software' },
      { key: 'SW001', value: 'Cloud Software' },
      { key: 'SW002', value: 'Computer Games' },
      { key: 'SW003', value: 'Content & Collaboration Software' },
      { key: 'SW004', value: 'Customer Relationship Management Software(CRM)' },
      { key: 'SW005', value: 'Database & File Management Software' },
      { key: 'SW006', value: 'Enterprise Resource Planning Software(ERP)' },
      { key: 'SW007', value: 'Software & Technical Consulting' },
      { key: 'SW008', value: 'Software - General' },
      { key: 'SW009', value: 'Storage & System Management Software' },
      { key: 'SW010', value: 'Supply Chain Management Software' },
    ],
  },
  {
    key: 'TC',
    value: 'Telecommunications',
    children: [
      { key: 'TC000', value: 'Cable & Satellite' },
      { key: 'TC001', value: 'Telecommunications - General' },
      { key: 'TC002', value: 'Wireless' },
    ],
  },
  {
    key: 'TS',
    value: 'Trade, Supply Chain & Commerce',
    children: [
      { key: 'TS000', value: 'Import & Export & Trade' },
      { key: 'TS001', value: 'Logistics & Supply Chain' },
      { key: 'TS002', value: 'Warehousing' },
      { key: 'TS003', value: 'Wholesale' },
    ],
  },
  {
    key: 'TP',
    value: 'Transportation',
    children: [
      { key: 'TP000', value: 'Airlines & Aviation' },
      { key: 'TP001', value: 'Delivery' },
      { key: 'TP002', value: 'Freight & Logistics Services' },
      { key: 'TP003', value: 'Rail Bus & Taxi' },
      { key: 'TP004', value: 'Shipping' },
      { key: 'TP005', value: 'Transportation - General' },
      { key: 'TP006', value: 'Trucking Moving & Storage' },
    ],
  },
];

export const MAJOR_DATA = [
  { key: 'Computer Science', value: 'Computer Science' },
  { key: 'Business Administration', value: 'Business Administration' },
  { key: 'Management', value: 'Management' },
  { key: 'Accounting', value: 'Accounting' },
  { key: 'Marketing', value: 'Marketing' },
];

export const DEGREE_DATA = [
  { key: 'Bachelors', value: 'Bachelors' },
  { key: 'Masters', value: 'Masters' },
  { key: 'Associates', value: 'Associates' },
  { key: 'Doctorates', value: 'Doctorates' },
  { key: 'High School', value: 'High School' },
];

export const MANAGEMENT_LEVELS_DATA = [
  { key: 'Founder/Owner', value: 'Founder/Owner' },
  { key: 'C-Level', value: 'C-Level' },
  { key: 'VP', value: 'VP' },
  { key: 'Director', value: 'Director' },
  { key: 'Manager', value: 'Manager' },
  {
    key: 'Individual Contributor',
    value: 'Individual Contributor',
  },
  { key: 'Entry', value: 'Entry' },
  { key: 'Intern', value: 'Intern' },
  { key: 'Unpaid', value: 'Unpaid' },
];

export const REVENUE_DATA = [
  { key: 'select', label: 'Select' },
  { key: '<10000000', label: '< $10M' },
  { key: '10000000-50000000', label: '$10M - $50M' },
  { key: '50000000-100000000', label: '$50M - $100M' },
  { key: '100000000-500000000', label: '$100M - $500M' },
  { key: '500000000-1000000000', label: '$500M - $1B' },
  { key: '1000000000-5000000000', label: '$1B - $5B' },
  { key: '5000000000<', label: '$5B+' },
  { key: 'custom', label: 'Custom' },
];

export const YEARS_OF_EXPERIENCE_DATA = [
  { key: 'select', label: 'Select' },
  { key: '0-3', label: '0 - 3' },
  { key: '3-10', label: '3 - 10' },
  { key: '10<', label: '10 +' },
  { key: 'custom', label: 'Custom' },
];

export const EMPLOYEE_COUNT_DATA = [
  { key: 'select', label: 'Select' },
  { key: '1-10', label: '1 - 10' },
  { key: '11-50', label: '11 - 50' },
  { key: '51-100', label: '51 - 100' },
  { key: '101-500', label: '101 - 500' },
  { key: '501-1000', label: '501 - 1,000' },
  { key: '1001-5000', label: '1,001 - 5,000' },
  { key: '5001-10000', label: '5,001 - 10,000' },
  { key: '10001-25000', label: '10,001 - 25,000' },
  { key: '25001<', label: '25,001 +' },
  { key: 'custom', label: 'Custom' },
];

export const CHANGE_JOB_DATA = [
  { key: 'select', label: 'Select' },
  { key: '0-90', label: 'Last 3 Months' },
  { key: '0-180', label: 'Last 6 Months' },
  { key: '0-360', label: 'Last Year' },
  { key: '0-720', label: 'Last 2 Years' },
  { key: '0-1080', label: 'Last 3 Years' },
];
