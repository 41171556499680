import { showLoading, hideLoading } from 'react-redux-loading-bar';
import React from 'react';
import { withTranslation } from 'react-i18next';
import type { CreateUpdateModalData, IProps, IState } from './types';
import store from '../../../../store';
import CustomOutcomeHeader from './components/custom-outcome-header';
import { RequestStatus } from '../../../../shared/enums/request-status';
import toaster from '../../../../shared/toaster';
import DeleteCustomOutcomeModal from './components/delete-custom-outcome-modal';
import UpdateProspectCustomOutcomeModal from './components/generic-create-update-custom-outcome-modal/prospect-modal/update-prospect-custom-outcome-modal';
import CreateProspectCustomOutcomeModal from './components/generic-create-update-custom-outcome-modal/prospect-modal/create-prospect-custom-outcome-modal';
import CustomOutcomeContent from './components/custom-outcome-content';
import { constants } from '../../../../shared/enums/constants';
import { GlobalSettings } from '../../../../shared/components/global-settings-wrapper';
import { OutcomeContentTabs } from './components/custom-outcome-content/types';
import { getIsRequestPending } from '../../../../shared/utils';

class CustomOutcome extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      actionCustomOutcome: null,
      showDeleteCustomOutcomeModal: false,
      showCreateProspectCustomOutcomeModal: false,
      showUpdateProspectCustomOutcomeModal: false,
      actionCustomOutcomeProspectUpdateId: 1,
      activeTabKey: OutcomeContentTabs.CustomOutcomes,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
    const {
      isLoading,
      createCustomOutcomeRequestStatus,
      updateCustomOutcomeRequestStatus,
      deleteCustomOutcomeRequestStatus,
    } = this.props;
    const { activeTabKey } = this.state;
    if (isLoading !== prevProps.isLoading) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      isLoading ? store.dispatch(showLoading()) : store.dispatch(hideLoading());
    }

    if (
      createCustomOutcomeRequestStatus !==
      prevProps.createCustomOutcomeRequestStatus
    ) {
      this.createProspectCustomOutcomeRefresh();
    }

    if (
      updateCustomOutcomeRequestStatus !==
      prevProps.updateCustomOutcomeRequestStatus
    ) {
      this.updateProspectCustomOutcomeRefresh();
    }

    if (
      deleteCustomOutcomeRequestStatus !==
      prevProps.deleteCustomOutcomeRequestStatus
    ) {
      this.deleteCustomOutcomeRefresh();
    }

    if (activeTabKey !== prevState.activeTabKey) {
      this.getOutcomes();
    }
  }

  componentWillUnmount = () => {
    const { resetProspectOutcomeData } = this.props;
    resetProspectOutcomeData();
  };

  onCreateProspectCustomOutcomeSubmit = (payload: CreateUpdateModalData) => {
    const { sendCreateCustomOutcomeRequest } = this.props;
    sendCreateCustomOutcomeRequest({
      label: payload.label,
      sentiment: payload.sentiment,
    });
  };

  onUpdateProspectCustomOutcomeSubmit = (payload: CreateUpdateModalData) => {
    const { sendUpdateCustomOutcomeRequest } = this.props;
    const { actionCustomOutcome } = this.state;
    sendUpdateCustomOutcomeRequest(actionCustomOutcome.id, {
      label: payload.label,
      sentiment: payload.sentiment,
    });
  };

  showCreateProspectCustomOutcomeModal = () => {
    this.setState({
      actionCustomOutcome: null,
      showCreateProspectCustomOutcomeModal: true,
    });
  };

  hideCreateProspectCustomOutcomeModal = () => {
    this.setState({
      actionCustomOutcome: null,
      showCreateProspectCustomOutcomeModal: false,
    });
  };

  changeActionCustomOutcomeProspectUpdateId = (id: number) => {
    this.setState({
      actionCustomOutcomeProspectUpdateId: id,
    });
  };

  showUpdateProspectCustomOutcomeModal = (customOutcome) => {
    this.setState({
      actionCustomOutcome: customOutcome,
      showUpdateProspectCustomOutcomeModal: true,
    });
  };

  hideUpdateProspectCustomOutcomeModal = () => {
    this.setState({
      actionCustomOutcome: null,
      showUpdateProspectCustomOutcomeModal: false,
    });
  };

  showProspectCustomOutcomeDeleteModal = (customOutcome) => {
    this.setState({
      actionCustomOutcome: customOutcome,
      showDeleteCustomOutcomeModal: true,
    });
  };

  hideDeleteCustomOutcomeModal = () => {
    this.setState({
      actionCustomOutcome: null,
      showDeleteCustomOutcomeModal: false,
      actionCustomOutcomeProspectUpdateId: 1,
    });
  };

  setActiveTabKey = (value: string) => {
    this.setState({
      activeTabKey: value,
    });
  };

  onDeleteCustomOutcomeSubmit = () => {
    const { sendDeleteCustomOutcomeRequest } = this.props;
    const {
      actionCustomOutcome,
      actionCustomOutcomeProspectUpdateId,
    } = this.state;
    sendDeleteCustomOutcomeRequest(
      actionCustomOutcome.id,
      actionCustomOutcomeProspectUpdateId,
    );
    this.setState({ actionCustomOutcomeProspectUpdateId: 1 });
  };

  componentDidMount = () => {
    this.getOutcomes();
  };

  getOutcomes = (forceRefetch = false) => {
    const {
      customOutcome,
      defaultOutcome,
      sendGetDefaultOutcomeRequest,
      sendGetCustomOutcomeRequest,
      customOutcomePagination: {
        options: { limit: cL, page: cP, search: cSearch },
      },
      defaultOutcomePagination: {
        options: { limit: dL, page: dP, search: dSearch },
      },
    } = this.props;

    const { activeTabKey } = this.state;

    if (
      activeTabKey === OutcomeContentTabs.CustomOutcomes &&
      (customOutcome.length <= 0 || forceRefetch)
    ) {
      sendGetCustomOutcomeRequest({ limit: cL, page: cP, search: cSearch });
    } else if (
      activeTabKey === OutcomeContentTabs.SystemOutcomes &&
      (defaultOutcome.length <= 0 || forceRefetch)
    ) {
      sendGetDefaultOutcomeRequest({ limit: dL, page: dP, search: dSearch });
    }
  };

  createProspectCustomOutcomeRefresh = () => {
    const {
      createCustomOutcomeRequestStatus,
      createCustomOutcomeRequestMessage,
      createCustomOutcomeRequestError,
      sendGetCustomOutcomeRequest,
      customOutcomePagination: {
        options: { limit: cL, page: cP },
      },
    } = this.props;

    if (createCustomOutcomeRequestStatus === RequestStatus.Succeeded) {
      toaster.success(createCustomOutcomeRequestMessage);
      this.hideCreateProspectCustomOutcomeModal();
      sendGetCustomOutcomeRequest({ limit: cL, page: cP });
    }
    if (
      createCustomOutcomeRequestStatus === RequestStatus.Failed &&
      createCustomOutcomeRequestError
    ) {
      toaster.error(createCustomOutcomeRequestError.message);
    }
  };

  updateProspectCustomOutcomeRefresh = () => {
    const {
      updateCustomOutcomeRequestStatus,
      updateCustomOutcomeRequestError,
    } = this.props;

    if (updateCustomOutcomeRequestStatus === RequestStatus.Succeeded) {
      this.showUpdateOutcomeSuccessToaster();
      this.hideUpdateProspectCustomOutcomeModal();
      this.getOutcomes(true);
    }

    if (
      updateCustomOutcomeRequestStatus === RequestStatus.Failed &&
      updateCustomOutcomeRequestError
    ) {
      toaster.error(updateCustomOutcomeRequestError.message);
    }
  };

  showUpdateOutcomeSuccessToaster = () => {
    const {
      actionCustomOutcome: { isDefault },
    } = this.state;
    const { t } = this.props;
    if (isDefault) {
      toaster.success(t('messages.system_outcome_update_successfully'));
    } else {
      toaster.success(t('messages.custom_outcome_update_successfully'));
    }
  };

  deleteCustomOutcomeRefresh = () => {
    const {
      deleteCustomOutcomeRequestStatus,
      deleteCustomOutcomeRequestMessage,
      deleteCustomOutcomeRequestError,
      sendGetCustomOutcomeRequest,
    } = this.props;

    if (deleteCustomOutcomeRequestStatus === RequestStatus.Succeeded) {
      toaster.success(deleteCustomOutcomeRequestMessage);
      this.hideDeleteCustomOutcomeModal();
      sendGetCustomOutcomeRequest({
        limit: constants.DEFAULT_PAGE_SIZE,
        page: 1,
      });
    }

    if (
      deleteCustomOutcomeRequestStatus === RequestStatus.Failed &&
      deleteCustomOutcomeRequestError
    ) {
      toaster.error(deleteCustomOutcomeRequestError.message);
    }
  };

  render() {
    const {
      customOutcome,
      defaultOutcome,
      getCustomOutcomeRequestStatus,
      getDefaultOutcomeRequestStatus,
      createCustomOutcomeRequestStatus,
      updateCustomOutcomeRequestStatus,
      deleteCustomOutcomeRequestStatus,
      sendGetCustomOutcomeRequest,
      sendGetDefaultOutcomeRequest,
      customOutcomePagination,
      defaultOutcomePagination,
      subscriptionPlan,
      sendGetAllOutcomesRequest,
      getAllCustomOutcomeRequestStatus,
      outcomes: allOutcomes,
    } = this.props;

    const {
      actionCustomOutcome,
      showCreateProspectCustomOutcomeModal,
      showUpdateProspectCustomOutcomeModal,
      showDeleteCustomOutcomeModal,
    } = this.state;

    const isCreateRequestPending =
      createCustomOutcomeRequestStatus === RequestStatus.Pending;

    const isUpdateRequestPending =
      updateCustomOutcomeRequestStatus === RequestStatus.Pending;

    const isDeleteRequestPending =
      deleteCustomOutcomeRequestStatus === RequestStatus.Pending;

    return (
      <GlobalSettings.Content className="custom-outcomes-container">
        <div className="custom-fields-grid bs-mb-30">
          <CustomOutcomeHeader
            totalCount={{
              systemCount: defaultOutcomePagination?.options?.totalElements,
              customCount: customOutcomePagination?.options?.totalElements,
            }}
            headerName="Prospect Outcome"
          />
          <CustomOutcomeContent
            isRequestPending={
              getIsRequestPending(getCustomOutcomeRequestStatus) ||
              getIsRequestPending(getDefaultOutcomeRequestStatus)
            }
            sendGetCustomOutcomeRequest={sendGetCustomOutcomeRequest}
            sendGetDefaultOutcomeRequest={sendGetDefaultOutcomeRequest}
            customOutcomes={customOutcome}
            defaultOutcomes={defaultOutcome}
            customOutcomePagination={customOutcomePagination}
            defaultOutcomePagination={defaultOutcomePagination}
            onCreateHandler={this.showCreateProspectCustomOutcomeModal}
            onUpdateHandler={this.showUpdateProspectCustomOutcomeModal}
            onDeleteHandler={this.showProspectCustomOutcomeDeleteModal}
            subscriptionPlan={subscriptionPlan}
            initialSearch=""
            activeTabKey={this.state.activeTabKey}
            setActiveTabKey={this.setActiveTabKey}
          />
        </div>
        {showCreateProspectCustomOutcomeModal && (
          <CreateProspectCustomOutcomeModal
            show={showCreateProspectCustomOutcomeModal}
            onClose={this.hideCreateProspectCustomOutcomeModal}
            onSave={this.onCreateProspectCustomOutcomeSubmit}
            isRequestPending={isCreateRequestPending}
          />
        )}
        {showUpdateProspectCustomOutcomeModal && (
          <UpdateProspectCustomOutcomeModal
            show={showUpdateProspectCustomOutcomeModal}
            onClose={this.hideUpdateProspectCustomOutcomeModal}
            onSave={this.onUpdateProspectCustomOutcomeSubmit}
            isRequestPending={isUpdateRequestPending}
            customOutcome={actionCustomOutcome}
          />
        )}
        {showDeleteCustomOutcomeModal && (
          <DeleteCustomOutcomeModal
            title="Delete Outcome"
            content="Deleting this outcome will leave all associated prospects uncategorized."
            show={showDeleteCustomOutcomeModal}
            onClose={this.hideDeleteCustomOutcomeModal}
            onSubmit={this.onDeleteCustomOutcomeSubmit}
            isRequestPending={isDeleteRequestPending}
            onOutcomeIdUpdate={this.changeActionCustomOutcomeProspectUpdateId}
            className="delete-outcome-modal"
            options={allOutcomes}
            sendAllOutcomesRequest={sendGetAllOutcomesRequest}
            getAllOutcomesRequestStatus={getAllCustomOutcomeRequestStatus}
            actionCustomOutcome={actionCustomOutcome}
          />
        )}
      </GlobalSettings.Content>
    );
  }
}

export default withTranslation()(CustomOutcome);
