import React from 'react';
import { Images } from '../../app-constants';
import ImageIcon from './image-icon';

const NoSearchResultSavedIcon = () => (
  <ImageIcon
    src={Images.NoSearchResultSavedIcon}
    alt="No Search Saved Result"
  />
);

export default NoSearchResultSavedIcon;
