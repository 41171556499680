import {
  SubscriptionPlanIdMap,
  SubscriptionPlans,
} from '../../../shared/utils/subscription-plans';

const checkIsPlanDowngraded = (from, includeTrailPlan = false): boolean => {
  const freePlanId = SubscriptionPlanIdMap[SubscriptionPlans.FREE];
  const trialPlanId = SubscriptionPlanIdMap[SubscriptionPlans.TRIAL];

  if (includeTrailPlan) {
    return from !== freePlanId;
  }
  return from !== freePlanId && from !== trialPlanId;
};

// eslint-disable-next-line import/prefer-default-export
export const isPlanDowngraded = (planTransitionDetails: {
  current: number;
  from: number;
}) =>
  planTransitionDetails !== null &&
  planTransitionDetails?.current === SubscriptionPlanIdMap.free &&
  checkIsPlanDowngraded(planTransitionDetails?.from, true);

export const isPlanDowngradedFromTrial = (planTransitionDetails: {
  current: number;
  from: number;
}) =>
  planTransitionDetails !== null &&
  planTransitionDetails?.current === SubscriptionPlanIdMap.free &&
  planTransitionDetails?.from === SubscriptionPlanIdMap.trial;

export const isPlanDowngradedFromPaid = (planTransitionDetails: {
  current: number;
  from: number;
}) =>
  planTransitionDetails !== null &&
  planTransitionDetails?.current === SubscriptionPlanIdMap.free &&
  checkIsPlanDowngraded(planTransitionDetails?.from);
