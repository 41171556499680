import React from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import CreateUpdateCustomOutcomeModal from '../..';
import { CreateProspectCustomOutcomeModalProps } from './types';

const CreateProspectCustomOutcomeModal: React.FC<CreateProspectCustomOutcomeModalProps> = (
  props,
) => {
  const { t } = useTranslation();
  return (
    <CreateUpdateCustomOutcomeModal
      {...props}
      modalHeaderTitle={t(`Add Custom Outcome`)}
      disableDropdownBox={false}
      showWarning={false}
    />
  );
};

export default withTranslation()(CreateProspectCustomOutcomeModal);