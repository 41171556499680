import React from 'react';

const TaskBanner = ({ selectedTasks }) => (
  <div className="d-flex justify-content-center align-items-center task-banner">
    <span className="semibold-1 popover-arrow-color-txt">
      {`${selectedTasks?.length} task(s) selected.`}
    </span>
  </div>
);

export default TaskBanner;
