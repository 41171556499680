import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../store/root-reducer';
import TasksHeader from './task-header';
import { GetTasksParams } from '../../utils/types';
import {
  getTasksRequest,
  getTaskStatusCountRequest,
} from '../../extra-actions';
import { TaskStatusKey } from '../../utils/enums';

const mapStateToProps = (state: RootState) => ({
  taskStatusCount: state.tasks.taskStatusCount,
  getTasksRequestStatus: state.tasks.getTasksRequest.status,
  tasksCount: state.tasks.tasksCount,
  tasks: state.tasks.tasks,
});

const mapDispatchToProps = {
  getTasksRequest: (params: GetTasksParams) => getTasksRequest(params),
  getTasksStatusCountRequest: (params: GetTasksParams) =>
    getTaskStatusCountRequest(params),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  taskStatus: TaskStatusKey;
  onChange: (status: TaskStatusKey) => void;
};

export default connector(TasksHeader);
