import React from 'react';
import SignupForm from '../signup-form';
import { IProps } from './types';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';
import { SHLogoDark } from '../../../../shared/svg';
import { SHLogoTypography } from '../../../../shared/svg/sh-typography';

const Signup: React.FC<IProps> = () => (
  <>
    <div className="testimonials-container">
      <div className="d-flex align-items-center">
        <SHLogoDark />
        <div className="bs-ml-10">
          <SHLogoTypography />
        </div>
      </div>
      <TestimonialsCarousel />
      <p className="copyright">
        &copy;{`${new Date().getFullYear()} Saleshandy`}
      </p>
    </div>
    <SignupForm />
  </>
);

export default Signup;
