import { Images } from '../app-constants';
import { Theme, Variant } from './toast';

export const getIconBasedOnVariantForNewTheme = (
  theme: Theme,
  variant: Variant,
): {
  iconSrc: string;
  iconAlt: string;
} => {
  if (theme === Theme.New) {
    if (variant === Variant.Success) {
      return {
        iconSrc: Images.ChecksGreen,
        iconAlt: 'success',
      };
    }
    if (variant === Variant.Error) {
      return {
        iconSrc: Images.AlertTriangleRed,
        iconAlt: 'error',
      };
    }
    if(variant === Variant.Warning){
      return {
        iconSrc: Images.WarningTriangle,
        iconAlt: 'warning',
      }
    }
  }

  return {
    iconSrc: '',
    iconAlt: '',
  };
};

export const getCloseIconBasedOnVariant = (variant: Variant): string => {
  if (variant === Variant.Error) {
    return Images.CrossRed;
  }

  return '';
};
