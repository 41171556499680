import React from 'react';

export const ArrowRight = ({ width = 24, height = 24, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 29"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.55542 14.7718C1.55542 14.1274 2.25187 13.6051 3.11098 13.6051H24.8888C25.7479 13.6051 26.4443 14.1274 26.4443 14.7718C26.4443 15.4161 25.7479 15.9384 24.8888 15.9384H3.11098C2.25187 15.9384 1.55542 15.4161 1.55542 14.7718Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.2304 13.9468C27.686 14.4024 27.686 15.1411 27.2304 15.5967L20.2304 22.5967C19.7748 23.0523 19.0361 23.0523 18.5805 22.5967C18.1249 22.1411 18.1249 21.4024 18.5805 20.9468L25.5805 13.9468C26.0361 13.4912 26.7748 13.4912 27.2304 13.9468Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5805 6.94679C19.0361 6.49118 19.7748 6.49118 20.2304 6.94679L27.2304 13.9468C27.686 14.4024 27.686 15.1411 27.2304 15.5967C26.7748 16.0523 26.0361 16.0523 25.5805 15.5967L18.5805 8.59671C18.1249 8.1411 18.1249 7.4024 18.5805 6.94679Z"
      fill="currentColor"
    />
  </svg>
);
