import { TeamForFilter } from '../types';

export const onUserOptionClicked = (
  userId: number,
  selectedUserIds: number[],
  setSelectedUserIds: (payload: number[]) => void,
) => {
  // Check if the user ID is already in the selectedUserIds array
  const isSelected = selectedUserIds.includes(userId);

  // If the user is already selected, remove the ID; otherwise, add it
  if (isSelected) {
    setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
  } else {
    setSelectedUserIds([...selectedUserIds, userId]);
  }
};

export const onTeamOptionClicked = (
  team: TeamForFilter,
  selectedUserIds: number[],
  setSelectedUserIds: (payload: number[]) => void,
) => {
  // Check if all user IDs in the team are in selectedUserIds
  const allUsersSelected = team.users.every((user) =>
    selectedUserIds.includes(user.id),
  );

  if (allUsersSelected) {
    // If all users are selected, remove all of them
    const updatedSelectedUserIds = selectedUserIds.filter(
      (id) => !team.users.some((user) => user.id === id),
    );
    setSelectedUserIds(updatedSelectedUserIds);
  } else {
    // If not all users are selected, add those who are not already in selectedUserIds
    const userIDsToAdd = team.users
      .map((user) => user.id)
      .filter((userId) => !selectedUserIds.includes(userId));

    const newSelectedUserIds = [...selectedUserIds, ...userIDsToAdd];
    setSelectedUserIds(newSelectedUserIds);
  }
};
