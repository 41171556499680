export enum SequenceConfig {
  NewSequenceTitle = 'New Sequence',
  MaxSequenceTitleLength = 100,
}

export enum SequenceProgress {
  Active = 1,
  Pause = 2,
  Draft = 3,
}

export enum SequenceAction {
  Pause = 1,
  Resume = 2,
}

export enum SequenceActionLabel {
  Pause = 'pause',
  Resume = 'resume',
}

export enum SequenceSortBy {
  CreatedAt = 'created-date',
  Title = 'title',
  SequenceScore = 'sequenceScore',
  TotalSteps = 'totalSteps',
  Status = 'progress',
  Active = 'active',
  Finished = 'finished',
  Total = 'total',
  Opened = 'openPer',
  Replied = 'repliedPer',
  CreatedBy = 'userName',
  ClientAssociated = 'client',
}

export enum SortSequenceProgress {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DRAFT = 'DRAFT',
}

export enum SortBySequence {
  Opened = 'opened',
  Replied = 'replied',
  CreatedBy = 'created-by',
}
