import { ProspectContactInfoCategory } from './enum';

export const CONTACT_CATEGORY_OPTIONS = [
  {
    name: ProspectContactInfoCategory.Personal,
    key: ProspectContactInfoCategory.Personal,
  },
  {
    name: ProspectContactInfoCategory.Work,
    key: ProspectContactInfoCategory.Work,
  },
  {
    name: ProspectContactInfoCategory.Other,
    key: ProspectContactInfoCategory.Other,
  },
];

export enum ContactType {
  Email = 'email',
  PhoneNumber = 'phonenumber',
}
