import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import DoNotContactListDetails from './do-not-contact-list-details';

import {
  bulkDeleteDoNotContactListEmailsAndDomainsRequest,
  deleteDoNotContactListEmailsAndDomainsRequest,
  getDoNotContactListDetailsMetaRequest,
  getDoNotContactListDetailsRequest,
  updateDoNotContactListRequest,
  importDoNotContactListEmailsAndDomainsRequest,
} from '../../extra-actions';
import {
  clearDNCListEmailsAndDomainsSelection,
  resetGetDoNotContactListDetailsRequest,
  resetSelectedDNCListEmailsAndDomains,
  resetUpdateDoNotContactListRequest,
  resetDeleteDoNotContactListEmailsAndDomainsRequest,
  resetBulkDeleteDoNotContactListEmailsAndDomainsRequest,
  resetImportDoNotContactListEmailsAndDomainsRequest,
  resetDoNotContactListDetailsMeta,
} from '../../do-not-contact-slice';
import { GetAgencyClientDropdownRequestPayload } from '../../../../../agency-client-management/types/request-payload';
import { getAgencyClientsDropdown } from '../../../../../agency-client-management/extra-actions';
import {
  BulkDeleteDoNotContactListEmailsAndDomainsPayload,
  DeleteDoNotContactListEmailsAndDomainsPayload,
  GetDoNotContactListDetailsPayload,
  ImportEmailsAndDomainsViaCsvPayload,
  UpdateDoNotContactListPayload,
} from '../../../../types/do-not-contact-list';

const mapStateToProps = (state: RootState) => ({
  getDoNotContactListDetailsMetaRequestStatus:
    state.doNotContact.getDoNotContactListDetailsMetaRequest.status,
  getDoNotContactListDetailsMetaRequestMessage:
    state.doNotContact.getDoNotContactListDetailsMetaRequest.message,
  getDoNotContactListDetailsMetaRequestError:
    state.doNotContact.getDoNotContactListDetailsMetaRequest.error,

  getDoNotContactListDetailsRequestStatus:
    state.doNotContact.getDoNotContactListDetailsRequest.status,
  getDoNotContactListDetailsRequestMessage:
    state.doNotContact.getDoNotContactListDetailsRequest.message,
  getDoNotContactListDetailsRequestError:
    state.doNotContact.getDoNotContactListDetailsRequest.error,

  updateDoNotContactListRequestStatus:
    state.doNotContact.updateDoNotContactListRequest.status,
  updateDoNotContactListRequestMessage:
    state.doNotContact.updateDoNotContactListRequest.message,
  updateDoNotContactListRequestError:
    state.doNotContact.updateDoNotContactListRequest.error,

  updateDoNotContactListDetailsRequestStatus:
    state.doNotContact.updateDoNotContactListDetailsRequest.status,
  updateDoNotContactListDetailsRequestMessage:
    state.doNotContact.updateDoNotContactListDetailsRequest.message,
  updateDoNotContactListDetailsRequestError:
    state.doNotContact.updateDoNotContactListDetailsRequest.error,

  deleteDoNotContactListEmailsAndDomainsRequestStatus:
    state.doNotContact.deleteDoNotContactListEmailsAndDomainsRequest.status,
  deleteDoNotContactListEmailsAndDomainsRequestMessage:
    state.doNotContact.deleteDoNotContactListEmailsAndDomainsRequest.message,
  deleteDoNotContactListEmailsAndDomainsRequestError:
    state.doNotContact.deleteDoNotContactListEmailsAndDomainsRequest.error,

  bulkDeleteDoNotContactListEmailsAndDomainsRequestStatus:
    state.doNotContact.bulkDeleteDoNotContactListEmailsAndDomainsRequest.status,
  bulkDeleteDoNotContactListEmailsAndDomainsRequestMessage:
    state.doNotContact.bulkDeleteDoNotContactListEmailsAndDomainsRequest
      .message,
  bulkDeleteDoNotContactListEmailsAndDomainsRequestError:
    state.doNotContact.bulkDeleteDoNotContactListEmailsAndDomainsRequest.error,

  importDoNotContactListEmailsAndDomainsRequestStatus:
    state.doNotContact.importDoNotContactListEmailsAndDomainsRequest.status,
  importDoNotContactListEmailsAndDomainsRequestMessage:
    state.doNotContact.importDoNotContactListEmailsAndDomainsRequest.message,
  importDoNotContactListEmailsAndDomainsRequestError:
    state.doNotContact.importDoNotContactListEmailsAndDomainsRequest.error,

  doNotContactListDetailsMeta: state.doNotContact.doNotContactListDetailsMeta,
  doNotContactListDetails: state.doNotContact.doNotContactListDetails,
  doNotContactListDetailsPaginationOptions:
    state.doNotContact.doNotContactListDetailsPaginationOptions,

  agencyClients: state.agencyClient.clients,

  selectedDNCListEmailsAndDomains:
    state.doNotContact.selectedDNCListEmailsAndDomains,
  bulkSelectedDNCListEmailsAndDomains:
    state.doNotContact.bulkSelectedDNCListEmailsAndDomains,
});

const mapDispatchToProps = {
  sendGetDoNotContactListDetailsMetaRequest: (id: string) =>
    getDoNotContactListDetailsMetaRequest(id),
  resetDoNotContactListDetailsMeta: () => resetDoNotContactListDetailsMeta(),

  sendGetDoNotContactListDetailsRequest: (
    payload: GetDoNotContactListDetailsPayload,
  ) => getDoNotContactListDetailsRequest(payload),
  resetGetDoNotContactListDetailsRequest: () =>
    resetGetDoNotContactListDetailsRequest(),

  sendUpdateDoNotContactListRequest: (payload: UpdateDoNotContactListPayload) =>
    updateDoNotContactListRequest(payload),
  resetUpdateDoNotContactListRequest: () =>
    resetUpdateDoNotContactListRequest(),

  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),

  sendDeleteDoNotContactListEmailsAndDomainsRequest: (
    payload: DeleteDoNotContactListEmailsAndDomainsPayload,
  ) => deleteDoNotContactListEmailsAndDomainsRequest(payload),
  resetDeleteDoNotContactListEmailsAndDomainsRequest: () =>
    resetDeleteDoNotContactListEmailsAndDomainsRequest(),

  sendImportDoNotContactListEmailsAndDomainsRequest: (
    payload: ImportEmailsAndDomainsViaCsvPayload,
  ) => importDoNotContactListEmailsAndDomainsRequest(payload),
  resetImportDoNotContactListEmailsAndDomainsRequest: () =>
    resetImportDoNotContactListEmailsAndDomainsRequest(),

  sendBulkDeleteDoNotContactListEmailsAndDomainsRequest: (
    payload: BulkDeleteDoNotContactListEmailsAndDomainsPayload,
  ) => bulkDeleteDoNotContactListEmailsAndDomainsRequest(payload),
  resetBulkDeleteDoNotContactListEmailsAndDomainsRequest: () =>
    resetBulkDeleteDoNotContactListEmailsAndDomainsRequest(),

  clearDNCListEmailsAndDomainsSelection: () =>
    clearDNCListEmailsAndDomainsSelection(),
  resetSelectedDNCListEmailsAndDomains: () =>
    resetSelectedDNCListEmailsAndDomains(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(DoNotContactListDetails);
