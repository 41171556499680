import React, { useEffect, useState } from 'react';
import { Replace } from '@saleshandy/icons';
import type { IProps } from './reassign-modal-container';
import ConfirmationModalV3 from '../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { getIsRequestPending } from '../../../../../../shared/utils';
import { getSelectedTasksId } from '../../utils/helper';
import Select from '../../../../../../shared/design-system/components/select';

const ReassignModal: React.FC<IProps> = ({
  show,
  selectedTasks,
  taskActionRequestStatus,
  taskAssigneeList,
  userId,
  onClose,
  onSubmit,
  sendGetTaskAssigneeListRequest,
}) => {
  const selectedTaskIds = getSelectedTasksId(selectedTasks);

  const [selectedAssignee, setSelectedAssignee] = useState<number>(null);

  const isButtonDisabled = () => getIsRequestPending(taskActionRequestStatus);

  useEffect(() => {
    sendGetTaskAssigneeListRequest(userId);
  }, []);

  //! Render Function
  const getModalContent = () => {
    const arr = [
      {
        firstName: taskAssigneeList?.firstName,
        lastName: taskAssigneeList?.lastName,
        id: taskAssigneeList?.id,
      },
    ];
    const options = arr?.map((item) => ({
      key: `${item?.firstName} ${item?.lastName}`,
      value: item.id,
    }));

    return (
      <div>
        <span className="regular-2 popover-arrow-color-txt">
          Task assignee will have full access to the respective sequence(s) and
          prospects of that sequence(s).
        </span>

        <div className="bs-mt-20">
          <span className="regular-1 font-medium gray-txt-15">Assign to</span>
          <div className="d-flex task-assignee__select">
            <Select<{
              key: string;
              value: number;
            }>
              options={options}
              selectedOptionKey={
                options?.find((item) => item.value === selectedAssignee)?.key
              }
              placeholder="Select Assignee"
              optionRenderer={(option) => <span>{option?.key}</span>}
              selectedOptionRenderer={([option]) => <span>{option?.key}</span>}
              onChange={([option]) => setSelectedAssignee(option?.value)}
              isLoading={getIsRequestPending(taskActionRequestStatus)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ConfirmationModalV3
      show={show}
      onSubmit={() => onSubmit(selectedAssignee)}
      onClose={onClose}
      showCloseIcon={false}
      hideTitleIcon={true}
      icon={<Replace width={24} height={24} />}
      title={`Re-assign ${selectedTaskIds?.length || '1'} Tasks`}
      cancelButtonText="Cancel"
      submitButtonText="Assign"
      content={getModalContent()}
      isSubmitLoading={getIsRequestPending(taskActionRequestStatus)}
      isSubmitDisabled={isButtonDisabled()}
      className="task-confirmation-modal-bulk-action"
    />
  );
};

export default ReassignModal;
