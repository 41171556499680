import React from 'react';

export const NoEmailAccountsFound = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37.4762" cy="40.7633" r="30.4762" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3337 35.2306C16.7424 35.0672 16.9412 34.6035 16.7778 34.1948C16.6144 33.7862 16.1506 33.5873 15.742 33.7507L13.7688 34.5397C13.3602 34.7031 13.1614 35.1668 13.3248 35.5755C13.4882 35.9841 13.9519 36.1829 14.3606 36.0196L16.3337 35.2306ZM22.513 24.5273C22.5758 24.9629 22.2736 25.3669 21.838 25.4298C21.4024 25.4926 20.9984 25.1904 20.9356 24.7548L20.6322 22.6515C20.5694 22.2159 20.8716 21.8119 21.3072 21.749C21.7428 21.6862 22.1469 21.9884 22.2097 22.424L22.513 24.5273ZM16.6399 30.359C16.603 30.7975 16.2176 31.1231 15.7791 31.0862L13.6615 30.9081C13.223 30.8712 12.8973 30.4858 12.9342 30.0472C12.9711 29.6086 13.3566 29.283 13.7951 29.3199L15.9127 29.4981C16.3512 29.535 16.6768 29.9204 16.6399 30.359ZM18.6442 26.12C18.8955 26.4814 18.8062 26.978 18.4448 27.2292C18.0835 27.4804 17.5869 27.3912 17.3357 27.0298L16.1226 25.2851C15.8714 24.9237 15.9606 24.4271 16.322 24.1759C16.6833 23.9246 17.1799 24.0139 17.4312 24.3753L18.6442 26.12Z"
      fill="#CCD0DB"
    />
    <g clipPath="url(#clip0_26228_17259)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8147 44.0448L22.2923 35.3026C21.8944 35.0462 21.5682 34.6982 21.3427 34.2898C21.1172 33.8814 20.9995 33.4253 21 32.962C21 31.3367 22.3623 30.0157 24.0368 30.0156H50.9632C52.6377 30.0156 54 31.3366 54 32.9604C54 33.9048 53.5169 34.7798 52.7077 35.3026L39.1853 44.0448C38.6734 44.3756 38.0867 44.5411 37.4999 44.5411C36.9133 44.5411 36.3266 44.3757 35.8147 44.0448ZM37.5 46.4163C38.4832 46.4163 39.4369 46.1357 40.2579 45.605L53.7798 36.8632C53.8546 36.8147 53.928 36.7642 53.9999 36.7118V51.0367C53.9999 52.6497 52.6558 53.9825 50.9621 53.9825H24.0379C22.3745 53.9825 21 52.6791 21 51.0367V36.7119C21.0718 36.7642 21.1451 36.8145 21.2197 36.8629L34.7422 45.605C35.5632 46.1357 36.5168 46.4163 37.5 46.4163Z"
        fill="url(#paint0_linear_26228_17259)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.5356 49.7821C71.5356 48.9931 70.8961 48.3535 70.1071 48.3535H64.1376C63.3486 48.3535 62.709 48.9931 62.709 49.7821C62.709 50.5711 63.3486 51.2107 64.1376 51.2107H70.1071C70.896 51.2107 71.5356 50.5711 71.5356 49.7821ZM71.5356 56.4491C71.5356 55.6601 70.8961 55.0205 70.1071 55.0205H59.7243C58.9353 55.0205 58.2957 55.6601 58.2957 56.4491C58.2957 57.2381 58.9353 57.8777 59.7243 57.8777H70.1071C70.8961 57.8777 71.5356 57.2381 71.5356 56.4491ZM60.1768 48.3535C60.9658 48.3535 61.6054 48.9931 61.6054 49.7821C61.6054 50.5711 60.9658 51.2107 60.1768 51.2107H59.724C58.935 51.2107 58.2954 50.5711 58.2954 49.7821C58.2954 48.9931 58.935 48.3535 59.724 48.3535H60.1768Z"
      fill="#DBE0E7"
    />
    <path
      d="M64.8268 10.9838C61.0628 10.1256 58.8254 11.9624 58.4821 13.4684C58.2341 14.556 59.0398 15.2677 59.7926 15.4394C61.2982 15.7827 61.1745 13.4955 64.0184 14.144C65.4126 14.4619 66.388 15.3298 66.0955 16.6125C65.7521 18.1183 63.9937 18.6268 62.8956 19.1978C61.9281 19.7107 60.6079 20.6125 60.0611 23.0107C59.7305 24.4608 60.0254 24.968 61.1686 25.2287C62.5348 25.5401 62.9535 24.9903 63.0742 24.4601C63.4049 23.01 63.6235 22.1797 65.4304 21.3303C66.3168 20.9164 69.1191 19.5603 69.7168 16.939C70.3145 14.3178 68.3957 11.7976 64.8268 10.9838Z"
      fill="#DBE0E7"
    />
    <path
      d="M60.7577 27.161C60.4757 27.0967 60.1838 27.0886 59.8987 27.1371C59.6136 27.1856 59.3408 27.2898 59.0959 27.4438C58.851 27.5977 58.6389 27.7984 58.4716 28.0343C58.3043 28.2702 58.1851 28.5368 58.1208 28.8188C58.0565 29.1008 58.0484 29.3926 58.0969 29.6778C58.1454 29.9629 58.2496 30.2357 58.4035 30.4805C58.5575 30.7254 58.7581 30.9375 58.994 31.1048C59.23 31.2722 59.4965 31.3914 59.7785 31.4556C60.348 31.5855 60.9458 31.4838 61.4403 31.1729C61.9348 30.862 62.2856 30.3674 62.4155 29.7979C62.5453 29.2284 62.4436 28.6306 62.1327 28.1361C61.8218 27.6416 61.3272 27.2909 60.7577 27.161Z"
      fill="#DBE0E7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_26228_17259"
        x1="29.7348"
        y1="51.6164"
        x2="30.9364"
        y2="29.4518"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <clipPath id="clip0_26228_17259">
        <rect
          width="33"
          height="32"
          fill="white"
          transform="translate(21 26)"
        />
      </clipPath>
    </defs>
  </svg>
);
