import React, { useEffect } from 'react';
import Form from '../login-form';
import type { IProps } from './types';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';
import { SHLogoDark } from '../../../../shared/svg';
import { SHLogoTypography } from '../../../../shared/svg/sh-typography';
import {
  getIsRequestPending,
  getIsRequestSucceeded,
} from '../../../../shared/utils';
import { isInternalUrl } from '../../../../shared/utils/is-internal-url';
import HelmetIcon from '../../../../shared/components/helmet-icon';
import { defaultAgencyObj } from '../../../home/utils/helper';

const Signup: React.FC<IProps> = ({
  agencyConfig,
  agencyConfigRequestStatus,
}) => {
  const isWhitelabel = !!agencyConfig?.clientBaseUrl;

  return (
    <>
      {getIsRequestSucceeded(agencyConfigRequestStatus) && (
        <HelmetIcon icon={agencyConfig?.favicon || defaultAgencyObj?.favicon} />
      )}
      {(getIsRequestPending(agencyConfigRequestStatus) && !isInternalUrl()) ||
      isWhitelabel ? null : (
        <div className="testimonials-container">
          <div className="d-flex align-items-center">
            <SHLogoDark />
            <div className="bs-ml-10">
              <SHLogoTypography />
            </div>
          </div>

          <TestimonialsCarousel />
          <p className="copyright">
            &copy;{`${new Date().getFullYear()} Saleshandy`}
          </p>
        </div>
      )}
      <Form />
    </>
  );
};

export default Signup;
