import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';

import { RootState } from '../../../../../../store/root-reducer';

import DoNotContactList from './do-not-contact-list';

import {
  CreateDoNotContactListPayload,
  GetDoNotContactListsPayload,
  UpdateDoNotContactListPayload,
} from '../../../../types/do-not-contact-list';
import {
  getDoNotContactListsRequest,
  createDoNotContactListRequest,
  updateDoNotContactListRequest,
  deleteDoNotContactListRequest,
} from '../../extra-actions';
import {
  resetGetDoNotContactListsRequest,
  resetCreateDoNotContactListRequest,
  resetUpdateDoNotContactListRequest,
  resetDeleteDoNotContactListRequest,
  resetCreatedDoNotContactListId,
} from '../../do-not-contact-slice';
import { GetAgencyClientDropdownRequestPayload } from '../../../../../agency-client-management/types/request-payload';
import { getAgencyClientsDropdown } from '../../../../../agency-client-management/extra-actions';

const mapStateToProps = (state: RootState) => ({
  getDoNotContactListsRequestStatus:
    state.doNotContact.getDoNotContactListsRequest.status,
  getDoNotContactListsRequestMessage:
    state.doNotContact.getDoNotContactListsRequest.message,
  getDoNotContactListsRequestError:
    state.doNotContact.getDoNotContactListsRequest.error,

  createDoNotContactListRequestStatus:
    state.doNotContact.createDoNotContactListRequest.status,
  createDoNotContactListRequestMessage:
    state.doNotContact.createDoNotContactListRequest.message,
  createDoNotContactListRequestError:
    state.doNotContact.createDoNotContactListRequest.error,

  updateDoNotContactListRequestStatus:
    state.doNotContact.updateDoNotContactListRequest.status,
  updateDoNotContactListRequestMessage:
    state.doNotContact.updateDoNotContactListRequest.message,
  updateDoNotContactListRequestError:
    state.doNotContact.updateDoNotContactListRequest.error,

  deleteDoNotContactListRequestStatus:
    state.doNotContact.deleteDoNotContactListRequest.status,
  deleteDoNotContactListRequestMessage:
    state.doNotContact.deleteDoNotContactListRequest.message,
  deleteDoNotContactListRequestError:
    state.doNotContact.deleteDoNotContactListRequest.error,

  doNotContactLists: state.doNotContact.doNotContactLists,
  doNotContactListsPaginationOptions:
    state.doNotContact.doNotContactListsPaginationOptions,
  createdDoNotContactListId: state.doNotContact.createdDoNotContactListId,

  agencyClients: state.agencyClient.clients,
  agencyConfigRequestStatus: state.home?.getAgencyConfigRequest.status,
});

const mapDispatchToProps = {
  sendGetDoNotContactListsRequest: (payload: GetDoNotContactListsPayload) =>
    getDoNotContactListsRequest(payload),
  resetGetDoNotContactListsRequest: () => resetGetDoNotContactListsRequest(),

  sendCreateDoNotContactListRequest: (payload: CreateDoNotContactListPayload) =>
    createDoNotContactListRequest(payload),
  resetCreateDoNotContactListRequest: () =>
    resetCreateDoNotContactListRequest(),
  resetCreatedDoNotContactListId: () => resetCreatedDoNotContactListId(),

  sendUpdateDoNotContactListRequest: (payload: UpdateDoNotContactListPayload) =>
    updateDoNotContactListRequest(payload),
  resetUpdateDoNotContactListRequest: () =>
    resetUpdateDoNotContactListRequest(),

  sendDeleteDoNotContactListRequest: (id: string) =>
    deleteDoNotContactListRequest(id),
  resetDeleteDoNotContactListRequest: () =>
    resetDeleteDoNotContactListRequest(),

  sendGetAgencyClientsDropdownRequest: (
    payload: GetAgencyClientDropdownRequestPayload,
  ) => getAgencyClientsDropdown(payload),

  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(DoNotContactList);
