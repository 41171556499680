import React from 'react';
import { Images } from '../../app-constants';
import ImageIcon from './image-icon';

const ProspectActivityEmailUnsubscribedColored = () => (
  <ImageIcon
    src={Images.ProspectActivityEmailUnsubscribedColored}
    alt="empty-data/"
  />
);

export default ProspectActivityEmailUnsubscribedColored;
