import React, { useState, useEffect } from 'react';
import Table from '../../../../../../../../shared/design-system/components/organisms/table';
import { getDomainSetupTableColumns } from '../../utils/domain-setup-table-column';
import { PaginationShowHide } from '../../../../../../../../shared/design-system/components/organisms/table/types';

const DomainSetupTable = ({
  domain,
  onRefresh,
  isVerified = null,
  isDomainSetup,
  isRefreshed,
}) => {
  const [isContentCopied, setIsContentCopied] = useState(false);

  const isValidDomain = () => {
    const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{2,6}$/;
    return domainPattern.test(domain);
  };

  const extractParts = () => {
    if (!isValidDomain()) {
      return null;
    }

    const parts = domain.split('.');

    if (!!parts?.length) {
      const cName = parts[0];
      parts.pop();
      const textDomain = parts.join('.');
      return { cName, textDomain, isSubDomain: parts.length > 1 };
    }
  };

  const getTextValue = () => {
    if (!extractParts()?.isSubDomain) {
      return '@';
    }

    return extractParts()?.cName;
  };

  const handleRefresh = () => {
    if (isDomainSetup) {
      onRefresh();
    }
  };

  const data = [
    {
      recordType: 'CNAME',
      recordHost: isValidDomain() ? getTextValue() : 'Brand domain required',
      pointsToValue: 'whitelabel.saleshandy.com',
      isVerified,
      cNameValue: extractParts()?.cName,
      isDisabled: isDomainSetup,
    },
  ];

  const onContentCopy = (text) => {
    navigator.clipboard.writeText(text);
    setIsContentCopied(true);

    setTimeout(() => {
      setIsContentCopied(false);
    }, 2000);
  };

  useEffect(() => {}, [domain]);

  return (
    <div className="domain-setup-table">
      <Table
        isLoading={isRefreshed}
        data={data}
        columns={getDomainSetupTableColumns(
          handleRefresh,
          onContentCopy,
          isContentCopied,
          isValidDomain(),
        )}
        pagination={PaginationShowHide.HIDE}
        paginationOptions={{
          options: {
            size: 2,
            limit: 2,
            totalElements: 2,
          },
        }}
        loadingRowsCount={1}
      />
    </div>
  );
};

export default DomainSetupTable;
