import React, { ReactNode } from 'react';

type IconAndLogProps = {
  icon: ReactNode;
  log: string;
  type: string;
};

const IconAndLog: React.FC<IconAndLogProps> = ({ icon, log, type }) => (
  <div className="icon-log-container mt-3 d-flex">
    <div className="icon-container-wrapper">
      <div className={`icon-container icon-${type}`}>{icon}</div>{' '}
    </div>
    <div
      className="ml-3 activity-html-content"
      dangerouslySetInnerHTML={{ __html: log }}
    />
  </div>
);

export default IconAndLog;
