/* eslint-disable @typescript-eslint/no-shadow */
import React, { useState } from 'react';
import { AlertCircle, Trash } from '@saleshandy/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { capitalize } from '../../../../utils';
import Input from '../../input';
import { Placement } from '../../overlay';
import { OverlayTooltip } from '../../overlay/tooltip';
import Select from '../../select';
import DeleteCustomFieldModal from '../../../../../components/settings/components/custom-fields/components/delete-custom-field-modal';
import toaster from '../../../../toaster';
import ConfirmationModalV3 from '../confirmation-modal/confirmation-modal-v3';
import { CONTACT_CATEGORY_OPTIONS } from './types';
import { RootState } from '../../../../../store/root-reducer';
import { RequestStatus } from '../../../../enums/request-status';
import { FormMode } from '../../organisms/form/types';
import Tag from '../../tag';
import { Variant } from '../../tag/enums';
import Icon from '../icon';

export default function IndividualEmailField({
  email,
  emails,
  setEmails,
  index,
  handleBlur,
  errors,
  touched,
  setFieldTouched,
  formMode,
  primaryEmailChangeInProspectRequest,
  deleteEmailFromProspectRequest,
  selectedProspectId,
  setIsExpandedContacts,
}) {
  const { t } = useTranslation();
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isShowConfirmationModal, setIsShowConfirmationModal] = useState(false);
  const changePrimaryEmailInProspectRequest = useSelector(
    (state: RootState) => state?.prospect?.changePrimaryEmailInProspectRequest,
  );

  const handleDeleteClick = (_index) => {
    const deleteEmail = emails.find((_, i) => i === _index);
    deleteEmailFromProspectRequest(deleteEmail?.id, selectedProspectId);
  };

  const handleEmailChange = (index: number, newEmail: any) => {
    const updatedEmails = emails.map((email, i) =>
      i === index ? { ...email, email: newEmail } : email,
    );

    setEmails(updatedEmails);
  };

  const handleEmailTypeChange = (index, emailType: any) => {
    const updatedEmails = emails.map((email, i) =>
      i === index ? { ...email, emailType } : email,
    );

    setEmails(updatedEmails);
  };

  const handleIsPrimaryChange = (index) => {
    const updatedEmails = emails.map((email, i) =>
      i === index
        ? { ...email, isPrimary: true }
        : { ...email, isPrimary: false },
    );
    const emailTobeChange = emails.find((_email, i) => index === i);
    updatedEmails.sort((a: any, b: any) => b.isPrimary - a.isPrimary);
    setEmails(updatedEmails);
    primaryEmailChangeInProspectRequest(
      emailTobeChange?.id,
      selectedProspectId,
    );
  };

  const AddProspectHandleIsPrimaryChange = (index) => {
    const updatedEmails = emails.map((email, i) =>
      i === index
        ? { ...email, isPrimary: true }
        : { ...email, isPrimary: false },
    );
    updatedEmails.sort((a: any, b: any) => b.isPrimary - a.isPrimary);
    setEmails(updatedEmails);
  };

  const AddProspectHandleDeleteClick = (_index) => {
    const updatedEmails = emails.filter((_, i) => i !== _index);

    setEmails(updatedEmails);
  };

  const labelClass = classNames([
    'bs-radio-wrapper',
    {
      'bs-radio-wrapper-disabled': false,
    },
  ]);

  const spanClass = classNames([
    'bs-radio',
    {
      'bs-radio-checked': email?.isPrimary,
    },
  ]);

  const iconConfigs = {
    skip: {
      identifier: 'circle-check',
      toolTipText: 'Unverified',
      className: 'gray-txt-23',
    },
    valid: {
      identifier: 'circle-check',
      toolTipText: 'Valid',
      className: 'green-txt-14',
    },
    risky: {
      identifier: 'danger',
      toolTipText: 'Risky',
      className: 'orange-txt-6',
    },
    bad: {
      identifier: 'warning',
      toolTipText: 'Bad',
      className: 'red-txt-6',
    },
    unknown: {
      identifier: 'danger',
      toolTipText: 'Risky',
      className: 'red-txt-6',
    },
    inProgress: {
      identifier: 'rotate-clockwise',
      toolTipText: 'In Progress',
      className: 'gray-txt-23',
    },
  };

  const iconStyle = { marginLeft: '.25rem', marginTop: '.15rem' };

  const iconConfig = iconConfigs[email?.emailVerificationStatus];

  const getVerificationIcon = () => (
    <OverlayTooltip text={iconConfig?.toolTipText}>
      <Icon
        identifier={iconConfig?.identifier}
        className={`${iconConfig?.className} position-absolute email-varification-icon m-0`}
        style={iconStyle}
      />
    </OverlayTooltip>
  );

  const input = (
    <Input
      type="text"
      placeholder="Enter Email"
      disabled={
        formMode === FormMode.Edit
          ? (email?.emailVerificationStatus &&
              email?.emailVerificationStatus !== 'skip') ||
            email?.isPrimary
          : false
      }
      className={`ml-2 mb-0 ${
        email?.emailVerificationStatus && 'verification-status'
      } ${email?.isBounced && 'bounced-status'}`}
      name="email"
      value={email?.email}
      onChange={(e: any) => handleEmailChange(index, e)}
      onBlur={() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        handleBlur;
        setFieldTouched(`email${index}`);
      }}
      variant={
        errors[`email${index}`] &&
        touched[`email${index}`] &&
        Input.Variant.Error
      }
    />
  );

  return (
    <div className="individual-contact-field row ml-0" key={index}>
      <div className="col-7 contact-col d-flex align-items-center pl-0">
        {/* eslint-disable-next-line no-nested-ternary */}
        {email?.id ? (
          <OverlayTooltip
            text={
              //  eslint-disable-next-line
              email?.isPrimary
                ? 'Primary Email Address'
                : 'Mark as primary email'
            }
            className="tooltip-md tooltip-primary-email"
            placement={Placement.BottomStart}
          >
            <label key={index} className={labelClass}>
              <span className={spanClass}>
                <input
                  type="radio"
                  checked={email?.isPrimary}
                  className="bs-radio-input radio-button"
                  value={email?.isPrimary}
                  onClick={() => {
                    if (email?.id && !email?.isPrimary) {
                      setIsShowConfirmationModal(true);
                    } else if (email?.id && !email?.isPrimary) {
                      handleEmailChange(index, email);
                    }
                  }}
                />
                <span className="bs-radio-inner" />
              </span>
            </label>
          </OverlayTooltip>
        ) : formMode !== FormMode.Edit &&
          (email?.isPrimary ||
            !(email.email === '' || errors[`email${index}`])) ? (
          <OverlayTooltip
            text={
              //  eslint-disable-next-line
              email?.isPrimary
                ? 'Primary Email Address'
                : 'Mark as primary email'
            }
            className="tooltip-md tooltip-primary-email"
            placement={Placement.BottomStart}
          >
            <label key={index} className={labelClass}>
              <span className={spanClass}>
                <input
                  type="radio"
                  checked={email.isPrimary}
                  disabled={
                    (formMode === FormMode.Edit && email?.isPrimary) ||
                    email.email === '' ||
                    errors[`email${index}`]
                  }
                  className="bs-radio-input radio-button"
                  value={email?.isPrimary}
                  onClick={() => {
                    if (email?.id) {
                      setIsShowConfirmationModal(true);
                    } else if (formMode !== FormMode.Edit) {
                      setIsShowConfirmationModal(true);
                    }
                  }}
                />
                <span className="bs-radio-inner" />
              </span>
            </label>
          </OverlayTooltip>
        ) : (
          <label key={index} className={labelClass}>
            <span className={spanClass}>
              <input
                type="radio"
                checked={email.isPrimary}
                disabled={
                  (formMode === FormMode.Edit && email?.isPrimary) ||
                  email.email === '' ||
                  errors[`email${index}`]
                }
                className="bs-radio-input radio-button"
                value={email.isPrimary}
                onClick={() => {
                  if (email?.id) {
                    setIsShowConfirmationModal(true);
                  } else if (formMode !== FormMode.Edit) {
                    setIsShowConfirmationModal(true);
                  }
                }}
              />
              <span className="bs-radio-inner" />
            </span>
          </label>
        )}

        {email?.id ? (
          <OverlayTooltip
            placement={Placement.BottomStart}
            className="email-id-tooltip"
            text={email.email}
          >
            {input}
          </OverlayTooltip>
        ) : (
          input
        )}

        {iconConfig && getVerificationIcon()}
        {email?.isBounced && (
          <OverlayTooltip
            className="bounced-tooltip"
            text={t('labels.this_email_was_previously_bounced')}
          >
            <span className="position-absolute bounced-tag">
              <Tag variant={Variant.Default} value="Bounced" />
            </span>
          </OverlayTooltip>
        )}
      </div>
      <div className="col-5 contact-col d-flex align-items-center">
        <Select<any>
          options={CONTACT_CATEGORY_OPTIONS}
          optionFilterProp="name"
          placeholder="Select Type"
          selectedOptionKey={email?.emailType}
          optionRenderer={(option) => <span>{capitalize(option?.name)}</span>}
          showOptionsSeparator={true}
          onChange={([e]) => {
            handleEmailTypeChange(index, e?.name);
            setFieldTouched(`email${index}`);
          }}
          selectedOptionRenderer={([option]) => (
            <span>{capitalize(option?.name)}</span>
          )}
        />

        <OverlayTooltip
          rootClose={true}
          text={
            email?.isPrimary
              ? 'Primary email address can not be deleted'
              : 'Delete this email'
          }
          className="tooltip-md tooltip-arrow"
          placement={Placement.Bottom}
        >
          <span>
            <Trash
              width={20}
              height={20}
              className={`gray-txt-15 ml-2 ${
                email.isPrimary ? 'disable-trash-icon' : 'trash-icon'
              }`}
              onClick={() => {
                if (!email?.isPrimary) {
                  if (email?.id) {
                    setIsShowDeleteModal(true);
                    return;
                  }

                  setEmails(
                    emails?.filter(
                      (email, indexTobeDelete) => index !== indexTobeDelete,
                    ),
                  );
                }
              }}
            />
          </span>
        </OverlayTooltip>
      </div>
      <span className="form-input-error-caption red-txt-12 ml-4 py-1">
        {errors[`email${index}`] &&
          touched[`email${index}`] &&
          errors[`email${index}`]}
      </span>
      {isShowDeleteModal && (
        <DeleteCustomFieldModal
          show={isShowDeleteModal}
          title={t('messages.delete_mail_modal_title')}
          content={t('messages.delete_mail_modal_content')}
          onClose={() => setIsShowDeleteModal(false)}
          className="delete-modal prospect-delete-modal"
          showCloseIcon={false}
          onSubmit={() => {
            if (!email.isPrimary) {
              if (formMode === FormMode.Edit) {
                handleDeleteClick(index);
              } else {
                AddProspectHandleDeleteClick(index);
                toaster.success('Email address deleted successfully');
              }
              setIsShowDeleteModal(false);
            }
          }}
          isRequestPending={false}
        />
      )}
      {isShowConfirmationModal && (
        <ConfirmationModalV3
          show={isShowConfirmationModal}
          onClose={() => setIsShowConfirmationModal(false)}
          onSubmit={() => {
            if (!email.isPrimary && formMode === FormMode.Edit) {
              handleIsPrimaryChange(index);
              setIsShowConfirmationModal(false);
              changePrimaryEmailInProspectRequest?.status ===
                RequestStatus.Succeeded &&
                toaster.success('Primary email address updated successfully');
            } else {
              AddProspectHandleIsPrimaryChange(index);
              setIsShowConfirmationModal(false);
              toaster.success('Primary email address updated successfully');
            }
          }}
          title={t('messages.confirmation_mail_modal_title')}
          content={t('messages.confirmation_mail_modal_content')}
          className="confirmation-modal-v3 confirmation-modal"
          cancelButtonText="cancel"
          icon={<AlertCircle />}
          showCloseIcon={false}
          hideTitleIcon
        />
      )}
    </div>
  );
}
