export enum UserAndTeamsTabs {
  Users = 'Users',
  Teams = 'Teams',
}

export enum UserSortBy {
  LastLogin = 'lastLogin',
}

export enum TeamSortBy {
  CreatedAt = 'createdAt',
}

export enum UserStatusKeys {
  InActive = 0,
  Active = 1,
  Invited = 2,
  InviteCancelled = 3,
  Deleted = 4,
  Failed = 5,
}

export enum UserStatusLabel {
  InActive = 'Disabled',
  Active = 'Active',
  Invited = 'Invited',
  InviteCancelled = 'InviteCancelled',
  Deleted = 'Deleted',
  Failed = 'Failed',
}

export enum UserRole {
  Owner = 'owner',
  Admin = 'admin',
  Member = 'member',
  TeamManager = 'team-manager',
}
