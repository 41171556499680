import { Action } from '../../../../../../../shared/design-system/components/organisms/table/types';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';

export const getActionPermissions = (action: Action): boolean => {
  if (
    action.key === 'modifyTeam' &&
    hasPermission(Permissions.ACCOUNT_TEAM_UPDATE)
  ) {
    return true;
  }

  if (
    action.key === 'delete' &&
    hasPermission(Permissions.ACCOUNT_TEAM_DELETE)
  ) {
    return true;
  }

  return false;
};
