import React from 'react';

export const ArrowForwardUp = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7197 3.22248C11.0126 2.92958 11.4874 2.92958 11.7803 3.22248L14.7803 6.22248C15.0732 6.51537 15.0732 6.99024 14.7803 7.28314L11.7803 10.2831C11.4874 10.576 11.0126 10.576 10.7197 10.2831C10.4268 9.99024 10.4268 9.51537 10.7197 9.22248L12.4393 7.50281H6C5.40326 7.50281 4.83097 7.73986 4.40901 8.16182C3.98705 8.58377 3.75 9.15607 3.75 9.75281C3.75 10.3495 3.98705 10.9218 4.40901 11.3438C4.83097 11.7658 5.40326 12.0028 6 12.0028H6.75C7.16421 12.0028 7.5 12.3386 7.5 12.7528C7.5 13.167 7.16421 13.5028 6.75 13.5028H6C5.00544 13.5028 4.05161 13.1077 3.34835 12.4045C2.64509 11.7012 2.25 10.7474 2.25 9.75281C2.25 8.75825 2.64509 7.80442 3.34835 7.10116C4.05161 6.3979 5.00544 6.00281 6 6.00281H12.4393L10.7197 4.28314C10.4268 3.99024 10.4268 3.51537 10.7197 3.22248Z"
      fill="currentColor"
    />
  </svg>
);
