import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import type { IProps } from './billing-subscription-container';
import Suspense from '../../../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../../../shared/components/error-boundary-wrapper';
import retryLazy from '../../../retry-lazy';
import { Routes } from '../../../../shared/routes';

const SubscriptionInfo = React.lazy(() =>
  retryLazy(() => import('./components/subscription-info')),
);
const UpgradePlanTabs = React.lazy(() =>
  retryLazy(() => import('./components/upgrade-plan-tabs')),
);

const BillingSubscription: React.FC<IProps> = (props) => {
  useEffect(
    () => () => {
      const { resetSubscription } = props;
      resetSubscription();
    },
    [],
  );

  return (
    <div className="billing-subscription">
      <ErrorBoundaryWrapper>
        <Suspense>
          <Switch>
            <Route path="/settings/billing/subscriptions" exact>
              <SubscriptionInfo containerProps={props} />
            </Route>
            <Route path="/settings/billing/subscriptions/:tab" exact>
              <SubscriptionInfo containerProps={props} />
            </Route>
            <Route path={`${Routes.Setting_Billing_Upgrade_Plan}`} exact>
              <UpgradePlanTabs />
            </Route>
            <Route path={`${Routes.Setting_Billing_Upgrade_Plan}/:tab`} exact>
              <UpgradePlanTabs />
            </Route>
            <Redirect to="/settings/billing/subscriptions" />
          </Switch>
        </Suspense>
      </ErrorBoundaryWrapper>
    </div>
  );
};

export default BillingSubscription;
