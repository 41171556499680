import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import Teams from './teams';
import {
  addTeamRequest,
  deleteTeamRequest,
  deleteUserFromTeamRequest,
  getMembersListRequest,
  getTeamsRequest,
  modifyTeamRequest,
} from '../../extra-actions';
import {
  resetAddTeamRequest,
  resetDeleteTeamRequest,
  resetDeleteUserFromTeamRequest,
  resetGetMembersListRequest,
  resetModifyTeamRequest,
} from '../../users-and-teams-slice';
import {
  UserAndTeamsTabs,
  UserStatusKeys,
} from '../../../../enums/users-and-teams';
import {
  AddTeamPayload,
  DeleteUserFromTeamPayload,
  ModifyTeamPayload,
  TeamsFilter,
} from '../../../../types/users-and-teams';

const mapStateToProps = (state: RootState) => ({
  teams: state.usersAndTeams.teams,
  teamsPaginationOptions: state.usersAndTeams.teamsPaginationOptions,
  membersList: state.usersAndTeams.membersList,
  userTimezone: state.home.timeZone,
  userId: state.home.userId,
  atmRole: state.home.atmRole,

  getTeamsRequestStatus: state.usersAndTeams.getTeamsRequest.status,
  getTeamsRequestError: state.usersAndTeams.getTeamsRequest.error,
  getTeamsRequestMessage: state.usersAndTeams.getTeamsRequest.message,

  getMembersListRequestStatus: state.usersAndTeams.getMembersListRequest.status,
  getMembersListRequestError: state.usersAndTeams.getMembersListRequest.error,
  getMembersListRequestMessage:
    state.usersAndTeams.getMembersListRequest.message,

  addTeamRequestStatus: state.usersAndTeams.addTeamRequest.status,
  addTeamRequestError: state.usersAndTeams.addTeamRequest.error,
  addTeamRequestMessage: state.usersAndTeams.addTeamRequest.message,

  modifyTeamRequestStatus: state.usersAndTeams.modifyTeamRequest.status,
  modifyTeamRequestError: state.usersAndTeams.modifyTeamRequest.error,
  modifyTeamRequestMessage: state.usersAndTeams.modifyTeamRequest.message,

  deleteTeamRequestStatus: state.usersAndTeams.deleteTeamRequest.status,
  deleteTeamRequestError: state.usersAndTeams.deleteTeamRequest.error,
  deleteTeamRequestMessage: state.usersAndTeams.deleteTeamRequest.message,

  deleteUserFromTeamRequestStatus:
    state.usersAndTeams.deleteUserFromTeamRequest.status,
  deleteUserFromTeamRequestError:
    state.usersAndTeams.deleteUserFromTeamRequest.error,
  deleteUserFromTeamRequestMessage:
    state.usersAndTeams.deleteUserFromTeamRequest.message,
});

const mapDispatchToProps = {
  sendGetTeamsRequest: (filters: TeamsFilter) => getTeamsRequest(filters),

  sendGetMembersListRequest: () =>
    getMembersListRequest([UserStatusKeys.Active, UserStatusKeys.Invited]),
  resetGetMembersListRequest: () => resetGetMembersListRequest(),

  sendAddTeamRequest: (payload: AddTeamPayload) => addTeamRequest(payload),
  resetAddTeamRequest: () => resetAddTeamRequest(),

  sendModifyTeamRequest: (payload: ModifyTeamPayload) =>
    modifyTeamRequest(payload),
  resetModifyTeamRequest: () => resetModifyTeamRequest(),

  sendDeleteTeamRequest: (id: number) => deleteTeamRequest(id),
  resetDeleteTeamRequest: () => resetDeleteTeamRequest(),

  sendDeleteUserFromTeamRequest: (payload: DeleteUserFromTeamPayload) =>
    deleteUserFromTeamRequest(payload),
  resetDeleteUserFromTeamRequest: () => resetDeleteUserFromTeamRequest(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  activeTab: UserAndTeamsTabs;
  addTeamModal: boolean;
  showAddTeamModal: (value: boolean) => void;
  hideAddTeamModal: VoidFunction;
  isTeamsFilterDirty: boolean;
  setIsTeamsFilterDirty: (value: boolean) => void;
  tableRef: any;
};

export default connector(Teams);
