import React, { ReactElement, Ref } from 'react';
import { ArrowProps, Placement } from 'react-bootstrap/Overlay';
import composeRefs from '@seznam/compose-react-refs';
import classNames from 'classnames';
import Transfer, { ExtendedOption, TransferProps } from './transfer';

export type TransferOverlayProps<T> = {
  id: string;
  placement?: Placement;
  arrowProps?: ArrowProps;
  popper?: any;
  show?: boolean;
  style?: any;
  customRef?: Ref<HTMLDivElement>;
  transferContainerStyle?: any;
  autoFocusSearch?: boolean;
  downSearch?: boolean;
} & TransferProps<T>;

// Disabled @typescript-eslint/no-unused-vars warning for some props as they
// needs to be extracted for using the rest operator.
// They aren't used anywhere for now, but they will be useful in the future.
const TransferOverlay = (React.forwardRef(
  <T extends ExtendedOption>(
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      id,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      placement,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      arrowProps,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      show,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      popper,
      style,
      customRef,
      className,
      ...rest
    }: TransferOverlayProps<T>,
    ref: Ref<HTMLDivElement>,
  ) => {
    const transferOverlayClass = classNames(['transfer-overlay', className]);
    return (
      <div
        className={transferOverlayClass}
        style={style}
        ref={composeRefs(ref, customRef)}
      >
        <Transfer<T> {...rest} />
      </div>
    );
  },
) as unknown) as <T>(
  props: TransferOverlayProps<T> & { ref?: Ref<HTMLDivElement> },
) => ReactElement;

export default TransferOverlay;
