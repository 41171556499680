import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from '../../../../../../store/root-reducer';
import { CreateOutcomeRequestPayload } from '../../../../types/request-payload';
import { createCustomOutcomeRequest } from '../../extra-actions';
import GenericCustomOutcomeDropdown from './custom-outcome-dropdown';
import { IPropsComponent } from './types';
import { onOutcomeChangeRequest } from '../../../../../sequence/extra-actions';

const mapStateToProps = (state: RootState) => ({

  createCustomOutcomeRequestStatus:
    state.customOutcome.createCustomOutcomeRequest.status,
  createCustomOutcomeRequestMessage:
    state.customOutcome.createCustomOutcomeRequest.message,
  createCustomOutcomeRequestError:
    state.customOutcome.createCustomOutcomeRequest.error,
  allOutcomes: state.customOutcome.outcomes,
});

const mapDispatchToProps = {
  sendCreateCustomOutcomeRequest: (payload: CreateOutcomeRequestPayload) =>
    createCustomOutcomeRequest(payload),
  sendOnOutcomeChangeRequest: (
    sequenceId: number,
    prospectId: number,
    outcomeId: number,
  ) => onOutcomeChangeRequest({ sequenceId, prospectId, outcomeId }),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps<any> & IPropsComponent;

export default connector(GenericCustomOutcomeDropdown);
