import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import { resetPasswordRequest } from './extra-actions';

type State = {
  status: RequestStatus;
  message: string;
  error: any;
  email: string;
  showError: boolean;
};

const initialState: State = {
  status: RequestStatus.Ideal,
  message: '',
  error: '',
  email: '',
  showError: false,
};

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    hideError: (state) => {
      state.showError = false;
    },
    resetPasswordRequestState: (state) => {
      state.status = initialState.status;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetPasswordRequest.pending, (state) => {
      state.status = RequestStatus.Pending;
      state.showError = false;
    });
    builder.addCase(resetPasswordRequest.fulfilled, (state, action) => {
      state.status = RequestStatus.Succeeded;
      state.message = action.payload.message;
      state.email = action.meta.arg.email;
    });
    builder.addCase(resetPasswordRequest.rejected, (state, action) => {
      state.status = RequestStatus.Failed;
      state.error = action.payload;
      state.showError = !action.payload.isHandled;
    });
  },
});

export const {
  hideError,
  resetPasswordRequestState,
} = resetPasswordSlice.actions;
export { resetPasswordRequest };
export default resetPasswordSlice.reducer;
