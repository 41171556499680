import React from 'react';
import {
  GlobalSettingsProps,
  GlobalSettingsHeaderTitleProps,
  GlobalSettingsHeaderProps,
  GlobalSettingsContentProps,
} from './type';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';

const GlobalSettingWrapper: React.FC<GlobalSettingsProps> = ({
  children,
  className = '',
}) => <div className={`globalSettings__Wrapper ${className}`}>{children}</div>;

const GlobalSettingsHeader: React.FC<GlobalSettingsHeaderProps> = ({
  children,
  className,
}) => <div className={`globalSettings__Header ${className}`}>{children}</div>;

const GlobalSettingsContent: React.FC<GlobalSettingsContentProps> = ({
  children,
  className,
}) => <div className={`globalSettings__Content ${className}`}>{children}</div>;

const GlobalSettingsHeaderLeft: React.FC<GlobalSettingsProps> = ({
  children,
}) => <div className="globalSettings__Header__Left">{children}</div>;

const GlobalSettingsHeaderRight: React.FC<GlobalSettingsProps> = ({
  children,
}) => <div className="globalSettings__Header__Right">{children}</div>;

const GlobalSettingsHeaderTitle: React.FC<GlobalSettingsHeaderTitleProps> = ({
  title,
  isToolTip,
  tooltipText,
}) => {
  const headerTitle = (
    <div className="globalSettings__Header__Title">
      <h1>{title}</h1>
    </div>
  );
  return (
    <>
      {isToolTip ? (
        <OverlayTooltip
          rootClose={true}
          placement={Placement.Bottom}
          className="header-title-tooltip"
          text={tooltipText}
        >
          {headerTitle}
        </OverlayTooltip>
      ) : (
        headerTitle
      )}
    </>
  );
};
const GlobalSettings = {
  Wrapper: GlobalSettingWrapper,
  Content: GlobalSettingsContent,
  Header: GlobalSettingsHeader,
  HeaderLeft: GlobalSettingsHeaderLeft,
  HeaderRight: GlobalSettingsHeaderRight,
  HeaderTitle: GlobalSettingsHeaderTitle,
};

export default GlobalSettings;
