import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import type { IProps } from './tasks-container';
import TasksHeader from './components/tasks-header';
import TasksContent from './components/tasks-content';
import { TaskStatusKey } from './utils/enums';
import hasPermission from '../../shared/utils/access-control/has-permission';
import { Permissions } from '../../shared/utils/access-control/enums/permissions';
import { redirectWithoutRefresh } from '../../shared/utils';

const Tasks: React.FC<IProps> = ({ isMultichannelActive, resetTasksState }) => {
  const history = useHistory();
  const location = useLocation();

  const [taskStatus, setTaskStatus] = useState<TaskStatusKey>(
    TaskStatusKey.DueToday,
  );

  const onTaskStatusChange = (status) => {
    setTaskStatus(status);

    history.push(`?status=${status}`);
  };

  // useEffect
  useEffect(() => {
    // Check Task Permission
    if (!isMultichannelActive || !hasPermission(Permissions.TASK_READ)) {
      redirectWithoutRefresh('/sequence');
    }

    onTaskStatusChange(taskStatus);
    // Get status params value from location
    const searchParams = new URLSearchParams(location.search);
    const statusParam: string = searchParams.get('status');

    const taskStatusKeyObject = Object.keys(TaskStatusKey).reduce(
      (acc, key) => {
        acc[key] = TaskStatusKey[key];
        return acc;
      },
      {},
    );
    const keyExists = Object.values(taskStatusKeyObject).includes(statusParam);

    // Set initial task status based on status param
    if (statusParam) {
      onTaskStatusChange(keyExists ? statusParam : TaskStatusKey.DueToday);
    }

    return () => {
      resetTasksState();
    };
  }, []);

  return (
    <div className="tasks">
      <TasksHeader
        taskStatus={taskStatus}
        onChange={(status: TaskStatusKey) => onTaskStatusChange(status)}
      />
      <TasksContent taskStatus={taskStatus} />
    </div>
  );
};

export default Tasks;
