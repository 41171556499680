import React from 'react';
import { Webhook } from '../../../../../../../types/webhook';
import { accessibleOnClick } from '../../../../../../../../../shared/utils/accessible-on-click';
import TruncatedTextWithTooltip from '../../../../../../../../../shared/components/truncated-text-with-tooltip';
import { Placement } from '../../../../../../../../../shared/design-system/components/overlay';

type IProps = {
  webhook: Webhook;
  onClickHandler: (webhook: Webhook) => void;
};

const WebhookNameField: React.FC<IProps> = ({ webhook, onClickHandler }) => (
  <p {...accessibleOnClick(() => onClickHandler(webhook))}>
    <TruncatedTextWithTooltip
      length={40}
      string={webhook?.name || ''}
      placement={Placement.Bottom}
    />
  </p>
);

export default WebhookNameField;
