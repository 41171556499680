import React from 'react';

export const Trash = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 5.25562C2.25 4.8414 2.58579 4.50562 3 4.50562H15C15.4142 4.50562 15.75 4.8414 15.75 5.25562C15.75 5.66983 15.4142 6.00562 15 6.00562H3C2.58579 6.00562 2.25 5.66983 2.25 5.25562Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 7.49438C7.91421 7.49438 8.25 7.83017 8.25 8.24438V12.7444C8.25 13.1586 7.91421 13.4944 7.5 13.4944C7.08579 13.4944 6.75 13.1586 6.75 12.7444V8.24438C6.75 7.83017 7.08579 7.49438 7.5 7.49438Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 7.49438C10.9142 7.49438 11.25 7.83017 11.25 8.24438V12.7444C11.25 13.1586 10.9142 13.4944 10.5 13.4944C10.0858 13.4944 9.75 13.1586 9.75 12.7444V8.24438C9.75 7.83017 10.0858 7.49438 10.5 7.49438Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.68776 4.5082C4.10054 4.4738 4.46305 4.78054 4.49745 5.19333L5.24745 14.1933C5.24918 14.214 5.25004 14.2348 5.25004 14.2556C5.25004 14.4545 5.32906 14.6453 5.46971 14.7859C5.61036 14.9266 5.80113 15.0056 6.00004 15.0056H12C12.199 15.0056 12.3897 14.9266 12.5304 14.7859C12.671 14.6453 12.75 14.4545 12.75 14.2556C12.75 14.2348 12.7509 14.214 12.7526 14.1933L13.5026 5.19333C13.537 4.78054 13.8995 4.4738 14.3123 4.5082C14.7251 4.5426 15.0318 4.90511 14.9975 5.3179L14.2498 14.2899C14.2409 14.8743 14.0049 15.4327 13.591 15.8466C13.1691 16.2686 12.5968 16.5056 12 16.5056H6.00004C5.4033 16.5056 4.83101 16.2686 4.40905 15.8466C3.99519 15.4327 3.75921 14.8743 3.7503 14.2899L3.00263 5.3179C2.96823 4.90511 3.27497 4.5426 3.68776 4.5082Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60217 12 3V5.25C12 5.66421 11.6642 6 11.25 6C10.8358 6 10.5 5.66421 10.5 5.25V3H7.5L7.5 5.25C7.5 5.66421 7.16421 6 6.75 6C6.33579 6 6 5.66421 6 5.25V3C6 2.60217 6.15804 2.22064 6.43934 1.93934Z"
      fill="currentColor"
    />
  </svg>
);
