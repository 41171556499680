import moment from 'moment-timezone';
import { Time } from '../../components/prospect/enums/time';

const getSingularAndPlural = (
  value: number,
  singular: string,
  plural: string,
): string => {
  if (value === 1) {
    return `1 ${singular} ago`;
  }
  return `${value} ${plural} ago`;
};

export const getFormattedTime = (time: string): string => {
  const createdTime = Math.floor(new Date(time).getTime() / 60000);
  const currentTime = Math.floor(new Date().getTime() / 60000);

  const timeElapsedInMinutes = currentTime - createdTime;

  if (timeElapsedInMinutes === Time.MINUTESINYEAR) {
    return '1 year ago';
  }
  if (timeElapsedInMinutes > Time.MINUTESINYEAR) {
    const year = Math.floor(timeElapsedInMinutes / Time.MINUTESINYEAR);
    return getSingularAndPlural(year, 'year', 'years');
  }
  if (
    timeElapsedInMinutes > Time.MINUTESINMONTH &&
    timeElapsedInMinutes < Time.MINUTESINYEAR
  ) {
    const month = Math.floor(timeElapsedInMinutes / Time.MINUTESINMONTH);
    return getSingularAndPlural(month, 'month', 'months');
  }
  if (timeElapsedInMinutes === Time.MINUTESINMONTH) {
    return `1 month ago`;
  }
  if (
    timeElapsedInMinutes > Time.MINUTESINDAY &&
    timeElapsedInMinutes < Time.MINUTESINMONTH
  ) {
    const day = Math.floor(timeElapsedInMinutes / Time.MINUTESINDAY);
    return getSingularAndPlural(day, 'day', 'days');
  }
  if (timeElapsedInMinutes === Time.MINUTESINDAY) {
    return '1 day ago';
  }
  if (
    timeElapsedInMinutes > Time.MINUTESINHOUR &&
    timeElapsedInMinutes < Time.MINUTESINDAY
  ) {
    const hours = Math.floor(timeElapsedInMinutes / Time.MINUTESINHOUR);
    return getSingularAndPlural(hours, 'hour', 'hours');
  }
  if (timeElapsedInMinutes === Time.MINUTESINHOUR) {
    return 'an hour ago';
  }
  if (timeElapsedInMinutes > 1 && timeElapsedInMinutes < Time.MINUTESINHOUR) {
    return `${timeElapsedInMinutes} minutes ago`;
  }
  if (timeElapsedInMinutes <= 1) {
    return 'a few secs ago';
  }
  return '';
};

export const getFormattedDateWithMoment = (time: string): string => {
  const now = moment();
  const dueMoment = moment(time);

  const duration = now.isAfter(dueMoment)
    ? moment.duration(now.diff(dueMoment))
    : moment.duration(dueMoment.diff(now));

  if (now.isSame(dueMoment, 'day')) {
    return 'Today';
  }

  const days = Math.ceil(duration.asDays());
  if (days >= 365) {
    return '1 year';
  }

  if (days < 29) {
    return `${days} ${days > 1 ? 'days' : 'day'}`;
  }

  const months = Math.ceil(duration.asMonths());
  if (months <= 12) {
    return `${months > 1 ? months - 1 : months} ${
      months - 1 > 1 ? 'months' : 'month'
    }`;
  }

  // const years = Math.ceil(duration.asYears());
  // return `${years > 1 ? years - 1 : years} ${years - 1 > 1 ? 'years' : 'year'}`;
};
