import validator from 'validator';

export const validate = (name: string, value: string): string => {
  switch (name) {
    case 'email':
      if (!validator.isEmail(value)) {
        return 'Please enter a valid email address.';
      }
      break;

    case 'password':
      if (validator.isEmpty(value)) {
        return 'Please enter a valid password.';
      }
      break;
    default:
      break;
  }

  return '';
};
