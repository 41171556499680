export enum OutcomeCategory {
  Custom = 'custom',
  Default = 'default',
}

export enum OutcomeSentiment{
  Positive = 'Positive',
  Negative = 'Negative',
  Neutral = 'Neutral',
}

export const OutcomeSentimentTextColor = '#1F2937';

export enum OutcomeSentimentColor{
  Positive = '#D1FAE5',
  Negative = '#FEE2E2',
  Neutral = '#F3F4F6',
}

export enum OutcomeSentimentBGColor{
  Positive = '#10B981',
  Negative = '#EF4444',
  Neutral = '#9CA3AF',
}

export enum OutcomeSentimentIconColor{
  Positive = '#34D399',
  Negative = '#F87171',
  Neutral = '#9CA3AF',
}