import React from 'react';
import { Link } from 'react-router-dom';
import { BrokenLink } from '../../../../../shared/svg';

const InvitationSignupFailed: React.FC = () => (
  <div className="auth-wrapper">
    <div className="auth-container">
      <div className="auth-container--icon">
        <BrokenLink />
      </div>
      <h1 className="auth-container--title">
        The invite link is no longer active
      </h1>
      <p className="auth-container--sub-title">
        The invitation link has expired. Please check with the person who shared
        it with you and request a new link or{' '}
        <Link to="/login" tabIndex={0}>
          Login
        </Link>
        .
      </p>
    </div>
  </div>
);

export default InvitationSignupFailed;
