import React, { forwardRef, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Icon from '../icon';

type DatePickerProps = {
  start_date: Date | null;
  end_date: Date | null;
  onDateChange: (dates: Date[]) => void;
  max_date?: Date | null;
  min_date?: Date | null;
};

type ButtonProps = React.HTMLProps<HTMLButtonElement>;

const DatePickerComponent = ({
  start_date,
  end_date,
  onDateChange,
  min_date = null,
  max_date,
}: DatePickerProps) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const datePickerRef = useRef<any>();
  const [open, setOpen] = useState(false);

  const getMonth = (date: Date) => {
    const month = new Date(date);
    return months[month.getMonth()];
  };

  const CustomInput = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ value, onClick }, ref) => (
      <button
        className="btn datepicker_button"
        type="button"
        onClick={(val) => {
          onClick(val);
          if (!open) {
            setOpen(true);
          } else {
            datePickerRef.current.setOpen(false);
            setOpen(false);
          }
        }}
        ref={ref}
      >
        <Icon className="icon-padding" identifier="calendar-today" />
        <span>{start_date && end_date ? value : 'Custom'} </span>
        <Icon className="icon-padding" identifier="chevron-down" />
      </button>
    ),
  );

  return (
    <DatePicker
      dateFormat="MMM d, yyyy"
      ref={datePickerRef}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="header-wrapper">
          <span className="label">{`${getMonth(date)} ${new Date(
            date,
          ).getFullYear()}`}</span>
          <div className="btn-wrapper">
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
            >
              <Icon identifier="chevron-left" />
            </button>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
            >
              <Icon identifier="chevron-right" />
            </button>
          </div>
        </div>
      )}
      selected={start_date}
      onChange={(dates) => {
        onDateChange(dates);
        if (dates[1]) {
          setOpen(false);
        }
      }}
      startDate={start_date}
      endDate={end_date}
      selectsRange
      // customInput={<CustomInput />}
      onCalendarClose={() => setOpen(false)}
      inline
      minDate={min_date}
      maxDate={max_date}
    />
  );
};

export default DatePickerComponent;
