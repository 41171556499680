import classNames from 'classnames';
import { ProspectType } from '../../../../settings/enums/prospect-type';
import {
  SubjectEmailSuggestion,
  EmailSuggestionStatus,
  PersonalizationEmailSuggestion,
  LinkEmailSuggestion,
  SpammyEmailSuggestion,
  WordEmailSuggestion,
} from './types';
import { Sequence } from '../../../types';
import SpammyWords from '../../../../../shared/utils/spammy-word-list';
// eslint-disable-next-line import/no-cycle
import { EmailContentSizeAllowed } from '../../../../../shared/utils';
import {
  AttachmentType,
  AttachmentUploadStatus,
} from '../../../../../shared/editor/types';
import { SequenceStepType } from '../../../enums';

export const getTestEmailAddressFromLocalStorage = () =>
  localStorage.getItem('test_email_address');

export const saveTestEmailAddressInLocalStorage = (value: string) =>
  localStorage.setItem('test_email_address', value);

export const clearTestEmailAddressFromLocalStorage = () =>
  localStorage.removeItem('test_email_address');

export const getTestEmaiContent = () => [
  {
    id: 1,
    text: 'test_email_content',
  },
];

export const getEmailAccountDisconnectedContent = () => [
  {
    id: 1,
    text: 'email_account_disconnected_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getEmailAccountNotConnectedContent = () => [
  {
    id: 1,
    text: 'email_account_not_connected_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getEmailNotAddedModalContent = () => [
  {
    id: 1,
    text: 'email_not_added_content',
  },
  {
    id: 2,
    text: 'email_account_note_content',
  },
];

export const getConfirmationModalContent = () => [
  {
    id: 1,
    text: 'confirmation_modal_content',
  },
];

export const prepareMergeTagOptions = (orderedFields) =>
  orderedFields.map((item) => {
    let tag = '';
    let fallbackText = '';
    let label = `{{${item.label}}}`;

    if (item.fallbackText !== null) {
      fallbackText =
        item.fallbackText.length > 0 ? `|'${item.fallbackText}'` : '';
    }

    if (item.prospectType === ProspectType.Account) {
      label = `{{account.${item.label}}}`;
      tag = `{{account.${item.label}${fallbackText}}}`;
    } else {
      tag = `{{${item.label}${fallbackText}}}`;
    }

    return {
      key: `${item.prospectType}-${item.id}`,
      type: item.prospectType,
      tag,
      label,
    };
  });

export const isButtonDisabled = (errors, attachments: AttachmentType[]) =>
  errors.content !== '' ||
  errors.subject !== '' ||
  errors.preheader !== '' ||
  attachments.some(
    (item) =>
      item.status === AttachmentUploadStatus.Uploading ||
      item.status === AttachmentUploadStatus.UploadFailed ||
      item.status === AttachmentUploadStatus.Deleting,
  );

export const isSubmitDisabled = (
  isRequestPending,
  isSaveDisabled,
  errors,
  attachments: AttachmentType[],
) =>
  isRequestPending || isSaveDisabled || isButtonDisabled(errors, attachments);

export const footerContent = (footerPart) => footerPart && footerPart();
export const getPrevStepSubject = (
  sequence: Sequence,
  currentStepNumber: number,
) => {
  let prevStepSubject = '';
  let subjectToSelect: string[];
  let stepIndex = currentStepNumber - 2;
  while (!prevStepSubject && stepIndex >= 0) {
    const prevStep = sequence.steps?.filter(
      (step) => step.type === SequenceStepType.Email,
    )?.[stepIndex];
    if (prevStep && prevStep.variants) {
      subjectToSelect = prevStep.variants
        .filter(
          (variant) =>
            variant.status === 1 &&
            variant.payload?.subject !== undefined &&
            variant.payload?.subject !== '',
        )
        .map((variant) => variant.payload?.subject);
      prevStepSubject = subjectToSelect?.[0];
    }
    stepIndex -= 1;
  }
  return prevStepSubject;
};

export const checkSubjectEmailSuggestions = (
  subject: string,
): SubjectEmailSuggestion => {
  // Remove Emoji from subject string
  const subjectWithoutEmojis = subject.replace(
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
    '',
  );

  const { length } = subjectWithoutEmojis;

  if (length >= 1 && length <= 10) {
    return {
      subjectPercentage: 25,
      subjectStatus: EmailSuggestionStatus.TOO_SHORT,
      subjectLength: length,
    };
  }
  if (length >= 11 && length <= 30) {
    return {
      subjectPercentage: 50,
      subjectStatus: EmailSuggestionStatus.SHORT,
      subjectLength: length,
    };
  }
  if (length >= 31 && length <= 60) {
    return {
      subjectPercentage: 100,
      subjectStatus: EmailSuggestionStatus.IDEAL,
      subjectLength: length,
    };
  }
  if (length >= 61 && length <= 70) {
    return {
      subjectPercentage: 50,
      subjectStatus: EmailSuggestionStatus.LENGTHY,
      subjectLength: length,
    };
  }
  if (length >= 71) {
    return {
      subjectPercentage: 25,
      subjectStatus: EmailSuggestionStatus.TOO_LENGTHY,
      subjectLength: length,
    };
  }
  return {
    subjectPercentage: 0,
    subjectStatus: '',
    subjectLength: length,
  };
};

export const checkWordCountSuggestions = (
  content: string,
  stepNumber: number,
): WordEmailSuggestion => {
  // Regular expression to match expressions like {{ merge tag }} or {spin} some text 1 | some text 2 {endspin}
  const expressionRegex = /\{\{[^{}]*\}\}|\{spin\}[^{}]*\{endspin\}/g;

  // Remove HTML tags from the content
  const stringWithoutHTML = content.replace(/<\/?[^>]+(>|$)/g, '');

  // Extract expressions from the string
  const expressions = stringWithoutHTML.match(expressionRegex);

  // Count the number of words in the expressions
  let wordCount = 0;

  if (expressions) {
    expressions.forEach((expression) => {
      // Treat the entire expression as a single word
      wordCount += expression ? 1 : 0;
    });
  }

  // Count the number of words in the remaining text
  const remainingText = stringWithoutHTML.replace(expressionRegex, '');
  const remainingWords = remainingText
    .split(/\s+/)
    .filter((word) => word !== '');

  // Add the count of words in the remaining text to the total word count
  wordCount += remainingWords.length;

  if (stepNumber === 1) {
    if (wordCount >= 1 && wordCount < 50) {
      return {
        wordPercentage: 25,
        wordStatus: EmailSuggestionStatus.TOO_SHORT,
        wordLength: wordCount,
      };
    }
    if (wordCount >= 50 && wordCount <= 200) {
      return {
        wordPercentage: 100,
        wordStatus: EmailSuggestionStatus.IDEAL,
        wordLength: wordCount,
      };
    }
    if (wordCount >= 201 && wordCount <= 300) {
      return {
        wordPercentage: 50,
        wordStatus: EmailSuggestionStatus.LENGTHY,
        wordLength: wordCount,
      };
    }
    if (wordCount >= 301) {
      return {
        wordPercentage: 25,
        wordStatus: EmailSuggestionStatus.TOO_LENGTHY,
        wordLength: wordCount,
      };
    }
  }

  if (wordCount >= 1 && wordCount < 25) {
    return {
      wordPercentage: 25,
      wordStatus: EmailSuggestionStatus.TOO_SHORT,
      wordLength: wordCount,
    };
  }
  if (wordCount >= 25 && wordCount <= 149) {
    return {
      wordPercentage: 100,
      wordStatus: EmailSuggestionStatus.IDEAL,
      wordLength: wordCount,
    };
  }
  if (wordCount >= 150 && wordCount <= 200) {
    return {
      wordPercentage: 50,
      wordStatus: EmailSuggestionStatus.LENGTHY,
      wordLength: wordCount,
    };
  }
  if (wordCount >= 201) {
    return {
      wordPercentage: 25,
      wordStatus: EmailSuggestionStatus.TOO_LENGTHY,
      wordLength: wordCount,
    };
  }

  return {
    wordPercentage: 0,
    wordStatus: '',
    wordLength: wordCount,
  };
};

export const checkPersonalizationEmailSuggestions = (
  content: string,
  mergeTagOptions,
): PersonalizationEmailSuggestion => {
  // This will return all matching merge tags which has opened with {{ and closed with }}
  const ptrn = /{{([^}}]+)}}/g;
  const mergeTags = content.match(ptrn);

  if (mergeTags === null || mergeTags.length === 0) {
    return {
      personalizationPercentage: 25,
      personalizationStatus: EmailSuggestionStatus.POOR,
      personalizationLength: 0,
    };
  }

  const tags = [];
  mergeTagOptions.forEach((field) => {
    tags.push(field.tag);
  });

  const filteredMergeTags = [];
  mergeTags.forEach((tag) => {
    if (tags.includes(tag)) {
      filteredMergeTags.push(tag);
    }
  });

  const { length } = filteredMergeTags;

  if (length === 1) {
    return {
      personalizationPercentage: 50,
      personalizationStatus: EmailSuggestionStatus.AVERAGE,
      personalizationLength: length,
    };
  }
  if (length === 2) {
    return {
      personalizationPercentage: 100,
      personalizationStatus: EmailSuggestionStatus.GOOD,
      personalizationLength: length,
    };
  }
  if (length >= 3) {
    return {
      personalizationPercentage: 100,
      personalizationStatus: EmailSuggestionStatus.EXCELLENT,
      personalizationLength: length,
    };
  }

  return {
    personalizationPercentage: 0,
    personalizationStatus: '',
    personalizationLength: 0,
  };
};

export const checkLinksEmailSuggestions = (
  content: string,
): LinkEmailSuggestion => {
  const container = document.createElement('p');
  container.innerHTML = content;

  const anchors = container.getElementsByTagName('a');
  const links = [];
  const keys = Object.keys(anchors);
  keys.forEach((key) => {
    links.push(anchors[key].href);
  });
  container.remove();

  const { length } = links;

  if (length === 0) {
    return {
      linkPercentage: 100,
      linkStatus: EmailSuggestionStatus.EXCELLENT,
      linkLength: 0,
    };
  }

  if (length === 1) {
    return {
      linkPercentage: 100,
      linkStatus: EmailSuggestionStatus.GOOD,
      linkLength: 1,
    };
  }
  if (length >= 2 && length <= 3) {
    return {
      linkPercentage: 50,
      linkStatus: EmailSuggestionStatus.AVERAGE,
      linkLength: length,
    };
  }
  if (length >= 4) {
    return {
      linkPercentage: 25,
      linkStatus: EmailSuggestionStatus.POOR,
      linkLength: length,
    };
  }

  return {
    linkPercentage: 0,
    linkStatus: '',
    linkLength: 0,
  };
};

export const checkSpammyEmailSuggestions = (
  content: string,
): SpammyEmailSuggestion => {
  const spammyWordsInContent = [];

  SpammyWords.forEach((word) => {
    const regx = new RegExp(`\\b${word}\\b`, 'gi');
    if (regx.test(content)) {
      spammyWordsInContent.push(word);
    }
  });

  const { length } = spammyWordsInContent;

  if (length === 0) {
    return {
      spammyPercentage: 100,
      spammyStatus: EmailSuggestionStatus.EXCELLENT,
      spammyLength: 0,
      spammyWords: [],
    };
  }

  if (length === 1) {
    return {
      spammyPercentage: 100,
      spammyStatus: EmailSuggestionStatus.GOOD,
      spammyLength: 1,
      spammyWords: spammyWordsInContent,
    };
  }

  if (length >= 2 && length <= 5) {
    return {
      spammyPercentage: 50,
      spammyStatus: EmailSuggestionStatus.AVERAGE,
      spammyLength: length,
      spammyWords: spammyWordsInContent,
    };
  }

  if (length >= 6) {
    return {
      spammyPercentage: 25,
      spammyStatus: EmailSuggestionStatus.POOR,
      spammyLength: length,
      spammyWords: spammyWordsInContent,
    };
  }

  return {
    spammyPercentage: 0,
    spammyStatus: '',
    spammyLength: 0,
    spammyWords: [],
  };
};

export const checkContentEmailSuggestions = ({
  content,
  doNotCheckLink = false,
  mergeTagOptions = [],
  stepNumber,
}: {
  content: string;
  doNotCheckLink?: boolean;
  mergeTagOptions: any;
  stepNumber: number;
}): {
  wordCount: WordEmailSuggestion;
  personalization: PersonalizationEmailSuggestion;
  link: LinkEmailSuggestion;
  spammy: SpammyEmailSuggestion;
} => {
  // Word Count Suggestion
  const { wordPercentage, wordStatus, wordLength } = checkWordCountSuggestions(
    content,
    stepNumber,
  );

  // Personalization Email Suggestion
  const {
    personalizationPercentage,
    personalizationStatus,
    personalizationLength,
  } = checkPersonalizationEmailSuggestions(content, mergeTagOptions);

  // Links Email Suggestion
  let linkObj = {
    linkPercentage: 0,
    linkStatus: '',
    linkLength: 0,
  };

  if (!doNotCheckLink) {
    const {
      linkPercentage,
      linkStatus,
      linkLength,
    } = checkLinksEmailSuggestions(content);
    linkObj = {
      linkPercentage,
      linkStatus,
      linkLength,
    };
  }

  const {
    spammyPercentage,
    spammyStatus,
    spammyLength,
    spammyWords,
  } = checkSpammyEmailSuggestions(content);

  return {
    wordCount: {
      wordPercentage,
      wordStatus,
      wordLength,
    },
    personalization: {
      personalizationPercentage,
      personalizationStatus,
      personalizationLength,
    },
    link: linkObj,
    spammy: {
      spammyPercentage,
      spammyStatus,
      spammyLength,
      spammyWords,
    },
  };
};

export const getEditorClassNames = ({
  showTitle,
  showPreheaderInput,
  values,
  errors,
}): string =>
  classNames([
    'email-modal--editor',
    {
      'with-two-inputs': showTitle || showPreheaderInput,
      'with-one-inputs-one-error':
        !showTitle &&
        !showPreheaderInput &&
        (errors.title ||
          errors.preheader ||
          values.preheader.length > 0 ||
          errors.subject),
      'with-two-inputs-one-error':
        (errors.title ||
          errors.preheader ||
          values.preheader.length > 0 ||
          errors.subject) &&
        (showTitle || showPreheaderInput),
      'with-two-errors':
        (errors.title || errors.preheader || values.preheader.length > 0) &&
        errors.subject,
    },
  ]);

export const getStringSize = (content: string) =>
  Buffer.byteLength(content, 'utf-8');

export const checkTotalEmailContentSizeValidator = ({
  content,
  attachmentsSizeInBytes,
}): boolean => {
  const contentSizeInBytes = getStringSize(content);

  const totalEmailSize = contentSizeInBytes + attachmentsSizeInBytes;

  return totalEmailSize > EmailContentSizeAllowed;
};

export const getAttachmentsSize = (attachments) => {
  let attachmentsSizeInBytes = 0;

  if (attachments && attachments.length) {
    attachments.forEach((item) => {
      attachmentsSizeInBytes += parseInt(item.file.size, 10);
    });
  }

  return attachmentsSizeInBytes;
};

export const attachmentStatusChangeHandler = (
  attachment: AttachmentType,
  attachments: AttachmentType[],
) => {
  let oldAttachment = null;

  if (attachment.attachmentIdentifier) {
    oldAttachment = attachments.find(
      (item) => item.attachmentIdentifier === attachment.attachmentIdentifier,
    );
  } else if (attachment.attachmentId) {
    oldAttachment = attachments.find(
      (item) => item.attachmentId === attachment.attachmentId,
    );
  }

  if (oldAttachment) {
    if (attachment.attachmentIdentifier) {
      return attachments.map((item) =>
        item.attachmentIdentifier === attachment.attachmentIdentifier
          ? {
              ...oldAttachment,
              ...attachment,
            }
          : item,
      );
    }

    if (attachment.attachmentId) {
      return attachments.map((item) =>
        item.attachmentId === attachment.attachmentId
          ? {
              ...oldAttachment,
              ...attachment,
            }
          : item,
      );
    }
  }
  return attachments;
};

export const getIsHTMLContentDetected = (content: string): boolean => {
  const imageRegex = /<img\b[^>]*alt=['"]([^'"]*)['"][^>]*>/g;
  const restrictedTagRegex = /<(img|span|iframe)[^>]*>/g;
  const styleTagRegex = /style="[^"]*"/g;
  const headerTagsRegex = /<h[1-6]>/g;
  const underlineBoldItalicTagsRegex = /<(u|b|strong|i|em)>/g;
  const closeTagsRegex = /<\/(h[1-6]|u|b|strong|i|em)>/g;
  const strikethroughTagRegex = /<s>/g;
  const linkTagRegex = /<a\s+href="([^"]+)"[^>]*>(.*?)<\/a>/g;

  const regexArray = [
    imageRegex,
    restrictedTagRegex,
    styleTagRegex,
    headerTagsRegex,
    underlineBoldItalicTagsRegex,
    closeTagsRegex,
    strikethroughTagRegex,
    linkTagRegex,
  ];

  return regexArray.some((regex) => regex.test(content));
};

// eslint-disable-next-line no-useless-escape
export const regexForSenderSignatureExistenceCheck = /\{\{\s*SenderSignature\s*([\+\-\*\/]\s*\w+)?\s*\}\}/i;

// eslint-disable-next-line no-useless-escape
export const regexForSenderSignatureCountCheck = /\{\{\s*SenderSignature\s*([\+\-\*\/]\s*\w+)?\s*\}\}/gi;
