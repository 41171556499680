import React from 'react';

export const SHLogoTypography = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="22"
    viewBox="0 0 120 22"
    fill="none"
  >
    <path
      d="M5.80078 10.1962C4.03528 9.94504 3.42999 9.64372 3.42999 8.84002C3.42999 8.0866 4.08574 7.58435 5.19548 7.58435C6.35561 7.58435 6.9609 8.03639 7.11228 9.041H10.2397C9.93698 6.2282 7.86888 5.22363 5.14502 5.22363C2.62293 5.22363 0.252224 6.47933 0.252224 9.041C0.252224 11.4519 1.51327 12.356 4.79195 12.8583C6.55737 13.1094 7.26358 13.461 7.26358 14.3149C7.26358 15.1688 6.65829 15.6711 5.34679 15.6711C3.88398 15.6711 3.32915 15.0181 3.17784 13.9131H0C0.100842 16.5752 2.0681 18.0318 5.34679 18.0318C8.57509 18.0318 10.5423 16.6254 10.5423 14.0135C10.5927 11.4017 8.8777 10.6483 5.80078 10.1962Z"
      fill="#1F2937"
    />
    <path
      d="M17.9577 5.27393C14.9312 5.27393 12.6108 6.17804 12.4091 9.0913H15.6374C15.7887 8.03648 16.394 7.7351 17.7559 7.7351C19.3196 7.7351 19.7736 8.53878 19.7736 9.99534V10.4976H18.3612C14.5781 10.4976 11.9551 11.5525 11.9551 14.4154C11.9551 16.9771 13.8215 18.0821 16.0914 18.0821C18.0082 18.0821 19.1178 17.3287 19.824 16.3241V17.7807H23.1532V9.84471C23.1027 6.4794 20.9842 5.27393 17.9577 5.27393ZM19.7231 13.5113C19.7231 14.8675 18.563 15.6712 17.0497 15.6712C15.7887 15.6712 15.2843 15.0684 15.2843 14.2145C15.2843 12.9086 16.4444 12.4566 18.4117 12.4566H19.7231V13.5113Z"
      fill="#1F2937"
    />
    <path d="M27.8437 0H24.4641V17.831H27.8437V0Z" fill="#1F2937" />
    <path
      d="M35.561 5.27393C31.9796 5.27393 29.2053 7.78533 29.2053 11.6529V11.8538C29.2053 15.7716 31.9292 18.0821 35.6114 18.0821C38.991 18.0821 41.1096 16.6255 41.5131 13.9634H38.2849C38.0831 15.0182 37.276 15.6712 35.7123 15.6712C33.846 15.6712 32.7362 14.5159 32.6354 12.4566H41.5131V11.5023C41.5635 7.13237 38.7388 5.27393 35.561 5.27393ZM32.7362 10.3469C32.9885 8.58899 34.0477 7.58442 35.561 7.58442C37.1751 7.58442 38.1335 8.4885 38.2344 10.3469H32.7362Z"
      fill="#1F2937"
    />
    <path
      d="M48.6768 10.1962C46.9113 9.94504 46.306 9.64372 46.306 8.84002C46.306 8.0866 46.9617 7.58435 48.0715 7.58435C49.2316 7.58435 49.8369 8.03639 49.9883 9.041H53.1156C52.8129 6.2282 50.7448 5.22363 48.021 5.22363C45.4989 5.22363 43.1282 6.47933 43.1282 9.041C43.1282 11.4519 44.3892 12.356 47.6679 12.8583C49.4333 13.1094 50.1396 13.461 50.1396 14.3149C50.1396 15.1688 49.5343 15.6711 48.2228 15.6711C46.76 15.6711 46.2051 15.0181 46.0538 13.9131H42.876C42.9768 16.5752 44.9441 18.0318 48.2228 18.0318C51.4511 18.0318 53.4183 16.6254 53.4183 14.0135C53.4687 11.4017 51.8041 10.6483 48.6768 10.1962Z"
      fill="#1F2937"
    />
    <path
      d="M62.2442 5.27395C60.176 5.27395 58.8646 6.27852 58.2588 7.48399V0H54.8293V17.831H58.2588V10.7488C58.2588 8.94061 59.3684 8.03649 60.8317 8.03649C62.3447 8.03649 63.0004 8.84011 63.0004 10.4977V17.831H66.4306V9.99543C66.38 6.73057 64.6653 5.27395 62.2442 5.27395Z"
      fill="#1F2937"
    />
    <path
      d="M73.796 5.27393C70.7699 5.27393 68.4494 6.17804 68.2477 9.0913H71.4762C71.6273 8.03648 72.2324 7.7351 73.5943 7.7351C75.158 7.7351 75.612 8.53878 75.612 9.99534V10.4976H74.2002C70.4164 10.4976 67.7937 11.5525 67.7937 14.4154C67.7937 16.9771 69.6603 18.0821 71.9302 18.0821C73.8466 18.0821 74.9563 17.3287 75.6626 16.3241V17.7807H78.9916V9.84471C78.9417 6.4794 76.7722 5.27393 73.796 5.27393ZM75.5621 13.5113C75.5621 14.8675 74.4018 15.6712 72.888 15.6712C71.6273 15.6712 71.1227 15.0684 71.1227 14.2145C71.1227 12.9086 72.283 12.4566 74.25 12.4566H75.5621V13.5113Z"
      fill="#1F2937"
    />
    <path
      d="M87.7184 5.27393C85.6502 5.27393 84.3388 6.27849 83.733 7.48397V5.52507H80.3027V17.7807H83.733V10.7488C83.733 8.9406 84.8426 8.03648 86.3058 8.03648C87.8189 8.03648 88.4745 8.8401 88.4745 10.4976V17.8309H91.9047V9.99534C91.8541 6.73054 90.1394 5.27393 87.7184 5.27393Z"
      fill="#1F2937"
    />
    <path
      d="M102.549 7.38353C101.842 6.12783 100.631 5.27395 98.6643 5.27395C95.5877 5.27395 93.2671 7.63468 93.2671 11.653V11.8539C93.2671 15.9223 95.6375 18.0821 98.6137 18.0821C100.43 18.0821 101.943 17.0273 102.549 15.7717V17.831H105.928V0H102.549V7.38353ZM102.649 11.7534C102.649 14.2145 101.388 15.4201 99.6229 15.4201C97.9074 15.4201 96.7471 14.2145 96.7471 11.8036V11.6027C96.7471 9.14151 97.807 7.88579 99.7234 7.88579C101.59 7.88579 102.649 9.14151 102.649 11.5525V11.7534Z"
      fill="#1F2937"
    />
    <path
      d="M116.722 5.5752L113.947 12.9085L110.971 5.5752H107.289L112.182 16.5752L110.012 21.9998H113.342L120 5.5752H116.722Z"
      fill="#1F2937"
    />
  </svg>
);
