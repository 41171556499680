// utils.ts

import { Location } from 'history';

/**
 * Returns the current route (pathname) from a Location object.
 *
 * @param location - The location object from react-router-dom.
 * @returns The current pathname.
 */
export const getCurrentRoute = (location: Location): string =>
  location.pathname;
