import validator from 'validator';

export const validate = (name: string, value: string) => {
  switch (name) {
    case 'firstName':
      if (!validator.isLength(value.trim(), { min: 1 })) {
        return 'Enter at least one character';
      }
      if (!validator.isLength(value, { max: 50 })) {
        return 'First name should be at most 50 characters long';
      }
      break;

    case 'lastName':
      if (!validator.isLength(value.trim(), { min: 1 })) {
        return 'Enter at least one character';
      }
      if (!validator.isLength(value, { max: 50 })) {
        return 'Last name should be at most 50 characters long';
      }
      break;

    case 'email':
      if (!validator.isEmail(value)) {
        return 'Enter a valid email address';
      }
      break;

    default:
      break;
  }

  return '';
};
