import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  SignupRequestPayload,
  VerifyUserVerificationCodeRequestPayload,
  ResendUserVerificationCodeRequestPayload,
  OnBoardingRequest,
} from '../../types';
import {
  signup,
  verifyUserVerificationCode,
  resendUserVerificationCode,
  onBoardingDetails,
  resendVerificationEmail,
} from '../../helpers/auth.api';
import {
  ResponseErrorWithHandled,
  ResponseSuccess,
} from '../../../../shared/types';

export const signupRequest = createAsyncThunk<
  ResponseSuccess,
  SignupRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('signup/signupRequest', async (args, thunkAPI) => {
  try {
    return await signup(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const verifyUserVerificationCodeRequest = createAsyncThunk<
  ResponseSuccess,
  VerifyUserVerificationCodeRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('signup/verifyUserVerificationCodeRequest', async (args, thunkAPI) => {
  try {
    return await verifyUserVerificationCode(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const resendUserVerificationCodeRequest = createAsyncThunk<
  ResponseSuccess,
  ResendUserVerificationCodeRequestPayload,
  { rejectValue: ResponseErrorWithHandled }
>('signup/resendUserVerificationCodeRequest', async (args, thunkAPI) => {
  try {
    return await resendUserVerificationCode(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const onboardingDetailsRequest = createAsyncThunk<
  ResponseSuccess,
  OnBoardingRequest,
  { rejectValue: ResponseErrorWithHandled }
>('signup/onboardingDetailsRequest', async (args, thunkAPI) => {
  try {
    return await onBoardingDetails(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

export const resendVerificationEmailRequest = createAsyncThunk<
  ResponseSuccess,
  string,
  { rejectValue: ResponseErrorWithHandled }
>('signup/resendVerificationEmailRequest', async (args, thunkAPI) => {
  try {
    return await resendVerificationEmail(args);
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});
