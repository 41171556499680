import React from 'react';

export const NoProspectResultFound = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="33.4762" cy="40.7623" r="30.4762" fill="#F5F5F7" />
    <path
      d="M32.758 41.3046C35.2962 41.3046 37.4942 40.4027 39.29 38.6231C41.0859 36.844 41.9963 34.667 41.9963 32.1521C41.9963 29.6379 41.0859 27.4607 39.2897 25.6808C37.4936 23.9019 35.2959 23 32.758 23C30.2195 23 28.0221 23.9019 26.2263 25.6811C24.4304 27.4604 23.5197 29.6376 23.5197 32.1521C23.5197 34.667 24.4304 36.8443 26.2266 38.6235C28.0227 40.4025 30.2204 41.3046 32.758 41.3046ZM48.9225 52.2199C48.8706 51.4795 48.7659 50.6717 48.6117 49.8189C48.456 48.9595 48.2556 48.1472 48.0156 47.4047C47.7678 46.6373 47.4307 45.8795 47.014 45.1532C46.5814 44.3995 46.0735 43.7431 45.5034 43.203C44.9073 42.638 44.1775 42.1837 43.3335 41.8522C42.4926 41.5227 41.5605 41.3557 40.5636 41.3557C40.172 41.3557 39.7934 41.5148 39.0621 41.9866C38.5423 42.3219 38.0209 42.6549 37.498 42.9855C36.9955 43.3028 36.3149 43.5999 35.4741 43.869C34.6539 44.1319 33.8211 44.2653 32.9991 44.2653C32.1772 44.2653 31.3446 44.1319 30.5235 43.869C29.6837 43.6001 29.003 43.303 28.5011 42.9858C27.9191 42.6173 27.3924 42.2811 26.9356 41.9862C26.2052 41.5145 25.8262 41.3553 25.4347 41.3553C24.4374 41.3553 23.5057 41.5226 22.665 41.8526C21.8216 42.1834 21.0915 42.6377 20.4948 43.2033C19.9251 43.7437 19.4168 44.3997 18.9849 45.1532C18.5685 45.8795 18.2314 46.637 17.9832 47.405C17.7435 48.1475 17.5431 48.9595 17.3875 49.8189C17.2332 50.6706 17.1285 51.4786 17.0766 52.2208C17.0251 52.9677 16.9995 53.7161 17 54.4647C17 56.4483 17.6365 58.0541 18.8916 59.2384C20.1311 60.4071 21.7713 61 23.7658 61H42.2342C44.2287 61 45.8683 60.4074 47.1082 59.2385C48.3635 58.055 49 56.4489 49 54.4644C48.9997 53.6988 48.9737 52.9435 48.9225 52.2199Z"
      fill="url(#paint0_linear_35780_16048)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0433 30.4002C21.4909 30.293 21.7668 29.8431 21.6595 29.3955C21.5523 28.9479 21.1024 28.6719 20.6548 28.7792L18.4934 29.2972C18.0458 29.4044 17.7698 29.8543 17.8771 30.3019C17.9844 30.7495 18.4342 31.0255 18.8819 30.9182L21.0433 30.4002ZM29.0572 20.259C29.0563 20.7193 28.6824 21.0917 28.2221 21.0908C27.7618 21.0899 27.3893 20.716 27.3902 20.2557L27.3946 18.0331C27.3955 17.5728 27.7693 17.2004 28.2297 17.2013C28.69 17.2022 29.0624 17.5761 29.0615 18.0364L29.0572 20.259ZM22.0965 25.4039C21.9919 25.8522 21.5438 26.1309 21.0955 26.0263L18.931 25.5216C18.4827 25.417 18.204 24.9689 18.3086 24.5206C18.4131 24.0723 18.8613 23.7937 19.3096 23.8982L21.4741 24.403C21.9224 24.5075 22.201 24.9557 22.0965 25.4039ZM24.813 21.3219C25.0183 21.7339 24.8508 22.2343 24.4388 22.4397C24.0268 22.645 23.5264 22.4775 23.3211 22.0655L22.3296 20.0763C22.1243 19.6643 22.2918 19.1639 22.7038 18.9585C23.1158 18.7532 23.6162 18.9207 23.8216 19.3327L24.813 21.3219Z"
      fill="#CCD0DB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.8574 43.4286C74.8574 42.6396 74.2178 42 73.4289 42H61.0479C60.2589 42 59.6193 42.6396 59.6193 43.4286C59.6193 44.2175 60.2589 44.8571 61.0479 44.8571H73.4289C74.2178 44.8571 74.8574 44.2175 74.8574 43.4286ZM74.8574 50.0956C74.8574 49.3066 74.2178 48.667 73.4289 48.667H53.4289C52.6399 48.667 52.0003 49.3066 52.0003 50.0956C52.0003 50.8845 52.6399 51.5241 53.4289 51.5241H73.4288C74.2178 51.5241 74.8574 50.8845 74.8574 50.0956ZM56.286 42C57.075 42 57.7146 42.6396 57.7146 43.4286C57.7146 44.2175 57.075 44.8571 56.286 44.8571H53.4289C52.6399 44.8571 52.0003 44.2175 52.0003 43.4286C52.0003 42.6396 52.6399 42 53.4289 42H56.286Z"
      fill="#DBE0E7"
    />
    <path
      d="M64.502 9.67578C62.2916 9.67478 60.1204 10.2659 58.2089 11.3891C56.2975 12.5123 54.7139 14.1275 53.6192 16.0705C52.5244 18.0136 51.9575 20.2154 51.976 22.452C51.9946 24.6886 52.5979 26.8804 53.7247 28.8046L52.045 33.4808C51.9824 33.6548 51.9622 33.8415 51.986 34.0251C52.0099 34.2087 52.0771 34.3838 52.182 34.5356C52.2869 34.6873 52.4264 34.8112 52.5887 34.8968C52.751 34.9824 52.9313 35.0271 53.1143 35.0273C53.247 35.0269 53.3788 35.0035 53.5037 34.9581L58.1249 33.2573C59.7833 34.2515 61.6462 34.8449 63.5684 34.9914C65.4906 35.138 67.4203 34.8336 69.2072 34.1021C70.9942 33.3705 72.5902 32.2315 73.871 30.7737C75.1518 29.3159 76.0828 27.5787 76.5916 25.6972C77.1004 23.8158 77.1732 21.8409 76.8044 19.9263C76.4356 18.0117 75.6351 16.2091 74.4653 14.6588C73.2954 13.1085 71.7877 11.8523 70.0596 10.988C68.3315 10.1237 66.4296 9.67463 64.502 9.67578Z"
      fill="#DBE0E7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64.4195 15.5262C66.5183 15.569 68.2475 17.2626 68.3562 19.3819C68.414 20.51 68.0232 21.5816 67.2556 22.3994C66.7829 22.9031 66.1904 23.2726 65.5445 23.479V24.8624C65.5445 25.537 65.0039 26.0838 64.3371 26.0838C63.6703 26.0838 63.1297 25.537 63.1297 24.8624V22.4467C63.1297 21.7722 63.6703 21.2253 64.3371 21.2253C64.7825 21.2253 65.1971 21.045 65.5045 20.7175C65.8116 20.3903 65.9679 19.9609 65.9447 19.5084C65.9238 19.1021 65.7497 18.7191 65.4543 18.43C65.1587 18.1406 64.7739 17.9768 64.3709 17.9685C63.5538 17.9522 62.8552 18.5536 62.7429 19.3681C62.7325 19.4431 62.7273 19.52 62.7273 19.5968C62.7273 20.2713 62.1867 20.8182 61.5199 20.8182C60.8531 20.8182 60.3125 20.2713 60.3125 19.5968C60.3125 19.4073 60.3255 19.2181 60.3513 19.0304C60.6288 17.0187 62.3348 15.5254 64.3373 15.5254C64.3647 15.5254 64.392 15.5257 64.4195 15.5262ZM65.5444 28.2005C65.5444 28.8751 65.0039 29.4219 64.337 29.4219C63.6702 29.4219 63.1297 28.8751 63.1297 28.2005C63.1297 27.5259 63.6702 26.9791 64.337 26.9791C65.0039 26.9791 65.5444 27.5259 65.5444 28.2005Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_35780_16048"
        x1="25.186"
        y1="61"
        x2="46.1399"
        y2="29.2079"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
    </defs>
  </svg>
);
