import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { RootState } from '../../../../../../store/root-reducer';

import {
  getWebhookRequest,
  updateWebhookStatusRequest,
} from '../../extra-actions';
import {
  resetGetWebhookRequest,
  resetUpdateWebhookStatusRequest,
  resetWebhookData,
  updateWebhookData,
} from '../../webhook-slice';

import WebhookDetailAndLogs from './webhook-details-and-logs';
import { UpdateWebhookStatusPayload, Webhook } from '../../../../types/webhook';

const mapStateToProps = (state: RootState) => ({
  webhook: state.webhook.webhook,
  webhookTestResult: state.webhook.webhookTestResult,

  updateWebhookRequestStatus: state.webhook.updateWebhookRequest.status,

  getWebhookRequestStatus: state.webhook.getWebhookRequest.status,
  getWebhookRequestMessage: state.webhook.getWebhookRequest.message,
  getWebhookRequestError: state.webhook.getWebhookRequest.error,

  updateWebhookStatusRequestStatus:
    state.webhook.updateWebhookStatusRequest.status,
  updateWebhookStatusRequestMessage:
    state.webhook.updateWebhookStatusRequest.message,
  updateWebhookStatusRequestError:
    state.webhook.updateWebhookStatusRequest.error,
});

const mapDispatchToProps = {
  updateWebhookData: (payload: Partial<Webhook>) => updateWebhookData(payload),
  resetWebhookData: () => resetWebhookData(),

  sendGetWebhookRequest: (webhookId: string) => getWebhookRequest(webhookId),
  resetGetWebhookRequest: () => resetGetWebhookRequest(),

  sendUpdateWebhookStatusRequest: (payload: UpdateWebhookStatusPayload) =>
    updateWebhookStatusRequest(payload),
  resetUpdateWebhookStatusRequest: () => resetUpdateWebhookStatusRequest(),

  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(WebhookDetailAndLogs);
