import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';

import AddWebhook from './add-webhook';

import {
  createWebhookRequest,
  testWebhookRequest,
  updateWebhookRequest,
} from '../../extra-actions';
import {
  resetCreateWebhookRequest,
  resetTestWebhookRequest,
  resetUpdateWebhookRequest,
  resetWebhookTestResult,
} from '../../webhook-slice';
import {
  Webhook,
  CreateAndTestWebhookPayload,
  UpdateWebhookPayload,
} from '../../../../types/webhook';

const mapStateToProps = (state: RootState) => ({
  createWebhookRequestStatus: state.webhook.createWebhookRequest.status,
  createWebhookRequestMessage: state.webhook.createWebhookRequest.message,
  createWebhookRequestError: state.webhook.createWebhookRequest.error,

  updateWebhookRequestStatus: state.webhook.updateWebhookRequest.status,
  updateWebhookRequestMessage: state.webhook.updateWebhookRequest.message,
  updateWebhookRequestError: state.webhook.updateWebhookRequest.error,

  testWebhookRequestStatus: state.webhook.testWebhookRequest.status,
  testWebhookRequestMessage: state.webhook.testWebhookRequest.message,
  testWebhookRequestError: state.webhook.testWebhookRequest.error,

  webhookTestResult: state.webhook.webhookTestResult,
});

const mapDispatchToProps = {
  sendCreateWebhookRequest: (payload: CreateAndTestWebhookPayload) =>
    createWebhookRequest(payload),
  resetCreateWebhookRequest: () => resetCreateWebhookRequest(),

  sendUpdateWebhookRequest: (payload: UpdateWebhookPayload) =>
    updateWebhookRequest(payload),
  resetUpdateWebhookRequest: () => resetUpdateWebhookRequest(),

  sendTestWebhookRequest: (payload: CreateAndTestWebhookPayload) =>
    testWebhookRequest(payload),
  resetTestWebhookRequest: () => resetTestWebhookRequest(),
  resetWebhookTestResult: () => resetWebhookTestResult(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  hideHeader?: boolean;
  defaultWebhook?: Webhook;
  action?: 'create' | 'update';
};

export default connector(AddWebhook);
