enum FeatureCodes {
  EmailSending = 'EMAIL.SEND',
  ProspectAdd = 'PROSPECT.ADD',
  LeadReveal = 'LEAD.REVEAL',
  LeadRevealPhone = 'LEAD.REVEAL.PHONE',
  EmailAccountSendingMinInterval = 'EMAIL_ACCOUNT_SETTING.MIN_INTERVAL',
  EmailAccountSendingMinIntervalDifference = 'EMAIL_ACCOUNT_SETTING.MIN_INTERVAL_DIFFERENCE',
  EmailAccountSendingMaxDailySending = 'EMAIL_ACCOUNT_SETTING.MAX_DAILY_EMAIL_SENDING_LIMIT',
}

export default FeatureCodes;
