import React from 'react';

export const GaugeChart = () => (
  <svg
    width="176"
    height="124"
    viewBox="0 0 176 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="153"
      y="53.8159"
      width="2"
      height="4"
      rx="1"
      transform="rotate(-122 153 53.8159)"
      fill="#E5E7EB"
    />
    <rect x="87" y="12" width="2" height="4" rx="1" fill="#E5E7EB" />
    <rect x="72" y="65" width="32" height="30" rx="4" fill="#F3F4F6" />
    <path
      d="M25.6296 122.779C23.1691 124.033 20.1416 123.06 19.0546 120.522C14.6772 110.299 12.6196 99.2094 13.0578 88.0555C13.5616 75.2329 17.347 62.7541 24.052 51.8126C30.7569 40.8711 40.1571 31.833 51.3534 25.5628C62.5497 19.2926 75.1675 16 88 16C100.832 16 113.45 19.2926 124.647 25.5628C135.843 31.833 145.243 40.8711 151.948 51.8126C158.653 62.7541 162.438 75.2329 162.942 88.0555C163.38 99.2094 161.323 110.299 156.945 120.522C155.858 123.06 152.831 124.033 150.37 122.779L147.92 121.531C145.46 120.277 144.502 117.273 145.554 114.719C148.961 106.453 150.555 97.5295 150.202 88.5561C149.784 77.9133 146.642 67.5559 141.077 58.4745C135.512 49.393 127.71 41.8914 118.417 36.6871C109.124 31.4828 98.651 28.75 88 28.75C77.349 28.75 66.8762 31.4828 57.5833 36.6871C48.2904 41.8914 40.4883 49.393 34.9232 58.4745C29.3581 67.5559 26.2162 77.9133 25.798 88.5561C25.4454 97.5295 27.0391 106.453 30.4461 114.72C31.4983 117.273 30.5403 120.277 28.0798 121.531L25.6296 122.779Z"
      fill="#F1F2F5"
    />
    <rect
      x="23"
      y="53.1196"
      width="2"
      height="4"
      rx="1"
      transform="rotate(122 23 53.1196)"
      fill="#E5E7EB"
    />
    <rect x="5" y="42" width="13" height="10" rx="4" fill="#F3F4F6" />
    <rect x="82" width="13" height="10" rx="4" fill="#F3F4F6" />
    <rect x="157" y="42" width="13" height="10" rx="4" fill="#F3F4F6" />
    <rect x="163" y="113" width="13" height="10" rx="4" fill="#F3F4F6" />
    <rect y="113" width="13" height="10" rx="4" fill="#F3F4F6" />
  </svg>
);
