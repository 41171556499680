import isNaN from 'lodash/isNaN';
import { formateDate } from '../../../../../shared/utils';
import store from '../../../../../store';

export const getPercentageValue = (remainingValue: number, total: number) => {
  const percentage = (remainingValue / total) * 100;

  if (isNaN(percentage)) {
    return 0;
  }

  return percentage;
};

export const getProspectAndEmailSentLimit = () => {
  const state = store.getState();

  const totalProspectLimit = parseInt(
    state.home.getPostLoadMetaResponse?.featureLimits?.['PROSPECT.ADD'],
    10,
  );
  const remainingProspectLimit = parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'PROSPECT.ADD'
    ],
    10,
  );
  const totalEmailSentLimit = parseInt(
    state.home.getPostLoadMetaResponse?.featureLimits?.['EMAIL.SEND'],
    10,
  );
  const remainingEmailSentLimit = parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'EMAIL.SEND'
    ],
    10,
  );
  const totalLeadRevealLimit = parseInt(
    state.home.getPostLoadMetaResponse?.featureLimits?.['LEAD.REVEAL'],
    10,
  );
  const remainingLeadRevealLimit = parseInt(
    state.home.getPostLoadMetaResponse?.accountUsageQuotaRemaining?.[
      'LEAD.REVEAL'
    ],
    10,
  );
  const remainingLeadRevealLimitPer = getPercentageValue(
    remainingLeadRevealLimit,
    totalLeadRevealLimit,
  );

  const quotaResetDate =
    state.home.getPostLoadMetaResponse?.accountUsageQuotaResetDate;

  const leadFinderQuotaResetDate =
    state.home.getPostLoadMetaResponse?.leadFinderAccountUsageQuotaResetDate;

  const remainingProspectLimitPer = getPercentageValue(
    remainingProspectLimit,
    totalProspectLimit,
  );
  const remainingEmailSentLimitPer = getPercentageValue(
    remainingEmailSentLimit,
    totalEmailSentLimit,
  );

  return {
    totalProspectLimit,
    remainingProspectLimit,
    totalEmailSentLimit,
    remainingEmailSentLimit,
    remainingProspectLimitPer,
    remainingEmailSentLimitPer,
    quotaResetDate,
    totalLeadRevealLimit,
    remainingLeadRevealLimit,
    remainingLeadRevealLimitPer,
    leadFinderQuotaResetDate,
  };
};

export const getRestrictionWarning = () => {
  const {
    totalProspectLimit,
    remainingProspectLimit,
    totalEmailSentLimit,
    remainingEmailSentLimit,
    remainingProspectLimitPer,
    remainingEmailSentLimitPer,
  } = getProspectAndEmailSentLimit();

  if (
    [
      totalProspectLimit,
      remainingProspectLimit,
      remainingEmailSentLimit,
      totalEmailSentLimit,
    ].includes(NaN)
  ) {
    return '';
  }

  if (remainingEmailSentLimitPer === 0 && remainingProspectLimitPer === 0) {
    return 'Monthly email limit and prospect limit reached.';
  }
  if (remainingEmailSentLimitPer === 0) {
    return 'Monthly email limit reached.';
  }
  if (remainingProspectLimitPer === 0) {
    return 'Prospect limit reached.';
  }
  if (remainingEmailSentLimitPer <= 5 && remainingProspectLimitPer <= 5) {
    return 'Monthly email & Prospect limit almost reached.';
  }
  if (remainingEmailSentLimitPer <= 5) {
    return 'Monthly email limit almost reached.';
  }
  if (remainingProspectLimitPer <= 5) {
    return 'Prospect limit almost reached.';
  }

  return '';
};

export const getRestrictionWarningTooltip = (): string => {
  const {
    remainingEmailSentLimitPer,
    remainingProspectLimitPer,
    quotaResetDate,
  } = getProspectAndEmailSentLimit();

  if (remainingEmailSentLimitPer === 0 && remainingProspectLimitPer === 0) {
    return 'Attention: You have reached the limit of total prospects & monthly emails. To continue using your account without interruption, please upgrade your account.';
  }
  if (remainingEmailSentLimitPer === 0) {
    return `Your monthly sending limit is reached & further emails shall not be sent. Please upgrade your account. Automatic resume on: ${formateDate(
      quotaResetDate,
    )}`;
  }
  if (remainingProspectLimitPer === 0) {
    return 'Your account has reached the maximum number of prospects allowed. To continue importing prospects, please upgrade your account. ';
  }
  if (remainingEmailSentLimitPer <= 5 && remainingProspectLimitPer <= 5) {
    return 'Attention: Your monthly email sending and prospects limit is reached. To ensure seamless account usage, please upgrade your account.';
  }
  if (remainingEmailSentLimitPer <= 5) {
    return 'Your monthly email-sending limit is almost reached. To ensure an uninterrupted email-sending experience, please upgrade your account.';
  }
  if (remainingProspectLimitPer <= 5) {
    return 'Your account prospect limit is almost reached. To ensure a seamless prospect import experience, please upgrade your account.';
  }

  return '';
};

export const getRestrictionWarningClass = () =>
  getRestrictionWarning().includes('almost')
    ? 'bs-banner-warning'
    : 'bs-banner-danger';

export const getEmailBody = () => {
  const state = store.getState();
  const senderFirstName = state.home?.firstName;
  const {
    totalEmailSentLimit,
    totalProspectLimit,
    remainingEmailSentLimitPer,
    quotaResetDate,
  } = getProspectAndEmailSentLimit();

  if (remainingEmailSentLimitPer <= 5) {
    return (
      `It looks like the Saleshandy account has reached the maximum email sending limit - ${totalEmailSentLimit}.` +
      `It requires the upgrade to start sending emails from this account. Please do the needful.` +
      `%0D%0APlease note that the automatic resume of email sending will be on ${formateDate(
        quotaResetDate,
      )} %0D%0A %0D%0A Thanks,%0D%0A %0D%0A  ${senderFirstName}`
    );
  }

  return `It looks like the Saleshandy account has reached the maximum prospect limit - ${totalProspectLimit}. It requires the upgrade to add more prospects into account. Please do the needful.%0D%0A %0D%0A Thanks,%0D%0A %0D%0A  ${senderFirstName}`;
};

export const getTrailPlanWarningString = ({ t, subscriptionEndDays }) => {
  let trailPlanWarningText = '';

  if (subscriptionEndDays === 0) {
    trailPlanWarningText = t('messages.your_plan_expired_text_0_day');
  } else {
    trailPlanWarningText = `${t(
      'messages.your_plan_expired_text',
    )} ${subscriptionEndDays}`;

    if (subscriptionEndDays === 1) {
      trailPlanWarningText += ` ${t('labels.day')}.`;
    } else {
      trailPlanWarningText += ` ${t('labels.days')}.`;
    }
  }

  return trailPlanWarningText;
};
