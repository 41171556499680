import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@saleshandy/design-system';
import { CloudUpload } from '@saleshandy/icons';

import Modal from '../../../../../../../shared/design-system/components/atoms/modal';

import history from '../../../../../../../shared/history';
import { Routes } from '../../../../../../../shared/routes';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';

import { contactAdmin } from '../../../../../../../shared/utils';
import { getAdminFirstName } from '../../../../../../../shared/utils/user-details';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';

const RestrictionModal = ({ show, onClose }) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    const emailSubject = `${t(
      'messages.no_seat_available_email_subject_1',
    )} ${t('messages.no_seat_available_email_subject_2')}`;

    const emailBody = `Hello ${getAdminFirstName()}, %0D%0A %0D%0A ${t(
      'messages.no_seat_available_email_body_1',
    )} ${t('messages.no_seat_available_email_body_2')} ${t(
      'messages.no_seat_available_email_body_3',
    )} ${t('messages.no_seat_available_email_body_4')}`;

    if (hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)) {
      history.push(Routes.Setting_Billing_Upgrade_Email_Outreach_Plan);
    } else {
      contactAdmin(emailSubject, emailBody);
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      hideHeader
      hideFooter
      backdrop="static"
      className="plan-restriction-modal"
    >
      <div className="plan-restriction-modal--title">
        <div className="title">
          <div className="title-icon">
            <CloudUpload />
          </div>
          <h1>Upgrade your plan to use Webhook</h1>
        </div>
      </div>

      <div className="plan-restriction-modal--description">
        <p>Your current plan doesn’t allow you to use Webhook.</p>
      </div>

      <div className="plan-restriction-modal--description">
        <p>
          Please upgrade to the Outreach Pro or higher plans to use unlimited
          Webhook for seamless integrations.
        </p>
      </div>

      <div className="plan-restriction-modal--divider" />

      <div className="plan-restriction-modal--footer">
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={onSubmit}>
          {hasPermission(Permissions.ACCOUNT_SUBSCRIPTION_READ)
            ? 'Upgrade'
            : t('labels.contact_admin')}
        </Button>
      </div>
    </Modal>
  );
};

export default RestrictionModal;
