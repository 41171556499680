enum MasterLog {
  EmailSent = 'email-sent',
  EmailFailed = 'email-failed',
  EmailOpened = 'email-opened',
  EmailBounced = 'email-bounced',
  LinkClicked = 'link-clicked',
  UnsubscribedAuto = 'unsubscribed-auto',
  UnsubscribedManual = 'unsubscribed-manual',
  ReSubscribed = 'resubscribed',
  ReplyReceivedAuto = 'reply-received-auto',
  ReplyReceivedManual = 'reply-received-manual',
  Blacklisted = 'blacklisted',
  RemovedFromBlacklist = 'removed-from-blacklist',
  MailboxEmailSent = 'mailbox-email-sent',
  MailboxEmailOpened = 'mailbox-email-opened',
  MailboxEmailLinkClicked = 'mailbox-email-link-clicked',
  SequenceContactResumed = 'sequence-contact-resumed',
  SequenceContactPaused = 'sequence-contact-paused',
  UniboxEmailOpened = 'unibox-email-opened',
  UniboxEmailLinkClicked = 'unibox-email-link-clicked',
  PrimaryEmailChangedAuto = 'primary-email-changed-auto',
  PrimaryEmailChangedManual = 'primary-email-changed-manual',
}

export default MasterLog;
