export enum DoNotContactListsSortBy {
  Total = 'total',
  ClientAssociated = 'client',
  UpdatedAt = 'updatedAt',
}

export enum DoNoContactListApplicableTo {
  AllClients = 'all-clients',
  SpecificClient = 'specific-client',
}

export enum DoNoContactValueType {
  ALL = 'all',
  EMAIL = 'email',
  DOMAIN = 'domain',
}
