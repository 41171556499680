import React from 'react';

export const Quora = ({ width = 16, height = 16 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.40041 11.728C7.93882 10.8191 7.39589 9.8979 6.33849 9.8979C6.12926 9.89551 5.92172 9.93563 5.72846 10.0158L5.36854 9.296C5.80573 8.91981 6.51337 8.62496 7.40199 8.62496C8.81524 8.62496 9.54118 9.30617 10.1187 10.1765C10.4603 9.43427 10.623 8.43178 10.623 7.18731C10.623 4.08427 9.65302 2.51038 7.38572 2.51038C5.14893 2.51038 4.1871 4.1046 4.1871 7.18731C4.1871 10.27 5.15299 11.85 7.38572 11.85C7.71406 11.8544 8.04135 11.8119 8.35771 11.7239L8.40041 11.728ZM8.95351 12.8118C8.45612 12.9455 7.94345 13.0138 7.42842 13.0152C4.45349 13.0172 1.53955 10.6421 1.53955 7.18935C1.53955 3.70604 4.45349 1.33301 7.42842 1.33301C10.4522 1.33301 13.3336 3.69181 13.3336 7.18935C13.3411 8.06925 13.1438 8.93884 12.7574 9.72938C12.3709 10.5199 11.8059 11.2097 11.1069 11.7443C11.5136 12.3828 11.9732 12.8078 12.5853 12.8078C13.2522 12.8078 13.5206 12.2913 13.5674 11.8866H14.4377C14.4886 12.4255 14.2181 14.6643 11.7881 14.6643C10.3159 14.6643 9.53711 13.8123 8.95351 12.8118Z"
      fill="currentcolor"
    />
  </svg>
);
