import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../../../store/root-reducer';

import DoNotContactListDetailsTable from './do-not-contact-list-details-table';

import {
  PaginationOptions,
  DoNotContactListDetailsFilter,
  DoNotContactListEmailsAndDomains,
  BulkSelectedDNCListEmailsAndDomains,
} from '../../../../../../types/do-not-contact-list';

import {
  clearDNCListEmailsAndDomainsSelection,
  onBulkSelectDNCListEmailsAndDomains,
  selectAllDNCListEmailsAndDomains,
  selectSingleDNCListEmailsAndDomains,
} from '../../../../do-not-contact-slice';

const mapStateToProps = (state: RootState) => ({
  bulkSelectedDNCListEmailsAndDomains:
    state.doNotContact.bulkSelectedDNCListEmailsAndDomains,
  selectedDNCListEmailsAndDomains:
    state.doNotContact.selectedDNCListEmailsAndDomains,

  getDoNotContactListDetailsRequestStatus:
    state.doNotContact.getDoNotContactListDetailsRequest.status,
});

const mapDispatchToProps = {
  onBulkSelectDNCListEmailsAndDomains: (
    payload: BulkSelectedDNCListEmailsAndDomains,
  ) => onBulkSelectDNCListEmailsAndDomains(payload),
  selectAllDNCListEmailsAndDomains: (payload: {
    rows: any[];
    status: boolean;
  }) => selectAllDNCListEmailsAndDomains(payload),
  clearDNCListEmailsAndDomainsSelection: () =>
    clearDNCListEmailsAndDomainsSelection(),
  selectSingleDNCListEmailsAndDomains: (payload: {
    row: any;
    status: boolean;
  }) => selectSingleDNCListEmailsAndDomains(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  doNotContactListDetails: DoNotContactListEmailsAndDomains[];
  doNotContactListDetailsPaginationOptions: PaginationOptions;
  filters: DoNotContactListDetailsFilter;
  onFiltersChange: (payload: Partial<DoNotContactListDetailsFilter>) => void;
  onAction: (
    key: string,
    doNotContactList: DoNotContactListEmailsAndDomains,
  ) => void;
  isLoading: boolean;
  resetSelected: boolean;
  handleResetSelected: VoidFunction;
};

export default connector(DoNotContactListDetailsTable);
