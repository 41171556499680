import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { RootState } from '../../../../store/root-reducer';
import { signupRequest, hideSignupRequestError } from '../signup/signup-slice';
import { acceptInvitationRequest } from '../invitation-signup/invitation-signup-slice';
import {
  AcceptInvitationRequestPayload,
  SignupRequestPayload,
} from '../../types/request-payload';
import AuthForm from './signup-form';

const mapStateToProps = (state: RootState) => ({
  signupRequestStatus: state.signup.signupRequest.status,
  signupRequestMessage: state.signup.signupRequest.message,
  signupRequestError: state.signup.signupRequest.error,
  showSignupRequestError: state.signup.signupRequest.showError,

  token: state.signup.token || state.invitationSignup.accessToken,
  trackingId: state.signup.trackingId || state.invitationSignup.trackingId,

  acceptInvitationRequestStatus:
    state.invitationSignup.acceptInvitationRequest.status,
});

const mapDispatchToProps = {
  hideError: () => hideSignupRequestError(),

  sendSignupRequest: (payload: SignupRequestPayload) => signupRequest(payload),

  sendAcceptInvitationRequest: (payload: AcceptInvitationRequestPayload) =>
    acceptInvitationRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> &
  RouteComponentProps & {
    isInvitedUser?: boolean;
    invitationToken?: string;
    email?: string;
  };

export default withRouter(connector(AuthForm));
