import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { ResponseErrorWithHandled } from '../../shared/types';
import {
  getSequenceListRequest,
  getSelectedSequencesRequest,
  getSelectedUsersRequest,
  getSequenceReportsSummaryRequest,
  getTeamReportsSummaryRequest,
  getSequenceReportsStatsRequest,
  getTeamReportsStatsRequest,
  gerGraphDataRequest,
} from './extra-actions';
import {
  Sequence,
  ReportsSummary,
  SequenceStatsReport,
  TeamStatsReport,
  SelectedUser,
  SequenceReportsFilter,
} from './types';
import { ReportCountBy, ReportsBy } from './enums/reports';
import {
  transformEmailGraphDataResponse,
  transformGraphDataResponse,
} from './components/graph-content/utils/helper';

type RequestState = {
  status: RequestStatus;
  message: string;
  error: ResponseErrorWithHandled;
};

type State = {
  getSequenceListRequest: RequestState;
  getSelectedSequencesRequest: RequestState;
  getSelectedUsersRequest: RequestState;
  getSequenceReportsSummaryRequest: RequestState;
  getTeamReportsSummaryRequest: RequestState;
  getSequenceReportsStatsRequest: RequestState;
  getTeamReportsStatsRequest: RequestState;
  gerGraphDataRequest: RequestState;

  sequences: Sequence[];
  selectedSequences: any[];
  selectedUsers: SelectedUser[];
  sequenceReportsSummary: ReportsSummary;
  // sequenceReportsSummary: any;
  teamReportsSummary: ReportsSummary;
  sequenceReportsStats: SequenceStatsReport[];
  teamReportsStats: TeamStatsReport[];
  totalTeams: number;
  countBy: ReportCountBy;
  isFirstEvent: boolean;
  graphData: any;
  reportsBy: ReportsBy;
};

const initialState: State = {
  getSequenceListRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSelectedSequencesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSelectedUsersRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceReportsSummaryRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTeamReportsSummaryRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getSequenceReportsStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getTeamReportsStatsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  gerGraphDataRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },

  sequences: [],
  selectedSequences: [],
  selectedUsers: [],
  sequenceReportsSummary: null,
  teamReportsSummary: null,
  sequenceReportsStats: null,
  teamReportsStats: null,
  totalTeams: 0,
  countBy: ReportCountBy.Relative,
  isFirstEvent: false,
  graphData: null,
  reportsBy: ReportsBy.ByProspects,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetSequenceReportsState: (state) => {
      state.sequenceReportsSummary = initialState.sequenceReportsSummary;
      state.sequenceReportsStats = initialState.sequenceReportsStats;
    },
    resetTeamReportsState: (state) => {
      state.teamReportsSummary = initialState.teamReportsSummary;
      state.teamReportsStats = initialState.teamReportsStats;
      state.totalTeams = initialState.totalTeams;
    },
    resetGetSelectedSequencesRequest: (state) => {
      state.getSelectedSequencesRequest =
        initialState.getSelectedSequencesRequest;
    },
    resetGetSelectedUsersRequest: (state) => {
      state.getSelectedUsersRequest = initialState.getSelectedUsersRequest;
    },
    resetGetSequenceReportsSummaryRequest: (state) => {
      state.getSequenceReportsSummaryRequest =
        initialState.getSequenceReportsSummaryRequest;
    },
    resetGetTeamReportsSummaryRequest: (state) => {
      state.getTeamReportsSummaryRequest =
        initialState.getTeamReportsSummaryRequest;
    },
    resetGetSequenceReportsStatsRequest: (state) => {
      state.getSequenceReportsStatsRequest =
        initialState.getSequenceReportsStatsRequest;
    },
    resetGetTeamReportsStatsRequest: (state) => {
      state.getTeamReportsStatsRequest =
        initialState.getTeamReportsStatsRequest;
    },
    toggleCountByAndFirstEvent: (state, action) => {
      state.countBy = action.payload?.countBy;
      state.isFirstEvent = action.payload?.isFirstEvent;
    },
    onReportsByChange: (state, action) => {
      state.reportsBy = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get Sequence List
    builder.addCase(getSequenceListRequest.pending, (state) => {
      state.getSequenceListRequest.status = RequestStatus.Pending;
      state.getSequenceListRequest.error = null;
    });
    builder.addCase(getSequenceListRequest.fulfilled, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Succeeded;
      state.getSequenceListRequest.message = action.payload.message;
      state.sequences = action.payload.payload;
    });
    builder.addCase(getSequenceListRequest.rejected, (state, action) => {
      state.getSequenceListRequest.status = RequestStatus.Failed;
      state.getSequenceListRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Selected Sequences
    builder.addCase(getSelectedSequencesRequest.pending, (state) => {
      state.getSelectedSequencesRequest.status = RequestStatus.Pending;
      state.getSelectedSequencesRequest.error = null;
    });
    builder.addCase(getSelectedSequencesRequest.fulfilled, (state, action) => {
      state.getSelectedSequencesRequest.status = RequestStatus.Succeeded;
      state.getSelectedSequencesRequest.message = action.payload.message;
      state.selectedSequences = action.payload.payload;
    });
    builder.addCase(getSelectedSequencesRequest.rejected, (state, action) => {
      state.getSelectedSequencesRequest.status = RequestStatus.Failed;
      state.getSelectedSequencesRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Selected Users
    builder.addCase(getSelectedUsersRequest.pending, (state) => {
      state.getSelectedUsersRequest.status = RequestStatus.Pending;
      state.getSelectedUsersRequest.error = null;
    });
    builder.addCase(getSelectedUsersRequest.fulfilled, (state, action) => {
      state.getSelectedUsersRequest.status = RequestStatus.Succeeded;
      state.getSelectedUsersRequest.message = action.payload.message;
      state.selectedUsers = action.payload.payload;
    });
    builder.addCase(getSelectedUsersRequest.rejected, (state, action) => {
      state.getSelectedUsersRequest.status = RequestStatus.Failed;
      state.getSelectedUsersRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Summary
    builder.addCase(getSequenceReportsSummaryRequest.pending, (state) => {
      state.getSequenceReportsSummaryRequest.status = RequestStatus.Pending;
      state.getSequenceReportsSummaryRequest.error = null;
    });
    builder.addCase(
      getSequenceReportsSummaryRequest.fulfilled,
      (state, action) => {
        state.getSequenceReportsSummaryRequest.status = RequestStatus.Succeeded;
        state.getSequenceReportsSummaryRequest.message = action.payload.message;
        state.sequenceReportsSummary = action.payload.payload;
      },
    );
    builder.addCase(
      getSequenceReportsSummaryRequest.rejected,
      (state, action) => {
        state.getSequenceReportsSummaryRequest.status = RequestStatus.Failed;
        state.getSequenceReportsSummaryRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Team Summary
    builder.addCase(getTeamReportsSummaryRequest.pending, (state) => {
      state.getTeamReportsSummaryRequest.status = RequestStatus.Pending;
      state.getTeamReportsSummaryRequest.error = null;
    });
    builder.addCase(getTeamReportsSummaryRequest.fulfilled, (state, action) => {
      state.getTeamReportsSummaryRequest.status = RequestStatus.Succeeded;
      state.getTeamReportsSummaryRequest.message = action.payload.message;
      state.teamReportsSummary = action.payload.payload;
    });
    builder.addCase(getTeamReportsSummaryRequest.rejected, (state, action) => {
      state.getTeamReportsSummaryRequest.status = RequestStatus.Failed;
      state.getTeamReportsSummaryRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Sequence Stats
    builder.addCase(getSequenceReportsStatsRequest.pending, (state) => {
      state.getSequenceReportsStatsRequest.status = RequestStatus.Pending;
      state.getSequenceReportsStatsRequest.error = null;
    });
    builder.addCase(
      getSequenceReportsStatsRequest.fulfilled,
      (state, action) => {
        state.getSequenceReportsStatsRequest.status = RequestStatus.Succeeded;
        state.getSequenceReportsStatsRequest.message = action.payload.message;
        state.sequenceReportsStats = action.payload.payload;
      },
    );
    builder.addCase(
      getSequenceReportsStatsRequest.rejected,
      (state, action) => {
        state.getSequenceReportsStatsRequest.status = RequestStatus.Failed;
        state.getSequenceReportsStatsRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Get Team Stats
    builder.addCase(getTeamReportsStatsRequest.pending, (state) => {
      state.getTeamReportsStatsRequest.status = RequestStatus.Pending;
      state.getTeamReportsStatsRequest.error = null;
    });
    builder.addCase(getTeamReportsStatsRequest.fulfilled, (state, action) => {
      state.getTeamReportsStatsRequest.status = RequestStatus.Succeeded;
      state.getTeamReportsStatsRequest.message = action.payload.message;
      state.teamReportsStats = action.payload.payload.items;
      state.totalTeams = action.payload.payload?.meta?.totalItems;
    });
    builder.addCase(getTeamReportsStatsRequest.rejected, (state, action) => {
      state.getTeamReportsStatsRequest.status = RequestStatus.Failed;
      state.getTeamReportsStatsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Graph Data
    builder.addCase(gerGraphDataRequest.pending, (state) => {
      state.gerGraphDataRequest.status = RequestStatus.Pending;
      state.gerGraphDataRequest.error = null;
    });
    builder.addCase(gerGraphDataRequest.fulfilled, (state, action) => {
      state.gerGraphDataRequest.status = RequestStatus.Succeeded;
      state.gerGraphDataRequest.message = action.payload.message;
      state.graphData =
        state.reportsBy === ReportsBy.ByProspects
          ? transformGraphDataResponse(action.payload.payload)
          : transformEmailGraphDataResponse(action.payload.payload);
    });
    builder.addCase(gerGraphDataRequest.rejected, (state, action) => {
      state.gerGraphDataRequest.status = RequestStatus.Failed;
      state.gerGraphDataRequest.error =
        !action.payload.isHandled && action.payload;
    });
  },
});

export const {
  resetSequenceReportsState,
  resetTeamReportsState,
  resetGetSelectedSequencesRequest,
  resetGetSelectedUsersRequest,
  resetGetSequenceReportsSummaryRequest,
  resetGetTeamReportsSummaryRequest,
  resetGetSequenceReportsStatsRequest,
  resetGetTeamReportsStatsRequest,
  toggleCountByAndFirstEvent,
  onReportsByChange,
} = reportsSlice.actions;
export default reportsSlice.reducer;
