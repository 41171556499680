import React from 'react';
import { IProps, IState } from './types';
import { SHLogoDark } from '../../../../shared/svg';
import ChangePasswordSuccess from './components/change-password-success';
import ChangePasswordForm from './components/change-password-form';
import TestimonialsCarousel from '../../../../shared/components/testimonials-carousel';

class ChangePassword extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { showSuccessComponent: false };
    this.showSuccessComponent = this.showSuccessComponent.bind(this);
  }

  showSuccessComponent() {
    this.setState({ showSuccessComponent: true });
  }

  render() {
    const { showSuccessComponent } = this.state;

    return (
      <>
        <div className="testimonials-container">
          <SHLogoDark width={160} />
          <TestimonialsCarousel />
          <p className="copyright">
            &copy;{`${new Date().getFullYear()} Saleshandy`}
          </p>
        </div>
        {showSuccessComponent ? (
          <ChangePasswordSuccess />
        ) : (
          <ChangePasswordForm
            showSuccessComponent={this.showSuccessComponent}
          />
        )}
      </>
    );
  }
}

export default ChangePassword;
