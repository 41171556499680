import { withTranslation } from 'react-i18next';
import React, { useState } from 'react';
import classNames from 'classnames';
import { IProps, OutcomeContentTabs } from './types';
import { PaginationQueryParams } from '../../../../../../shared/types/request';
import CustomOutcomeTabPanes from './custom-outcome-tabpanes';

const CustomOutcomeContent: React.FC<IProps> = ({
  customOutcomes,
  defaultOutcomes,
  customOutcomePagination,
  defaultOutcomePagination,
  sendGetCustomOutcomeRequest,
  sendGetDefaultOutcomeRequest,
  onUpdateHandler,
  onDeleteHandler,
  onCreateHandler,
  isRequestPending,
  subscriptionPlan,
  initialSearch = '',
  activeTabKey,
  setActiveTabKey,
}) => {
  const onTabChange = (tabKey) => {
    setActiveTabKey(tabKey);
  };

  const onCustomFieldPaginationOptionsChangeHandler = (
    newPartialOptions: Partial<PaginationQueryParams> & { search?: string },
  ) => {
    const { options } = customOutcomePagination;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetCustomOutcomeRequest(payload);
  };

  const onDefaultFieldPaginationOptionsChangeHandler = (
    newPartialOptions: Partial<PaginationQueryParams> & { search?: string },
  ) => {
    const { options } = customOutcomePagination;
    const payload = { ...options, ...newPartialOptions };
    delete payload.totalElements;
    sendGetDefaultOutcomeRequest(payload);
  };

  const [search, setSearch] = useState(initialSearch);

  const onSearch = () => {
    if (activeTabKey === OutcomeContentTabs.CustomOutcomes) {
      onCustomFieldPaginationOptionsChangeHandler({
        search,
        page: 1,
        limit: 25,
      });
    } else if (activeTabKey === OutcomeContentTabs.SystemOutcomes) {
      onDefaultFieldPaginationOptionsChangeHandler({
        search,
        page: 1,
        limit: 25,
      });
    }
  };
  const onClearSearch = () => {
    setSearch('');
    if (activeTabKey === OutcomeContentTabs.CustomOutcomes) {
      onCustomFieldPaginationOptionsChangeHandler({
        search: '',
        page: 1,
        limit: 25,
      });
    } else if (activeTabKey === OutcomeContentTabs.SystemOutcomes) {
      onDefaultFieldPaginationOptionsChangeHandler({
        search: '',
        page: 1,
        limit: 25,
      });
    }
  };

  const onSetSearch = (_search: string) => {
    setSearch(_search);
  };

  const onAction = (key, row) =>
    key === 'edit' ? onUpdateHandler(row) : onDeleteHandler(row);

  const containerClassName = classNames([
    'custom-fields-content-container',
    {
      'custom-outcome-container':
        activeTabKey === OutcomeContentTabs.CustomOutcomes,
      'system-outcome-container':
        activeTabKey === OutcomeContentTabs.SystemOutcomes,
    },
  ]);

  return (
    <div className={containerClassName}>
      <CustomOutcomeTabPanes
        onCreateHandler={onCreateHandler}
        customOutcomes={customOutcomes}
        defaultOutcomes={defaultOutcomes}
        defaultOutcomePagination={defaultOutcomePagination}
        customOutcomePagination={customOutcomePagination}
        onActonHandler={onAction}
        onCustomOutcomePaginationOptionsChangeHandler={
          onCustomFieldPaginationOptionsChangeHandler
        }
        onDefaultOutcomePaginationOptionsChangeHandler={
          onDefaultFieldPaginationOptionsChangeHandler
        }
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        subscriptionPlan={subscriptionPlan}
        search={search}
        setSearch={onSetSearch}
        onClearSearch={onClearSearch}
        onSearch={onSearch}
        isRequestPending={isRequestPending}
      />
    </div>
  );
};

export default withTranslation()(CustomOutcomeContent);
