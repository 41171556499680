import {
  WebhookTriggerEvent,
  WebhookTriggerEventName,
} from '../../../enums/webhook';

export const getWebhookTriggerEventName = (
  trigger: WebhookTriggerEvent,
): WebhookTriggerEventName => {
  switch (trigger) {
    case WebhookTriggerEvent.EmailSent:
      return WebhookTriggerEventName.EmailSent;

    case WebhookTriggerEvent.EmailOpened:
      return WebhookTriggerEventName.EmailOpened;

    case WebhookTriggerEvent.EmailLinkClicked:
      return WebhookTriggerEventName.EmailLinkClicked;

    case WebhookTriggerEvent.ReplyReceived:
      return WebhookTriggerEventName.ReplyReceived;

    case WebhookTriggerEvent.EmailBounced:
      return WebhookTriggerEventName.EmailBounced;

    case WebhookTriggerEvent.ProspectUnsubscribed:
      return WebhookTriggerEventName.ProspectUnsubscribed;

    case WebhookTriggerEvent.ProspectFinished:
      return WebhookTriggerEventName.ProspectFinished;

    case WebhookTriggerEvent.ProspectOutcomeUpdated:
      return WebhookTriggerEventName.ProspectOutcomeUpdated;

    default:
      return null;
  }
};
