import React from 'react';
import { getFormattedDate } from '../../../utils/get-formatted-date';

const TeamsCell: React.FC<{ lastLogin: string; userTimezone: string }> = ({
  lastLogin,
  userTimezone,
}) => (
  <div className="text-cell last-login-cell">
    {getFormattedDate(lastLogin, userTimezone)}
  </div>
);

export default TeamsCell;
