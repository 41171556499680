import { ResponseSuccess } from '../../../shared/types';
import api from '../../../api';
import {
  CreateOutcomeRequestPayload,
  // GetOutcomesQueryParams,
  UpdateOutcomeRequestPayload,
} from '../types/request-payload';
// import { ProspectType } from '../enums/prospect-type';
import { PaginationQueryParams } from '../../../shared/types/request';
import { OutcomeCategory } from '../enums/custom-outcome';

// Get Outcomes with pagination function
export const getCustomOutcomesWithPagination = async (
  params: PaginationQueryParams = {},
) =>
  api.get<ResponseSuccess>('/outcome', {
    params: { ...params, category: OutcomeCategory.Custom },
  });

// Get fields with pagination function
export const getDefaultOutcomesWithPagination = async (
  params: PaginationQueryParams = {},
) =>
  api.get<ResponseSuccess>('/outcome', {
    params: { ...params, category: OutcomeCategory.Default },
  });

// Get All Fields
export const getAllOutcomes = async (
  params: PaginationQueryParams & { search?: string } = {},
) => api.get<ResponseSuccess>('/outcome', { params });

// Get fields generic function
const getOutcomesGeneric = async () => api.get<ResponseSuccess>('/outcome');

// Get fields (excluding defaults)
export const getCustomOutcomes = () => getOutcomesGeneric();

// Create Outcome
export const createCustomOutcome = async (
  payload: CreateOutcomeRequestPayload,
) =>
  api.post<ResponseSuccess>('/outcome', {
    name: payload.label,
    sentiment: payload.sentiment,
  });

// Update outcome
export const updateCustomOutcome = async (
  outcomeId: number,
  payload: UpdateOutcomeRequestPayload,
) =>
  api.patch<ResponseSuccess>(`/outcome/${outcomeId}`, {
    name: payload.label,
    sentiment: payload.sentiment,
  });

// Delete outcome
export const deleteCustomOutcome = async (outcomeId: number, newOutcomeId: number) =>
  api.delete<ResponseSuccess>(`/outcome/${outcomeId}?updateOutcomeId=${newOutcomeId}`);
