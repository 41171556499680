import { TaskTypeFilter } from './types';

export const tasksTypeFilterOptions: TaskTypeFilter[] = [
  {
    id: 10,
    key: 'linkedin',
    label: 'Linkedin',
    subTypes: [
      {
        id: 2,
        key: 'connectionRequest',
        label: 'Connection Request',
      },
      {
        id: 3,
        key: 'message',
        label: 'Message',
      },
      {
        id: 4,
        key: 'inMail',
        label: 'InMail',
      },
      {
        id: 5,
        key: 'viewProfile',
        label: 'View Profile',
      },
      {
        id: 6,
        key: 'postInteraction',
        label: 'Post Interaction',
      },
    ],
  },
  {
    id: 7,
    key: 'calls',
    label: 'Calls',
  },
  //   {
  //     id: 8,
  //     key: 'whatsApp',
  //     label: 'Whatsapp',
  //     isDisabled: true,
  //   },
  {
    id: 9,
    key: 'tasks',
    label: 'Tasks',
  },
];

export const onLinkedInOptionClicked = (
  option: TaskTypeFilter,
  selectedTaskIds: number[],
  setSelectedTaskIds: (payload: number[]) => void,
) => {
  // Check if all user IDs in the team are in selectedUserIds
  const allUsersSelected = option?.subTypes.every((task) =>
    selectedTaskIds.includes(task.id),
  );

  if (allUsersSelected) {
    // If all users are selected, remove all of them
    const updatedSelectedUserIds = selectedTaskIds.filter(
      (id) => !option?.subTypes.some((task) => task.id === id),
    );
    setSelectedTaskIds(updatedSelectedUserIds);
  } else {
    // If not all users are selected, add those who are not already in selectedUserIds
    const taskIDsToAdd = option?.subTypes
      .map((task) => task.id)
      .filter((taskId) => !selectedTaskIds.includes(taskId));

    const newSelectedUserIds = [...selectedTaskIds, ...taskIDsToAdd];
    setSelectedTaskIds(newSelectedUserIds);
  }
};

export const getIsAllCheckboxChecked = (
  options: TaskTypeFilter[],
  selectedTaskTypeIds: number[],
) => {
  const allTaskTypeIds = options.reduce((taskTypeIds, option) => {
    if (option?.subTypes?.length > 0) {
      taskTypeIds.push(...option.subTypes.map((t) => t.id));
    } else if (!option?.subTypes?.length) {
      taskTypeIds.push(option.id);
    }
    return taskTypeIds;
  }, []);

  if (options?.length > 0) {
    // Check if all user IDs are in selectedUserIds
    return {
      isSelectAllCheckboxChecked: allTaskTypeIds.every((taskTypeId) =>
        selectedTaskTypeIds.includes(taskTypeId),
      ),
      intermediate: allTaskTypeIds.some((taskTypeId) =>
        selectedTaskTypeIds.includes(taskTypeId),
      ),
    };
  }
  return {
    isSelectAllCheckboxChecked: false,
    intermediate: false,
  };
};

export const getSelectedOptionKey = (
  options: TaskTypeFilter[],
  selectedTaskTypeIds: number[],
): string[] => {
  // Initialize an array to store selected team and user keys
  const selectedKeys = [];

  // Loop through the "teams" array
  options.forEach((option) => {
    // Check if the team is of type "team"
    if (option?.subTypes?.length > 0) {
      // Check if all users in the team are selected
      const allUsersSelected = option.subTypes.every((type) =>
        selectedTaskTypeIds.includes(type.id),
      );

      // If all users are selected, add the team key to the selectedKeys array
      if (allUsersSelected) {
        selectedKeys.push(option.key);
      }
    }
    // If the team is of type "user" and the user is selected, add their key to selectedKeys
    else if (
      !option?.subTypes?.length &&
      selectedTaskTypeIds.includes(option.id)
    ) {
      selectedKeys.push(option.key);
    }
  });
  return selectedKeys;
};
