import { RequestStatus } from '../../../../../shared/enums/request-status';
import { SubscriptionPlans } from '../../../../../shared/utils/subscription-plans';

export const getIsUserSubscribed = (planCode: SubscriptionPlans) =>
  planCode !== SubscriptionPlans.FREE && planCode !== SubscriptionPlans.TRIAL;

export const getIsLeadFinderUserSubscribed = (planCode: SubscriptionPlans) =>
  planCode !== SubscriptionPlans.LEAD_FINDER_FREE &&
  planCode !== SubscriptionPlans.LEAD_FINDER_TRIAL;

export const isPageLoading = (
  sendGetPlansRequestStatus,
  sendGetAccountSubscriptionRequestStatus,
  sendGetConnectedUsersAndEmailAccountsRequestStatus,
) =>
  sendGetPlansRequestStatus === RequestStatus.Ideal ||
  sendGetPlansRequestStatus === RequestStatus.Pending ||
  sendGetAccountSubscriptionRequestStatus === RequestStatus.Ideal ||
  sendGetAccountSubscriptionRequestStatus === RequestStatus.Pending ||
  sendGetConnectedUsersAndEmailAccountsRequestStatus === RequestStatus.Ideal ||
  sendGetConnectedUsersAndEmailAccountsRequestStatus === RequestStatus.Pending;

export const isRequestPending = (status) => status === RequestStatus.Pending;
