import React, { useState, useEffect } from 'react';
import {
  Cross,
  CircleCheck,
  AlertTriangle,
  Forbid,
  Hourglass,
  UserX,
} from '@saleshandy/icons';
import {
  DeleteUserFromTeamPayload,
  Team,
} from '../../../../../types/users-and-teams';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { getFormattedDate } from '../../../utils/get-formatted-date';
import {
  UserRole,
  UserStatusKeys,
  UserStatusLabel,
} from '../../../../../enums/users-and-teams';
import {
  capitalize,
  getIsRequestPending,
} from '../../../../../../../shared/utils';
import hasPermission from '../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../shared/utils/access-control/enums/permissions';
import { SeeMore } from '../../../../../../../shared/svg';
import {
  OverlayTooltip,
  Placement,
} from '../../../../../../../shared/design-system/components/overlay';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';

type IProps = {
  show: boolean;
  team: Team;
  onClose: VoidFunction;
  userTimezone: string;
  userId: number;
  atmRole: string;
  onTeamMemberRemove: (payload: DeleteUserFromTeamPayload) => void;
  getTeamsRequestStatus: RequestStatus;
  deleteUserFromTeamRequestStatus: RequestStatus;
};

const getStatusIcon = (status) => {
  if (status === UserStatusKeys.Invited) {
    return <Hourglass />;
  }
  if (status === UserStatusKeys.Failed) {
    return <AlertTriangle />;
  }
  if (status === UserStatusKeys.InActive) {
    return <Forbid />;
  }

  return <CircleCheck />;
};

const getStatusLabel = (status) => {
  if (status === UserStatusKeys.Invited) {
    return UserStatusLabel.Invited;
  }
  if (status === UserStatusKeys.Failed) {
    return UserStatusLabel.Failed;
  }
  if (status === UserStatusKeys.InActive) {
    return UserStatusLabel.InActive;
  }

  return UserStatusLabel.Active;
};

const getUserRole = (roles: string[]): string => {
  if (roles.includes(UserRole.Owner)) {
    return capitalize(UserRole.Owner);
  }
  if (roles.includes(UserRole.Admin)) {
    return capitalize(UserRole.Admin);
  }
  if (roles.includes(UserRole.TeamManager)) {
    return 'Team Manager';
  }

  return capitalize(UserRole.Member);
};

const TeamDetailsModal: React.FC<IProps> = ({
  show,
  team,
  onClose,
  userTimezone,
  userId,
  atmRole,
  onTeamMemberRemove,
  getTeamsRequestStatus,
  deleteUserFromTeamRequestStatus,
}) => {
  const [displayedRows, setDisplayedRows] = useState(50);

  useEffect(() => {
    if (show) {
      setDisplayedRows(50);
    }
  }, [show]);

  if (!show || !team) {
    return <span />;
  }

  const users = {
    owners: [],
    admins: [],
    teamManagers: [],
    members: [],
  };

  team?.users?.forEach((user) => {
    const { email, role } = user;

    const isAlreadyExist =
      users.owners.some((u) => u.email === email) ||
      users.admins.some((u) => u.email === email) ||
      users.teamManagers.some((u) => u.email === email) ||
      users.members.some((u) => u.email === email);

    if (isAlreadyExist) {
      return;
    }

    if (role.includes(UserRole.Owner)) {
      users.owners.push(user);
      return;
    }
    if (role.includes(UserRole.Admin)) {
      users.admins.push(user);
      return;
    }
    if (role.includes(UserRole.TeamManager)) {
      users.teamManagers.push(user);
      return;
    }
    if (role.includes(UserRole.Member)) {
      users.members.push(user);
    }
  });

  // Add a "See More" button click handler
  const handleSeeMoreClick = () => {
    // Set the number of displayed rows to the total number of users
    setDisplayedRows(
      users.owners?.length +
        users.admins?.length +
        users.teamManagers?.length +
        users.members?.length,
    );
  };

  //  Display the "See More" button if there are more users to show
  const renderSeeMoreBtn = () =>
    displayedRows <
      users.owners?.length +
        users.admins?.length +
        users.teamManagers?.length +
        users.members?.length && (
      <div className="see-more-container">
        <SeeMore />
        <span {...accessibleOnClick(handleSeeMoreClick)}>See more users</span>
      </div>
    );

  const renderUsers = ({ id, name, email, status, role }, index: number) => {
    const isDeletionInProgress =
      getIsRequestPending(deleteUserFromTeamRequestStatus) ||
      getIsRequestPending(getTeamsRequestStatus);

    return (
      <div className="user">
        <div className="user-left">
          <h1>
            {name && (
              <h1>{`${name} ${userId === id ? '(You)' : ''}`.trim()}</h1>
            )}
          </h1>
          <p>
            {email}
            <OverlayTooltip
              text={getStatusLabel(status)}
              placement={Placement.Right}
            >
              <span className={getStatusLabel(status).toLowerCase()}>
                {getStatusIcon(status)}
              </span>
            </OverlayTooltip>
          </p>
        </div>
        <div className="user-right">
          <p>{getUserRole(role)}</p>

          {hasPermission(Permissions.ACCOUNT_TEAM_UPDATE) &&
            userId !== id &&
            !role.includes(UserRole.Owner) &&
            (atmRole !== UserRole.Admin || !role.includes(UserRole.Admin)) && (
              <div
                className={isDeletionInProgress ? 'disabled-btn' : ''}
                {...(!isDeletionInProgress &&
                  accessibleOnClick(() =>
                    onTeamMemberRemove({
                      teamId: Number(team.id),
                      userId: id,
                    }),
                  ))}
              >
                <UserX />
              </div>
            )}
        </div>
        {displayedRows === index + 1 && renderSeeMoreBtn()}
      </div>
    );
  };

  return (
    <Modal
      show={show}
      className="team-detail-modal"
      hideHeader
      hideFooter
      backdrop="static"
    >
      <div
        className="team-detail-modal--close-btn"
        {...accessibleOnClick(onClose)}
      >
        <Cross />
      </div>

      <div className="invite-user-modal--content">
        <div className="team-detail-modal--wrapper">
          <h1 className="team-detail-modal--title">
            <span>{team.name}</span>
          </h1>
          <p className="team-detail-modal--createdOn">
            Created on {getFormattedDate(team.createdAt, userTimezone, false)}
          </p>
          <p className="team-detail-modal--total">
            {team?.users?.length} Users
          </p>
          <div className="team-detail-modal--users">
            {[
              ...users.owners,
              ...users.admins,
              ...users.teamManagers,
              ...users.members,
            ]
              .slice(0, displayedRows)
              .map((user, index) => renderUsers(user, index))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TeamDetailsModal;
