import React from 'react';

export const NoEmailResultFound = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="37.0875" cy="40.9195" r="30.4762" fill="#F5F5F7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.945 35.3878C16.3537 35.2244 16.5525 34.7607 16.3891 34.352C16.2257 33.9434 15.762 33.7446 15.3533 33.908L13.3802 34.6969C12.9715 34.8603 12.7727 35.3241 12.9361 35.7327C13.0995 36.1414 13.5632 36.3402 13.9719 36.1768L15.945 35.3878ZM22.1243 24.6845C22.1872 25.1201 21.885 25.5242 21.4494 25.587C21.0138 25.6498 20.6097 25.3476 20.5469 24.912L20.2436 22.8087C20.1808 22.3731 20.483 21.9691 20.9186 21.9063C21.3542 21.8434 21.7582 22.1456 21.821 22.5812L22.1243 24.6845ZM16.2513 30.5162C16.2144 30.9548 15.8289 31.2804 15.3904 31.2435L13.2728 31.0653C12.8343 31.0284 12.5087 30.643 12.5456 30.2044C12.5825 29.7659 12.9679 29.4403 13.4065 29.4772L15.524 29.6553C15.9626 29.6922 16.2882 30.0776 16.2513 30.5162ZM18.2556 26.2773C18.5068 26.6386 18.4175 27.1352 18.0562 27.3864C17.6948 27.6377 17.1982 27.5484 16.947 27.187L15.7339 25.4423C15.4827 25.0809 15.572 24.5843 15.9333 24.3331C16.2947 24.0819 16.7913 24.1711 17.0425 24.5325L18.2556 26.2773Z"
      fill="#CCD0DB"
    />
    <g clipPath="url(#clip0_35780_16730)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4261 44.2028L21.9036 35.4607C21.5058 35.2043 21.1795 34.8563 20.9541 34.4479C20.7286 34.0395 20.6108 33.5833 20.6113 33.1201C20.6113 31.4948 21.9737 30.1738 23.6482 30.1737H50.5745C52.249 30.1737 53.6113 31.4947 53.6113 33.1185C53.6113 34.0629 53.1283 34.9379 52.319 35.4607L38.7966 44.2028C38.2847 44.5337 37.698 44.6992 37.1113 44.6992C36.5246 44.6992 35.938 44.5338 35.4261 44.2028ZM37.1113 46.5744C38.0946 46.5744 39.0482 46.2938 39.8692 45.7631L53.3911 37.0213C53.4659 36.9728 53.5393 36.9223 53.6113 36.8699V51.1948C53.6113 52.8078 52.2671 54.1406 50.5734 54.1406H23.6492C21.9858 54.1406 20.6113 52.8371 20.6113 51.1948V36.8699C20.6831 36.9222 20.7564 36.9726 20.8311 37.021L34.3535 45.7631C35.1745 46.2938 36.1282 46.5744 37.1113 46.5744Z"
        fill="url(#paint0_linear_35780_16730)"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M71.147 49.9383C71.147 49.1494 70.5074 48.5098 69.7184 48.5098H63.7489C62.9599 48.5098 62.3203 49.1494 62.3203 49.9383C62.3203 50.7273 62.9599 51.3669 63.7489 51.3669H69.7184C70.5074 51.3669 71.147 50.7273 71.147 49.9383ZM71.147 56.6053C71.147 55.8164 70.5074 55.1768 69.7184 55.1768H59.3356C58.5466 55.1768 57.907 55.8164 57.907 56.6053C57.907 57.3943 58.5466 58.0339 59.3356 58.0339H69.7184C70.5074 58.0339 71.147 57.3943 71.147 56.6053ZM59.7882 48.5098C60.5771 48.5098 61.2167 49.1494 61.2167 49.9383C61.2167 50.7273 60.5771 51.3669 59.7882 51.3669H59.3353C58.5463 51.3669 57.9067 50.7273 57.9067 49.9383C57.9067 49.1494 58.5463 48.5098 59.3353 48.5098H59.7882Z"
      fill="#DBE0E7"
    />
    <path
      d="M64.4382 11.1409C60.6741 10.2827 58.4367 12.1195 58.0935 13.6255C57.8455 14.7131 58.6511 15.4248 59.4039 15.5965C60.9096 15.9398 60.7858 13.6526 63.6298 14.3011C65.0239 14.619 65.9993 15.4869 65.7069 16.7696C65.3635 18.2755 63.605 18.7839 62.5069 19.3549C61.5394 19.8678 60.2192 20.7696 59.6724 23.1678C59.3418 24.6179 59.6367 25.1251 60.7799 25.3858C62.1461 25.6972 62.5649 25.1474 62.6855 24.6172C63.0162 23.1671 63.2348 22.3368 65.0417 21.4874C65.9281 21.0735 68.7304 19.7174 69.3281 17.0961C69.9258 14.4749 68.007 11.9547 64.4382 11.1409Z"
      fill="#DBE0E7"
    />
    <path
      d="M60.369 27.3164C60.087 27.2521 59.7952 27.244 59.51 27.2925C59.2249 27.341 58.9521 27.4452 58.7072 27.5992C58.4624 27.7531 58.2502 27.9538 58.0829 28.1897C57.9156 28.4256 57.7964 28.6922 57.7321 28.9742C57.6678 29.2561 57.6597 29.548 57.7082 29.8332C57.7567 30.1183 57.8609 30.3911 58.0149 30.6359C58.1688 30.8808 58.3694 31.0929 58.6054 31.2602C58.8413 31.4276 59.1079 31.5468 59.3898 31.611C59.9594 31.7409 60.5571 31.6392 61.0517 31.3283C61.5462 31.0174 61.8969 30.5228 62.0268 29.9533C62.1566 29.3838 62.0549 28.786 61.744 28.2915C61.4332 27.797 60.9385 27.4463 60.369 27.3164Z"
      fill="#DBE0E7"
    />
    <defs>
      <linearGradient
        id="paint0_linear_35780_16730"
        x1="29.3462"
        y1="51.7745"
        x2="30.5478"
        y2="29.6098"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A7ABB6" />
        <stop offset="1" stopColor="#C7CCD7" />
      </linearGradient>
      <clipPath id="clip0_35780_16730">
        <rect
          width="33"
          height="32"
          fill="white"
          transform="translate(20.6113 26.1572)"
        />
      </clipPath>
    </defs>
  </svg>
);
