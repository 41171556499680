/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Avatar, SkeletonLoading } from '@saleshandy/design-system';
import {
  Alarm,
  Checkbox,
  CircleCheck,
  Whatsapp,
  Mail,
  ForwardStep,
  LinkedinSquare,
  Signal,
} from '@saleshandy/icons';
import { Link } from 'react-router-dom';
import { Column } from '../../../../../shared/design-system/components/organisms/table/types';
import { getStepPriority } from '../../../../sequence/helpers/sequence-step';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';
import { OverlayTooltip } from '../../../../../shared/design-system/components/overlay';
import { TaskAction, TaskStatusKey } from '../../../utils/enums';
import ContactNameField from '../../../../../shared/design-system/components/molecules/contact-name-field';
import { SequenceStepType } from '../../../../sequence/enums';
import {
  getDateColumnTitle,
  getTaskDate,
  isActionsVisible,
  isLinkedInType,
} from './helper';
import TruncatedTextWithTooltip from '../../../../../shared/components/truncated-text-with-tooltip';
import { Priority } from '../../../../../shared/components/priority';
import { getFormattedDateWithMoment } from '../../../../../shared/utils';

const actions = [
  {
    icon: <Alarm width={18} height={18} className="gray-txt-15" />,
    tooltipText: 'Snooze Task',
    taskAction: TaskAction.SnoozeTask,
  },
  {
    icon: <ForwardStep width={20} height={20} className="gray-txt-15" />,
    tooltipText: 'Skip Task',
    taskAction: TaskAction.SkipTask,
  },
  {
    icon: <CircleCheck width={18} height={18} className="gray-txt-15" />,
    tooltipText: 'Mark as Complete',
    taskAction: TaskAction.ExecuteTask,
  },
];

const getTaskIcon = (type: SequenceStepType) => {
  if (isLinkedInType(type)) {
    return (
      <LinkedinSquare width={20} height={20} className="gray-txt-11 hr-blue" />
    );
  }
  if (type === SequenceStepType.Whatsapp) {
    return <Whatsapp width={20} height={20} className="gray-txt-11 hr-green" />;
  }
  if (type === SequenceStepType.Call) {
    return <Signal width={20} height={20} className="gray-txt-11 hr-purple" />;
  }
  if (type === SequenceStepType.Email) {
    return <Mail width={20} height={20} className="gray-txt-11" />;
  }

  return <Checkbox width={20} height={20} className="gray-txt-11 hr-orange" />;
};
const getDateColumnCellValue = (taskStatus: TaskStatusKey, date: string) => {
  if (taskStatus === TaskStatusKey.DueToday) {
    return 'Today';
  }
  if (taskStatus === TaskStatusKey.Upcoming) {
    return (
      <span
        className={`regular-2 ${
          taskStatus === TaskStatusKey.Upcoming
            ? 'popover-arrow-color-txt'
            : 'red-txt-12'
        }`}
      >{`Due in ${getFormattedDateWithMoment(date)}`}</span>
    );
  }
  if (taskStatus === TaskStatusKey.Overdue) {
    return (
      <span className="regular-2 red-txt-12">{`Due ${getFormattedDateWithMoment(
        date,
      )} ago`}</span>
    );
  }

  return (
    <span
      className={`regular-2 ${
        taskStatus === TaskStatusKey.Completed
          ? 'popover-arrow-color-txt'
          : 'orange-txt-14'
      }`}
    >
      {getFormattedDateWithMoment(date)}{' '}
      {getFormattedDateWithMoment(date).toLowerCase() !== 'today' && 'ago'}
    </span>
  );
};

export const getTasksColumns = (
  taskStatus: TaskStatusKey,
  updateTasks: (
    id: number,
    taskAction: TaskAction,
    taskType: SequenceStepType,
  ) => void,
  onTaskClick: (id: number) => void,
): Column[] => [
  {
    dataField: 'prospect',
    text: 'Prospect',
    headerAlign: 'left',
    cellClasses: 'prospect-field',
    headerClasses: 'name-field',
    component: (cell, row) => (
      <ContactNameField
        cell={cell}
        status={row.status}
        onClick={() => onTaskClick(row.id)}
        maxEmailLength={50}
      />
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'taskTitle',
    text: 'Task Title',
    headerAlign: 'left',
    cellClasses: 'name-field',
    component: (cell, row) => (
      <div
        className="d-flex align-items-center"
        {...accessibleOnClick(() => onTaskClick(row.id))}
      >
        {getTaskIcon(cell?.type)}
        <TruncatedTextWithTooltip
          string={cell?.title}
          length={50}
          textClassName="semibold-2 popover-arrow-color-txt ml-1"
        />
      </div>
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'sequence',
    text: 'Sequence',
    headerAlign: 'left',
    cellClasses: 'name-field',
    component: (cell) => (
      <div>
        <span className="regular-2 popover-arrow-color-txt">
          Step {cell.stepNumber}
        </span>

        <Link
          className="ml-1 regular-2 font-medium blue-txt-11 pointer"
          to={`/sequence/${cell.sequenceId}/steps`}
        >
          <TruncatedTextWithTooltip string={cell.sequenceName} length={40} />
        </Link>
      </div>
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'priority',
    text: 'Priority',
    headerAlign: 'left',
    cellClasses: 'tag-field',
    component: (cell) => {
      const { text, variant, className } = getStepPriority(cell);

      return <Priority text={text} variant={variant} className={className} />;
    },
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'date',
    text: getDateColumnTitle(taskStatus),
    headerAlign: 'left',
    cellClasses: 'name-field',
    component: (cell) => {
      const date = new Date(getTaskDate(taskStatus, cell));
      return (
        <div className="pointer">
          <OverlayTooltip text={date.toLocaleString()}>
            <span className="regular-2 popover-arrow-color-txt">
              {getDateColumnCellValue(
                taskStatus,
                getTaskDate(taskStatus, cell),
              )}
            </span>
          </OverlayTooltip>
        </div>
      );
    },
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
  {
    dataField: 'owner',
    text: 'Task Owner',
    headerAlign: 'left',
    cellClasses: 'owner',
    component: (cell, row) => (
      <div className="d-flex align-items-center">
        <Avatar firstName={cell.firstName} lastName={cell.lastName} />
        {!isActionsVisible(taskStatus) && (
          <div className="action-container">
            {actions.map(({ icon, tooltipText, taskAction }) => (
              <OverlayTooltip text={tooltipText} key={tooltipText}>
                <div
                  {...accessibleOnClick(() =>
                    updateTasks(cell.taskId, taskAction, row?.owner?.taskType),
                  )}
                >
                  {icon}
                </div>
              </OverlayTooltip>
            ))}
          </div>
        )}
        <div
          className={`ml-2 ${
            !isActionsVisible(taskStatus) && 'name-container'
          }`}
        >
          <span className="regular-2 gray-txt-10">{`${cell.firstName} ${cell.lastName}`}</span>
        </div>
      </div>
    ),
    headerLoadingSkeleton: <SkeletonLoading width={120} height={14} />,
    cellLoadingSkeleton: (
      <div>
        <SkeletonLoading width={72} height={14} />
        <SkeletonLoading width={160} height={8} />
      </div>
    ),
  },
];
