export type IProps = {
  show: boolean;
  onHide: () => void;
  isLoading: boolean;
  varient?: string;
  selectedSavedFilter?: any;
  getSavedFilters: () => void;
  handleGeneratePayload?: () => void;
};

export const SaveFilterModalVarient = {
  Save: 'Save',
  Edit: 'Edit',
  Delete: 'Delete',
};
