import React from 'react';

type IProps = {
  value: string;
  placeholder: string;
  className: string;
  onChange: (e: React.ChangeEvent) => void;
  ref: React.Ref<HTMLInputElement>;
  disabled?: boolean;
};

const SearchInput = React.forwardRef<HTMLInputElement, IProps>(
  (
    {
      value,
      onChange,
      placeholder = '',
      className = '',
      disabled = false,
      ...rest
    },
    ref,
  ) => (
    <input
      ref={ref}
      className={`select-search ${className}`}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      disabled={disabled}
      {...rest}
    />
  ),
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
