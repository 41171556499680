import React from 'react';

export const ArrowLeft = ({ width = 24, height = 24, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    className={className}
    viewBox="0 0 28 29"
    fill="none"
  >
    <g clipPath="url(#clip0_13464_3090)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.4448 14.7718C26.4448 14.1274 25.7484 13.6051 24.8893 13.6051H3.11149C2.25238 13.6051 1.55593 14.1274 1.55593 14.7718C1.55593 15.4161 2.25238 15.9384 3.11149 15.9384H24.8893C25.7484 15.9384 26.4448 15.4161 26.4448 14.7718Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.770095 13.9468C0.314483 14.4024 0.314483 15.1411 0.770095 15.5967L7.77009 22.5967C8.22571 23.0523 8.9644 23.0523 9.42001 22.5967C9.87562 22.1411 9.87562 21.4024 9.42001 20.9468L2.42001 13.9468C1.9644 13.4912 1.22571 13.4912 0.770095 13.9468Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.42001 6.94679C8.9644 6.49118 8.22571 6.49118 7.77009 6.94679L0.770095 13.9468C0.314483 14.4024 0.314483 15.1411 0.770095 15.5967C1.22571 16.0523 1.9644 16.0523 2.42001 15.5967L9.42001 8.59671C9.87562 8.1411 9.87562 7.4024 9.42001 6.94679Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_13464_3090">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="matrix(-1 0 0 1 28.0002 0.771732)"
        />
      </clipPath>
    </defs>
  </svg>
);
