import React from 'react';
import { NoLogsFound } from '../../../../../../../shared/svg';

const NoLogsFoundUI = ({ activityType = 'all' }) => (
  <div className="no-logs-found">
    <NoLogsFound />
    <h1>No logs found</h1>
    <p>{`There is no ${
      activityType === 'all' ? '' : activityType
    } log to show.`}</p>
  </div>
);

export default NoLogsFoundUI;
