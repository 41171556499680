import React, { useEffect } from 'react';
import { Button } from '@saleshandy/design-system';

import type { EmailChangedSuccessfullyProps } from '../types';

import Title from './sub-components/title';
import Description from './sub-components/description';
import Footer from './sub-components/footer';
import Divider from './sub-components/divider';
import { AuthHelper } from '../../../../../../../shared/utils';

const EmailChangedSuccessfully: React.FC<EmailChangedSuccessfullyProps> = ({
  newEmail,
}) => {
  const handleOnSubmit = () => {
    AuthHelper.logout();
  };

  useEffect(() => {
    window.addEventListener('unload', handleOnSubmit);

    return () => {
      window.removeEventListener('unload', handleOnSubmit);
    };
  }, []);

  return (
    <>
      <Title>
        <h1>Email Changed Successfully</h1>
      </Title>

      <Description
        content={
          <>
            <span>Your login email address has been updated to</span>{' '}
            <span className="bold">{newEmail}</span>.{' '}
            <span>Please note that your password remains unchanged.</span>
          </>
        }
      />

      <Description content="To continue, please log in again." />

      <Divider />

      <Footer>
        <Button onClick={handleOnSubmit}>Login Again</Button>
      </Footer>
    </>
  );
};

export default EmailChangedSuccessfully;
