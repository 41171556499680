import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../shared/enums/request-status';
import { RequestState } from '../../shared/types/request-state';
import {
  getAcademyTopicsRequest,
  getAcademyTopicsProgressRequest,
  updateAcademyTopicProgressRequest,
} from './extra-actions';
import {
  GetAcademyTopicsProgressResponse,
  GetAcademyTopicsResponse,
} from './types';

type State = {
  getAcademyTopicsRequest: RequestState;
  getAcademyTopicsProgressRequest: RequestState;
  updateAcademyTopicProgressRequest: RequestState;
  getAcademyTopicsResponse: GetAcademyTopicsResponse[];
  getAcademyTopicsProgressResponse: GetAcademyTopicsProgressResponse;
};

const initialState: State = {
  getAcademyTopicsRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAcademyTopicsProgressRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateAcademyTopicProgressRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAcademyTopicsResponse: null,
  getAcademyTopicsProgressResponse: null,
};

const growthHubSlice = createSlice({
  name: 'growthHubSlice',
  initialState,
  reducers: {
    resetUpdateAcademyProgressStatus: (state) => {
      state.updateAcademyTopicProgressRequest.status = RequestStatus.Ideal;
    },
  },
  extraReducers: (builder) => {
    // Get Academy Topics Request
    builder.addCase(getAcademyTopicsRequest.pending, (state) => {
      state.getAcademyTopicsRequest.status = RequestStatus.Pending;
    });
    builder.addCase(getAcademyTopicsRequest.fulfilled, (state, action) => {
      state.getAcademyTopicsRequest.status = RequestStatus.Succeeded;
      state.getAcademyTopicsResponse = action.payload.payload;
      state.getAcademyTopicsRequest.message = action.payload.message;
    });
    builder.addCase(getAcademyTopicsRequest.rejected, (state, action) => {
      state.getAcademyTopicsRequest.status = RequestStatus.Failed;
      state.getAcademyTopicsRequest.message = action.payload.message;
      state.getAcademyTopicsRequest.error =
        !action.payload.isHandled && action.payload;
    });

    // Get Academy Progress Request
    builder.addCase(getAcademyTopicsProgressRequest.pending, (state) => {
      state.getAcademyTopicsProgressRequest.status = RequestStatus.Pending;
    });
    builder.addCase(
      getAcademyTopicsProgressRequest.fulfilled,
      (state, action) => {
        state.getAcademyTopicsProgressRequest.status = RequestStatus.Succeeded;
        state.getAcademyTopicsProgressResponse = action.payload.payload;
        state.getAcademyTopicsProgressRequest.message = action.payload.message;
      },
    );
    builder.addCase(
      getAcademyTopicsProgressRequest.rejected,
      (state, action) => {
        state.getAcademyTopicsProgressRequest.status = RequestStatus.Failed;
        state.getAcademyTopicsProgressRequest.message = action.payload.message;
        state.getAcademyTopicsProgressRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );

    // Update Academy Progress
    builder.addCase(updateAcademyTopicProgressRequest.pending, (state) => {
      state.updateAcademyTopicProgressRequest.status = RequestStatus.Pending;
    });
    builder.addCase(
      updateAcademyTopicProgressRequest.fulfilled,
      (state, action) => {
        state.updateAcademyTopicProgressRequest.status =
          RequestStatus.Succeeded;
        state.updateAcademyTopicProgressRequest.message =
          action.payload.message;
      },
    );
    builder.addCase(
      updateAcademyTopicProgressRequest.rejected,
      (state, action) => {
        state.updateAcademyTopicProgressRequest.status = RequestStatus.Failed;
        state.updateAcademyTopicProgressRequest.message =
          action.payload.message;
        state.updateAcademyTopicProgressRequest.error =
          !action.payload.isHandled && action.payload;
      },
    );
  },
});

export const { resetUpdateAcademyProgressStatus } = growthHubSlice.actions;
export { getAcademyTopicsRequest };
export default growthHubSlice.reducer;
