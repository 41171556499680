import React from 'react';
import { DoNotContactList } from '../../../../../../../types/do-not-contact-list';

import { OverlayTooltip } from '../../../../../../../../../shared/design-system/components/overlay';

import { getUserTimeZone } from '../../../../../../../../../shared/utils/user-details';
import { getFormattedDate } from '../../../../../../users-and-teams/utils/get-formatted-date';
import { accessibleOnClick } from '../../../../../../../../../shared/utils/accessible-on-click';

import { Action } from '../../../../../../../../../shared/design-system/components/organisms/table/types';
import hasPermission from '../../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../../shared/utils/access-control/enums/permissions';

type ActionWithPermission = Action & {
  permission: string;
};

type IProps = {
  doNotContactList: DoNotContactList;
  actions: ActionWithPermission[];
  onAction: (key: string, row: DoNotContactList) => void;
};

const UpdatedAtField: React.FC<IProps> = ({
  doNotContactList,
  actions,
  onAction,
}) => {
  const userTimezone = getUserTimeZone();

  const updatedAtFiled = (
    <div className="text-cell last-login-cell">
      {getFormattedDate(doNotContactList.lastUpdatedAt, userTimezone)}
    </div>
  );

  if (
    hasPermission(Permissions.DNC_UPDATE) ||
    hasPermission(Permissions.DNC_DELETE)
  ) {
    return (
      <div className="hoverable-cell">
        <div className="hoverable-cell--content">{updatedAtFiled}</div>
        <div className="hoverable-cell--actions actionables">
          {actions.map(
            (action) =>
              hasPermission(action.permission) && (
                <OverlayTooltip text={action.displayName} key={action.key}>
                  <div
                    {...accessibleOnClick(() =>
                      onAction(action.key, doNotContactList),
                    )}
                    className="action-btn"
                  >
                    {action.iconElement}
                  </div>
                </OverlayTooltip>
              ),
          )}
        </div>
      </div>
    );
  }

  return updatedAtFiled;
};

export default UpdatedAtField;
