import toaster, { Theme } from '../../../../../../../shared/toaster';

export const getInviteUserMessage = (userInvitedCount: number) => {
  if (userInvitedCount === 0) {
    toaster.error('Failed to send invitations', {
      theme: Theme.New,
    });
    return;
  }
  if (userInvitedCount === 1) {
    toaster.success('Invitation sent to 1 user', {
      theme: Theme.New,
    });
    return;
  }
  if (userInvitedCount <= 10) {
    toaster.success(`Invitation sent to ${userInvitedCount} users`, {
      theme: Theme.New,
    });
    return;
  }

  toaster.success(`${userInvitedCount} users will be invited`, {
    theme: Theme.New,
  });
};
