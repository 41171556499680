import React from 'react';
import { Images } from '../../app-constants';
import ImageIcon from './image-icon';

const ProspectActivityEmailOpenedColored = () => (
  <ImageIcon
    src={Images.ProspectActivityEmailOpenedColored}
    alt="empty-data/"
  />
);

export default ProspectActivityEmailOpenedColored;
