import React, { useState, useEffect, useRef } from 'react';
import { Pills, Button } from '@saleshandy/design-system';
import { Cross } from '@saleshandy/icons';
import classNames from 'classnames';
import ContentEditable from 'react-contenteditable';
import Modal from '../../../../../../../shared/design-system/components/atoms/modal';
import Select from '../../../../../../../shared/design-system/components/select';
import {
  AddTeamPayload,
  MembersList,
  Team,
} from '../../../../../types/users-and-teams';
import Input from '../../../../../../../shared/design-system/components/input';
import { Placement } from '../../../../../../../shared/design-system/components/overlay';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import {
  capitalize,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../../shared/utils';
import { UserRole } from '../../../../../enums/users-and-teams';
import SearchInput from '../../search-input';
import { RequestStatus } from '../../../../../../../shared/enums/request-status';
import { useScreenSize } from '../../../../../../../shared/hooks/useScreenSize';

type TeamMembersType = MembersList & {
  key: string;
};

type IProps = {
  show: boolean;
  title: string;
  defaultValues: Team;
  membersList: MembersList[];
  onClose: VoidFunction;
  onSubmit: (payload: AddTeamPayload) => void;
  isLoading: boolean;
  buttonLabel: string;
  buttonLoadingText: string;
  getMembersListRequestStatus: RequestStatus;
  error: any;
  resetAddTeamRequest: VoidFunction;
  resetModifyTeamRequest: VoidFunction;
};

const AddModifyTeamModal: React.FC<IProps> = ({
  show,
  title,
  defaultValues,
  membersList,
  onClose,
  onSubmit,
  isLoading,
  buttonLabel,
  buttonLoadingText,
  getMembersListRequestStatus,
  error,
  resetAddTeamRequest,
  resetModifyTeamRequest,
}) => {
  const teamMembersSearchInput = useRef<HTMLInputElement>(null);
  const teamManagersSearchInput = useRef<HTMLDivElement>(null);

  // Define a threshold value for conditional rendering
  const SCREEN_HEIGHT_THRESHOLD = 630;
  const { screenHeight } = useScreenSize();

  const [assignTeamMemberDropdown, setAssignTeamMemberDropdown] = useState(
    false,
  );
  const [assignTeamManagerDropdown, setAssignTeamManagerDropdown] = useState(
    false,
  );
  const [membersOptions, setMembersOptions] = useState<TeamMembersType[]>([]);
  const [teamName, setTeamName] = useState('');
  const [teamNameError, setTeamNameError] = useState('');
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<
    TeamMembersType[]
  >([]);
  const [managersOptions, setManagersOptions] = useState<TeamMembersType[]>([]);
  const [selectedManagers, setSelectedManagers] = useState<TeamMembersType[]>(
    [],
  );
  const [teamMembersSearchText, setTeamMembersSearchText] = useState('');
  const [teamManagersSearchText, setTeamManagersSearchText] = useState('');
  const [isTeamManagersDivFocused, setIsTeamManagersDivFocused] = useState(
    false,
  );

  const onAssignTeamMemberDropdownToggle = (value: boolean) => {
    setAssignTeamMemberDropdown(value);
    if (value && teamMembersSearchInput?.current) {
      teamMembersSearchInput.current.focus();
    }
  };

  const onAssignTeamMemberSearchChange = (e) => {
    setTeamMembersSearchText(e.target.value);
    if (!assignTeamMemberDropdown) {
      setAssignTeamMemberDropdown(true);
    }
  };

  const onAssignTeamManagerDropdownToggle = (value: boolean) => {
    setAssignTeamManagerDropdown(value);
    if (value && teamManagersSearchInput?.current) {
      teamManagersSearchInput.current.focus();
    }
    if (isTeamManagersDivFocused && !value) {
      setIsTeamManagersDivFocused(false);
      setTeamManagersSearchText('');
    }
  };

  const onAssignTeamManagerSearchChange = (e) => {
    setTeamManagersSearchText(e.target.value);
    if (!assignTeamManagerDropdown) {
      setAssignTeamManagerDropdown(true);
    }
  };

  const onTeamNameChange = (value: string) => {
    if (value?.length > 30) {
      setTeamNameError('Length should be less than 30 characters');
    } else if (teamNameError) {
      setTeamNameError('');
    }
    setTeamName(value);

    if (error) {
      resetAddTeamRequest();
      resetModifyTeamRequest();
    }
  };

  const onMemberRemove = (id: number) => {
    setSelectedTeamMembers(
      selectedTeamMembers.filter((member) => member.id !== id),
    );
    setSelectedManagers(selectedManagers.filter((member) => member.id !== id));
  };

  const onManagerRemove = (id: number) => {
    setTimeout(() => {
      setAssignTeamManagerDropdown(false);
    }, 10);

    setSelectedManagers(selectedManagers.filter((member) => member.id !== id));
  };

  const onCreateOrModifyTeam = () => {
    const managerIds = selectedManagers?.map((m) => m.id);
    const userIds = selectedTeamMembers
      ?.filter((m) => !managerIds.includes(m.id))
      ?.map((m) => m.id);

    onSubmit({
      name: teamName,
      userIds: userIds || [],
      managerIds: managerIds || [],
    });
  };

  const getUserRole = (roles: string[]): string => {
    if (roles.includes(UserRole.Owner)) {
      return capitalize(UserRole.Owner);
    }
    if (roles.includes(UserRole.Admin)) {
      return capitalize(UserRole.Admin);
    }
    if (roles.includes(UserRole.TeamManager)) {
      return capitalize(UserRole.TeamManager);
    }

    return capitalize(UserRole.Member);
  };

  const getTeamNameErrorMessage = (): string => {
    if (teamNameError) {
      return teamNameError;
    }
    if (error && error.code === 1001) {
      return error.message;
    }
    return '';
  };

  useEffect(() => {
    if (show && defaultValues) {
      setTeamName(defaultValues.name);
      setTeamNameError('');
      setSelectedTeamMembers(
        defaultValues.users.map((user) => ({
          key: user.id.toString(),
          id: user.id,
          firstName: user.name?.split(' ')?.[0]?.trim(),
          lastName: user.name?.split(' ')?.[1].trim(),
          email: user.email,
          role: getUserRole(user.role),
        })) || [],
      );

      setSelectedManagers(
        defaultValues.users
          .filter((user) =>
            user.role
              .filter((role) => role.toLowerCase())
              .includes(UserRole.TeamManager),
          )
          .map((user) => ({
            key: user.id.toString(),
            id: user.id,
            firstName: user.name?.split(' ')?.[0]?.trim(),
            lastName: user.name?.split(' ')?.[1].trim(),
            email: user.email,
            role: getUserRole(user.role),
          })) || [],
      );
    }

    if (!show) {
      setTeamName('');
      setTeamNameError('');
      setSelectedTeamMembers([]);
      setSelectedManagers([]);
      setTeamMembersSearchText('');
      setTeamManagersSearchText('');
      setIsTeamManagersDivFocused(false);
    }
  }, [show]);

  useEffect(() => {
    if (show) {
      setManagersOptions(selectedTeamMembers || []);
    }
  }, [show, selectedTeamMembers]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getMembersListRequestStatus,
      onSuccess: () => {
        const mappedMembersList =
          membersList?.map((team) => ({
            key: team.id.toString(),
            ...team,
          })) || [];

        const newMembers = [];

        if (defaultValues) {
          defaultValues.users.forEach((user) => {
            const isExist = mappedMembersList.find((u) => u.id === user.id);

            if (!isExist) {
              newMembers.push({
                key: user.id.toString(),
                id: user.id,
                firstName: user.name?.split(' ')?.[0]?.trim(),
                lastName: user.name?.split(' ')?.[1].trim(),
                email: user.email,
                role: getUserRole(user.role),
              });
            }
          });
        }
        setMembersOptions([...mappedMembersList, ...newMembers]);
      },
    });
  }, [getMembersListRequestStatus]);

  const pillsClassName = classNames([
    'email-input--pills',
    {
      'p-0': !selectedTeamMembers || selectedTeamMembers?.length === 0,
    },
  ]);

  return (
    <Modal
      show={show}
      className="invite-user-modal"
      hideHeader
      hideFooter
      backdrop="static"
      extraModalProps={{
        enforceFocus: false,
      }}
    >
      <div
        className="invite-user-modal--close-btn"
        {...accessibleOnClick(onClose)}
      >
        <Cross />
      </div>

      <div className="invite-user-modal--content">
        <div className="invite-user-modal--wrapper">
          <h1 className="invite-user-modal--title">{title}</h1>

          <Input
            name="teamName"
            label="Team Name"
            placeholder="Enter Team Name"
            className="invite-user-modal--txt-input"
            value={teamName}
            onChange={onTeamNameChange}
            tabIndex={0}
            autoFocus
            autoComplete="current-teamName"
            caption={getTeamNameErrorMessage()}
            variant={getTeamNameErrorMessage() && Input.Variant.Error}
          />

          <div className="invite-user-modal--container team-members-input">
            <p className="user-action-label">Team Members</p>

            <div className="email-input--container">
              <div className={pillsClassName}>
                {selectedTeamMembers &&
                  selectedTeamMembers?.length > 0 &&
                  selectedTeamMembers?.map(
                    (item) =>
                      item && (
                        <Pills
                          theme="outline"
                          size="md"
                          label={
                            `${item.firstName} ${item.lastName}`.trim() ||
                            item?.email
                          }
                          showCloseIcon={true}
                          onClose={() => onMemberRemove(item.id)}
                          key={item.id}
                        />
                      ),
                  )}
              </div>

              <Select<TeamMembersType>
                className="users-change-role-select team-select"
                options={membersOptions}
                selectedOptionKey={
                  selectedTeamMembers?.map((item) =>
                    item ? item?.id?.toString() : '',
                  ) || []
                }
                selectedOptionRenderer={() => null}
                onChange={(options) => {
                  if (teamMembersSearchText) {
                    setTeamMembersSearchText('');
                  }
                  teamMembersSearchInput?.current?.focus();
                  setSelectedTeamMembers(options);
                }}
                optionRenderer={(option) => {
                  const name = `${option?.firstName} ${option?.lastName}`.trim();

                  return (
                    <span className="blue-txt-15">{name || option?.email}</span>
                  );
                }}
                multiSelect
                filterOption={(_, option) =>
                  option?.firstName
                    ?.toLowerCase()
                    .includes(teamMembersSearchText.toLowerCase()) ||
                  option?.lastName
                    ?.toLowerCase()
                    .includes(teamMembersSearchText.toLowerCase()) ||
                  option?.email
                    ?.toLowerCase()
                    .includes(teamMembersSearchText.toLowerCase())
                }
                placeholder={
                  <SearchInput
                    ref={teamMembersSearchInput}
                    className="w-100"
                    placeholder="Add Members"
                    value={teamMembersSearchText}
                    onChange={onAssignTeamMemberSearchChange}
                  />
                }
                show={assignTeamMemberDropdown}
                onToggle={onAssignTeamMemberDropdownToggle}
                isLoading={getIsRequestPending(getMembersListRequestStatus)}
                emptyText="No Team Member Found"
              />
            </div>
          </div>

          <div className="invite-user-modal--container">
            <p className="user-action-label">Team Manager</p>
            <Select<TeamMembersType>
              className="users-change-role-select"
              options={managersOptions}
              selectedOptionKey={
                selectedManagers?.map((item) => item?.id?.toString()) || []
              }
              selectedOptionRenderer={() => (
                <div className="email-input--pills">
                  {selectedManagers?.map(
                    (item) =>
                      item && (
                        <Pills
                          theme="outline"
                          size="md"
                          label={
                            `${item.firstName} ${item.lastName}`.trim() ||
                            item?.email
                          }
                          showCloseIcon={true}
                          onClose={() => onManagerRemove(item.id)}
                          key={item.id}
                        />
                      ),
                  )}
                  <ContentEditable
                    innerRef={teamManagersSearchInput}
                    html={teamManagersSearchText}
                    disabled={false}
                    onChange={onAssignTeamManagerSearchChange}
                    className="select-search"
                    onBlur={() => {
                      if (isTeamManagersDivFocused) {
                        setIsTeamManagersDivFocused(false);
                      }
                      setTeamManagersSearchText('');
                    }}
                  />
                </div>
              )}
              onChange={(options) => {
                if (teamManagersSearchText) {
                  setTeamManagersSearchText('');
                }
                teamManagersSearchInput?.current?.focus();
                setSelectedManagers(options);
              }}
              optionRenderer={(option) => {
                const name = `${option?.firstName} ${option?.lastName}`.trim();

                return (
                  <span className="blue-txt-15">{name || option?.email}</span>
                );
              }}
              multiSelect
              filterOption={(_, option) =>
                option?.firstName
                  ?.toLowerCase()
                  .includes(teamManagersSearchText.toLowerCase()) ||
                option?.lastName
                  ?.toLowerCase()
                  .includes(teamManagersSearchText.toLowerCase()) ||
                option?.email
                  ?.toLowerCase()
                  .includes(teamManagersSearchText.toLowerCase())
              }
              placeholder={
                <SearchInput
                  className="w-100"
                  placeholder="Add Members"
                  value={
                    isTeamManagersDivFocused ? teamManagersSearchText : 'Select'
                  }
                  onChange={onAssignTeamManagerSearchChange}
                />
              }
              placement={
                screenHeight < SCREEN_HEIGHT_THRESHOLD
                  ? Placement.Top
                  : Placement.Bottom
              }
              show={assignTeamManagerDropdown}
              onToggle={onAssignTeamManagerDropdownToggle}
              emptyText={
                teamManagersSearchText
                  ? 'No Team Manager Found'
                  : 'Add members to appoint a team manager'
              }
            />
          </div>
        </div>

        <Button
          isFullWidth
          onClick={onCreateOrModifyTeam}
          isLoading={isLoading}
          disabled={isLoading || teamName?.length === 0 || teamNameError !== ''}
          loadingText={buttonLoadingText}
        >
          {buttonLabel}
        </Button>
      </div>
    </Modal>
  );
};

export default AddModifyTeamModal;
