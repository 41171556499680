import React from 'react';

export const MailOpen = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.58397 1.62596C8.8359 1.45801 9.1641 1.45801 9.41603 1.62596L16.166 6.12596C16.3747 6.26506 16.5 6.49924 16.5 6.75C16.5 7.00076 16.3747 7.23494 16.166 7.37404L9.41603 11.874C9.1641 12.042 8.8359 12.042 8.58397 11.874L1.83397 7.37404C1.62533 7.23494 1.5 7.00076 1.5 6.75C1.5 6.49924 1.62533 6.26506 1.83397 6.12596L8.58397 1.62596ZM3.60208 6.75L9 10.3486L14.3979 6.75L9 3.15139L3.60208 6.75Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.25 6C2.66421 6 3 6.33579 3 6.75V14.25C3 14.4489 3.07902 14.6397 3.21967 14.7803C3.36032 14.921 3.55109 15 3.75 15H14.25C14.4489 15 14.6397 14.921 14.7803 14.7803C14.921 14.6397 15 14.4489 15 14.25V6.75C15 6.33579 15.3358 6 15.75 6C16.1642 6 16.5 6.33579 16.5 6.75V14.25C16.5 14.8467 16.2629 15.419 15.841 15.841C15.419 16.2629 14.8467 16.5 14.25 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V6.75C1.5 6.33579 1.83579 6 2.25 6Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28033 9.22553C7.57322 9.51842 7.57322 9.9933 7.28033 10.2862L2.78033 14.7862C2.48744 15.0791 2.01256 15.0791 1.71967 14.7862C1.42678 14.4933 1.42678 14.0184 1.71967 13.7255L6.21967 9.22553C6.51256 8.93264 6.98744 8.93264 7.28033 9.22553Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7197 9.22553C11.0126 8.93264 11.4874 8.93264 11.7803 9.22553L16.2803 13.7255C16.5732 14.0184 16.5732 14.4933 16.2803 14.7862C15.9874 15.0791 15.5126 15.0791 15.2197 14.7862L10.7197 10.2862C10.4268 9.9933 10.4268 9.51842 10.7197 9.22553Z"
      fill="currentColor"
    />
  </svg>
);
