import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';
import { IProps } from './types';
import Suspense from '../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../shared/components/error-boundary-wrapper';
import retryLazy from '../retry-lazy';

const ProspectList = React.lazy(() =>
  retryLazy(() => import('./components/prospect-list')),
);

const Prospect: React.FC<IProps> = () => (
  <Container fluid className="prospect-container p-0">
    <ErrorBoundaryWrapper>
      <Suspense>
        <Switch>
          <Route exact path="/prospects" component={ProspectList} />
          <Redirect to="/prospects" />
        </Switch>
      </Suspense>
    </ErrorBoundaryWrapper>
  </Container>
);

export default Prospect;
