import React from 'react';

export const SHLogoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="31"
    viewBox="0 0 32 31"
    fill="none"
  >
    <g clipPath="url(#clip0_33407_3465)">
      <path
        d="M8.8103 0C7.66994 0 7.09885 1.3606 7.9052 2.15635L29.1351 23.1068C29.9414 23.9026 31.3202 23.339 31.3202 22.2136V1.26316C31.3202 0.565535 30.7471 0 30.0401 0H8.8103Z"
        fill="url(#paint0_linear_33407_3465)"
      />
      <path
        d="M15.6013 27.4354C16.2061 28.0322 17.2401 27.6095 17.2401 26.7655V14.8421C17.2401 14.3189 16.8103 13.8947 16.2801 13.8947H4.19774C3.34247 13.8947 2.91415 14.9152 3.51892 15.512L15.6013 27.4354Z"
        fill="url(#paint1_linear_33407_3465)"
      />
      <path
        d="M7.18756 29.2376C7.59073 29.6355 8.28011 29.3537 8.28011 28.791V23.3684C8.28011 23.0196 7.99357 22.7368 7.64011 22.7368H2.14519C1.57501 22.7368 1.28946 23.4171 1.69264 23.815L7.18756 29.2376Z"
        fill="url(#paint2_linear_33407_3465)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_33407_3465"
        x1="7.09445"
        y1="24.6425"
        x2="31.0142"
        y2="1.52246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_33407_3465"
        x1="7.09445"
        y1="24.6425"
        x2="31.0142"
        y2="1.52246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_33407_3465"
        x1="7.09445"
        y1="24.6425"
        x2="31.0142"
        y2="1.52246"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
      <clipPath id="clip0_33407_3465">
        <rect
          width="30.7201"
          height="30.3158"
          fill="white"
          transform="translate(0.599609)"
        />
      </clipPath>
    </defs>
  </svg>
);
