import React from 'react';
import { GlobalSettings } from '../../../../../shared/components/global-settings-wrapper';

const UsersAndTeamsHeader: React.FC = () => (
  <GlobalSettings.Header>
    <GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderTitle title="Users & Teams" />
    </GlobalSettings.HeaderLeft>
  </GlobalSettings.Header>
);

export default UsersAndTeamsHeader;
