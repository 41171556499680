import React from 'react';
import { ImageIcon } from '@saleshandy/design-system';
import {
  OverlayTooltip,
  Placement,
} from '../../design-system/components/overlay';
import { Images } from '../../app-constants';
import type { IProps } from './types';
import { getUserPlanCode } from '../../utils/user-details';
import { SubscriptionPlans } from '../../utils/subscription-plans';

const PremiumFeatureIndicator: React.FC<IProps> = ({
  show = false,
  tooltipTxt,
  placement,
  className,
  tooltipClassname,
}) => {
  const tooltipText = () =>
    getUserPlanCode() === SubscriptionPlans.TRIAL
      ? 'This is a premium feature that is available on PRO & higher plans. You can try it for free in your trial.'
      : "This is a premium feature that is available on PRO & higher plans. It's not included in your current plan.";

  const isPremiumIconVisible = () =>
    getUserPlanCode() === SubscriptionPlans.TRIAL ||
    getUserPlanCode() === SubscriptionPlans.OUTREACH_BASIC_MONTHLY ||
    getUserPlanCode() === SubscriptionPlans.OUTREACH_BASIC_YEARLY ||
    getUserPlanCode() === SubscriptionPlans.OUTREACH_BASIC_MONTHLY_8_2023 ||
    getUserPlanCode() === SubscriptionPlans.OUTREACH_BASIC_YEARLY_8_2023;

  if (isPremiumIconVisible() || show) {
    return (
      <OverlayTooltip
        className={tooltipClassname}
        text={tooltipTxt || tooltipText()}
        placement={placement || Placement.BottomStart}
      >
        <ImageIcon
          src={Images.PremiumFeature}
          alt="premium feature"
          className={className}
        />
      </OverlayTooltip>
    );
  }

  return null;
};

export default PremiumFeatureIndicator;
