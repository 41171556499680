import React from 'react';

export const ArrowsMinimize = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.6463 5.17537C13.5291 5.29253 13.3392 5.29253 13.222 5.17537L12.1788 4.13214C11.9898 3.94315 11.6666 4.077 11.6666 4.34427V8.03334C11.6666 8.19902 11.801 8.33334 11.9666 8.33334H15.6557C15.923 8.33334 16.0568 8.0102 15.8678 7.82121L14.8246 6.77797C14.7074 6.66081 14.7074 6.47086 14.8246 6.35371L17.0437 4.13464C17.1608 4.01748 17.1608 3.82753 17.0437 3.71037L16.2896 2.9563C16.1724 2.83915 15.9825 2.83915 15.8653 2.9563L13.6463 5.17537ZM4.13461 2.9563C4.01745 2.83915 3.8275 2.83915 3.71034 2.9563L2.95627 3.71037C2.83912 3.82753 2.83912 4.01748 2.95627 4.13464L5.17534 6.35371C5.2925 6.47086 5.2925 6.66081 5.17534 6.77797L4.13211 7.82121C3.94312 8.0102 4.07697 8.33334 4.34424 8.33334H8.03331C8.19899 8.33334 8.33331 8.19902 8.33331 8.03334V4.34427C8.33331 4.077 8.01016 3.94315 7.82118 4.13214L6.77794 5.17537C6.66078 5.29253 6.47083 5.29253 6.35368 5.17537L4.13461 2.9563ZM15.8678 12.1788C16.0568 11.9898 15.923 11.6667 15.6557 11.6667H11.9666C11.801 11.6667 11.6666 11.801 11.6666 11.9667V15.6557C11.6666 15.923 11.9898 16.0569 12.1788 15.8679L13.222 14.8246C13.3392 14.7075 13.5291 14.7075 13.6463 14.8246L15.8653 17.0437C15.9825 17.1609 16.1724 17.1609 16.2896 17.0437L17.0437 16.2896C17.1608 16.1725 17.1608 15.9825 17.0437 15.8654L14.8246 13.6463C14.7074 13.5291 14.7074 13.3392 14.8246 13.222L15.8678 12.1788ZM5.17534 13.222C5.2925 13.3392 5.2925 13.5291 5.17534 13.6463L2.95627 15.8654C2.83912 15.9825 2.83912 16.1725 2.95627 16.2896L3.71034 17.0437C3.8275 17.1609 4.01745 17.1609 4.13461 17.0437L6.35368 14.8246C6.47083 14.7075 6.66078 14.7075 6.77794 14.8246L7.82118 15.8679C8.01017 16.0569 8.33331 15.923 8.33331 15.6557V11.9667C8.33331 11.801 8.19899 11.6667 8.03331 11.6667H4.34424C4.07697 11.6667 3.94312 11.9898 4.13211 12.1788L5.17534 13.222Z"
      fill="#6B7280"
    />
  </svg>
);
