import React from 'react';

import Modal from '../../atoms/modal';
import type { IProps } from './types';

const YoutubeVideoModal: React.FC<IProps> = ({
  show,
  titleContent = 'Video tutorial',
  youtubeVideoUrl,
  onClose,
}) => (
  <Modal
    show={show}
    titleContent={titleContent}
    className="youtube-video-modal"
    showCloseIcon
    hideFooter
    onClose={onClose}
    onHide={onClose}
  >
    <iframe
      width="560"
      height="315"
      src={youtubeVideoUrl}
      title="Saleshandy Video Tutorial"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </Modal>
);

export default YoutubeVideoModal;
