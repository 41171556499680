import api from '../../../api';
import type { ResponseSuccess } from '../../../shared/types';
import {
  CreateAgencyClientRequestPayload,
  UpdateAgencyClientRequestPayload,
  AssignAgencyClientResourceRequestPayload,
  GetAgencyClientDropdownRequestPayload,
  GetSelectedAgencyClientRequestPayload,
} from '../types/request-payload';
import type { ClientParams } from '../types/types';

// get clients list for dropdown
export const agencyClientsDropdown = async (
  payload: GetAgencyClientDropdownRequestPayload,
) =>
  api.get<ResponseSuccess>(`/client/list`, {
    params: {
      ...(payload.search && { search: payload.search }),
      ...(payload.status && { status: payload.status }),
    },
  });

// get selected client for resourceType
export const selectedAgencyClientsDropdown = async (
  payload: GetSelectedAgencyClientRequestPayload,
) =>
  api.get<ResponseSuccess>(
    `/client/${payload.resourceId}/${payload.resourceType}/connected-client`,
  );

// assign resource to client
export const assignAgencyClientResource = async (
  payload: AssignAgencyClientResourceRequestPayload,
) =>
  api.post<ResponseSuccess>(`/client/assign/${payload.resourceType}`, {
    resourceIds: payload.resourceIds,
    clientId: payload.clientId,
  });

// Get Agency Clients
export const getAgencyClients = async (params: ClientParams) =>
  api.get<ResponseSuccess>('/client', { params });

// Create Agency Client
export const createAgencyClient = async (
  payload: CreateAgencyClientRequestPayload,
) => api.post<ResponseSuccess>('/client', { ...payload });

// Update Agency Client
export const updateAgencyClient = async ({
  clientId,
  ...payload
}: UpdateAgencyClientRequestPayload) =>
  api.patch<ResponseSuccess>(`/client/${clientId}`, { ...payload });

// Delete Agency Client
export const deleteAgencyClient = async (clientId: string) =>
  api.delete<ResponseSuccess>(`/client/${clientId}`);

// Toggle Agency Client Status
export const toggleClientStatus = async (clientId: number) =>
  api.post<ResponseSuccess>(`/client/${clientId}/toggle-status`);

// Get Agency Client Active Sequences
export const getAgencyClientActiveSequences = async (clientId: string) =>
  api.get<ResponseSuccess>(`/client/sequences/${clientId}`);

// Send Agency Client Reset Password
export const agencyClientResetPassword = async (clientId: string) =>
  api.patch<ResponseSuccess>(`/client/reset-password/${clientId}`);
