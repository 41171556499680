import React, { useRef } from 'react';
import { Pills } from '@saleshandy/design-system';
import classNames from 'classnames';
import { accessibleOnClick } from '../../../../../shared/utils/accessible-on-click';

type IProps = {
  value: string;
  emails: string[];
  onEmailRemove: (value: string) => void;
  handleOnChange: (value: string) => void;
  onAction: (action: string) => void;
  isEmailError: boolean;
};

const EmailInput: React.FC<IProps> = ({
  value = '',
  emails,
  onEmailRemove,
  handleOnChange,
  onAction,
  isEmailError,
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const isMaxedEmailAdded = emails.length > 50;

  const onContainerClicked = () => {
    if (emailInputRef?.current) {
      emailInputRef.current.focus();
    }
  };

  const onKeyPress = (e) => {
    onAction(e.key);
  };

  const onPaste = () => {
    onAction('Paste');
  };
  const onBlur = () => {
    onAction('Blur');
  };

  const classes = classNames([
    'email-input--container',
    {
      error: isMaxedEmailAdded || isEmailError,
    },
  ]);

  return (
    <div>
      <div className={classes} {...accessibleOnClick(onContainerClicked)}>
        <div className="email-input--pills">
          {emails?.map(
            (email) =>
              email && (
                <Pills
                  theme="outline"
                  size="md"
                  label={email}
                  showCloseIcon={true}
                  onClose={() => onEmailRemove(email)}
                  key={email}
                />
              ),
          )}

          <input
            className="email-input--input"
            value={value}
            placeholder="Enter Email Addresses, separated by commas or pressing 'Enter'"
            onChange={(e) => handleOnChange(e?.target?.value)}
            ref={emailInputRef}
            onKeyPress={onKeyPress}
            onPaste={onPaste}
            onBlur={onBlur}
          />
        </div>
      </div>
      <div className="email-input--helper-wrapper">
        <span className="email-input--error-msg">
          {isMaxedEmailAdded &&
            'You can invite maximum 50 email addresses at once'}
          {!isMaxedEmailAdded && isEmailError && 'Invalid email address(es)'}
        </span>
        <span className="email-input--count">{emails.length}/50</span>
      </div>
    </div>
  );
};

export default EmailInput;
