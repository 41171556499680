import React from 'react';

export const VerifyEmail = () => (
  <svg
    width="110"
    height="110"
    viewBox="0 0 110 110"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M55 110C85.3757 110 110 85.3757 110 55C110 24.6243 85.3757 0 55 0C24.6243 0 0 24.6243 0 55C0 85.3757 24.6243 110 55 110Z"
      fill="url(#paint0_linear_10122_247249)"
    />
    <path
      d="M88.002 110H22.002V38.8664C25.1128 38.8629 28.0951 37.6256 30.2948 35.4259C32.4945 33.2263 33.7318 30.2439 33.7353 27.1331H76.2687C76.2654 28.6741 76.5677 30.2004 77.1582 31.6238C77.7487 33.0472 78.6156 34.3394 79.7087 35.4256C80.7949 36.519 82.0871 37.3862 83.5107 37.9768C84.9342 38.5675 86.4608 38.8698 88.002 38.8664V110Z"
      fill="white"
    />
    <path
      d="M54.9965 74.8003C64.7167 74.8003 72.5965 66.9205 72.5965 57.2003C72.5965 47.4801 64.7167 39.6003 54.9965 39.6003C45.2763 39.6003 37.3965 47.4801 37.3965 57.2003C37.3965 66.9205 45.2763 74.8003 54.9965 74.8003Z"
      fill="#10B981"
    />
    <g clipPath="url(#clip0_10122_247249)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.8549 53.7541C63.1413 54.0404 63.1413 54.5048 62.8549 54.7912L55.5216 62.1245C55.2352 62.4109 54.7709 62.4109 54.4845 62.1245L50.8178 58.4578C50.5314 58.1714 50.5314 57.7071 50.8178 57.4207C51.1042 57.1343 51.5685 57.1343 51.8549 57.4207L55.003 60.5689L61.8178 53.7541C62.1042 53.4677 62.5685 53.4677 62.8549 53.7541Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.1884 53.7541C59.4748 54.0404 59.4748 54.5048 59.1884 54.7912L55.5217 58.4578C55.2354 58.7442 54.771 58.7442 54.4846 58.4578C54.1983 58.1714 54.1983 57.7071 54.4846 57.4207L58.1513 53.7541C58.4377 53.4677 58.902 53.4677 59.1884 53.7541ZM47.1513 57.4207C47.4377 57.1343 47.902 57.1343 48.1884 57.4207L51.8551 61.0874C52.1415 61.3738 52.1415 61.8381 51.8551 62.1245C51.5687 62.4109 51.1044 62.4109 50.818 62.1245L47.1513 58.4578C46.8649 58.1714 46.8649 57.7071 47.1513 57.4207Z"
        fill="white"
      />
    </g>
    <path
      d="M64.5343 79.2H45.4676C44.2526 79.2 43.2676 80.1849 43.2676 81.4C43.2676 82.615 44.2526 83.6 45.4676 83.6H64.5343C65.7493 83.6 66.7343 82.615 66.7343 81.4C66.7343 80.1849 65.7493 79.2 64.5343 79.2Z"
      fill="#EFF6FF"
    />
    <path
      d="M71.1366 88.0005H38.8699C37.6549 88.0005 36.6699 88.9855 36.6699 90.2005C36.6699 91.4155 37.6549 92.4005 38.8699 92.4005H71.1366C72.3517 92.4005 73.3366 91.4155 73.3366 90.2005C73.3366 88.9855 72.3517 88.0005 71.1366 88.0005Z"
      fill="#EFF6FF"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10122_247249"
        x1="55"
        y1="0"
        x2="55"
        y2="110"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF6FF" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <clipPath id="clip0_10122_247249">
        <rect
          width="17.6"
          height="17.6"
          fill="white"
          transform="translate(46.2031 49.1338)"
        />
      </clipPath>
    </defs>
  </svg>
);
