import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect, Route, Switch } from 'react-router-dom';

import history from '../../../../shared/history';
import Suspense from '../../../../shared/components/suspense';
import ErrorBoundaryWrapper from '../../../../shared/components/error-boundary-wrapper';

import WebhookList from './components/webhook-list';
import AddWebhook from './components/add-webhook';
import WebhookDetailAndLogs from './components/webhook-details-and-logs';
import hasPermission from '../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../shared/utils/access-control/enums/permissions';

const Webhook: React.FC = () => {
  if (!hasPermission(Permissions.WEBHOOKS_SHOW)) {
    history.push('/settings/');
    return <></>;
  }

  return (
    <Container fluid className="p-0 d-flex flex-column overflow-hidden h-100">
      <ErrorBoundaryWrapper>
        <Suspense>
          <Switch>
            <Route exact path="/settings/webhook" component={WebhookList} />
            <Route exact path="/settings/webhook/add" component={AddWebhook} />
            <Route
              exact
              path="/settings/webhook/:webhookId/:tab"
              component={WebhookDetailAndLogs}
            />
            <Route
              exact
              path="/settings/webhook/:webhookId"
              component={WebhookDetailAndLogs}
            />
            <Redirect to="/settings/webhook" />
          </Switch>
        </Suspense>
      </ErrorBoundaryWrapper>
    </Container>
  );
};

export default Webhook;
