import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { SkeletonLoading } from '@saleshandy/design-system';
import { isEmpty } from 'lodash';

import api from '../../../../../../api';
import type { IProps } from './do-not-contact-list-details-container';
import { DoNoContactValueType } from '../../../../enums/do-not-contact-list';
import {
  DoNotContactList,
  DoNotContactListDetailsFilter,
  DoNotContactListEmailsAndDomains,
} from '../../../../types/do-not-contact-list';

import { NoDNCListFound } from '../../../../../../shared/svg';
import toaster from '../../../../../../shared/toaster';
import Select from '../../../../../../shared/design-system/components/select';
import DeleteButton from '../../../../../../shared/design-system/components/atoms/delete-button';
import ExportButton from '../../../../../../shared/design-system/components/atoms/export-button/export-button';

import AddModifyDoNotContactList from '../modals/add-modify-do-not-contact-list';
import DeleteDoNotContactList from '../modals/delete-do-not-contact-list';
import DoNotContactListDetailsHeader from './components/do-not-contact-list-details-header';
import DoNotContactListDetailsInput from './components/do-not-contact-list-details-input';
import DoNotContactListDetailsTable from './components/do-not-contact-list-details-table';
import ImportEmailsDomainsViaCsv from '../modals/import-emails-domains-via-csv';

import { getListAssociatedByName } from '../../helper/get-list-associated-by-name';
import {
  executeOnErrorWithErrorCheck,
  executeOnRequestStatus,
  getIsRequestPending,
} from '../../../../../../shared/utils';
import DoNotContactListDetailsSearch from './components/do-not-contact-list-details-search';
import DoNotContactListEmptyUI from '../do-not-contact-list/components/do-not-contact-list-empty-ui/do-not-contact-list-empty-ui';
import hasPermission from '../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../shared/utils/access-control/enums/permissions';
import {
  getEntriesMessage,
  getEntriesTitle,
} from '../../helper/get-entries-message';
import {
  isWhiteLabel,
  isWhitelabelWithClientView,
} from '../../../../../../shared/utils/user-details';

const filterOptions = [
  {
    key: DoNoContactValueType.ALL,
    label: 'All',
  },
  {
    key: DoNoContactValueType.DOMAIN,
    label: 'Domains',
  },
  {
    key: DoNoContactValueType.EMAIL,
    label: 'Emails',
  },
];

const DoNotContactListDetails: React.FC<IProps> = ({
  doNotContactListDetailsMeta,
  doNotContactListDetails,
  doNotContactListDetailsPaginationOptions,

  sendGetDoNotContactListDetailsMetaRequest,
  getDoNotContactListDetailsMetaRequestStatus,
  getDoNotContactListDetailsMetaRequestError,
  resetDoNotContactListDetailsMeta,

  getDoNotContactListDetailsRequestStatus,
  getDoNotContactListDetailsRequestError,
  sendGetDoNotContactListDetailsRequest,
  resetGetDoNotContactListDetailsRequest,

  updateDoNotContactListRequestStatus,
  updateDoNotContactListRequestError,
  sendUpdateDoNotContactListRequest,
  resetUpdateDoNotContactListRequest,

  updateDoNotContactListDetailsRequestStatus,

  agencyClients,
  sendGetAgencyClientsDropdownRequest,

  sendDeleteDoNotContactListEmailsAndDomainsRequest,
  resetDeleteDoNotContactListEmailsAndDomainsRequest,
  deleteDoNotContactListEmailsAndDomainsRequestStatus,
  deleteDoNotContactListEmailsAndDomainsRequestError,

  sendImportDoNotContactListEmailsAndDomainsRequest,
  resetImportDoNotContactListEmailsAndDomainsRequest,
  importDoNotContactListEmailsAndDomainsRequestStatus,
  importDoNotContactListEmailsAndDomainsRequestError,

  sendBulkDeleteDoNotContactListEmailsAndDomainsRequest,
  resetBulkDeleteDoNotContactListEmailsAndDomainsRequest,
  bulkDeleteDoNotContactListEmailsAndDomainsRequestStatus,
  bulkDeleteDoNotContactListEmailsAndDomainsRequestError,

  selectedDNCListEmailsAndDomains,
  bulkSelectedDNCListEmailsAndDomains,
  clearDNCListEmailsAndDomainsSelection,
  resetSelectedDNCListEmailsAndDomains,
}) => {
  const history = useHistory();
  const location = useLocation<{ row: DoNotContactList }>();
  const { row } = location.state || {};

  const { listId: doNotContactListId } = useParams<{ listId: string }>();

  const [dncListRow, setDncListRow] = useState<DoNotContactList>(row);

  const [filters, setFilters] = useState<DoNotContactListDetailsFilter>({
    page: 1,
    limit: 25,
    search: '',
    type: filterOptions[0].key,
  });

  const [resetSelected, setResetSelected] = useState(false);

  const [
    selectedEmailOrDomain,
    setSelectedEmailOrDomain,
  ] = useState<DoNotContactListEmailsAndDomains>(null);
  const [
    modifyDoNotContactListModal,
    setModifyDoNotContactListModal,
  ] = useState<boolean>(false);
  const [
    deleteDoNotContactListModal,
    setDeleteDoNotContactListModal,
  ] = useState<boolean>(false);
  const [
    importDoNotContactListModal,
    setImportDoNotContactListModal,
  ] = useState(false);

  const onShowModifyDoNotContactListModal = () => {
    setModifyDoNotContactListModal(true);
  };

  const onHideModifyDoNotContactListModal = () => {
    setModifyDoNotContactListModal(false);
  };

  const onShowDeleteDoNotContactListModal = () => {
    setDeleteDoNotContactListModal(true);
  };

  const onHideDeleteDoNotContactListModal = () => {
    setDeleteDoNotContactListModal(false);
    setSelectedEmailOrDomain(null);
  };

  const onShowImportDoNotContactListModal = () => {
    setImportDoNotContactListModal(true);
  };

  const onHideImportDoNotContactListModal = () => {
    setImportDoNotContactListModal(false);
  };

  const handleResetSelected = (value = false) => {
    setResetSelected(value);
  };

  const onFiltersChange = (
    partialFilters: Partial<DoNotContactListDetailsFilter>,
    resetSelection = false,
  ) => {
    const updatedFilters = {
      ...filters,
      ...partialFilters,
    };

    setFilters(updatedFilters);
    sendGetDoNotContactListDetailsRequest({
      id: doNotContactListId,
      ...updatedFilters,
    });

    if (resetSelection) {
      clearDNCListEmailsAndDomainsSelection();
      handleResetSelected(true);
    }
  };

  const onDeleteEmailsAndDomains = () => {
    sendDeleteDoNotContactListEmailsAndDomainsRequest({
      dncListId: doNotContactListId,
      emailDomainsIds: [selectedEmailOrDomain.id],
    });
  };

  const onBulkDeleteEmailsAndDomains = () => {
    if (
      bulkSelectedDNCListEmailsAndDomains &&
      bulkSelectedDNCListEmailsAndDomains.isAllDNCListEmailsAndDomainsSelected
    ) {
      sendBulkDeleteDoNotContactListEmailsAndDomainsRequest({
        dncListId: doNotContactListId,
        dncDetailsFilter: {
          search: filters.search,
          type: filters.type,
        },
        deSelectedIds:
          bulkSelectedDNCListEmailsAndDomains.deSelectedDNCListEmailsAndDomainsIds,
      });
    } else {
      sendDeleteDoNotContactListEmailsAndDomainsRequest({
        dncListId: doNotContactListId,
        emailDomainsIds: selectedDNCListEmailsAndDomains?.map((obj) => obj.id),
      });
    }
  };

  const onDeleteModalSubmit = () => {
    if (selectedEmailOrDomain) {
      onDeleteEmailsAndDomains();
    } else {
      onBulkDeleteEmailsAndDomains();
    }
  };

  const onAction = (
    key: string,
    actionRow: DoNotContactListEmailsAndDomains,
  ) => {
    if (key === 'delete') {
      setSelectedEmailOrDomain(actionRow);
      onShowDeleteDoNotContactListModal();
    }
  };

  const handleImport = (file: File, emailsDomainsColumn: string) => {
    sendImportDoNotContactListEmailsAndDomainsRequest({
      dncListId: doNotContactListId,
      file,
      emailsDomainsColumn,
    });
  };

  const handleExport = async () => {
    const isBulkAction =
      bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected ||
      false;

    const result = await api.post(`/dnc/${doNotContactListId}/export`, {
      type: filters.type,
      ...(filters?.search !== '' && {
        search: filters.search,
      }),
      isBulkAction,
      ...(!isBulkAction &&
        selectedDNCListEmailsAndDomains?.length > 0 && {
          selectedIds: selectedDNCListEmailsAndDomains?.map((obj) => obj.id),
        }),
      ...(isBulkAction &&
        bulkSelectedDNCListEmailsAndDomains
          ?.deSelectedDNCListEmailsAndDomainsIds?.length > 0 && {
          deSelectedIds:
            bulkSelectedDNCListEmailsAndDomains?.deSelectedDNCListEmailsAndDomainsIds,
        }),
    });

    const { message, status } = result.payload;

    if (status === 0) {
      toaster.error(message);
    } else if (status === 2) {
      toaster.warning(
        isWhitelabelWithClientView()
          ? 'Exporting data has been started successfully. You will shortly receive a notification with the exported data. (Estimate 30 mins)'
          : message,
      );
    } else {
      toaster.success(
        isWhitelabelWithClientView()
          ? 'Exporting data has been started successfully. You will shortly receive a notification with the exported data. (Estimate 30 mins)'
          : message,
      );
    }
  };

  const getNoResultMetaData = () => {
    if (filters.search === '' && filters.type === DoNoContactValueType.DOMAIN) {
      return {
        title: 'No Domains Found',
        desc: 'We can’t find any domains entries.',
      };
    }
    if (filters.search === '' && filters.type === DoNoContactValueType.EMAIL) {
      return {
        title: 'No Emails Found',
        desc: 'We can’t find any emails entries.',
      };
    }
    return {
      title: 'No Search Result',
      desc: 'We can’t find any entries matching your search.',
    };
  };

  const getIsGlobalList = () =>
    isEmpty(doNotContactListDetailsMeta?.client) && isEmpty(dncListRow?.client);

  useEffect(() => {
    if (doNotContactListId) {
      if (!dncListRow) {
        sendGetDoNotContactListDetailsMetaRequest(doNotContactListId);
      }
      sendGetDoNotContactListDetailsRequest({
        id: doNotContactListId,
        ...filters,
      });
      resetSelectedDNCListEmailsAndDomains();
    }

    return () => {
      setDncListRow(null);
      resetDoNotContactListDetailsMeta();
    };
  }, []);

  useEffect(() => {
    executeOnRequestStatus({
      status: getDoNotContactListDetailsMetaRequestStatus,
      onSuccess: () => {
        if (doNotContactListDetailsMeta) {
          const updatedRow = {
            ...dncListRow,
            id: doNotContactListDetailsMeta.id,
            name: doNotContactListDetailsMeta.name,
            client: doNotContactListDetailsMeta.client,
            totalDomainsCount: doNotContactListDetailsMeta.totalDomainsCount,
            totalEmailsCount: doNotContactListDetailsMeta.totalEmailsCount,
          };
          setDncListRow(updatedRow);
        }
      },
      onFailed: () => {
        executeOnErrorWithErrorCheck({
          error: getDoNotContactListDetailsMetaRequestError,
          onError: () => {
            toaster.error(getDoNotContactListDetailsMetaRequestError?.message);
          },
        });
      },
    });
  }, [getDoNotContactListDetailsMetaRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: getDoNotContactListDetailsRequestStatus,
      onSuccess: () => {
        resetGetDoNotContactListDetailsRequest();
      },
      onFailed: () => {
        resetGetDoNotContactListDetailsRequest();
        executeOnErrorWithErrorCheck({
          error: getDoNotContactListDetailsRequestError,
          onError: () => {
            toaster.error(getDoNotContactListDetailsRequestError?.message);
          },
        });
      },
    });
  }, [getDoNotContactListDetailsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateDoNotContactListRequestStatus,
      onSuccess: () => {
        toaster.success('List modified successfully');
        sendGetDoNotContactListDetailsMetaRequest(doNotContactListId);
        onHideModifyDoNotContactListModal();
        resetUpdateDoNotContactListRequest();
      },
      onFailed: () => {
        resetUpdateDoNotContactListRequest();
        onHideModifyDoNotContactListModal();
        executeOnErrorWithErrorCheck({
          error: updateDoNotContactListRequestError,
          onError: () => {
            toaster.error(updateDoNotContactListRequestError?.message);
          },
        });
      },
    });
  }, [updateDoNotContactListRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: updateDoNotContactListDetailsRequestStatus,
      onSuccess: () => {
        sendGetDoNotContactListDetailsMetaRequest(doNotContactListId);
        sendGetDoNotContactListDetailsRequest({
          id: doNotContactListId,
          ...filters,
        });
      },
    });
  }, [updateDoNotContactListDetailsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: deleteDoNotContactListEmailsAndDomainsRequestStatus,
      onSuccess: () => {
        toaster.success(
          getEntriesMessage(
            'deleted',
            selectedEmailOrDomain,
            selectedDNCListEmailsAndDomains,
            bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
          ),
        );
        sendGetDoNotContactListDetailsMetaRequest(doNotContactListId);

        if (selectedEmailOrDomain) {
          if (doNotContactListDetailsPaginationOptions?.itemCount === 1) {
            onFiltersChange({
              page: 1,
            });
          } else {
            sendGetDoNotContactListDetailsRequest({
              id: doNotContactListId,
              ...filters,
            });
          }
        } else {
          sendGetDoNotContactListDetailsRequest({
            id: doNotContactListId,
            ...filters,
            page: 1,
          });
        }

        onHideDeleteDoNotContactListModal();
        clearDNCListEmailsAndDomainsSelection();
        handleResetSelected(true);
        resetDeleteDoNotContactListEmailsAndDomainsRequest();
      },
      onFailed: () => {
        resetDeleteDoNotContactListEmailsAndDomainsRequest();
        onHideDeleteDoNotContactListModal();
        clearDNCListEmailsAndDomainsSelection();
        handleResetSelected(true);
        executeOnErrorWithErrorCheck({
          error: deleteDoNotContactListEmailsAndDomainsRequestError,
          onError: () => {
            toaster.error(
              deleteDoNotContactListEmailsAndDomainsRequestError?.message,
            );
          },
        });
      },
    });
  }, [deleteDoNotContactListEmailsAndDomainsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: importDoNotContactListEmailsAndDomainsRequestStatus,
      onSuccess: () => {
        toaster.success(
          isWhiteLabel()
            ? 'You will shortly receive a notification with blacklist data import report. (Estimate 30 min)'
            : 'You will shortly receive an email with blacklist data import report. (Estimate 30 min)',
        );
        sendGetDoNotContactListDetailsMetaRequest(doNotContactListId);
        sendGetDoNotContactListDetailsRequest({
          id: doNotContactListId,
          ...filters,
        });
        resetImportDoNotContactListEmailsAndDomainsRequest();
      },
      onFailed: () => {
        resetImportDoNotContactListEmailsAndDomainsRequest();
        executeOnErrorWithErrorCheck({
          error: importDoNotContactListEmailsAndDomainsRequestError,
          onError: () => {
            toaster.error(
              importDoNotContactListEmailsAndDomainsRequestError?.message,
            );
          },
        });
      },
    });
  }, [importDoNotContactListEmailsAndDomainsRequestStatus]);

  useEffect(() => {
    executeOnRequestStatus({
      status: bulkDeleteDoNotContactListEmailsAndDomainsRequestStatus,
      onSuccess: () => {
        toaster.success('Entries deleted successfully');
        sendGetDoNotContactListDetailsMetaRequest(doNotContactListId);
        sendGetDoNotContactListDetailsRequest({
          id: doNotContactListId,
          ...filters,
          page: 1,
        });
        onHideDeleteDoNotContactListModal();
        resetBulkDeleteDoNotContactListEmailsAndDomainsRequest();
        clearDNCListEmailsAndDomainsSelection();
        handleResetSelected(true);
      },
      onFailed: () => {
        resetBulkDeleteDoNotContactListEmailsAndDomainsRequest();
        onHideDeleteDoNotContactListModal();
        clearDNCListEmailsAndDomainsSelection();
        handleResetSelected(true);
        executeOnErrorWithErrorCheck({
          error: bulkDeleteDoNotContactListEmailsAndDomainsRequestError,
          onError: () => {
            toaster.error(
              bulkDeleteDoNotContactListEmailsAndDomainsRequestError?.message,
            );
          },
        });
      },
    });
  }, [bulkDeleteDoNotContactListEmailsAndDomainsRequestStatus]);

  if (!hasPermission(Permissions.DNC_LIST_READ)) {
    history.push('/settings/dnc-list');
    return null;
  }

  const getDoNotContactListDetailsMetaRequestLoading = getIsRequestPending(
    getDoNotContactListDetailsMetaRequestStatus,
  );
  const getDoNotContactListDetailsRequestLoading = getIsRequestPending(
    getDoNotContactListDetailsRequestStatus,
  );
  const importDoNotContactListEmailsAndDomainsRequestStatusLoading = getIsRequestPending(
    importDoNotContactListEmailsAndDomainsRequestStatus,
  );

  const renderTableHeader = () => (
    <div className="do-not-contact-list-details--actions d-flex justify-content-between align-items-center mb-3">
      <div className="actions-left d-flex justify-content-center align-items-center">
        {getDoNotContactListDetailsRequestLoading && filters.search === '' ? (
          <>
            <SkeletonLoading width={300} height={32} />
            <SkeletonLoading width={140} height={32} />
          </>
        ) : (
          <>
            <DoNotContactListDetailsSearch
              filters={filters}
              onFiltersChange={onFiltersChange}
            />

            <Select
              options={filterOptions}
              selectedOptionKey={filters.type}
              optionRenderer={(option) => <span>{option?.label}</span>}
              selectedOptionRenderer={([option]) => (
                <span>{option?.label}</span>
              )}
              onChange={([option]) => {
                onFiltersChange({ type: option.key }, true);
              }}
            />
          </>
        )}
      </div>

      <div className="actions-right d-flex justify-content-center align-items-center">
        {getDoNotContactListDetailsRequestLoading && filters.search === '' ? (
          <>
            <SkeletonLoading width={80} height={32} />
          </>
        ) : (
          <>
            {selectedDNCListEmailsAndDomains.length > 0 && (
              <DeleteButton
                label="Remove from List"
                handleDelete={onShowDeleteDoNotContactListModal}
              />
            )}

            {doNotContactListDetails?.length > 0 && (
              <ExportButton handleExport={handleExport} />
            )}
          </>
        )}
      </div>
    </div>
  );

  const renderTableJSX = () => {
    if (
      doNotContactListDetails?.length === 0 &&
      !getDoNotContactListDetailsRequestLoading &&
      filters.search === '' &&
      filters.type === DoNoContactValueType.ALL
    ) {
      return null;
    }

    if (
      doNotContactListDetails?.length === 0 &&
      !getDoNotContactListDetailsRequestLoading
    ) {
      const { title, desc } = getNoResultMetaData();
      return (
        <>
          {renderTableHeader()}
          <DoNotContactListEmptyUI
            icon={<NoDNCListFound />}
            title={title}
            desc={desc}
          />
        </>
      );
    }

    return (
      <>
        {renderTableHeader()}
        <DoNotContactListDetailsTable
          doNotContactListDetails={doNotContactListDetails}
          doNotContactListDetailsPaginationOptions={
            doNotContactListDetailsPaginationOptions
          }
          filters={filters}
          isLoading={getDoNotContactListDetailsRequestLoading}
          onAction={onAction}
          onFiltersChange={onFiltersChange}
          resetSelected={resetSelected}
          handleResetSelected={handleResetSelected}
        />
      </>
    );
  };

  return (
    <div className="do-not-contact-list-details">
      <DoNotContactListDetailsHeader
        listName={doNotContactListDetailsMeta?.name || dncListRow?.name}
        emailsCount={
          doNotContactListDetailsMeta
            ? doNotContactListDetailsMeta?.totalEmailsCount
            : Number(dncListRow?.totalEmailsCount || 0)
        }
        domainsCount={
          doNotContactListDetailsMeta
            ? doNotContactListDetailsMeta?.totalDomainsCount
            : Number(dncListRow?.totalDomainsCount || 0)
        }
        associatedClient={getListAssociatedByName(
          doNotContactListDetailsMeta?.client || dncListRow?.client,
        )}
        isLoading={!dncListRow && getDoNotContactListDetailsMetaRequestLoading}
        onEdit={onShowModifyDoNotContactListModal}
        onShowImportDoNotContactListModal={onShowImportDoNotContactListModal}
      />

      {hasPermission(Permissions.DNC_LIST_WRITE) && (
        <DoNotContactListDetailsInput
          doNotContactListId={doNotContactListId}
          isGlobalList={getIsGlobalList()}
        />
      )}

      {renderTableJSX()}

      <AddModifyDoNotContactList
        show={modifyDoNotContactListModal}
        action="modify"
        isLoading={getIsRequestPending(updateDoNotContactListRequestStatus)}
        defaultDoNotContactList={dncListRow || doNotContactListDetailsMeta}
        onClose={onHideModifyDoNotContactListModal}
        onUpdateDoNotContactList={sendUpdateDoNotContactListRequest}
        agencyClients={agencyClients}
        sendGetAgencyClientsDropdownRequest={
          sendGetAgencyClientsDropdownRequest
        }
      />

      <DeleteDoNotContactList
        show={deleteDoNotContactListModal}
        title={`Delete ${getEntriesTitle(
          selectedEmailOrDomain,
          selectedDNCListEmailsAndDomains,
          bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
        )}`}
        contents={[
          `Are you sure you want to delete the selected ${getEntriesTitle(
            selectedEmailOrDomain,
            selectedDNCListEmailsAndDomains,
            bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected,
          ).toLowerCase()}?`,
          'Once deleted, all the related prospects will become active.',
        ]}
        isLoading={
          getIsRequestPending(
            deleteDoNotContactListEmailsAndDomainsRequestStatus,
          ) ||
          getIsRequestPending(
            bulkDeleteDoNotContactListEmailsAndDomainsRequestStatus,
          )
        }
        onHide={onHideDeleteDoNotContactListModal}
        onSubmit={onDeleteModalSubmit}
      />

      <ImportEmailsDomainsViaCsv
        show={importDoNotContactListModal}
        onCancel={onHideImportDoNotContactListModal}
        isLoading={importDoNotContactListEmailsAndDomainsRequestStatusLoading}
        onSubmit={handleImport}
        isGlobalList={getIsGlobalList()}
      />
    </div>
  );
};

export default DoNotContactListDetails;
