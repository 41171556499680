import { connect, ConnectedProps } from 'react-redux';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import EmailWarmup from './email-warmup';
import { RootState } from '../../store/root-reducer';
import { turnOnOffEmailAccountWarmupRequest } from './extra-actions';
import { EmailAccountId } from './types';
import { connectEmailAccountRequest } from '../email-accounts/extra-actions';
import { EmailAccountMethod } from '../email-accounts/enums/email-account';
import { resetTurnOnOffEmailAccountWarmupState } from './email-warmup-slice';
import {
  getUserSettingsRequest,
  updateUserSettingsRequest,
} from '../home/extra-actions';
import { UpdateUserSettingsRequestPayload } from '../sequence/types';

const mapStateToProps = (state: RootState) => ({
  authUrl: state.emailAccount.authUrl,
  subscriptionPlan: state.home.subscription?.planCode,
  meta: state.home.meta,

  turnOnOffEmailAccountWarmupRequestStatus:
    state.emailWarmup.turnOnOffEmailAccountWarmupRequest.status,
  turnOnOffEmailAccountWarmupRequestError:
    state.emailWarmup.turnOnOffEmailAccountWarmupRequest.error,

  connectEmailAccountRequestStatus:
    state.emailAccount.connectEmailAccountRequest.status,
  connectEmailAccountRequestError:
    state.emailAccount.connectEmailAccountRequest.error,

  updateUserSettingsRequestStatus: state.home.updateUserSettingsRequest.status,
});

const mapDispatchToProps = {
  sendConnectEmailAccountRequest: (
    method: EmailAccountMethod,
    emailAccountId?: number,
  ) => connectEmailAccountRequest({ method, emailAccountId }),
  sendTurnOnOffEmailAccountWarmupRequest: (payload: EmailAccountId) =>
    turnOnOffEmailAccountWarmupRequest(payload),
  resetTurnOnOffEmailAccountWarmupState: () =>
    resetTurnOnOffEmailAccountWarmupState(),
  showLoading: () => showLoading(),
  hideLoading: () => hideLoading(),
  sendGetUserSettingsRequest: () => getUserSettingsRequest(),
  updateEnableEmailWarmupConsent: (payload: UpdateUserSettingsRequestPayload) =>
    updateUserSettingsRequest(payload),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector>;

export default connector(EmailWarmup);
