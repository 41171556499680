import React from 'react';

export const MailRead = ({ width = 24, height = 24 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75 4.49438C3.33579 4.49438 3 4.83017 3 5.24438V12.7444C3 13.1586 3.33579 13.4944 3.75 13.4944H14.25C14.6642 13.4944 15 13.1586 15 12.7444V5.24438C15 4.83017 14.6642 4.49438 14.25 4.49438H3.75ZM1.5 5.24438C1.5 4.00174 2.50736 2.99438 3.75 2.99438H14.25C15.4926 2.99438 16.5 4.00174 16.5 5.24438V12.7444C16.5 13.987 15.4926 14.9944 14.25 14.9944H3.75C2.50736 14.9944 1.5 13.987 1.5 12.7444V5.24438Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.62604 4.83959C1.85581 4.49495 2.32146 4.40182 2.6661 4.63158L9.00008 8.85423L15.3341 4.63158C15.6787 4.40182 16.1444 4.49495 16.3741 4.83959C16.6039 5.18424 16.5108 5.64989 16.1661 5.87966L9.4161 10.3797C9.16418 10.5476 8.83598 10.5476 8.58405 10.3797L1.83405 5.87966C1.48941 5.64989 1.39628 5.18424 1.62604 4.83959Z"
      fill="currentColor"
    />
    <path
      d="M16 14C16 14.5523 15.5523 15 15 15C14.4477 15 14 14.5523 14 14C14 13.4477 14.4477 13 15 13C15.5523 13 16 13.4477 16 14Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 14C17 15.1046 16.1046 16 15 16C13.8954 16 13 15.1046 13 14C13 12.8954 13.8954 12 15 12C16.1046 12 17 12.8954 17 14ZM15 15C15.5523 15 16 14.5523 16 14C16 13.4477 15.5523 13 15 13C14.4477 13 14 13.4477 14 14C14 14.5523 14.4477 15 15 15Z"
      fill="white"
    />
  </svg>
);
