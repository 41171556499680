import React, { useEffect, useRef, useState } from 'react';
import { Trash } from '@saleshandy/icons';

import type { IProps } from './do-not-contact-list-details-table-container';

import SelectedEmailsDomainsRowInfoBanner from './selected-emails-domains-info-banner';
import Table from '../../../../../../../../shared/design-system/components/organisms/table';
import { PaginationShowHide } from '../../../../../../../../shared/design-system/components/organisms/table/types';
import { getDoNotContactListDetailsColumns } from './utils/get-do-not-contact-list-details-columns';
import { constants } from '../../../../../../../../shared/enums/constants';
import { executeOnRequestStatus } from '../../../../../../../../shared/utils';

import {
  checkIsTableLastPage,
  getShouldShowSelectedRowInfoBanner,
  isCurrentPageUnderTheSelectAllPage,
} from './utils/bulk-action-helper-function';
import hasPermission from '../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../shared/utils/access-control/enums/permissions';

const DoNotContactListDetailsTable: React.FC<IProps> = ({
  doNotContactListDetails,
  doNotContactListDetailsPaginationOptions,
  filters,
  onFiltersChange,
  onAction,
  isLoading,
  getDoNotContactListDetailsRequestStatus,

  // bulk action state & handler
  resetSelected,
  handleResetSelected,
  bulkSelectedDNCListEmailsAndDomains,
  selectedDNCListEmailsAndDomains,
  clearDNCListEmailsAndDomainsSelection,
  onBulkSelectDNCListEmailsAndDomains,
  selectAllDNCListEmailsAndDomains,
  selectSingleDNCListEmailsAndDomains,
}) => {
  const dncListEmailsAndDomainsTableRef = useRef<any>(null);
  const [
    isBulkEmailsAndDomainsSelected,
    setIsBulkEmailsAndDomainsSelected,
  ] = useState<boolean>(false);

  // handle table pagination change
  const onPaginationOptionsChange = ({ page, limit }) => {
    if (filters.page === page && filters.limit === limit) {
      return;
    }
    onFiltersChange({ page, limit });
  };

  // * handle select/deselect all rows within a page
  const handleSelectDeselectRows = (rows: any, status: boolean) => {
    selectAllDNCListEmailsAndDomains({ rows, status });
    setIsBulkEmailsAndDomainsSelected(false);
  };

  // * handle bulk select from banner
  const handleSelectAllRows = () => {
    if (
      checkIsTableLastPage(
        doNotContactListDetailsPaginationOptions.currentPage,
        doNotContactListDetailsPaginationOptions.itemsPerPage,
      )
    ) {
      dncListEmailsAndDomainsTableRef?.current?.selectAllCurrentPageRows();
    } else {
      dncListEmailsAndDomainsTableRef?.current?.resetSelectedRows();
    }

    onBulkSelectDNCListEmailsAndDomains({
      isAllDNCListEmailsAndDomainsSelected: true,
      deSelectedDNCListEmailsAndDomainsIds: [],
    });
  };

  // * handle clear selection
  const handleClearSelection = (resetSelectedRows = false) => {
    clearDNCListEmailsAndDomainsSelection();
    handleSelectDeselectRows(doNotContactListDetails, false);

    if (
      isCurrentPageUnderTheSelectAllPage(
        bulkSelectedDNCListEmailsAndDomains,
        doNotContactListDetailsPaginationOptions?.currentPage,
        doNotContactListDetailsPaginationOptions?.itemsPerPage,
      ) ||
      resetSelectedRows
    ) {
      dncListEmailsAndDomainsTableRef.current?.resetSelectedRows();
    }
  };

  // * handle reset selection via select all
  const handleResetSelectAllRows = () => {
    const shouldResetSelection = isCurrentPageUnderTheSelectAllPage(
      bulkSelectedDNCListEmailsAndDomains,
      doNotContactListDetailsPaginationOptions?.currentPage,
      doNotContactListDetailsPaginationOptions?.itemsPerPage,
    );

    if (!shouldResetSelection && shouldResetSelection !== undefined) {
      handleClearSelection();
    }
  };

  // * handle select single row
  const handleSelectSingleRow = (row: any, status: boolean) => {
    selectSingleDNCListEmailsAndDomains({ row, status });
    handleResetSelectAllRows();
    setIsBulkEmailsAndDomainsSelected(false);
  };

  // * select all rows on current page
  const selectAllCurrentPageRows = () => {
    const lastPage =
      constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS /
      doNotContactListDetailsPaginationOptions?.itemsPerPage;

    if (
      doNotContactListDetailsPaginationOptions?.totalItems <=
        constants.BULK_DELETE_DNC_LIST_EMAILS_DOMAINS ||
      lastPage > doNotContactListDetailsPaginationOptions?.currentPage
    ) {
      dncListEmailsAndDomainsTableRef.current?.selectAllCurrentPageRows();
    } else {
      dncListEmailsAndDomainsTableRef.current?.resetSelectedRows();
    }
  };

  const actions = [
    {
      key: 'delete',
      iconElement: <Trash />,
      displayName: 'Delete',
      position: 'out',
    },
  ];

  useEffect(() => {
    executeOnRequestStatus({
      status: getDoNotContactListDetailsRequestStatus,
      onSuccess: () => {
        if (
          bulkSelectedDNCListEmailsAndDomains?.isAllDNCListEmailsAndDomainsSelected
        ) {
          selectAllCurrentPageRows();
        }
      },
    });
  }, [getDoNotContactListDetailsRequestStatus]);

  useEffect(
    () => () => {
      handleClearSelection();
    },
    [],
  );

  return (
    <div className="do-not-contact-list-table-wrapper">
      {getShouldShowSelectedRowInfoBanner(
        selectedDNCListEmailsAndDomains,
        bulkSelectedDNCListEmailsAndDomains,
        isBulkEmailsAndDomainsSelected,
      ) && (
        <div className="email-account-banner">
          <SelectedEmailsDomainsRowInfoBanner
            selectedDNCListEmailsAndDomains={selectedDNCListEmailsAndDomains}
            bulkSelectedDNCListEmailsAndDomains={
              bulkSelectedDNCListEmailsAndDomains
            }
            totalEmailsAndDomains={
              doNotContactListDetailsPaginationOptions?.totalItems
            }
            handleSelectAllRows={handleSelectAllRows}
            handleClearSelection={() => handleClearSelection(true)}
          />
        </div>
      )}

      <Table
        columns={getDoNotContactListDetailsColumns()}
        data={doNotContactListDetails}
        {...(hasPermission(Permissions.DNC_LIST_DELETE) && {
          actionColumnName: 'Action',
          actions,
          onAction,
        })}
        paginationOptions={{
          options: {
            limit: doNotContactListDetailsPaginationOptions?.itemsPerPage,
            page: doNotContactListDetailsPaginationOptions?.currentPage,
            totalElements: doNotContactListDetailsPaginationOptions?.totalItems,
          },
        }}
        tableWrapperClasses="do-not-contact-list-details-table"
        isLoading={isLoading}
        pagination={PaginationShowHide.SHOW}
        isNewPagination={true}
        onPaginationOptionsChange={onPaginationOptionsChange}
        headerVisibleForGenerateColumn={true}
        onRowSelect={handleSelectSingleRow}
        onRowSelectAll={handleSelectDeselectRows}
        resetSelected={resetSelected}
        handleResetSelected={handleResetSelected}
        deselectedRows={
          bulkSelectedDNCListEmailsAndDomains?.deSelectedDNCListEmailsAndDomainsIds
        }
        tableRef={(refValue: any) => {
          dncListEmailsAndDomainsTableRef.current = refValue;
        }}
      />
    </div>
  );
};

export default DoNotContactListDetailsTable;
