import React, { useState } from 'react';
import { Button } from '@saleshandy/design-system';
import LinkBadge from '../../../../../../../../shared/design-system/components/atoms/link-badge';
import SearchInput from '../../../../../../../../shared/design-system/components/atoms/search-input';
import { IconPlace } from '../../../../../../../../shared/design-system/components/dropdown';
import { GlobalSettings } from '../../../../../../../../shared/components/global-settings-wrapper';
import {
  DoNotContactListsFilter,
  ModalActions,
} from '../../../../../../types/do-not-contact-list';
import hasPermission from '../../../../../../../../shared/utils/access-control/has-permission';
import { Permissions } from '../../../../../../../../shared/utils/access-control/enums/permissions';
import { supportUrls } from '../../../../../../../../shared/utils/urls';
import { isWhitelabelWithoutClientView } from '../../../../../../../../shared/utils/user-details';
import { isInternalUrl } from '../../../../../../../../shared/utils/is-internal-url';

type IProps = {
  filters: DoNotContactListsFilter;
  onFiltersChange: (payload: Partial<DoNotContactListsFilter>) => void;
  onAddList: (action: ModalActions) => void;
  isAgencyConfigRequestPending?: boolean;
};

const DoNotContactListHeader: React.FC<IProps> = ({
  filters,
  onFiltersChange,
  onAddList,
  isAgencyConfigRequestPending,
}) => {
  const [search, setSearch] = useState(filters.search || '');

  const onSearch = () => {
    onFiltersChange({ search, page: 1 });
  };

  const onClearSearch = () => {
    setSearch('');
    onFiltersChange({ search: '', page: 1 });
  };

  return (
    <GlobalSettings.Header className="do-no-contact-list-header">
      <GlobalSettings.HeaderLeft>
        <GlobalSettings.HeaderTitle
          title="Do Not Contact List"
          isToolTip={true}
          tooltipText="Create a do-not-contact list to prevent reaching out to prospects you shouldn't contact."
        />
        {isWhitelabelWithoutClientView() &&
          (!isAgencyConfigRequestPending || isInternalUrl()) && (
            <LinkBadge url={supportUrls.dncBlogURL} />
          )}
      </GlobalSettings.HeaderLeft>
      <GlobalSettings.HeaderRight>
        <SearchInput
          searchValue={search}
          setSearchValue={setSearch}
          onSearch={onSearch}
          onClearSearch={onClearSearch}
          searchIconPlace={IconPlace.Left}
          searchIconFixed
          placeholder="Search"
          className="dnc-search-input"
        />

        {hasPermission(Permissions.DNC_WRITE) && (
          <Button onClick={() => onAddList('add')}>Add List</Button>
        )}
      </GlobalSettings.HeaderRight>
    </GlobalSettings.Header>
  );
};

export default DoNotContactListHeader;
