import React from 'react';
import classNames from 'classnames';

type IProps = {
  name: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  isError?: boolean;
  errorMsg?: string;
  type?: 'input' | 'textarea';
};

const TextInput: React.FC<IProps> = ({
  name,
  placeholder = '',
  value,
  onChange,
  isError = false,
  errorMsg = '',
  type = 'input',
}) => {
  const textInputClasses = classNames([
    'webhook-form-field-input',
    {
      'input-error': isError,
    },
  ]);
  return (
    <div className={textInputClasses}>
      {type === 'input' ? (
        <input
          name={name}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      ) : (
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
      )}
      {isError && <span>{errorMsg}</span>}
    </div>
  );
};

export default TextInput;
