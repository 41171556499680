import React from 'react';

import { IProps } from './types';
import { BrokenLink } from '../../../../../../shared/svg';
import { accessibleOnClick } from '../../../../../../shared/utils/accessible-on-click';

const ResetPasswordSuccess: React.FC<IProps> = ({
  showSuccessComponent,
  onResendVerificationEmail,
}) => (
  <div className="auth-wrapper">
    <div className="auth-container">
      <div className="auth-container--icon">
        <BrokenLink />
      </div>
      <h1 className="auth-container--title">
        We’ve sent you an email containing further instructions for resetting
        your password.
      </h1>
      <p className="auth-container--sub-title">
        If you haven’t received an email in 5 minutes, check your spam folder,{' '}
        <span
          className="link"
          {...accessibleOnClick(onResendVerificationEmail)}
        >
          resend email
        </span>
        , or{' '}
        <span className="link" {...accessibleOnClick(showSuccessComponent)}>
          try again
        </span>
        .
      </p>
    </div>
  </div>
);

export default ResetPasswordSuccess;
