import { IntercomEvents } from '../enums/intercom-events';

export const openChatSupport = () => {
  if (window.Intercom) {
    window.Intercom(IntercomEvents.SHOW_MESSAGES);
  }
};

export const openIntercomHome = () => {
  if (window.Intercom) {
    window.Intercom(IntercomEvents.SHOW);
  }
};
