import React from 'react';

export const SeeMore = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="520"
    height="80"
    viewBox="0 0 520 80"
    fill="none"
  >
    <path
      d="M0 4C0 1.79086 1.79086 0 4 0H516C518.209 0 520 1.79086 520 4V80H0V4Z"
      fill="url(#paint0_linear_25904_330469)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_25904_330469"
        x1="260.267"
        y1="34.6154"
        x2="260.385"
        y2="51.5654"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
    </defs>
  </svg>
);
