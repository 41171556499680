import { withRouter } from 'react-router';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../../../../store/root-reducer';
import { changePasswordRequest, hideError } from '../../change-password-slice';
import { ResetPasswordCallbackRouteProps } from '../../../reset-password-callback/types';
import ChangePasswordForm from './change-password-form';

const mapStateToProps = (state: RootState) => ({
  changePasswordStatus: state.changePassword.status,
  changePasswordMessage: state.changePassword.message,
  changePasswordError: state.changePassword.error,
  changePasswordShowError: state.changePassword.showError,
});

type IPropsWithRoute = ResetPasswordCallbackRouteProps & {
  showSuccessComponent: () => void;
};

const mapDispatchToProps = (dispatch, ownProps: IPropsWithRoute) => ({
  hideError: () => dispatch(hideError()),

  sendChangePasswordRequest: (password: string) =>
    dispatch(
      changePasswordRequest({ code: ownProps.match.params.code, password }),
    ),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & IPropsWithRoute;

export default withRouter(connector(ChangePasswordForm));
