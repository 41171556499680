import React from 'react';

export const SHLogoDark = ({ width = 30, height = 30 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
  >
    <g clipPath="url(#clip0_12652_119359)">
      <path
        d="M8.0177 0C6.90407 0 6.34636 1.34643 7.13382 2.13388L27.8661 22.8661C28.6535 23.6536 29.9999 23.0959 29.9999 21.9822V1.25C29.9999 0.559644 29.4403 0 28.7499 0H8.0177Z"
        fill="url(#paint0_linear_12652_119359)"
      />
      <path
        d="M14.6495 27.1496C15.2401 27.7402 16.2499 27.3219 16.2499 26.4867V14.6875C16.2499 14.1697 15.8302 13.75 15.3124 13.75H3.51326C2.67804 13.75 2.25976 14.7598 2.85035 15.3504L14.6495 27.1496Z"
        fill="url(#paint1_linear_12652_119359)"
      />
      <path
        d="M6.433 28.9331C6.82672 29.3268 7.49994 29.0479 7.49994 28.4911V23.125C7.49994 22.7798 7.22012 22.5 6.87494 22.5H1.50882C0.952006 22.5 0.673152 23.1732 1.06688 23.5669L6.433 28.9331Z"
        fill="url(#paint2_linear_12652_119359)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12652_119359"
        x1="6.34207"
        y1="24.3858"
        x2="29.9999"
        y2="1.81984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12652_119359"
        x1="6.34207"
        y1="24.3858"
        x2="29.9999"
        y2="1.81984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_12652_119359"
        x1="6.34207"
        y1="24.3858"
        x2="29.9999"
        y2="1.81984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="1" stopColor="#3B82F6" />
      </linearGradient>
      <clipPath id="clip0_12652_119359">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
