export enum SequenceScoreErrorType {
  FirstStepNotFound = 2014,
  MaximumEmailContentSizeExceeded = 2015,
  NoEmailAccountConnectedToSequence = 2016,
  FirstStepDataNotAvailable = 2017,
}

export enum SequenceScoreTab {
  EmailAccountSetupScore = 'emailAccountSetupScore',
  SequenceSetupScore = 'sequenceSetupScore',
  EmailWritingScore = 'emailWritingScore',
}
