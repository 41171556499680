export enum EmailAccountMethod {
  Gmail = 'gmail',
  Microsoft = 'microsoft',
  SmtpImap = 'smtp-imap',
}

export enum EmailAccountType {
  Gmail = 1,
  Microsoft = 2,
  SmtpImap = 3,
}

export enum EmailServiceProvider {
  Gmail = 'gmail',
  Microsoft = 'microsoft',
  Gsuite = 'gsuite',
  O365 = 'o365',
  Other = 'other',
  Yahoo = 'yahoo',
  Zoho = 'zoho',
  Godaddy = 'godaddy',
  Yandex = 'yandex',
  Sendgrid = 'sendgrid',
}

export enum EmailAccountsSortBy {
  SetupScore = 'health-score',
  EmailSent = 'remaining-quota',
  ClientAssociated = 'clientFirstName',
}

export enum EmailAccountSortBy {
  Owner = 'owner',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum EmailAccountSettingCode {
  DailySendingLimit = 'daily-sending-limit',
  AvailableQuota = 'available-quota',
  Bcc = 'bcc',
  Signature = 'signature',
  RampUpInitialSendingLimit = 'ramp-up-initial-sending-limit',
  RampUpStatus = 'ramp-up-status',
  RampUpLimit = 'ramp-up-limit',
  RampUpPercent = 'ramp-up-percent',
  MaxSendingLimit = 'max-sending-limit',
  MinInterval = 'min-interval',
  MaxInterval = 'max-interval',
}
