import React from 'react';

import ConfirmationModalV3 from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/confirmation-modal-v3';
import { ConfirmationModalIconType } from '../../../../../../../shared/design-system/components/atoms/confirmation-modal/enum';

type IProps = {
  show: boolean;
  title: string;
  contents: string[];
  isLoading: boolean;
  onHide: VoidFunction;
  onSubmit: VoidFunction;
};

const DeleteDoNotContactList: React.FC<IProps> = ({
  show,
  title = '',
  contents = [],
  isLoading,
  onHide,
  onSubmit,
}) => (
  <ConfirmationModalV3
    show={show}
    onClose={onHide}
    title={title}
    contents={contents}
    iconType={ConfirmationModalIconType.TRASH_RED}
    className="delete-conversation-confirmation-modal agency-client-delete-modal"
    showCloseIcon={false}
    onSubmit={onSubmit}
    submitButtonText="Delete"
    cancelButtonText="Cancel"
    submitButtonClassName="action-btn-delete"
    cancelButtonClassName="action-btn-cancel"
    isSubmitDisabled={isLoading}
    isSubmitLoading={isLoading}
  />
);

export default DeleteDoNotContactList;
