/* eslint-disable @typescript-eslint/no-shadow */
import * as luxon from 'luxon';
import React, { ReactNode, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Images } from '../../../../../../../../shared/app-constants';
import { useAxios } from '../../../../../../../../shared/hooks/useAxios';
import Activity from '../../../../../../../../shared/design-system/components/organisms/activity';
import { ActivityType } from '../../../../../../../../shared/design-system/components/organisms/activity/types';
import EmptyList from '../../../../../../../../shared/design-system/components/molecules/empty-list/empty-list';
import { constants } from '../../../../../../../../shared/enums/constants';
import Spinner, {
  SpinnerAnimation,
} from '../../../../../../../../shared/design-system/components/atoms/spinner/spinner';
import store from '../../../../../../../../store/store';
import { executeOnRequestStatus } from '../../../../../../../../shared/utils';
import { resetUnsubscribeRequestState } from '../../../../../../prospect-slice';

type ProspectActivityProps = {
  prospectId: number;
  t: (x: string) => ReactNode;
  fetchActivity: boolean;
  onActivityChange: (value: boolean) => void;
  activeTab: string;
};

const ProspectActivity: React.FC<ProspectActivityProps> = ({
  prospectId,
  t,
  fetchActivity,
  onActivityChange,
  activeTab,
}) => {
  const state = store.getState();
  const dispatch = useDispatch();
  const unsubscribeRequestStatus =
    state.prospect.unsubscribeProspectRequest.status;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { loading, response, axiosConfigSetter } = useAxios(
    {
      url: '/activity/prospect',
      params: {
        pageNum: page,
        contactId: prospectId,
      },
    },
    [],
  );

  useEffect(() => {
    if (items && response.length < constants.DEFAULT_PAGE_SIZE) {
      setHasMore(false);
    }

    setItems(response || []);
  }, [response]);

  useEffect(() => {
    executeOnRequestStatus({
      status: unsubscribeRequestStatus,
      onSuccess: () => {
        axiosConfigSetter({
          params: {
            pageNum: page,
            contactId: prospectId,
          },
        });
        dispatch(resetUnsubscribeRequestState());
      },
    });
  }, [unsubscribeRequestStatus]);

  useEffect(() => {
    if(fetchActivity && activeTab === "activity") {
      axiosConfigSetter({
        params: {
          pageNum: page,
          contactId: prospectId,
        },
      });
      onActivityChange(false)
    }
  }, [activeTab])

  const getProspectActivity = () => {
    setPage((page) => page + 1);
    axiosConfigSetter({
      params: {
        pageNum: page + 1,
        contactId: prospectId,
      },
    });
  };

  // show spinner while loading
  if (loading) {
    return (
      <div className="mt-5 d-flex justify-content-center">
        <Spinner animation={SpinnerAnimation.Border} />
      </div>
    );
  }

  // if the returned data is empty show no data icon
  if (items.length === 0) {
    return (
      <EmptyList
        description=""
        title={t('messages.no_activity_data')}
        imgSrc={Images.EmptyData1}
        className="prospect-activity-empty-list"
      />
    );
  }

  // Creating an activity arr
  const activityArr: ActivityType[] = [];

  // Set to track the dayText that we already have got. like "20 July 2020"
  const dayTextSet = new Set();

  items.forEach(({ timestamp, log, key }) => {
    const ts = luxon.DateTime.fromISO(timestamp)
      .toFormat('dd LLLL yyyy')
      .toString();
    if (!dayTextSet.has(ts)) {
      const activityArrElement: ActivityType = {
        dayText: ts,
        activity: [{ log, key }],
      };
      dayTextSet.add(ts);
      activityArr.push(activityArrElement);
    } else {
      const activityArrElement = activityArr.find(
        ({ dayText }) => dayText === ts,
      );
      activityArrElement.activity.push({ log, key });
    }
  });

  const yesterday = luxon.DateTime.fromMillis(
    new Date().setDate(new Date().getDate() - 1),
  ).toFormat('dd LLLL yyyy');

  const today = luxon.DateTime.fromMillis(new Date().getTime()).toFormat(
    'dd LLLL yyyy',
  );

  if (activityArr[0].dayText === today) {
    activityArr[0].dayText = t('labels.today');
  }

  if (activityArr[0].dayText === yesterday) {
    activityArr[0].dayText = t('labels.yesterday');
  }

  if (activityArr[1] && activityArr[1].dayText === yesterday) {
    activityArr[1].dayText = t('labels.yesterday');
  }

  return (
    <Activity
      activityArr={activityArr}
      wrapperId="prospectActivity"
      scrollableTarget="prospectActivity"
      onLoadMore={getProspectActivity}
      hasMore={hasMore}
      lengthOfPage={items ? items.length : 0}
      heightOfScroll="calc(100vh - 14rem)"
      wrapperStyle={{ overflow: 'auto' }}
    />
  );
};

export default withTranslation()(ProspectActivity);
