/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { DeviceFloppy, Pencil, Trash } from '@saleshandy/icons';
import { useTranslation } from 'react-i18next';
import { IProps, SaveFilterModalVarient } from './types';
import Modal from '../../../../../shared/design-system/components/atoms/modal';
import Button from '../../../../../shared/design-system/components/atoms/button';
import Input from '../../../../../shared/design-system/components/input';
import toaster from '../../../../../shared/toaster';
import {
  addSavedFilterAPI,
  deleteSavedFilterAPI,
  editSavedFilterAPI,
} from '../../../helpers/leads.api';
import { LEAD_SAVED_FILTER_MESSAGES } from '../../../helpers/leads-message';

const SaveFilterModal: React.FC<IProps> = ({
  show,
  onHide,
  varient,
  selectedSavedFilter,
  getSavedFilters,
  handleGeneratePayload,
}) => {
  const { t } = useTranslation();
  const [filterName, setFilterName] = useState(selectedSavedFilter?.name);
  // eslint-disable-next-line  @typescript-eslint/no-unused-vars
  const [loadingSavedFilters, setLoadingSavedFilters] = useState(false);

  const handleSavedFilter = async () => {
    setLoadingSavedFilters(true);
    try {
      if (varient === SaveFilterModalVarient.Save) {
        await addSavedFilterAPI({
          name: filterName,
          searchQuery: handleGeneratePayload(),
        });
        toaster.success(
          LEAD_SAVED_FILTER_MESSAGES.saved_filter_add_success_msg,
        );
      }
      if (varient === SaveFilterModalVarient.Edit) {
        // eslint-disable-next-line no-underscore-dangle
        await editSavedFilterAPI(selectedSavedFilter?._id, {
          name: filterName,
        });
        toaster.success(
          LEAD_SAVED_FILTER_MESSAGES.saved_filter_edit_success_msg,
        );
      }

      if (varient === SaveFilterModalVarient.Delete) {
        // eslint-disable-next-line no-underscore-dangle
        await deleteSavedFilterAPI(selectedSavedFilter?._id);
        toaster.success(
          LEAD_SAVED_FILTER_MESSAGES.saved_filter_delete_success_msg,
        );
      }
      getSavedFilters();
      setLoadingSavedFilters(false);
      onHide();
    } catch (e: any) {
      getSavedFilters();
      setLoadingSavedFilters(false);
      toaster.error(e?.message);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSavedFilter();
    }
  };

  return (
    <Modal
      show={show}
      titleContent={
        <div className="d-flex align-items-center">
          {varient === SaveFilterModalVarient.Save ? (
            <DeviceFloppy />
          ) : varient === SaveFilterModalVarient.Edit ? (
            <Pencil />
          ) : varient === SaveFilterModalVarient.Delete ? (
            <Trash className="delete-icon" />
          ) : (
            ''
          )}
          <div>
            {varient === SaveFilterModalVarient.Save
              ? t('labels.save_filter')
              : varient === SaveFilterModalVarient.Edit
              ? t('labels.edit_filter')
              : varient === SaveFilterModalVarient.Delete
              ? t('labels.delete_filter')
              : t('labels.delete')}
          </div>
        </div>
      }
      showCloseIcon={false}
      onClose={onHide}
      cancelButtonText={t('labels.cancel')}
      isSubmitLoading={loadingSavedFilters}
      submitButtonText={
        varient === SaveFilterModalVarient.Save
          ? t('labels.save_filterBtn')
          : varient === SaveFilterModalVarient.Edit
          ? t('labels.save_filterBtn')
          : varient === SaveFilterModalVarient.Delete
          ? t('labels.delete')
          : ''
      }
      submitButtonVariant={
        varient === SaveFilterModalVarient.Delete
          ? Button.Variant.Danger
          : Button.Variant.Primary
      }
      cancelButtonVarient={Button.Variant.Outlined}
      onHide={onHide}
      isCancelDisabled={loadingSavedFilters}
      className="save-filter-modal"
      onSubmit={handleSavedFilter}
    >
      {varient !== SaveFilterModalVarient.Delete ? (
        <div>
          <Input
            name="filterName"
            id="filterName"
            value={filterName}
            placeholder={t('messages.save_filter_lable')}
            autoFocus
            type="text"
            label="Filter Name"
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setFilterName(e);
            }}
          />
        </div>
      ) : (
        <>
          <p>This action can't be reversed.</p>
          <p>Are you sure, you want to continue?</p>
        </>
      )}
    </Modal>
  );
};

export default SaveFilterModal;
