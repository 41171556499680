import { createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../../shared/enums/request-status';
import {
  getCustomOutcomeRequest,
  getCustomOutcomesWithPaginationRequest,
  getDefaultOutcomesWithPaginationRequest,
  createCustomOutcomeRequest,
  updateCustomOutcomeRequest,
  deleteCustomOutcomeRequest,
  getAllOutcomesRequest,
} from './extra-actions';
import { PaginationQueryParams } from '../../../../shared/types/request';
import { constants } from '../../../../shared/enums/constants';
import { CustomOutcome, DefaultOutcome } from '../../types/custom-outcome';

interface RequestState {
  status: RequestStatus;
  message: string;
  error: any;
}

interface State {
  getCustomOutcomesWithPaginationRequest: RequestState;
  getDefaultOutcomesWithPaginationRequest: RequestState;
  getCustomOutcomeRequest: RequestState;
  createCustomOutcomeRequest: RequestState;
  updateCustomOutcomeRequest: RequestState;
  deleteCustomOutcomeRequest: RequestState;
  getAllOutcomesRequest: RequestState;
  customOutcome: CustomOutcome[];
  defaultOutcome: DefaultOutcome[];
  outcomes: DefaultOutcome[];
  isLoading: boolean;
  totalOutcomes: number;
  customOutcomePagination: {
    options: Required<PaginationQueryParams> & { search?: string };
    pagesCount: number;
    count: number;
  };
  defaultOutcomePagination: {
    options: Required<PaginationQueryParams> & { search?: string };
    pagesCount: number;
    count: number;
  };
}

const initialState: State = {
  getCustomOutcomeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getCustomOutcomesWithPaginationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getDefaultOutcomesWithPaginationRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  createCustomOutcomeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  updateCustomOutcomeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  deleteCustomOutcomeRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  getAllOutcomesRequest: {
    status: RequestStatus.Ideal,
    message: null,
    error: null,
  },
  customOutcome: [],
  defaultOutcome: [],
  outcomes: [],
  isLoading: false,
  totalOutcomes: 0,
  customOutcomePagination: {
    options: {
      page: 1,
      limit: constants.DEFAULT_PAGE_SIZE,
    },
    count: 0,
    pagesCount: 0,
  },
  defaultOutcomePagination: {
    options: {
      page: 1,
      limit: constants.DEFAULT_PAGE_SIZE,
    },
    count: 0,
    pagesCount: 0,
  },
};

const customOutcomeSlice = createSlice({
  name: 'customOutcome',
  initialState,
  reducers: {
    resetCreateCustomOutcomeRequest: (state) => {
      state.createCustomOutcomeRequest.status = RequestStatus.Ideal;
      state.createCustomOutcomeRequest.error = null;
      state.isLoading = false;
    },
    resetProspectOutcomeData: (state) => {
      state.customOutcome = [];
      state.defaultOutcome = [];
    },
  },
  extraReducers: (builder) => {
    // Get custom Outcomes
    builder.addCase(getCustomOutcomeRequest.pending, (state) => {
      state.getCustomOutcomeRequest.status = RequestStatus.Pending;
      state.getCustomOutcomeRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getCustomOutcomeRequest.fulfilled, (state, action) => {
      state.getCustomOutcomeRequest.status = RequestStatus.Succeeded;
      state.getCustomOutcomeRequest.message = action.payload.message;
      state.outcomes = action.payload.payload;
      state.isLoading = false;
    });
    builder.addCase(getCustomOutcomeRequest.rejected, (state, action) => {
      state.getCustomOutcomeRequest.status = RequestStatus.Failed;
      state.getCustomOutcomeRequest.error = action.payload;
      state.isLoading = false;
    });

    // Get custom Outcomes with pagination
    builder.addCase(
      getCustomOutcomesWithPaginationRequest.pending,
      (state, action) => {
        state.getCustomOutcomesWithPaginationRequest.status =
          RequestStatus.Pending;
        state.getCustomOutcomesWithPaginationRequest.error = null;
        Object.keys(state.customOutcomePagination.options).forEach((key) => {
          state.customOutcomePagination.options[key] = action.meta.arg[key];
        });

        state.isLoading = true;
      },
    );
    builder.addCase(
      getCustomOutcomesWithPaginationRequest.fulfilled,
      (state, action) => {
        state.getCustomOutcomesWithPaginationRequest.status =
          RequestStatus.Succeeded;
        state.getCustomOutcomesWithPaginationRequest.message =
          action.payload.message;
        let outcomes = action.payload.payload.items;
        outcomes = outcomes.map((outcome) => {
          const processedOutcome = {
            ...outcome,
            processedSentiment: {
              sentiment: outcome.sentiment,
              bgColor: outcome.bgColor,
              textColor: outcome.textColor,
            },
            processedName: {
              name: outcome.name,
              sentiment: outcome.sentiment,
              isDefault: outcome.isDefault,
              emoji: outcome.emoji,
            },
          };
          return processedOutcome;
        });
        state.customOutcome = outcomes;
        state.customOutcomePagination.options.page =
          action.payload.payload.meta.currentPage;
        state.totalOutcomes = action.payload.payload.meta.itemCount;
        state.customOutcomePagination.count =
          action.payload.payload.meta.totalItems;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getCustomOutcomesWithPaginationRequest.rejected,
      (state, action) => {
        state.getCustomOutcomesWithPaginationRequest.status =
          RequestStatus.Failed;
        state.getCustomOutcomesWithPaginationRequest.error = action.payload;
        state.isLoading = false;
      },
    );

    // Get default Outcomes with pagination
    builder.addCase(
      getDefaultOutcomesWithPaginationRequest.pending,
      (state, action) => {
        state.getDefaultOutcomesWithPaginationRequest.status =
          RequestStatus.Pending;
        state.getDefaultOutcomesWithPaginationRequest.error = null;
        Object.keys(state.defaultOutcomePagination.options).forEach((key) => {
          state.defaultOutcomePagination.options[key] = action.meta.arg[key];
        });

        state.isLoading = true;
      },
    );
    builder.addCase(
      getDefaultOutcomesWithPaginationRequest.fulfilled,
      (state, action) => {
        state.getDefaultOutcomesWithPaginationRequest.status =
          RequestStatus.Succeeded;
        state.getDefaultOutcomesWithPaginationRequest.message =
          action.payload.message;

        let outcomes = action.payload.payload.items;
        outcomes = outcomes.map((outcome) => {
          const processedOutcome = {
            ...outcome,
            processedSentiment: {
              sentiment: outcome.sentiment,
              bgColor: outcome.bgColor,
              textColor: outcome.textColor,
            },
            processedName: {
              name: outcome.name,
              sentiment: outcome.sentiment,
              isDefault: outcome.isDefault,
              emoji: outcome.emoji,
            },
          };
          return processedOutcome;
        });
        state.defaultOutcome = outcomes;
        state.defaultOutcomePagination.options.page =
          action.payload.payload.meta.currentPage;
        state.totalOutcomes = action.payload.payload.meta.itemCount;
        state.defaultOutcomePagination.count =
          action.payload.payload.meta.totalItems;
        state.isLoading = false;
      },
    );
    builder.addCase(
      getDefaultOutcomesWithPaginationRequest.rejected,
      (state, action) => {
        state.getDefaultOutcomesWithPaginationRequest.status =
          RequestStatus.Failed;
        state.getDefaultOutcomesWithPaginationRequest.error = action.payload;
        state.isLoading = false;
      },
    );

    // Create custom Outcome for prospect
    builder.addCase(createCustomOutcomeRequest.pending, (state) => {
      state.createCustomOutcomeRequest.status = RequestStatus.Pending;
      state.createCustomOutcomeRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(createCustomOutcomeRequest.fulfilled, (state, action) => {
      state.createCustomOutcomeRequest.status = RequestStatus.Succeeded;
      state.createCustomOutcomeRequest.message = action.payload.message;
      state.customOutcome.unshift(action.payload.payload);
      state.isLoading = false;
    });
    builder.addCase(createCustomOutcomeRequest.rejected, (state, action) => {
      state.createCustomOutcomeRequest.status = RequestStatus.Failed;
      state.createCustomOutcomeRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Update custom Outcome for prospect
    builder.addCase(updateCustomOutcomeRequest.pending, (state) => {
      state.updateCustomOutcomeRequest.status = RequestStatus.Pending;
      state.updateCustomOutcomeRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(updateCustomOutcomeRequest.fulfilled, (state, action) => {
      state.updateCustomOutcomeRequest.status = RequestStatus.Succeeded;
      state.updateCustomOutcomeRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(updateCustomOutcomeRequest.rejected, (state, action) => {
      state.updateCustomOutcomeRequest.status = RequestStatus.Failed;
      state.updateCustomOutcomeRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Delete custom Outcome for prospect
    builder.addCase(deleteCustomOutcomeRequest.pending, (state) => {
      state.deleteCustomOutcomeRequest.status = RequestStatus.Pending;
      state.deleteCustomOutcomeRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(deleteCustomOutcomeRequest.fulfilled, (state, action) => {
      state.deleteCustomOutcomeRequest.status = RequestStatus.Succeeded;
      state.deleteCustomOutcomeRequest.message = action.payload.message;
      state.isLoading = false;
    });
    builder.addCase(deleteCustomOutcomeRequest.rejected, (state, action) => {
      state.deleteCustomOutcomeRequest.status = RequestStatus.Failed;
      state.deleteCustomOutcomeRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });

    // Get All Outcomes Request
    builder.addCase(getAllOutcomesRequest.pending, (state) => {
      state.getAllOutcomesRequest.status = RequestStatus.Pending;
      state.getAllOutcomesRequest.error = null;
      state.isLoading = true;
    });
    builder.addCase(getAllOutcomesRequest.fulfilled, (state, action) => {
      state.getAllOutcomesRequest.status = RequestStatus.Succeeded;
      state.getAllOutcomesRequest.message = action.payload.message;
      let outcomes = action.payload.payload.items;
      outcomes = outcomes.map((outcome) => {
        const processedOutcome = {
          ...outcome,
          processedSentiment: {
            sentiment: outcome.sentiment,
            bgColor: outcome.bgColor,
            textColor: outcome.textColor,
          },
          processedName: {
            name: outcome.name,
            sentiment: outcome.sentiment,
            isDefault: outcome.isDefault,
            emoji: outcome.emoji,
          },
        };
        return processedOutcome;
      });
      state.outcomes = outcomes;
      state.isLoading = false;
    });
    builder.addCase(getAllOutcomesRequest.rejected, (state, action) => {
      state.getAllOutcomesRequest.status = RequestStatus.Failed;
      state.getAllOutcomesRequest.error =
        !action.payload.isHandled && action.payload;
      state.isLoading = false;
    });
  },
});

export {
  getCustomOutcomeRequest,
  getCustomOutcomesWithPaginationRequest,
  getDefaultOutcomesWithPaginationRequest,
  deleteCustomOutcomeRequest,
  createCustomOutcomeRequest,
  updateCustomOutcomeRequest,
};

export const {
  resetCreateCustomOutcomeRequest,
  resetProspectOutcomeData,
} = customOutcomeSlice.actions;
export default customOutcomeSlice.reducer;
