import React from 'react';

export const GiftBox = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
  >
    <path
      d="M51.5255 7.41281L38.59 6.05705L35.443 22.0132L47.9247 25.6703L51.5255 7.41281Z"
      fill="#FDE68A"
    />
    <path
      d="M38.5901 6.05717L26.1083 2.40005L22.5076 20.6575L35.4431 22.0133L46.3515 25.5662L50.0305 6.91201L38.5901 6.05717Z"
      fill="#FDE68A"
    />
    <path
      d="M47.893 25.4252C47.8936 25.4253 47.7237 25.0739 47.7237 25.0739C47.6917 25.0068 47.6549 24.9421 47.6137 24.8803L46.7427 23.5815L44.9945 24.3638L46.3148 25.7512L47.4655 25.9782C47.5672 25.9982 47.6728 25.9771 47.7589 25.9193C47.845 25.8616 47.9046 25.772 47.9247 25.6703C47.9411 25.5873 47.9299 25.5013 47.893 25.4252Z"
      fill="#FCD34D"
    />
    <path
      d="M39.5137 12.8011C38.5517 11.3664 36.6088 10.9832 35.1741 11.9452L23.0948 20.0449C23.0332 20.0865 22.9746 20.1323 22.9195 20.1822L22.6289 20.4428C22.575 20.4914 22.5356 20.554 22.5153 20.6237C22.495 20.6934 22.4944 20.7673 22.5138 20.8373C22.5331 20.9072 22.5715 20.9704 22.6247 21.0198C22.6779 21.0691 22.7438 21.1027 22.815 21.1168L46.3145 25.7514L46.7424 23.5816L39.5137 12.8011Z"
      fill="#FCD34D"
    />
    <path
      d="M51.4027 7.6275C51.4033 7.62761 51.1127 7.88823 51.1127 7.88823C51.0576 7.9381 50.999 7.98398 50.9374 8.02553L49.6385 8.89644L48.3183 7.50899L50.0665 6.72666L51.2172 6.9536C51.3189 6.97366 51.4085 7.03332 51.4662 7.11943C51.524 7.20555 51.5451 7.31108 51.5251 7.41281C51.5087 7.49577 51.4658 7.57114 51.4027 7.6275Z"
      fill="#FEF3C7"
    />
    <path
      d="M38.8582 16.1251C37.4235 17.0871 35.4806 16.704 34.5186 15.2693L26.419 3.18985C26.3778 3.128 26.3411 3.06331 26.309 2.99627L26.1391 2.64483C26.1077 2.57941 26.0951 2.50654 26.1027 2.43437C26.1104 2.3622 26.138 2.29359 26.1824 2.23621C26.2268 2.17883 26.2864 2.13494 26.3543 2.10948C26.4223 2.08401 26.496 2.07796 26.5672 2.09201L50.0667 6.72665L49.6388 8.89643L38.8582 16.1251Z"
      fill="#FEF3C7"
    />
    <path
      d="M46.0424 18.427H24.6163L15.6953 33.4701V49.2308H50.9122V18.427H46.0424Z"
      fill="#93C5FD"
    />
    <path
      d="M5.65186 34.9645C3.67179 34.9645 1.93692 33.7379 1.33471 31.9121C0.559832 29.5641 1.9071 27.0004 4.33805 26.1974C4.63079 26.1008 4.97031 26.0541 5.37651 26.0541C6.59446 26.0541 8.34254 26.4926 9.88482 26.8797C10.6878 27.0812 11.4461 27.2716 11.9782 27.3608C12.1757 27.394 12.3603 27.4804 12.5123 27.6108C12.6642 27.7411 12.7778 27.9104 12.8407 28.1005C12.9036 28.2905 12.9134 28.4941 12.8692 28.6894C12.825 28.8846 12.7283 29.0641 12.5897 29.2085C12.2033 29.6108 11.6996 30.2304 11.1662 30.8863C9.48038 32.9594 8.29016 34.3464 7.15078 34.7222C6.66728 34.8824 6.16123 34.9642 5.65186 34.9645ZM5.37651 28.2551C5.13318 28.2551 5.03777 28.2843 5.02775 28.2876C3.74971 28.7098 3.0303 30.0265 3.42506 31.2225C3.72913 32.1443 4.62408 32.7634 5.65197 32.7634C5.92703 32.7631 6.20027 32.7188 6.4613 32.632C7.0744 32.4299 8.62549 30.5224 9.4587 29.4978C9.56259 29.37 9.66538 29.2435 9.76684 29.1194L9.3493 29.0148C8.00061 28.6763 6.32241 28.2551 5.37651 28.2551Z"
      fill="#60A5FA"
    />
    <path
      d="M11.7965 29.5468C11.5161 29.5468 11.2413 29.4395 11.033 29.239C10.5418 28.766 9.78686 28.146 8.98766 27.4896C6.50498 25.4505 4.71343 23.9032 4.27014 22.5573C3.81166 21.1688 3.8997 19.6881 4.51809 18.3884C5.14462 17.0717 6.23744 16.0999 7.59538 15.652C8.11704 15.4796 8.66289 15.3918 9.21228 15.3917C11.5485 15.3917 13.6261 16.9311 14.3822 19.2224C14.8273 20.5718 14.3269 22.7549 13.5126 25.9315C13.2489 26.9606 12.9996 27.9326 12.8814 28.6302C12.838 28.8865 12.7052 29.1191 12.5066 29.2869C12.308 29.4547 12.0565 29.5468 11.7965 29.5468ZM9.21228 17.5928C8.89764 17.5928 8.58564 17.6431 8.28464 17.7422C7.50316 18 6.87135 18.5653 6.50553 19.3339C6.13179 20.1195 6.08018 21.0193 6.36037 21.8678C6.63826 22.7116 8.98337 24.6377 10.3846 25.7885C10.6367 25.9956 10.8845 26.1991 11.1231 26.3972C11.2048 26.0695 11.2918 25.7302 11.3803 25.3847C11.8533 23.5392 12.5682 20.7501 12.2919 19.9118C11.8343 18.5248 10.5965 17.5928 9.21228 17.5928Z"
      fill="#3B82F6"
    />
    <path
      d="M27.4093 14.0129L24.7263 18.5371L15.8053 33.5803L6.07777 49.9836L0.397949 46.615L21.7295 10.6442L27.4093 14.0129Z"
      fill="#60A5FA"
    />
  </svg>
);
