import React from 'react';
import { ChevronRight, ChevronUp, Help, Trash } from '@saleshandy/icons';
import { Button } from '@saleshandy/design-system';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion';

import TextInput from './text-input';
import { accessibleOnClick } from '../../../../../../../shared/utils/accessible-on-click';
import { OverlayTooltip } from '../../../../../../../shared/design-system/components/overlay';

type IProps = {
  headers: { key: string; value: string; id: number }[];
  onAddHeader: () => void;
  onDeleteHeader: (id: number) => void;
  onHeaderKeyChange: (id: number, key: string) => void;
  onHeaderValueChange: (id: number, value: string) => void;
};

const HttpHeadersInput: React.FC<IProps> = ({
  headers,
  onAddHeader,
  onDeleteHeader,
  onHeaderKeyChange,
  onHeaderValueChange,
}) => (
  <div className="http-headers">
    <Accordion allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <AccordionItemState>
              {({ expanded }) =>
                expanded ? (
                  <div className="http-header-title">
                    <ChevronUp />
                    <span>HTTP Headers</span>
                  </div>
                ) : (
                  <div className="http-header-title">
                    <ChevronRight />
                    <span>HTTP Headers</span>
                  </div>
                )
              }
            </AccordionItemState>
            <p
              className="optional-txt"
              {...accessibleOnClick((e) => {
                e.preventDefault();
                e.stopPropagation();
              })}
            >
              Optional
            </p>
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <div className="header-input-wrapper">
            {headers.map((header, index) => (
              <div key={header.id} className="header-input">
                <div className="webhook-form-field">
                  <div className="webhook-form-field-label">
                    <p>Key</p>
                  </div>

                  <TextInput
                    name="key"
                    value={header.key}
                    onChange={(key) => onHeaderKeyChange(header.id, key)}
                  />
                </div>

                <div className="webhook-form-field">
                  <div className="webhook-form-field-label">
                    <p>Value</p>
                  </div>

                  <TextInput
                    name="value"
                    value={header.value}
                    onChange={(value) => onHeaderValueChange(header.id, value)}
                  />
                </div>

                {index > 0 && (
                  <OverlayTooltip text="Remove Header">
                    <button
                      type="button"
                      className="delete-header-input-btn"
                      onClick={() => onDeleteHeader(header.id)}
                    >
                      <Trash />
                    </button>
                  </OverlayTooltip>
                )}
              </div>
            ))}
          </div>
          {headers.length < 5 && (
            <Button
              className="add-new-header-btn"
              variant="tertiary"
              onClick={onAddHeader}
            >
              + Add New Header
            </Button>
          )}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
    <OverlayTooltip
      className="http-headers-help-tooltip"
      text="Headers are additional information you can include with your webhook data. Add a key-value pair for each header you want to include"
    >
      <div className="http-headers-help-icon">
        <Help />
      </div>
    </OverlayTooltip>
  </div>
);

export default HttpHeadersInput;
