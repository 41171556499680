import HelpscoutBeaconEvents from '../enums/helpscout-beacon-events';
import { IcpStatus } from '../enums/icp-status';
import { IntercomEvents } from '../enums/intercom-events';
import { SubscriptionPlans } from './subscription-plans';
import { supportUrls } from './urls';
import {
  getUserEmail,
  getUserFullName,
  getUserFirstName,
  getUserLastName,
  getUserSignUpDate,
  getUserTrackingId,
  getAgencyUserRole,
  isAgencyUser,
  getAgencyName,
  getUserCurrentPlan,
  getUserShAccountId,
} from './user-details';

const script = document.createElement('script');
const intercomScript = document.createElement('script');
script.setAttribute('id', 'chat-support-script');
intercomScript.setAttribute('id', 'intercom-support-script');

export const destroyChatSupport = () => {
  if (document.getElementById('chat-support-script')) {
    document.body.removeChild(script);
    window.Beacon && window.Beacon(HelpscoutBeaconEvents.DESTROY);
  }
  if (document.getElementById('intercom-support-script')) {
    document.body.removeChild(intercomScript);
    window.Intercom && window.Intercom(IntercomEvents.SHUTDOWN);
  }
};

export const initializeIntercom = () => {
  destroyChatSupport();

  window.intercomSettings = {
    api_base: supportUrls.intercomBaseUrl,
    app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    alignment: 'left',
    horizontal_padding: 65,
    name: getUserFullName(),
    'First Name': getUserFirstName(),
    'Last Name': getUserLastName(),
    'Signed up': getUserSignUpDate(),
    user_id: getUserTrackingId(),
    email: getUserEmail(),
    isAgencyUser: isAgencyUser(),
    'Agency Role': getAgencyUserRole(),
    'Agency Name': getAgencyName(),
    'Client Name': isAgencyUser() && getUserFullName(),
    company: {
      company_id: getUserShAccountId(),
      plan: getUserCurrentPlan(),
    },
  };

  intercomScript.innerHTML = `(function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src =
          'https://widget.intercom.io/widget/${process.env.REACT_APP_INTERCOM_APP_ID}';
        var x = d.getElementsByTagName('script')[0];
        if(x){
          x.parentNode.insertBefore(s, x);
        }
      };
      if (document.readyState === 'complete') {
        l();
      } else if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();`;

  setTimeout(() => {
    document.body.appendChild(intercomScript);
    window.Intercom &&
      window.Intercom('boot', {
        api_base: supportUrls.intercomBaseUrl,
        app_id: process.env.REACT_APP_INTERCOM_APP_ID,
      });
  }, 1000);
};

export const getHelpscoutBeaconId = (
  planCode?: SubscriptionPlans,
  icpStatus?: IcpStatus,
): string => {
  const ltdBeaconId = '78082ab8-48fe-4827-abf8-91853b675bec';
  const generalBeaconId = '19093d77-d406-4050-ae63-a681893c0d1a';
  const icpAndGcpBeaconId = '669ab2d9-1d6f-4a62-af0b-b719b4264379';
  const nonIcpBeaconId = '668e5203-ad1e-46f8-adbd-2359b7ccd90d';

  const params = new URLSearchParams(window.location?.search);

  if (
    (planCode &&
      (planCode === SubscriptionPlans.EMAIL_OUTREACH_LIFETIME ||
        planCode === SubscriptionPlans.EMAIL_OUTREACH_PRO_LIFETIME)) ||
    window.location?.pathname?.toLocaleLowerCase().includes('ltd') ||
    params.get('redirectedFrom') === 'ltd'
  ) {
    return ltdBeaconId;
  }

  if (icpStatus === IcpStatus.ICP || icpStatus === IcpStatus.GCP) {
    return icpAndGcpBeaconId;
  }

  if (icpStatus === IcpStatus.NONICP) {
    return nonIcpBeaconId;
  }

  return generalBeaconId;
};
