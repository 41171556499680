import React, { ReactNode, useEffect, useState } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { ChevronLeft, ChevronRight, Cross } from '@saleshandy/icons';
import { Button as SHButton } from '@saleshandy/design-system';
import classNames from 'classnames';

import Button from '../button';
import Icon from '../icon';
import eventBus from '../../../../utils/event-bus';
import { constants } from '../../../../enums/constants';
import { Variant, Theme } from '../button/button';
import { accessibleOnClick } from '../../../../utils/accessible-on-click';

const { Header, Title, Body, Footer } = BootstrapModal;

type IProps = {
  // modal configurations
  show: boolean;
  onClose?: () => void;
  onSubmit?: (e?: any) => void;
  onCancel?: (e?: any) => void;
  onHide?: () => void;
  className?: string;
  backdrop?: 'static' | boolean;

  extraModalProps?: Record<string, unknown>;

  // modal title
  titleContent?: ReactNode;
  showCloseIcon?: boolean;
  hideHeader?: boolean;
  isCustomCloseIcon?: boolean;

  // modal body
  children: ReactNode;

  // modal footer
  hideFooter?: boolean;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  submitButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  isSubmitDisabled?: boolean;
  isSubmitLoading?: boolean;
  isCancelLoading?: boolean;
  isCancelDisabled?: boolean;
  footerContent?: ReactNode;
  showSidebar?: boolean;
  isCollapsibleSidebar?: boolean;
  sidebarTitle?: ReactNode;
  sidebarContent?: ReactNode;
  submitButtonClassName?: string;
  cancelButtonClassName?: string;
  submitButtonVariant?: Variant;
  cancelButtonVarient?: Variant;
  cancelButtonTheme?: Theme;
  submitButtonRef?: any;
  showSidebarFooter?: boolean;
  sidebarCtaMeta?: {
    label: string;
    onClick: VoidFunction;
    disabled: boolean;
  };
  showSeparator?: boolean;
  defaultSidebarCollapsedValue?: boolean;
  onSidebarCollapseToggle?: (value: boolean) => void;
  footerClassNames?: string;
} & ({ backdrop: 'static' | boolean } | { onHide: () => void });

const Modal: React.FC<IProps> = ({
  show,
  onClose,
  onSubmit,
  onCancel,
  onHide,
  className = '',
  backdrop,
  extraModalProps,
  titleContent,
  showCloseIcon = false,
  isCustomCloseIcon = false,
  hideHeader = false,
  children,
  hideFooter = false,
  hideCancelButton = false,
  hideSubmitButton = false,
  submitButtonText = 'Submit',
  cancelButtonText = 'Cancel',
  isSubmitDisabled = false,
  isSubmitLoading = false,
  isCancelLoading = false,
  isCancelDisabled = false,
  footerContent = false,
  showSidebar = false,
  isCollapsibleSidebar = false,
  sidebarTitle,
  sidebarContent,
  submitButtonClassName,
  cancelButtonClassName,
  submitButtonVariant,
  cancelButtonVarient = Button.Variant.Primary,
  cancelButtonTheme = Button.Theme.Ghost,
  submitButtonRef,
  showSidebarFooter,
  sidebarCtaMeta,
  defaultSidebarCollapsedValue = false,
  showSeparator,
  onSidebarCollapseToggle,
  footerClassNames = '',
}) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultSidebarCollapsedValue);

  const onCloseHandler = () => {
    onClose();
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
  };

  const onCancelHandler = () => {
    if (onCancel) {
      onCancel();
    } else {
      onCloseHandler();
    }
  };

  const onSubmitHandler = (e) => {
    onSubmit(e);
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: false });
  };

  useEffect(() => {
    eventBus.dispatch(constants.MODAL_HANDLER, { isModalOpened: show });
  }, [show]);

  const isHiddenButton = hideSubmitButton && hideCancelButton;

  const modalClasses = classNames([
    'bs-modal-container',
    className,
    { 'modal-with-sidebar': showSidebar },
    { 'modal-with-collapsible-sidebar': showSidebar && isCollapsibleSidebar },
    {
      'modal-sidebar-collapsed':
        showSidebar && isCollapsibleSidebar && isCollapsed,
    },
  ]);

  const modalFooterClasses = classNames([
    'bs-modal-footer',
    { 'footer-with-content': !isHiddenButton && footerContent },
    footerClassNames,
  ]);

  const sidebarBodyClasses = classNames([
    'bs-modal-body sidebar-body',
    { 'h-100': !(showSidebarFooter && sidebarCtaMeta) },
  ]);

  const sidebarBodyContentClasses = classNames([
    'sidebar-body-content',
    { 'd-none': isCollapsibleSidebar && isCollapsed },
  ]);

  const renderCloseBtn = () =>
    showCloseIcon && (
      <Button className="close" onClick={onCloseHandler}>
        {isCustomCloseIcon ? (
          <Cross width={20} height={20} className="gray-txt-1 mt-1" />
        ) : (
          <Icon identifier="close" />
        )}
      </Button>
    );

  return (
    <BootstrapModal
      show={show}
      className={modalClasses}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      backdrop={backdrop}
      {...extraModalProps}
    >
      <div className="modal-content-container">
        {!hideHeader && (
          <Header className="bs-modal-header">
            <Title>{titleContent}</Title>
            {!isCollapsibleSidebar && renderCloseBtn()}
          </Header>
        )}

        <Body className="bs-modal-body">
          {showSidebar && isCollapsibleSidebar && (
            <div
              className="collapse-sidebar-btn"
              {...accessibleOnClick(() => {
                setIsCollapsed(!isCollapsed);
                onSidebarCollapseToggle?.(!isCollapsed);
              })}
            >
              {isCollapsed ? <ChevronLeft /> : <ChevronRight />}
            </div>
          )}

          {children}
        </Body>

        {showSeparator && <span className="modal-separator" />}

        {!hideFooter && (
          <Footer className={modalFooterClasses}>
            {footerContent && (
              <div className="bs-modal-footer-content">{footerContent}</div>
            )}

            {!isHiddenButton && (
              <div className="bs-modal-footer-action-buttons">
                {!hideCancelButton && (
                  <Button
                    variant={cancelButtonVarient}
                    theme={cancelButtonTheme}
                    onClick={onCancelHandler}
                    isLoading={isCancelLoading}
                    disabled={isCancelDisabled}
                    className={cancelButtonClassName}
                  >
                    {cancelButtonText}
                  </Button>
                )}
                {!hideSubmitButton && (
                  <Button
                    ref={submitButtonRef}
                    variant={submitButtonVariant || Button.Variant.Primary}
                    onClick={onSubmitHandler}
                    isLoading={isSubmitLoading}
                    disabled={isSubmitDisabled}
                    className={submitButtonClassName}
                  >
                    {submitButtonText}
                  </Button>
                )}
              </div>
            )}
          </Footer>
        )}
      </div>

      {showSidebar && (
        <div className="modal-sidebar">
          <Header className="bs-modal-header sidebar-header">
            {sidebarTitle && <Title>{sidebarTitle}</Title>}
            {isCollapsibleSidebar && renderCloseBtn()}
          </Header>
          <Body className={sidebarBodyClasses}>
            <div className={sidebarBodyContentClasses}>{sidebarContent}</div>
          </Body>
          {showSidebarFooter && sidebarCtaMeta && !isCollapsed && (
            <Footer>
              <SHButton
                variant="secondary"
                disabled={sidebarCtaMeta.disabled}
                onClick={sidebarCtaMeta.onClick}
              >
                {sidebarCtaMeta.label}
              </SHButton>
            </Footer>
          )}
        </div>
      )}
    </BootstrapModal>
  );
};

export default Modal;
