import { connect, ConnectedProps } from 'react-redux';
import { RequestStatus } from '../../../../../../shared/enums/request-status';
import { RootState } from '../../../../../../store/root-reducer';
import {
  AddToStepParams,
  SelectedProspectData,
} from '../../../../../sequence/types';
import {
  unsubscribeProspectRequest,
  addToStepRequest,
  removeProspectRequest,
  getSingleProspectSequencesDetailsRequest,
  singleProspectSequencesMarkAsRepliedRequest,
  singleProspectRemoveFromSequencesRequest,
  saveContactAttributesRequest,
  getSingleProspectDetailsRequest,
  singleProspectSequencesMarkAsFinishedRequest,
  changePrimaryEmailInProspectRequest,
  deleteSecondaryEmailRequest,
  deleteSecondaryPhoneRequest,
} from '../../../../extra-actions';
import {
  resetProspectDeleteEmailRequestStatus,
  resetProspectDeletePhoneRequestStatus,
  resetRemoveProspectRequest,
  resetSaveContactAttributesRequestStatus,
  resetSequenceAndStepData,
  resetProspectPrimaryEmailChangeRequestStatus,
} from '../../../../prospect-slice';
import { markAsRepliedParam, removeFromSequenceParam } from '../../../../types';
import ProspectDetails from './prospect-details';

const mapStateToProps = (state: RootState) => ({
  addToStepRequestStatus: state.prospect.addToStepRequest.status,
  addToStepRequestError: state.prospect.addToStepRequest.error,
  addToStepRequestMessage: state.prospect.addToStepRequest.message,
  addToStepRequestFailedCount:
    state.prospect.addToStepRequestResultCount.failed,
  addToStepRequestSuccessfulCount:
    state.prospect.addToStepRequestResultCount.successful,

  removeProspectStatus: state.prospect.removeProspectRequest.status,
  removeProspectError: state.prospect.removeProspectRequest.error,
  removeProspectMessage: state.prospect.removeProspectRequest.message,

  unsubscribeProspectStatus: state.prospect.unsubscribeProspectRequest.status,
  unsubscribeProspectError: state.prospect.unsubscribeProspectRequest.error,
  unsubscribeProspectMessage: state.prospect.unsubscribeProspectRequest.message,

  markAsRepliedError:
    state.prospect.singleProspectSequencesMarkAsRepliedRequest.error,
  markAsRepliedMessage:
    state.prospect.singleProspectSequencesMarkAsRepliedRequest.message,
  markAsRepliedStatus:
    state.prospect.singleProspectSequencesMarkAsRepliedRequest.status,

  markAsFinishedError:
    state.prospect.singleProspectSequencesMarkAsFinishedRequest.error,
  markAsFinishedMessage:
    state.prospect.singleProspectSequencesMarkAsFinishedRequest.message,
  markAsFinishedStatus:
    state.prospect.singleProspectSequencesMarkAsFinishedRequest.status,

  removeFromSequenceStatus:
    state.prospect.singleProspectRemoveFromSequencesRequest.status,
  removeFromSequenceError:
    state.prospect.singleProspectRemoveFromSequencesRequest.error,
  removeFromSequenceMessage:
    state.prospect.singleProspectRemoveFromSequencesRequest.message,

  deleteSecondaryEmailRequestStatus:
    state.prospect.deleteSecondaryEmailRequest.status,
  deleteSecondaryEmailRequestError:
    state.prospect.deleteSecondaryEmailRequest.error,
  deleteSecondaryEmailRequestMessage:
    state.prospect.deleteSecondaryEmailRequest.message,

  deleteSecondaryPhoneRequestStatus:
    state.prospect.deleteSecondaryPhoneRequest.status,
  deleteSecondaryPhoneRequestError:
    state.prospect.deleteSecondaryPhoneRequest.error,
  deleteSecondaryPhoneRequestMessage:
    state.prospect.deleteSecondaryPhoneRequest.message,

  primaryEmailChangeRequestStatus:
    state.prospect.changePrimaryEmailInProspectRequest.status,
  primaryEmailChangeRequestError:
    state.prospect.changePrimaryEmailInProspectRequest.error,
  primaryEmailChangeRequestMessage:
    state.prospect.changePrimaryEmailInProspectRequest.message,

  saveContactAttributesRequestStatus:
    state.prospect.saveContactAttributesRequest.status,
  saveContactAttributesRequestError:
    state.prospect.saveContactAttributesRequest.error,
  saveContactAttributesRequestMessage:
    state.prospect.saveContactAttributesRequest.message,
  allOutcomes: state.customOutcome.outcomes,
});

const mapDispatchToProps = {
  sendAddContactToStepRequest: (payload: AddToStepParams) =>
    addToStepRequest(payload),
  resetSequenceAndStepData: () => resetSequenceAndStepData(),
  sendRemoveProspectRequest: (contactArr: number[]) =>
    removeProspectRequest(contactArr),
  sendUnsubscribeProspectRequest: (contactArr: number[]) =>
    unsubscribeProspectRequest(contactArr),
  sendGetSingleProspectSequencesDetailsRequest: (contactArr: number) =>
    getSingleProspectSequencesDetailsRequest(contactArr),
  sendSequenceMarkAsRepliedRequest: (contactAndStepIds: markAsRepliedParam[]) =>
    singleProspectSequencesMarkAsRepliedRequest(contactAndStepIds),
  sendSequenceMarkAsFinishedRequest: (
    contactAndStepIds: markAsRepliedParam[],
  ) => singleProspectSequencesMarkAsFinishedRequest(contactAndStepIds),
  sendSingleProspectRemoveFromSequencesRequest: (
    param: removeFromSequenceParam,
  ) => singleProspectRemoveFromSequencesRequest(param),
  sendSaveContactAttributesRequest: (contactId: number, attributes: []) =>
    saveContactAttributesRequest({ contactId, attributes }),
  sendGetSingleProspectDetailsRequest: (contactId: number) =>
    getSingleProspectDetailsRequest(contactId),
  resetRemoveProspectRequest: () => resetRemoveProspectRequest(),
  resetSaveContactAttributesRequestStatus: () =>
    resetSaveContactAttributesRequestStatus(),
  sendPrimaryEmailChangeInProspectRequest: (
    primaryEmailProspectInfoId: number,
    contactId: number,
  ) =>
    changePrimaryEmailInProspectRequest({
      primaryEmailProspectInfoId,
      contactId,
    }),
  sendDeleteEmailFromProspectRequest: (
    prospectInfoId: number,
    contactId: number,
  ) => deleteSecondaryEmailRequest({ prospectInfoId, contactId }),
  sendDeletePhoneFromProspectRequest: (
    prospectInfoId: number,
    contactId: number,
  ) => deleteSecondaryPhoneRequest({ prospectInfoId, contactId }),
  resetProspectDeleteEmailRequestStatus: () =>
    resetProspectDeleteEmailRequestStatus(),
  resetProspectDeletePhoneRequestStatus: () =>
    resetProspectDeletePhoneRequestStatus(),
  resetProspectPrimaryEmailChangeRequestStatus: () =>
    resetProspectPrimaryEmailChangeRequestStatus(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type IProps = ConnectedProps<typeof connector> & {
  show: boolean;
  onHide: VoidFunction;
  selectedProspectId: number;
  resetSelectedProspectId: VoidFunction;
  selectedProspectData: SelectedProspectData;
  selectedProspectSequences: any[];
  getSingleProspectDetailsStatus: RequestStatus;
  getSingleProspectSequencesDetailsStatus: RequestStatus;
  isTagsFilterApplied: boolean;
  t?: (x: string) => string;
  currentSequenceId?: number;
};

export default connector(ProspectDetails);
